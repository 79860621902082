/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Language } from './Language';
import {
    LanguageFromJSON,
    LanguageFromJSONTyped,
    LanguageToJSON,
} from './Language';
import type { Product } from './Product';
import {
    ProductFromJSON,
    ProductFromJSONTyped,
    ProductToJSON,
} from './Product';

/**
 * 
 * @export
 * @interface ProductLanguage
 */
export interface ProductLanguage {
    /**
     * 
     * @type {Language}
     * @memberof ProductLanguage
     */
    languageByLanguageId?: Language;
    /**
     * 
     * @type {number}
     * @memberof ProductLanguage
     */
    languageId?: number;
    /**
     * 
     * @type {Product}
     * @memberof ProductLanguage
     */
    productByProductId?: Product;
    /**
     * 
     * @type {number}
     * @memberof ProductLanguage
     */
    productId?: number;
}

/**
 * Check if a given object implements the ProductLanguage interface.
 */
export function instanceOfProductLanguage(value: object): boolean {
    return true;
}

export function ProductLanguageFromJSON(json: any): ProductLanguage {
    return ProductLanguageFromJSONTyped(json, false);
}

export function ProductLanguageFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProductLanguage {
    if (json == null) {
        return json;
    }
    return {
        
        'languageByLanguageId': json['languageByLanguageId'] == null ? undefined : LanguageFromJSON(json['languageByLanguageId']),
        'languageId': json['languageId'] == null ? undefined : json['languageId'],
        'productByProductId': json['productByProductId'] == null ? undefined : ProductFromJSON(json['productByProductId']),
        'productId': json['productId'] == null ? undefined : json['productId'],
    };
}

export function ProductLanguageToJSON(value?: ProductLanguage | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'languageByLanguageId': LanguageToJSON(value['languageByLanguageId']),
        'languageId': value['languageId'],
        'productByProductId': ProductToJSON(value['productByProductId']),
        'productId': value['productId'],
    };
}

