/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { MarketingEvent } from './MarketingEvent';
import {
    MarketingEventFromJSON,
    MarketingEventFromJSONTyped,
    MarketingEventToJSON,
} from './MarketingEvent';
import type { MemberLevel } from './MemberLevel';
import {
    MemberLevelFromJSON,
    MemberLevelFromJSONTyped,
    MemberLevelToJSON,
} from './MemberLevel';

/**
 * 
 * @export
 * @interface MarketingEventMemberLevel
 */
export interface MarketingEventMemberLevel {
    /**
     * 
     * @type {MarketingEvent}
     * @memberof MarketingEventMemberLevel
     */
    marketingEventByMarketingEventId?: MarketingEvent;
    /**
     * 
     * @type {number}
     * @memberof MarketingEventMemberLevel
     */
    marketingEventId?: number;
    /**
     * 
     * @type {MemberLevel}
     * @memberof MarketingEventMemberLevel
     */
    memberLevelByMemberLevelId?: MemberLevel;
    /**
     * 
     * @type {number}
     * @memberof MarketingEventMemberLevel
     */
    memberLevelId?: number;
}

/**
 * Check if a given object implements the MarketingEventMemberLevel interface.
 */
export function instanceOfMarketingEventMemberLevel(value: object): boolean {
    return true;
}

export function MarketingEventMemberLevelFromJSON(json: any): MarketingEventMemberLevel {
    return MarketingEventMemberLevelFromJSONTyped(json, false);
}

export function MarketingEventMemberLevelFromJSONTyped(json: any, ignoreDiscriminator: boolean): MarketingEventMemberLevel {
    if (json == null) {
        return json;
    }
    return {
        
        'marketingEventByMarketingEventId': json['marketingEventByMarketingEventId'] == null ? undefined : MarketingEventFromJSON(json['marketingEventByMarketingEventId']),
        'marketingEventId': json['marketingEventId'] == null ? undefined : json['marketingEventId'],
        'memberLevelByMemberLevelId': json['memberLevelByMemberLevelId'] == null ? undefined : MemberLevelFromJSON(json['memberLevelByMemberLevelId']),
        'memberLevelId': json['memberLevelId'] == null ? undefined : json['memberLevelId'],
    };
}

export function MarketingEventMemberLevelToJSON(value?: MarketingEventMemberLevel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'marketingEventByMarketingEventId': MarketingEventToJSON(value['marketingEventByMarketingEventId']),
        'marketingEventId': value['marketingEventId'],
        'memberLevelByMemberLevelId': MemberLevelToJSON(value['memberLevelByMemberLevelId']),
        'memberLevelId': value['memberLevelId'],
    };
}

