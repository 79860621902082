/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ProductDtoLite
 */
export interface ProductDtoLite {
    /**
     * 
     * @type {number}
     * @memberof ProductDtoLite
     */
    deposit?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductDtoLite
     */
    displayProductId?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductDtoLite
     */
    isGroupProduct?: string;
    /**
     * 
     * @type {Date}
     * @memberof ProductDtoLite
     */
    offShelfDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof ProductDtoLite
     */
    onShelfDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof ProductDtoLite
     */
    productDescription?: string;
    /**
     * 
     * @type {number}
     * @memberof ProductDtoLite
     */
    productDiscountPrice?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductDtoLite
     */
    productHashtag?: string;
    /**
     * 
     * @type {number}
     * @memberof ProductDtoLite
     */
    productId?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof ProductDtoLite
     */
    productImage?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof ProductDtoLite
     */
    productInventory?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductDtoLite
     */
    productNameChi?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductDtoLite
     */
    productNameEng?: string;
    /**
     * 
     * @type {number}
     * @memberof ProductDtoLite
     */
    productPrice?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductDtoLite
     */
    productStep?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductDtoLite
     */
    productVolume?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductDtoLite
     */
    productVolumeUnit?: string;
    /**
     * 
     * @type {number}
     * @memberof ProductDtoLite
     */
    sales?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductDtoLite
     */
    sku?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductDtoLite
     */
    status?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductDtoLite
     */
    subscriptionPeriod?: string;
}

/**
 * Check if a given object implements the ProductDtoLite interface.
 */
export function instanceOfProductDtoLite(value: object): boolean {
    return true;
}

export function ProductDtoLiteFromJSON(json: any): ProductDtoLite {
    return ProductDtoLiteFromJSONTyped(json, false);
}

export function ProductDtoLiteFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProductDtoLite {
    if (json == null) {
        return json;
    }
    return {
        
        'deposit': json['deposit'] == null ? undefined : json['deposit'],
        'displayProductId': json['displayProductId'] == null ? undefined : json['displayProductId'],
        'isGroupProduct': json['isGroupProduct'] == null ? undefined : json['isGroupProduct'],
        'offShelfDate': json['offShelfDate'] == null ? undefined : (new Date(json['offShelfDate'])),
        'onShelfDate': json['onShelfDate'] == null ? undefined : (new Date(json['onShelfDate'])),
        'productDescription': json['productDescription'] == null ? undefined : json['productDescription'],
        'productDiscountPrice': json['productDiscountPrice'] == null ? undefined : json['productDiscountPrice'],
        'productHashtag': json['productHashtag'] == null ? undefined : json['productHashtag'],
        'productId': json['productId'] == null ? undefined : json['productId'],
        'productImage': json['productImage'] == null ? undefined : json['productImage'],
        'productInventory': json['productInventory'] == null ? undefined : json['productInventory'],
        'productNameChi': json['productNameChi'] == null ? undefined : json['productNameChi'],
        'productNameEng': json['productNameEng'] == null ? undefined : json['productNameEng'],
        'productPrice': json['productPrice'] == null ? undefined : json['productPrice'],
        'productStep': json['productStep'] == null ? undefined : json['productStep'],
        'productVolume': json['productVolume'] == null ? undefined : json['productVolume'],
        'productVolumeUnit': json['productVolumeUnit'] == null ? undefined : json['productVolumeUnit'],
        'sales': json['sales'] == null ? undefined : json['sales'],
        'sku': json['sku'] == null ? undefined : json['sku'],
        'status': json['status'] == null ? undefined : json['status'],
        'subscriptionPeriod': json['subscriptionPeriod'] == null ? undefined : json['subscriptionPeriod'],
    };
}

export function ProductDtoLiteToJSON(value?: ProductDtoLite | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'deposit': value['deposit'],
        'displayProductId': value['displayProductId'],
        'isGroupProduct': value['isGroupProduct'],
        'offShelfDate': value['offShelfDate'] == null ? undefined : ((value['offShelfDate']).toISOString()),
        'onShelfDate': value['onShelfDate'] == null ? undefined : ((value['onShelfDate']).toISOString()),
        'productDescription': value['productDescription'],
        'productDiscountPrice': value['productDiscountPrice'],
        'productHashtag': value['productHashtag'],
        'productId': value['productId'],
        'productImage': value['productImage'],
        'productInventory': value['productInventory'],
        'productNameChi': value['productNameChi'],
        'productNameEng': value['productNameEng'],
        'productPrice': value['productPrice'],
        'productStep': value['productStep'],
        'productVolume': value['productVolume'],
        'productVolumeUnit': value['productVolumeUnit'],
        'sales': value['sales'],
        'sku': value['sku'],
        'status': value['status'],
        'subscriptionPeriod': value['subscriptionPeriod'],
    };
}

