/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AdminMemberRoleDto,
} from '../models/index';
import {
    AdminMemberRoleDtoFromJSON,
    AdminMemberRoleDtoToJSON,
} from '../models/index';

export interface AddAdminMemberRoleUsingPOSTRequest {
    adminMemberRoleName: string;
    productManagement: number;
    inventoryManagement: number;
    articleManagement: number;
    productPriceManagement: number;
    discountManagement: number;
    marketingEventManagement: number;
    membershipManagement: number;
    membershipManagementRead: number;
    pointManagement: number;
    admin: number;
    orderManagement: number;
    orderManagementRead: number;
    stockLevelManagement: number;
    name?: string;
}

export interface AddAdminMemberRoleUsingPOST1Request {
    adminMemberRoleName: string;
    productManagement: number;
    inventoryManagement: number;
    articleManagement: number;
    productPriceManagement: number;
    discountManagement: number;
    marketingEventManagement: number;
    membershipManagement: number;
    membershipManagementRead: number;
    pointManagement: number;
    admin: number;
    orderManagement: number;
    orderManagementRead: number;
    stockLevelManagement: number;
    name?: string;
}

export interface DeleteAdminMemberRoleUsingDELETERequest {
    adminMemberRoleId: number;
    name?: string;
}

export interface GetAdminMemberRoleUsingGETRequest {
    adminMemberRoleId: number;
    name?: string;
}

export interface GetAllAdminMemberRoleUsingGETRequest {
    name?: string;
}

export interface UpdateAdminMemberRoleUsingPUTRequest {
    adminMemberRoleId: number;
    name?: string;
    adminMemberRoleName?: string;
    productManagement?: number;
    inventoryManagement?: number;
    articleManagement?: number;
    productPriceManagement?: number;
    discountManagement?: number;
    marketingEventManagement?: number;
    membershipManagement?: number;
    membershipManagementRead?: number;
    pointManagement?: number;
    admin?: number;
    orderManagement?: number;
    orderManagementRead?: number;
    stockLevelManagement?: number;
    reportManagement?: number;
}

/**
 * 
 */
export class AdminMemberRoleControllerApi extends runtime.BaseAPI {

    /**
     * addAdminMemberRole
     */
    async addAdminMemberRoleUsingPOSTRaw(requestParameters: AddAdminMemberRoleUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AdminMemberRoleDto>> {
        if (requestParameters['adminMemberRoleName'] == null) {
            throw new runtime.RequiredError(
                'adminMemberRoleName',
                'Required parameter "adminMemberRoleName" was null or undefined when calling addAdminMemberRoleUsingPOST().'
            );
        }

        if (requestParameters['productManagement'] == null) {
            throw new runtime.RequiredError(
                'productManagement',
                'Required parameter "productManagement" was null or undefined when calling addAdminMemberRoleUsingPOST().'
            );
        }

        if (requestParameters['inventoryManagement'] == null) {
            throw new runtime.RequiredError(
                'inventoryManagement',
                'Required parameter "inventoryManagement" was null or undefined when calling addAdminMemberRoleUsingPOST().'
            );
        }

        if (requestParameters['articleManagement'] == null) {
            throw new runtime.RequiredError(
                'articleManagement',
                'Required parameter "articleManagement" was null or undefined when calling addAdminMemberRoleUsingPOST().'
            );
        }

        if (requestParameters['productPriceManagement'] == null) {
            throw new runtime.RequiredError(
                'productPriceManagement',
                'Required parameter "productPriceManagement" was null or undefined when calling addAdminMemberRoleUsingPOST().'
            );
        }

        if (requestParameters['discountManagement'] == null) {
            throw new runtime.RequiredError(
                'discountManagement',
                'Required parameter "discountManagement" was null or undefined when calling addAdminMemberRoleUsingPOST().'
            );
        }

        if (requestParameters['marketingEventManagement'] == null) {
            throw new runtime.RequiredError(
                'marketingEventManagement',
                'Required parameter "marketingEventManagement" was null or undefined when calling addAdminMemberRoleUsingPOST().'
            );
        }

        if (requestParameters['membershipManagement'] == null) {
            throw new runtime.RequiredError(
                'membershipManagement',
                'Required parameter "membershipManagement" was null or undefined when calling addAdminMemberRoleUsingPOST().'
            );
        }

        if (requestParameters['membershipManagementRead'] == null) {
            throw new runtime.RequiredError(
                'membershipManagementRead',
                'Required parameter "membershipManagementRead" was null or undefined when calling addAdminMemberRoleUsingPOST().'
            );
        }

        if (requestParameters['pointManagement'] == null) {
            throw new runtime.RequiredError(
                'pointManagement',
                'Required parameter "pointManagement" was null or undefined when calling addAdminMemberRoleUsingPOST().'
            );
        }

        if (requestParameters['admin'] == null) {
            throw new runtime.RequiredError(
                'admin',
                'Required parameter "admin" was null or undefined when calling addAdminMemberRoleUsingPOST().'
            );
        }

        if (requestParameters['orderManagement'] == null) {
            throw new runtime.RequiredError(
                'orderManagement',
                'Required parameter "orderManagement" was null or undefined when calling addAdminMemberRoleUsingPOST().'
            );
        }

        if (requestParameters['orderManagementRead'] == null) {
            throw new runtime.RequiredError(
                'orderManagementRead',
                'Required parameter "orderManagementRead" was null or undefined when calling addAdminMemberRoleUsingPOST().'
            );
        }

        if (requestParameters['stockLevelManagement'] == null) {
            throw new runtime.RequiredError(
                'stockLevelManagement',
                'Required parameter "stockLevelManagement" was null or undefined when calling addAdminMemberRoleUsingPOST().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        if (requestParameters['adminMemberRoleName'] != null) {
            queryParameters['adminMemberRoleName'] = requestParameters['adminMemberRoleName'];
        }

        if (requestParameters['productManagement'] != null) {
            queryParameters['productManagement'] = requestParameters['productManagement'];
        }

        if (requestParameters['inventoryManagement'] != null) {
            queryParameters['inventoryManagement'] = requestParameters['inventoryManagement'];
        }

        if (requestParameters['articleManagement'] != null) {
            queryParameters['articleManagement'] = requestParameters['articleManagement'];
        }

        if (requestParameters['productPriceManagement'] != null) {
            queryParameters['productPriceManagement'] = requestParameters['productPriceManagement'];
        }

        if (requestParameters['discountManagement'] != null) {
            queryParameters['discountManagement'] = requestParameters['discountManagement'];
        }

        if (requestParameters['marketingEventManagement'] != null) {
            queryParameters['marketingEventManagement'] = requestParameters['marketingEventManagement'];
        }

        if (requestParameters['membershipManagement'] != null) {
            queryParameters['membershipManagement'] = requestParameters['membershipManagement'];
        }

        if (requestParameters['membershipManagementRead'] != null) {
            queryParameters['membershipManagementRead'] = requestParameters['membershipManagementRead'];
        }

        if (requestParameters['pointManagement'] != null) {
            queryParameters['pointManagement'] = requestParameters['pointManagement'];
        }

        if (requestParameters['admin'] != null) {
            queryParameters['admin'] = requestParameters['admin'];
        }

        if (requestParameters['orderManagement'] != null) {
            queryParameters['orderManagement'] = requestParameters['orderManagement'];
        }

        if (requestParameters['orderManagementRead'] != null) {
            queryParameters['orderManagementRead'] = requestParameters['orderManagementRead'];
        }

        if (requestParameters['stockLevelManagement'] != null) {
            queryParameters['stockLevelManagement'] = requestParameters['stockLevelManagement'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/adminMemberRole`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AdminMemberRoleDtoFromJSON(jsonValue));
    }

    /**
     * addAdminMemberRole
     */
    async addAdminMemberRoleUsingPOST(requestParameters: AddAdminMemberRoleUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AdminMemberRoleDto | null | undefined > {
        const response = await this.addAdminMemberRoleUsingPOSTRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 201:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * addAdminMemberRole
     */
    async addAdminMemberRoleUsingPOST1Raw(requestParameters: AddAdminMemberRoleUsingPOST1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AdminMemberRoleDto>> {
        if (requestParameters['adminMemberRoleName'] == null) {
            throw new runtime.RequiredError(
                'adminMemberRoleName',
                'Required parameter "adminMemberRoleName" was null or undefined when calling addAdminMemberRoleUsingPOST1().'
            );
        }

        if (requestParameters['productManagement'] == null) {
            throw new runtime.RequiredError(
                'productManagement',
                'Required parameter "productManagement" was null or undefined when calling addAdminMemberRoleUsingPOST1().'
            );
        }

        if (requestParameters['inventoryManagement'] == null) {
            throw new runtime.RequiredError(
                'inventoryManagement',
                'Required parameter "inventoryManagement" was null or undefined when calling addAdminMemberRoleUsingPOST1().'
            );
        }

        if (requestParameters['articleManagement'] == null) {
            throw new runtime.RequiredError(
                'articleManagement',
                'Required parameter "articleManagement" was null or undefined when calling addAdminMemberRoleUsingPOST1().'
            );
        }

        if (requestParameters['productPriceManagement'] == null) {
            throw new runtime.RequiredError(
                'productPriceManagement',
                'Required parameter "productPriceManagement" was null or undefined when calling addAdminMemberRoleUsingPOST1().'
            );
        }

        if (requestParameters['discountManagement'] == null) {
            throw new runtime.RequiredError(
                'discountManagement',
                'Required parameter "discountManagement" was null or undefined when calling addAdminMemberRoleUsingPOST1().'
            );
        }

        if (requestParameters['marketingEventManagement'] == null) {
            throw new runtime.RequiredError(
                'marketingEventManagement',
                'Required parameter "marketingEventManagement" was null or undefined when calling addAdminMemberRoleUsingPOST1().'
            );
        }

        if (requestParameters['membershipManagement'] == null) {
            throw new runtime.RequiredError(
                'membershipManagement',
                'Required parameter "membershipManagement" was null or undefined when calling addAdminMemberRoleUsingPOST1().'
            );
        }

        if (requestParameters['membershipManagementRead'] == null) {
            throw new runtime.RequiredError(
                'membershipManagementRead',
                'Required parameter "membershipManagementRead" was null or undefined when calling addAdminMemberRoleUsingPOST1().'
            );
        }

        if (requestParameters['pointManagement'] == null) {
            throw new runtime.RequiredError(
                'pointManagement',
                'Required parameter "pointManagement" was null or undefined when calling addAdminMemberRoleUsingPOST1().'
            );
        }

        if (requestParameters['admin'] == null) {
            throw new runtime.RequiredError(
                'admin',
                'Required parameter "admin" was null or undefined when calling addAdminMemberRoleUsingPOST1().'
            );
        }

        if (requestParameters['orderManagement'] == null) {
            throw new runtime.RequiredError(
                'orderManagement',
                'Required parameter "orderManagement" was null or undefined when calling addAdminMemberRoleUsingPOST1().'
            );
        }

        if (requestParameters['orderManagementRead'] == null) {
            throw new runtime.RequiredError(
                'orderManagementRead',
                'Required parameter "orderManagementRead" was null or undefined when calling addAdminMemberRoleUsingPOST1().'
            );
        }

        if (requestParameters['stockLevelManagement'] == null) {
            throw new runtime.RequiredError(
                'stockLevelManagement',
                'Required parameter "stockLevelManagement" was null or undefined when calling addAdminMemberRoleUsingPOST1().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        if (requestParameters['adminMemberRoleName'] != null) {
            queryParameters['adminMemberRoleName'] = requestParameters['adminMemberRoleName'];
        }

        if (requestParameters['productManagement'] != null) {
            queryParameters['productManagement'] = requestParameters['productManagement'];
        }

        if (requestParameters['inventoryManagement'] != null) {
            queryParameters['inventoryManagement'] = requestParameters['inventoryManagement'];
        }

        if (requestParameters['articleManagement'] != null) {
            queryParameters['articleManagement'] = requestParameters['articleManagement'];
        }

        if (requestParameters['productPriceManagement'] != null) {
            queryParameters['productPriceManagement'] = requestParameters['productPriceManagement'];
        }

        if (requestParameters['discountManagement'] != null) {
            queryParameters['discountManagement'] = requestParameters['discountManagement'];
        }

        if (requestParameters['marketingEventManagement'] != null) {
            queryParameters['marketingEventManagement'] = requestParameters['marketingEventManagement'];
        }

        if (requestParameters['membershipManagement'] != null) {
            queryParameters['membershipManagement'] = requestParameters['membershipManagement'];
        }

        if (requestParameters['membershipManagementRead'] != null) {
            queryParameters['membershipManagementRead'] = requestParameters['membershipManagementRead'];
        }

        if (requestParameters['pointManagement'] != null) {
            queryParameters['pointManagement'] = requestParameters['pointManagement'];
        }

        if (requestParameters['admin'] != null) {
            queryParameters['admin'] = requestParameters['admin'];
        }

        if (requestParameters['orderManagement'] != null) {
            queryParameters['orderManagement'] = requestParameters['orderManagement'];
        }

        if (requestParameters['orderManagementRead'] != null) {
            queryParameters['orderManagementRead'] = requestParameters['orderManagementRead'];
        }

        if (requestParameters['stockLevelManagement'] != null) {
            queryParameters['stockLevelManagement'] = requestParameters['stockLevelManagement'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/adminMemberRole/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AdminMemberRoleDtoFromJSON(jsonValue));
    }

    /**
     * addAdminMemberRole
     */
    async addAdminMemberRoleUsingPOST1(requestParameters: AddAdminMemberRoleUsingPOST1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AdminMemberRoleDto | null | undefined > {
        const response = await this.addAdminMemberRoleUsingPOST1Raw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 201:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * deleteAdminMemberRole
     */
    async deleteAdminMemberRoleUsingDELETERaw(requestParameters: DeleteAdminMemberRoleUsingDELETERequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['adminMemberRoleId'] == null) {
            throw new runtime.RequiredError(
                'adminMemberRoleId',
                'Required parameter "adminMemberRoleId" was null or undefined when calling deleteAdminMemberRoleUsingDELETE().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/adminMemberRole/{adminMemberRoleId}`.replace(`{${"adminMemberRoleId"}}`, encodeURIComponent(String(requestParameters['adminMemberRoleId']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * deleteAdminMemberRole
     */
    async deleteAdminMemberRoleUsingDELETE(requestParameters: DeleteAdminMemberRoleUsingDELETERequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteAdminMemberRoleUsingDELETERaw(requestParameters, initOverrides);
    }

    /**
     * getAdminMemberRole
     */
    async getAdminMemberRoleUsingGETRaw(requestParameters: GetAdminMemberRoleUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AdminMemberRoleDto>> {
        if (requestParameters['adminMemberRoleId'] == null) {
            throw new runtime.RequiredError(
                'adminMemberRoleId',
                'Required parameter "adminMemberRoleId" was null or undefined when calling getAdminMemberRoleUsingGET().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/adminMemberRole/{adminMemberRoleId}`.replace(`{${"adminMemberRoleId"}}`, encodeURIComponent(String(requestParameters['adminMemberRoleId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AdminMemberRoleDtoFromJSON(jsonValue));
    }

    /**
     * getAdminMemberRole
     */
    async getAdminMemberRoleUsingGET(requestParameters: GetAdminMemberRoleUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AdminMemberRoleDto> {
        const response = await this.getAdminMemberRoleUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getAllAdminMemberRole
     */
    async getAllAdminMemberRoleUsingGETRaw(requestParameters: GetAllAdminMemberRoleUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<AdminMemberRoleDto>>> {
        const queryParameters: any = {};

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/adminMemberRole/all`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(AdminMemberRoleDtoFromJSON));
    }

    /**
     * getAllAdminMemberRole
     */
    async getAllAdminMemberRoleUsingGET(requestParameters: GetAllAdminMemberRoleUsingGETRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<AdminMemberRoleDto>> {
        const response = await this.getAllAdminMemberRoleUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * updateAdminMemberRole
     */
    async updateAdminMemberRoleUsingPUTRaw(requestParameters: UpdateAdminMemberRoleUsingPUTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AdminMemberRoleDto>> {
        if (requestParameters['adminMemberRoleId'] == null) {
            throw new runtime.RequiredError(
                'adminMemberRoleId',
                'Required parameter "adminMemberRoleId" was null or undefined when calling updateAdminMemberRoleUsingPUT().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        if (requestParameters['adminMemberRoleName'] != null) {
            queryParameters['adminMemberRoleName'] = requestParameters['adminMemberRoleName'];
        }

        if (requestParameters['productManagement'] != null) {
            queryParameters['productManagement'] = requestParameters['productManagement'];
        }

        if (requestParameters['inventoryManagement'] != null) {
            queryParameters['inventoryManagement'] = requestParameters['inventoryManagement'];
        }

        if (requestParameters['articleManagement'] != null) {
            queryParameters['articleManagement'] = requestParameters['articleManagement'];
        }

        if (requestParameters['productPriceManagement'] != null) {
            queryParameters['productPriceManagement'] = requestParameters['productPriceManagement'];
        }

        if (requestParameters['discountManagement'] != null) {
            queryParameters['discountManagement'] = requestParameters['discountManagement'];
        }

        if (requestParameters['marketingEventManagement'] != null) {
            queryParameters['marketingEventManagement'] = requestParameters['marketingEventManagement'];
        }

        if (requestParameters['membershipManagement'] != null) {
            queryParameters['membershipManagement'] = requestParameters['membershipManagement'];
        }

        if (requestParameters['membershipManagementRead'] != null) {
            queryParameters['membershipManagementRead'] = requestParameters['membershipManagementRead'];
        }

        if (requestParameters['pointManagement'] != null) {
            queryParameters['pointManagement'] = requestParameters['pointManagement'];
        }

        if (requestParameters['admin'] != null) {
            queryParameters['admin'] = requestParameters['admin'];
        }

        if (requestParameters['orderManagement'] != null) {
            queryParameters['orderManagement'] = requestParameters['orderManagement'];
        }

        if (requestParameters['orderManagementRead'] != null) {
            queryParameters['orderManagementRead'] = requestParameters['orderManagementRead'];
        }

        if (requestParameters['stockLevelManagement'] != null) {
            queryParameters['stockLevelManagement'] = requestParameters['stockLevelManagement'];
        }

        if (requestParameters['reportManagement'] != null) {
            queryParameters['reportManagement'] = requestParameters['reportManagement'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/adminMemberRole/{adminMemberRoleId}`.replace(`{${"adminMemberRoleId"}}`, encodeURIComponent(String(requestParameters['adminMemberRoleId']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AdminMemberRoleDtoFromJSON(jsonValue));
    }

    /**
     * updateAdminMemberRole
     */
    async updateAdminMemberRoleUsingPUT(requestParameters: UpdateAdminMemberRoleUsingPUTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AdminMemberRoleDto | null | undefined > {
        const response = await this.updateAdminMemberRoleUsingPUTRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 201:
                return null;
            default:
                return await response.value();
        }
    }

}
