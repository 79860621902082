/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Discount } from './Discount';
import {
    DiscountFromJSON,
    DiscountFromJSONTyped,
    DiscountToJSON,
} from './Discount';
import type { OrderProduct } from './OrderProduct';
import {
    OrderProductFromJSON,
    OrderProductFromJSONTyped,
    OrderProductToJSON,
} from './OrderProduct';

/**
 * 
 * @export
 * @interface OrderProductDiscount
 */
export interface OrderProductDiscount {
    /**
     * 
     * @type {Discount}
     * @memberof OrderProductDiscount
     */
    discountByDiscountId?: Discount;
    /**
     * 
     * @type {number}
     * @memberof OrderProductDiscount
     */
    discountId?: number;
    /**
     * 
     * @type {OrderProduct}
     * @memberof OrderProductDiscount
     */
    orderProductByOrderProductId?: OrderProduct;
    /**
     * 
     * @type {number}
     * @memberof OrderProductDiscount
     */
    orderProductId?: number;
}

/**
 * Check if a given object implements the OrderProductDiscount interface.
 */
export function instanceOfOrderProductDiscount(value: object): boolean {
    return true;
}

export function OrderProductDiscountFromJSON(json: any): OrderProductDiscount {
    return OrderProductDiscountFromJSONTyped(json, false);
}

export function OrderProductDiscountFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrderProductDiscount {
    if (json == null) {
        return json;
    }
    return {
        
        'discountByDiscountId': json['discountByDiscountId'] == null ? undefined : DiscountFromJSON(json['discountByDiscountId']),
        'discountId': json['discountId'] == null ? undefined : json['discountId'],
        'orderProductByOrderProductId': json['orderProductByOrderProductId'] == null ? undefined : OrderProductFromJSON(json['orderProductByOrderProductId']),
        'orderProductId': json['orderProductId'] == null ? undefined : json['orderProductId'],
    };
}

export function OrderProductDiscountToJSON(value?: OrderProductDiscount | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'discountByDiscountId': DiscountToJSON(value['discountByDiscountId']),
        'discountId': value['discountId'],
        'orderProductByOrderProductId': OrderProductToJSON(value['orderProductByOrderProductId']),
        'orderProductId': value['orderProductId'],
    };
}

