/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { CategoryDto } from './CategoryDto';
import {
    CategoryDtoFromJSON,
    CategoryDtoFromJSONTyped,
    CategoryDtoToJSON,
} from './CategoryDto';
import type { DisplayProductDto } from './DisplayProductDto';
import {
    DisplayProductDtoFromJSON,
    DisplayProductDtoFromJSONTyped,
    DisplayProductDtoToJSON,
} from './DisplayProductDto';
import type { HashtagDto } from './HashtagDto';
import {
    HashtagDtoFromJSON,
    HashtagDtoFromJSONTyped,
    HashtagDtoToJSON,
} from './HashtagDto';
import type { MarketingEventDtoLite } from './MarketingEventDtoLite';
import {
    MarketingEventDtoLiteFromJSON,
    MarketingEventDtoLiteFromJSONTyped,
    MarketingEventDtoLiteToJSON,
} from './MarketingEventDtoLite';

/**
 * 
 * @export
 * @interface ArticleDto
 */
export interface ArticleDto {
    /**
     * 
     * @type {number}
     * @memberof ArticleDto
     */
    articleCategory?: number;
    /**
     * 
     * @type {string}
     * @memberof ArticleDto
     */
    articleContent?: string;
    /**
     * 
     * @type {string}
     * @memberof ArticleDto
     */
    articleCoverImage?: string;
    /**
     * 
     * @type {number}
     * @memberof ArticleDto
     */
    articleId?: number;
    /**
     * 
     * @type {string}
     * @memberof ArticleDto
     */
    articleTitle?: string;
    /**
     * 
     * @type {CategoryDto}
     * @memberof ArticleDto
     */
    category?: CategoryDto;
    /**
     * 
     * @type {Array<DisplayProductDto>}
     * @memberof ArticleDto
     */
    displayProducts?: Array<DisplayProductDto>;
    /**
     * 
     * @type {Array<HashtagDto>}
     * @memberof ArticleDto
     */
    hashtags?: Array<HashtagDto>;
    /**
     * 
     * @type {MarketingEventDtoLite}
     * @memberof ArticleDto
     */
    marketingEvent?: MarketingEventDtoLite;
    /**
     * 
     * @type {Date}
     * @memberof ArticleDto
     */
    offShelfDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof ArticleDto
     */
    onShelfDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof ArticleDto
     */
    status?: string;
}

/**
 * Check if a given object implements the ArticleDto interface.
 */
export function instanceOfArticleDto(value: object): boolean {
    return true;
}

export function ArticleDtoFromJSON(json: any): ArticleDto {
    return ArticleDtoFromJSONTyped(json, false);
}

export function ArticleDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ArticleDto {
    if (json == null) {
        return json;
    }
    return {
        
        'articleCategory': json['articleCategory'] == null ? undefined : json['articleCategory'],
        'articleContent': json['articleContent'] == null ? undefined : json['articleContent'],
        'articleCoverImage': json['articleCoverImage'] == null ? undefined : json['articleCoverImage'],
        'articleId': json['articleId'] == null ? undefined : json['articleId'],
        'articleTitle': json['articleTitle'] == null ? undefined : json['articleTitle'],
        'category': json['category'] == null ? undefined : CategoryDtoFromJSON(json['category']),
        'displayProducts': json['displayProducts'] == null ? undefined : ((json['displayProducts'] as Array<any>).map(DisplayProductDtoFromJSON)),
        'hashtags': json['hashtags'] == null ? undefined : ((json['hashtags'] as Array<any>).map(HashtagDtoFromJSON)),
        'marketingEvent': json['marketingEvent'] == null ? undefined : MarketingEventDtoLiteFromJSON(json['marketingEvent']),
        'offShelfDate': json['offShelfDate'] == null ? undefined : (new Date(json['offShelfDate'])),
        'onShelfDate': json['onShelfDate'] == null ? undefined : (new Date(json['onShelfDate'])),
        'status': json['status'] == null ? undefined : json['status'],
    };
}

export function ArticleDtoToJSON(value?: ArticleDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'articleCategory': value['articleCategory'],
        'articleContent': value['articleContent'],
        'articleCoverImage': value['articleCoverImage'],
        'articleId': value['articleId'],
        'articleTitle': value['articleTitle'],
        'category': CategoryDtoToJSON(value['category']),
        'displayProducts': value['displayProducts'] == null ? undefined : ((value['displayProducts'] as Array<any>).map(DisplayProductDtoToJSON)),
        'hashtags': value['hashtags'] == null ? undefined : ((value['hashtags'] as Array<any>).map(HashtagDtoToJSON)),
        'marketingEvent': MarketingEventDtoLiteToJSON(value['marketingEvent']),
        'offShelfDate': value['offShelfDate'] == null ? undefined : ((value['offShelfDate']).toISOString()),
        'onShelfDate': value['onShelfDate'] == null ? undefined : ((value['onShelfDate']).toISOString()),
        'status': value['status'],
    };
}

