/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Product } from './Product';
import {
    ProductFromJSON,
    ProductFromJSONTyped,
    ProductToJSON,
} from './Product';

/**
 * 
 * @export
 * @interface GroupProduct
 */
export interface GroupProduct {
    /**
     * 
     * @type {number}
     * @memberof GroupProduct
     */
    groupProductId?: number;
    /**
     * 
     * @type {Product}
     * @memberof GroupProduct
     */
    productByGroupProductId?: Product;
    /**
     * 
     * @type {Product}
     * @memberof GroupProduct
     */
    productByProductId?: Product;
    /**
     * 
     * @type {number}
     * @memberof GroupProduct
     */
    productId?: number;
}

/**
 * Check if a given object implements the GroupProduct interface.
 */
export function instanceOfGroupProduct(value: object): boolean {
    return true;
}

export function GroupProductFromJSON(json: any): GroupProduct {
    return GroupProductFromJSONTyped(json, false);
}

export function GroupProductFromJSONTyped(json: any, ignoreDiscriminator: boolean): GroupProduct {
    if (json == null) {
        return json;
    }
    return {
        
        'groupProductId': json['groupProductId'] == null ? undefined : json['groupProductId'],
        'productByGroupProductId': json['productByGroupProductId'] == null ? undefined : ProductFromJSON(json['productByGroupProductId']),
        'productByProductId': json['productByProductId'] == null ? undefined : ProductFromJSON(json['productByProductId']),
        'productId': json['productId'] == null ? undefined : json['productId'],
    };
}

export function GroupProductToJSON(value?: GroupProduct | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'groupProductId': value['groupProductId'],
        'productByGroupProductId': ProductToJSON(value['productByGroupProductId']),
        'productByProductId': ProductToJSON(value['productByProductId']),
        'productId': value['productId'],
    };
}

