/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { MemberLevelDtoLite } from './MemberLevelDtoLite';
import {
    MemberLevelDtoLiteFromJSON,
    MemberLevelDtoLiteFromJSONTyped,
    MemberLevelDtoLiteToJSON,
} from './MemberLevelDtoLite';

/**
 * 
 * @export
 * @interface MembershipRuleDto
 */
export interface MembershipRuleDto {
    /**
     * 
     * @type {number}
     * @memberof MembershipRuleDto
     */
    cashRequire?: number;
    /**
     * 
     * @type {MemberLevelDtoLite}
     * @memberof MembershipRuleDto
     */
    memberLevel?: MemberLevelDtoLite;
    /**
     * 
     * @type {number}
     * @memberof MembershipRuleDto
     */
    memberLevelId?: number;
    /**
     * 
     * @type {number}
     * @memberof MembershipRuleDto
     */
    membershipRuleId?: number;
    /**
     * 
     * @type {string}
     * @memberof MembershipRuleDto
     */
    period?: string;
    /**
     * 
     * @type {number}
     * @memberof MembershipRuleDto
     */
    pointRequire?: number;
}

/**
 * Check if a given object implements the MembershipRuleDto interface.
 */
export function instanceOfMembershipRuleDto(value: object): boolean {
    return true;
}

export function MembershipRuleDtoFromJSON(json: any): MembershipRuleDto {
    return MembershipRuleDtoFromJSONTyped(json, false);
}

export function MembershipRuleDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): MembershipRuleDto {
    if (json == null) {
        return json;
    }
    return {
        
        'cashRequire': json['cashRequire'] == null ? undefined : json['cashRequire'],
        'memberLevel': json['memberLevel'] == null ? undefined : MemberLevelDtoLiteFromJSON(json['memberLevel']),
        'memberLevelId': json['memberLevelId'] == null ? undefined : json['memberLevelId'],
        'membershipRuleId': json['membershipRuleId'] == null ? undefined : json['membershipRuleId'],
        'period': json['period'] == null ? undefined : json['period'],
        'pointRequire': json['pointRequire'] == null ? undefined : json['pointRequire'],
    };
}

export function MembershipRuleDtoToJSON(value?: MembershipRuleDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'cashRequire': value['cashRequire'],
        'memberLevel': MemberLevelDtoLiteToJSON(value['memberLevel']),
        'memberLevelId': value['memberLevelId'],
        'membershipRuleId': value['membershipRuleId'],
        'period': value['period'],
        'pointRequire': value['pointRequire'],
    };
}

