/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  BookShelf,
  ProductDto,
} from '../models/index';
import {
    BookShelfFromJSON,
    BookShelfToJSON,
    ProductDtoFromJSON,
    ProductDtoToJSON,
} from '../models/index';

export interface GetByMemberIdUsingGETRequest {
    memberId: number;
}

/**
 * 
 */
export class BookShelfControllerApi extends runtime.BaseAPI {

    /**
     * getAll
     */
    async getAllUsingGETRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<BookShelf>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/book_shelf/all`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(BookShelfFromJSON));
    }

    /**
     * getAll
     */
    async getAllUsingGET(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<BookShelf>> {
        const response = await this.getAllUsingGETRaw(initOverrides);
        return await response.value();
    }

    /**
     * getByMemberId
     */
    async getByMemberIdUsingGETRaw(requestParameters: GetByMemberIdUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ProductDto>>> {
        if (requestParameters['memberId'] == null) {
            throw new runtime.RequiredError(
                'memberId',
                'Required parameter "memberId" was null or undefined when calling getByMemberIdUsingGET().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/book_shelf/getByMemberId/{memberId}`.replace(`{${"memberId"}}`, encodeURIComponent(String(requestParameters['memberId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ProductDtoFromJSON));
    }

    /**
     * getByMemberId
     */
    async getByMemberIdUsingGET(requestParameters: GetByMemberIdUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ProductDto>> {
        const response = await this.getByMemberIdUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
