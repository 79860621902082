/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Gospel
 */
export interface Gospel {
    /**
     * 
     * @type {string}
     * @memberof Gospel
     */
    chnContent?: string;
    /**
     * 
     * @type {string}
     * @memberof Gospel
     */
    chnDateString?: string;
    /**
     * 
     * @type {Date}
     * @memberof Gospel
     */
    date?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof Gospel
     */
    enable?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Gospel
     */
    engContent?: string;
    /**
     * 
     * @type {string}
     * @memberof Gospel
     */
    engDateString?: string;
    /**
     * 
     * @type {number}
     * @memberof Gospel
     */
    gospelId?: number;
}

/**
 * Check if a given object implements the Gospel interface.
 */
export function instanceOfGospel(value: object): boolean {
    return true;
}

export function GospelFromJSON(json: any): Gospel {
    return GospelFromJSONTyped(json, false);
}

export function GospelFromJSONTyped(json: any, ignoreDiscriminator: boolean): Gospel {
    if (json == null) {
        return json;
    }
    return {
        
        'chnContent': json['chnContent'] == null ? undefined : json['chnContent'],
        'chnDateString': json['chnDateString'] == null ? undefined : json['chnDateString'],
        'date': json['date'] == null ? undefined : (new Date(json['date'])),
        'enable': json['enable'] == null ? undefined : json['enable'],
        'engContent': json['engContent'] == null ? undefined : json['engContent'],
        'engDateString': json['engDateString'] == null ? undefined : json['engDateString'],
        'gospelId': json['gospelId'] == null ? undefined : json['gospelId'],
    };
}

export function GospelToJSON(value?: Gospel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'chnContent': value['chnContent'],
        'chnDateString': value['chnDateString'],
        'date': value['date'] == null ? undefined : ((value['date']).toISOString()),
        'enable': value['enable'],
        'engContent': value['engContent'],
        'engDateString': value['engDateString'],
        'gospelId': value['gospelId'],
    };
}

