/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MemberLevelDtoLite
 */
export interface MemberLevelDtoLite {
    /**
     * 
     * @type {number}
     * @memberof MemberLevelDtoLite
     */
    memberLevelId?: number;
    /**
     * 
     * @type {string}
     * @memberof MemberLevelDtoLite
     */
    membershipName?: string;
    /**
     * 
     * @type {string}
     * @memberof MemberLevelDtoLite
     */
    membershipPeriod?: string;
    /**
     * 
     * @type {string}
     * @memberof MemberLevelDtoLite
     */
    order?: string;
}

/**
 * Check if a given object implements the MemberLevelDtoLite interface.
 */
export function instanceOfMemberLevelDtoLite(value: object): boolean {
    return true;
}

export function MemberLevelDtoLiteFromJSON(json: any): MemberLevelDtoLite {
    return MemberLevelDtoLiteFromJSONTyped(json, false);
}

export function MemberLevelDtoLiteFromJSONTyped(json: any, ignoreDiscriminator: boolean): MemberLevelDtoLite {
    if (json == null) {
        return json;
    }
    return {
        
        'memberLevelId': json['memberLevelId'] == null ? undefined : json['memberLevelId'],
        'membershipName': json['membershipName'] == null ? undefined : json['membershipName'],
        'membershipPeriod': json['membershipPeriod'] == null ? undefined : json['membershipPeriod'],
        'order': json['order'] == null ? undefined : json['order'],
    };
}

export function MemberLevelDtoLiteToJSON(value?: MemberLevelDtoLite | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'memberLevelId': value['memberLevelId'],
        'membershipName': value['membershipName'],
        'membershipPeriod': value['membershipPeriod'],
        'order': value['order'],
    };
}

