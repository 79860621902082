/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { MemberLevel } from './MemberLevel';
import {
    MemberLevelFromJSON,
    MemberLevelFromJSONTyped,
    MemberLevelToJSON,
} from './MemberLevel';

/**
 * 
 * @export
 * @interface MembershipRule
 */
export interface MembershipRule {
    /**
     * 
     * @type {number}
     * @memberof MembershipRule
     */
    cashRequire?: number;
    /**
     * 
     * @type {MemberLevel}
     * @memberof MembershipRule
     */
    memberLevelByMemberLevelId?: MemberLevel;
    /**
     * 
     * @type {number}
     * @memberof MembershipRule
     */
    memberLevelId?: number;
    /**
     * 
     * @type {number}
     * @memberof MembershipRule
     */
    membershipRuleId?: number;
    /**
     * 
     * @type {string}
     * @memberof MembershipRule
     */
    period?: string;
    /**
     * 
     * @type {number}
     * @memberof MembershipRule
     */
    pointRequire?: number;
}

/**
 * Check if a given object implements the MembershipRule interface.
 */
export function instanceOfMembershipRule(value: object): boolean {
    return true;
}

export function MembershipRuleFromJSON(json: any): MembershipRule {
    return MembershipRuleFromJSONTyped(json, false);
}

export function MembershipRuleFromJSONTyped(json: any, ignoreDiscriminator: boolean): MembershipRule {
    if (json == null) {
        return json;
    }
    return {
        
        'cashRequire': json['cashRequire'] == null ? undefined : json['cashRequire'],
        'memberLevelByMemberLevelId': json['memberLevelByMemberLevelId'] == null ? undefined : MemberLevelFromJSON(json['memberLevelByMemberLevelId']),
        'memberLevelId': json['memberLevelId'] == null ? undefined : json['memberLevelId'],
        'membershipRuleId': json['membershipRuleId'] == null ? undefined : json['membershipRuleId'],
        'period': json['period'] == null ? undefined : json['period'],
        'pointRequire': json['pointRequire'] == null ? undefined : json['pointRequire'],
    };
}

export function MembershipRuleToJSON(value?: MembershipRule | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'cashRequire': value['cashRequire'],
        'memberLevelByMemberLevelId': MemberLevelToJSON(value['memberLevelByMemberLevelId']),
        'memberLevelId': value['memberLevelId'],
        'membershipRuleId': value['membershipRuleId'],
        'period': value['period'],
        'pointRequire': value['pointRequire'],
    };
}

