/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  MarketingEventDto,
} from '../models/index';
import {
    MarketingEventDtoFromJSON,
    MarketingEventDtoToJSON,
} from '../models/index';

export interface AddMarkketingEventUsingPOSTRequest {
    isMegaSale: string;
    marketingEventName: string;
    discountId: number;
    name?: string;
    promotionCode?: string;
    memberIds?: number;
    bannerContent?: string;
    mobileBannerContent?: string;
    expireBannerContent?: string;
    mobileExpireBannerContent?: string;
    bannerPosition?: number;
    bannerLinkTo?: string;
    memberLevelIds?: number;
    remark?: string;
    body?: Date;
}

export interface DeleteProductUsingPOST1Request {
    marketingEventId: number;
    name?: string;
}

export interface GetAllMarketEventWithCouponCodeAdminUsingGETRequest {
    name?: string;
}

export interface GetAllMarketingEventAdminUsingGETRequest {
    name?: string;
}

export interface GetAllMarketingEventBannerAdminUsingGETRequest {
    name?: string;
}

export interface GetMarketingEventByMarketingEventIdAdminUsingGETRequest {
    marketingEventId: number;
    name?: string;
}

export interface GetMarketingEventByMarketingEventIdUsingGETRequest {
    marketingEventId: number;
}

export interface UpdateMarkketingEventUsingPOSTRequest {
    marketingEventId: number;
    isMegaSale: string;
    marketingEventName: string;
    discountId: number;
    name?: string;
    promotionCode?: string;
    marketingEventPriority?: number;
    memberIds?: number;
    bannerContent?: string;
    mobileBannerContent?: string;
    expireBannerContent?: string;
    mobileExpireBannerContent?: string;
    bannerPosition?: number;
    bannerLinkTo?: string;
    memberLevelIds?: number;
    remark?: string;
    body?: Date;
}

/**
 * 
 */
export class MarketingEventControllerApi extends runtime.BaseAPI {

    /**
     * addMarkketingEvent
     */
    async addMarkketingEventUsingPOSTRaw(requestParameters: AddMarkketingEventUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MarketingEventDto>> {
        if (requestParameters['isMegaSale'] == null) {
            throw new runtime.RequiredError(
                'isMegaSale',
                'Required parameter "isMegaSale" was null or undefined when calling addMarkketingEventUsingPOST().'
            );
        }

        if (requestParameters['marketingEventName'] == null) {
            throw new runtime.RequiredError(
                'marketingEventName',
                'Required parameter "marketingEventName" was null or undefined when calling addMarkketingEventUsingPOST().'
            );
        }

        if (requestParameters['discountId'] == null) {
            throw new runtime.RequiredError(
                'discountId',
                'Required parameter "discountId" was null or undefined when calling addMarkketingEventUsingPOST().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        if (requestParameters['promotionCode'] != null) {
            queryParameters['promotionCode'] = requestParameters['promotionCode'];
        }

        if (requestParameters['isMegaSale'] != null) {
            queryParameters['isMegaSale'] = requestParameters['isMegaSale'];
        }

        if (requestParameters['marketingEventName'] != null) {
            queryParameters['marketingEventName'] = requestParameters['marketingEventName'];
        }

        if (requestParameters['discountId'] != null) {
            queryParameters['discountId'] = requestParameters['discountId'];
        }

        if (requestParameters['memberIds'] != null) {
            queryParameters['memberIds'] = requestParameters['memberIds'];
        }

        if (requestParameters['bannerContent'] != null) {
            queryParameters['bannerContent'] = requestParameters['bannerContent'];
        }

        if (requestParameters['mobileBannerContent'] != null) {
            queryParameters['mobileBannerContent'] = requestParameters['mobileBannerContent'];
        }

        if (requestParameters['expireBannerContent'] != null) {
            queryParameters['expireBannerContent'] = requestParameters['expireBannerContent'];
        }

        if (requestParameters['mobileExpireBannerContent'] != null) {
            queryParameters['mobileExpireBannerContent'] = requestParameters['mobileExpireBannerContent'];
        }

        if (requestParameters['bannerPosition'] != null) {
            queryParameters['bannerPosition'] = requestParameters['bannerPosition'];
        }

        if (requestParameters['bannerLinkTo'] != null) {
            queryParameters['bannerLinkTo'] = requestParameters['bannerLinkTo'];
        }

        if (requestParameters['memberLevelIds'] != null) {
            queryParameters['memberLevelIds'] = requestParameters['memberLevelIds'];
        }

        if (requestParameters['remark'] != null) {
            queryParameters['remark'] = requestParameters['remark'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/marketingEvent/add`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters['body'] as any,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MarketingEventDtoFromJSON(jsonValue));
    }

    /**
     * addMarkketingEvent
     */
    async addMarkketingEventUsingPOST(requestParameters: AddMarkketingEventUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MarketingEventDto | null | undefined > {
        const response = await this.addMarkketingEventUsingPOSTRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 201:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * deleteProduct
     */
    async deleteProductUsingPOST1Raw(requestParameters: DeleteProductUsingPOST1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MarketingEventDto>> {
        if (requestParameters['marketingEventId'] == null) {
            throw new runtime.RequiredError(
                'marketingEventId',
                'Required parameter "marketingEventId" was null or undefined when calling deleteProductUsingPOST1().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/marketingEvent/delete/{marketingEventId}`.replace(`{${"marketingEventId"}}`, encodeURIComponent(String(requestParameters['marketingEventId']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MarketingEventDtoFromJSON(jsonValue));
    }

    /**
     * deleteProduct
     */
    async deleteProductUsingPOST1(requestParameters: DeleteProductUsingPOST1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MarketingEventDto | null | undefined > {
        const response = await this.deleteProductUsingPOST1Raw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 201:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * getAllMarketEventWithCouponCodeAdmin
     */
    async getAllMarketEventWithCouponCodeAdminUsingGETRaw(requestParameters: GetAllMarketEventWithCouponCodeAdminUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<MarketingEventDto>>> {
        const queryParameters: any = {};

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/marketingEvent/admin/allWithCouponCode`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(MarketingEventDtoFromJSON));
    }

    /**
     * getAllMarketEventWithCouponCodeAdmin
     */
    async getAllMarketEventWithCouponCodeAdminUsingGET(requestParameters: GetAllMarketEventWithCouponCodeAdminUsingGETRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<MarketingEventDto>> {
        const response = await this.getAllMarketEventWithCouponCodeAdminUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getAllMarketEventWithCouponCode
     */
    async getAllMarketEventWithCouponCodeUsingGETRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<MarketingEventDto>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/marketingEvent/allWithCouponCode`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(MarketingEventDtoFromJSON));
    }

    /**
     * getAllMarketEventWithCouponCode
     */
    async getAllMarketEventWithCouponCodeUsingGET(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<MarketingEventDto>> {
        const response = await this.getAllMarketEventWithCouponCodeUsingGETRaw(initOverrides);
        return await response.value();
    }

    /**
     * getAllMarketingEventAdmin
     */
    async getAllMarketingEventAdminUsingGETRaw(requestParameters: GetAllMarketingEventAdminUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<MarketingEventDto>>> {
        const queryParameters: any = {};

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/marketingEvent/admin/all`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(MarketingEventDtoFromJSON));
    }

    /**
     * getAllMarketingEventAdmin
     */
    async getAllMarketingEventAdminUsingGET(requestParameters: GetAllMarketingEventAdminUsingGETRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<MarketingEventDto>> {
        const response = await this.getAllMarketingEventAdminUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getAllMarketingEventBannerAdmin
     */
    async getAllMarketingEventBannerAdminUsingGETRaw(requestParameters: GetAllMarketingEventBannerAdminUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<{ [key: string]: object; }>>> {
        const queryParameters: any = {};

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/marketingEvent/admin/banner/all`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * getAllMarketingEventBannerAdmin
     */
    async getAllMarketingEventBannerAdminUsingGET(requestParameters: GetAllMarketingEventBannerAdminUsingGETRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<{ [key: string]: object; }>> {
        const response = await this.getAllMarketingEventBannerAdminUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getAllMarketingEventBanner
     */
    async getAllMarketingEventBannerUsingGETRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<{ [key: string]: object; }>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/marketingEvent/banner/all`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * getAllMarketingEventBanner
     */
    async getAllMarketingEventBannerUsingGET(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<{ [key: string]: object; }>> {
        const response = await this.getAllMarketingEventBannerUsingGETRaw(initOverrides);
        return await response.value();
    }

    /**
     * getAllMarketingEventBannerWithPriority
     */
    async getAllMarketingEventBannerWithPriorityUsingGETRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<{ [key: string]: object; }>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/marketingEvent/banner/allWithPriority`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * getAllMarketingEventBannerWithPriority
     */
    async getAllMarketingEventBannerWithPriorityUsingGET(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<{ [key: string]: object; }>> {
        const response = await this.getAllMarketingEventBannerWithPriorityUsingGETRaw(initOverrides);
        return await response.value();
    }

    /**
     * getAllMarketingEvent
     */
    async getAllMarketingEventUsingGETRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<MarketingEventDto>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/marketingEvent/all`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(MarketingEventDtoFromJSON));
    }

    /**
     * getAllMarketingEvent
     */
    async getAllMarketingEventUsingGET(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<MarketingEventDto>> {
        const response = await this.getAllMarketingEventUsingGETRaw(initOverrides);
        return await response.value();
    }

    /**
     * getMarketingEventByMarketingEventIdAdmin
     */
    async getMarketingEventByMarketingEventIdAdminUsingGETRaw(requestParameters: GetMarketingEventByMarketingEventIdAdminUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MarketingEventDto>> {
        if (requestParameters['marketingEventId'] == null) {
            throw new runtime.RequiredError(
                'marketingEventId',
                'Required parameter "marketingEventId" was null or undefined when calling getMarketingEventByMarketingEventIdAdminUsingGET().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/marketingEvent/admin/{marketingEventId}`.replace(`{${"marketingEventId"}}`, encodeURIComponent(String(requestParameters['marketingEventId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MarketingEventDtoFromJSON(jsonValue));
    }

    /**
     * getMarketingEventByMarketingEventIdAdmin
     */
    async getMarketingEventByMarketingEventIdAdminUsingGET(requestParameters: GetMarketingEventByMarketingEventIdAdminUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MarketingEventDto> {
        const response = await this.getMarketingEventByMarketingEventIdAdminUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getMarketingEventByMarketingEventId
     */
    async getMarketingEventByMarketingEventIdUsingGETRaw(requestParameters: GetMarketingEventByMarketingEventIdUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MarketingEventDto>> {
        if (requestParameters['marketingEventId'] == null) {
            throw new runtime.RequiredError(
                'marketingEventId',
                'Required parameter "marketingEventId" was null or undefined when calling getMarketingEventByMarketingEventIdUsingGET().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/marketingEvent/{marketingEventId}`.replace(`{${"marketingEventId"}}`, encodeURIComponent(String(requestParameters['marketingEventId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MarketingEventDtoFromJSON(jsonValue));
    }

    /**
     * getMarketingEventByMarketingEventId
     */
    async getMarketingEventByMarketingEventIdUsingGET(requestParameters: GetMarketingEventByMarketingEventIdUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MarketingEventDto> {
        const response = await this.getMarketingEventByMarketingEventIdUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * updateMarkketingEvent
     */
    async updateMarkketingEventUsingPOSTRaw(requestParameters: UpdateMarkketingEventUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MarketingEventDto>> {
        if (requestParameters['marketingEventId'] == null) {
            throw new runtime.RequiredError(
                'marketingEventId',
                'Required parameter "marketingEventId" was null or undefined when calling updateMarkketingEventUsingPOST().'
            );
        }

        if (requestParameters['isMegaSale'] == null) {
            throw new runtime.RequiredError(
                'isMegaSale',
                'Required parameter "isMegaSale" was null or undefined when calling updateMarkketingEventUsingPOST().'
            );
        }

        if (requestParameters['marketingEventName'] == null) {
            throw new runtime.RequiredError(
                'marketingEventName',
                'Required parameter "marketingEventName" was null or undefined when calling updateMarkketingEventUsingPOST().'
            );
        }

        if (requestParameters['discountId'] == null) {
            throw new runtime.RequiredError(
                'discountId',
                'Required parameter "discountId" was null or undefined when calling updateMarkketingEventUsingPOST().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        if (requestParameters['marketingEventId'] != null) {
            queryParameters['marketingEventId'] = requestParameters['marketingEventId'];
        }

        if (requestParameters['promotionCode'] != null) {
            queryParameters['promotionCode'] = requestParameters['promotionCode'];
        }

        if (requestParameters['isMegaSale'] != null) {
            queryParameters['isMegaSale'] = requestParameters['isMegaSale'];
        }

        if (requestParameters['marketingEventPriority'] != null) {
            queryParameters['marketingEventPriority'] = requestParameters['marketingEventPriority'];
        }

        if (requestParameters['marketingEventName'] != null) {
            queryParameters['marketingEventName'] = requestParameters['marketingEventName'];
        }

        if (requestParameters['discountId'] != null) {
            queryParameters['discountId'] = requestParameters['discountId'];
        }

        if (requestParameters['memberIds'] != null) {
            queryParameters['memberIds'] = requestParameters['memberIds'];
        }

        if (requestParameters['bannerContent'] != null) {
            queryParameters['bannerContent'] = requestParameters['bannerContent'];
        }

        if (requestParameters['mobileBannerContent'] != null) {
            queryParameters['mobileBannerContent'] = requestParameters['mobileBannerContent'];
        }

        if (requestParameters['expireBannerContent'] != null) {
            queryParameters['expireBannerContent'] = requestParameters['expireBannerContent'];
        }

        if (requestParameters['mobileExpireBannerContent'] != null) {
            queryParameters['mobileExpireBannerContent'] = requestParameters['mobileExpireBannerContent'];
        }

        if (requestParameters['bannerPosition'] != null) {
            queryParameters['bannerPosition'] = requestParameters['bannerPosition'];
        }

        if (requestParameters['bannerLinkTo'] != null) {
            queryParameters['bannerLinkTo'] = requestParameters['bannerLinkTo'];
        }

        if (requestParameters['memberLevelIds'] != null) {
            queryParameters['memberLevelIds'] = requestParameters['memberLevelIds'];
        }

        if (requestParameters['remark'] != null) {
            queryParameters['remark'] = requestParameters['remark'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/marketingEvent/update`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters['body'] as any,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MarketingEventDtoFromJSON(jsonValue));
    }

    /**
     * updateMarkketingEvent
     */
    async updateMarkketingEventUsingPOST(requestParameters: UpdateMarkketingEventUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MarketingEventDto | null | undefined > {
        const response = await this.updateMarkketingEventUsingPOSTRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 201:
                return null;
            default:
                return await response.value();
        }
    }

}
