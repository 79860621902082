/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Order } from './Order';
import {
    OrderFromJSON,
    OrderFromJSONTyped,
    OrderToJSON,
} from './Order';
import type { ShippingProduct } from './ShippingProduct';
import {
    ShippingProductFromJSON,
    ShippingProductFromJSONTyped,
    ShippingProductToJSON,
} from './ShippingProduct';

/**
 * 
 * @export
 * @interface Shipping
 */
export interface Shipping {
    /**
     * 
     * @type {Order}
     * @memberof Shipping
     */
    orderByOrderId?: Order;
    /**
     * 
     * @type {number}
     * @memberof Shipping
     */
    orderId?: number;
    /**
     * 
     * @type {Date}
     * @memberof Shipping
     */
    shippingDate?: Date;
    /**
     * 
     * @type {number}
     * @memberof Shipping
     */
    shippingId?: number;
    /**
     * 
     * @type {Array<ShippingProduct>}
     * @memberof Shipping
     */
    shippingProductsByShippingId?: Array<ShippingProduct>;
    /**
     * 
     * @type {string}
     * @memberof Shipping
     */
    shippingStatus?: string;
    /**
     * 
     * @type {string}
     * @memberof Shipping
     */
    trackingNumber?: string;
}

/**
 * Check if a given object implements the Shipping interface.
 */
export function instanceOfShipping(value: object): boolean {
    return true;
}

export function ShippingFromJSON(json: any): Shipping {
    return ShippingFromJSONTyped(json, false);
}

export function ShippingFromJSONTyped(json: any, ignoreDiscriminator: boolean): Shipping {
    if (json == null) {
        return json;
    }
    return {
        
        'orderByOrderId': json['orderByOrderId'] == null ? undefined : OrderFromJSON(json['orderByOrderId']),
        'orderId': json['orderId'] == null ? undefined : json['orderId'],
        'shippingDate': json['shippingDate'] == null ? undefined : (new Date(json['shippingDate'])),
        'shippingId': json['shippingId'] == null ? undefined : json['shippingId'],
        'shippingProductsByShippingId': json['shippingProductsByShippingId'] == null ? undefined : ((json['shippingProductsByShippingId'] as Array<any>).map(ShippingProductFromJSON)),
        'shippingStatus': json['shippingStatus'] == null ? undefined : json['shippingStatus'],
        'trackingNumber': json['trackingNumber'] == null ? undefined : json['trackingNumber'],
    };
}

export function ShippingToJSON(value?: Shipping | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'orderByOrderId': OrderToJSON(value['orderByOrderId']),
        'orderId': value['orderId'],
        'shippingDate': value['shippingDate'] == null ? undefined : ((value['shippingDate']).toISOString()),
        'shippingId': value['shippingId'],
        'shippingProductsByShippingId': value['shippingProductsByShippingId'] == null ? undefined : ((value['shippingProductsByShippingId'] as Array<any>).map(ShippingProductToJSON)),
        'shippingStatus': value['shippingStatus'],
        'trackingNumber': value['trackingNumber'],
    };
}

