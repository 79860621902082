/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ClassLoader } from './ClassLoader';
import {
    ClassLoaderFromJSON,
    ClassLoaderFromJSONTyped,
    ClassLoaderToJSON,
} from './ClassLoader';
import type { ModuleDescriptor } from './ModuleDescriptor';
import {
    ModuleDescriptorFromJSON,
    ModuleDescriptorFromJSONTyped,
    ModuleDescriptorToJSON,
} from './ModuleDescriptor';

/**
 * 
 * @export
 * @interface Module
 */
export interface Module {
    /**
     * 
     * @type {Array<object>}
     * @memberof Module
     */
    annotations?: Array<object>;
    /**
     * 
     * @type {ClassLoader}
     * @memberof Module
     */
    classLoader?: ClassLoader;
    /**
     * 
     * @type {Array<object>}
     * @memberof Module
     */
    declaredAnnotations?: Array<object>;
    /**
     * 
     * @type {ModuleDescriptor}
     * @memberof Module
     */
    descriptor?: ModuleDescriptor;
    /**
     * 
     * @type {object}
     * @memberof Module
     */
    layer?: object;
    /**
     * 
     * @type {string}
     * @memberof Module
     */
    name?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Module
     */
    named?: boolean;
    /**
     * 
     * @type {Set<string>}
     * @memberof Module
     */
    packages?: Set<string>;
}

/**
 * Check if a given object implements the Module interface.
 */
export function instanceOfModule(value: object): boolean {
    return true;
}

export function ModuleFromJSON(json: any): Module {
    return ModuleFromJSONTyped(json, false);
}

export function ModuleFromJSONTyped(json: any, ignoreDiscriminator: boolean): Module {
    if (json == null) {
        return json;
    }
    return {
        
        'annotations': json['annotations'] == null ? undefined : json['annotations'],
        'classLoader': json['classLoader'] == null ? undefined : ClassLoaderFromJSON(json['classLoader']),
        'declaredAnnotations': json['declaredAnnotations'] == null ? undefined : json['declaredAnnotations'],
        'descriptor': json['descriptor'] == null ? undefined : ModuleDescriptorFromJSON(json['descriptor']),
        'layer': json['layer'] == null ? undefined : json['layer'],
        'name': json['name'] == null ? undefined : json['name'],
        'named': json['named'] == null ? undefined : json['named'],
        'packages': json['packages'] == null ? undefined : json['packages'],
    };
}

export function ModuleToJSON(value?: Module | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'annotations': value['annotations'],
        'classLoader': ClassLoaderToJSON(value['classLoader']),
        'declaredAnnotations': value['declaredAnnotations'],
        'descriptor': ModuleDescriptorToJSON(value['descriptor']),
        'layer': value['layer'],
        'name': value['name'],
        'named': value['named'],
        'packages': value['packages'] == null ? undefined : Array.from(value['packages'] as Set<any>),
    };
}

