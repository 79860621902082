import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import Button from "../../components/Button/Button";
import style from "./LoginPage.module.css";
import { Path, SubmitHandler, useForm, UseFormRegister } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Input2 from "../../components/Input/Input2";
import Checkbox from "../../components/Input/Checkbox";
import { useTranslation } from "react-i18next";
import { t } from "i18next";
import * as API from "../../api/PageContentAPI";
import Facebook from "@mui/icons-material/Facebook";

const month = () => {
  let monthArray = [{ value: "", display: t("signUp.birthdayMonth") }];
  for (let i = 1; i <= 12; i++) {
    monthArray.push({ value: `${i}`, display: `${i}月` });
  }
  return monthArray;
};

export interface IFormValues {
  firstname: string;
  lastname: string;
  ageGroup: number;
  gender: string;
  verificationCode: string;
  birthday: string;
  password: string;
  email: string;
  phone: string;
  acceptPromoption: string;
  confirmPassword: string;
  district: string;
  agreement: boolean;
  agreement2: boolean;
}

// type InputProps = {
//   label: Path<IFormValues>;
//   placeholder: string;
//   register: UseFormRegister<IFormValues>;
//   required: boolean;
//   type?: string;
//   pattern?: any;
//   minLength?: any;
// };

const formSchema = yup
  .object()
  .shape({
    gender: yup
      .string()
      .required("必須選擇稱謂")
      .test("gender", "必須選擇稱謂", (value) => value !== ""),
    firstname: yup
      .string()
      .required("必須輸入姓名")
      .min(1, "姓名不能為空")
      .max(50, "姓名過長")
      .trim(),
    email: yup.string().email("電郵格式錯誤").required("必須輸入電郵"),
    verificationCode: yup
      .string()
      .required("必須選擇區號"), // 如果區號也要驗證
    phone: yup
      .string()
      .required("必須輸入電話號碼")
      .matches(/^[0-9]+$/, "電話號碼只能包含數字")
      .min(8, "電話號碼至少需要8位")
      .max(8, "電話號碼不能超過8位"),
    password: yup
      .string()
      .required("必須輸入密碼")
      .min(8, "密碼長度至少8位")
      .matches(RegExp("(.*[a-zA-Z].*)"), "必須包含字母和數字")
      .matches(RegExp("(.*\\d.*)"), "必須包含字母和數字"),
    confirmPassword: yup
      .string()
      .required("必須輸入密碼")
      .min(8, "密碼長度至少8位")
      .oneOf([yup.ref("password")], "確定密碼不吻合"),
    birthday: yup
      .string()
      .required("必須選擇生日月份")
      .test("birthday", "必須選擇生日月份", (value) => value !== ""),
    agreement: yup
      .boolean()
      .oneOf([true], "必須同意政策條款及細則")
      .required("必須同意政策條款及細則"),
  })
  .required();

const formSchema2 = yup
  .object()
  .shape({
    gender: yup
      .string()
      .required("必須選擇稱謂")
      .test("gender", "必須選擇稱謂", (value) => value !== ""),
    firstname: yup
      .string()
      .required("必須輸入姓名")
      .min(1, "姓名不能為空")
      .max(50, "姓名過長"),
    birthday: yup
      .string()
      .required("必須選擇生日月份")
      .test("birthday", "必須選擇生日月份", (value) => value !== ""),
    verificationCode: yup
      .string()
      .required("必須選擇區號"),
    phone: yup
      .string()
      .required("必須輸入電話號碼")
      .matches(/^[0-9]+$/, "電話號碼只能包含數字")
      .min(8, "電話號碼至少需要8位")
      .max(15, "電話號碼不能超過15位"),
    agreement: yup
      .boolean()
      .oneOf([true], "必須同意政策條款及細則")
      .required("必須同意政策條款及細則"),
  })
  .required();

export default function RegisterPage() {
  //const [checked, setChecked] = useState(false);
  const [pageContent, setPageContent] = useState("");
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const email = searchParams.get("email");
  const googleId = searchParams.get("googleId");

  const countryCode = [
    { value: "+852", display: t("country.HK") }
  ];
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm<IFormValues>({
    mode: "onTouched",
    resolver: yupResolver(email !== null ? formSchema2 : formSchema),
    defaultValues: {
      gender: "",
      firstname: "",
      verificationCode: "+852",
      phone: "",
      birthday: "",
      agreement: false,
      agreement2: false,
    },
  });

  useEffect(() => {
    if (email != "" && email !== null) {
      setValue("email", email);
    }
  }, []);

  const navigate = useNavigate();

  const submitSignUpForm = (data: any) => {
    let formData = new FormData();
    for (var key in data) {
      formData.append(key, data[key]);
    }
    formData.append("locationId", "6");
    if (email !== null && email !== "") {
      if (googleId) {
        formData.append("googleId", googleId);
        formData.append("facebookId", "");
      }
      fetch(process.env.REACT_APP_BACKEND + "member/addOauth", {
        method: "post",
        headers: {
          ContentType: "application/json",
        },
        body: formData,
      })
        .then((res) => res.json())
        .then((res) => {
          switch (res.status) {
            case 1:
              alert("電郵地址已被註冊");
              break;
            case 2:
              alert("電話已被註冊");
              break;
            case 3:
              alert("驗證服務發生錯誤");
              break;
            case 4:
              alert("驗證碼錯誤");
              break;
            case 5:
              alert("註冊成功。");
              navigate("/");
          }
        })
        .catch((error) => alert(error));
    } else {
      fetch(process.env.REACT_APP_BACKEND + "member/add", {
        method: "post",
        headers: {
          ContentType: "application/json",
        },
        body: formData,
      })
        .then((res) => res.json())
        .then((res) => {
          switch (res.status) {
            case 1:
              alert("電郵地址已被註冊");
              break;
            case 2:
              alert("電話已被註冊");
              break;
            case 3:
              alert("驗證服務發生錯誤");
              break;
            case 4:
              alert("驗證碼錯誤");
              break;
            case 5:
              alert("註冊成功, 請檢查你的電郵信箱, 以激活你的帳號。");
              navigate("/");
          }
        })
        .catch((error) => alert(error));
    }
  };

  const Select = React.forwardRef<
    HTMLSelectElement,
    { value: any; id: string; errors?: string } & ReturnType<UseFormRegister<IFormValues>>
  >(({ onChange, onBlur, name, value, id, errors }, ref) => (
    <>
      <select ref={ref} name={name} onChange={onChange} id={id}>
        {value.map((item: any, idx: number) => (
          <option key={idx} value={item.value}>
            {item.display}
          </option>
        ))}
      </select>
      {errors && <span style={{ fontSize: "10px", color: "red" }}>{errors}</span>}
    </>
  ));

  // const Input = ({
  //   label,
  //   placeholder,
  //   type,
  //   pattern,
  //   register,
  //   required,
  //   minLength,
  // }: InputProps) => (
  //   <>
  //     <input
  //       placeholder={placeholder}
  //       type={type}
  //       {...register(
  //         label,
  //         type === "password"
  //           ? { required, minLength: minLength }
  //           : { required, pattern: pattern }
  //       )}
  //     />
  //     {errors.email && label === "email" && (
  //       <span role="alert">{errors.email.message}</span>
  //     )}
  //     {errors.password && label === "password" && (
  //       <span role="alert">{errors.password.message}</span>
  //     )}
  //   </>
  // );

  const onSubmit: SubmitHandler<IFormValues> = (data) => {
    // 驗證稱謂
    if (!data.gender || data.gender === "") {
      return;
    }

    // 驗證姓名
    if (!data.firstname || data.firstname.trim() === "") {
      return;
    }

    // 驗證電話相關欄位
    if (!data.verificationCode || data.verificationCode === "") {
      return;
    }

    if (!data.phone || data.phone.trim() === "") {
      return;
    }

    // 驗證電話號碼格式
    const phoneRegex = /^[0-9]+$/;
    if (!phoneRegex.test(data.phone)) {
      return;
    }

    if (data.phone.length < 8 || data.phone.length > 8) {
      return;
    }

    // 驗證生日
    if (!data.birthday || data.birthday === "") {
      return;
    }

    console.log("Form data:", data);
    submitSignUpForm(data);
  };

  React.useEffect(() => {
    API.getPageContentById(5).then((res) => setPageContent(res.content));
  }, []);

  return (
    <>
      <div className={style.tabButtonContainer}>
        <Button
          buttonType={"button"}
          styleType={"tabButton"}
          callBack={(value) => navigate("/login")}
        >
          <p className={style.tabButtonText}>{t("signIn.memberSignIn")}</p>
        </Button>

        <Button
          buttonType={"button"}
          styleType={"tabButtonCurrent"}
          callBack={(value) => navigate("/register")}
        >
          <p className={style.tabButtonText}>{t("signUp.signUp")}</p>
        </Button>
      </div>
      <div className={style.abc}>
        <form
          className={style.registerAlignment}
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className={style.registerInnerAlignment}>
            <h5>{t("signUp.pleaseEnterYourDetails")}</h5>
            <p className={style.registerTitle}>
              {/* {t("signUp.fieldsMarkedWithAnAreMandatory")} */}
            </p>
            <div className={style.formRow}>
              <div>
                <Select
                  {...register("gender")}
                  value={[
                    { value: "", display: t("signUp.title") },
                    { value: "Mr", display: "先生" },
                    { value: "Miss", display: "小姐" },
                    { value: "None", display: "不適用" },
                  ]}
                  id="gender"
                  errors={errors.gender?.message}
                />
              </div>
              <Input2
                id="firstname"
                type="text"
                placeholder={t("signUp.name")}
                register={register}
                errors={errors.firstname?.message}
              />
            </div>
            <div className={style.formRow}>
              <Input2
                id="email"
                type="text"
                placeholder={t("signUp.emailAddress")}
                register={register}
                errors={errors.email?.message}
                disabled={email !== null && email !== "" ? true : false}
              />

              {/* <Input
                label="email"
                placeholder="電子郵件"
                register={register}
                pattern={{
                  value: /\S+@\S+\.\S+/,
                  message: "電子郵件格式錯誤",
                }}
                required
              /> */}
            </div>
            <div className={style.formRow}>
              <div>
                <Select 
                  {...register("verificationCode")} 
                  value={countryCode}
                  id="verificationCode"
                  errors={errors.verificationCode?.message}
                />
              </div>
              {/* <Input2
                id="verificationCode"
                type="tel"
                placeholder={t("signUp.areaCode")}
                register={register}
                errors={errors.verificationCode?.message}
              /> */}
              <Input2
                id="phone"
                type="tel"
                placeholder={t("signUp.phoneNumber")}
                register={register}
                errors={errors.phone?.message}
              />
              {/* <Input
                type="tel"
                label="phone"
                placeholder="電話"
                register={register}
                required
              /> */}
            </div>
            {/* <div className={style.registerDetailTextContainer}>
              <span className={style.registerDetailText}>
                只需驗證 <span>電郵</span> 或 <span>電話</span> 其中一樣.
              </span>
            </div> */}
            {/* <div className={style.regsiterNameInputContainer}>
              <Input
                type="text"
                placeholder={"輸入六位數驗証碼"}
                styleType={"loginInput"}
                callBack={() => console.log(1)}
              ></Input>
            </div> */}
            {email !== null && email !== "" ? (
              <></>
            ) : (
              <>
                <div className={style.formRow}>
                  {/* <Input
                type="password"
                label="password"
                placeholder="密碼"
                register={register}
                required
                minLength={{
                  value: 8,
                  message: "密碼長度至少8位",
                }}
              /> */}

                  <Input2
                    id="password"
                    type="password"
                    placeholder={t("signUp.password")}
                    register={register}
                    errors={errors.password?.message}
                  />
                </div>
                <div className={style.formRow}>
                  {/* <Input
                type="password"
                label="confirmPassword"
                placeholder="確認密碼"
                register={register}
                required
                minLength={{
                  value: 8,
                  message: "密碼長度至少8位",
                }}
              /> */}
                  <Input2
                    id="confirmPassword"
                    type="password"
                    placeholder={t("signUp.confirmPassword")}
                    register={register}
                    errors={errors.confirmPassword?.message}
                  />
                </div>
              </>
            )}

            <div className={style.formRow2}>
              <Select 
                {...register("birthday")} 
                value={month()} 
                id="birthday"
                errors={errors.birthday?.message}
              />
            </div>
            {/*
            <div className={style.regsiterIDInputContainer}>
              <Input type="text" placeholder={"身份證號碼 *"} styleType={"loginInput"} callBack={() => console.log(1)}></Input>
              <Select firstValue={"年齡組別"} styleType={"registerSelect"} callBack={() => console.log(1)} />
            </div>
            */}
            <div className={style.AgreementRow}>
              <Checkbox
                id="agreement"
                type="checkbox"
                register={register}
                label={t("signUp.agreeConditions")}
                errors={errors.agreement?.message}
              />
              {/* <span className={style.spanText}>
                  本人已閱讀並同意
                  <span onClick={() => navigate("/PrivacyPolicy")}>
                    私隱政策
                  </span>
                  及
                  <span onClick={() => navigate("/TermsOfService")}>
                    條款及細則
                  </span>

                </span> */}
            </div>
            <div className={style.AgreementRow2}>
              <Checkbox
                id="agreement2"
                type="checkbox"
                register={register}
                label={t("signUp.agreeToReceive")}
                errors={errors.agreement2?.message}
              />
              {/* <span className={style.spanText}>
                  本人已閱讀並同意
                  <span onClick={() => navigate("/PrivacyPolicy")}>
                    私隱政策
                  </span>
                  及
                  <span onClick={() => navigate("/TermsOfService")}>
                    條款及細則
                  </span>

                </span> */}
            </div>

            {/* <div className={style.regsiterNameInputContainer}> */}
            {/* <div className={style.tabButtonContainer}> */}
            <input type="submit" value="註冊" className={style.submitButton} />
          </div>
        </form>
      </div>
    </>
  );
}
