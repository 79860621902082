/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ArticleHashtag } from './ArticleHashtag';
import {
    ArticleHashtagFromJSON,
    ArticleHashtagFromJSONTyped,
    ArticleHashtagToJSON,
} from './ArticleHashtag';
import type { DisplayProductHashtag } from './DisplayProductHashtag';
import {
    DisplayProductHashtagFromJSON,
    DisplayProductHashtagFromJSONTyped,
    DisplayProductHashtagToJSON,
} from './DisplayProductHashtag';

/**
 * 
 * @export
 * @interface Hashtag
 */
export interface Hashtag {
    /**
     * 
     * @type {Array<ArticleHashtag>}
     * @memberof Hashtag
     */
    articleHashtagByHashtagId?: Array<ArticleHashtag>;
    /**
     * 
     * @type {Array<DisplayProductHashtag>}
     * @memberof Hashtag
     */
    displayProductHashtagByHashtagId?: Array<DisplayProductHashtag>;
    /**
     * 
     * @type {number}
     * @memberof Hashtag
     */
    hashtagId?: number;
    /**
     * 
     * @type {string}
     * @memberof Hashtag
     */
    hashtagName?: string;
}

/**
 * Check if a given object implements the Hashtag interface.
 */
export function instanceOfHashtag(value: object): boolean {
    return true;
}

export function HashtagFromJSON(json: any): Hashtag {
    return HashtagFromJSONTyped(json, false);
}

export function HashtagFromJSONTyped(json: any, ignoreDiscriminator: boolean): Hashtag {
    if (json == null) {
        return json;
    }
    return {
        
        'articleHashtagByHashtagId': json['articleHashtagByHashtagId'] == null ? undefined : ((json['articleHashtagByHashtagId'] as Array<any>).map(ArticleHashtagFromJSON)),
        'displayProductHashtagByHashtagId': json['displayProductHashtagByHashtagId'] == null ? undefined : ((json['displayProductHashtagByHashtagId'] as Array<any>).map(DisplayProductHashtagFromJSON)),
        'hashtagId': json['hashtagId'] == null ? undefined : json['hashtagId'],
        'hashtagName': json['hashtagName'] == null ? undefined : json['hashtagName'],
    };
}

export function HashtagToJSON(value?: Hashtag | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'articleHashtagByHashtagId': value['articleHashtagByHashtagId'] == null ? undefined : ((value['articleHashtagByHashtagId'] as Array<any>).map(ArticleHashtagToJSON)),
        'displayProductHashtagByHashtagId': value['displayProductHashtagByHashtagId'] == null ? undefined : ((value['displayProductHashtagByHashtagId'] as Array<any>).map(DisplayProductHashtagToJSON)),
        'hashtagId': value['hashtagId'],
        'hashtagName': value['hashtagName'],
    };
}

