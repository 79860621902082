/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  Sync,
} from '../models/index';
import {
    SyncFromJSON,
    SyncToJSON,
} from '../models/index';

export interface SyncMissionDataUsingPOSTRequest {
    sync?: Sync;
}

/**
 * 
 */
export class SyncControllerApi extends runtime.BaseAPI {

    /**
     * syncMissionData
     */
    async syncMissionDataUsingPOSTRaw(requestParameters: SyncMissionDataUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/sync/adds`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SyncToJSON(requestParameters['sync']),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<number>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * syncMissionData
     */
    async syncMissionDataUsingPOST(requestParameters: SyncMissionDataUsingPOSTRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number | null | undefined > {
        const response = await this.syncMissionDataUsingPOSTRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 201:
                return null;
            default:
                return await response.value();
        }
    }

}
