/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GoogleVerifyResponseModel
 */
export interface GoogleVerifyResponseModel {
    /**
     * 
     * @type {string}
     * @memberof GoogleVerifyResponseModel
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof GoogleVerifyResponseModel
     */
    familyName?: string;
    /**
     * 
     * @type {string}
     * @memberof GoogleVerifyResponseModel
     */
    givenName?: string;
    /**
     * 
     * @type {boolean}
     * @memberof GoogleVerifyResponseModel
     */
    success?: boolean;
    /**
     * 
     * @type {string}
     * @memberof GoogleVerifyResponseModel
     */
    userId?: string;
}

/**
 * Check if a given object implements the GoogleVerifyResponseModel interface.
 */
export function instanceOfGoogleVerifyResponseModel(value: object): boolean {
    return true;
}

export function GoogleVerifyResponseModelFromJSON(json: any): GoogleVerifyResponseModel {
    return GoogleVerifyResponseModelFromJSONTyped(json, false);
}

export function GoogleVerifyResponseModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): GoogleVerifyResponseModel {
    if (json == null) {
        return json;
    }
    return {
        
        'email': json['email'] == null ? undefined : json['email'],
        'familyName': json['familyName'] == null ? undefined : json['familyName'],
        'givenName': json['givenName'] == null ? undefined : json['givenName'],
        'success': json['success'] == null ? undefined : json['success'],
        'userId': json['userId'] == null ? undefined : json['userId'],
    };
}

export function GoogleVerifyResponseModelToJSON(value?: GoogleVerifyResponseModel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'email': value['email'],
        'familyName': value['familyName'],
        'givenName': value['givenName'],
        'success': value['success'],
        'userId': value['userId'],
    };
}

