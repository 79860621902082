import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import * as API from "../../api/PageContentAPI";

export default function ContactUs() {
  const [pageContent, setPageContent] = useState("");
  const { i18n } = useTranslation();

  window.scrollTo(0, 0);
  useEffect(() => {
    API.getPageContentById(23).then((res) => setPageContent(res.content));
  }, []);
  return (
    <div
      dangerouslySetInnerHTML={{
        __html: pageContent.split(":::")[i18n.language === "en" ? 1 : 0],
      }}
    ></div>
  );
}
