import React, { Suspense } from "react";
import type { RouteObject } from "react-router-dom";
import Layout from "../layout/test/Layout";
import Home from "../layout/test/Home";
import LayoutSecond from "../layout/test/LayoutSecond";
import Private from "../layout/test/Private";
import LoginPage from "../layout/Login/LoginPage";
import Body from "../layout/test/Body";
import LandingPage from "../layout/LandingPage/LandingPage";
import RegisterPage from "../layout/Login/RegisterPage";
import LoginLayout from "../layout/Login/LoginLayout";
import BookDetail from "../layout/test/BookDetail";
import Favourite from "../layout/Favourite/Favourite";
import ShoppingCart from "../components/ShopingCart/ShoppingCart";
import Checkout from "../layout/Checkout/Checkout";
import AboutUs from "../layout/AboutUs/AboutUs";
import ContactUs from "../layout/ContactUs/ContactUs";
import FrequentlyQuestions from "../layout/FrequentlyQuestions/FrequentlyQuestions";
import Customer from "../layout/Customer/Customer";
import Subscription from "../layout/Subscription/Subscription";
import Complete from "../layout/Complete/Complete";
import EpubReader from "../layout/reader/epubPage/component";
import PdfReader from "../layout/reader/pdfReader/component";
import TransactionFailed from "../layout/Complete/TransactionFailed";
import UserInformation from "../layout/UserInformation/UserInformation";
import Bookshelf from "../layout/Bookshelf/Bookshelf";
import Category from "../layout/Category/Category";
import News from "../layout/News/News";
import Events from "../layout/Events/Events";
import PrivatePolicy from "../layout/PrivatePolicy/PrivatePolicy";
import TermsOfService from "../layout/TermsOfService/TermsOfService";
import BecomeMember from "../layout/BecomeMember/BecomeMember";
import BeginnerGuide from "../layout/BeginnerGuide/BeginnerGuide";
import JoinUs from "../layout/JoinUs/JoinUs";
import AccountRecovery from "../layout/AccountRecovery/AccountRecovery";
import CustomerService from "../layout/CustomerService/CustomerService";
import EmailConfirm from "../layout/Login/EmailConfirm";
import Disclaimer from "../layout/Disclaimer/Disclaimer";
import Organization from "../layout/Category/Organization";

const SearchResult = React.lazy(() => import("../layout/Category/Search"));
export const routes = (): RouteObject[] => [
  {
    path: "/epub/:id",
    element: <EpubReader />,
  },
  {
    path: "/pdf/:id",
    element: <PdfReader />,
  },
  {
    element: <LoginLayout />,
    children: [
      { path: "/login", element: <LoginPage /> },
      {
        path: "/register",
        element: <RegisterPage />,
      },
    ],
  },
  {
    path: "/",
    element: <Layout />,
    children: [
      {
        element: <Home />,
        children: [
          { index: true, element: <LandingPage /> },
          { path: "/:id", element: <Body /> },

          {
            path: "/FAQ",
            element: <FrequentlyQuestions />,
          },
          {
            path: "/News",
            element: <News />,
          },
          {
            path: "/Events",
            element: <Events />,
          },
          {
            path: "/aboutUs",
            element: <AboutUs />,
          },
          {
            path: "/contactus",
            element: <ContactUs />,
          },
          {
            path: "/PrivacyPolicy",
            element: <PrivatePolicy />,
          },
          {
            path: "/TermsOfService",
            element: <TermsOfService />,
          },
          {
            path: "/customer",
            element: <Customer />,
          },
          { path: "/Subscription", element: <Subscription /> },
          { path: "/BecomeMember", element: <BecomeMember /> },
          { path: "/joinUs", element: <JoinUs /> },
          { path: "/AccountRecovery", element: <AccountRecovery /> },
          { path: "/customerService", element: <CustomerService /> },
          { path: "/BeginnerGuide", element: <BeginnerGuide /> },
          { path: "/BeginnerGuide", element: <BeginnerGuide /> },
          { path: "/Disclaimer", element: <Disclaimer /> },
        ],
      },
      {
        path: "product/:id",
        element: <BookDetail />,

        // { path: "/privates/:id", element: <Private /> },
      },
      {
        path: "search",
        element: (
          <Suspense fallback={<>loading...</>}>
            <SearchResult />
          </Suspense>
        ),
        // { path: "/privates/:id", element: <Private /> },
      },
      {
        path: "category/:id",
        element: (
          <Suspense fallback={<>loading...</>}>
            <Category />
          </Suspense>
        ),
        // { path: "/privates/:id", element: <Private /> },
      },
      {
        path: "organization/:id",
        element: (
          <Suspense fallback={<>loading...</>}>
            <Organization />
          </Suspense>
        ),
        // { path: "/privates/:id", element: <Private /> },
      },
      {
        path: "/favourite",
        element: <Favourite />,
      },
      {
        path: "/user",
        element: <UserInformation />,
      },
      {
        path: "/bookshelf",
        element: <Bookshelf />,
      },
      {
        path: "/shoppingcart",
        element: <ShoppingCart />,
      },
      {
        path: "/checkout",
        element: <Checkout />,
      },
      {
        path: "/orderComplete",
        element: <Complete />,
      },
      {
        path: "/transaction/failed",
        element: <TransactionFailed />,
      },
      {
        path: "/email-confirm",
        element: <EmailConfirm />,
      },
    ],
  },
];
