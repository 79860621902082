/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Member } from './Member';
import {
    MemberFromJSON,
    MemberFromJSONTyped,
    MemberToJSON,
} from './Member';

/**
 * 
 * @export
 * @interface Point
 */
export interface Point {
    /**
     * 
     * @type {Date}
     * @memberof Point
     */
    expireDate?: Date;
    /**
     * 
     * @type {Member}
     * @memberof Point
     */
    memberByMemberId?: Member;
    /**
     * 
     * @type {number}
     * @memberof Point
     */
    memberId?: number;
    /**
     * 
     * @type {number}
     * @memberof Point
     */
    pointId?: number;
    /**
     * 
     * @type {number}
     * @memberof Point
     */
    pointRemain?: number;
}

/**
 * Check if a given object implements the Point interface.
 */
export function instanceOfPoint(value: object): boolean {
    return true;
}

export function PointFromJSON(json: any): Point {
    return PointFromJSONTyped(json, false);
}

export function PointFromJSONTyped(json: any, ignoreDiscriminator: boolean): Point {
    if (json == null) {
        return json;
    }
    return {
        
        'expireDate': json['expireDate'] == null ? undefined : (new Date(json['expireDate'])),
        'memberByMemberId': json['memberByMemberId'] == null ? undefined : MemberFromJSON(json['memberByMemberId']),
        'memberId': json['memberId'] == null ? undefined : json['memberId'],
        'pointId': json['pointId'] == null ? undefined : json['pointId'],
        'pointRemain': json['pointRemain'] == null ? undefined : json['pointRemain'],
    };
}

export function PointToJSON(value?: Point | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'expireDate': value['expireDate'] == null ? undefined : ((value['expireDate']).toISOString()),
        'memberByMemberId': MemberToJSON(value['memberByMemberId']),
        'memberId': value['memberId'],
        'pointId': value['pointId'],
        'pointRemain': value['pointRemain'],
    };
}

