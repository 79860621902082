import React, { useEffect, useState } from "react";
import Button from "../../components/Button/Button";
// import { Outlet } from 'react-router-dom'
import style from "./BookDetail.module.css";
import testPic from "../../assets/images/images.png";
// import Input from "../../components/Input/Input";
import Textarea from "../../components/Textarea/Textarea";
import BookCard, { IBookData } from "../../components/BookCard/BookCard";
import useWindowSize from "../../utils/useWindowSize";
import footer from "../../assets/images/Logo.svg";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import backArrow from "../../assets/icons/iconsarrowleft@1x.svg";
import * as DisplayProductApi from "../../api/DisplayProductAPI";
import { connect, useSelector } from "react-redux";
import { addProductItem } from "../../redux/actions/ShoppingCartAction";
import { addToShoppingCart } from "../../redux/services/ShoppingCartService";
import { useTranslation, Trans } from "react-i18next";
import { addToFavorite } from "../../redux/services/FavoriteService";
import { handleReaderMode } from "../../redux/actions/reader/reader";
import { openBook, openCdnBook } from "../../utils/openBook";
import UpperSideNav from "../SideNav/UpperSideNav";
import api from '../../api/api';
import * as CategoryAPI from "../../api/CategoryAPI";
import LowerSideNav from "../SideNav/LowerSideNav";
import PhonelinkRingIcon from "@mui/icons-material/PhonelinkRing";

// import { useArray } from "../../global/useArray";

export const NewBooks = [
  {
    id: 1,
    img: "images.png",
    title: "教子有方 - 子女的信仰培育(修訂本)",
    author: "香港教區教理委員會",
    price: 30,
  },
  {
    id: 2,
    img: "images.png",
    title: "教子有方 - 子女的信仰培育(修訂本)",
    author: "香港教區教理委員會",
    price: 30,
  },
  {
    id: 3,
    img: "images.png",
    title: "教子有方 - 子女的信仰培育(修訂本)",
    author: "香港教區教理委員會",
    price: 30,
  },
  {
    id: 3,
    img: "images.png",
    title: "教子有方 - 子女的信仰培育(修訂本)",
    author: "香港教區教理委員會",
    price: 30,
  },
  {
    id: 4,
    img: "images.png",
    title: "教子有方 - 子女的信仰培育(修訂本)",
    author: "香港教區教理委員會",
    price: 30,
  },
];

function Privates({ reader, handleReaderMode }: any) {
  const [toggle, setToggle] = useState(false);
  const { width, height } = useWindowSize();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { id } = useParams();
  const [product, setProduct] = useState<IBookData>();
  const [loader, setLoader] = useState(false);

  const { t, i18n } = useTranslation();
  const member = useSelector((state: any) => state.MemberReducer);

  const [tempProductId, setTempProductId] = useState<number | null>();
  const [loadingPreview, setLoadingPreview] = useState(false);
  const [loadingProd, setLoadingProd] = useState(false);
  const [loadingOnlineRead, setLoadingOnlineRead] = useState(false);
  const [displayCategory, setDisplayCategory] = useState([]);
  const isOnBookShelf = useSelector((state: any) =>
    state.MyBookShelfReducer.productList.find(
      (productId: any) => productId == product?.displayProductId
    )
  );

  useEffect(() => {
    categoryList();
    //import StorageUtil from "../../../utils/reader/serviceUtils/storageUtil";
    if (tempProductId !== undefined && tempProductId !== null) {
      console.log("tempProductId", tempProductId);
      let readerConfig = localStorage.getItem("readerConfig");
      if (readerConfig !== null) {
        let readerConfigObj = JSON.parse(readerConfig);
        if (reader.mode !== "" && reader.mode !== undefined) {
          readerConfigObj["rm"] = reader.mode.substring(0, 1);
          localStorage.setItem("readerConfig", JSON.stringify(readerConfigObj));
          if (reader.mode === "read") {
            setLoadingProd(true);
            //handleReaderMode("");
          }

          if (reader.mode === "preview") {
            setLoadingPreview(true);
            //handleReaderMode("");
          }

          // if (props.reader.mode === "online") {
          //   setLoadingOnlineRead(true);
          //   handleReaderMode("");
          // }
        }
      }
    }

    // if (props.userInfo.mode !== undefined) {
    //   console.log("props10", props.userInfo.mode);
    //   if (props.reader.mode === "read") {
    //     setLoadingProd(true);
    //   }
    //   if (props.reader.mode === "preview") {
    //     setLoadingPreview(true);
    //   }
    // }
  }, [reader]);

  useEffect(() => {
    fetchProduct();
  }, [pathname]);

  const fetchProduct = async () => {
    const product = await DisplayProductApi.getDisplayProductByUrlName(id);
    setProduct(product);
  };
  const addToCart = () => {
    if (member.token !== null) {
      addToShoppingCart(product!.products[0].productId, 1);
    } else {
      alert("請先登入");
    }
  };

  const addToFavourite = () => {
    addToFavorite(product!.displayProductId);
  };

  useEffect(() => {
    if (loadingPreview) {
      console.log("preview product", product);
      handleBookOpening(product, 'preview');
      setLoadingPreview(false);
      setLoadingProd(false);
      setLoadingOnlineRead(false);
      setTempProductId(null);
    }
  }, [loadingPreview]);
  useEffect(() => {
    if (loadingProd) {
      console.log("prod product", product);
      handleBookOpening(product, 'prod');
      setLoadingPreview(false);
      setLoadingProd(false);
      setLoadingOnlineRead(false);
      setTempProductId(null);
    }
  }, [loadingProd]);

  const handleBookOpening = (product: any, type: 'preview' | 'prod') => {
    let fileName = type === 'preview' 
      ? product?.products[0]?.epubPreviewFileName
      : product?.products[0]?.epubProdFileName;

    // 如果是預覽模式且檔案名為空或null，則使用生產版本的檔案名
    if (type === 'preview' && (!fileName || fileName.trim() === '')) {
      fileName = product?.products[0]?.epubProdFileName;
    }
  
    const openCdnBookFn = type === 'preview' ? openCdnBook : openCdnBook;
    const openBookFn = type === 'preview' ? openBook : openBook;
  
    if (process.env.REACT_APP_BOOK_USE_CDN_URL === 'true') {
      if (fileName) {
        api.fileHandlingApi.checkEncryptedFileUsingGET({
          filename: fileName
        })
        .then((response) => {
          if (response.result === 1 && response.encrypted) {
            openCdnBookFn(product.products[0], setLoader, response.encrypted);
          } else {
            openBookFn(product.products[0], setLoader);
          }
        })
        .catch(error => {
          console.error("Error checking encryption:", error);
          openBookFn(product.products[0], setLoader);
        });
      }
    } else {
      openBookFn(product!.products[0], setLoader);
    }
  };

  const handleReadBook = (actionType: string, book: any) => {
    setTempProductId(book.displayProductId);
    if (
      actionType === "read" ||
      actionType === "preview" ||
      actionType === "online"
    ) {
      console.log("actionType", book);

      handleReaderMode(actionType);
      //setReadAction(actionType);
    } else {
      //   setLoadingOnlineRead(true);
    }
  };

  const categoryList = async () => {
    const categories = await CategoryAPI.getAll();
    setDisplayCategory(categories);
  };

  function formatBytes(bytes: number, decimals = 2) {
    if (!+bytes) return "0 Bytes";

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = [
      "Bytes",
      "KB",
      "MB",
      "GiB",
      "TiB",
      "PiB",
      "EiB",
      "ZiB",
      "YiB",
    ];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
  }

  let _bookPrice = 0;
  _bookPrice = product! && product!.displayProductDiscountPrice
    ? 
      Math.floor(
        (
          product! &&
          product!.displayProductDiscountPrice + Number.EPSILON
        ) * 10
      ) / 10
    :
      Math.floor(
        (
          product! &&
          product!.displayProductPrice + Number.EPSILON
        ) * 10
      ) / 10
  ;

  return (
    <>
      {loader && (
        <div className={style.loadingOverlay}>
          <div className={style.loadingSpinner}></div>
        </div>
      )}
      <div className={style.searchResultInnerContainer}>
        <div>
          <UpperSideNav props={displayCategory} />
          <LowerSideNav props={displayCategory} />
        </div>
        <div className={style.privateConatinaer}>
          <div className={style.privateHeader}>
            <div className={style.backButtonContainer}>
              <img src={backArrow} style={{ width: "20px" }} />

              <Button
                styleType={"upperSideNavButton"}
                callBack={() => navigate(-1)}
              >
                {t("back")}
              </Button>
            </div>
            <div className={style.catalogContaiSner}>
              <div className={style.catalog}>
                所有書籍 /
                {product &&
                  product.categories[0] &&
                  product.categories[0].categoryName}
                /{product && product.displayProductNameChi}
              </div>
            </div>
          </div>
          <div className={style.bookIntroConatainer}>
            {/* <div className={style.bookIntroInnerConatainer}> */}
            <div className={style.bookImageContainer}>
              {product && product!.displayProductImage != null ? (
                <img
                  src={
                    product!.displayProductImageCdn
                    && product!.displayProductImageCdn.length > 0
                      ? process.env.REACT_APP_CDN_URL + "/fileData/" + product!.displayProductImageCdn
                      : `${process.env.REACT_APP_BACKEND}file/${product!.displayProductImage.split(":::")[0]}`
                  }
                  className={style.bookImage}
                  alt={"description"}
                ></img>
              ) : (
                <></>
              )}
              {/* <img
                src={` ${process.env.REACT_APP_BACKEND}file/${
                  product && product!.displayProductImage.split(":::")[0]
                }`}
                className={style.bookImage}
                alt={"description"}
              ></img> */}
            </div>
            <div className={style.bookIntroDetailContainer}>
              <div className={style.bookIntroDetail}>
                {/* <p className={style.booktitle}>
                  {product && product.displayProductNameChi}
                </p>
                <Button
                  styleType={"upperSideNavButton"}
                  callBack={() =>
                    navigate(
                      `/search?querystring=${product!.displayProductAuthor}`
                    )
                  }
                >
                  {product && product.displayProductAuthor}
                </Button> */}
                <div className={style.subDetail}>
                  <p>作者</p>
                  <p
                    style={{ cursor: "pointer", color: "rgb(0, 157, 165)" }}
                    onClick={() =>
                      navigate(
                        `/search?querystring=${product!.displayProductAuthor}`
                      )
                    }
                  >
                    {product && product!.displayProductAuthor}
                  </p>
                </div>
                <div className={style.subDetail}>
                  <p>譯者</p>
                  <p style={{ color: "rgb(0, 157, 165)" }}>
                    {product && product!.displayProductTranslator}
                  </p>
                </div>
                <div className={style.subDetail}>
                  <p>出版社</p>

                  {product && product.publisher !== null ? (
                    <p
                      style={{ color: "#009da5", cursor: "pointer" }}
                      onClick={() =>
                        navigate(`/search?querystring=${product!.publisher}`)
                      }
                    >
                      {product!.publisher}
                    </p>
                  ) : (
                    <p>沒有資料</p>
                  )}
                </div>

                <div className={style.subDetail}>
                  <p>出版日期</p>
                  <p>
                    {product &&
                      product.products[0].publishDate &&
                      new Intl.DateTimeFormat("zh", {
                        year: "numeric",
                        month: "2-digit",
                      }).format(
                        new Date(product && product.products[0].publishDate)
                      )}
                  </p>
                </div>

                <div className={style.subDetail}>
                  <p>分類</p>
                  <div style={{ lineHeight: "1.5", flex: "0 1 12rem" }}>
                    {product && product.categories.length
                      ? product &&
                        product!.categories.slice(0, 3).map((x: any) => (
                          <div
                            key={x.categoryId}
                            style={{ color: "#009DA5", cursor: "pointer" }}
                            onClick={() => navigate(`/category/${x.categoryId}`)}
                          >
                            {x.categoryName}&nbsp; &nbsp;
                          </div>
                        ))
                      : "沒有資料"}
                  </div>
                </div>
                <div className={style.subDetail}>
                  <p>語言</p>
                  <p>
                    {product && product.language !== null
                      ? product!.language
                      : "沒有資料"}
                  </p>
                </div>
                <div className={style.subDetail}>
                  <p>產品類型 </p>
                  <p>
                    {product && product.products[0].productType !== null
                      ? product!.products[0].productType
                      : "沒有資料"}
                  </p>
                </div>

                <div className={style.subDetail}>
                  <p>CTS書號</p>
                  <p>
                    {product && product.products[0].ctsId !== null
                      ? product!.products[0].ctsId
                      : "沒有資料"}
                  </p>
                </div>
                <div className={style.subDetail}>
                  <p>ISBN</p>
                  <p>
                    {product && product.isbn !== null
                      ? product!.isbn
                      : "沒有資料"}
                  </p>
                </div>

                <div className={style.subDetail}>
                  <p>檔案格式</p>
                  <p>
                    {product && product.products[0].epubProdFileName != null ? (
                      product.products[0].epubProdFileName.split(".").pop()
                    ) : (
                      <></>
                    )}
                  </p>
                </div>

                <div className={style.subDetail}>
                  <p>檔案大小</p>
                  <p>
                    {" "}
                    {product && formatBytes(product.products[0].epubProdFileSize)}
                  </p>
                </div>
                <div className={style.subDetail}>
                  <p>售價</p>
                  <p className={style.bookPrice}>
                    HKD &nbsp;
                    {/*
                    {product! && product!.displayProductDiscountPrice
                      ? 
                        (
                          Math.floor(
                            (
                              product! &&
                              product!.displayProductDiscountPrice + Number.EPSILON
                            ) * 10
                          ) / 10
                        ).toFixed(2)
                      :
                        (
                          Math.floor(
                            (
                              product! &&
                              product!.displayProductPrice + Number.EPSILON
                            ) * 10
                          ) / 10
                        ).toFixed(2)
                    }
                    */}
                    {_bookPrice.toFixed(2)}
                  </p>
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  {product &&
                  product.products[0].epubProdFileName != null &&
                  product.products[0].epubProdFileName.split(".").pop() ===
                    "epub" ? (
                    <PhonelinkRingIcon />
                  ) : (
                    <></>
                  )}
                  <div
                    dangerouslySetInnerHTML={{
                      __html: product
                        ? product.displayProductDescriptionUsage
                        : "",
                    }}
                  ></div>
                </div>
                <div className={style.horizontalLineContainer}>
                  <hr className={style.horizontalLine}></hr>
                </div>

                <div className={style.BookButtonContainer}>
                  {isOnBookShelf ? (
                    <Button
                      styleType={"bookIntroButton"}
                      callBack={() => handleReadBook("read", product)}
                    >
                      閱讀
                    </Button>
                  ) : (
                    <Button
                      styleType={"bookIntroButton"}
                      callBack={() => addToCart()}
                    >
                      加入購物車
                    </Button>
                  )}

                  <Button
                    styleType={"bookIntroButton"}
                    callBack={() => addToFavourite()}
                  >
                    加入我的最愛
                  </Button>
                </div>
                <div className={style.BookButtonContainer}>
                  <Button
                    styleType={"bookIntroButton"}
                    callBack={() => handleReadBook("preview", product)}
                  >
                    試閱
                  </Button>
                  {/* <Button
                    styleType={"bookIntroButton"}
                    callBack={() =>
                      (window.location.href =
                        "https://www.catholiccentre.org.hk/")
                    }
                  >
                    購買實體書連結
                  </Button> */}
                </div>
              </div>
            </div>
          </div>
          <div className={style.toggleGroupContainer}>
            <div className={style.toggleGroup} style={{ display: "flex" }}>
              {/* <div className={style.toggleSilder}>123</div> */}
              <Button
                buttonType={"button"}
                styleType={toggle ? "toggleButton" : "toggleButtonCurrent"}
                callBack={(value) => setToggle(false)}
                // onClick={activeLogin()}
              >
                簡介
              </Button>
              <Button
                buttonType={"button"}
                styleType={toggle ? "toggleButtonCurrent" : "toggleButton"}
                callBack={(value) => setToggle(true)}
              >
                書評
              </Button>
            </div>
          </div>
          <div
          // className={
          //   toggle ? style.introParagraphNonActive : style.introParagraph
          // }
          >
            {toggle ? (
              <p
                dangerouslySetInnerHTML={{
                  __html: product ? product.displayProductDescriptionDetail : "",
                }}
              ></p>
            ) : (
              <p
                dangerouslySetInnerHTML={{
                  __html: product ? product.displayProductShortDescription : "",
                }}
              ></p>
            )}
          </div>
          {/* <div
            className={toggle ? style.bookComments : style.bookCommentsNonActive}
          >
            <div className={style.commentBoxInnerContainer}>
              <div className={style.commenter}>Peter</div>
              <div className={style.rating}>******</div>
              <Textarea
                placeholder={"密碼 *"}
                styleType={"commentInput"}
                callBack={() => console.log(1)}
              ></Textarea>
              <div className={style.commentButton}>
                <Button styleType={"loginButton"} callBack={() => console.log(1)}>
                  發佈
                </Button>
              </div>
            </div>
            <hr className={style.horizontalLine}></hr>
          </div> */}
        </div>

        {/* {const result = useArray([1,2,3,4])} */}
      </div>
    </>
  );
}

const mapStateToProps = (state: any) => {
  return {
    reader: state.reader,
  };
};

const actionCreator = {
  handleReaderMode,
};

export default connect(mapStateToProps, actionCreator)(Privates);
