/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { SubCategoryRequestModel } from './SubCategoryRequestModel';
import {
    SubCategoryRequestModelFromJSON,
    SubCategoryRequestModelFromJSONTyped,
    SubCategoryRequestModelToJSON,
} from './SubCategoryRequestModel';

/**
 * 
 * @export
 * @interface CategoryRequestModel
 */
export interface CategoryRequestModel {
    /**
     * 
     * @type {string}
     * @memberof CategoryRequestModel
     */
    categoryName?: string;
    /**
     * 
     * @type {string}
     * @memberof CategoryRequestModel
     */
    mongoId?: string;
    /**
     * 
     * @type {number}
     * @memberof CategoryRequestModel
     */
    seq?: number;
    /**
     * 
     * @type {Array<SubCategoryRequestModel>}
     * @memberof CategoryRequestModel
     */
    subCategoryRequestModels?: Array<SubCategoryRequestModel>;
}

/**
 * Check if a given object implements the CategoryRequestModel interface.
 */
export function instanceOfCategoryRequestModel(value: object): boolean {
    return true;
}

export function CategoryRequestModelFromJSON(json: any): CategoryRequestModel {
    return CategoryRequestModelFromJSONTyped(json, false);
}

export function CategoryRequestModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): CategoryRequestModel {
    if (json == null) {
        return json;
    }
    return {
        
        'categoryName': json['categoryName'] == null ? undefined : json['categoryName'],
        'mongoId': json['mongoId'] == null ? undefined : json['mongoId'],
        'seq': json['seq'] == null ? undefined : json['seq'],
        'subCategoryRequestModels': json['subCategoryRequestModels'] == null ? undefined : ((json['subCategoryRequestModels'] as Array<any>).map(SubCategoryRequestModelFromJSON)),
    };
}

export function CategoryRequestModelToJSON(value?: CategoryRequestModel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'categoryName': value['categoryName'],
        'mongoId': value['mongoId'],
        'seq': value['seq'],
        'subCategoryRequestModels': value['subCategoryRequestModels'] == null ? undefined : ((value['subCategoryRequestModels'] as Array<any>).map(SubCategoryRequestModelToJSON)),
    };
}

