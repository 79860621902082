/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface OrganizationDto
 */
export interface OrganizationDto {
    /**
     * 
     * @type {string}
     * @memberof OrganizationDto
     */
    logoUrl?: string;
    /**
     * 
     * @type {number}
     * @memberof OrganizationDto
     */
    organizationId?: number;
    /**
     * 
     * @type {string}
     * @memberof OrganizationDto
     */
    organizationImage?: string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationDto
     */
    organizationName?: string;
    /**
     * 
     * @type {number}
     * @memberof OrganizationDto
     */
    seq?: number;
    /**
     * 
     * @type {number}
     * @memberof OrganizationDto
     */
    serverId?: number;
    /**
     * 
     * @type {string}
     * @memberof OrganizationDto
     */
    webUrl?: string;
}

/**
 * Check if a given object implements the OrganizationDto interface.
 */
export function instanceOfOrganizationDto(value: object): boolean {
    return true;
}

export function OrganizationDtoFromJSON(json: any): OrganizationDto {
    return OrganizationDtoFromJSONTyped(json, false);
}

export function OrganizationDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrganizationDto {
    if (json == null) {
        return json;
    }
    return {
        
        'logoUrl': json['logoUrl'] == null ? undefined : json['logoUrl'],
        'organizationId': json['organizationId'] == null ? undefined : json['organizationId'],
        'organizationImage': json['organizationImage'] == null ? undefined : json['organizationImage'],
        'organizationName': json['organizationName'] == null ? undefined : json['organizationName'],
        'seq': json['seq'] == null ? undefined : json['seq'],
        'serverId': json['serverId'] == null ? undefined : json['serverId'],
        'webUrl': json['webUrl'] == null ? undefined : json['webUrl'],
    };
}

export function OrganizationDtoToJSON(value?: OrganizationDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'logoUrl': value['logoUrl'],
        'organizationId': value['organizationId'],
        'organizationImage': value['organizationImage'],
        'organizationName': value['organizationName'],
        'seq': value['seq'],
        'serverId': value['serverId'],
        'webUrl': value['webUrl'],
    };
}

