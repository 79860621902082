import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import Button from "../../components/Button/Button";
import style from "./LoginPage.module.css";
import { Path, SubmitHandler, useForm, UseFormRegister } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Input2 from "../../components/Input/Input2";
import Checkbox from "../../components/Input/Checkbox";
import { useTranslation } from "react-i18next";
import { t } from "i18next";
import * as API from "../../api/PageContentAPI";
import Facebook from "@mui/icons-material/Facebook";

import { getCountryCodeData } from './countryCodeData';

interface CountryCode {
  value: string;       // 电话区号
  display: string;     // 显示名称
  locationId: number;  // 位置ID
  minDigits: number;   // 最小电话号码位数
  maxDigits: number;   // 最大电话号码位数
}

export interface IFormValues {
  firstname: string;
  lastname: string;
  ageGroup: number;
  gender: string;
  verificationCode: string;
  birthday: string;
  password: string;
  email: string;
  phone: string;
  acceptPromoption: string;
  confirmPassword: string;
  district: string;
  agreement: boolean;
  agreement2: boolean;
}

// type InputProps = {
//   label: Path<IFormValues>;
//   placeholder: string;
//   register: UseFormRegister<IFormValues>;
//   required: boolean;
//   type?: string;
//   pattern?: any;
//   minLength?: any;
// };

export default function RegisterPage() {
  //const [checked, setChecked] = useState(false);
  const [pageContent, setPageContent] = useState("");
  const { t, i18n } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const email = searchParams.get("email");
  const googleId = searchParams.get("googleId");

  // Add a state to track form submission status
  const [isSubmitting, setIsSubmitting] = useState(false);

  // 性別選項
  const genderOptions = React.useMemo(() => [
    { value: "", display: "signUp.title" },
    { value: "Mr", display: "signUp.mr" },
    { value: "Miss", display: "signUp.miss" },
    { value: "None", display: "signUp.notApplicable" }
  ], []);

  // 月份選項
  const monthOptions = React.useMemo(() => {
    let monthArray = [{ value: "", display: "signUp.birthdayMonth" }];
    for (let i = 1; i <= 12; i++) {
      monthArray.push({ value: `${i}`, display: `${i}` });
    }
    return monthArray;
  }, []);

  const countryCode = React.useMemo(() => getCountryCodeData(t), [t, i18n.language]);
  
  const getPhoneValidationMessage = React.useCallback((verificationCode: string) => {
    const country = countryCode.find((c: CountryCode) => c.value === verificationCode);
    if (!country || !country.minDigits) {
      return t("signUp.pleaseSelectAreaCode");
    }
  
    if (country.minDigits === country.maxDigits) {
      return t("signUp.phoneDigitsFixed", { 
        country: country.display, 
        digits: country.minDigits 
      });
    }
  
    return t("signUp.phoneDigitsRange", { 
      country: country.display, 
      min: country.minDigits, 
      max: country.maxDigits 
    });
  }, [countryCode, t]);

  const formSchema = React.useMemo(() => 
    yup
      .object()
      .shape({
        gender: yup
          .string()
          .required(t("signUp.genderRequired") as string)
          .test("gender", t("signUp.genderTest") as string, (value) => value !== ""),
        firstname: yup
          .string()
          .required(t("signUp.firstnameRequired") as string)
          .min(1, t("signUp.firstnameMin") as string)
          .max(50, t("signUp.firstnameMax") as string)
          .trim(),
        email: yup
          .string()
          .email(t("signUp.emailFormatProblem") as string)
          .required(t("signUp.emailRequired") as string),
        verificationCode: yup
          .string()
          .required(t("signUp.verificationCodeRequired") as string),
        phone: yup
          .string()
          .required(t("signUp.phoneRequired") as string)
          .matches(/^[0-9]+$/, t("signUp.phoneMatches") as string)
          .test("phone", t("signUp.phoneTest") as string, function(value) {
            const verificationCode = this.parent.verificationCode;
            if (!value) return false;

            // 找到对应的国家/地区配置
            const country = countryCode.find(c => c.value === verificationCode);
            if (!country) return false;

            // 验证电话号码长度是否在允许范围内
            const length = value.length;
            return length >= country.minDigits && length <= country.maxDigits;
          }),
        password: yup
          .string()
          .required(t("signUp.passwordRequired") as string)
          .min(8, t("signUp.passwordMin") as string)
          .matches(RegExp("(.*[a-zA-Z].*)"), t("signUp.passwordMatches") as string)
          .matches(RegExp("(.*\\d.*)"), t("signUp.passwordMatches") as string),
        confirmPassword: yup
          .string()
          .required(t("signUp.passwordRequired") as string)
          .min(8, t("signUp.passwordMin") as string)
          .oneOf([yup.ref("password")], t("signUp.passwordOneOf") as string),
        birthday: yup
          .string()
          .required(t("signUp.birthdayRequired") as string)
          .test("birthday", t("signUp.birthdayTest") as string, (value) => value !== ""),
        agreement: yup
          .boolean()
          .oneOf([true], t("signUp.youMustAgree") as string)
          .required(t("signUp.youMustAgree") as string),
      })
      .required()
      , [t, i18n.language]
  );

  const formSchema2 = React.useMemo(() => 
    yup
      .object()
      .shape({
        gender: yup
          .string()
          .required(t("signUp.genderRequired") as string)
          .test("gender", t("signUp.genderTest") as string, (value) => value !== ""),
        firstname: yup
          .string()
          .required(t("signUp.firstnameRequired") as string)
          .min(1, t("signUp.firstnameMin") as string)
          .max(50, t("signUp.firstnameMax") as string),
        birthday: yup
          .string()
          .required(t("signUp.birthdayRequired") as string)
          .test("birthday", t("signUp.birthdayTest") as string, (value) => value !== ""),
        verificationCode: yup
          .string()
          .required(t("signUp.verificationCodeRequired") as string),
        phone: yup
          .string()
          .required(t("signUp.phoneRequired") as string)
          .matches(/^[0-9]+$/, t("signUp.phoneMatches") as string)
          .min(8, t("signUp.phoneMin") as string)
          .max(15, t("signUp.phoneMax") as string),
        agreement: yup
          .boolean()
          .oneOf([true], t("signUp.youMustAgree") as string)
          .required(t("signUp.youMustAgree") as string),
      })
      .required()
      , [t, i18n.language] // 添加 i18n.language 作为依赖
  );

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm<IFormValues>({
    mode: "onTouched",
    resolver: yupResolver(email !== null ? formSchema2 : formSchema),
    defaultValues: {
      gender: "",
      firstname: "",
      verificationCode: "+852",
      phone: "",
      birthday: "",
      agreement: false,
      agreement2: false,
    },
    context: { language: i18n.language }, // 添加语言上下文
  });

  useEffect(() => {
    if (email != "" && email !== null) {
      setValue("email", email);
    }
  }, []);

  const navigate = useNavigate();

  const submitSignUpForm = (data: any) => {
    // Set submitting state to true when starting form submission
    setIsSubmitting(true);

    let formData = new FormData();

    // 找到对应的国家/地区配置
    const country = countryCode.find(c => c.value === data.verificationCode);

    // 添加表单数据
    for (var key in data) {
      formData.append(key, data[key]);
    }

    // 添加 locationId，如果找不到对应的国家/地区，使用默认值
    formData.append("locationId", country ? String(country.locationId) : "6");
    
    if (email !== null && email !== "") {
      if (googleId) {
        formData.append("googleId", googleId);
        formData.append("facebookId", "");
      }
      fetch(process.env.REACT_APP_BACKEND + "member/addOauth", {
        method: "post",
        headers: {
          ContentType: "application/json",
        },
        body: formData,
      })
        .then((res) => res.json())
        .then((res) => {
          switch (res.status) {
            case 1:
              alert(t("signUp.memberAddAlert1"));
              break;
            case 2:
              alert(t("signUp.phoneNumber") + t("signUp.alreadyRegistered"));
              break;
            case 3:
              alert(t("signUp.verificationError"));
              break;
            case 4:
              alert(t("signUp.verificationCodeError"));
              break;
            case 5:
              alert(t("signUp.registrationSuccessful"));
              navigate("/");
          }
        })
        .catch((error) => alert(error))
        .finally(() => {
          // Re-enable the submit button when fetch completes (success or error)
          setIsSubmitting(false);
        });
    } else {
      fetch(process.env.REACT_APP_BACKEND + "member/add", {
        method: "post",
        headers: {
          ContentType: "application/json",
        },
        body: formData,
      })
        .then((res) => res.json())
        .then((res) => {
          switch (res.status) {
            case 1:
              alert(t("signUp.memberAddAlert1"));
              break;
            case 2:
              alert(t("signUp.phoneNumber") + t("signUp.alreadyRegistered"));
              break;
            case 3:
              alert(t("signUp.verificationError"));
              break;
            case 4:
              alert(t("signUp.verificationCodeError"));
              break;
            case 5:
              if (email !== null && email !== "") {
                alert(t("signUp.registrationSuccessful"));
              } else {
                alert(t("signUp.registrationSuccessfulCheckEmail"));
              }
              navigate("/");
          }
        })
        .catch((error) => alert(error))
        .finally(() => {
          // Re-enable the submit button when fetch completes (success or error)
          setIsSubmitting(false);
        });
    }
  };

  const Select = React.memo(React.forwardRef<
    HTMLSelectElement,
    {
      value: any;
      id: string;
      errors?: string;
      displayFormat?: "countryCode" | "default";
    } & ReturnType<UseFormRegister<IFormValues>>
  >(({
    onChange
    , onBlur
    , name
    , value
    , id
    , errors
    , displayFormat = "default"
  }, ref) => {
    const { t, i18n } = useTranslation();

    interface OptionItem {
      value: string;
      displayText: string;
    }

    const options: OptionItem[] = value.map((item: any, idx: number) => {
      let displayText;
      if (id === "gender") {
        displayText = item.value 
          ? t(item.display)
          : t(item.display);
      } else if (id === "birthday") {
        displayText = item.value 
          ? `${item.value}`
          : t(item.display);
      } else if (id === "verificationCode") {
        displayText = item.value 
          ? `${item.value} ${t(item.display)}`
          : item.display;
      } else {
        displayText = item.value 
          ? `${t(item.display)} (${item.value})`
          : t(item.display);
      }
      
      return { value: item.value, displayText };
    });

    return (
      <>
        <select 
          ref={ref} 
          name={name} 
          onChange={(e) => {
            onChange(e);
          }} 
          onBlur={onBlur}
          id={id}
        >
          {options.map((item, idx) => (
            <option key={idx} value={item.value}>
              {item.displayText}
            </option>
          ))}
        </select>
        {errors && <span style={{ fontSize: "10px", color: "red" }}>{errors}</span>}
      </>
    );
  }), (prevProps, nextProps) => {
    return prevProps.value === nextProps.value && 
           prevProps.errors === nextProps.errors &&
           prevProps.id === nextProps.id;
  });

  // const Input = ({
  //   label,
  //   placeholder,
  //   type,
  //   pattern,
  //   register,
  //   required,
  //   minLength,
  // }: InputProps) => (
  //   <>
  //     <input
  //       placeholder={placeholder}
  //       type={type}
  //       {...register(
  //         label,
  //         type === "password"
  //           ? { required, minLength: minLength }
  //           : { required, pattern: pattern }
  //       )}
  //     />
  //     {errors.email && label === "email" && (
  //       <span role="alert">{errors.email.message}</span>
  //     )}
  //     {errors.password && label === "password" && (
  //       <span role="alert">{errors.password.message}</span>
  //     )}
  //   </>
  // );

  const onSubmit: SubmitHandler<IFormValues> = (data) => {
    // 驗證稱謂
    if (!data.gender || data.gender === "") {
      return;
    }

    // 驗證姓名
    if (!data.firstname || data.firstname.trim() === "") {
      return;
    }

    // 驗證電話相關欄位
    if (!data.verificationCode || data.verificationCode === "") {
      return;
    }

    /*
    if (!data.phone || data.phone.trim() === "") {
      return;
    }
    // 驗證電話號碼格式
    const phoneRegex = /^[0-9]+$/;
    if (!phoneRegex.test(data.phone)) {
      return;
    }

    if (data.phone.length < 8 || data.phone.length > 8) {
      return;
    }
    */
    // 驗證生日
    if (!data.birthday || data.birthday === "") {
      return;
    }

    console.log("Form data:", data);
    submitSignUpForm(data);
  };

  React.useEffect(() => {
    API.getPageContentById(5).then((res) => setPageContent(res.content));
  }, []);

  const [phoneMessage, setPhoneMessage] = useState("");
  
  // 监听区号变化
  const handleVerificationCodeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const message = getPhoneValidationMessage(e.target.value);
    setPhoneMessage(message);
    // 清空电话号码输入
    setValue("phone", "");
  };

  // 监听语言变化
  useEffect(() => {
    // 当语言改变时，重新设置所有的 select 值
    setValue("gender", "");  // 重置性别选择
    setValue("verificationCode", "+852");  // 重置区号选择
    setValue("birthday", "");  // 重置生日选择
  }, [i18n.language]);
  
  const sortedCountryCode = React.useMemo(() => {
    const sortedArray = [...countryCode];
    const emptyOption = sortedArray.find(item => item.value === "");
    const sortedItems = sortedArray
      .filter(item => item.value !== "")
      .sort((a, b) => {
        const valueA = parseInt(a.value.replace(/\+|-/g, ''));
        const valueB = parseInt(b.value.replace(/\+|-/g, ''));
        return valueA - valueB;
      });
    return emptyOption ? [emptyOption, ...sortedItems] : sortedItems;
  }, []);

  useEffect(() => {
    // 當語言改變時，重新設置所有的 select 值
    setValue("gender", "");  // 重置性別選擇
    setValue("verificationCode", "+852");  // 重置區號選擇
    setValue("birthday", "");  // 重置生日選擇
    
    // 強制重新渲染所有 Select 組件
    const selectElements = document.querySelectorAll('select');
    selectElements.forEach(select => {
      const event = new Event('change', { bubbles: true });
      select.dispatchEvent(event);
    });
  }, [i18n.language, setValue]);

  return (
    <>
      <div className={style.tabButtonContainer}>
        <Button
          buttonType={"button"}
          styleType={"tabButton"}
          callBack={(value) => navigate("/login")}
        >
          <p className={style.tabButtonText}>{t("signIn.memberSignIn")}</p>
        </Button>

        <Button
          buttonType={"button"}
          styleType={"tabButtonCurrent"}
          callBack={(value) => navigate("/register")}
        >
          <p className={style.tabButtonText}>{t("signUp.signUp")}</p>
        </Button>
      </div>
      <div className={style.abc}>
        <form
          className={style.registerAlignment}
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className={style.registerInnerAlignment}>
            <h5>{t("signUp.pleaseEnterYourDetails")}</h5>
            <p className={style.registerTitle}>
              {/* {t("signUp.fieldsMarkedWithAnAreMandatory")} */}
            </p>
            <div className={style.formRow}>
              <div>
                <Select
                  {...register("gender")}
                  value={genderOptions}
                  id="gender"
                  errors={errors.gender?.message}
                />
              </div>
              <Input2
                id="firstname"
                type="text"
                placeholder={t("signUp.name")}
                register={register}
                errors={errors.firstname?.message}
              />
            </div>
            <div className={style.formRow}>
              <Input2
                id="email"
                type="text"
                placeholder={t("signUp.emailAddress")}
                register={register}
                errors={errors.email?.message}
                disabled={email !== null && email !== "" ? true : false}
              />

              {/*
              <Input
                label="email"
                placeholder="電子郵件"
                register={register}
                pattern={{
                  value: /\S+@\S+\.\S+/,
                  message: "電子郵件格式錯誤",
                }}
                required
              />
              */}
            </div>
            <div className={style.formRow}>
              <div>
                <Select 
                  {...register("verificationCode", {
                    onChange: handleVerificationCodeChange
                  })} 
                  value={sortedCountryCode}
                  id="verificationCode"
                  errors={errors.verificationCode?.message}
                  displayFormat="countryCode"
                />
              </div>
              {/*
              <Input2
                id="verificationCode"
                type="tel"
                placeholder={t("signUp.areaCode")}
                register={register}
                errors={errors.verificationCode?.message}
              />
              */}
              <div>
                <Input2
                  id="phone"
                  type="tel"
                  placeholder={t("signUp.phoneNumber")}
                  register={register}
                  errors={errors.phone?.message}
                />
              </div>
              {/*
              <Input
                type="tel"
                label="phone"
                placeholder="電話"
                register={register}
                required
              />
              */}
            </div>
            {/*
            <div className={style.registerDetailTextContainer}>
              <span className={style.registerDetailText}>
                只需驗證 <span>電郵</span> 或 <span>電話</span> 其中一樣.
              </span>
            </div>
            */}
            {/*
            <div className={style.regsiterNameInputContainer}>
              <Input
                type="text"
                placeholder={"輸入六位數驗証碼"}
                styleType={"loginInput"}
                callBack={() => console.log(1)}
              ></Input>
            </div>
            */}
            {email !== null && email !== "" ? (
              <></>
            ) : (
              <>
                <div className={style.formRow}>
                  {/*
                  <Input
                    type="password"
                    label="password"
                    placeholder="密碼"
                    register={register}
                    required
                    minLength={{
                      value: 8,
                      message: "密碼長度至少8位",
                    }}
                  />
                  */}

                  <Input2
                    id="password"
                    type="password"
                    placeholder={t("signUp.password")}
                    register={register}
                    errors={errors.password?.message}
                  />
                </div>
                <div className={style.formRow}>
                  {/*
                  <Input
                    type="password"
                    label="confirmPassword"
                    placeholder="確認密碼"
                    register={register}
                    required
                    minLength={{
                      value: 8,
                      message: "密碼長度至少8位",
                    }}
                  />
                  */}
                  <Input2
                    id="confirmPassword"
                    type="password"
                    placeholder={t("signUp.confirmPassword")}
                    register={register}
                    errors={errors.confirmPassword?.message}
                  />
                </div>
              </>
            )}

            <div className={style.formRow2}>
              <Select 
                {...register("birthday")} 
                value={monthOptions}  // 使用 monthOptions 替代 month()
                id="birthday"
                errors={errors.birthday?.message}
              />
            </div>
            {/*
            <div className={style.regsiterIDInputContainer}>
              <Input type="text" placeholder={"身份證號碼 *"} styleType={"loginInput"} callBack={() => console.log(1)}></Input>
              <Select firstValue={"年齡組別"} styleType={"registerSelect"} callBack={() => console.log(1)} />
            </div>
            */}
            <div className={style.AgreementRow}>
              <Checkbox
                id="agreement"
                type="checkbox"
                register={register}
                label={t("signUp.agreeConditions") as string}
                errors={errors.agreement?.message}
              />
              {/*
              <span className={style.spanText}>
                本人已閱讀並同意
                <span onClick={() => navigate("/PrivacyPolicy")}>
                  私隱政策
                </span>
                及
                <span onClick={() => navigate("/TermsOfService")}>
                  條款及細則
                </span>

              </span>
              */}
            </div>
            <div className={style.AgreementRow2}>
              <Checkbox
                id="agreement2"
                type="checkbox"
                register={register}
                label={t("signUp.agreeToReceive") as string}
                errors={errors.agreement2?.message}
              />
              {/*
              <span className={style.spanText}>
                本人已閱讀並同意
                <span onClick={() => navigate("/PrivacyPolicy")}>
                  私隱政策
                </span>
                及
                <span onClick={() => navigate("/TermsOfService")}>
                  條款及細則
                </span>

              </span>
              */}
            </div>

            {/* <div className={style.regsiterNameInputContainer}> */}
            {/* <div className={style.tabButtonContainer}> */}
            <input
              type="submit"
              value={isSubmitting ? t("signUp.processing") as string : t("signUp.signUp") as string}
              className={style.submitButton}
              disabled={isSubmitting}
              style={{
                opacity: isSubmitting ? 0.7 : 1,
                cursor: isSubmitting ? 'not-allowed' : 'pointer'
              }}
            />
          </div>
        </form>
      </div>
    </>
  );
}
