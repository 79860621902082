/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { MarketingEventArticle } from './MarketingEventArticle';
import {
    MarketingEventArticleFromJSON,
    MarketingEventArticleFromJSONTyped,
    MarketingEventArticleToJSON,
} from './MarketingEventArticle';
import type { MarketingEventDiscount } from './MarketingEventDiscount';
import {
    MarketingEventDiscountFromJSON,
    MarketingEventDiscountFromJSONTyped,
    MarketingEventDiscountToJSON,
} from './MarketingEventDiscount';
import type { MarketingEventMember } from './MarketingEventMember';
import {
    MarketingEventMemberFromJSON,
    MarketingEventMemberFromJSONTyped,
    MarketingEventMemberToJSON,
} from './MarketingEventMember';
import type { MarketingEventMemberLevel } from './MarketingEventMemberLevel';
import {
    MarketingEventMemberLevelFromJSON,
    MarketingEventMemberLevelFromJSONTyped,
    MarketingEventMemberLevelToJSON,
} from './MarketingEventMemberLevel';
import type { OrderMarketingEvent } from './OrderMarketingEvent';
import {
    OrderMarketingEventFromJSON,
    OrderMarketingEventFromJSONTyped,
    OrderMarketingEventToJSON,
} from './OrderMarketingEvent';

/**
 * 
 * @export
 * @interface MarketingEvent
 */
export interface MarketingEvent {
    /**
     * 
     * @type {string}
     * @memberof MarketingEvent
     */
    bannerContent?: string;
    /**
     * 
     * @type {string}
     * @memberof MarketingEvent
     */
    bannerLinkTo?: string;
    /**
     * 
     * @type {number}
     * @memberof MarketingEvent
     */
    bannerPosition?: number;
    /**
     * 
     * @type {Date}
     * @memberof MarketingEvent
     */
    endDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof MarketingEvent
     */
    expireBannerContent?: string;
    /**
     * 
     * @type {Date}
     * @memberof MarketingEvent
     */
    expireBannerEndDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof MarketingEvent
     */
    isMegaSale?: string;
    /**
     * 
     * @type {Array<MarketingEventArticle>}
     * @memberof MarketingEvent
     */
    marketingEventArticlesByMarketingEventId?: Array<MarketingEventArticle>;
    /**
     * 
     * @type {Array<MarketingEventDiscount>}
     * @memberof MarketingEvent
     */
    marketingEventDiscountsByMarketingEventId?: Array<MarketingEventDiscount>;
    /**
     * 
     * @type {number}
     * @memberof MarketingEvent
     */
    marketingEventId?: number;
    /**
     * 
     * @type {Array<MarketingEventMemberLevel>}
     * @memberof MarketingEvent
     */
    marketingEventMemberLevelsByMarketingEventId?: Array<MarketingEventMemberLevel>;
    /**
     * 
     * @type {Array<MarketingEventMember>}
     * @memberof MarketingEvent
     */
    marketingEventMembersByMarketingEventId?: Array<MarketingEventMember>;
    /**
     * 
     * @type {string}
     * @memberof MarketingEvent
     */
    marketingEventName?: string;
    /**
     * 
     * @type {number}
     * @memberof MarketingEvent
     */
    marketingEventPriority?: number;
    /**
     * 
     * @type {string}
     * @memberof MarketingEvent
     */
    mobileBannerContent?: string;
    /**
     * 
     * @type {string}
     * @memberof MarketingEvent
     */
    mobileExpireBannerContent?: string;
    /**
     * 
     * @type {Array<OrderMarketingEvent>}
     * @memberof MarketingEvent
     */
    orderMarketingEventsByMarketingEventId?: Array<OrderMarketingEvent>;
    /**
     * 
     * @type {string}
     * @memberof MarketingEvent
     */
    promotionCode?: string;
    /**
     * 
     * @type {string}
     * @memberof MarketingEvent
     */
    remark?: string;
    /**
     * 
     * @type {Date}
     * @memberof MarketingEvent
     */
    startDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof MarketingEvent
     */
    status?: string;
}

/**
 * Check if a given object implements the MarketingEvent interface.
 */
export function instanceOfMarketingEvent(value: object): boolean {
    return true;
}

export function MarketingEventFromJSON(json: any): MarketingEvent {
    return MarketingEventFromJSONTyped(json, false);
}

export function MarketingEventFromJSONTyped(json: any, ignoreDiscriminator: boolean): MarketingEvent {
    if (json == null) {
        return json;
    }
    return {
        
        'bannerContent': json['bannerContent'] == null ? undefined : json['bannerContent'],
        'bannerLinkTo': json['bannerLinkTo'] == null ? undefined : json['bannerLinkTo'],
        'bannerPosition': json['bannerPosition'] == null ? undefined : json['bannerPosition'],
        'endDate': json['endDate'] == null ? undefined : (new Date(json['endDate'])),
        'expireBannerContent': json['expireBannerContent'] == null ? undefined : json['expireBannerContent'],
        'expireBannerEndDate': json['expireBannerEndDate'] == null ? undefined : (new Date(json['expireBannerEndDate'])),
        'isMegaSale': json['isMegaSale'] == null ? undefined : json['isMegaSale'],
        'marketingEventArticlesByMarketingEventId': json['marketingEventArticlesByMarketingEventId'] == null ? undefined : ((json['marketingEventArticlesByMarketingEventId'] as Array<any>).map(MarketingEventArticleFromJSON)),
        'marketingEventDiscountsByMarketingEventId': json['marketingEventDiscountsByMarketingEventId'] == null ? undefined : ((json['marketingEventDiscountsByMarketingEventId'] as Array<any>).map(MarketingEventDiscountFromJSON)),
        'marketingEventId': json['marketingEventId'] == null ? undefined : json['marketingEventId'],
        'marketingEventMemberLevelsByMarketingEventId': json['marketingEventMemberLevelsByMarketingEventId'] == null ? undefined : ((json['marketingEventMemberLevelsByMarketingEventId'] as Array<any>).map(MarketingEventMemberLevelFromJSON)),
        'marketingEventMembersByMarketingEventId': json['marketingEventMembersByMarketingEventId'] == null ? undefined : ((json['marketingEventMembersByMarketingEventId'] as Array<any>).map(MarketingEventMemberFromJSON)),
        'marketingEventName': json['marketingEventName'] == null ? undefined : json['marketingEventName'],
        'marketingEventPriority': json['marketingEventPriority'] == null ? undefined : json['marketingEventPriority'],
        'mobileBannerContent': json['mobileBannerContent'] == null ? undefined : json['mobileBannerContent'],
        'mobileExpireBannerContent': json['mobileExpireBannerContent'] == null ? undefined : json['mobileExpireBannerContent'],
        'orderMarketingEventsByMarketingEventId': json['orderMarketingEventsByMarketingEventId'] == null ? undefined : ((json['orderMarketingEventsByMarketingEventId'] as Array<any>).map(OrderMarketingEventFromJSON)),
        'promotionCode': json['promotionCode'] == null ? undefined : json['promotionCode'],
        'remark': json['remark'] == null ? undefined : json['remark'],
        'startDate': json['startDate'] == null ? undefined : (new Date(json['startDate'])),
        'status': json['status'] == null ? undefined : json['status'],
    };
}

export function MarketingEventToJSON(value?: MarketingEvent | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'bannerContent': value['bannerContent'],
        'bannerLinkTo': value['bannerLinkTo'],
        'bannerPosition': value['bannerPosition'],
        'endDate': value['endDate'] == null ? undefined : ((value['endDate']).toISOString()),
        'expireBannerContent': value['expireBannerContent'],
        'expireBannerEndDate': value['expireBannerEndDate'] == null ? undefined : ((value['expireBannerEndDate']).toISOString()),
        'isMegaSale': value['isMegaSale'],
        'marketingEventArticlesByMarketingEventId': value['marketingEventArticlesByMarketingEventId'] == null ? undefined : ((value['marketingEventArticlesByMarketingEventId'] as Array<any>).map(MarketingEventArticleToJSON)),
        'marketingEventDiscountsByMarketingEventId': value['marketingEventDiscountsByMarketingEventId'] == null ? undefined : ((value['marketingEventDiscountsByMarketingEventId'] as Array<any>).map(MarketingEventDiscountToJSON)),
        'marketingEventId': value['marketingEventId'],
        'marketingEventMemberLevelsByMarketingEventId': value['marketingEventMemberLevelsByMarketingEventId'] == null ? undefined : ((value['marketingEventMemberLevelsByMarketingEventId'] as Array<any>).map(MarketingEventMemberLevelToJSON)),
        'marketingEventMembersByMarketingEventId': value['marketingEventMembersByMarketingEventId'] == null ? undefined : ((value['marketingEventMembersByMarketingEventId'] as Array<any>).map(MarketingEventMemberToJSON)),
        'marketingEventName': value['marketingEventName'],
        'marketingEventPriority': value['marketingEventPriority'],
        'mobileBannerContent': value['mobileBannerContent'],
        'mobileExpireBannerContent': value['mobileExpireBannerContent'],
        'orderMarketingEventsByMarketingEventId': value['orderMarketingEventsByMarketingEventId'] == null ? undefined : ((value['orderMarketingEventsByMarketingEventId'] as Array<any>).map(OrderMarketingEventToJSON)),
        'promotionCode': value['promotionCode'],
        'remark': value['remark'],
        'startDate': value['startDate'] == null ? undefined : ((value['startDate']).toISOString()),
        'status': value['status'],
    };
}

