/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  Resource,
  SubscriptionEmail,
} from '../models/index';
import {
    ResourceFromJSON,
    ResourceToJSON,
    SubscriptionEmailFromJSON,
    SubscriptionEmailToJSON,
} from '../models/index';

export interface AddSubscriptionEmailUsingPOSTRequest {
    email?: string;
}

/**
 * 
 */
export class SubscriptionEmailControllerApi extends runtime.BaseAPI {

    /**
     * addSubscriptionEmail
     */
    async addSubscriptionEmailUsingPOSTRaw(requestParameters: AddSubscriptionEmailUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SubscriptionEmail>> {
        const queryParameters: any = {};

        if (requestParameters['email'] != null) {
            queryParameters['email'] = requestParameters['email'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/subscription_email/add`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SubscriptionEmailFromJSON(jsonValue));
    }

    /**
     * addSubscriptionEmail
     */
    async addSubscriptionEmailUsingPOST(requestParameters: AddSubscriptionEmailUsingPOSTRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SubscriptionEmail | null | undefined > {
        const response = await this.addSubscriptionEmailUsingPOSTRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 201:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * exportSubscriptionEmail
     */
    async exportSubscriptionEmailUsingGETRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Resource>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/subscription_email/export`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResourceFromJSON(jsonValue));
    }

    /**
     * exportSubscriptionEmail
     */
    async exportSubscriptionEmailUsingGET(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Resource> {
        const response = await this.exportSubscriptionEmailUsingGETRaw(initOverrides);
        return await response.value();
    }

    /**
     * getSubscriptionEmail
     */
    async getSubscriptionEmailUsingGETRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<SubscriptionEmail>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/subscription_email/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SubscriptionEmailFromJSON));
    }

    /**
     * getSubscriptionEmail
     */
    async getSubscriptionEmailUsingGET(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<SubscriptionEmail>> {
        const response = await this.getSubscriptionEmailUsingGETRaw(initOverrides);
        return await response.value();
    }

}
