/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Product } from './Product';
import {
    ProductFromJSON,
    ProductFromJSONTyped,
    ProductToJSON,
} from './Product';

/**
 * 
 * @export
 * @interface ProductFile
 */
export interface ProductFile {
    /**
     * 
     * @type {string}
     * @memberof ProductFile
     */
    fileType?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductFile
     */
    fileUrl?: string;
    /**
     * 
     * @type {Product}
     * @memberof ProductFile
     */
    productByProductId?: Product;
    /**
     * 
     * @type {number}
     * @memberof ProductFile
     */
    productFileId?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductFile
     */
    productId?: number;
}

/**
 * Check if a given object implements the ProductFile interface.
 */
export function instanceOfProductFile(value: object): boolean {
    return true;
}

export function ProductFileFromJSON(json: any): ProductFile {
    return ProductFileFromJSONTyped(json, false);
}

export function ProductFileFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProductFile {
    if (json == null) {
        return json;
    }
    return {
        
        'fileType': json['fileType'] == null ? undefined : json['fileType'],
        'fileUrl': json['fileUrl'] == null ? undefined : json['fileUrl'],
        'productByProductId': json['productByProductId'] == null ? undefined : ProductFromJSON(json['productByProductId']),
        'productFileId': json['productFileId'] == null ? undefined : json['productFileId'],
        'productId': json['productId'] == null ? undefined : json['productId'],
    };
}

export function ProductFileToJSON(value?: ProductFile | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'fileType': value['fileType'],
        'fileUrl': value['fileUrl'],
        'productByProductId': ProductToJSON(value['productByProductId']),
        'productFileId': value['productFileId'],
        'productId': value['productId'],
    };
}

