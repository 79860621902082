/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Coupon } from './Coupon';
import {
    CouponFromJSON,
    CouponFromJSONTyped,
    CouponToJSON,
} from './Coupon';
import type { MemberShoppingCart } from './MemberShoppingCart';
import {
    MemberShoppingCartFromJSON,
    MemberShoppingCartFromJSONTyped,
    MemberShoppingCartToJSON,
} from './MemberShoppingCart';

/**
 * 
 * @export
 * @interface ShoppingCartCoupon
 */
export interface ShoppingCartCoupon {
    /**
     * 
     * @type {Coupon}
     * @memberof ShoppingCartCoupon
     */
    couponByCouponId?: Coupon;
    /**
     * 
     * @type {number}
     * @memberof ShoppingCartCoupon
     */
    couponId?: number;
    /**
     * 
     * @type {MemberShoppingCart}
     * @memberof ShoppingCartCoupon
     */
    shoppingCartByShoppingCartId?: MemberShoppingCart;
    /**
     * 
     * @type {number}
     * @memberof ShoppingCartCoupon
     */
    shoppingCartId?: number;
}

/**
 * Check if a given object implements the ShoppingCartCoupon interface.
 */
export function instanceOfShoppingCartCoupon(value: object): boolean {
    return true;
}

export function ShoppingCartCouponFromJSON(json: any): ShoppingCartCoupon {
    return ShoppingCartCouponFromJSONTyped(json, false);
}

export function ShoppingCartCouponFromJSONTyped(json: any, ignoreDiscriminator: boolean): ShoppingCartCoupon {
    if (json == null) {
        return json;
    }
    return {
        
        'couponByCouponId': json['couponByCouponId'] == null ? undefined : CouponFromJSON(json['couponByCouponId']),
        'couponId': json['couponId'] == null ? undefined : json['couponId'],
        'shoppingCartByShoppingCartId': json['shoppingCartByShoppingCartId'] == null ? undefined : MemberShoppingCartFromJSON(json['shoppingCartByShoppingCartId']),
        'shoppingCartId': json['shoppingCartId'] == null ? undefined : json['shoppingCartId'],
    };
}

export function ShoppingCartCouponToJSON(value?: ShoppingCartCoupon | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'couponByCouponId': CouponToJSON(value['couponByCouponId']),
        'couponId': value['couponId'],
        'shoppingCartByShoppingCartId': MemberShoppingCartToJSON(value['shoppingCartByShoppingCartId']),
        'shoppingCartId': value['shoppingCartId'],
    };
}

