/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CKEditorResponseDTO
 */
export interface CKEditorResponseDTO {
    /**
     * 
     * @type {string}
     * @memberof CKEditorResponseDTO
     */
    fileName?: string;
    /**
     * 
     * @type {number}
     * @memberof CKEditorResponseDTO
     */
    uploaded?: number;
    /**
     * 
     * @type {string}
     * @memberof CKEditorResponseDTO
     */
    url?: string;
}

/**
 * Check if a given object implements the CKEditorResponseDTO interface.
 */
export function instanceOfCKEditorResponseDTO(value: object): boolean {
    return true;
}

export function CKEditorResponseDTOFromJSON(json: any): CKEditorResponseDTO {
    return CKEditorResponseDTOFromJSONTyped(json, false);
}

export function CKEditorResponseDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): CKEditorResponseDTO {
    if (json == null) {
        return json;
    }
    return {
        
        'fileName': json['fileName'] == null ? undefined : json['fileName'],
        'uploaded': json['uploaded'] == null ? undefined : json['uploaded'],
        'url': json['url'] == null ? undefined : json['url'],
    };
}

export function CKEditorResponseDTOToJSON(value?: CKEditorResponseDTO | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'fileName': value['fileName'],
        'uploaded': value['uploaded'],
        'url': value['url'],
    };
}

