/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SfLocker
 */
export interface SfLocker {
    /**
     * 
     * @type {string}
     * @memberof SfLocker
     */
    address?: string;
    /**
     * 
     * @type {string}
     * @memberof SfLocker
     */
    addressCity?: string;
    /**
     * 
     * @type {string}
     * @memberof SfLocker
     */
    addressProvince?: string;
    /**
     * 
     * @type {string}
     * @memberof SfLocker
     */
    addressZone?: string;
    /**
     * 
     * @type {string}
     * @memberof SfLocker
     */
    area?: string;
    /**
     * 
     * @type {string}
     * @memberof SfLocker
     */
    sfLockerId?: string;
    /**
     * 
     * @type {number}
     * @memberof SfLocker
     */
    shippingFree?: number;
}

/**
 * Check if a given object implements the SfLocker interface.
 */
export function instanceOfSfLocker(value: object): boolean {
    return true;
}

export function SfLockerFromJSON(json: any): SfLocker {
    return SfLockerFromJSONTyped(json, false);
}

export function SfLockerFromJSONTyped(json: any, ignoreDiscriminator: boolean): SfLocker {
    if (json == null) {
        return json;
    }
    return {
        
        'address': json['address'] == null ? undefined : json['address'],
        'addressCity': json['addressCity'] == null ? undefined : json['addressCity'],
        'addressProvince': json['addressProvince'] == null ? undefined : json['addressProvince'],
        'addressZone': json['addressZone'] == null ? undefined : json['addressZone'],
        'area': json['area'] == null ? undefined : json['area'],
        'sfLockerId': json['sfLockerId'] == null ? undefined : json['sfLockerId'],
        'shippingFree': json['shippingFree'] == null ? undefined : json['shippingFree'],
    };
}

export function SfLockerToJSON(value?: SfLocker | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'address': value['address'],
        'addressCity': value['addressCity'],
        'addressProvince': value['addressProvince'],
        'addressZone': value['addressZone'],
        'area': value['area'],
        'sfLockerId': value['sfLockerId'],
        'shippingFree': value['shippingFree'],
    };
}

