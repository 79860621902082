/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { DisplayProduct } from './DisplayProduct';
import {
    DisplayProductFromJSON,
    DisplayProductFromJSONTyped,
    DisplayProductToJSON,
} from './DisplayProduct';
import type { Product } from './Product';
import {
    ProductFromJSON,
    ProductFromJSONTyped,
    ProductToJSON,
} from './Product';

/**
 * 
 * @export
 * @interface DisplayProductProduct
 */
export interface DisplayProductProduct {
    /**
     * 
     * @type {DisplayProduct}
     * @memberof DisplayProductProduct
     */
    displayProductByDisplayProductId?: DisplayProduct;
    /**
     * 
     * @type {number}
     * @memberof DisplayProductProduct
     */
    displayProductId?: number;
    /**
     * 
     * @type {Product}
     * @memberof DisplayProductProduct
     */
    productByProductId?: Product;
    /**
     * 
     * @type {number}
     * @memberof DisplayProductProduct
     */
    productId?: number;
}

/**
 * Check if a given object implements the DisplayProductProduct interface.
 */
export function instanceOfDisplayProductProduct(value: object): boolean {
    return true;
}

export function DisplayProductProductFromJSON(json: any): DisplayProductProduct {
    return DisplayProductProductFromJSONTyped(json, false);
}

export function DisplayProductProductFromJSONTyped(json: any, ignoreDiscriminator: boolean): DisplayProductProduct {
    if (json == null) {
        return json;
    }
    return {
        
        'displayProductByDisplayProductId': json['displayProductByDisplayProductId'] == null ? undefined : DisplayProductFromJSON(json['displayProductByDisplayProductId']),
        'displayProductId': json['displayProductId'] == null ? undefined : json['displayProductId'],
        'productByProductId': json['productByProductId'] == null ? undefined : ProductFromJSON(json['productByProductId']),
        'productId': json['productId'] == null ? undefined : json['productId'],
    };
}

export function DisplayProductProductToJSON(value?: DisplayProductProduct | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'displayProductByDisplayProductId': DisplayProductToJSON(value['displayProductByDisplayProductId']),
        'displayProductId': value['displayProductId'],
        'productByProductId': ProductToJSON(value['productByProductId']),
        'productId': value['productId'],
    };
}

