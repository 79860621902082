/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AdminMemberDto,
} from '../models/index';
import {
    AdminMemberDtoFromJSON,
    AdminMemberDtoToJSON,
} from '../models/index';

export interface AddAdminMemberUsingPOSTRequest {
    adminMemberRole: number;
    firstname: string;
    lastname: string;
    password: string;
    username: string;
    name?: string;
}

export interface AddAdminMemberUsingPOST1Request {
    adminMemberRole: number;
    firstname: string;
    lastname: string;
    password: string;
    username: string;
    name?: string;
}

export interface DeleteAdminMemberUsingDELETERequest {
    adminMemberId: number;
    name?: string;
}

export interface GetAdminMemberByPrincipalUsingGETRequest {
    name?: string;
}

export interface GetAdminMemberByPrincipalUsingGET1Request {
    name?: string;
}

export interface GetAdminMemberUsingGETRequest {
    adminMemberId: number;
    name?: string;
}

export interface GetAllAdminMemberUsingGETRequest {
    name?: string;
}

export interface UpdateAdminMemberUsingPUTRequest {
    adminMemberId: number;
    name?: string;
    adminMemberRole?: number;
    firstname?: string;
    lastname?: string;
    password?: string;
    username?: string;
}

/**
 * 
 */
export class AdminMemberControllerApi extends runtime.BaseAPI {

    /**
     * addAdminMember
     */
    async addAdminMemberUsingPOSTRaw(requestParameters: AddAdminMemberUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AdminMemberDto>> {
        if (requestParameters['adminMemberRole'] == null) {
            throw new runtime.RequiredError(
                'adminMemberRole',
                'Required parameter "adminMemberRole" was null or undefined when calling addAdminMemberUsingPOST().'
            );
        }

        if (requestParameters['firstname'] == null) {
            throw new runtime.RequiredError(
                'firstname',
                'Required parameter "firstname" was null or undefined when calling addAdminMemberUsingPOST().'
            );
        }

        if (requestParameters['lastname'] == null) {
            throw new runtime.RequiredError(
                'lastname',
                'Required parameter "lastname" was null or undefined when calling addAdminMemberUsingPOST().'
            );
        }

        if (requestParameters['password'] == null) {
            throw new runtime.RequiredError(
                'password',
                'Required parameter "password" was null or undefined when calling addAdminMemberUsingPOST().'
            );
        }

        if (requestParameters['username'] == null) {
            throw new runtime.RequiredError(
                'username',
                'Required parameter "username" was null or undefined when calling addAdminMemberUsingPOST().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        if (requestParameters['adminMemberRole'] != null) {
            queryParameters['adminMemberRole'] = requestParameters['adminMemberRole'];
        }

        if (requestParameters['firstname'] != null) {
            queryParameters['firstname'] = requestParameters['firstname'];
        }

        if (requestParameters['lastname'] != null) {
            queryParameters['lastname'] = requestParameters['lastname'];
        }

        if (requestParameters['password'] != null) {
            queryParameters['password'] = requestParameters['password'];
        }

        if (requestParameters['username'] != null) {
            queryParameters['username'] = requestParameters['username'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/adminMember`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AdminMemberDtoFromJSON(jsonValue));
    }

    /**
     * addAdminMember
     */
    async addAdminMemberUsingPOST(requestParameters: AddAdminMemberUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AdminMemberDto | null | undefined > {
        const response = await this.addAdminMemberUsingPOSTRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 201:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * addAdminMember
     */
    async addAdminMemberUsingPOST1Raw(requestParameters: AddAdminMemberUsingPOST1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AdminMemberDto>> {
        if (requestParameters['adminMemberRole'] == null) {
            throw new runtime.RequiredError(
                'adminMemberRole',
                'Required parameter "adminMemberRole" was null or undefined when calling addAdminMemberUsingPOST1().'
            );
        }

        if (requestParameters['firstname'] == null) {
            throw new runtime.RequiredError(
                'firstname',
                'Required parameter "firstname" was null or undefined when calling addAdminMemberUsingPOST1().'
            );
        }

        if (requestParameters['lastname'] == null) {
            throw new runtime.RequiredError(
                'lastname',
                'Required parameter "lastname" was null or undefined when calling addAdminMemberUsingPOST1().'
            );
        }

        if (requestParameters['password'] == null) {
            throw new runtime.RequiredError(
                'password',
                'Required parameter "password" was null or undefined when calling addAdminMemberUsingPOST1().'
            );
        }

        if (requestParameters['username'] == null) {
            throw new runtime.RequiredError(
                'username',
                'Required parameter "username" was null or undefined when calling addAdminMemberUsingPOST1().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        if (requestParameters['adminMemberRole'] != null) {
            queryParameters['adminMemberRole'] = requestParameters['adminMemberRole'];
        }

        if (requestParameters['firstname'] != null) {
            queryParameters['firstname'] = requestParameters['firstname'];
        }

        if (requestParameters['lastname'] != null) {
            queryParameters['lastname'] = requestParameters['lastname'];
        }

        if (requestParameters['password'] != null) {
            queryParameters['password'] = requestParameters['password'];
        }

        if (requestParameters['username'] != null) {
            queryParameters['username'] = requestParameters['username'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/adminMember/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AdminMemberDtoFromJSON(jsonValue));
    }

    /**
     * addAdminMember
     */
    async addAdminMemberUsingPOST1(requestParameters: AddAdminMemberUsingPOST1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AdminMemberDto | null | undefined > {
        const response = await this.addAdminMemberUsingPOST1Raw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 201:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * deleteAdminMember
     */
    async deleteAdminMemberUsingDELETERaw(requestParameters: DeleteAdminMemberUsingDELETERequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['adminMemberId'] == null) {
            throw new runtime.RequiredError(
                'adminMemberId',
                'Required parameter "adminMemberId" was null or undefined when calling deleteAdminMemberUsingDELETE().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/adminMember/{adminMemberId}`.replace(`{${"adminMemberId"}}`, encodeURIComponent(String(requestParameters['adminMemberId']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * deleteAdminMember
     */
    async deleteAdminMemberUsingDELETE(requestParameters: DeleteAdminMemberUsingDELETERequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteAdminMemberUsingDELETERaw(requestParameters, initOverrides);
    }

    /**
     * getAdminMemberByPrincipal
     */
    async getAdminMemberByPrincipalUsingGETRaw(requestParameters: GetAdminMemberByPrincipalUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AdminMemberDto>> {
        const queryParameters: any = {};

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/adminMember`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AdminMemberDtoFromJSON(jsonValue));
    }

    /**
     * getAdminMemberByPrincipal
     */
    async getAdminMemberByPrincipalUsingGET(requestParameters: GetAdminMemberByPrincipalUsingGETRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AdminMemberDto> {
        const response = await this.getAdminMemberByPrincipalUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getAdminMemberByPrincipal
     */
    async getAdminMemberByPrincipalUsingGET1Raw(requestParameters: GetAdminMemberByPrincipalUsingGET1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AdminMemberDto>> {
        const queryParameters: any = {};

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/adminMember/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AdminMemberDtoFromJSON(jsonValue));
    }

    /**
     * getAdminMemberByPrincipal
     */
    async getAdminMemberByPrincipalUsingGET1(requestParameters: GetAdminMemberByPrincipalUsingGET1Request = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AdminMemberDto> {
        const response = await this.getAdminMemberByPrincipalUsingGET1Raw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getAdminMember
     */
    async getAdminMemberUsingGETRaw(requestParameters: GetAdminMemberUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AdminMemberDto>> {
        if (requestParameters['adminMemberId'] == null) {
            throw new runtime.RequiredError(
                'adminMemberId',
                'Required parameter "adminMemberId" was null or undefined when calling getAdminMemberUsingGET().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/adminMember/{adminMemberId}`.replace(`{${"adminMemberId"}}`, encodeURIComponent(String(requestParameters['adminMemberId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AdminMemberDtoFromJSON(jsonValue));
    }

    /**
     * getAdminMember
     */
    async getAdminMemberUsingGET(requestParameters: GetAdminMemberUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AdminMemberDto> {
        const response = await this.getAdminMemberUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getAllAdminMember
     */
    async getAllAdminMemberUsingGETRaw(requestParameters: GetAllAdminMemberUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<AdminMemberDto>>> {
        const queryParameters: any = {};

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/adminMember/all`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(AdminMemberDtoFromJSON));
    }

    /**
     * getAllAdminMember
     */
    async getAllAdminMemberUsingGET(requestParameters: GetAllAdminMemberUsingGETRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<AdminMemberDto>> {
        const response = await this.getAllAdminMemberUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * updateAdminMember
     */
    async updateAdminMemberUsingPUTRaw(requestParameters: UpdateAdminMemberUsingPUTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AdminMemberDto>> {
        if (requestParameters['adminMemberId'] == null) {
            throw new runtime.RequiredError(
                'adminMemberId',
                'Required parameter "adminMemberId" was null or undefined when calling updateAdminMemberUsingPUT().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        if (requestParameters['adminMemberRole'] != null) {
            queryParameters['adminMemberRole'] = requestParameters['adminMemberRole'];
        }

        if (requestParameters['firstname'] != null) {
            queryParameters['firstname'] = requestParameters['firstname'];
        }

        if (requestParameters['lastname'] != null) {
            queryParameters['lastname'] = requestParameters['lastname'];
        }

        if (requestParameters['password'] != null) {
            queryParameters['password'] = requestParameters['password'];
        }

        if (requestParameters['username'] != null) {
            queryParameters['username'] = requestParameters['username'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/adminMember/{adminMemberId}`.replace(`{${"adminMemberId"}}`, encodeURIComponent(String(requestParameters['adminMemberId']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AdminMemberDtoFromJSON(jsonValue));
    }

    /**
     * updateAdminMember
     */
    async updateAdminMemberUsingPUT(requestParameters: UpdateAdminMemberUsingPUTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AdminMemberDto | null | undefined > {
        const response = await this.updateAdminMemberUsingPUTRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 201:
                return null;
            default:
                return await response.value();
        }
    }

}
