/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';

export interface GetPagedOrderAdminUsingGET1Request {
    payRef: string;
    ref: string;
    successcode: string;
}

/**
 * 
 */
export class PaydollarDataFeedControllerApi extends runtime.BaseAPI {

    /**
     * getPagedOrderAdmin
     */
    async getPagedOrderAdminUsingGET1Raw(requestParameters: GetPagedOrderAdminUsingGET1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        if (requestParameters['payRef'] == null) {
            throw new runtime.RequiredError(
                'payRef',
                'Required parameter "payRef" was null or undefined when calling getPagedOrderAdminUsingGET1().'
            );
        }

        if (requestParameters['ref'] == null) {
            throw new runtime.RequiredError(
                'ref',
                'Required parameter "ref" was null or undefined when calling getPagedOrderAdminUsingGET1().'
            );
        }

        if (requestParameters['successcode'] == null) {
            throw new runtime.RequiredError(
                'successcode',
                'Required parameter "successcode" was null or undefined when calling getPagedOrderAdminUsingGET1().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['payRef'] != null) {
            queryParameters['PayRef'] = requestParameters['payRef'];
        }

        if (requestParameters['ref'] != null) {
            queryParameters['Ref'] = requestParameters['ref'];
        }

        if (requestParameters['successcode'] != null) {
            queryParameters['successcode'] = requestParameters['successcode'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/v1/paydollar/receiver`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * getPagedOrderAdmin
     */
    async getPagedOrderAdminUsingGET1(requestParameters: GetPagedOrderAdminUsingGET1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object> {
        const response = await this.getPagedOrderAdminUsingGET1Raw(requestParameters, initOverrides);
        return await response.value();
    }

}
