/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Discount } from './Discount';
import {
    DiscountFromJSON,
    DiscountFromJSONTyped,
    DiscountToJSON,
} from './Discount';
import type { Product } from './Product';
import {
    ProductFromJSON,
    ProductFromJSONTyped,
    ProductToJSON,
} from './Product';

/**
 * 
 * @export
 * @interface DiscountProduct
 */
export interface DiscountProduct {
    /**
     * 
     * @type {Discount}
     * @memberof DiscountProduct
     */
    discountByDiscountId?: Discount;
    /**
     * 
     * @type {number}
     * @memberof DiscountProduct
     */
    discountId?: number;
    /**
     * 
     * @type {Product}
     * @memberof DiscountProduct
     */
    productByProductId?: Product;
    /**
     * 
     * @type {number}
     * @memberof DiscountProduct
     */
    productId?: number;
}

/**
 * Check if a given object implements the DiscountProduct interface.
 */
export function instanceOfDiscountProduct(value: object): boolean {
    return true;
}

export function DiscountProductFromJSON(json: any): DiscountProduct {
    return DiscountProductFromJSONTyped(json, false);
}

export function DiscountProductFromJSONTyped(json: any, ignoreDiscriminator: boolean): DiscountProduct {
    if (json == null) {
        return json;
    }
    return {
        
        'discountByDiscountId': json['discountByDiscountId'] == null ? undefined : DiscountFromJSON(json['discountByDiscountId']),
        'discountId': json['discountId'] == null ? undefined : json['discountId'],
        'productByProductId': json['productByProductId'] == null ? undefined : ProductFromJSON(json['productByProductId']),
        'productId': json['productId'] == null ? undefined : json['productId'],
    };
}

export function DiscountProductToJSON(value?: DiscountProduct | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'discountByDiscountId': DiscountToJSON(value['discountByDiscountId']),
        'discountId': value['discountId'],
        'productByProductId': ProductToJSON(value['productByProductId']),
        'productId': value['productId'],
    };
}

