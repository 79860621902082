/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { DiscountDto } from './DiscountDto';
import {
    DiscountDtoFromJSON,
    DiscountDtoFromJSONTyped,
    DiscountDtoToJSON,
} from './DiscountDto';
import type { ProductDto } from './ProductDto';
import {
    ProductDtoFromJSON,
    ProductDtoFromJSONTyped,
    ProductDtoToJSON,
} from './ProductDto';
import type { ProductPointDto } from './ProductPointDto';
import {
    ProductPointDtoFromJSON,
    ProductPointDtoFromJSONTyped,
    ProductPointDtoToJSON,
} from './ProductPointDto';

/**
 * 
 * @export
 * @interface CartProductDto
 */
export interface CartProductDto {
    /**
     * 
     * @type {number}
     * @memberof CartProductDto
     */
    cartProductId?: number;
    /**
     * 
     * @type {number}
     * @memberof CartProductDto
     */
    deliveryTimes?: number;
    /**
     * 
     * @type {Array<DiscountDto>}
     * @memberof CartProductDto
     */
    discounts?: Array<DiscountDto>;
    /**
     * 
     * @type {ProductDto}
     * @memberof CartProductDto
     */
    product?: ProductDto;
    /**
     * 
     * @type {number}
     * @memberof CartProductDto
     */
    productDiscountPrice?: number;
    /**
     * 
     * @type {number}
     * @memberof CartProductDto
     */
    productDiscountPriceTotal?: number;
    /**
     * 
     * @type {ProductPointDto}
     * @memberof CartProductDto
     */
    productPoint?: ProductPointDto;
    /**
     * 
     * @type {number}
     * @memberof CartProductDto
     */
    productPrice?: number;
    /**
     * 
     * @type {number}
     * @memberof CartProductDto
     */
    productPriceTotal?: number;
    /**
     * 
     * @type {number}
     * @memberof CartProductDto
     */
    quantity?: number;
}

/**
 * Check if a given object implements the CartProductDto interface.
 */
export function instanceOfCartProductDto(value: object): boolean {
    return true;
}

export function CartProductDtoFromJSON(json: any): CartProductDto {
    return CartProductDtoFromJSONTyped(json, false);
}

export function CartProductDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CartProductDto {
    if (json == null) {
        return json;
    }
    return {
        
        'cartProductId': json['cartProductId'] == null ? undefined : json['cartProductId'],
        'deliveryTimes': json['deliveryTimes'] == null ? undefined : json['deliveryTimes'],
        'discounts': json['discounts'] == null ? undefined : ((json['discounts'] as Array<any>).map(DiscountDtoFromJSON)),
        'product': json['product'] == null ? undefined : ProductDtoFromJSON(json['product']),
        'productDiscountPrice': json['productDiscountPrice'] == null ? undefined : json['productDiscountPrice'],
        'productDiscountPriceTotal': json['productDiscountPriceTotal'] == null ? undefined : json['productDiscountPriceTotal'],
        'productPoint': json['productPoint'] == null ? undefined : ProductPointDtoFromJSON(json['productPoint']),
        'productPrice': json['productPrice'] == null ? undefined : json['productPrice'],
        'productPriceTotal': json['productPriceTotal'] == null ? undefined : json['productPriceTotal'],
        'quantity': json['quantity'] == null ? undefined : json['quantity'],
    };
}

export function CartProductDtoToJSON(value?: CartProductDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'cartProductId': value['cartProductId'],
        'deliveryTimes': value['deliveryTimes'],
        'discounts': value['discounts'] == null ? undefined : ((value['discounts'] as Array<any>).map(DiscountDtoToJSON)),
        'product': ProductDtoToJSON(value['product']),
        'productDiscountPrice': value['productDiscountPrice'],
        'productDiscountPriceTotal': value['productDiscountPriceTotal'],
        'productPoint': ProductPointDtoToJSON(value['productPoint']),
        'productPrice': value['productPrice'],
        'productPriceTotal': value['productPriceTotal'],
        'quantity': value['quantity'],
    };
}

