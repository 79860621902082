/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Award } from './Award';
import {
    AwardFromJSON,
    AwardFromJSONTyped,
    AwardToJSON,
} from './Award';
import type { Product } from './Product';
import {
    ProductFromJSON,
    ProductFromJSONTyped,
    ProductToJSON,
} from './Product';

/**
 * 
 * @export
 * @interface ProductAward
 */
export interface ProductAward {
    /**
     * 
     * @type {Award}
     * @memberof ProductAward
     */
    awardByAwardId?: Award;
    /**
     * 
     * @type {number}
     * @memberof ProductAward
     */
    awardId?: number;
    /**
     * 
     * @type {Product}
     * @memberof ProductAward
     */
    productByProductId?: Product;
    /**
     * 
     * @type {number}
     * @memberof ProductAward
     */
    productId?: number;
}

/**
 * Check if a given object implements the ProductAward interface.
 */
export function instanceOfProductAward(value: object): boolean {
    return true;
}

export function ProductAwardFromJSON(json: any): ProductAward {
    return ProductAwardFromJSONTyped(json, false);
}

export function ProductAwardFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProductAward {
    if (json == null) {
        return json;
    }
    return {
        
        'awardByAwardId': json['awardByAwardId'] == null ? undefined : AwardFromJSON(json['awardByAwardId']),
        'awardId': json['awardId'] == null ? undefined : json['awardId'],
        'productByProductId': json['productByProductId'] == null ? undefined : ProductFromJSON(json['productByProductId']),
        'productId': json['productId'] == null ? undefined : json['productId'],
    };
}

export function ProductAwardToJSON(value?: ProductAward | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'awardByAwardId': AwardToJSON(value['awardByAwardId']),
        'awardId': value['awardId'],
        'productByProductId': ProductToJSON(value['productByProductId']),
        'productId': value['productId'],
    };
}

