/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  MarketingEventDto,
  OffShelfModel,
  ProductDto,
  Resource,
  SuccessResponseModel,
} from '../models/index';
import {
    MarketingEventDtoFromJSON,
    MarketingEventDtoToJSON,
    OffShelfModelFromJSON,
    OffShelfModelToJSON,
    ProductDtoFromJSON,
    ProductDtoToJSON,
    ResourceFromJSON,
    ResourceToJSON,
    SuccessResponseModelFromJSON,
    SuccessResponseModelToJSON,
} from '../models/index';

export interface AddProductInventoryUsingPOSTRequest {
    productId: number;
    num: number;
    name?: string;
}

export interface AddProductPointUsingPOSTRequest {
    productId: number;
    point: number;
    money: number;
    name?: string;
}

export interface AddProductUsingPOSTRequest {
    productNameChi: string;
    productNameEng: string;
    isGroupProduct: string;
    productPrice: number;
    name?: string;
    categoryIds?: number;
    productDescription?: string;
    onShelfDate?: Date;
    offShelfDate?: Date;
    productInventory?: number;
    subscriptionPeriod?: string;
    productVolume?: string;
    productVolumeUnit?: string;
    productImage?: string;
    productIds?: number;
    sku?: string;
    productOriginalPrice?: number;
    ctsId?: string;
    bookIsbn?: string;
    body?: Blob;
}

export interface CheckProductStatusUsingPOSTRequest {
    productIsbn: string;
    name?: string;
}

export interface DeleteProductPointUsingPOSTRequest {
    productId: number;
    productPointId: number;
    name?: string;
}

export interface DeleteProductUsingPOST2Request {
    productId: number;
    name?: string;
}

export interface DisableProductPointUsingPOSTRequest {
    productId: number;
    productPointId: number;
    name?: string;
}

export interface EnableProductPointUsingPOSTRequest {
    productId: number;
    productPointId: number;
    name?: string;
}

export interface GetPagedProductAdminUsingGETRequest {
    page: string;
    size: string;
    name?: string;
}

export interface GetPagedProductUsingGETRequest {
    page: string;
    size: string;
}

export interface GetPagedProductWithPointAdminUsingGETRequest {
    page: string;
    size: string;
    name?: string;
}

export interface GetPagedProductWithPointUsingGETRequest {
    page: string;
    size: string;
}

export interface GetProductByCategoryIdAdminUsingGETRequest {
    categoryId: number;
    name?: string;
}

export interface GetProductByCategoryIdUsingGETRequest {
    categoryId: number;
}

export interface GetProductByGroupProductIdAdminUsingGETRequest {
    groupProductId: number;
    name?: string;
}

export interface GetProductByGroupProductIdUsingGETRequest {
    groupProductId: number;
}

export interface GetProductByProductIdAdminUsingGETRequest {
    productId: number;
    name?: string;
}

export interface GetProductByProductIdUsingGETRequest {
    productId: number;
}

export interface GetProductBySkuWithParameterAdminUsingDELETERequest {
    sku: string;
    name?: string;
}

export interface GetProductBySkuWithParameterAdminUsingGETRequest {
    sku: string;
    name?: string;
}

export interface GetProductBySkuWithParameterAdminUsingHEADRequest {
    sku: string;
    name?: string;
}

export interface GetProductBySkuWithParameterAdminUsingOPTIONSRequest {
    sku: string;
    name?: string;
}

export interface GetProductBySkuWithParameterAdminUsingPATCHRequest {
    sku: string;
    name?: string;
}

export interface GetProductBySkuWithParameterAdminUsingPOSTRequest {
    sku: string;
    name?: string;
}

export interface GetProductBySkuWithParameterAdminUsingPUTRequest {
    sku: string;
    name?: string;
}

export interface GetProductBySkuWithParameterAdminUsingTRACERequest {
    sku: string;
    name?: string;
}

export interface GetProductBySkuWithParameterUsingDELETERequest {
    sku: string;
}

export interface GetProductBySkuWithParameterUsingDELETE1Request {
    sku: string;
}

export interface GetProductBySkuWithParameterUsingGETRequest {
    sku: string;
}

export interface GetProductBySkuWithParameterUsingGET1Request {
    sku: string;
}

export interface GetProductBySkuWithParameterUsingHEADRequest {
    sku: string;
}

export interface GetProductBySkuWithParameterUsingHEAD1Request {
    sku: string;
}

export interface GetProductBySkuWithParameterUsingOPTIONSRequest {
    sku: string;
}

export interface GetProductBySkuWithParameterUsingOPTIONS1Request {
    sku: string;
}

export interface GetProductBySkuWithParameterUsingPATCHRequest {
    sku: string;
}

export interface GetProductBySkuWithParameterUsingPATCH1Request {
    sku: string;
}

export interface GetProductBySkuWithParameterUsingPOSTRequest {
    sku: string;
}

export interface GetProductBySkuWithParameterUsingPOST1Request {
    sku: string;
}

export interface GetProductBySkuWithParameterUsingPUTRequest {
    sku: string;
}

export interface GetProductBySkuWithParameterUsingPUT1Request {
    sku: string;
}

export interface GetProductBySkuWithParameterUsingTRACERequest {
    sku: string;
}

export interface GetProductBySkuWithParameterUsingTRACE1Request {
    sku: string;
}

export interface GetProductBySkuWithPathVariableAdminUsingGETRequest {
    sku: string;
    name?: string;
}

export interface GetProductBySkuWithPathVariableUsingGETRequest {
    sku: string;
}

export interface ImportProductPriceUsingPOSTRequest {
    file: Blob;
}

export interface ListBelongedMarketingEventUsingGETRequest {
    productId: number;
    name?: string;
}

export interface UpdateProductInventoryUsingPOSTRequest {
    productId: number;
    num: number;
    name?: string;
}

export interface UpdateProductPriceUsingPOSTRequest {
    productId: number;
    price: number;
    name?: string;
}

export interface UpdateProductUsingPOSTRequest {
    productId: number;
    name?: string;
    productNameChi?: string;
    productNameEng?: string;
    categoryIds?: number;
    productDescription?: string;
    isGroupProduct?: string;
    productPrice?: number;
    productInventory?: number;
    subscriptionPeriod?: string;
    productVolume?: string;
    productVolumeUnit?: string;
    productImage?: string;
    productIds?: number;
    sku?: string;
    body?: Date;
}

export interface UpdateStatusProductUsingPOSTRequest {
    productId: number;
    name?: string;
}

export interface UpdateStatusProductUsingPOST1Request {
    productIsbn: string;
    name?: string;
}

/**
 * 
 */
export class ProductControllerApi extends runtime.BaseAPI {

    /**
     * addProductInventory
     */
    async addProductInventoryUsingPOSTRaw(requestParameters: AddProductInventoryUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProductDto>> {
        if (requestParameters['productId'] == null) {
            throw new runtime.RequiredError(
                'productId',
                'Required parameter "productId" was null or undefined when calling addProductInventoryUsingPOST().'
            );
        }

        if (requestParameters['num'] == null) {
            throw new runtime.RequiredError(
                'num',
                'Required parameter "num" was null or undefined when calling addProductInventoryUsingPOST().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        if (requestParameters['num'] != null) {
            queryParameters['num'] = requestParameters['num'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/product/{productId}/addInventory`.replace(`{${"productId"}}`, encodeURIComponent(String(requestParameters['productId']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProductDtoFromJSON(jsonValue));
    }

    /**
     * addProductInventory
     */
    async addProductInventoryUsingPOST(requestParameters: AddProductInventoryUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProductDto | null | undefined > {
        const response = await this.addProductInventoryUsingPOSTRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 201:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * addProductPoint
     */
    async addProductPointUsingPOSTRaw(requestParameters: AddProductPointUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProductDto>> {
        if (requestParameters['productId'] == null) {
            throw new runtime.RequiredError(
                'productId',
                'Required parameter "productId" was null or undefined when calling addProductPointUsingPOST().'
            );
        }

        if (requestParameters['point'] == null) {
            throw new runtime.RequiredError(
                'point',
                'Required parameter "point" was null or undefined when calling addProductPointUsingPOST().'
            );
        }

        if (requestParameters['money'] == null) {
            throw new runtime.RequiredError(
                'money',
                'Required parameter "money" was null or undefined when calling addProductPointUsingPOST().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        if (requestParameters['productId'] != null) {
            queryParameters['productId'] = requestParameters['productId'];
        }

        if (requestParameters['point'] != null) {
            queryParameters['point'] = requestParameters['point'];
        }

        if (requestParameters['money'] != null) {
            queryParameters['money'] = requestParameters['money'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/product/point/add`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProductDtoFromJSON(jsonValue));
    }

    /**
     * addProductPoint
     */
    async addProductPointUsingPOST(requestParameters: AddProductPointUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProductDto | null | undefined > {
        const response = await this.addProductPointUsingPOSTRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 201:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * addProduct
     */
    async addProductUsingPOSTRaw(requestParameters: AddProductUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProductDto>> {
        if (requestParameters['productNameChi'] == null) {
            throw new runtime.RequiredError(
                'productNameChi',
                'Required parameter "productNameChi" was null or undefined when calling addProductUsingPOST().'
            );
        }

        if (requestParameters['productNameEng'] == null) {
            throw new runtime.RequiredError(
                'productNameEng',
                'Required parameter "productNameEng" was null or undefined when calling addProductUsingPOST().'
            );
        }

        if (requestParameters['isGroupProduct'] == null) {
            throw new runtime.RequiredError(
                'isGroupProduct',
                'Required parameter "isGroupProduct" was null or undefined when calling addProductUsingPOST().'
            );
        }

        if (requestParameters['productPrice'] == null) {
            throw new runtime.RequiredError(
                'productPrice',
                'Required parameter "productPrice" was null or undefined when calling addProductUsingPOST().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        if (requestParameters['productNameChi'] != null) {
            queryParameters['productNameChi'] = requestParameters['productNameChi'];
        }

        if (requestParameters['productNameEng'] != null) {
            queryParameters['productNameEng'] = requestParameters['productNameEng'];
        }

        if (requestParameters['categoryIds'] != null) {
            queryParameters['categoryIds'] = requestParameters['categoryIds'];
        }

        if (requestParameters['productDescription'] != null) {
            queryParameters['productDescription'] = requestParameters['productDescription'];
        }

        if (requestParameters['isGroupProduct'] != null) {
            queryParameters['isGroupProduct'] = requestParameters['isGroupProduct'];
        }

        if (requestParameters['onShelfDate'] != null) {
            queryParameters['onShelfDate'] = (requestParameters['onShelfDate'] as any).toISOString();
        }

        if (requestParameters['offShelfDate'] != null) {
            queryParameters['offShelfDate'] = (requestParameters['offShelfDate'] as any).toISOString();
        }

        if (requestParameters['productPrice'] != null) {
            queryParameters['productPrice'] = requestParameters['productPrice'];
        }

        if (requestParameters['productInventory'] != null) {
            queryParameters['productInventory'] = requestParameters['productInventory'];
        }

        if (requestParameters['subscriptionPeriod'] != null) {
            queryParameters['subscriptionPeriod'] = requestParameters['subscriptionPeriod'];
        }

        if (requestParameters['productVolume'] != null) {
            queryParameters['productVolume'] = requestParameters['productVolume'];
        }

        if (requestParameters['productVolumeUnit'] != null) {
            queryParameters['productVolumeUnit'] = requestParameters['productVolumeUnit'];
        }

        if (requestParameters['productImage'] != null) {
            queryParameters['product_image'] = requestParameters['productImage'];
        }

        if (requestParameters['productIds'] != null) {
            queryParameters['productIds'] = requestParameters['productIds'];
        }

        if (requestParameters['sku'] != null) {
            queryParameters['sku'] = requestParameters['sku'];
        }

        if (requestParameters['productOriginalPrice'] != null) {
            queryParameters['productOriginalPrice'] = requestParameters['productOriginalPrice'];
        }

        if (requestParameters['ctsId'] != null) {
            queryParameters['ctsId'] = requestParameters['ctsId'];
        }

        if (requestParameters['bookIsbn'] != null) {
            queryParameters['bookIsbn'] = requestParameters['bookIsbn'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/product/add`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters['body'] as any,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProductDtoFromJSON(jsonValue));
    }

    /**
     * addProduct
     */
    async addProductUsingPOST(requestParameters: AddProductUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProductDto | null | undefined > {
        const response = await this.addProductUsingPOSTRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 201:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * checkProductStatus
     */
    async checkProductStatusUsingPOSTRaw(requestParameters: CheckProductStatusUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OffShelfModel>> {
        if (requestParameters['productIsbn'] == null) {
            throw new runtime.RequiredError(
                'productIsbn',
                'Required parameter "productIsbn" was null or undefined when calling checkProductStatusUsingPOST().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/product/checkProduct/status/{productIsbn}`.replace(`{${"productIsbn"}}`, encodeURIComponent(String(requestParameters['productIsbn']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OffShelfModelFromJSON(jsonValue));
    }

    /**
     * checkProductStatus
     */
    async checkProductStatusUsingPOST(requestParameters: CheckProductStatusUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OffShelfModel | null | undefined > {
        const response = await this.checkProductStatusUsingPOSTRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 201:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * deleteProductPoint
     */
    async deleteProductPointUsingPOSTRaw(requestParameters: DeleteProductPointUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProductDto>> {
        if (requestParameters['productId'] == null) {
            throw new runtime.RequiredError(
                'productId',
                'Required parameter "productId" was null or undefined when calling deleteProductPointUsingPOST().'
            );
        }

        if (requestParameters['productPointId'] == null) {
            throw new runtime.RequiredError(
                'productPointId',
                'Required parameter "productPointId" was null or undefined when calling deleteProductPointUsingPOST().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        if (requestParameters['productId'] != null) {
            queryParameters['productId'] = requestParameters['productId'];
        }

        if (requestParameters['productPointId'] != null) {
            queryParameters['productPointId'] = requestParameters['productPointId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/product/point/delete`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProductDtoFromJSON(jsonValue));
    }

    /**
     * deleteProductPoint
     */
    async deleteProductPointUsingPOST(requestParameters: DeleteProductPointUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProductDto | null | undefined > {
        const response = await this.deleteProductPointUsingPOSTRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 201:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * deleteProduct
     */
    async deleteProductUsingPOST2Raw(requestParameters: DeleteProductUsingPOST2Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProductDto>> {
        if (requestParameters['productId'] == null) {
            throw new runtime.RequiredError(
                'productId',
                'Required parameter "productId" was null or undefined when calling deleteProductUsingPOST2().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/product/delete/{productId}`.replace(`{${"productId"}}`, encodeURIComponent(String(requestParameters['productId']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProductDtoFromJSON(jsonValue));
    }

    /**
     * deleteProduct
     */
    async deleteProductUsingPOST2(requestParameters: DeleteProductUsingPOST2Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProductDto | null | undefined > {
        const response = await this.deleteProductUsingPOST2Raw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 201:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * disableProductPoint
     */
    async disableProductPointUsingPOSTRaw(requestParameters: DisableProductPointUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProductDto>> {
        if (requestParameters['productId'] == null) {
            throw new runtime.RequiredError(
                'productId',
                'Required parameter "productId" was null or undefined when calling disableProductPointUsingPOST().'
            );
        }

        if (requestParameters['productPointId'] == null) {
            throw new runtime.RequiredError(
                'productPointId',
                'Required parameter "productPointId" was null or undefined when calling disableProductPointUsingPOST().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        if (requestParameters['productId'] != null) {
            queryParameters['productId'] = requestParameters['productId'];
        }

        if (requestParameters['productPointId'] != null) {
            queryParameters['productPointId'] = requestParameters['productPointId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/product/point/disable`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProductDtoFromJSON(jsonValue));
    }

    /**
     * disableProductPoint
     */
    async disableProductPointUsingPOST(requestParameters: DisableProductPointUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProductDto | null | undefined > {
        const response = await this.disableProductPointUsingPOSTRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 201:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * enableProductPoint
     */
    async enableProductPointUsingPOSTRaw(requestParameters: EnableProductPointUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProductDto>> {
        if (requestParameters['productId'] == null) {
            throw new runtime.RequiredError(
                'productId',
                'Required parameter "productId" was null or undefined when calling enableProductPointUsingPOST().'
            );
        }

        if (requestParameters['productPointId'] == null) {
            throw new runtime.RequiredError(
                'productPointId',
                'Required parameter "productPointId" was null or undefined when calling enableProductPointUsingPOST().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        if (requestParameters['productId'] != null) {
            queryParameters['productId'] = requestParameters['productId'];
        }

        if (requestParameters['productPointId'] != null) {
            queryParameters['productPointId'] = requestParameters['productPointId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/product/point/enable`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProductDtoFromJSON(jsonValue));
    }

    /**
     * enableProductPoint
     */
    async enableProductPointUsingPOST(requestParameters: EnableProductPointUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProductDto | null | undefined > {
        const response = await this.enableProductPointUsingPOSTRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 201:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * exportProductPrice
     */
    async exportProductPriceUsingGETRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Resource>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/product/export`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResourceFromJSON(jsonValue));
    }

    /**
     * exportProductPrice
     */
    async exportProductPriceUsingGET(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Resource> {
        const response = await this.exportProductPriceUsingGETRaw(initOverrides);
        return await response.value();
    }

    /**
     * getPagedProductAdmin
     */
    async getPagedProductAdminUsingGETRaw(requestParameters: GetPagedProductAdminUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        if (requestParameters['page'] == null) {
            throw new runtime.RequiredError(
                'page',
                'Required parameter "page" was null or undefined when calling getPagedProductAdminUsingGET().'
            );
        }

        if (requestParameters['size'] == null) {
            throw new runtime.RequiredError(
                'size',
                'Required parameter "size" was null or undefined when calling getPagedProductAdminUsingGET().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        if (requestParameters['size'] != null) {
            queryParameters['size'] = requestParameters['size'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/product/admin/all`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * getPagedProductAdmin
     */
    async getPagedProductAdminUsingGET(requestParameters: GetPagedProductAdminUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object> {
        const response = await this.getPagedProductAdminUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getPagedProduct
     */
    async getPagedProductUsingGETRaw(requestParameters: GetPagedProductUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        if (requestParameters['page'] == null) {
            throw new runtime.RequiredError(
                'page',
                'Required parameter "page" was null or undefined when calling getPagedProductUsingGET().'
            );
        }

        if (requestParameters['size'] == null) {
            throw new runtime.RequiredError(
                'size',
                'Required parameter "size" was null or undefined when calling getPagedProductUsingGET().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        if (requestParameters['size'] != null) {
            queryParameters['size'] = requestParameters['size'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/product/all`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * getPagedProduct
     */
    async getPagedProductUsingGET(requestParameters: GetPagedProductUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object> {
        const response = await this.getPagedProductUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getPagedProductWithPointAdmin
     */
    async getPagedProductWithPointAdminUsingGETRaw(requestParameters: GetPagedProductWithPointAdminUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        if (requestParameters['page'] == null) {
            throw new runtime.RequiredError(
                'page',
                'Required parameter "page" was null or undefined when calling getPagedProductWithPointAdminUsingGET().'
            );
        }

        if (requestParameters['size'] == null) {
            throw new runtime.RequiredError(
                'size',
                'Required parameter "size" was null or undefined when calling getPagedProductWithPointAdminUsingGET().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        if (requestParameters['size'] != null) {
            queryParameters['size'] = requestParameters['size'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/product/admin/all/withPoint`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * getPagedProductWithPointAdmin
     */
    async getPagedProductWithPointAdminUsingGET(requestParameters: GetPagedProductWithPointAdminUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object> {
        const response = await this.getPagedProductWithPointAdminUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getPagedProductWithPoint
     */
    async getPagedProductWithPointUsingGETRaw(requestParameters: GetPagedProductWithPointUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        if (requestParameters['page'] == null) {
            throw new runtime.RequiredError(
                'page',
                'Required parameter "page" was null or undefined when calling getPagedProductWithPointUsingGET().'
            );
        }

        if (requestParameters['size'] == null) {
            throw new runtime.RequiredError(
                'size',
                'Required parameter "size" was null or undefined when calling getPagedProductWithPointUsingGET().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        if (requestParameters['size'] != null) {
            queryParameters['size'] = requestParameters['size'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/product/all/withPoint`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * getPagedProductWithPoint
     */
    async getPagedProductWithPointUsingGET(requestParameters: GetPagedProductWithPointUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object> {
        const response = await this.getPagedProductWithPointUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getProductByCategoryIdAdmin
     */
    async getProductByCategoryIdAdminUsingGETRaw(requestParameters: GetProductByCategoryIdAdminUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ProductDto>>> {
        if (requestParameters['categoryId'] == null) {
            throw new runtime.RequiredError(
                'categoryId',
                'Required parameter "categoryId" was null or undefined when calling getProductByCategoryIdAdminUsingGET().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/product/admin/category/{categoryId}`.replace(`{${"categoryId"}}`, encodeURIComponent(String(requestParameters['categoryId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ProductDtoFromJSON));
    }

    /**
     * getProductByCategoryIdAdmin
     */
    async getProductByCategoryIdAdminUsingGET(requestParameters: GetProductByCategoryIdAdminUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ProductDto>> {
        const response = await this.getProductByCategoryIdAdminUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getProductByCategoryId
     */
    async getProductByCategoryIdUsingGETRaw(requestParameters: GetProductByCategoryIdUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ProductDto>>> {
        if (requestParameters['categoryId'] == null) {
            throw new runtime.RequiredError(
                'categoryId',
                'Required parameter "categoryId" was null or undefined when calling getProductByCategoryIdUsingGET().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/product/category/{categoryId}`.replace(`{${"categoryId"}}`, encodeURIComponent(String(requestParameters['categoryId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ProductDtoFromJSON));
    }

    /**
     * getProductByCategoryId
     */
    async getProductByCategoryIdUsingGET(requestParameters: GetProductByCategoryIdUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ProductDto>> {
        const response = await this.getProductByCategoryIdUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getProductByGroupProductIdAdmin
     */
    async getProductByGroupProductIdAdminUsingGETRaw(requestParameters: GetProductByGroupProductIdAdminUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ProductDto>>> {
        if (requestParameters['groupProductId'] == null) {
            throw new runtime.RequiredError(
                'groupProductId',
                'Required parameter "groupProductId" was null or undefined when calling getProductByGroupProductIdAdminUsingGET().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/product/admin/groupproduct/{groupProductId}`.replace(`{${"groupProductId"}}`, encodeURIComponent(String(requestParameters['groupProductId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ProductDtoFromJSON));
    }

    /**
     * getProductByGroupProductIdAdmin
     */
    async getProductByGroupProductIdAdminUsingGET(requestParameters: GetProductByGroupProductIdAdminUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ProductDto>> {
        const response = await this.getProductByGroupProductIdAdminUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getProductByGroupProductId
     */
    async getProductByGroupProductIdUsingGETRaw(requestParameters: GetProductByGroupProductIdUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ProductDto>>> {
        if (requestParameters['groupProductId'] == null) {
            throw new runtime.RequiredError(
                'groupProductId',
                'Required parameter "groupProductId" was null or undefined when calling getProductByGroupProductIdUsingGET().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/product/groupproduct/{groupProductId}`.replace(`{${"groupProductId"}}`, encodeURIComponent(String(requestParameters['groupProductId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ProductDtoFromJSON));
    }

    /**
     * getProductByGroupProductId
     */
    async getProductByGroupProductIdUsingGET(requestParameters: GetProductByGroupProductIdUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ProductDto>> {
        const response = await this.getProductByGroupProductIdUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getProductByProductIdAdmin
     */
    async getProductByProductIdAdminUsingGETRaw(requestParameters: GetProductByProductIdAdminUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProductDto>> {
        if (requestParameters['productId'] == null) {
            throw new runtime.RequiredError(
                'productId',
                'Required parameter "productId" was null or undefined when calling getProductByProductIdAdminUsingGET().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/product/admin/{productId}`.replace(`{${"productId"}}`, encodeURIComponent(String(requestParameters['productId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProductDtoFromJSON(jsonValue));
    }

    /**
     * getProductByProductIdAdmin
     */
    async getProductByProductIdAdminUsingGET(requestParameters: GetProductByProductIdAdminUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProductDto> {
        const response = await this.getProductByProductIdAdminUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getProductByProductId
     */
    async getProductByProductIdUsingGETRaw(requestParameters: GetProductByProductIdUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProductDto>> {
        if (requestParameters['productId'] == null) {
            throw new runtime.RequiredError(
                'productId',
                'Required parameter "productId" was null or undefined when calling getProductByProductIdUsingGET().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/product/{productId}`.replace(`{${"productId"}}`, encodeURIComponent(String(requestParameters['productId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProductDtoFromJSON(jsonValue));
    }

    /**
     * getProductByProductId
     */
    async getProductByProductIdUsingGET(requestParameters: GetProductByProductIdUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProductDto> {
        const response = await this.getProductByProductIdUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getProductBySkuWithParameterAdmin
     */
    async getProductBySkuWithParameterAdminUsingDELETERaw(requestParameters: GetProductBySkuWithParameterAdminUsingDELETERequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProductDto>> {
        if (requestParameters['sku'] == null) {
            throw new runtime.RequiredError(
                'sku',
                'Required parameter "sku" was null or undefined when calling getProductBySkuWithParameterAdminUsingDELETE().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        if (requestParameters['sku'] != null) {
            queryParameters['sku'] = requestParameters['sku'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/product/admin`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProductDtoFromJSON(jsonValue));
    }

    /**
     * getProductBySkuWithParameterAdmin
     */
    async getProductBySkuWithParameterAdminUsingDELETE(requestParameters: GetProductBySkuWithParameterAdminUsingDELETERequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProductDto | null | undefined > {
        const response = await this.getProductBySkuWithParameterAdminUsingDELETERaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 204:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * getProductBySkuWithParameterAdmin
     */
    async getProductBySkuWithParameterAdminUsingGETRaw(requestParameters: GetProductBySkuWithParameterAdminUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProductDto>> {
        if (requestParameters['sku'] == null) {
            throw new runtime.RequiredError(
                'sku',
                'Required parameter "sku" was null or undefined when calling getProductBySkuWithParameterAdminUsingGET().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        if (requestParameters['sku'] != null) {
            queryParameters['sku'] = requestParameters['sku'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/product/admin`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProductDtoFromJSON(jsonValue));
    }

    /**
     * getProductBySkuWithParameterAdmin
     */
    async getProductBySkuWithParameterAdminUsingGET(requestParameters: GetProductBySkuWithParameterAdminUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProductDto> {
        const response = await this.getProductBySkuWithParameterAdminUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getProductBySkuWithParameterAdmin
     */
    async getProductBySkuWithParameterAdminUsingHEADRaw(requestParameters: GetProductBySkuWithParameterAdminUsingHEADRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProductDto>> {
        if (requestParameters['sku'] == null) {
            throw new runtime.RequiredError(
                'sku',
                'Required parameter "sku" was null or undefined when calling getProductBySkuWithParameterAdminUsingHEAD().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        if (requestParameters['sku'] != null) {
            queryParameters['sku'] = requestParameters['sku'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/product/admin`,
            method: 'HEAD',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProductDtoFromJSON(jsonValue));
    }

    /**
     * getProductBySkuWithParameterAdmin
     */
    async getProductBySkuWithParameterAdminUsingHEAD(requestParameters: GetProductBySkuWithParameterAdminUsingHEADRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProductDto | null | undefined > {
        const response = await this.getProductBySkuWithParameterAdminUsingHEADRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 204:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * getProductBySkuWithParameterAdmin
     */
    async getProductBySkuWithParameterAdminUsingOPTIONSRaw(requestParameters: GetProductBySkuWithParameterAdminUsingOPTIONSRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProductDto>> {
        if (requestParameters['sku'] == null) {
            throw new runtime.RequiredError(
                'sku',
                'Required parameter "sku" was null or undefined when calling getProductBySkuWithParameterAdminUsingOPTIONS().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        if (requestParameters['sku'] != null) {
            queryParameters['sku'] = requestParameters['sku'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/product/admin`,
            method: 'OPTIONS',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProductDtoFromJSON(jsonValue));
    }

    /**
     * getProductBySkuWithParameterAdmin
     */
    async getProductBySkuWithParameterAdminUsingOPTIONS(requestParameters: GetProductBySkuWithParameterAdminUsingOPTIONSRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProductDto | null | undefined > {
        const response = await this.getProductBySkuWithParameterAdminUsingOPTIONSRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 204:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * getProductBySkuWithParameterAdmin
     */
    async getProductBySkuWithParameterAdminUsingPATCHRaw(requestParameters: GetProductBySkuWithParameterAdminUsingPATCHRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProductDto>> {
        if (requestParameters['sku'] == null) {
            throw new runtime.RequiredError(
                'sku',
                'Required parameter "sku" was null or undefined when calling getProductBySkuWithParameterAdminUsingPATCH().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        if (requestParameters['sku'] != null) {
            queryParameters['sku'] = requestParameters['sku'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/product/admin`,
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProductDtoFromJSON(jsonValue));
    }

    /**
     * getProductBySkuWithParameterAdmin
     */
    async getProductBySkuWithParameterAdminUsingPATCH(requestParameters: GetProductBySkuWithParameterAdminUsingPATCHRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProductDto | null | undefined > {
        const response = await this.getProductBySkuWithParameterAdminUsingPATCHRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 204:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * getProductBySkuWithParameterAdmin
     */
    async getProductBySkuWithParameterAdminUsingPOSTRaw(requestParameters: GetProductBySkuWithParameterAdminUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProductDto>> {
        if (requestParameters['sku'] == null) {
            throw new runtime.RequiredError(
                'sku',
                'Required parameter "sku" was null or undefined when calling getProductBySkuWithParameterAdminUsingPOST().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        if (requestParameters['sku'] != null) {
            queryParameters['sku'] = requestParameters['sku'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/product/admin`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProductDtoFromJSON(jsonValue));
    }

    /**
     * getProductBySkuWithParameterAdmin
     */
    async getProductBySkuWithParameterAdminUsingPOST(requestParameters: GetProductBySkuWithParameterAdminUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProductDto | null | undefined > {
        const response = await this.getProductBySkuWithParameterAdminUsingPOSTRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 201:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * getProductBySkuWithParameterAdmin
     */
    async getProductBySkuWithParameterAdminUsingPUTRaw(requestParameters: GetProductBySkuWithParameterAdminUsingPUTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProductDto>> {
        if (requestParameters['sku'] == null) {
            throw new runtime.RequiredError(
                'sku',
                'Required parameter "sku" was null or undefined when calling getProductBySkuWithParameterAdminUsingPUT().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        if (requestParameters['sku'] != null) {
            queryParameters['sku'] = requestParameters['sku'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/product/admin`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProductDtoFromJSON(jsonValue));
    }

    /**
     * getProductBySkuWithParameterAdmin
     */
    async getProductBySkuWithParameterAdminUsingPUT(requestParameters: GetProductBySkuWithParameterAdminUsingPUTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProductDto | null | undefined > {
        const response = await this.getProductBySkuWithParameterAdminUsingPUTRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 201:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * getProductBySkuWithParameterAdmin
     */
    async getProductBySkuWithParameterAdminUsingTRACERaw(requestParameters: GetProductBySkuWithParameterAdminUsingTRACERequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProductDto>> {
        if (requestParameters['sku'] == null) {
            throw new runtime.RequiredError(
                'sku',
                'Required parameter "sku" was null or undefined when calling getProductBySkuWithParameterAdminUsingTRACE().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        if (requestParameters['sku'] != null) {
            queryParameters['sku'] = requestParameters['sku'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/product/admin`,
            method: 'TRACE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProductDtoFromJSON(jsonValue));
    }

    /**
     * getProductBySkuWithParameterAdmin
     */
    async getProductBySkuWithParameterAdminUsingTRACE(requestParameters: GetProductBySkuWithParameterAdminUsingTRACERequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProductDto | null | undefined > {
        const response = await this.getProductBySkuWithParameterAdminUsingTRACERaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 204:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * getProductBySkuWithParameter
     */
    async getProductBySkuWithParameterUsingDELETERaw(requestParameters: GetProductBySkuWithParameterUsingDELETERequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProductDto>> {
        if (requestParameters['sku'] == null) {
            throw new runtime.RequiredError(
                'sku',
                'Required parameter "sku" was null or undefined when calling getProductBySkuWithParameterUsingDELETE().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['sku'] != null) {
            queryParameters['sku'] = requestParameters['sku'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/product`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProductDtoFromJSON(jsonValue));
    }

    /**
     * getProductBySkuWithParameter
     */
    async getProductBySkuWithParameterUsingDELETE(requestParameters: GetProductBySkuWithParameterUsingDELETERequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProductDto | null | undefined > {
        const response = await this.getProductBySkuWithParameterUsingDELETERaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 204:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * getProductBySkuWithParameter
     */
    async getProductBySkuWithParameterUsingDELETE1Raw(requestParameters: GetProductBySkuWithParameterUsingDELETE1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProductDto>> {
        if (requestParameters['sku'] == null) {
            throw new runtime.RequiredError(
                'sku',
                'Required parameter "sku" was null or undefined when calling getProductBySkuWithParameterUsingDELETE1().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['sku'] != null) {
            queryParameters['sku'] = requestParameters['sku'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/product/`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProductDtoFromJSON(jsonValue));
    }

    /**
     * getProductBySkuWithParameter
     */
    async getProductBySkuWithParameterUsingDELETE1(requestParameters: GetProductBySkuWithParameterUsingDELETE1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProductDto | null | undefined > {
        const response = await this.getProductBySkuWithParameterUsingDELETE1Raw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 204:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * getProductBySkuWithParameter
     */
    async getProductBySkuWithParameterUsingGETRaw(requestParameters: GetProductBySkuWithParameterUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProductDto>> {
        if (requestParameters['sku'] == null) {
            throw new runtime.RequiredError(
                'sku',
                'Required parameter "sku" was null or undefined when calling getProductBySkuWithParameterUsingGET().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['sku'] != null) {
            queryParameters['sku'] = requestParameters['sku'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/product`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProductDtoFromJSON(jsonValue));
    }

    /**
     * getProductBySkuWithParameter
     */
    async getProductBySkuWithParameterUsingGET(requestParameters: GetProductBySkuWithParameterUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProductDto> {
        const response = await this.getProductBySkuWithParameterUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getProductBySkuWithParameter
     */
    async getProductBySkuWithParameterUsingGET1Raw(requestParameters: GetProductBySkuWithParameterUsingGET1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProductDto>> {
        if (requestParameters['sku'] == null) {
            throw new runtime.RequiredError(
                'sku',
                'Required parameter "sku" was null or undefined when calling getProductBySkuWithParameterUsingGET1().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['sku'] != null) {
            queryParameters['sku'] = requestParameters['sku'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/product/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProductDtoFromJSON(jsonValue));
    }

    /**
     * getProductBySkuWithParameter
     */
    async getProductBySkuWithParameterUsingGET1(requestParameters: GetProductBySkuWithParameterUsingGET1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProductDto> {
        const response = await this.getProductBySkuWithParameterUsingGET1Raw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getProductBySkuWithParameter
     */
    async getProductBySkuWithParameterUsingHEADRaw(requestParameters: GetProductBySkuWithParameterUsingHEADRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProductDto>> {
        if (requestParameters['sku'] == null) {
            throw new runtime.RequiredError(
                'sku',
                'Required parameter "sku" was null or undefined when calling getProductBySkuWithParameterUsingHEAD().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['sku'] != null) {
            queryParameters['sku'] = requestParameters['sku'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/product`,
            method: 'HEAD',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProductDtoFromJSON(jsonValue));
    }

    /**
     * getProductBySkuWithParameter
     */
    async getProductBySkuWithParameterUsingHEAD(requestParameters: GetProductBySkuWithParameterUsingHEADRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProductDto | null | undefined > {
        const response = await this.getProductBySkuWithParameterUsingHEADRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 204:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * getProductBySkuWithParameter
     */
    async getProductBySkuWithParameterUsingHEAD1Raw(requestParameters: GetProductBySkuWithParameterUsingHEAD1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProductDto>> {
        if (requestParameters['sku'] == null) {
            throw new runtime.RequiredError(
                'sku',
                'Required parameter "sku" was null or undefined when calling getProductBySkuWithParameterUsingHEAD1().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['sku'] != null) {
            queryParameters['sku'] = requestParameters['sku'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/product/`,
            method: 'HEAD',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProductDtoFromJSON(jsonValue));
    }

    /**
     * getProductBySkuWithParameter
     */
    async getProductBySkuWithParameterUsingHEAD1(requestParameters: GetProductBySkuWithParameterUsingHEAD1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProductDto | null | undefined > {
        const response = await this.getProductBySkuWithParameterUsingHEAD1Raw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 204:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * getProductBySkuWithParameter
     */
    async getProductBySkuWithParameterUsingOPTIONSRaw(requestParameters: GetProductBySkuWithParameterUsingOPTIONSRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProductDto>> {
        if (requestParameters['sku'] == null) {
            throw new runtime.RequiredError(
                'sku',
                'Required parameter "sku" was null or undefined when calling getProductBySkuWithParameterUsingOPTIONS().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['sku'] != null) {
            queryParameters['sku'] = requestParameters['sku'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/product`,
            method: 'OPTIONS',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProductDtoFromJSON(jsonValue));
    }

    /**
     * getProductBySkuWithParameter
     */
    async getProductBySkuWithParameterUsingOPTIONS(requestParameters: GetProductBySkuWithParameterUsingOPTIONSRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProductDto | null | undefined > {
        const response = await this.getProductBySkuWithParameterUsingOPTIONSRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 204:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * getProductBySkuWithParameter
     */
    async getProductBySkuWithParameterUsingOPTIONS1Raw(requestParameters: GetProductBySkuWithParameterUsingOPTIONS1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProductDto>> {
        if (requestParameters['sku'] == null) {
            throw new runtime.RequiredError(
                'sku',
                'Required parameter "sku" was null or undefined when calling getProductBySkuWithParameterUsingOPTIONS1().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['sku'] != null) {
            queryParameters['sku'] = requestParameters['sku'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/product/`,
            method: 'OPTIONS',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProductDtoFromJSON(jsonValue));
    }

    /**
     * getProductBySkuWithParameter
     */
    async getProductBySkuWithParameterUsingOPTIONS1(requestParameters: GetProductBySkuWithParameterUsingOPTIONS1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProductDto | null | undefined > {
        const response = await this.getProductBySkuWithParameterUsingOPTIONS1Raw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 204:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * getProductBySkuWithParameter
     */
    async getProductBySkuWithParameterUsingPATCHRaw(requestParameters: GetProductBySkuWithParameterUsingPATCHRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProductDto>> {
        if (requestParameters['sku'] == null) {
            throw new runtime.RequiredError(
                'sku',
                'Required parameter "sku" was null or undefined when calling getProductBySkuWithParameterUsingPATCH().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['sku'] != null) {
            queryParameters['sku'] = requestParameters['sku'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/product`,
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProductDtoFromJSON(jsonValue));
    }

    /**
     * getProductBySkuWithParameter
     */
    async getProductBySkuWithParameterUsingPATCH(requestParameters: GetProductBySkuWithParameterUsingPATCHRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProductDto | null | undefined > {
        const response = await this.getProductBySkuWithParameterUsingPATCHRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 204:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * getProductBySkuWithParameter
     */
    async getProductBySkuWithParameterUsingPATCH1Raw(requestParameters: GetProductBySkuWithParameterUsingPATCH1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProductDto>> {
        if (requestParameters['sku'] == null) {
            throw new runtime.RequiredError(
                'sku',
                'Required parameter "sku" was null or undefined when calling getProductBySkuWithParameterUsingPATCH1().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['sku'] != null) {
            queryParameters['sku'] = requestParameters['sku'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/product/`,
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProductDtoFromJSON(jsonValue));
    }

    /**
     * getProductBySkuWithParameter
     */
    async getProductBySkuWithParameterUsingPATCH1(requestParameters: GetProductBySkuWithParameterUsingPATCH1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProductDto | null | undefined > {
        const response = await this.getProductBySkuWithParameterUsingPATCH1Raw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 204:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * getProductBySkuWithParameter
     */
    async getProductBySkuWithParameterUsingPOSTRaw(requestParameters: GetProductBySkuWithParameterUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProductDto>> {
        if (requestParameters['sku'] == null) {
            throw new runtime.RequiredError(
                'sku',
                'Required parameter "sku" was null or undefined when calling getProductBySkuWithParameterUsingPOST().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['sku'] != null) {
            queryParameters['sku'] = requestParameters['sku'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/product`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProductDtoFromJSON(jsonValue));
    }

    /**
     * getProductBySkuWithParameter
     */
    async getProductBySkuWithParameterUsingPOST(requestParameters: GetProductBySkuWithParameterUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProductDto | null | undefined > {
        const response = await this.getProductBySkuWithParameterUsingPOSTRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 201:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * getProductBySkuWithParameter
     */
    async getProductBySkuWithParameterUsingPOST1Raw(requestParameters: GetProductBySkuWithParameterUsingPOST1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProductDto>> {
        if (requestParameters['sku'] == null) {
            throw new runtime.RequiredError(
                'sku',
                'Required parameter "sku" was null or undefined when calling getProductBySkuWithParameterUsingPOST1().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['sku'] != null) {
            queryParameters['sku'] = requestParameters['sku'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/product/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProductDtoFromJSON(jsonValue));
    }

    /**
     * getProductBySkuWithParameter
     */
    async getProductBySkuWithParameterUsingPOST1(requestParameters: GetProductBySkuWithParameterUsingPOST1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProductDto | null | undefined > {
        const response = await this.getProductBySkuWithParameterUsingPOST1Raw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 201:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * getProductBySkuWithParameter
     */
    async getProductBySkuWithParameterUsingPUTRaw(requestParameters: GetProductBySkuWithParameterUsingPUTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProductDto>> {
        if (requestParameters['sku'] == null) {
            throw new runtime.RequiredError(
                'sku',
                'Required parameter "sku" was null or undefined when calling getProductBySkuWithParameterUsingPUT().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['sku'] != null) {
            queryParameters['sku'] = requestParameters['sku'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/product`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProductDtoFromJSON(jsonValue));
    }

    /**
     * getProductBySkuWithParameter
     */
    async getProductBySkuWithParameterUsingPUT(requestParameters: GetProductBySkuWithParameterUsingPUTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProductDto | null | undefined > {
        const response = await this.getProductBySkuWithParameterUsingPUTRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 201:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * getProductBySkuWithParameter
     */
    async getProductBySkuWithParameterUsingPUT1Raw(requestParameters: GetProductBySkuWithParameterUsingPUT1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProductDto>> {
        if (requestParameters['sku'] == null) {
            throw new runtime.RequiredError(
                'sku',
                'Required parameter "sku" was null or undefined when calling getProductBySkuWithParameterUsingPUT1().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['sku'] != null) {
            queryParameters['sku'] = requestParameters['sku'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/product/`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProductDtoFromJSON(jsonValue));
    }

    /**
     * getProductBySkuWithParameter
     */
    async getProductBySkuWithParameterUsingPUT1(requestParameters: GetProductBySkuWithParameterUsingPUT1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProductDto | null | undefined > {
        const response = await this.getProductBySkuWithParameterUsingPUT1Raw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 201:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * getProductBySkuWithParameter
     */
    async getProductBySkuWithParameterUsingTRACERaw(requestParameters: GetProductBySkuWithParameterUsingTRACERequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProductDto>> {
        if (requestParameters['sku'] == null) {
            throw new runtime.RequiredError(
                'sku',
                'Required parameter "sku" was null or undefined when calling getProductBySkuWithParameterUsingTRACE().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['sku'] != null) {
            queryParameters['sku'] = requestParameters['sku'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/product`,
            method: 'TRACE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProductDtoFromJSON(jsonValue));
    }

    /**
     * getProductBySkuWithParameter
     */
    async getProductBySkuWithParameterUsingTRACE(requestParameters: GetProductBySkuWithParameterUsingTRACERequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProductDto | null | undefined > {
        const response = await this.getProductBySkuWithParameterUsingTRACERaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 204:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * getProductBySkuWithParameter
     */
    async getProductBySkuWithParameterUsingTRACE1Raw(requestParameters: GetProductBySkuWithParameterUsingTRACE1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProductDto>> {
        if (requestParameters['sku'] == null) {
            throw new runtime.RequiredError(
                'sku',
                'Required parameter "sku" was null or undefined when calling getProductBySkuWithParameterUsingTRACE1().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['sku'] != null) {
            queryParameters['sku'] = requestParameters['sku'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/product/`,
            method: 'TRACE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProductDtoFromJSON(jsonValue));
    }

    /**
     * getProductBySkuWithParameter
     */
    async getProductBySkuWithParameterUsingTRACE1(requestParameters: GetProductBySkuWithParameterUsingTRACE1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProductDto | null | undefined > {
        const response = await this.getProductBySkuWithParameterUsingTRACE1Raw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 204:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * getProductBySkuWithPathVariableAdmin
     */
    async getProductBySkuWithPathVariableAdminUsingGETRaw(requestParameters: GetProductBySkuWithPathVariableAdminUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProductDto>> {
        if (requestParameters['sku'] == null) {
            throw new runtime.RequiredError(
                'sku',
                'Required parameter "sku" was null or undefined when calling getProductBySkuWithPathVariableAdminUsingGET().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/product/admin/sku/{sku}`.replace(`{${"sku"}}`, encodeURIComponent(String(requestParameters['sku']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProductDtoFromJSON(jsonValue));
    }

    /**
     * getProductBySkuWithPathVariableAdmin
     */
    async getProductBySkuWithPathVariableAdminUsingGET(requestParameters: GetProductBySkuWithPathVariableAdminUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProductDto> {
        const response = await this.getProductBySkuWithPathVariableAdminUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getProductBySkuWithPathVariable
     */
    async getProductBySkuWithPathVariableUsingGETRaw(requestParameters: GetProductBySkuWithPathVariableUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProductDto>> {
        if (requestParameters['sku'] == null) {
            throw new runtime.RequiredError(
                'sku',
                'Required parameter "sku" was null or undefined when calling getProductBySkuWithPathVariableUsingGET().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/product/sku/{sku}`.replace(`{${"sku"}}`, encodeURIComponent(String(requestParameters['sku']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProductDtoFromJSON(jsonValue));
    }

    /**
     * getProductBySkuWithPathVariable
     */
    async getProductBySkuWithPathVariableUsingGET(requestParameters: GetProductBySkuWithPathVariableUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProductDto> {
        const response = await this.getProductBySkuWithPathVariableUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * importProductPrice
     */
    async importProductPriceUsingPOSTRaw(requestParameters: ImportProductPriceUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<boolean>> {
        if (requestParameters['file'] == null) {
            throw new runtime.RequiredError(
                'file',
                'Required parameter "file" was null or undefined when calling importProductPriceUsingPOST().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters['file'] != null) {
            formParams.append('file', requestParameters['file'] as any);
        }

        const response = await this.request({
            path: `/product/import`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<boolean>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * importProductPrice
     */
    async importProductPriceUsingPOST(requestParameters: ImportProductPriceUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<boolean | null | undefined > {
        const response = await this.importProductPriceUsingPOSTRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 201:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * listBelongedMarketingEvent
     */
    async listBelongedMarketingEventUsingGETRaw(requestParameters: ListBelongedMarketingEventUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<MarketingEventDto>>> {
        if (requestParameters['productId'] == null) {
            throw new runtime.RequiredError(
                'productId',
                'Required parameter "productId" was null or undefined when calling listBelongedMarketingEventUsingGET().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/product/{productId}/marketingEvent`.replace(`{${"productId"}}`, encodeURIComponent(String(requestParameters['productId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(MarketingEventDtoFromJSON));
    }

    /**
     * listBelongedMarketingEvent
     */
    async listBelongedMarketingEventUsingGET(requestParameters: ListBelongedMarketingEventUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<MarketingEventDto>> {
        const response = await this.listBelongedMarketingEventUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * listMarketingEventWhichHasProduct
     */
    async listMarketingEventWhichHasProductUsingGETRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<MarketingEventDto>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/product/marketingEvent`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(MarketingEventDtoFromJSON));
    }

    /**
     * listMarketingEventWhichHasProduct
     */
    async listMarketingEventWhichHasProductUsingGET(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<MarketingEventDto>> {
        const response = await this.listMarketingEventWhichHasProductUsingGETRaw(initOverrides);
        return await response.value();
    }

    /**
     * updateProductInventory
     */
    async updateProductInventoryUsingPOSTRaw(requestParameters: UpdateProductInventoryUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProductDto>> {
        if (requestParameters['productId'] == null) {
            throw new runtime.RequiredError(
                'productId',
                'Required parameter "productId" was null or undefined when calling updateProductInventoryUsingPOST().'
            );
        }

        if (requestParameters['num'] == null) {
            throw new runtime.RequiredError(
                'num',
                'Required parameter "num" was null or undefined when calling updateProductInventoryUsingPOST().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        if (requestParameters['num'] != null) {
            queryParameters['num'] = requestParameters['num'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/product/{productId}/updateInventory`.replace(`{${"productId"}}`, encodeURIComponent(String(requestParameters['productId']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProductDtoFromJSON(jsonValue));
    }

    /**
     * updateProductInventory
     */
    async updateProductInventoryUsingPOST(requestParameters: UpdateProductInventoryUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProductDto | null | undefined > {
        const response = await this.updateProductInventoryUsingPOSTRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 201:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * updateProductPrice
     */
    async updateProductPriceUsingPOSTRaw(requestParameters: UpdateProductPriceUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProductDto>> {
        if (requestParameters['productId'] == null) {
            throw new runtime.RequiredError(
                'productId',
                'Required parameter "productId" was null or undefined when calling updateProductPriceUsingPOST().'
            );
        }

        if (requestParameters['price'] == null) {
            throw new runtime.RequiredError(
                'price',
                'Required parameter "price" was null or undefined when calling updateProductPriceUsingPOST().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        if (requestParameters['price'] != null) {
            queryParameters['price'] = requestParameters['price'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/product/{productId}/updatePrice`.replace(`{${"productId"}}`, encodeURIComponent(String(requestParameters['productId']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProductDtoFromJSON(jsonValue));
    }

    /**
     * updateProductPrice
     */
    async updateProductPriceUsingPOST(requestParameters: UpdateProductPriceUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProductDto | null | undefined > {
        const response = await this.updateProductPriceUsingPOSTRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 201:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * updateProduct
     */
    async updateProductUsingPOSTRaw(requestParameters: UpdateProductUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProductDto>> {
        if (requestParameters['productId'] == null) {
            throw new runtime.RequiredError(
                'productId',
                'Required parameter "productId" was null or undefined when calling updateProductUsingPOST().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        if (requestParameters['productId'] != null) {
            queryParameters['productId'] = requestParameters['productId'];
        }

        if (requestParameters['productNameChi'] != null) {
            queryParameters['productNameChi'] = requestParameters['productNameChi'];
        }

        if (requestParameters['productNameEng'] != null) {
            queryParameters['productNameEng'] = requestParameters['productNameEng'];
        }

        if (requestParameters['categoryIds'] != null) {
            queryParameters['categoryIds'] = requestParameters['categoryIds'];
        }

        if (requestParameters['productDescription'] != null) {
            queryParameters['productDescription'] = requestParameters['productDescription'];
        }

        if (requestParameters['isGroupProduct'] != null) {
            queryParameters['isGroupProduct'] = requestParameters['isGroupProduct'];
        }

        if (requestParameters['productPrice'] != null) {
            queryParameters['productPrice'] = requestParameters['productPrice'];
        }

        if (requestParameters['productInventory'] != null) {
            queryParameters['productInventory'] = requestParameters['productInventory'];
        }

        if (requestParameters['subscriptionPeriod'] != null) {
            queryParameters['subscriptionPeriod'] = requestParameters['subscriptionPeriod'];
        }

        if (requestParameters['productVolume'] != null) {
            queryParameters['productVolume'] = requestParameters['productVolume'];
        }

        if (requestParameters['productVolumeUnit'] != null) {
            queryParameters['productVolumeUnit'] = requestParameters['productVolumeUnit'];
        }

        if (requestParameters['productImage'] != null) {
            queryParameters['product_image'] = requestParameters['productImage'];
        }

        if (requestParameters['productIds'] != null) {
            queryParameters['productIds'] = requestParameters['productIds'];
        }

        if (requestParameters['sku'] != null) {
            queryParameters['sku'] = requestParameters['sku'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/product/update`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters['body'] as any,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProductDtoFromJSON(jsonValue));
    }

    /**
     * updateProduct
     */
    async updateProductUsingPOST(requestParameters: UpdateProductUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProductDto | null | undefined > {
        const response = await this.updateProductUsingPOSTRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 201:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * updateStatusProduct
     */
    async updateStatusProductUsingPOSTRaw(requestParameters: UpdateStatusProductUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProductDto>> {
        if (requestParameters['productId'] == null) {
            throw new runtime.RequiredError(
                'productId',
                'Required parameter "productId" was null or undefined when calling updateStatusProductUsingPOST().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/product/update2/status/{productId}`.replace(`{${"productId"}}`, encodeURIComponent(String(requestParameters['productId']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProductDtoFromJSON(jsonValue));
    }

    /**
     * updateStatusProduct
     */
    async updateStatusProductUsingPOST(requestParameters: UpdateStatusProductUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProductDto | null | undefined > {
        const response = await this.updateStatusProductUsingPOSTRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 201:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * updateStatusProduct
     */
    async updateStatusProductUsingPOST1Raw(requestParameters: UpdateStatusProductUsingPOST1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SuccessResponseModel>> {
        if (requestParameters['productIsbn'] == null) {
            throw new runtime.RequiredError(
                'productIsbn',
                'Required parameter "productIsbn" was null or undefined when calling updateStatusProductUsingPOST1().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/product/update/status/{productIsbn}`.replace(`{${"productIsbn"}}`, encodeURIComponent(String(requestParameters['productIsbn']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SuccessResponseModelFromJSON(jsonValue));
    }

    /**
     * updateStatusProduct
     */
    async updateStatusProductUsingPOST1(requestParameters: UpdateStatusProductUsingPOST1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SuccessResponseModel | null | undefined > {
        const response = await this.updateStatusProductUsingPOST1Raw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 201:
                return null;
            default:
                return await response.value();
        }
    }

}
