/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { MemberDto } from './MemberDto';
import {
    MemberDtoFromJSON,
    MemberDtoFromJSONTyped,
    MemberDtoToJSON,
} from './MemberDto';
import type { PointRuleDto } from './PointRuleDto';
import {
    PointRuleDtoFromJSON,
    PointRuleDtoFromJSONTyped,
    PointRuleDtoToJSON,
} from './PointRuleDto';

/**
 * 
 * @export
 * @interface OrderDtoLite
 */
export interface OrderDtoLite {
    /**
     * 
     * @type {number}
     * @memberof OrderDtoLite
     */
    birthdayDiscountPrice?: number;
    /**
     * 
     * @type {number}
     * @memberof OrderDtoLite
     */
    convertedCash?: number;
    /**
     * 
     * @type {string}
     * @memberof OrderDtoLite
     */
    couponCode?: string;
    /**
     * 
     * @type {number}
     * @memberof OrderDtoLite
     */
    couponDiscountPrice?: number;
    /**
     * 
     * @type {number}
     * @memberof OrderDtoLite
     */
    earnedPoint?: number;
    /**
     * 
     * @type {MemberDto}
     * @memberof OrderDtoLite
     */
    member?: MemberDto;
    /**
     * 
     * @type {string}
     * @memberof OrderDtoLite
     */
    orderAddress?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderDtoLite
     */
    orderAddress1?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderDtoLite
     */
    orderAddress2?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderDtoLite
     */
    orderAddress3?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderDtoLite
     */
    orderAddress4?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderDtoLite
     */
    orderAddressRecipient?: string;
    /**
     * 
     * @type {Date}
     * @memberof OrderDtoLite
     */
    orderDate?: Date;
    /**
     * 
     * @type {number}
     * @memberof OrderDtoLite
     */
    orderId?: number;
    /**
     * 
     * @type {string}
     * @memberof OrderDtoLite
     */
    orderNo?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderDtoLite
     */
    orderStatus?: string;
    /**
     * 
     * @type {number}
     * @memberof OrderDtoLite
     */
    orderTotal?: number;
    /**
     * 
     * @type {PointRuleDto}
     * @memberof OrderDtoLite
     */
    pointRule?: PointRuleDto;
    /**
     * 
     * @type {number}
     * @memberof OrderDtoLite
     */
    productTotal?: number;
    /**
     * 
     * @type {number}
     * @memberof OrderDtoLite
     */
    shippingFee?: number;
    /**
     * 
     * @type {number}
     * @memberof OrderDtoLite
     */
    usedPoint?: number;
}

/**
 * Check if a given object implements the OrderDtoLite interface.
 */
export function instanceOfOrderDtoLite(value: object): boolean {
    return true;
}

export function OrderDtoLiteFromJSON(json: any): OrderDtoLite {
    return OrderDtoLiteFromJSONTyped(json, false);
}

export function OrderDtoLiteFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrderDtoLite {
    if (json == null) {
        return json;
    }
    return {
        
        'birthdayDiscountPrice': json['birthdayDiscountPrice'] == null ? undefined : json['birthdayDiscountPrice'],
        'convertedCash': json['convertedCash'] == null ? undefined : json['convertedCash'],
        'couponCode': json['couponCode'] == null ? undefined : json['couponCode'],
        'couponDiscountPrice': json['couponDiscountPrice'] == null ? undefined : json['couponDiscountPrice'],
        'earnedPoint': json['earnedPoint'] == null ? undefined : json['earnedPoint'],
        'member': json['member'] == null ? undefined : MemberDtoFromJSON(json['member']),
        'orderAddress': json['orderAddress'] == null ? undefined : json['orderAddress'],
        'orderAddress1': json['orderAddress1'] == null ? undefined : json['orderAddress1'],
        'orderAddress2': json['orderAddress2'] == null ? undefined : json['orderAddress2'],
        'orderAddress3': json['orderAddress3'] == null ? undefined : json['orderAddress3'],
        'orderAddress4': json['orderAddress4'] == null ? undefined : json['orderAddress4'],
        'orderAddressRecipient': json['orderAddressRecipient'] == null ? undefined : json['orderAddressRecipient'],
        'orderDate': json['orderDate'] == null ? undefined : (new Date(json['orderDate'])),
        'orderId': json['orderId'] == null ? undefined : json['orderId'],
        'orderNo': json['orderNo'] == null ? undefined : json['orderNo'],
        'orderStatus': json['orderStatus'] == null ? undefined : json['orderStatus'],
        'orderTotal': json['orderTotal'] == null ? undefined : json['orderTotal'],
        'pointRule': json['pointRule'] == null ? undefined : PointRuleDtoFromJSON(json['pointRule']),
        'productTotal': json['productTotal'] == null ? undefined : json['productTotal'],
        'shippingFee': json['shippingFee'] == null ? undefined : json['shippingFee'],
        'usedPoint': json['usedPoint'] == null ? undefined : json['usedPoint'],
    };
}

export function OrderDtoLiteToJSON(value?: OrderDtoLite | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'birthdayDiscountPrice': value['birthdayDiscountPrice'],
        'convertedCash': value['convertedCash'],
        'couponCode': value['couponCode'],
        'couponDiscountPrice': value['couponDiscountPrice'],
        'earnedPoint': value['earnedPoint'],
        'member': MemberDtoToJSON(value['member']),
        'orderAddress': value['orderAddress'],
        'orderAddress1': value['orderAddress1'],
        'orderAddress2': value['orderAddress2'],
        'orderAddress3': value['orderAddress3'],
        'orderAddress4': value['orderAddress4'],
        'orderAddressRecipient': value['orderAddressRecipient'],
        'orderDate': value['orderDate'] == null ? undefined : ((value['orderDate']).toISOString()),
        'orderId': value['orderId'],
        'orderNo': value['orderNo'],
        'orderStatus': value['orderStatus'],
        'orderTotal': value['orderTotal'],
        'pointRule': PointRuleDtoToJSON(value['pointRule']),
        'productTotal': value['productTotal'],
        'shippingFee': value['shippingFee'],
        'usedPoint': value['usedPoint'],
    };
}

