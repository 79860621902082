/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Discount } from './Discount';
import {
    DiscountFromJSON,
    DiscountFromJSONTyped,
    DiscountToJSON,
} from './Discount';
import type { MarketingEvent } from './MarketingEvent';
import {
    MarketingEventFromJSON,
    MarketingEventFromJSONTyped,
    MarketingEventToJSON,
} from './MarketingEvent';

/**
 * 
 * @export
 * @interface MarketingEventDiscount
 */
export interface MarketingEventDiscount {
    /**
     * 
     * @type {Discount}
     * @memberof MarketingEventDiscount
     */
    discountByDiscountId?: Discount;
    /**
     * 
     * @type {number}
     * @memberof MarketingEventDiscount
     */
    discountId?: number;
    /**
     * 
     * @type {MarketingEvent}
     * @memberof MarketingEventDiscount
     */
    marketingEventByMarketingEventId?: MarketingEvent;
    /**
     * 
     * @type {number}
     * @memberof MarketingEventDiscount
     */
    marketingEventId?: number;
}

/**
 * Check if a given object implements the MarketingEventDiscount interface.
 */
export function instanceOfMarketingEventDiscount(value: object): boolean {
    return true;
}

export function MarketingEventDiscountFromJSON(json: any): MarketingEventDiscount {
    return MarketingEventDiscountFromJSONTyped(json, false);
}

export function MarketingEventDiscountFromJSONTyped(json: any, ignoreDiscriminator: boolean): MarketingEventDiscount {
    if (json == null) {
        return json;
    }
    return {
        
        'discountByDiscountId': json['discountByDiscountId'] == null ? undefined : DiscountFromJSON(json['discountByDiscountId']),
        'discountId': json['discountId'] == null ? undefined : json['discountId'],
        'marketingEventByMarketingEventId': json['marketingEventByMarketingEventId'] == null ? undefined : MarketingEventFromJSON(json['marketingEventByMarketingEventId']),
        'marketingEventId': json['marketingEventId'] == null ? undefined : json['marketingEventId'],
    };
}

export function MarketingEventDiscountToJSON(value?: MarketingEventDiscount | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'discountByDiscountId': DiscountToJSON(value['discountByDiscountId']),
        'discountId': value['discountId'],
        'marketingEventByMarketingEventId': MarketingEventToJSON(value['marketingEventByMarketingEventId']),
        'marketingEventId': value['marketingEventId'],
    };
}

