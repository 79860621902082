/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { DiscountDtoLite } from './DiscountDtoLite';
import {
    DiscountDtoLiteFromJSON,
    DiscountDtoLiteFromJSONTyped,
    DiscountDtoLiteToJSON,
} from './DiscountDtoLite';

/**
 * 
 * @export
 * @interface CouponDto
 */
export interface CouponDto {
    /**
     * 
     * @type {string}
     * @memberof CouponDto
     */
    couponCode?: string;
    /**
     * 
     * @type {number}
     * @memberof CouponDto
     */
    couponId?: number;
    /**
     * 
     * @type {DiscountDtoLite}
     * @memberof CouponDto
     */
    discount?: DiscountDtoLite;
    /**
     * 
     * @type {number}
     * @memberof CouponDto
     */
    discountId?: number;
    /**
     * 
     * @type {Date}
     * @memberof CouponDto
     */
    endDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof CouponDto
     */
    startDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof CouponDto
     */
    status?: string;
    /**
     * 
     * @type {string}
     * @memberof CouponDto
     */
    title?: string;
}

/**
 * Check if a given object implements the CouponDto interface.
 */
export function instanceOfCouponDto(value: object): boolean {
    return true;
}

export function CouponDtoFromJSON(json: any): CouponDto {
    return CouponDtoFromJSONTyped(json, false);
}

export function CouponDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CouponDto {
    if (json == null) {
        return json;
    }
    return {
        
        'couponCode': json['couponCode'] == null ? undefined : json['couponCode'],
        'couponId': json['couponId'] == null ? undefined : json['couponId'],
        'discount': json['discount'] == null ? undefined : DiscountDtoLiteFromJSON(json['discount']),
        'discountId': json['discountId'] == null ? undefined : json['discountId'],
        'endDate': json['endDate'] == null ? undefined : (new Date(json['endDate'])),
        'startDate': json['startDate'] == null ? undefined : (new Date(json['startDate'])),
        'status': json['status'] == null ? undefined : json['status'],
        'title': json['title'] == null ? undefined : json['title'],
    };
}

export function CouponDtoToJSON(value?: CouponDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'couponCode': value['couponCode'],
        'couponId': value['couponId'],
        'discount': DiscountDtoLiteToJSON(value['discount']),
        'discountId': value['discountId'],
        'endDate': value['endDate'] == null ? undefined : ((value['endDate']).toISOString()),
        'startDate': value['startDate'] == null ? undefined : ((value['startDate']).toISOString()),
        'status': value['status'],
        'title': value['title'],
    };
}

