/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { CategoryDto } from './CategoryDto';
import {
    CategoryDtoFromJSON,
    CategoryDtoFromJSONTyped,
    CategoryDtoToJSON,
} from './CategoryDto';
import type { HashtagDto } from './HashtagDto';
import {
    HashtagDtoFromJSON,
    HashtagDtoFromJSONTyped,
    HashtagDtoToJSON,
} from './HashtagDto';
import type { MarketingEventDtoLite } from './MarketingEventDtoLite';
import {
    MarketingEventDtoLiteFromJSON,
    MarketingEventDtoLiteFromJSONTyped,
    MarketingEventDtoLiteToJSON,
} from './MarketingEventDtoLite';
import type { Member } from './Member';
import {
    MemberFromJSON,
    MemberFromJSONTyped,
    MemberToJSON,
} from './Member';
import type { MemberDto } from './MemberDto';
import {
    MemberDtoFromJSON,
    MemberDtoFromJSONTyped,
    MemberDtoToJSON,
} from './MemberDto';
import type { ProductDto } from './ProductDto';
import {
    ProductDtoFromJSON,
    ProductDtoFromJSONTyped,
    ProductDtoToJSON,
} from './ProductDto';

/**
 * 
 * @export
 * @interface DisplayProductDto
 */
export interface DisplayProductDto {
    /**
     * 
     * @type {string}
     * @memberof DisplayProductDto
     */
    bookComment?: string;
    /**
     * 
     * @type {Array<CategoryDto>}
     * @memberof DisplayProductDto
     */
    categories?: Array<CategoryDto>;
    /**
     * 
     * @type {string}
     * @memberof DisplayProductDto
     */
    displayProductAuthor?: string;
    /**
     * 
     * @type {string}
     * @memberof DisplayProductDto
     */
    displayProductDescriptionDetail?: string;
    /**
     * 
     * @type {string}
     * @memberof DisplayProductDto
     */
    displayProductDescriptionFeature?: string;
    /**
     * 
     * @type {string}
     * @memberof DisplayProductDto
     */
    displayProductDescriptionIngredient?: string;
    /**
     * 
     * @type {string}
     * @memberof DisplayProductDto
     */
    displayProductDescriptionTarget?: string;
    /**
     * 
     * @type {string}
     * @memberof DisplayProductDto
     */
    displayProductDescriptionUsage?: string;
    /**
     * 
     * @type {string}
     * @memberof DisplayProductDto
     */
    displayProductDescriptionUseCount?: string;
    /**
     * 
     * @type {number}
     * @memberof DisplayProductDto
     */
    displayProductDiscountPrice?: number;
    /**
     * 
     * @type {number}
     * @memberof DisplayProductDto
     */
    displayProductId?: number;
    /**
     * 
     * @type {string}
     * @memberof DisplayProductDto
     */
    displayProductImage?: string;
    /**
     * 
     * @type {string}
     * @memberof DisplayProductDto
     */
    displayProductImageCdn?: string;
    /**
     * 
     * @type {string}
     * @memberof DisplayProductDto
     */
    displayProductNameChi?: string;
    /**
     * 
     * @type {string}
     * @memberof DisplayProductDto
     */
    displayProductNameEng?: string;
    /**
     * 
     * @type {string}
     * @memberof DisplayProductDto
     */
    displayProductNameHtmlChi?: string;
    /**
     * 
     * @type {string}
     * @memberof DisplayProductDto
     */
    displayProductNameHtmlEng?: string;
    /**
     * 
     * @type {number}
     * @memberof DisplayProductDto
     */
    displayProductOriginalPrice?: number;
    /**
     * 
     * @type {number}
     * @memberof DisplayProductDto
     */
    displayProductPrice?: number;
    /**
     * 
     * @type {string}
     * @memberof DisplayProductDto
     */
    displayProductShortDescription?: string;
    /**
     * 
     * @type {string}
     * @memberof DisplayProductDto
     */
    displayProductTranslator?: string;
    /**
     * 
     * @type {string}
     * @memberof DisplayProductDto
     */
    displayProductUrlNaming?: string;
    /**
     * 
     * @type {string}
     * @memberof DisplayProductDto
     */
    ePubProdFileName?: string;
    /**
     * 
     * @type {string}
     * @memberof DisplayProductDto
     */
    functionalIcon?: string;
    /**
     * 
     * @type {Array<HashtagDto>}
     * @memberof DisplayProductDto
     */
    hashtags?: Array<HashtagDto>;
    /**
     * 
     * @type {string}
     * @memberof DisplayProductDto
     */
    isbn?: string;
    /**
     * 
     * @type {string}
     * @memberof DisplayProductDto
     */
    language?: string;
    /**
     * 
     * @type {Array<MarketingEventDtoLite>}
     * @memberof DisplayProductDto
     */
    marketingEvents?: Array<MarketingEventDtoLite>;
    /**
     * 
     * @type {Member}
     * @memberof DisplayProductDto
     */
    member?: Member;
    /**
     * 
     * @type {MemberDto}
     * @memberof DisplayProductDto
     */
    memberOutput?: MemberDto;
    /**
     * 
     * @type {string}
     * @memberof DisplayProductDto
     */
    metadesc?: string;
    /**
     * 
     * @type {string}
     * @memberof DisplayProductDto
     */
    metatitle?: string;
    /**
     * 
     * @type {Date}
     * @memberof DisplayProductDto
     */
    offShelfDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof DisplayProductDto
     */
    onShelfDate?: Date;
    /**
     * 
     * @type {number}
     * @memberof DisplayProductDto
     */
    order?: number;
    /**
     * 
     * @type {number}
     * @memberof DisplayProductDto
     */
    organizationId?: number;
    /**
     * 
     * @type {string}
     * @memberof DisplayProductDto
     */
    productType?: string;
    /**
     * 
     * @type {Array<ProductDto>}
     * @memberof DisplayProductDto
     */
    products?: Array<ProductDto>;
    /**
     * 
     * @type {string}
     * @memberof DisplayProductDto
     */
    publisher?: string;
    /**
     * 
     * @type {boolean}
     * @memberof DisplayProductDto
     */
    purchased?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DisplayProductDto
     */
    recommended?: string;
    /**
     * 
     * @type {Array<DisplayProductDto>}
     * @memberof DisplayProductDto
     */
    recommendeds?: Array<DisplayProductDto>;
    /**
     * 
     * @type {string}
     * @memberof DisplayProductDto
     */
    sku?: string;
    /**
     * 
     * @type {string}
     * @memberof DisplayProductDto
     */
    status?: string;
}

/**
 * Check if a given object implements the DisplayProductDto interface.
 */
export function instanceOfDisplayProductDto(value: object): boolean {
    return true;
}

export function DisplayProductDtoFromJSON(json: any): DisplayProductDto {
    return DisplayProductDtoFromJSONTyped(json, false);
}

export function DisplayProductDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): DisplayProductDto {
    if (json == null) {
        return json;
    }
    return {
        
        'bookComment': json['bookComment'] == null ? undefined : json['bookComment'],
        'categories': json['categories'] == null ? undefined : ((json['categories'] as Array<any>).map(CategoryDtoFromJSON)),
        'displayProductAuthor': json['displayProductAuthor'] == null ? undefined : json['displayProductAuthor'],
        'displayProductDescriptionDetail': json['displayProductDescriptionDetail'] == null ? undefined : json['displayProductDescriptionDetail'],
        'displayProductDescriptionFeature': json['displayProductDescriptionFeature'] == null ? undefined : json['displayProductDescriptionFeature'],
        'displayProductDescriptionIngredient': json['displayProductDescriptionIngredient'] == null ? undefined : json['displayProductDescriptionIngredient'],
        'displayProductDescriptionTarget': json['displayProductDescriptionTarget'] == null ? undefined : json['displayProductDescriptionTarget'],
        'displayProductDescriptionUsage': json['displayProductDescriptionUsage'] == null ? undefined : json['displayProductDescriptionUsage'],
        'displayProductDescriptionUseCount': json['displayProductDescriptionUseCount'] == null ? undefined : json['displayProductDescriptionUseCount'],
        'displayProductDiscountPrice': json['displayProductDiscountPrice'] == null ? undefined : json['displayProductDiscountPrice'],
        'displayProductId': json['displayProductId'] == null ? undefined : json['displayProductId'],
        'displayProductImage': json['displayProductImage'] == null ? undefined : json['displayProductImage'],
        'displayProductImageCdn': json['displayProductImageCdn'] == null ? undefined : json['displayProductImageCdn'],
        'displayProductNameChi': json['displayProductNameChi'] == null ? undefined : json['displayProductNameChi'],
        'displayProductNameEng': json['displayProductNameEng'] == null ? undefined : json['displayProductNameEng'],
        'displayProductNameHtmlChi': json['displayProductNameHtmlChi'] == null ? undefined : json['displayProductNameHtmlChi'],
        'displayProductNameHtmlEng': json['displayProductNameHtmlEng'] == null ? undefined : json['displayProductNameHtmlEng'],
        'displayProductOriginalPrice': json['displayProductOriginalPrice'] == null ? undefined : json['displayProductOriginalPrice'],
        'displayProductPrice': json['displayProductPrice'] == null ? undefined : json['displayProductPrice'],
        'displayProductShortDescription': json['displayProductShortDescription'] == null ? undefined : json['displayProductShortDescription'],
        'displayProductTranslator': json['displayProductTranslator'] == null ? undefined : json['displayProductTranslator'],
        'displayProductUrlNaming': json['displayProductUrlNaming'] == null ? undefined : json['displayProductUrlNaming'],
        'ePubProdFileName': json['ePubProdFileName'] == null ? undefined : json['ePubProdFileName'],
        'functionalIcon': json['functionalIcon'] == null ? undefined : json['functionalIcon'],
        'hashtags': json['hashtags'] == null ? undefined : ((json['hashtags'] as Array<any>).map(HashtagDtoFromJSON)),
        'isbn': json['isbn'] == null ? undefined : json['isbn'],
        'language': json['language'] == null ? undefined : json['language'],
        'marketingEvents': json['marketingEvents'] == null ? undefined : ((json['marketingEvents'] as Array<any>).map(MarketingEventDtoLiteFromJSON)),
        'member': json['member'] == null ? undefined : MemberFromJSON(json['member']),
        'memberOutput': json['memberOutput'] == null ? undefined : MemberDtoFromJSON(json['memberOutput']),
        'metadesc': json['metadesc'] == null ? undefined : json['metadesc'],
        'metatitle': json['metatitle'] == null ? undefined : json['metatitle'],
        'offShelfDate': json['offShelfDate'] == null ? undefined : (new Date(json['offShelfDate'])),
        'onShelfDate': json['onShelfDate'] == null ? undefined : (new Date(json['onShelfDate'])),
        'order': json['order'] == null ? undefined : json['order'],
        'organizationId': json['organizationId'] == null ? undefined : json['organizationId'],
        'productType': json['productType'] == null ? undefined : json['productType'],
        'products': json['products'] == null ? undefined : ((json['products'] as Array<any>).map(ProductDtoFromJSON)),
        'publisher': json['publisher'] == null ? undefined : json['publisher'],
        'purchased': json['purchased'] == null ? undefined : json['purchased'],
        'recommended': json['recommended'] == null ? undefined : json['recommended'],
        'recommendeds': json['recommendeds'] == null ? undefined : ((json['recommendeds'] as Array<any>).map(DisplayProductDtoFromJSON)),
        'sku': json['sku'] == null ? undefined : json['sku'],
        'status': json['status'] == null ? undefined : json['status'],
    };
}

export function DisplayProductDtoToJSON(value?: DisplayProductDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'bookComment': value['bookComment'],
        'categories': value['categories'] == null ? undefined : ((value['categories'] as Array<any>).map(CategoryDtoToJSON)),
        'displayProductAuthor': value['displayProductAuthor'],
        'displayProductDescriptionDetail': value['displayProductDescriptionDetail'],
        'displayProductDescriptionFeature': value['displayProductDescriptionFeature'],
        'displayProductDescriptionIngredient': value['displayProductDescriptionIngredient'],
        'displayProductDescriptionTarget': value['displayProductDescriptionTarget'],
        'displayProductDescriptionUsage': value['displayProductDescriptionUsage'],
        'displayProductDescriptionUseCount': value['displayProductDescriptionUseCount'],
        'displayProductDiscountPrice': value['displayProductDiscountPrice'],
        'displayProductId': value['displayProductId'],
        'displayProductImage': value['displayProductImage'],
        'displayProductImageCdn': value['displayProductImageCdn'],
        'displayProductNameChi': value['displayProductNameChi'],
        'displayProductNameEng': value['displayProductNameEng'],
        'displayProductNameHtmlChi': value['displayProductNameHtmlChi'],
        'displayProductNameHtmlEng': value['displayProductNameHtmlEng'],
        'displayProductOriginalPrice': value['displayProductOriginalPrice'],
        'displayProductPrice': value['displayProductPrice'],
        'displayProductShortDescription': value['displayProductShortDescription'],
        'displayProductTranslator': value['displayProductTranslator'],
        'displayProductUrlNaming': value['displayProductUrlNaming'],
        'ePubProdFileName': value['ePubProdFileName'],
        'functionalIcon': value['functionalIcon'],
        'hashtags': value['hashtags'] == null ? undefined : ((value['hashtags'] as Array<any>).map(HashtagDtoToJSON)),
        'isbn': value['isbn'],
        'language': value['language'],
        'marketingEvents': value['marketingEvents'] == null ? undefined : ((value['marketingEvents'] as Array<any>).map(MarketingEventDtoLiteToJSON)),
        'member': MemberToJSON(value['member']),
        'memberOutput': MemberDtoToJSON(value['memberOutput']),
        'metadesc': value['metadesc'],
        'metatitle': value['metatitle'],
        'offShelfDate': value['offShelfDate'] == null ? undefined : ((value['offShelfDate']).toISOString()),
        'onShelfDate': value['onShelfDate'] == null ? undefined : ((value['onShelfDate']).toISOString()),
        'order': value['order'],
        'organizationId': value['organizationId'],
        'productType': value['productType'],
        'products': value['products'] == null ? undefined : ((value['products'] as Array<any>).map(ProductDtoToJSON)),
        'publisher': value['publisher'],
        'purchased': value['purchased'],
        'recommended': value['recommended'],
        'recommendeds': value['recommendeds'] == null ? undefined : ((value['recommendeds'] as Array<any>).map(DisplayProductDtoToJSON)),
        'sku': value['sku'],
        'status': value['status'],
    };
}

