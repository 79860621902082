/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CheckoutBannerDto,
} from '../models/index';
import {
    CheckoutBannerDtoFromJSON,
    CheckoutBannerDtoToJSON,
} from '../models/index';

export interface AddUsingGETRequest {
    content: string;
    name?: string;
    linkTo?: string;
    type?: number;
    position?: number;
}

export interface DeleteUsingGET1Request {
    id: number;
    name?: string;
}

export interface DisableUsingGETRequest {
    id: number;
    name?: string;
}

export interface EnableUsingGETRequest {
    id: number;
    name?: string;
}

export interface GetAllAdminUsingGETRequest {
    name?: string;
}

export interface GetByIdAdminUsingGETRequest {
    id: number;
    name?: string;
}

export interface GetByIdUsingGETRequest {
    id: number;
}

export interface UpdateUsingGETRequest {
    id: number;
    name?: string;
    content?: string;
    linkTo?: string;
    type?: number;
    position?: number;
}

/**
 * 
 */
export class CheckoutBannerControllerApi extends runtime.BaseAPI {

    /**
     * add
     */
    async addUsingGETRaw(requestParameters: AddUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CheckoutBannerDto>> {
        if (requestParameters['content'] == null) {
            throw new runtime.RequiredError(
                'content',
                'Required parameter "content" was null or undefined when calling addUsingGET().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        if (requestParameters['content'] != null) {
            queryParameters['content'] = requestParameters['content'];
        }

        if (requestParameters['linkTo'] != null) {
            queryParameters['linkTo'] = requestParameters['linkTo'];
        }

        if (requestParameters['type'] != null) {
            queryParameters['type'] = requestParameters['type'];
        }

        if (requestParameters['position'] != null) {
            queryParameters['position'] = requestParameters['position'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/checkoutBanner/admin/add`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CheckoutBannerDtoFromJSON(jsonValue));
    }

    /**
     * add
     */
    async addUsingGET(requestParameters: AddUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CheckoutBannerDto> {
        const response = await this.addUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * delete
     */
    async deleteUsingGET1Raw(requestParameters: DeleteUsingGET1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CheckoutBannerDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling deleteUsingGET1().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        if (requestParameters['id'] != null) {
            queryParameters['id'] = requestParameters['id'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/checkoutBanner/admin/delete`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CheckoutBannerDtoFromJSON(jsonValue));
    }

    /**
     * delete
     */
    async deleteUsingGET1(requestParameters: DeleteUsingGET1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CheckoutBannerDto> {
        const response = await this.deleteUsingGET1Raw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * disable
     */
    async disableUsingGETRaw(requestParameters: DisableUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CheckoutBannerDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling disableUsingGET().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        if (requestParameters['id'] != null) {
            queryParameters['id'] = requestParameters['id'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/checkoutBanner/admin/disable`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CheckoutBannerDtoFromJSON(jsonValue));
    }

    /**
     * disable
     */
    async disableUsingGET(requestParameters: DisableUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CheckoutBannerDto> {
        const response = await this.disableUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * enable
     */
    async enableUsingGETRaw(requestParameters: EnableUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CheckoutBannerDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling enableUsingGET().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        if (requestParameters['id'] != null) {
            queryParameters['id'] = requestParameters['id'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/checkoutBanner/admin/enale`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CheckoutBannerDtoFromJSON(jsonValue));
    }

    /**
     * enable
     */
    async enableUsingGET(requestParameters: EnableUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CheckoutBannerDto> {
        const response = await this.enableUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getAllAdmin
     */
    async getAllAdminUsingGETRaw(requestParameters: GetAllAdminUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<CheckoutBannerDto>>> {
        const queryParameters: any = {};

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/checkoutBanner/admin/all`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CheckoutBannerDtoFromJSON));
    }

    /**
     * getAllAdmin
     */
    async getAllAdminUsingGET(requestParameters: GetAllAdminUsingGETRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<CheckoutBannerDto>> {
        const response = await this.getAllAdminUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getAll
     */
    async getAllUsingGET1Raw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<CheckoutBannerDto>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/checkoutBanner/all`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CheckoutBannerDtoFromJSON));
    }

    /**
     * getAll
     */
    async getAllUsingGET1(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<CheckoutBannerDto>> {
        const response = await this.getAllUsingGET1Raw(initOverrides);
        return await response.value();
    }

    /**
     * getByIdAdmin
     */
    async getByIdAdminUsingGETRaw(requestParameters: GetByIdAdminUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CheckoutBannerDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling getByIdAdminUsingGET().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/checkoutBanner/admin/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CheckoutBannerDtoFromJSON(jsonValue));
    }

    /**
     * getByIdAdmin
     */
    async getByIdAdminUsingGET(requestParameters: GetByIdAdminUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CheckoutBannerDto> {
        const response = await this.getByIdAdminUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getById
     */
    async getByIdUsingGETRaw(requestParameters: GetByIdUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CheckoutBannerDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling getByIdUsingGET().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/checkoutBanner/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CheckoutBannerDtoFromJSON(jsonValue));
    }

    /**
     * getById
     */
    async getByIdUsingGET(requestParameters: GetByIdUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CheckoutBannerDto> {
        const response = await this.getByIdUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * update
     */
    async updateUsingGETRaw(requestParameters: UpdateUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CheckoutBannerDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling updateUsingGET().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        if (requestParameters['id'] != null) {
            queryParameters['id'] = requestParameters['id'];
        }

        if (requestParameters['content'] != null) {
            queryParameters['content'] = requestParameters['content'];
        }

        if (requestParameters['linkTo'] != null) {
            queryParameters['linkTo'] = requestParameters['linkTo'];
        }

        if (requestParameters['type'] != null) {
            queryParameters['type'] = requestParameters['type'];
        }

        if (requestParameters['position'] != null) {
            queryParameters['position'] = requestParameters['position'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/checkoutBanner/admin/update`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CheckoutBannerDtoFromJSON(jsonValue));
    }

    /**
     * update
     */
    async updateUsingGET(requestParameters: UpdateUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CheckoutBannerDto> {
        const response = await this.updateUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
