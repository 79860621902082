/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AgeGroup
 */
export interface AgeGroup {
    /**
     * 
     * @type {number}
     * @memberof AgeGroup
     */
    ageGroupId?: number;
    /**
     * 
     * @type {string}
     * @memberof AgeGroup
     */
    ageGroupName?: string;
    /**
     * 
     * @type {string}
     * @memberof AgeGroup
     */
    status?: string;
}

/**
 * Check if a given object implements the AgeGroup interface.
 */
export function instanceOfAgeGroup(value: object): boolean {
    return true;
}

export function AgeGroupFromJSON(json: any): AgeGroup {
    return AgeGroupFromJSONTyped(json, false);
}

export function AgeGroupFromJSONTyped(json: any, ignoreDiscriminator: boolean): AgeGroup {
    if (json == null) {
        return json;
    }
    return {
        
        'ageGroupId': json['ageGroupId'] == null ? undefined : json['ageGroupId'],
        'ageGroupName': json['ageGroupName'] == null ? undefined : json['ageGroupName'],
        'status': json['status'] == null ? undefined : json['status'],
    };
}

export function AgeGroupToJSON(value?: AgeGroup | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'ageGroupId': value['ageGroupId'],
        'ageGroupName': value['ageGroupName'],
        'status': value['status'],
    };
}

