/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  Resource,
} from '../models/index';
import {
    ResourceFromJSON,
    ResourceToJSON,
} from '../models/index';

export interface DownloadGiftRedemptionExcelUsingGETRequest {
    endDate: string;
    startDate: string;
    name?: string;
}

export interface DownloadMemberAndConsumptionAnalysisExcelUsingGETRequest {
    endDate: string;
    startDate: string;
    name?: string;
}

export interface DownloadSFExcelUsingGETRequest {
    endDate: string;
    startDate: string;
    name?: string;
}

export interface DownloadSalesExcelUsingGETRequest {
    endDate: string;
    startDate: string;
    name?: string;
    paytype?: string;
}

export interface DownloadUnpackExcelUsingGETRequest {
    endDate: string;
    startDate: string;
    name?: string;
    paytype?: string;
}

/**
 * 
 */
export class ExcelControllerApi extends runtime.BaseAPI {

    /**
     * downloadGiftRedemptionExcel
     */
    async downloadGiftRedemptionExcelUsingGETRaw(requestParameters: DownloadGiftRedemptionExcelUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Resource>> {
        if (requestParameters['endDate'] == null) {
            throw new runtime.RequiredError(
                'endDate',
                'Required parameter "endDate" was null or undefined when calling downloadGiftRedemptionExcelUsingGET().'
            );
        }

        if (requestParameters['startDate'] == null) {
            throw new runtime.RequiredError(
                'startDate',
                'Required parameter "startDate" was null or undefined when calling downloadGiftRedemptionExcelUsingGET().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['endDate'] != null) {
            queryParameters['endDate'] = requestParameters['endDate'];
        }

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        if (requestParameters['startDate'] != null) {
            queryParameters['startDate'] = requestParameters['startDate'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/excel/giftRedemptionExcel`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResourceFromJSON(jsonValue));
    }

    /**
     * downloadGiftRedemptionExcel
     */
    async downloadGiftRedemptionExcelUsingGET(requestParameters: DownloadGiftRedemptionExcelUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Resource> {
        const response = await this.downloadGiftRedemptionExcelUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * downloadMemberAndConsumptionAnalysisExcel
     */
    async downloadMemberAndConsumptionAnalysisExcelUsingGETRaw(requestParameters: DownloadMemberAndConsumptionAnalysisExcelUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Resource>> {
        if (requestParameters['endDate'] == null) {
            throw new runtime.RequiredError(
                'endDate',
                'Required parameter "endDate" was null or undefined when calling downloadMemberAndConsumptionAnalysisExcelUsingGET().'
            );
        }

        if (requestParameters['startDate'] == null) {
            throw new runtime.RequiredError(
                'startDate',
                'Required parameter "startDate" was null or undefined when calling downloadMemberAndConsumptionAnalysisExcelUsingGET().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['endDate'] != null) {
            queryParameters['endDate'] = requestParameters['endDate'];
        }

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        if (requestParameters['startDate'] != null) {
            queryParameters['startDate'] = requestParameters['startDate'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/excel/memberAndConsumptionAnalysisExcel`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResourceFromJSON(jsonValue));
    }

    /**
     * downloadMemberAndConsumptionAnalysisExcel
     */
    async downloadMemberAndConsumptionAnalysisExcelUsingGET(requestParameters: DownloadMemberAndConsumptionAnalysisExcelUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Resource> {
        const response = await this.downloadMemberAndConsumptionAnalysisExcelUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * downloadSFExcel
     */
    async downloadSFExcelUsingGETRaw(requestParameters: DownloadSFExcelUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Resource>> {
        if (requestParameters['endDate'] == null) {
            throw new runtime.RequiredError(
                'endDate',
                'Required parameter "endDate" was null or undefined when calling downloadSFExcelUsingGET().'
            );
        }

        if (requestParameters['startDate'] == null) {
            throw new runtime.RequiredError(
                'startDate',
                'Required parameter "startDate" was null or undefined when calling downloadSFExcelUsingGET().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['endDate'] != null) {
            queryParameters['endDate'] = requestParameters['endDate'];
        }

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        if (requestParameters['startDate'] != null) {
            queryParameters['startDate'] = requestParameters['startDate'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/excel/downloadSFExcel`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResourceFromJSON(jsonValue));
    }

    /**
     * downloadSFExcel
     */
    async downloadSFExcelUsingGET(requestParameters: DownloadSFExcelUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Resource> {
        const response = await this.downloadSFExcelUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * downloadSalesExcel
     */
    async downloadSalesExcelUsingGETRaw(requestParameters: DownloadSalesExcelUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Resource>> {
        if (requestParameters['endDate'] == null) {
            throw new runtime.RequiredError(
                'endDate',
                'Required parameter "endDate" was null or undefined when calling downloadSalesExcelUsingGET().'
            );
        }

        if (requestParameters['startDate'] == null) {
            throw new runtime.RequiredError(
                'startDate',
                'Required parameter "startDate" was null or undefined when calling downloadSalesExcelUsingGET().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['endDate'] != null) {
            queryParameters['endDate'] = requestParameters['endDate'];
        }

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        if (requestParameters['startDate'] != null) {
            queryParameters['startDate'] = requestParameters['startDate'];
        }

        if (requestParameters['paytype'] != null) {
            queryParameters['paytype'] = requestParameters['paytype'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/excel/salesExcel`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResourceFromJSON(jsonValue));
    }

    /**
     * downloadSalesExcel
     */
    async downloadSalesExcelUsingGET(requestParameters: DownloadSalesExcelUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Resource> {
        const response = await this.downloadSalesExcelUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * downloadUnpackExcel
     */
    async downloadUnpackExcelUsingGETRaw(requestParameters: DownloadUnpackExcelUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Resource>> {
        if (requestParameters['endDate'] == null) {
            throw new runtime.RequiredError(
                'endDate',
                'Required parameter "endDate" was null or undefined when calling downloadUnpackExcelUsingGET().'
            );
        }

        if (requestParameters['startDate'] == null) {
            throw new runtime.RequiredError(
                'startDate',
                'Required parameter "startDate" was null or undefined when calling downloadUnpackExcelUsingGET().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['endDate'] != null) {
            queryParameters['endDate'] = requestParameters['endDate'];
        }

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        if (requestParameters['startDate'] != null) {
            queryParameters['startDate'] = requestParameters['startDate'];
        }

        if (requestParameters['paytype'] != null) {
            queryParameters['paytype'] = requestParameters['paytype'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/excel/unpackExcel`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResourceFromJSON(jsonValue));
    }

    /**
     * downloadUnpackExcel
     */
    async downloadUnpackExcelUsingGET(requestParameters: DownloadUnpackExcelUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Resource> {
        const response = await this.downloadUnpackExcelUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
