/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { CategoryDto } from './CategoryDto';
import {
    CategoryDtoFromJSON,
    CategoryDtoFromJSONTyped,
    CategoryDtoToJSON,
} from './CategoryDto';
import type { DiscountDtoLite } from './DiscountDtoLite';
import {
    DiscountDtoLiteFromJSON,
    DiscountDtoLiteFromJSONTyped,
    DiscountDtoLiteToJSON,
} from './DiscountDtoLite';

/**
 * 
 * @export
 * @interface MarketingEventDtoLite
 */
export interface MarketingEventDtoLite {
    /**
     * 
     * @type {Array<number>}
     * @memberof MarketingEventDtoLite
     */
    articleIds?: Array<number>;
    /**
     * 
     * @type {string}
     * @memberof MarketingEventDtoLite
     */
    bannerContent?: string;
    /**
     * 
     * @type {number}
     * @memberof MarketingEventDtoLite
     */
    bannerPosition?: number;
    /**
     * 
     * @type {CategoryDto}
     * @memberof MarketingEventDtoLite
     */
    category?: CategoryDto;
    /**
     * 
     * @type {DiscountDtoLite}
     * @memberof MarketingEventDtoLite
     */
    discount?: DiscountDtoLite;
    /**
     * 
     * @type {Date}
     * @memberof MarketingEventDtoLite
     */
    endDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof MarketingEventDtoLite
     */
    expireBannerContent?: string;
    /**
     * 
     * @type {Date}
     * @memberof MarketingEventDtoLite
     */
    expireBannerEndDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof MarketingEventDtoLite
     */
    isMegaSale?: string;
    /**
     * 
     * @type {number}
     * @memberof MarketingEventDtoLite
     */
    marketingEventId?: number;
    /**
     * 
     * @type {string}
     * @memberof MarketingEventDtoLite
     */
    marketingEventName?: string;
    /**
     * 
     * @type {number}
     * @memberof MarketingEventDtoLite
     */
    marketingEventPriority?: number;
    /**
     * 
     * @type {string}
     * @memberof MarketingEventDtoLite
     */
    mobileBannerContent?: string;
    /**
     * 
     * @type {string}
     * @memberof MarketingEventDtoLite
     */
    mobileExpireBannerContent?: string;
    /**
     * 
     * @type {string}
     * @memberof MarketingEventDtoLite
     */
    promotionCode?: string;
    /**
     * 
     * @type {Date}
     * @memberof MarketingEventDtoLite
     */
    startDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof MarketingEventDtoLite
     */
    status?: string;
}

/**
 * Check if a given object implements the MarketingEventDtoLite interface.
 */
export function instanceOfMarketingEventDtoLite(value: object): boolean {
    return true;
}

export function MarketingEventDtoLiteFromJSON(json: any): MarketingEventDtoLite {
    return MarketingEventDtoLiteFromJSONTyped(json, false);
}

export function MarketingEventDtoLiteFromJSONTyped(json: any, ignoreDiscriminator: boolean): MarketingEventDtoLite {
    if (json == null) {
        return json;
    }
    return {
        
        'articleIds': json['articleIds'] == null ? undefined : json['articleIds'],
        'bannerContent': json['bannerContent'] == null ? undefined : json['bannerContent'],
        'bannerPosition': json['bannerPosition'] == null ? undefined : json['bannerPosition'],
        'category': json['category'] == null ? undefined : CategoryDtoFromJSON(json['category']),
        'discount': json['discount'] == null ? undefined : DiscountDtoLiteFromJSON(json['discount']),
        'endDate': json['endDate'] == null ? undefined : (new Date(json['endDate'])),
        'expireBannerContent': json['expireBannerContent'] == null ? undefined : json['expireBannerContent'],
        'expireBannerEndDate': json['expireBannerEndDate'] == null ? undefined : (new Date(json['expireBannerEndDate'])),
        'isMegaSale': json['isMegaSale'] == null ? undefined : json['isMegaSale'],
        'marketingEventId': json['marketingEventId'] == null ? undefined : json['marketingEventId'],
        'marketingEventName': json['marketingEventName'] == null ? undefined : json['marketingEventName'],
        'marketingEventPriority': json['marketingEventPriority'] == null ? undefined : json['marketingEventPriority'],
        'mobileBannerContent': json['mobileBannerContent'] == null ? undefined : json['mobileBannerContent'],
        'mobileExpireBannerContent': json['mobileExpireBannerContent'] == null ? undefined : json['mobileExpireBannerContent'],
        'promotionCode': json['promotionCode'] == null ? undefined : json['promotionCode'],
        'startDate': json['startDate'] == null ? undefined : (new Date(json['startDate'])),
        'status': json['status'] == null ? undefined : json['status'],
    };
}

export function MarketingEventDtoLiteToJSON(value?: MarketingEventDtoLite | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'articleIds': value['articleIds'],
        'bannerContent': value['bannerContent'],
        'bannerPosition': value['bannerPosition'],
        'category': CategoryDtoToJSON(value['category']),
        'discount': DiscountDtoLiteToJSON(value['discount']),
        'endDate': value['endDate'] == null ? undefined : ((value['endDate']).toISOString()),
        'expireBannerContent': value['expireBannerContent'],
        'expireBannerEndDate': value['expireBannerEndDate'] == null ? undefined : ((value['expireBannerEndDate']).toISOString()),
        'isMegaSale': value['isMegaSale'],
        'marketingEventId': value['marketingEventId'],
        'marketingEventName': value['marketingEventName'],
        'marketingEventPriority': value['marketingEventPriority'],
        'mobileBannerContent': value['mobileBannerContent'],
        'mobileExpireBannerContent': value['mobileExpireBannerContent'],
        'promotionCode': value['promotionCode'],
        'startDate': value['startDate'] == null ? undefined : ((value['startDate']).toISOString()),
        'status': value['status'],
    };
}

