/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface LanguageRequestModel
 */
export interface LanguageRequestModel {
    /**
     * 
     * @type {boolean}
     * @memberof LanguageRequestModel
     */
    _delete?: boolean;
    /**
     * 
     * @type {string}
     * @memberof LanguageRequestModel
     */
    languageName?: string;
    /**
     * 
     * @type {string}
     * @memberof LanguageRequestModel
     */
    mongoId?: string;
    /**
     * 
     * @type {number}
     * @memberof LanguageRequestModel
     */
    seq?: number;
}

/**
 * Check if a given object implements the LanguageRequestModel interface.
 */
export function instanceOfLanguageRequestModel(value: object): boolean {
    return true;
}

export function LanguageRequestModelFromJSON(json: any): LanguageRequestModel {
    return LanguageRequestModelFromJSONTyped(json, false);
}

export function LanguageRequestModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): LanguageRequestModel {
    if (json == null) {
        return json;
    }
    return {
        
        '_delete': json['delete'] == null ? undefined : json['delete'],
        'languageName': json['languageName'] == null ? undefined : json['languageName'],
        'mongoId': json['mongoId'] == null ? undefined : json['mongoId'],
        'seq': json['seq'] == null ? undefined : json['seq'],
    };
}

export function LanguageRequestModelToJSON(value?: LanguageRequestModel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'delete': value['_delete'],
        'languageName': value['languageName'],
        'mongoId': value['mongoId'],
        'seq': value['seq'],
    };
}

