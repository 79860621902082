import {
  Configuration,
  MemberControllerApi,
  RefreshTokenUsingPOSTRequest,
} from "../fetcher";


//const baseUrl = process.env.REACT_APP_BACKEND;
const baseUrl = process.env.REACT_APP_BACKEND_URL;
export const genApiConfig = (
  noCache: boolean | null = null,
  header: { [key: string]: any } = {
    credentials: "include",
  },
  mode: string = "cors"
): Configuration => {
  // let header: { [key: string]: any } = {
  //   credentials: "include",
  // };

  if (localStorage.getItem("accessToken") !== null) {
    header["Authorization"] = `Bearer ${localStorage.getItem("accessToken")}`;
  }

  header["Access-Control-Allow-Origin"] = "*";
  header["Access-Control-Allow-Credentials"] = "true";

  //headers.append('Access-Control-Allow-Credentials', 'true');

  // "Accept-Language": Storage.language,

  var conf = new Configuration({
    basePath: baseUrl,
    headers: header,
    fetchApi: (input: any, init: any) => {
      //init["mode"] = "no-cors";
      return fetch(input, init)
        .then(async (res) => {
          if (res.status && res.status === 401) {
            var token = await new MemberControllerApi(
              new Configuration({
                basePath: baseUrl,
              })
            ).refreshTokenUsingPOST({
              refreshToken: localStorage.getItem("refreshToken")?.toString(),
            } as RefreshTokenUsingPOSTRequest);

            if (Object.keys(token).length > 0) {
              localStorage.setItem("accessToken", token.accessToken!!);
              localStorage.setItem("refreshToken", token.refreshToken!!);
              init.headers["Authorization"] = `Bearer ${token.accessToken}`;

              return await fetch(input, init);
            } else {
              let isPhone = localStorage.getItem("isPhone");
              let savedSchool = localStorage.getItem("savedSchool");
              localStorage.clear();
              localStorage.setItem("isPhone", isPhone!.toString());
              if (savedSchool !== null)
                localStorage.setItem("savedSchool", savedSchool!);
              window.location.pathname = "/";
            }
          }

          if (!res.ok) {
            return Promise.reject(res);
          } else {
            return Promise.resolve(res);
          }
        })
        .catch(async (err) => {
          console.log("err", err);
          //TODO Server Error GOTO 404?
          // let isPhone = localStorage.getItem("isPhone");
          // let savedSchool = localStorage.getItem("savedSchool");
          // localStorage.clear();
          // localStorage.setItem("isPhone", isPhone!.toString());
          // if (savedSchool !== null)
          //   localStorage.setItem("savedSchool", savedSchool!);
          // window.location.pathname = HOME_PATH;
          return Promise.reject(err);
        });
    },
  });
  return conf;
};
export const sharedBaseUrl = baseUrl;