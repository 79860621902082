/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PageContentDto
 */
export interface PageContentDto {
    /**
     * 
     * @type {string}
     * @memberof PageContentDto
     */
    content?: string;
    /**
     * 
     * @type {number}
     * @memberof PageContentDto
     */
    pageContentId?: number;
    /**
     * 
     * @type {string}
     * @memberof PageContentDto
     */
    pageName?: string;
}

/**
 * Check if a given object implements the PageContentDto interface.
 */
export function instanceOfPageContentDto(value: object): boolean {
    return true;
}

export function PageContentDtoFromJSON(json: any): PageContentDto {
    return PageContentDtoFromJSONTyped(json, false);
}

export function PageContentDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PageContentDto {
    if (json == null) {
        return json;
    }
    return {
        
        'content': json['content'] == null ? undefined : json['content'],
        'pageContentId': json['pageContentId'] == null ? undefined : json['pageContentId'],
        'pageName': json['pageName'] == null ? undefined : json['pageName'],
    };
}

export function PageContentDtoToJSON(value?: PageContentDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'content': value['content'],
        'pageContentId': value['pageContentId'],
        'pageName': value['pageName'],
    };
}

