/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Country } from './Country';
import {
    CountryFromJSON,
    CountryFromJSONTyped,
    CountryToJSON,
} from './Country';
import type { MemberDto } from './MemberDto';
import {
    MemberDtoFromJSON,
    MemberDtoFromJSONTyped,
    MemberDtoToJSON,
} from './MemberDto';
import type { PointRuleDto } from './PointRuleDto';
import {
    PointRuleDtoFromJSON,
    PointRuleDtoFromJSONTyped,
    PointRuleDtoToJSON,
} from './PointRuleDto';

/**
 * 
 * @export
 * @interface OrderDtoForReport
 */
export interface OrderDtoForReport {
    /**
     * 
     * @type {number}
     * @memberof OrderDtoForReport
     */
    birthdayDiscountPrice?: number;
    /**
     * 
     * @type {string}
     * @memberof OrderDtoForReport
     */
    buyerType?: string;
    /**
     * 
     * @type {number}
     * @memberof OrderDtoForReport
     */
    convertedCash?: number;
    /**
     * 
     * @type {Country}
     * @memberof OrderDtoForReport
     */
    country?: Country;
    /**
     * 
     * @type {number}
     * @memberof OrderDtoForReport
     */
    countryId?: number;
    /**
     * 
     * @type {number}
     * @memberof OrderDtoForReport
     */
    couponCashDiscountPrice?: number;
    /**
     * 
     * @type {string}
     * @memberof OrderDtoForReport
     */
    couponCode?: string;
    /**
     * 
     * @type {number}
     * @memberof OrderDtoForReport
     */
    couponCodeDiscountPrice?: number;
    /**
     * 
     * @type {string}
     * @memberof OrderDtoForReport
     */
    couponCodeType?: string;
    /**
     * 
     * @type {number}
     * @memberof OrderDtoForReport
     */
    couponDiscountPrice?: number;
    /**
     * 
     * @type {Date}
     * @memberof OrderDtoForReport
     */
    deliveryDate2nd?: Date;
    /**
     * 
     * @type {number}
     * @memberof OrderDtoForReport
     */
    earnedPoint?: number;
    /**
     * 
     * @type {MemberDto}
     * @memberof OrderDtoForReport
     */
    member?: MemberDto;
    /**
     * 
     * @type {string}
     * @memberof OrderDtoForReport
     */
    orderAddress?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderDtoForReport
     */
    orderAddress1?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderDtoForReport
     */
    orderAddress2?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderDtoForReport
     */
    orderAddress3?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderDtoForReport
     */
    orderAddress4?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderDtoForReport
     */
    orderAddressRecipient?: string;
    /**
     * 
     * @type {Date}
     * @memberof OrderDtoForReport
     */
    orderDate?: Date;
    /**
     * 
     * @type {number}
     * @memberof OrderDtoForReport
     */
    orderId?: number;
    /**
     * 
     * @type {string}
     * @memberof OrderDtoForReport
     */
    orderNo?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderDtoForReport
     */
    orderStatus?: string;
    /**
     * 
     * @type {number}
     * @memberof OrderDtoForReport
     */
    orderTotal?: number;
    /**
     * 
     * @type {string}
     * @memberof OrderDtoForReport
     */
    paymentMethod?: string;
    /**
     * 
     * @type {PointRuleDto}
     * @memberof OrderDtoForReport
     */
    pointRule?: PointRuleDto;
    /**
     * 
     * @type {number}
     * @memberof OrderDtoForReport
     */
    productTotal?: number;
    /**
     * 
     * @type {number}
     * @memberof OrderDtoForReport
     */
    shippingFee?: number;
    /**
     * 
     * @type {string}
     * @memberof OrderDtoForReport
     */
    shippingMedhod?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof OrderDtoForReport
     */
    shippingTrackingNumberList?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof OrderDtoForReport
     */
    storeWideDiscountPrice?: number;
    /**
     * 
     * @type {number}
     * @memberof OrderDtoForReport
     */
    totalPointUsedToBuy?: number;
    /**
     * 
     * @type {string}
     * @memberof OrderDtoForReport
     */
    trackingNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderDtoForReport
     */
    trackingNumber2nd?: string;
    /**
     * 
     * @type {number}
     * @memberof OrderDtoForReport
     */
    usedPoint?: number;
    /**
     * 
     * @type {number}
     * @memberof OrderDtoForReport
     */
    vipDiscountPrice?: number;
}

/**
 * Check if a given object implements the OrderDtoForReport interface.
 */
export function instanceOfOrderDtoForReport(value: object): boolean {
    return true;
}

export function OrderDtoForReportFromJSON(json: any): OrderDtoForReport {
    return OrderDtoForReportFromJSONTyped(json, false);
}

export function OrderDtoForReportFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrderDtoForReport {
    if (json == null) {
        return json;
    }
    return {
        
        'birthdayDiscountPrice': json['birthdayDiscountPrice'] == null ? undefined : json['birthdayDiscountPrice'],
        'buyerType': json['buyerType'] == null ? undefined : json['buyerType'],
        'convertedCash': json['convertedCash'] == null ? undefined : json['convertedCash'],
        'country': json['country'] == null ? undefined : CountryFromJSON(json['country']),
        'countryId': json['countryId'] == null ? undefined : json['countryId'],
        'couponCashDiscountPrice': json['couponCashDiscountPrice'] == null ? undefined : json['couponCashDiscountPrice'],
        'couponCode': json['couponCode'] == null ? undefined : json['couponCode'],
        'couponCodeDiscountPrice': json['couponCodeDiscountPrice'] == null ? undefined : json['couponCodeDiscountPrice'],
        'couponCodeType': json['couponCodeType'] == null ? undefined : json['couponCodeType'],
        'couponDiscountPrice': json['couponDiscountPrice'] == null ? undefined : json['couponDiscountPrice'],
        'deliveryDate2nd': json['deliveryDate2nd'] == null ? undefined : (new Date(json['deliveryDate2nd'])),
        'earnedPoint': json['earnedPoint'] == null ? undefined : json['earnedPoint'],
        'member': json['member'] == null ? undefined : MemberDtoFromJSON(json['member']),
        'orderAddress': json['orderAddress'] == null ? undefined : json['orderAddress'],
        'orderAddress1': json['orderAddress1'] == null ? undefined : json['orderAddress1'],
        'orderAddress2': json['orderAddress2'] == null ? undefined : json['orderAddress2'],
        'orderAddress3': json['orderAddress3'] == null ? undefined : json['orderAddress3'],
        'orderAddress4': json['orderAddress4'] == null ? undefined : json['orderAddress4'],
        'orderAddressRecipient': json['orderAddressRecipient'] == null ? undefined : json['orderAddressRecipient'],
        'orderDate': json['orderDate'] == null ? undefined : (new Date(json['orderDate'])),
        'orderId': json['orderId'] == null ? undefined : json['orderId'],
        'orderNo': json['orderNo'] == null ? undefined : json['orderNo'],
        'orderStatus': json['orderStatus'] == null ? undefined : json['orderStatus'],
        'orderTotal': json['orderTotal'] == null ? undefined : json['orderTotal'],
        'paymentMethod': json['paymentMethod'] == null ? undefined : json['paymentMethod'],
        'pointRule': json['pointRule'] == null ? undefined : PointRuleDtoFromJSON(json['pointRule']),
        'productTotal': json['productTotal'] == null ? undefined : json['productTotal'],
        'shippingFee': json['shippingFee'] == null ? undefined : json['shippingFee'],
        'shippingMedhod': json['shippingMedhod'] == null ? undefined : json['shippingMedhod'],
        'shippingTrackingNumberList': json['shippingTrackingNumberList'] == null ? undefined : json['shippingTrackingNumberList'],
        'storeWideDiscountPrice': json['storeWideDiscountPrice'] == null ? undefined : json['storeWideDiscountPrice'],
        'totalPointUsedToBuy': json['totalPointUsedToBuy'] == null ? undefined : json['totalPointUsedToBuy'],
        'trackingNumber': json['trackingNumber'] == null ? undefined : json['trackingNumber'],
        'trackingNumber2nd': json['trackingNumber2nd'] == null ? undefined : json['trackingNumber2nd'],
        'usedPoint': json['usedPoint'] == null ? undefined : json['usedPoint'],
        'vipDiscountPrice': json['vipDiscountPrice'] == null ? undefined : json['vipDiscountPrice'],
    };
}

export function OrderDtoForReportToJSON(value?: OrderDtoForReport | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'birthdayDiscountPrice': value['birthdayDiscountPrice'],
        'buyerType': value['buyerType'],
        'convertedCash': value['convertedCash'],
        'country': CountryToJSON(value['country']),
        'countryId': value['countryId'],
        'couponCashDiscountPrice': value['couponCashDiscountPrice'],
        'couponCode': value['couponCode'],
        'couponCodeDiscountPrice': value['couponCodeDiscountPrice'],
        'couponCodeType': value['couponCodeType'],
        'couponDiscountPrice': value['couponDiscountPrice'],
        'deliveryDate2nd': value['deliveryDate2nd'] == null ? undefined : ((value['deliveryDate2nd']).toISOString()),
        'earnedPoint': value['earnedPoint'],
        'member': MemberDtoToJSON(value['member']),
        'orderAddress': value['orderAddress'],
        'orderAddress1': value['orderAddress1'],
        'orderAddress2': value['orderAddress2'],
        'orderAddress3': value['orderAddress3'],
        'orderAddress4': value['orderAddress4'],
        'orderAddressRecipient': value['orderAddressRecipient'],
        'orderDate': value['orderDate'] == null ? undefined : ((value['orderDate']).toISOString()),
        'orderId': value['orderId'],
        'orderNo': value['orderNo'],
        'orderStatus': value['orderStatus'],
        'orderTotal': value['orderTotal'],
        'paymentMethod': value['paymentMethod'],
        'pointRule': PointRuleDtoToJSON(value['pointRule']),
        'productTotal': value['productTotal'],
        'shippingFee': value['shippingFee'],
        'shippingMedhod': value['shippingMedhod'],
        'shippingTrackingNumberList': value['shippingTrackingNumberList'],
        'storeWideDiscountPrice': value['storeWideDiscountPrice'],
        'totalPointUsedToBuy': value['totalPointUsedToBuy'],
        'trackingNumber': value['trackingNumber'],
        'trackingNumber2nd': value['trackingNumber2nd'],
        'usedPoint': value['usedPoint'],
        'vipDiscountPrice': value['vipDiscountPrice'],
    };
}

