/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SubOrganizationRequestModel
 */
export interface SubOrganizationRequestModel {
    /**
     * 
     * @type {string}
     * @memberof SubOrganizationRequestModel
     */
    bannerUrl?: string;
    /**
     * 
     * @type {boolean}
     * @memberof SubOrganizationRequestModel
     */
    _delete?: boolean;
    /**
     * 
     * @type {string}
     * @memberof SubOrganizationRequestModel
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof SubOrganizationRequestModel
     */
    fullName?: string;
    /**
     * 
     * @type {string}
     * @memberof SubOrganizationRequestModel
     */
    logoUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof SubOrganizationRequestModel
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof SubOrganizationRequestModel
     */
    nameEng?: string;
    /**
     * 
     * @type {string}
     * @memberof SubOrganizationRequestModel
     */
    nameZhCn?: string;
    /**
     * 
     * @type {string}
     * @memberof SubOrganizationRequestModel
     */
    nameZhHk?: string;
    /**
     * 
     * @type {number}
     * @memberof SubOrganizationRequestModel
     */
    seq?: number;
    /**
     * 
     * @type {number}
     * @memberof SubOrganizationRequestModel
     */
    serverId?: number;
    /**
     * 
     * @type {string}
     * @memberof SubOrganizationRequestModel
     */
    shortName?: string;
    /**
     * 
     * @type {string}
     * @memberof SubOrganizationRequestModel
     */
    webUrl?: string;
}

/**
 * Check if a given object implements the SubOrganizationRequestModel interface.
 */
export function instanceOfSubOrganizationRequestModel(value: object): boolean {
    return true;
}

export function SubOrganizationRequestModelFromJSON(json: any): SubOrganizationRequestModel {
    return SubOrganizationRequestModelFromJSONTyped(json, false);
}

export function SubOrganizationRequestModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): SubOrganizationRequestModel {
    if (json == null) {
        return json;
    }
    return {
        
        'bannerUrl': json['bannerUrl'] == null ? undefined : json['bannerUrl'],
        '_delete': json['delete'] == null ? undefined : json['delete'],
        'description': json['description'] == null ? undefined : json['description'],
        'fullName': json['fullName'] == null ? undefined : json['fullName'],
        'logoUrl': json['logoUrl'] == null ? undefined : json['logoUrl'],
        'name': json['name'] == null ? undefined : json['name'],
        'nameEng': json['nameEng'] == null ? undefined : json['nameEng'],
        'nameZhCn': json['nameZhCn'] == null ? undefined : json['nameZhCn'],
        'nameZhHk': json['nameZhHk'] == null ? undefined : json['nameZhHk'],
        'seq': json['seq'] == null ? undefined : json['seq'],
        'serverId': json['serverId'] == null ? undefined : json['serverId'],
        'shortName': json['shortName'] == null ? undefined : json['shortName'],
        'webUrl': json['webUrl'] == null ? undefined : json['webUrl'],
    };
}

export function SubOrganizationRequestModelToJSON(value?: SubOrganizationRequestModel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'bannerUrl': value['bannerUrl'],
        'delete': value['_delete'],
        'description': value['description'],
        'fullName': value['fullName'],
        'logoUrl': value['logoUrl'],
        'name': value['name'],
        'nameEng': value['nameEng'],
        'nameZhCn': value['nameZhCn'],
        'nameZhHk': value['nameZhHk'],
        'seq': value['seq'],
        'serverId': value['serverId'],
        'shortName': value['shortName'],
        'webUrl': value['webUrl'],
    };
}

