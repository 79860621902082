/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Category } from './Category';
import {
    CategoryFromJSON,
    CategoryFromJSONTyped,
    CategoryToJSON,
} from './Category';
import type { Product } from './Product';
import {
    ProductFromJSON,
    ProductFromJSONTyped,
    ProductToJSON,
} from './Product';

/**
 * 
 * @export
 * @interface ProductCategory
 */
export interface ProductCategory {
    /**
     * 
     * @type {Category}
     * @memberof ProductCategory
     */
    categoryByCategoryId?: Category;
    /**
     * 
     * @type {number}
     * @memberof ProductCategory
     */
    categoryId?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductCategory
     */
    order?: number;
    /**
     * 
     * @type {Product}
     * @memberof ProductCategory
     */
    productByProductId?: Product;
    /**
     * 
     * @type {number}
     * @memberof ProductCategory
     */
    productId?: number;
}

/**
 * Check if a given object implements the ProductCategory interface.
 */
export function instanceOfProductCategory(value: object): boolean {
    return true;
}

export function ProductCategoryFromJSON(json: any): ProductCategory {
    return ProductCategoryFromJSONTyped(json, false);
}

export function ProductCategoryFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProductCategory {
    if (json == null) {
        return json;
    }
    return {
        
        'categoryByCategoryId': json['categoryByCategoryId'] == null ? undefined : CategoryFromJSON(json['categoryByCategoryId']),
        'categoryId': json['categoryId'] == null ? undefined : json['categoryId'],
        'order': json['order'] == null ? undefined : json['order'],
        'productByProductId': json['productByProductId'] == null ? undefined : ProductFromJSON(json['productByProductId']),
        'productId': json['productId'] == null ? undefined : json['productId'],
    };
}

export function ProductCategoryToJSON(value?: ProductCategory | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'categoryByCategoryId': CategoryToJSON(value['categoryByCategoryId']),
        'categoryId': value['categoryId'],
        'order': value['order'],
        'productByProductId': ProductToJSON(value['productByProductId']),
        'productId': value['productId'],
    };
}

