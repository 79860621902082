/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CheckoutBannerDto
 */
export interface CheckoutBannerDto {
    /**
     * 
     * @type {string}
     * @memberof CheckoutBannerDto
     */
    content?: string;
    /**
     * 
     * @type {number}
     * @memberof CheckoutBannerDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof CheckoutBannerDto
     */
    linkTo?: string;
    /**
     * 
     * @type {number}
     * @memberof CheckoutBannerDto
     */
    position?: number;
    /**
     * 
     * @type {string}
     * @memberof CheckoutBannerDto
     */
    status?: string;
    /**
     * 
     * @type {number}
     * @memberof CheckoutBannerDto
     */
    type?: number;
}

/**
 * Check if a given object implements the CheckoutBannerDto interface.
 */
export function instanceOfCheckoutBannerDto(value: object): boolean {
    return true;
}

export function CheckoutBannerDtoFromJSON(json: any): CheckoutBannerDto {
    return CheckoutBannerDtoFromJSONTyped(json, false);
}

export function CheckoutBannerDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CheckoutBannerDto {
    if (json == null) {
        return json;
    }
    return {
        
        'content': json['content'] == null ? undefined : json['content'],
        'id': json['id'] == null ? undefined : json['id'],
        'linkTo': json['linkTo'] == null ? undefined : json['linkTo'],
        'position': json['position'] == null ? undefined : json['position'],
        'status': json['status'] == null ? undefined : json['status'],
        'type': json['type'] == null ? undefined : json['type'],
    };
}

export function CheckoutBannerDtoToJSON(value?: CheckoutBannerDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'content': value['content'],
        'id': value['id'],
        'linkTo': value['linkTo'],
        'position': value['position'],
        'status': value['status'],
        'type': value['type'],
    };
}

