/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SignUpMessageDto
 */
export interface SignUpMessageDto {
    /**
     * 
     * @type {number}
     * @memberof SignUpMessageDto
     */
    status?: number;
}

/**
 * Check if a given object implements the SignUpMessageDto interface.
 */
export function instanceOfSignUpMessageDto(value: object): boolean {
    return true;
}

export function SignUpMessageDtoFromJSON(json: any): SignUpMessageDto {
    return SignUpMessageDtoFromJSONTyped(json, false);
}

export function SignUpMessageDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): SignUpMessageDto {
    if (json == null) {
        return json;
    }
    return {
        
        'status': json['status'] == null ? undefined : json['status'],
    };
}

export function SignUpMessageDtoToJSON(value?: SignUpMessageDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'status': value['status'],
    };
}

