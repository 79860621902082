/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  MarketingEventBannerDto,
} from '../models/index';
import {
    MarketingEventBannerDtoFromJSON,
    MarketingEventBannerDtoToJSON,
} from '../models/index';

export interface AddUsingPOSTRequest {
    content: string;
    mobileContent: string;
    position: number;
    name?: string;
    marketingEventId?: number;
}

export interface DeleteByIdUsingPOSTRequest {
    bannerId: number;
    name?: string;
}

export interface GetByIdUsingGET1Request {
    bannerId: number;
}

export interface UpdateUsingPOSTRequest {
    bannerId: number;
    content: string;
    mobileContent: string;
    position: number;
    name?: string;
    marketingEventId?: number;
}

/**
 * 
 */
export class MarketingEventBannerControllerApi extends runtime.BaseAPI {

    /**
     * add
     */
    async addUsingPOSTRaw(requestParameters: AddUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MarketingEventBannerDto>> {
        if (requestParameters['content'] == null) {
            throw new runtime.RequiredError(
                'content',
                'Required parameter "content" was null or undefined when calling addUsingPOST().'
            );
        }

        if (requestParameters['mobileContent'] == null) {
            throw new runtime.RequiredError(
                'mobileContent',
                'Required parameter "mobileContent" was null or undefined when calling addUsingPOST().'
            );
        }

        if (requestParameters['position'] == null) {
            throw new runtime.RequiredError(
                'position',
                'Required parameter "position" was null or undefined when calling addUsingPOST().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        if (requestParameters['marketingEventId'] != null) {
            queryParameters['marketingEventId'] = requestParameters['marketingEventId'];
        }

        if (requestParameters['content'] != null) {
            queryParameters['content'] = requestParameters['content'];
        }

        if (requestParameters['mobileContent'] != null) {
            queryParameters['mobileContent'] = requestParameters['mobileContent'];
        }

        if (requestParameters['position'] != null) {
            queryParameters['position'] = requestParameters['position'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/banner/add`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MarketingEventBannerDtoFromJSON(jsonValue));
    }

    /**
     * add
     */
    async addUsingPOST(requestParameters: AddUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MarketingEventBannerDto | null | undefined > {
        const response = await this.addUsingPOSTRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 201:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * deleteById
     */
    async deleteByIdUsingPOSTRaw(requestParameters: DeleteByIdUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['bannerId'] == null) {
            throw new runtime.RequiredError(
                'bannerId',
                'Required parameter "bannerId" was null or undefined when calling deleteByIdUsingPOST().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/banner/delete/{bannerId}`.replace(`{${"bannerId"}}`, encodeURIComponent(String(requestParameters['bannerId']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * deleteById
     */
    async deleteByIdUsingPOST(requestParameters: DeleteByIdUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteByIdUsingPOSTRaw(requestParameters, initOverrides);
    }

    /**
     * getAll
     */
    async getAllUsingGET2Raw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<MarketingEventBannerDto>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/banner/all`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(MarketingEventBannerDtoFromJSON));
    }

    /**
     * getAll
     */
    async getAllUsingGET2(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<MarketingEventBannerDto>> {
        const response = await this.getAllUsingGET2Raw(initOverrides);
        return await response.value();
    }

    /**
     * getById
     */
    async getByIdUsingGET1Raw(requestParameters: GetByIdUsingGET1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MarketingEventBannerDto>> {
        if (requestParameters['bannerId'] == null) {
            throw new runtime.RequiredError(
                'bannerId',
                'Required parameter "bannerId" was null or undefined when calling getByIdUsingGET1().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/banner/{bannerId}`.replace(`{${"bannerId"}}`, encodeURIComponent(String(requestParameters['bannerId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MarketingEventBannerDtoFromJSON(jsonValue));
    }

    /**
     * getById
     */
    async getByIdUsingGET1(requestParameters: GetByIdUsingGET1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MarketingEventBannerDto> {
        const response = await this.getByIdUsingGET1Raw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * update
     */
    async updateUsingPOSTRaw(requestParameters: UpdateUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MarketingEventBannerDto>> {
        if (requestParameters['bannerId'] == null) {
            throw new runtime.RequiredError(
                'bannerId',
                'Required parameter "bannerId" was null or undefined when calling updateUsingPOST().'
            );
        }

        if (requestParameters['content'] == null) {
            throw new runtime.RequiredError(
                'content',
                'Required parameter "content" was null or undefined when calling updateUsingPOST().'
            );
        }

        if (requestParameters['mobileContent'] == null) {
            throw new runtime.RequiredError(
                'mobileContent',
                'Required parameter "mobileContent" was null or undefined when calling updateUsingPOST().'
            );
        }

        if (requestParameters['position'] == null) {
            throw new runtime.RequiredError(
                'position',
                'Required parameter "position" was null or undefined when calling updateUsingPOST().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        if (requestParameters['bannerId'] != null) {
            queryParameters['bannerId'] = requestParameters['bannerId'];
        }

        if (requestParameters['marketingEventId'] != null) {
            queryParameters['marketingEventId'] = requestParameters['marketingEventId'];
        }

        if (requestParameters['content'] != null) {
            queryParameters['content'] = requestParameters['content'];
        }

        if (requestParameters['mobileContent'] != null) {
            queryParameters['mobileContent'] = requestParameters['mobileContent'];
        }

        if (requestParameters['position'] != null) {
            queryParameters['position'] = requestParameters['position'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/banner/update`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MarketingEventBannerDtoFromJSON(jsonValue));
    }

    /**
     * update
     */
    async updateUsingPOST(requestParameters: UpdateUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MarketingEventBannerDto | null | undefined > {
        const response = await this.updateUsingPOSTRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 201:
                return null;
            default:
                return await response.value();
        }
    }

}
