/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { MemberLevel } from './MemberLevel';
import {
    MemberLevelFromJSON,
    MemberLevelFromJSONTyped,
    MemberLevelToJSON,
} from './MemberLevel';
import type { Product } from './Product';
import {
    ProductFromJSON,
    ProductFromJSONTyped,
    ProductToJSON,
} from './Product';

/**
 * 
 * @export
 * @interface PointRule
 */
export interface PointRule {
    /**
     * 
     * @type {number}
     * @memberof PointRule
     */
    appliedMemberLevel?: number;
    /**
     * 
     * @type {number}
     * @memberof PointRule
     */
    appliedProduct?: number;
    /**
     * 
     * @type {number}
     * @memberof PointRule
     */
    cashForOnePoint?: number;
    /**
     * 
     * @type {Date}
     * @memberof PointRule
     */
    endDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof PointRule
     */
    forBirthday?: string;
    /**
     * 
     * @type {MemberLevel}
     * @memberof PointRule
     */
    memberLevelByAppliedMemberLevel?: MemberLevel;
    /**
     * 
     * @type {string}
     * @memberof PointRule
     */
    name?: string;
    /**
     * 
     * @type {number}
     * @memberof PointRule
     */
    oneCashToPoint?: number;
    /**
     * 
     * @type {string}
     * @memberof PointRule
     */
    period?: string;
    /**
     * 
     * @type {number}
     * @memberof PointRule
     */
    pointRuleId?: number;
    /**
     * 
     * @type {number}
     * @memberof PointRule
     */
    pointToOneCash?: number;
    /**
     * 
     * @type {Product}
     * @memberof PointRule
     */
    productByAppliedProduct?: Product;
    /**
     * 
     * @type {Date}
     * @memberof PointRule
     */
    startDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof PointRule
     */
    status?: string;
}

/**
 * Check if a given object implements the PointRule interface.
 */
export function instanceOfPointRule(value: object): boolean {
    return true;
}

export function PointRuleFromJSON(json: any): PointRule {
    return PointRuleFromJSONTyped(json, false);
}

export function PointRuleFromJSONTyped(json: any, ignoreDiscriminator: boolean): PointRule {
    if (json == null) {
        return json;
    }
    return {
        
        'appliedMemberLevel': json['appliedMemberLevel'] == null ? undefined : json['appliedMemberLevel'],
        'appliedProduct': json['appliedProduct'] == null ? undefined : json['appliedProduct'],
        'cashForOnePoint': json['cashForOnePoint'] == null ? undefined : json['cashForOnePoint'],
        'endDate': json['endDate'] == null ? undefined : (new Date(json['endDate'])),
        'forBirthday': json['forBirthday'] == null ? undefined : json['forBirthday'],
        'memberLevelByAppliedMemberLevel': json['memberLevelByAppliedMemberLevel'] == null ? undefined : MemberLevelFromJSON(json['memberLevelByAppliedMemberLevel']),
        'name': json['name'] == null ? undefined : json['name'],
        'oneCashToPoint': json['oneCashToPoint'] == null ? undefined : json['oneCashToPoint'],
        'period': json['period'] == null ? undefined : json['period'],
        'pointRuleId': json['pointRuleId'] == null ? undefined : json['pointRuleId'],
        'pointToOneCash': json['pointToOneCash'] == null ? undefined : json['pointToOneCash'],
        'productByAppliedProduct': json['productByAppliedProduct'] == null ? undefined : ProductFromJSON(json['productByAppliedProduct']),
        'startDate': json['startDate'] == null ? undefined : (new Date(json['startDate'])),
        'status': json['status'] == null ? undefined : json['status'],
    };
}

export function PointRuleToJSON(value?: PointRule | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'appliedMemberLevel': value['appliedMemberLevel'],
        'appliedProduct': value['appliedProduct'],
        'cashForOnePoint': value['cashForOnePoint'],
        'endDate': value['endDate'] == null ? undefined : ((value['endDate']).toISOString()),
        'forBirthday': value['forBirthday'],
        'memberLevelByAppliedMemberLevel': MemberLevelToJSON(value['memberLevelByAppliedMemberLevel']),
        'name': value['name'],
        'oneCashToPoint': value['oneCashToPoint'],
        'period': value['period'],
        'pointRuleId': value['pointRuleId'],
        'pointToOneCash': value['pointToOneCash'],
        'productByAppliedProduct': ProductToJSON(value['productByAppliedProduct']),
        'startDate': value['startDate'] == null ? undefined : ((value['startDate']).toISOString()),
        'status': value['status'],
    };
}

