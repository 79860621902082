/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  SystemSetting,
} from '../models/index';
import {
    SystemSettingFromJSON,
    SystemSettingToJSON,
} from '../models/index';

export interface AddOrUpdateUsingPOSTRequest {
    systemSetting?: SystemSetting;
}

export interface AddOrUpdatesUsingPOSTRequest {
    systemSetting?: Array<SystemSetting>;
}

export interface GetUsingGETRequest {
    name: string;
}

/**
 * 
 */
export class SystemSettingControllerApi extends runtime.BaseAPI {

    /**
     * addOrUpdate
     */
    async addOrUpdateUsingPOSTRaw(requestParameters: AddOrUpdateUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SystemSetting>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/system-setting/addOrUpdate`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SystemSettingToJSON(requestParameters['systemSetting']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SystemSettingFromJSON(jsonValue));
    }

    /**
     * addOrUpdate
     */
    async addOrUpdateUsingPOST(requestParameters: AddOrUpdateUsingPOSTRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SystemSetting | null | undefined > {
        const response = await this.addOrUpdateUsingPOSTRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 201:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * addOrUpdates
     */
    async addOrUpdatesUsingPOSTRaw(requestParameters: AddOrUpdatesUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<SystemSetting>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/system-setting/addOrUpdates`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters['systemSetting']!.map(SystemSettingToJSON),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SystemSettingFromJSON));
    }

    /**
     * addOrUpdates
     */
    async addOrUpdatesUsingPOST(requestParameters: AddOrUpdatesUsingPOSTRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<SystemSetting> | null | undefined > {
        const response = await this.addOrUpdatesUsingPOSTRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 201:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * get
     */
    async getUsingGETRaw(requestParameters: GetUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SystemSetting>> {
        if (requestParameters['name'] == null) {
            throw new runtime.RequiredError(
                'name',
                'Required parameter "name" was null or undefined when calling getUsingGET().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/system-setting/get/{name}`.replace(`{${"name"}}`, encodeURIComponent(String(requestParameters['name']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SystemSettingFromJSON(jsonValue));
    }

    /**
     * get
     */
    async getUsingGET(requestParameters: GetUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SystemSetting> {
        const response = await this.getUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
