/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Country
 */
export interface Country {
    /**
     * 
     * @type {string}
     * @memberof Country
     */
    callingCode?: string;
    /**
     * 
     * @type {string}
     * @memberof Country
     */
    countryName?: string;
    /**
     * 
     * @type {number}
     * @memberof Country
     */
    locationId?: number;
    /**
     * 
     * @type {number}
     * @memberof Country
     */
    numberOfDigit?: number;
}

/**
 * Check if a given object implements the Country interface.
 */
export function instanceOfCountry(value: object): boolean {
    return true;
}

export function CountryFromJSON(json: any): Country {
    return CountryFromJSONTyped(json, false);
}

export function CountryFromJSONTyped(json: any, ignoreDiscriminator: boolean): Country {
    if (json == null) {
        return json;
    }
    return {
        
        'callingCode': json['callingCode'] == null ? undefined : json['callingCode'],
        'countryName': json['countryName'] == null ? undefined : json['countryName'],
        'locationId': json['locationId'] == null ? undefined : json['locationId'],
        'numberOfDigit': json['numberOfDigit'] == null ? undefined : json['numberOfDigit'],
    };
}

export function CountryToJSON(value?: Country | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'callingCode': value['callingCode'],
        'countryName': value['countryName'],
        'locationId': value['locationId'],
        'numberOfDigit': value['numberOfDigit'],
    };
}

