/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  MemberDto,
} from '../models/index';
import {
    MemberDtoFromJSON,
    MemberDtoToJSON,
} from '../models/index';

export interface AddNewMemberUsingPOSTRequest {
    firstName: string;
    lastName: string;
    phone: string;
    email: string;
    password: string;
    username: string;
    role: string;
}

export interface GetVerificationCodeUsingPOSTRequest {
    locationId: number;
    phone: string;
}

/**
 * 
 */
export class LoginControllerApi extends runtime.BaseAPI {

    /**
     * addNewMember
     */
    async addNewMemberUsingPOSTRaw(requestParameters: AddNewMemberUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters['firstName'] == null) {
            throw new runtime.RequiredError(
                'firstName',
                'Required parameter "firstName" was null or undefined when calling addNewMemberUsingPOST().'
            );
        }

        if (requestParameters['lastName'] == null) {
            throw new runtime.RequiredError(
                'lastName',
                'Required parameter "lastName" was null or undefined when calling addNewMemberUsingPOST().'
            );
        }

        if (requestParameters['phone'] == null) {
            throw new runtime.RequiredError(
                'phone',
                'Required parameter "phone" was null or undefined when calling addNewMemberUsingPOST().'
            );
        }

        if (requestParameters['email'] == null) {
            throw new runtime.RequiredError(
                'email',
                'Required parameter "email" was null or undefined when calling addNewMemberUsingPOST().'
            );
        }

        if (requestParameters['password'] == null) {
            throw new runtime.RequiredError(
                'password',
                'Required parameter "password" was null or undefined when calling addNewMemberUsingPOST().'
            );
        }

        if (requestParameters['username'] == null) {
            throw new runtime.RequiredError(
                'username',
                'Required parameter "username" was null or undefined when calling addNewMemberUsingPOST().'
            );
        }

        if (requestParameters['role'] == null) {
            throw new runtime.RequiredError(
                'role',
                'Required parameter "role" was null or undefined when calling addNewMemberUsingPOST().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['firstName'] != null) {
            queryParameters['first_name'] = requestParameters['firstName'];
        }

        if (requestParameters['lastName'] != null) {
            queryParameters['last_name'] = requestParameters['lastName'];
        }

        if (requestParameters['phone'] != null) {
            queryParameters['phone'] = requestParameters['phone'];
        }

        if (requestParameters['email'] != null) {
            queryParameters['email'] = requestParameters['email'];
        }

        if (requestParameters['password'] != null) {
            queryParameters['password'] = requestParameters['password'];
        }

        if (requestParameters['username'] != null) {
            queryParameters['username'] = requestParameters['username'];
        }

        if (requestParameters['role'] != null) {
            queryParameters['role'] = requestParameters['role'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/login/add`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * addNewMember
     */
    async addNewMemberUsingPOST(requestParameters: AddNewMemberUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string | null | undefined > {
        const response = await this.addNewMemberUsingPOSTRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 201:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * getAllMember
     */
    async getAllMemberUsingGETRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<MemberDto>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/login/all`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(MemberDtoFromJSON));
    }

    /**
     * getAllMember
     */
    async getAllMemberUsingGET(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<MemberDto>> {
        const response = await this.getAllMemberUsingGETRaw(initOverrides);
        return await response.value();
    }

    /**
     * getVerificationCode
     */
    async getVerificationCodeUsingPOSTRaw(requestParameters: GetVerificationCodeUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters['locationId'] == null) {
            throw new runtime.RequiredError(
                'locationId',
                'Required parameter "locationId" was null or undefined when calling getVerificationCodeUsingPOST().'
            );
        }

        if (requestParameters['phone'] == null) {
            throw new runtime.RequiredError(
                'phone',
                'Required parameter "phone" was null or undefined when calling getVerificationCodeUsingPOST().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['locationId'] != null) {
            queryParameters['locationId'] = requestParameters['locationId'];
        }

        if (requestParameters['phone'] != null) {
            queryParameters['phone'] = requestParameters['phone'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/login/phone`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * getVerificationCode
     */
    async getVerificationCodeUsingPOST(requestParameters: GetVerificationCodeUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string | null | undefined > {
        const response = await this.getVerificationCodeUsingPOSTRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 201:
                return null;
            default:
                return await response.value();
        }
    }

}
