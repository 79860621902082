/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Article } from './Article';
import {
    ArticleFromJSON,
    ArticleFromJSONTyped,
    ArticleToJSON,
} from './Article';
import type { MarketingEvent } from './MarketingEvent';
import {
    MarketingEventFromJSON,
    MarketingEventFromJSONTyped,
    MarketingEventToJSON,
} from './MarketingEvent';

/**
 * 
 * @export
 * @interface MarketingEventArticle
 */
export interface MarketingEventArticle {
    /**
     * 
     * @type {Article}
     * @memberof MarketingEventArticle
     */
    articleByArticleId?: Article;
    /**
     * 
     * @type {number}
     * @memberof MarketingEventArticle
     */
    articleId?: number;
    /**
     * 
     * @type {string}
     * @memberof MarketingEventArticle
     */
    content?: string;
    /**
     * 
     * @type {MarketingEvent}
     * @memberof MarketingEventArticle
     */
    marketingEventByMarketingEventId?: MarketingEvent;
    /**
     * 
     * @type {number}
     * @memberof MarketingEventArticle
     */
    marketingEventId?: number;
}

/**
 * Check if a given object implements the MarketingEventArticle interface.
 */
export function instanceOfMarketingEventArticle(value: object): boolean {
    return true;
}

export function MarketingEventArticleFromJSON(json: any): MarketingEventArticle {
    return MarketingEventArticleFromJSONTyped(json, false);
}

export function MarketingEventArticleFromJSONTyped(json: any, ignoreDiscriminator: boolean): MarketingEventArticle {
    if (json == null) {
        return json;
    }
    return {
        
        'articleByArticleId': json['articleByArticleId'] == null ? undefined : ArticleFromJSON(json['articleByArticleId']),
        'articleId': json['articleId'] == null ? undefined : json['articleId'],
        'content': json['content'] == null ? undefined : json['content'],
        'marketingEventByMarketingEventId': json['marketingEventByMarketingEventId'] == null ? undefined : MarketingEventFromJSON(json['marketingEventByMarketingEventId']),
        'marketingEventId': json['marketingEventId'] == null ? undefined : json['marketingEventId'],
    };
}

export function MarketingEventArticleToJSON(value?: MarketingEventArticle | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'articleByArticleId': ArticleToJSON(value['articleByArticleId']),
        'articleId': value['articleId'],
        'content': value['content'],
        'marketingEventByMarketingEventId': MarketingEventToJSON(value['marketingEventByMarketingEventId']),
        'marketingEventId': value['marketingEventId'],
    };
}

