/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { CouponDto } from './CouponDto';
import {
    CouponDtoFromJSON,
    CouponDtoFromJSONTyped,
    CouponDtoToJSON,
} from './CouponDto';
import type { DisplayProductDto } from './DisplayProductDto';
import {
    DisplayProductDtoFromJSON,
    DisplayProductDtoFromJSONTyped,
    DisplayProductDtoToJSON,
} from './DisplayProductDto';
import type { MarketingEventDto } from './MarketingEventDto';
import {
    MarketingEventDtoFromJSON,
    MarketingEventDtoFromJSONTyped,
    MarketingEventDtoToJSON,
} from './MarketingEventDto';
import type { MemberLevelDto } from './MemberLevelDto';
import {
    MemberLevelDtoFromJSON,
    MemberLevelDtoFromJSONTyped,
    MemberLevelDtoToJSON,
} from './MemberLevelDto';
import type { ProductDto } from './ProductDto';
import {
    ProductDtoFromJSON,
    ProductDtoFromJSONTyped,
    ProductDtoToJSON,
} from './ProductDto';

/**
 * 
 * @export
 * @interface DiscountDto
 */
export interface DiscountDto {
    /**
     * 
     * @type {string}
     * @memberof DiscountDto
     */
    actionType?: string;
    /**
     * 
     * @type {number}
     * @memberof DiscountDto
     */
    actionValue?: number;
    /**
     * 
     * @type {string}
     * @memberof DiscountDto
     */
    condition?: string;
    /**
     * 
     * @type {CouponDto}
     * @memberof DiscountDto
     */
    coupon?: CouponDto;
    /**
     * 
     * @type {Date}
     * @memberof DiscountDto
     */
    createAt?: Date;
    /**
     * 
     * @type {number}
     * @memberof DiscountDto
     */
    discountId?: number;
    /**
     * 
     * @type {string}
     * @memberof DiscountDto
     */
    discountTitle?: string;
    /**
     * 
     * @type {string}
     * @memberof DiscountDto
     */
    discountType?: string;
    /**
     * 
     * @type {Array<DisplayProductDto>}
     * @memberof DiscountDto
     */
    displayProducts?: Array<DisplayProductDto>;
    /**
     * 
     * @type {number}
     * @memberof DiscountDto
     */
    limitToUse?: number;
    /**
     * 
     * @type {number}
     * @memberof DiscountDto
     */
    limitToUseRest?: number;
    /**
     * 
     * @type {Array<MarketingEventDto>}
     * @memberof DiscountDto
     */
    marketingEvents?: Array<MarketingEventDto>;
    /**
     * 
     * @type {Array<MemberLevelDto>}
     * @memberof DiscountDto
     */
    memberLevels?: Array<MemberLevelDto>;
    /**
     * 
     * @type {Array<ProductDto>}
     * @memberof DiscountDto
     */
    products?: Array<ProductDto>;
    /**
     * 
     * @type {string}
     * @memberof DiscountDto
     */
    status?: string;
    /**
     * 
     * @type {string}
     * @memberof DiscountDto
     */
    target?: string;
}

/**
 * Check if a given object implements the DiscountDto interface.
 */
export function instanceOfDiscountDto(value: object): boolean {
    return true;
}

export function DiscountDtoFromJSON(json: any): DiscountDto {
    return DiscountDtoFromJSONTyped(json, false);
}

export function DiscountDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): DiscountDto {
    if (json == null) {
        return json;
    }
    return {
        
        'actionType': json['actionType'] == null ? undefined : json['actionType'],
        'actionValue': json['actionValue'] == null ? undefined : json['actionValue'],
        'condition': json['condition'] == null ? undefined : json['condition'],
        'coupon': json['coupon'] == null ? undefined : CouponDtoFromJSON(json['coupon']),
        'createAt': json['createAt'] == null ? undefined : (new Date(json['createAt'])),
        'discountId': json['discountId'] == null ? undefined : json['discountId'],
        'discountTitle': json['discountTitle'] == null ? undefined : json['discountTitle'],
        'discountType': json['discountType'] == null ? undefined : json['discountType'],
        'displayProducts': json['displayProducts'] == null ? undefined : ((json['displayProducts'] as Array<any>).map(DisplayProductDtoFromJSON)),
        'limitToUse': json['limitToUse'] == null ? undefined : json['limitToUse'],
        'limitToUseRest': json['limitToUseRest'] == null ? undefined : json['limitToUseRest'],
        'marketingEvents': json['marketingEvents'] == null ? undefined : ((json['marketingEvents'] as Array<any>).map(MarketingEventDtoFromJSON)),
        'memberLevels': json['memberLevels'] == null ? undefined : ((json['memberLevels'] as Array<any>).map(MemberLevelDtoFromJSON)),
        'products': json['products'] == null ? undefined : ((json['products'] as Array<any>).map(ProductDtoFromJSON)),
        'status': json['status'] == null ? undefined : json['status'],
        'target': json['target'] == null ? undefined : json['target'],
    };
}

export function DiscountDtoToJSON(value?: DiscountDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'actionType': value['actionType'],
        'actionValue': value['actionValue'],
        'condition': value['condition'],
        'coupon': CouponDtoToJSON(value['coupon']),
        'createAt': value['createAt'] == null ? undefined : ((value['createAt']).toISOString()),
        'discountId': value['discountId'],
        'discountTitle': value['discountTitle'],
        'discountType': value['discountType'],
        'displayProducts': value['displayProducts'] == null ? undefined : ((value['displayProducts'] as Array<any>).map(DisplayProductDtoToJSON)),
        'limitToUse': value['limitToUse'],
        'limitToUseRest': value['limitToUseRest'],
        'marketingEvents': value['marketingEvents'] == null ? undefined : ((value['marketingEvents'] as Array<any>).map(MarketingEventDtoToJSON)),
        'memberLevels': value['memberLevels'] == null ? undefined : ((value['memberLevels'] as Array<any>).map(MemberLevelDtoToJSON)),
        'products': value['products'] == null ? undefined : ((value['products'] as Array<any>).map(ProductDtoToJSON)),
        'status': value['status'],
        'target': value['target'],
    };
}

