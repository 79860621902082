/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { AgeGroup } from './AgeGroup';
import {
    AgeGroupFromJSON,
    AgeGroupFromJSONTyped,
    AgeGroupToJSON,
} from './AgeGroup';
import type { MemberLevelDtoLite } from './MemberLevelDtoLite';
import {
    MemberLevelDtoLiteFromJSON,
    MemberLevelDtoLiteFromJSONTyped,
    MemberLevelDtoLiteToJSON,
} from './MemberLevelDtoLite';

/**
 * 
 * @export
 * @interface MemberDto
 */
export interface MemberDto {
    /**
     * 
     * @type {string}
     * @memberof MemberDto
     */
    acceptPromoption?: string;
    /**
     * 
     * @type {AgeGroup}
     * @memberof MemberDto
     */
    ageGroup?: AgeGroup;
    /**
     * 
     * @type {number}
     * @memberof MemberDto
     */
    ageGroupId?: number;
    /**
     * 
     * @type {Date}
     * @memberof MemberDto
     */
    birthday?: Date;
    /**
     * 
     * @type {number}
     * @memberof MemberDto
     */
    birthdayMonth?: number;
    /**
     * 
     * @type {string}
     * @memberof MemberDto
     */
    district?: string;
    /**
     * 
     * @type {string}
     * @memberof MemberDto
     */
    email?: string;
    /**
     * 
     * @type {number}
     * @memberof MemberDto
     */
    expiredPoint?: number;
    /**
     * 
     * @type {string}
     * @memberof MemberDto
     */
    firstname?: string;
    /**
     * 
     * @type {string}
     * @memberof MemberDto
     */
    gender?: string;
    /**
     * 
     * @type {string}
     * @memberof MemberDto
     */
    googleId?: string;
    /**
     * 
     * @type {Date}
     * @memberof MemberDto
     */
    joinDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof MemberDto
     */
    lastname?: string;
    /**
     * 
     * @type {number}
     * @memberof MemberDto
     */
    locationId?: number;
    /**
     * 
     * @type {number}
     * @memberof MemberDto
     */
    memberId?: number;
    /**
     * 
     * @type {MemberLevelDtoLite}
     * @memberof MemberDto
     */
    memberLevel?: MemberLevelDtoLite;
    /**
     * 
     * @type {number}
     * @memberof MemberDto
     */
    memberLevelId?: number;
    /**
     * 
     * @type {Date}
     * @memberof MemberDto
     */
    membershipExpireDate?: Date;
    /**
     * 
     * @type {number}
     * @memberof MemberDto
     */
    orderNum?: number;
    /**
     * 
     * @type {string}
     * @memberof MemberDto
     */
    phone?: string;
    /**
     * 
     * @type {number}
     * @memberof MemberDto
     */
    totalPoint?: number;
    /**
     * 
     * @type {number}
     * @memberof MemberDto
     */
    usedPoint?: number;
    /**
     * 
     * @type {string}
     * @memberof MemberDto
     */
    username?: string;
    /**
     * 
     * @type {number}
     * @memberof MemberDto
     */
    validPoint?: number;
    /**
     * 
     * @type {Date}
     * @memberof MemberDto
     */
    vipJoinDate?: Date;
}

/**
 * Check if a given object implements the MemberDto interface.
 */
export function instanceOfMemberDto(value: object): boolean {
    return true;
}

export function MemberDtoFromJSON(json: any): MemberDto {
    return MemberDtoFromJSONTyped(json, false);
}

export function MemberDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): MemberDto {
    if (json == null) {
        return json;
    }
    return {
        
        'acceptPromoption': json['acceptPromoption'] == null ? undefined : json['acceptPromoption'],
        'ageGroup': json['ageGroup'] == null ? undefined : AgeGroupFromJSON(json['ageGroup']),
        'ageGroupId': json['ageGroupId'] == null ? undefined : json['ageGroupId'],
        'birthday': json['birthday'] == null ? undefined : (new Date(json['birthday'])),
        'birthdayMonth': json['birthdayMonth'] == null ? undefined : json['birthdayMonth'],
        'district': json['district'] == null ? undefined : json['district'],
        'email': json['email'] == null ? undefined : json['email'],
        'expiredPoint': json['expiredPoint'] == null ? undefined : json['expiredPoint'],
        'firstname': json['firstname'] == null ? undefined : json['firstname'],
        'gender': json['gender'] == null ? undefined : json['gender'],
        'googleId': json['googleId'] == null ? undefined : json['googleId'],
        'joinDate': json['joinDate'] == null ? undefined : (new Date(json['joinDate'])),
        'lastname': json['lastname'] == null ? undefined : json['lastname'],
        'locationId': json['locationId'] == null ? undefined : json['locationId'],
        'memberId': json['memberId'] == null ? undefined : json['memberId'],
        'memberLevel': json['memberLevel'] == null ? undefined : MemberLevelDtoLiteFromJSON(json['memberLevel']),
        'memberLevelId': json['memberLevelId'] == null ? undefined : json['memberLevelId'],
        'membershipExpireDate': json['membershipExpireDate'] == null ? undefined : (new Date(json['membershipExpireDate'])),
        'orderNum': json['orderNum'] == null ? undefined : json['orderNum'],
        'phone': json['phone'] == null ? undefined : json['phone'],
        'totalPoint': json['totalPoint'] == null ? undefined : json['totalPoint'],
        'usedPoint': json['usedPoint'] == null ? undefined : json['usedPoint'],
        'username': json['username'] == null ? undefined : json['username'],
        'validPoint': json['validPoint'] == null ? undefined : json['validPoint'],
        'vipJoinDate': json['vipJoinDate'] == null ? undefined : (new Date(json['vipJoinDate'])),
    };
}

export function MemberDtoToJSON(value?: MemberDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'acceptPromoption': value['acceptPromoption'],
        'ageGroup': AgeGroupToJSON(value['ageGroup']),
        'ageGroupId': value['ageGroupId'],
        'birthday': value['birthday'] == null ? undefined : ((value['birthday']).toISOString()),
        'birthdayMonth': value['birthdayMonth'],
        'district': value['district'],
        'email': value['email'],
        'expiredPoint': value['expiredPoint'],
        'firstname': value['firstname'],
        'gender': value['gender'],
        'googleId': value['googleId'],
        'joinDate': value['joinDate'] == null ? undefined : ((value['joinDate']).toISOString()),
        'lastname': value['lastname'],
        'locationId': value['locationId'],
        'memberId': value['memberId'],
        'memberLevel': MemberLevelDtoLiteToJSON(value['memberLevel']),
        'memberLevelId': value['memberLevelId'],
        'membershipExpireDate': value['membershipExpireDate'] == null ? undefined : ((value['membershipExpireDate']).toISOString()),
        'orderNum': value['orderNum'],
        'phone': value['phone'],
        'totalPoint': value['totalPoint'],
        'usedPoint': value['usedPoint'],
        'username': value['username'],
        'validPoint': value['validPoint'],
        'vipJoinDate': value['vipJoinDate'] == null ? undefined : ((value['vipJoinDate']).toISOString()),
    };
}

