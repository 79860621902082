/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { CartProduct } from './CartProduct';
import {
    CartProductFromJSON,
    CartProductFromJSONTyped,
    CartProductToJSON,
} from './CartProduct';
import type { Discount } from './Discount';
import {
    DiscountFromJSON,
    DiscountFromJSONTyped,
    DiscountToJSON,
} from './Discount';

/**
 * 
 * @export
 * @interface CartProductDiscount
 */
export interface CartProductDiscount {
    /**
     * 
     * @type {CartProduct}
     * @memberof CartProductDiscount
     */
    cartProductByCartProductId?: CartProduct;
    /**
     * 
     * @type {number}
     * @memberof CartProductDiscount
     */
    cartProductId?: number;
    /**
     * 
     * @type {Discount}
     * @memberof CartProductDiscount
     */
    discountByDiscountId?: Discount;
    /**
     * 
     * @type {number}
     * @memberof CartProductDiscount
     */
    discountId?: number;
}

/**
 * Check if a given object implements the CartProductDiscount interface.
 */
export function instanceOfCartProductDiscount(value: object): boolean {
    return true;
}

export function CartProductDiscountFromJSON(json: any): CartProductDiscount {
    return CartProductDiscountFromJSONTyped(json, false);
}

export function CartProductDiscountFromJSONTyped(json: any, ignoreDiscriminator: boolean): CartProductDiscount {
    if (json == null) {
        return json;
    }
    return {
        
        'cartProductByCartProductId': json['cartProductByCartProductId'] == null ? undefined : CartProductFromJSON(json['cartProductByCartProductId']),
        'cartProductId': json['cartProductId'] == null ? undefined : json['cartProductId'],
        'discountByDiscountId': json['discountByDiscountId'] == null ? undefined : DiscountFromJSON(json['discountByDiscountId']),
        'discountId': json['discountId'] == null ? undefined : json['discountId'],
    };
}

export function CartProductDiscountToJSON(value?: CartProductDiscount | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'cartProductByCartProductId': CartProductToJSON(value['cartProductByCartProductId']),
        'cartProductId': value['cartProductId'],
        'discountByDiscountId': DiscountToJSON(value['discountByDiscountId']),
        'discountId': value['discountId'],
    };
}

