/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Article } from './Article';
import {
    ArticleFromJSON,
    ArticleFromJSONTyped,
    ArticleToJSON,
} from './Article';
import type { Hashtag } from './Hashtag';
import {
    HashtagFromJSON,
    HashtagFromJSONTyped,
    HashtagToJSON,
} from './Hashtag';

/**
 * 
 * @export
 * @interface ArticleHashtag
 */
export interface ArticleHashtag {
    /**
     * 
     * @type {Article}
     * @memberof ArticleHashtag
     */
    articleByArticleId?: Article;
    /**
     * 
     * @type {number}
     * @memberof ArticleHashtag
     */
    articleId?: number;
    /**
     * 
     * @type {Hashtag}
     * @memberof ArticleHashtag
     */
    hashtagByHashtagId?: Hashtag;
    /**
     * 
     * @type {number}
     * @memberof ArticleHashtag
     */
    hashtagId?: number;
}

/**
 * Check if a given object implements the ArticleHashtag interface.
 */
export function instanceOfArticleHashtag(value: object): boolean {
    return true;
}

export function ArticleHashtagFromJSON(json: any): ArticleHashtag {
    return ArticleHashtagFromJSONTyped(json, false);
}

export function ArticleHashtagFromJSONTyped(json: any, ignoreDiscriminator: boolean): ArticleHashtag {
    if (json == null) {
        return json;
    }
    return {
        
        'articleByArticleId': json['articleByArticleId'] == null ? undefined : ArticleFromJSON(json['articleByArticleId']),
        'articleId': json['articleId'] == null ? undefined : json['articleId'],
        'hashtagByHashtagId': json['hashtagByHashtagId'] == null ? undefined : HashtagFromJSON(json['hashtagByHashtagId']),
        'hashtagId': json['hashtagId'] == null ? undefined : json['hashtagId'],
    };
}

export function ArticleHashtagToJSON(value?: ArticleHashtag | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'articleByArticleId': ArticleToJSON(value['articleByArticleId']),
        'articleId': value['articleId'],
        'hashtagByHashtagId': HashtagToJSON(value['hashtagByHashtagId']),
        'hashtagId': value['hashtagId'],
    };
}

