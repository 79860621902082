/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Product } from './Product';
import {
    ProductFromJSON,
    ProductFromJSONTyped,
    ProductToJSON,
} from './Product';
import type { Shipping } from './Shipping';
import {
    ShippingFromJSON,
    ShippingFromJSONTyped,
    ShippingToJSON,
} from './Shipping';

/**
 * 
 * @export
 * @interface ShippingProduct
 */
export interface ShippingProduct {
    /**
     * 
     * @type {Product}
     * @memberof ShippingProduct
     */
    productByProductId?: Product;
    /**
     * 
     * @type {number}
     * @memberof ShippingProduct
     */
    productId?: number;
    /**
     * 
     * @type {number}
     * @memberof ShippingProduct
     */
    quantity?: number;
    /**
     * 
     * @type {Shipping}
     * @memberof ShippingProduct
     */
    shippingByShippingId?: Shipping;
    /**
     * 
     * @type {number}
     * @memberof ShippingProduct
     */
    shippingId?: number;
}

/**
 * Check if a given object implements the ShippingProduct interface.
 */
export function instanceOfShippingProduct(value: object): boolean {
    return true;
}

export function ShippingProductFromJSON(json: any): ShippingProduct {
    return ShippingProductFromJSONTyped(json, false);
}

export function ShippingProductFromJSONTyped(json: any, ignoreDiscriminator: boolean): ShippingProduct {
    if (json == null) {
        return json;
    }
    return {
        
        'productByProductId': json['productByProductId'] == null ? undefined : ProductFromJSON(json['productByProductId']),
        'productId': json['productId'] == null ? undefined : json['productId'],
        'quantity': json['quantity'] == null ? undefined : json['quantity'],
        'shippingByShippingId': json['shippingByShippingId'] == null ? undefined : ShippingFromJSON(json['shippingByShippingId']),
        'shippingId': json['shippingId'] == null ? undefined : json['shippingId'],
    };
}

export function ShippingProductToJSON(value?: ShippingProduct | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'productByProductId': ProductToJSON(value['productByProductId']),
        'productId': value['productId'],
        'quantity': value['quantity'],
        'shippingByShippingId': ShippingToJSON(value['shippingByShippingId']),
        'shippingId': value['shippingId'],
    };
}

