/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CategoryDto
 */
export interface CategoryDto {
    /**
     * 
     * @type {string}
     * @memberof CategoryDto
     */
    bannerImage?: string;
    /**
     * 
     * @type {number}
     * @memberof CategoryDto
     */
    categoryId?: number;
    /**
     * 
     * @type {string}
     * @memberof CategoryDto
     */
    categoryName?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CategoryDto
     */
    collection?: boolean;
    /**
     * 
     * @type {number}
     * @memberof CategoryDto
     */
    isHeader?: number;
    /**
     * 
     * @type {number}
     * @memberof CategoryDto
     */
    isStep?: number;
    /**
     * 
     * @type {string}
     * @memberof CategoryDto
     */
    mobileBannerImage?: string;
    /**
     * 
     * @type {number}
     * @memberof CategoryDto
     */
    productCount?: number;
    /**
     * 
     * @type {boolean}
     * @memberof CategoryDto
     */
    recommend?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CategoryDto
     */
    root?: boolean;
    /**
     * 
     * @type {number}
     * @memberof CategoryDto
     */
    seq?: number;
    /**
     * 
     * @type {Array<CategoryDto>}
     * @memberof CategoryDto
     */
    subCategories?: Array<CategoryDto>;
}

/**
 * Check if a given object implements the CategoryDto interface.
 */
export function instanceOfCategoryDto(value: object): boolean {
    return true;
}

export function CategoryDtoFromJSON(json: any): CategoryDto {
    return CategoryDtoFromJSONTyped(json, false);
}

export function CategoryDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CategoryDto {
    if (json == null) {
        return json;
    }
    return {
        
        'bannerImage': json['bannerImage'] == null ? undefined : json['bannerImage'],
        'categoryId': json['categoryId'] == null ? undefined : json['categoryId'],
        'categoryName': json['categoryName'] == null ? undefined : json['categoryName'],
        'collection': json['collection'] == null ? undefined : json['collection'],
        'isHeader': json['isHeader'] == null ? undefined : json['isHeader'],
        'isStep': json['isStep'] == null ? undefined : json['isStep'],
        'mobileBannerImage': json['mobileBannerImage'] == null ? undefined : json['mobileBannerImage'],
        'productCount': json['productCount'] == null ? undefined : json['productCount'],
        'recommend': json['recommend'] == null ? undefined : json['recommend'],
        'root': json['root'] == null ? undefined : json['root'],
        'seq': json['seq'] == null ? undefined : json['seq'],
        'subCategories': json['subCategories'] == null ? undefined : ((json['subCategories'] as Array<any>).map(CategoryDtoFromJSON)),
    };
}

export function CategoryDtoToJSON(value?: CategoryDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'bannerImage': value['bannerImage'],
        'categoryId': value['categoryId'],
        'categoryName': value['categoryName'],
        'collection': value['collection'],
        'isHeader': value['isHeader'],
        'isStep': value['isStep'],
        'mobileBannerImage': value['mobileBannerImage'],
        'productCount': value['productCount'],
        'recommend': value['recommend'],
        'root': value['root'],
        'seq': value['seq'],
        'subCategories': value['subCategories'] == null ? undefined : ((value['subCategories'] as Array<any>).map(CategoryDtoToJSON)),
    };
}

