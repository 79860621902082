/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { DiscountDtoLite } from './DiscountDtoLite';
import {
    DiscountDtoLiteFromJSON,
    DiscountDtoLiteFromJSONTyped,
    DiscountDtoLiteToJSON,
} from './DiscountDtoLite';
import type { ProductDto } from './ProductDto';
import {
    ProductDtoFromJSON,
    ProductDtoFromJSONTyped,
    ProductDtoToJSON,
} from './ProductDto';

/**
 * 
 * @export
 * @interface AddGuestGWPResponseDto
 */
export interface AddGuestGWPResponseDto {
    /**
     * 
     * @type {DiscountDtoLite}
     * @memberof AddGuestGWPResponseDto
     */
    discount?: DiscountDtoLite;
    /**
     * 
     * @type {number}
     * @memberof AddGuestGWPResponseDto
     */
    discountPrice?: number;
    /**
     * 
     * @type {ProductDto}
     * @memberof AddGuestGWPResponseDto
     */
    product?: ProductDto;
}

/**
 * Check if a given object implements the AddGuestGWPResponseDto interface.
 */
export function instanceOfAddGuestGWPResponseDto(value: object): boolean {
    return true;
}

export function AddGuestGWPResponseDtoFromJSON(json: any): AddGuestGWPResponseDto {
    return AddGuestGWPResponseDtoFromJSONTyped(json, false);
}

export function AddGuestGWPResponseDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): AddGuestGWPResponseDto {
    if (json == null) {
        return json;
    }
    return {
        
        'discount': json['discount'] == null ? undefined : DiscountDtoLiteFromJSON(json['discount']),
        'discountPrice': json['discountPrice'] == null ? undefined : json['discountPrice'],
        'product': json['product'] == null ? undefined : ProductDtoFromJSON(json['product']),
    };
}

export function AddGuestGWPResponseDtoToJSON(value?: AddGuestGWPResponseDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'discount': DiscountDtoLiteToJSON(value['discount']),
        'discountPrice': value['discountPrice'],
        'product': ProductDtoToJSON(value['product']),
    };
}

