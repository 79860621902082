/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ProductPriceTotal
 */
export interface ProductPriceTotal {
    /**
     * 
     * @type {number}
     * @memberof ProductPriceTotal
     */
    discountPriceTotal?: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof ProductPriceTotal
     */
    discountPrices?: Array<number>;
    /**
     * 
     * @type {number}
     * @memberof ProductPriceTotal
     */
    priceTotal?: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof ProductPriceTotal
     */
    prices?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof ProductPriceTotal
     */
    quantities?: Array<number>;
    /**
     * 
     * @type {number}
     * @memberof ProductPriceTotal
     */
    quantity?: number;
}

/**
 * Check if a given object implements the ProductPriceTotal interface.
 */
export function instanceOfProductPriceTotal(value: object): boolean {
    return true;
}

export function ProductPriceTotalFromJSON(json: any): ProductPriceTotal {
    return ProductPriceTotalFromJSONTyped(json, false);
}

export function ProductPriceTotalFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProductPriceTotal {
    if (json == null) {
        return json;
    }
    return {
        
        'discountPriceTotal': json['discountPriceTotal'] == null ? undefined : json['discountPriceTotal'],
        'discountPrices': json['discountPrices'] == null ? undefined : json['discountPrices'],
        'priceTotal': json['priceTotal'] == null ? undefined : json['priceTotal'],
        'prices': json['prices'] == null ? undefined : json['prices'],
        'quantities': json['quantities'] == null ? undefined : json['quantities'],
        'quantity': json['quantity'] == null ? undefined : json['quantity'],
    };
}

export function ProductPriceTotalToJSON(value?: ProductPriceTotal | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'discountPriceTotal': value['discountPriceTotal'],
        'discountPrices': value['discountPrices'],
        'priceTotal': value['priceTotal'],
        'prices': value['prices'],
        'quantities': value['quantities'],
        'quantity': value['quantity'],
    };
}

