/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { DisplayProductArticle } from './DisplayProductArticle';
import {
    DisplayProductArticleFromJSON,
    DisplayProductArticleFromJSONTyped,
    DisplayProductArticleToJSON,
} from './DisplayProductArticle';
import type { DisplayProductAward } from './DisplayProductAward';
import {
    DisplayProductAwardFromJSON,
    DisplayProductAwardFromJSONTyped,
    DisplayProductAwardToJSON,
} from './DisplayProductAward';
import type { DisplayProductCategory } from './DisplayProductCategory';
import {
    DisplayProductCategoryFromJSON,
    DisplayProductCategoryFromJSONTyped,
    DisplayProductCategoryToJSON,
} from './DisplayProductCategory';
import type { DisplayProductHashtag } from './DisplayProductHashtag';
import {
    DisplayProductHashtagFromJSON,
    DisplayProductHashtagFromJSONTyped,
    DisplayProductHashtagToJSON,
} from './DisplayProductHashtag';
import type { DisplayProductLanguage } from './DisplayProductLanguage';
import {
    DisplayProductLanguageFromJSON,
    DisplayProductLanguageFromJSONTyped,
    DisplayProductLanguageToJSON,
} from './DisplayProductLanguage';
import type { DisplayProductProduct } from './DisplayProductProduct';
import {
    DisplayProductProductFromJSON,
    DisplayProductProductFromJSONTyped,
    DisplayProductProductToJSON,
} from './DisplayProductProduct';

/**
 * 
 * @export
 * @interface DisplayProduct
 */
export interface DisplayProduct {
    /**
     * 
     * @type {string}
     * @memberof DisplayProduct
     */
    bookComment?: string;
    /**
     * 
     * @type {string}
     * @memberof DisplayProduct
     */
    coverFileName?: string;
    /**
     * 
     * @type {string}
     * @memberof DisplayProduct
     */
    ctsId?: string;
    /**
     * 
     * @type {Array<DisplayProductAward>}
     * @memberof DisplayProduct
     */
    displayAwardByProductId?: Array<DisplayProductAward>;
    /**
     * 
     * @type {Array<DisplayProductArticle>}
     * @memberof DisplayProduct
     */
    displayProductArticlesByDisplayProductId?: Array<DisplayProductArticle>;
    /**
     * 
     * @type {string}
     * @memberof DisplayProduct
     */
    displayProductAuthor?: string;
    /**
     * 
     * @type {Array<DisplayProductCategory>}
     * @memberof DisplayProduct
     */
    displayProductCategoriesByDisplayProductId?: Array<DisplayProductCategory>;
    /**
     * 
     * @type {string}
     * @memberof DisplayProduct
     */
    displayProductDescriptionDetail?: string;
    /**
     * 
     * @type {string}
     * @memberof DisplayProduct
     */
    displayProductDescriptionFeature?: string;
    /**
     * 
     * @type {string}
     * @memberof DisplayProduct
     */
    displayProductDescriptionIngredient?: string;
    /**
     * 
     * @type {string}
     * @memberof DisplayProduct
     */
    displayProductDescriptionTarget?: string;
    /**
     * 
     * @type {string}
     * @memberof DisplayProduct
     */
    displayProductDescriptionUsage?: string;
    /**
     * 
     * @type {string}
     * @memberof DisplayProduct
     */
    displayProductDescriptionUseCount?: string;
    /**
     * 
     * @type {number}
     * @memberof DisplayProduct
     */
    displayProductDiscountPrice?: number;
    /**
     * 
     * @type {Array<DisplayProductHashtag>}
     * @memberof DisplayProduct
     */
    displayProductHashtagsByDisplayProductId?: Array<DisplayProductHashtag>;
    /**
     * 
     * @type {number}
     * @memberof DisplayProduct
     */
    displayProductId?: number;
    /**
     * 
     * @type {string}
     * @memberof DisplayProduct
     */
    displayProductImage?: string;
    /**
     * 
     * @type {Array<DisplayProductLanguage>}
     * @memberof DisplayProduct
     */
    displayProductLanguagesByProductId?: Array<DisplayProductLanguage>;
    /**
     * 
     * @type {string}
     * @memberof DisplayProduct
     */
    displayProductNameChi?: string;
    /**
     * 
     * @type {string}
     * @memberof DisplayProduct
     */
    displayProductNameEng?: string;
    /**
     * 
     * @type {string}
     * @memberof DisplayProduct
     */
    displayProductNameHtmlChi?: string;
    /**
     * 
     * @type {string}
     * @memberof DisplayProduct
     */
    displayProductNameHtmlEng?: string;
    /**
     * 
     * @type {number}
     * @memberof DisplayProduct
     */
    displayProductOriginalPrice?: number;
    /**
     * 
     * @type {number}
     * @memberof DisplayProduct
     */
    displayProductPrice?: number;
    /**
     * 
     * @type {Array<DisplayProductProduct>}
     * @memberof DisplayProduct
     */
    displayProductProductsByDisplayProductId?: Array<DisplayProductProduct>;
    /**
     * 
     * @type {string}
     * @memberof DisplayProduct
     */
    displayProductShortDescription?: string;
    /**
     * 
     * @type {string}
     * @memberof DisplayProduct
     */
    displayProductTranslator?: string;
    /**
     * 
     * @type {string}
     * @memberof DisplayProduct
     */
    displayProductUrlNaming?: string;
    /**
     * 
     * @type {string}
     * @memberof DisplayProduct
     */
    ePubPreviewFileMd5?: string;
    /**
     * 
     * @type {string}
     * @memberof DisplayProduct
     */
    ePubPreviewFileName?: string;
    /**
     * 
     * @type {number}
     * @memberof DisplayProduct
     */
    ePubPreviewFileSize?: number;
    /**
     * 
     * @type {string}
     * @memberof DisplayProduct
     */
    ePubProdFileMd5?: string;
    /**
     * 
     * @type {string}
     * @memberof DisplayProduct
     */
    ePubProdFileName?: string;
    /**
     * 
     * @type {number}
     * @memberof DisplayProduct
     */
    ePubProdFileSize?: number;
    /**
     * 
     * @type {string}
     * @memberof DisplayProduct
     */
    functionalIcon?: string;
    /**
     * 
     * @type {string}
     * @memberof DisplayProduct
     */
    isbn?: string;
    /**
     * 
     * @type {string}
     * @memberof DisplayProduct
     */
    language?: string;
    /**
     * 
     * @type {string}
     * @memberof DisplayProduct
     */
    metadesc?: string;
    /**
     * 
     * @type {string}
     * @memberof DisplayProduct
     */
    metatitle?: string;
    /**
     * 
     * @type {string}
     * @memberof DisplayProduct
     */
    multimediaFileNames?: string;
    /**
     * 
     * @type {Date}
     * @memberof DisplayProduct
     */
    offShelfDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof DisplayProduct
     */
    onShelfDate?: Date;
    /**
     * 
     * @type {number}
     * @memberof DisplayProduct
     */
    order?: number;
    /**
     * 
     * @type {number}
     * @memberof DisplayProduct
     */
    organizationId?: number;
    /**
     * 
     * @type {string}
     * @memberof DisplayProduct
     */
    productType?: string;
    /**
     * 
     * @type {string}
     * @memberof DisplayProduct
     */
    publisher?: string;
    /**
     * 
     * @type {string}
     * @memberof DisplayProduct
     */
    recommended?: string;
    /**
     * 
     * @type {string}
     * @memberof DisplayProduct
     */
    sku?: string;
    /**
     * 
     * @type {string}
     * @memberof DisplayProduct
     */
    status?: string;
    /**
     * 
     * @type {string}
     * @memberof DisplayProduct
     */
    thumbFileNames?: string;
}

/**
 * Check if a given object implements the DisplayProduct interface.
 */
export function instanceOfDisplayProduct(value: object): boolean {
    return true;
}

export function DisplayProductFromJSON(json: any): DisplayProduct {
    return DisplayProductFromJSONTyped(json, false);
}

export function DisplayProductFromJSONTyped(json: any, ignoreDiscriminator: boolean): DisplayProduct {
    if (json == null) {
        return json;
    }
    return {
        
        'bookComment': json['bookComment'] == null ? undefined : json['bookComment'],
        'coverFileName': json['coverFileName'] == null ? undefined : json['coverFileName'],
        'ctsId': json['ctsId'] == null ? undefined : json['ctsId'],
        'displayAwardByProductId': json['displayAwardByProductId'] == null ? undefined : ((json['displayAwardByProductId'] as Array<any>).map(DisplayProductAwardFromJSON)),
        'displayProductArticlesByDisplayProductId': json['displayProductArticlesByDisplayProductId'] == null ? undefined : ((json['displayProductArticlesByDisplayProductId'] as Array<any>).map(DisplayProductArticleFromJSON)),
        'displayProductAuthor': json['displayProductAuthor'] == null ? undefined : json['displayProductAuthor'],
        'displayProductCategoriesByDisplayProductId': json['displayProductCategoriesByDisplayProductId'] == null ? undefined : ((json['displayProductCategoriesByDisplayProductId'] as Array<any>).map(DisplayProductCategoryFromJSON)),
        'displayProductDescriptionDetail': json['displayProductDescriptionDetail'] == null ? undefined : json['displayProductDescriptionDetail'],
        'displayProductDescriptionFeature': json['displayProductDescriptionFeature'] == null ? undefined : json['displayProductDescriptionFeature'],
        'displayProductDescriptionIngredient': json['displayProductDescriptionIngredient'] == null ? undefined : json['displayProductDescriptionIngredient'],
        'displayProductDescriptionTarget': json['displayProductDescriptionTarget'] == null ? undefined : json['displayProductDescriptionTarget'],
        'displayProductDescriptionUsage': json['displayProductDescriptionUsage'] == null ? undefined : json['displayProductDescriptionUsage'],
        'displayProductDescriptionUseCount': json['displayProductDescriptionUseCount'] == null ? undefined : json['displayProductDescriptionUseCount'],
        'displayProductDiscountPrice': json['displayProductDiscountPrice'] == null ? undefined : json['displayProductDiscountPrice'],
        'displayProductHashtagsByDisplayProductId': json['displayProductHashtagsByDisplayProductId'] == null ? undefined : ((json['displayProductHashtagsByDisplayProductId'] as Array<any>).map(DisplayProductHashtagFromJSON)),
        'displayProductId': json['displayProductId'] == null ? undefined : json['displayProductId'],
        'displayProductImage': json['displayProductImage'] == null ? undefined : json['displayProductImage'],
        'displayProductLanguagesByProductId': json['displayProductLanguagesByProductId'] == null ? undefined : ((json['displayProductLanguagesByProductId'] as Array<any>).map(DisplayProductLanguageFromJSON)),
        'displayProductNameChi': json['displayProductNameChi'] == null ? undefined : json['displayProductNameChi'],
        'displayProductNameEng': json['displayProductNameEng'] == null ? undefined : json['displayProductNameEng'],
        'displayProductNameHtmlChi': json['displayProductNameHtmlChi'] == null ? undefined : json['displayProductNameHtmlChi'],
        'displayProductNameHtmlEng': json['displayProductNameHtmlEng'] == null ? undefined : json['displayProductNameHtmlEng'],
        'displayProductOriginalPrice': json['displayProductOriginalPrice'] == null ? undefined : json['displayProductOriginalPrice'],
        'displayProductPrice': json['displayProductPrice'] == null ? undefined : json['displayProductPrice'],
        'displayProductProductsByDisplayProductId': json['displayProductProductsByDisplayProductId'] == null ? undefined : ((json['displayProductProductsByDisplayProductId'] as Array<any>).map(DisplayProductProductFromJSON)),
        'displayProductShortDescription': json['displayProductShortDescription'] == null ? undefined : json['displayProductShortDescription'],
        'displayProductTranslator': json['displayProductTranslator'] == null ? undefined : json['displayProductTranslator'],
        'displayProductUrlNaming': json['displayProductUrlNaming'] == null ? undefined : json['displayProductUrlNaming'],
        'ePubPreviewFileMd5': json['ePubPreviewFileMd5'] == null ? undefined : json['ePubPreviewFileMd5'],
        'ePubPreviewFileName': json['ePubPreviewFileName'] == null ? undefined : json['ePubPreviewFileName'],
        'ePubPreviewFileSize': json['ePubPreviewFileSize'] == null ? undefined : json['ePubPreviewFileSize'],
        'ePubProdFileMd5': json['ePubProdFileMd5'] == null ? undefined : json['ePubProdFileMd5'],
        'ePubProdFileName': json['ePubProdFileName'] == null ? undefined : json['ePubProdFileName'],
        'ePubProdFileSize': json['ePubProdFileSize'] == null ? undefined : json['ePubProdFileSize'],
        'functionalIcon': json['functionalIcon'] == null ? undefined : json['functionalIcon'],
        'isbn': json['isbn'] == null ? undefined : json['isbn'],
        'language': json['language'] == null ? undefined : json['language'],
        'metadesc': json['metadesc'] == null ? undefined : json['metadesc'],
        'metatitle': json['metatitle'] == null ? undefined : json['metatitle'],
        'multimediaFileNames': json['multimediaFileNames'] == null ? undefined : json['multimediaFileNames'],
        'offShelfDate': json['offShelfDate'] == null ? undefined : (new Date(json['offShelfDate'])),
        'onShelfDate': json['onShelfDate'] == null ? undefined : (new Date(json['onShelfDate'])),
        'order': json['order'] == null ? undefined : json['order'],
        'organizationId': json['organizationId'] == null ? undefined : json['organizationId'],
        'productType': json['productType'] == null ? undefined : json['productType'],
        'publisher': json['publisher'] == null ? undefined : json['publisher'],
        'recommended': json['recommended'] == null ? undefined : json['recommended'],
        'sku': json['sku'] == null ? undefined : json['sku'],
        'status': json['status'] == null ? undefined : json['status'],
        'thumbFileNames': json['thumbFileNames'] == null ? undefined : json['thumbFileNames'],
    };
}

export function DisplayProductToJSON(value?: DisplayProduct | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'bookComment': value['bookComment'],
        'coverFileName': value['coverFileName'],
        'ctsId': value['ctsId'],
        'displayAwardByProductId': value['displayAwardByProductId'] == null ? undefined : ((value['displayAwardByProductId'] as Array<any>).map(DisplayProductAwardToJSON)),
        'displayProductArticlesByDisplayProductId': value['displayProductArticlesByDisplayProductId'] == null ? undefined : ((value['displayProductArticlesByDisplayProductId'] as Array<any>).map(DisplayProductArticleToJSON)),
        'displayProductAuthor': value['displayProductAuthor'],
        'displayProductCategoriesByDisplayProductId': value['displayProductCategoriesByDisplayProductId'] == null ? undefined : ((value['displayProductCategoriesByDisplayProductId'] as Array<any>).map(DisplayProductCategoryToJSON)),
        'displayProductDescriptionDetail': value['displayProductDescriptionDetail'],
        'displayProductDescriptionFeature': value['displayProductDescriptionFeature'],
        'displayProductDescriptionIngredient': value['displayProductDescriptionIngredient'],
        'displayProductDescriptionTarget': value['displayProductDescriptionTarget'],
        'displayProductDescriptionUsage': value['displayProductDescriptionUsage'],
        'displayProductDescriptionUseCount': value['displayProductDescriptionUseCount'],
        'displayProductDiscountPrice': value['displayProductDiscountPrice'],
        'displayProductHashtagsByDisplayProductId': value['displayProductHashtagsByDisplayProductId'] == null ? undefined : ((value['displayProductHashtagsByDisplayProductId'] as Array<any>).map(DisplayProductHashtagToJSON)),
        'displayProductId': value['displayProductId'],
        'displayProductImage': value['displayProductImage'],
        'displayProductLanguagesByProductId': value['displayProductLanguagesByProductId'] == null ? undefined : ((value['displayProductLanguagesByProductId'] as Array<any>).map(DisplayProductLanguageToJSON)),
        'displayProductNameChi': value['displayProductNameChi'],
        'displayProductNameEng': value['displayProductNameEng'],
        'displayProductNameHtmlChi': value['displayProductNameHtmlChi'],
        'displayProductNameHtmlEng': value['displayProductNameHtmlEng'],
        'displayProductOriginalPrice': value['displayProductOriginalPrice'],
        'displayProductPrice': value['displayProductPrice'],
        'displayProductProductsByDisplayProductId': value['displayProductProductsByDisplayProductId'] == null ? undefined : ((value['displayProductProductsByDisplayProductId'] as Array<any>).map(DisplayProductProductToJSON)),
        'displayProductShortDescription': value['displayProductShortDescription'],
        'displayProductTranslator': value['displayProductTranslator'],
        'displayProductUrlNaming': value['displayProductUrlNaming'],
        'ePubPreviewFileMd5': value['ePubPreviewFileMd5'],
        'ePubPreviewFileName': value['ePubPreviewFileName'],
        'ePubPreviewFileSize': value['ePubPreviewFileSize'],
        'ePubProdFileMd5': value['ePubProdFileMd5'],
        'ePubProdFileName': value['ePubProdFileName'],
        'ePubProdFileSize': value['ePubProdFileSize'],
        'functionalIcon': value['functionalIcon'],
        'isbn': value['isbn'],
        'language': value['language'],
        'metadesc': value['metadesc'],
        'metatitle': value['metatitle'],
        'multimediaFileNames': value['multimediaFileNames'],
        'offShelfDate': value['offShelfDate'] == null ? undefined : ((value['offShelfDate']).toISOString()),
        'onShelfDate': value['onShelfDate'] == null ? undefined : ((value['onShelfDate']).toISOString()),
        'order': value['order'],
        'organizationId': value['organizationId'],
        'productType': value['productType'],
        'publisher': value['publisher'],
        'recommended': value['recommended'],
        'sku': value['sku'],
        'status': value['status'],
        'thumbFileNames': value['thumbFileNames'],
    };
}

