/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SuccessResponseModel
 */
export interface SuccessResponseModel {
    /**
     * 
     * @type {object}
     * @memberof SuccessResponseModel
     */
    date?: object;
    /**
     * 
     * @type {boolean}
     * @memberof SuccessResponseModel
     */
    success?: boolean;
}

/**
 * Check if a given object implements the SuccessResponseModel interface.
 */
export function instanceOfSuccessResponseModel(value: object): boolean {
    return true;
}

export function SuccessResponseModelFromJSON(json: any): SuccessResponseModel {
    return SuccessResponseModelFromJSONTyped(json, false);
}

export function SuccessResponseModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): SuccessResponseModel {
    if (json == null) {
        return json;
    }
    return {
        
        'date': json['date'] == null ? undefined : json['date'],
        'success': json['success'] == null ? undefined : json['success'],
    };
}

export function SuccessResponseModelToJSON(value?: SuccessResponseModel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'date': value['date'],
        'success': value['success'],
    };
}

