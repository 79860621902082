/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Award
 */
export interface Award {
    /**
     * 
     * @type {number}
     * @memberof Award
     */
    awardId?: number;
    /**
     * 
     * @type {string}
     * @memberof Award
     */
    mongoId?: string;
    /**
     * 
     * @type {string}
     * @memberof Award
     */
    name?: string;
    /**
     * 
     * @type {number}
     * @memberof Award
     */
    seq?: number;
    /**
     * 
     * @type {string}
     * @memberof Award
     */
    status?: string;
    /**
     * 
     * @type {string}
     * @memberof Award
     */
    yearOf?: string;
}

/**
 * Check if a given object implements the Award interface.
 */
export function instanceOfAward(value: object): boolean {
    return true;
}

export function AwardFromJSON(json: any): Award {
    return AwardFromJSONTyped(json, false);
}

export function AwardFromJSONTyped(json: any, ignoreDiscriminator: boolean): Award {
    if (json == null) {
        return json;
    }
    return {
        
        'awardId': json['awardId'] == null ? undefined : json['awardId'],
        'mongoId': json['mongoId'] == null ? undefined : json['mongoId'],
        'name': json['name'] == null ? undefined : json['name'],
        'seq': json['seq'] == null ? undefined : json['seq'],
        'status': json['status'] == null ? undefined : json['status'],
        'yearOf': json['yearOf'] == null ? undefined : json['yearOf'],
    };
}

export function AwardToJSON(value?: Award | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'awardId': value['awardId'],
        'mongoId': value['mongoId'],
        'name': value['name'],
        'seq': value['seq'],
        'status': value['status'],
        'yearOf': value['yearOf'],
    };
}

