import React from "react";
import chardet from "chardet";
import iconv from "iconv-lite";
import localforage from "localforage";
import { Suspense } from "react";
import { isElectron } from "react-device-detect";
import { CouponToJSON } from "../../../fetcher";
import BookModel from "../../../model/Book";
import { withRouter } from "../../withRouter";
import StorageUtil from "../serviceUtils/storageUtil";
import { getPDFCover } from "./pdfUtil";
import { xmlMetadata } from "./xmlUtil";
// import { base64ArrayBuffer } from "./coverUtil";

let cordova;

const { MobiRender, Azw3Render } = window.Kookit;

// let Unrar = window.Unrar;
class BookUtil {
  static addBook(key, buffer) {
    if (isElectron) {
      const fs = window.require("fs");
      const path = window.require("path");
      const dataPath = localStorage.getItem("storageLocation")
        ? localStorage.getItem("storageLocation")
        : window
            .require("electron")
            .ipcRenderer.sendSync("storage-location", "ping");
      return new Promise((resolve, reject) => {
        var reader = new FileReader();
        reader.readAsArrayBuffer(new Blob([buffer]));
        reader.onload = async (event) => {
          if (!event.target) return;
          try {
            fs.writeFileSync(
              path.join(dataPath, "book", key),
              Buffer.from(event.target.result)
            );
            resolve();
          } catch (error) {
            reject(error);
            throw error;
          }
        };
        reader.onerror = () => {
          reject();
        };
      });
    } else {
      return localforage.setItem(key, buffer);
    }
  }

  static deleteBook(key) {
    if (isElectron) {
      const fs = window.require("fs-extra");
      const path = window.require("path");
      const dataPath = localStorage.getItem("storageLocation")
        ? localStorage.getItem("storageLocation")
        : window
            .require("electron")
            .ipcRenderer.sendSync("storage-location", "ping");
      return new Promise((resolve, reject) => {
        try {
          fs.remove(path.join(dataPath, `book`, key), (err) => {
            if (err) throw err;
            resolve();
          });
        } catch (e) {
          reject();
        }
      });
    } else {
      return localforage.removeItem(key);
    }
  }
  static isBookExist(key, bookPath = "") {
    return new Promise((resolve, reject) => {
      if (isElectron) {
        var fs = window.require("fs");
        var path = window.require("path");
        let _bookPath = path.join(
          localStorage.getItem("storageLocation")
            ? localStorage.getItem("storageLocation")
            : window
                .require("electron")
                .ipcRenderer.sendSync("storage-location", "ping"),
          `book`,
          key
        );

        if ((bookPath && fs.existsSync(bookPath)) || fs.existsSync(_bookPath)) {
          resolve(true);
        } else {
          resolve(false);
        }
      } else {
        localforage.getItem(key).then((result) => {
          if (result) {
            resolve(true);
          } else {
            resolve(false);
          }
        });
      }
    });
  }
  static fetchBook(key, isArrayBuffer = false, bookPath = "") {
    if (isElectron) {
      return new Promise((resolve, reject) => {
        var fs = window.require("fs");
        var path = window.require("path");
        let _bookPath = path.join(
          localStorage.getItem("storageLocation")
            ? localStorage.getItem("storageLocation")
            : window
                .require("electron")
                .ipcRenderer.sendSync("storage-location", "ping"),
          `book`,
          key
        );
        var data;
        if (bookPath && fs.existsSync(bookPath)) {
          data = fs.readFileSync(bookPath);
        } else if (fs.existsSync(_bookPath)) {
          data = fs.readFileSync(_bookPath);
        } else {
          resolve(false);
        }

        let blobTemp = new Blob([data]);
        let fileTemp = new File([blobTemp], "data", {
          lastModified: new Date().getTime(),
          type: blobTemp.type,
        });
        if (isArrayBuffer) {
          resolve(new Uint8Array(data).buffer);
        } else {
          resolve(fileTemp);
        }
      });
    } else {
      return localforage.getItem(key);
    }
  }
  static async RedirectBook(book, name, bookId) {
    console.log("RedirectBook Start:", { book, name, bookId });

    const bookExist = await this.isBookExist(book.key, book.path);
    console.log("Book exists check:", bookExist);

    if (!bookExist) {
      console.log("Book not found, exiting");
      alert("Book not exist");
      return;
    }

    let ref = book.format.toLowerCase();
    console.log("Book format:", ref);

    if (isElectron) {
      console.log("Electron environment detected");
      const { ipcRenderer } = window.require("electron");
      console.log("Opening book in Electron...");
      ipcRenderer.invoke("open-book", {
        url: `${window.location.href.split("/")[0]}/${ref}/${book.key}`,
        isMergeWord:
          book.format === "PDF" || book.format === "DJVU"
            ? "no"
            : StorageUtil.getReaderConfig("isMergeWord"),
        isFullscreen: StorageUtil.getReaderConfig("isAutoFullscreen"),
        isPreventSleep: StorageUtil.getReaderConfig("isPreventSleep"),
      });
    } else {
      console.log("Web environment detected");
      // window.open(
      //   `${window.location.href.split("/")[0]}/${ref}/${book.key}?title=${book.name
      //   }`
      // );

      if (bookId === undefined) {
        console.log("Book ID is undefined, exiting");
        alert("Book id not found");
        return;
      }

      var sUsrAg = navigator.userAgent;
      console.log("User Agent:", sUsrAg);

      if (sUsrAg.includes("suepiosapp")) {
        console.log("iOS app detected");
        window.open = window.cordova.InAppBrowser.open;
        const iosUrl = `${window.location.href.split("/")[0]}/${ref}/${book.key}?title=${
          name !== undefined ? name : book.name
        }&bookId=${bookId}`;
        console.log("Opening iOS URL:", iosUrl);
        window.cordova.InAppBrowser.open(iosUrl, "_blank");
        return;
      }

      if (sUsrAg.includes("suepandroidapp")) {
        console.log("Android app detected");
        const androidUrl = `${window.location.href.split("/")[0]}/${ref}/${book.key}?title=${
          name !== undefined ? name : book.name
        }&bookId=${bookId}`;
        console.log("Opening Android URL:", androidUrl);
        window.open(androidUrl, "_blank");
        return;
      }

      console.log("Showing confirmation dialog");
      if (!window.confirm("是否開啟書本?")) {
        console.log("User cancelled book opening");
        return;
      }

      console.log("Creating loading element");
      const loadingElement = document.createElement('div');
      loadingElement.id = 'book-loading';
      loadingElement.style.cssText = `
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background: rgba(0, 0, 0, 0.8);
        color: white;
        padding: 20px;
        border-radius: 5px;
        z-index: 9999;
      `;
      loadingElement.textContent = '下載中...';
      document.body.appendChild(loadingElement);

      try {
        const bookUrl = `${window.location.href.split("/")[0]}/${ref}/${book.key}?title=${
          name !== undefined ? name : book.name
        }&bookId=${bookId}`;
        console.log("Storing book URL:", bookUrl);
        localStorage.setItem("openBookUrl", bookUrl);

        if (
          sUsrAg.includes("suepiosapp") ||
          sUsrAg.includes("suepandroidapp")
        ) {
          console.log("Opening in mobile app browser");
          window.open = cordova.InAppBrowser.open;
          cordova.InAppBrowser.open(bookUrl, "_self");
        } else {
          console.log("Opening in regular browser");
          window.open(bookUrl, "_blank");
        }
      } catch (error) {
        console.error("Error opening book:", error);
      } finally {
        console.log("Removing loading element");
        const loadingEl = document.getElementById('book-loading');
        if (loadingEl) {
          loadingEl.remove();
        }
      }
    }
    console.log("RedirectBook End");
  }

  static getBookUrl(book) {
    let ref = book.format.toLowerCase();
    return `/${ref}/${book.key}`;
  }

  static getPDFUrl(book) {
    if (isElectron) {
      const path = window.require("path");
      const { ipcRenderer } = window.require("electron");
      localStorage.setItem("pdfPath", book.path);
      const __dirname = ipcRenderer.sendSync("get-dirname", "ping");
      let pdfLocation =
        document.URL.indexOf("localhost") > -1
          ? "http://localhost:3000/"
          : `file://${path.join(
              __dirname,
              "./build",
              "lib",
              "pdf",
              "web",
              "viewer.html"
            )}`;
      let url = `${
        window.navigator.platform.indexOf("Win") > -1
          ? "lib/pdf/web/"
          : "lib\\pdf\\web\\"
      }viewer.html?file=${book.key}`;
      return document.URL.indexOf("localhost") > -1
        ? pdfLocation + url
        : `${pdfLocation}?file=${book.key}`;
    } else {
      return `../lib/pdf/web/viewer.html?file=${book.key}`;
    }
  }

  static generateBook(bookName, extension, md5, size, path, file_content) {
    return new Promise(async (resolve, reject) => {
      console.log("file_content", file_content);
      let cover;
      let key, name, author, publisher, description, charset;
      [name, author, description, publisher, charset] = [
        bookName,
        "Unknown Authur",
        "",
        "",
        "",
      ];
      switch (extension) {
        case "pdf":
          cover = await getPDFCover(file_content);
          break;
        case "mobi":
          let mobiRendition = new MobiRender(
            file_content,
            "scroll",
            StorageUtil.getReaderConfig("isSliding") === "yes" ? true : false
          );
          if (mobiRendition.getMetadata().compression === 17480) {
            resolve(false);
          }
          break;
        case "azw3":
          let azw3Rendition = new Azw3Render(
            file_content,
            "scroll",
            StorageUtil.getReaderConfig("isSliding") === "yes" ? true : false
          );
          if (azw3Rendition.getMetadata().compression === 17480) {
            resolve(false);
          }
          break;
        case "fb2":
          charset = chardet.detect(Buffer.from(file_content)) || "";
          let fb2Str = iconv.decode(
            Buffer.from(file_content),
            charset || "utf8"
          );
          let fb2Obj = await xmlMetadata(fb2Str);
          cover = fb2Obj.cover;
          name = fb2Obj.name;
          author = fb2Obj.author;
          break;
        // case "cbr":
        //   let unrar = new Unrar(file_content);
        //   let buffer = unrar.decompress(
        //     unrar.getEntries().map((item: any) => item.name)[0]
        //   );
        //   cover = base64ArrayBuffer(buffer);
        //   break;

        default:
          break;
      }

      let format = extension.toUpperCase();
      console.log("md5", md5);
      key = new Date().getTime() + "";
      resolve(
        new BookModel(
          key,
          name,
          author,
          description,
          md5,
          cover,
          format,
          publisher,
          size,
          path,
          charset
        )
      );
    });
  }
}

export default BookUtil;
