/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AddressDto,
  DisplayProductDto,
  GoogleVerifyResponseModel,
  MemberDto,
  SignUpMessageDto,
  SuccessResponseModel,
} from '../models/index';
import {
    AddressDtoFromJSON,
    AddressDtoToJSON,
    DisplayProductDtoFromJSON,
    DisplayProductDtoToJSON,
    GoogleVerifyResponseModelFromJSON,
    GoogleVerifyResponseModelToJSON,
    MemberDtoFromJSON,
    MemberDtoToJSON,
    SignUpMessageDtoFromJSON,
    SignUpMessageDtoToJSON,
    SuccessResponseModelFromJSON,
    SuccessResponseModelToJSON,
} from '../models/index';

export interface AccountConfirmFromEmailUsingPOSTRequest {
    token: string;
}

export interface AddMemberAddressUsingPOSTRequest {
    recipient: string;
    contactNumber: string;
    addressRow1: string;
    addressRow2: string;
    addressRow3: string;
    addressRow4: string;
    countryCode: number;
    phoneCountryId: string;
    addressProvince: string;
    addressCity: string;
    addressZone: string;
    name?: string;
}

export interface AddMemberFavouriteUsingPUTRequest {
    productId: number;
    name?: string;
}

export interface AddMemberUsingPOSTRequest {
    phone: string;
    firstname: string;
    email: string;
    gender: string;
    birthday: number;
    password: string;
    locationId?: number;
    verificationCode?: string;
    district?: string;
    lastname?: string;
    ageGroup?: number;
    agreement2?: boolean;
    acceptPromoption?: string;
}

export interface AddOauthMember2UsingPOSTRequest {
    credential: string;
}

export interface AddOauthMemberUsingPOSTRequest {
    locationId: number;
    phone: string;
    firstname: string;
    email: string;
    gender: string;
    birthday: number;
    googleId: string;
    facebookId: string;
    verificationCode?: string;
    lastname?: string;
    ageGroup?: number;
    acceptPromoption?: string;
}

export interface ChangeDefaultMemberAddressUsingPOSTRequest {
    name?: string;
    addressId?: number;
}

export interface CheckEmailUsingDELETERequest {
    email: string;
}

export interface CheckEmailUsingGETRequest {
    email: string;
}

export interface CheckEmailUsingHEADRequest {
    email: string;
}

export interface CheckEmailUsingOPTIONSRequest {
    email: string;
}

export interface CheckEmailUsingPATCHRequest {
    email: string;
}

export interface CheckEmailUsingPOSTRequest {
    email: string;
}

export interface CheckEmailUsingPUTRequest {
    email: string;
}

export interface CheckEmailUsingTRACERequest {
    email: string;
}

export interface CheckFacebookIdUsingDELETERequest {
    facebookId: string;
}

export interface CheckFacebookIdUsingGETRequest {
    facebookId: string;
}

export interface CheckFacebookIdUsingHEADRequest {
    facebookId: string;
}

export interface CheckFacebookIdUsingOPTIONSRequest {
    facebookId: string;
}

export interface CheckFacebookIdUsingPATCHRequest {
    facebookId: string;
}

export interface CheckFacebookIdUsingPOSTRequest {
    facebookId: string;
}

export interface CheckFacebookIdUsingPUTRequest {
    facebookId: string;
}

export interface CheckFacebookIdUsingTRACERequest {
    facebookId: string;
}

export interface CheckGoogleIdUsingDELETERequest {
    googleId: string;
}

export interface CheckGoogleIdUsingGETRequest {
    googleId: string;
}

export interface CheckGoogleIdUsingHEADRequest {
    googleId: string;
}

export interface CheckGoogleIdUsingOPTIONSRequest {
    googleId: string;
}

export interface CheckGoogleIdUsingPATCHRequest {
    googleId: string;
}

export interface CheckGoogleIdUsingPOSTRequest {
    googleId: string;
}

export interface CheckGoogleIdUsingPUTRequest {
    googleId: string;
}

export interface CheckGoogleIdUsingTRACERequest {
    googleId: string;
}

export interface DeleteMemberAddressUsingDELETERequest {
    name?: string;
    addressId?: number;
}

export interface DeleteMemberFavouriteUsingDELETERequest {
    name?: string;
    productId?: number;
}

export interface GetDefaultMemberAddressUsingGETRequest {
    name?: string;
}

export interface GetMemberAddressUsingGETRequest {
    name?: string;
}

export interface GetMemberFavouritesUsingGETRequest {
    name?: string;
}

export interface GetMemberUsingGETRequest {
    memberId: number;
}

export interface GetMemberUsingGET1Request {
    name?: string;
}

export interface GetTokenByFacebookIdUsingDELETERequest {
    facebookId: string;
}

export interface GetTokenByFacebookIdUsingGETRequest {
    facebookId: string;
}

export interface GetTokenByFacebookIdUsingHEADRequest {
    facebookId: string;
}

export interface GetTokenByFacebookIdUsingOPTIONSRequest {
    facebookId: string;
}

export interface GetTokenByFacebookIdUsingPATCHRequest {
    facebookId: string;
}

export interface GetTokenByFacebookIdUsingPOSTRequest {
    facebookId: string;
}

export interface GetTokenByFacebookIdUsingPUTRequest {
    facebookId: string;
}

export interface GetTokenByFacebookIdUsingTRACERequest {
    facebookId: string;
}

export interface GetTokenByGoogleIdUsingDELETERequest {
    googleId: string;
}

export interface GetTokenByGoogleIdUsingGETRequest {
    googleId: string;
}

export interface GetTokenByGoogleIdUsingHEADRequest {
    googleId: string;
}

export interface GetTokenByGoogleIdUsingOPTIONSRequest {
    googleId: string;
}

export interface GetTokenByGoogleIdUsingPATCHRequest {
    googleId: string;
}

export interface GetTokenByGoogleIdUsingPOSTRequest {
    googleId: string;
}

export interface GetTokenByGoogleIdUsingPUTRequest {
    googleId: string;
}

export interface GetTokenByGoogleIdUsingTRACERequest {
    googleId: string;
}

export interface IsValidEmailUsingDELETERequest {
    email: string;
}

export interface IsValidEmailUsingGETRequest {
    email: string;
}

export interface IsValidEmailUsingHEADRequest {
    email: string;
}

export interface IsValidEmailUsingOPTIONSRequest {
    email: string;
}

export interface IsValidEmailUsingPATCHRequest {
    email: string;
}

export interface IsValidEmailUsingPOSTRequest {
    email: string;
}

export interface IsValidEmailUsingPUTRequest {
    email: string;
}

export interface IsValidEmailUsingTRACERequest {
    email: string;
}

export interface ResetPasswordEmailUsingPOSTRequest {
    email: string;
    reset?: boolean;
}

export interface ResetPasswordSetMemberPasswordUsingPOSTRequest {
    email: string;
    password: string;
}

export interface ResetPasswordUsingPOSTRequest {
    locationId: number;
    phone: string;
    verificationCode: string;
    password: string;
}

export interface ResetPasswordVerifyUsingPOSTRequest {
    email: string;
    verifyCode: string;
}

export interface UpdateMemberAddressUsingPUTRequest {
    addressId: number;
    name?: string;
    recipient?: string;
    contactNumber?: string;
    addressRow1?: string;
    addressRow2?: string;
    addressRow3?: string;
    addressRow4?: string;
    countryCode?: number;
    phoneCountryId?: string;
    addressProvince?: string;
    addressCity?: string;
    addressZone?: string;
}

export interface UpdateMemberUsingPOSTRequest {
    memberId: number;
    username?: string;
    firstName?: string;
    lastName?: string;
    password?: string;
    email?: string;
    locationId?: number;
    phone?: string;
    address1?: number;
    address2?: number;
    address3?: number;
    birthday?: number;
    ageGroup?: number;
    memberLevelId?: number;
    district?: string;
    body?: Date;
}

/**
 * 
 */
export class MemberControllerApi extends runtime.BaseAPI {

    /**
     * accountConfirmFromEmail
     */
    async accountConfirmFromEmailUsingPOSTRaw(requestParameters: AccountConfirmFromEmailUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SuccessResponseModel>> {
        if (requestParameters['token'] == null) {
            throw new runtime.RequiredError(
                'token',
                'Required parameter "token" was null or undefined when calling accountConfirmFromEmailUsingPOST().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['token'] != null) {
            queryParameters['token'] = requestParameters['token'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/member/email-confirm`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SuccessResponseModelFromJSON(jsonValue));
    }

    /**
     * accountConfirmFromEmail
     */
    async accountConfirmFromEmailUsingPOST(requestParameters: AccountConfirmFromEmailUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SuccessResponseModel | null | undefined > {
        const response = await this.accountConfirmFromEmailUsingPOSTRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 201:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * addMemberAddress
     */
    async addMemberAddressUsingPOSTRaw(requestParameters: AddMemberAddressUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters['recipient'] == null) {
            throw new runtime.RequiredError(
                'recipient',
                'Required parameter "recipient" was null or undefined when calling addMemberAddressUsingPOST().'
            );
        }

        if (requestParameters['contactNumber'] == null) {
            throw new runtime.RequiredError(
                'contactNumber',
                'Required parameter "contactNumber" was null or undefined when calling addMemberAddressUsingPOST().'
            );
        }

        if (requestParameters['addressRow1'] == null) {
            throw new runtime.RequiredError(
                'addressRow1',
                'Required parameter "addressRow1" was null or undefined when calling addMemberAddressUsingPOST().'
            );
        }

        if (requestParameters['addressRow2'] == null) {
            throw new runtime.RequiredError(
                'addressRow2',
                'Required parameter "addressRow2" was null or undefined when calling addMemberAddressUsingPOST().'
            );
        }

        if (requestParameters['addressRow3'] == null) {
            throw new runtime.RequiredError(
                'addressRow3',
                'Required parameter "addressRow3" was null or undefined when calling addMemberAddressUsingPOST().'
            );
        }

        if (requestParameters['addressRow4'] == null) {
            throw new runtime.RequiredError(
                'addressRow4',
                'Required parameter "addressRow4" was null or undefined when calling addMemberAddressUsingPOST().'
            );
        }

        if (requestParameters['countryCode'] == null) {
            throw new runtime.RequiredError(
                'countryCode',
                'Required parameter "countryCode" was null or undefined when calling addMemberAddressUsingPOST().'
            );
        }

        if (requestParameters['phoneCountryId'] == null) {
            throw new runtime.RequiredError(
                'phoneCountryId',
                'Required parameter "phoneCountryId" was null or undefined when calling addMemberAddressUsingPOST().'
            );
        }

        if (requestParameters['addressProvince'] == null) {
            throw new runtime.RequiredError(
                'addressProvince',
                'Required parameter "addressProvince" was null or undefined when calling addMemberAddressUsingPOST().'
            );
        }

        if (requestParameters['addressCity'] == null) {
            throw new runtime.RequiredError(
                'addressCity',
                'Required parameter "addressCity" was null or undefined when calling addMemberAddressUsingPOST().'
            );
        }

        if (requestParameters['addressZone'] == null) {
            throw new runtime.RequiredError(
                'addressZone',
                'Required parameter "addressZone" was null or undefined when calling addMemberAddressUsingPOST().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        if (requestParameters['recipient'] != null) {
            queryParameters['recipient'] = requestParameters['recipient'];
        }

        if (requestParameters['contactNumber'] != null) {
            queryParameters['contactNumber'] = requestParameters['contactNumber'];
        }

        if (requestParameters['addressRow1'] != null) {
            queryParameters['addressRow1'] = requestParameters['addressRow1'];
        }

        if (requestParameters['addressRow2'] != null) {
            queryParameters['addressRow2'] = requestParameters['addressRow2'];
        }

        if (requestParameters['addressRow3'] != null) {
            queryParameters['addressRow3'] = requestParameters['addressRow3'];
        }

        if (requestParameters['addressRow4'] != null) {
            queryParameters['addressRow4'] = requestParameters['addressRow4'];
        }

        if (requestParameters['countryCode'] != null) {
            queryParameters['countryCode'] = requestParameters['countryCode'];
        }

        if (requestParameters['phoneCountryId'] != null) {
            queryParameters['phoneCountryId'] = requestParameters['phoneCountryId'];
        }

        if (requestParameters['addressProvince'] != null) {
            queryParameters['addressProvince'] = requestParameters['addressProvince'];
        }

        if (requestParameters['addressCity'] != null) {
            queryParameters['addressCity'] = requestParameters['addressCity'];
        }

        if (requestParameters['addressZone'] != null) {
            queryParameters['addressZone'] = requestParameters['addressZone'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/member/address`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * addMemberAddress
     */
    async addMemberAddressUsingPOST(requestParameters: AddMemberAddressUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string | null | undefined > {
        const response = await this.addMemberAddressUsingPOSTRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 201:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * addMemberFavourite
     */
    async addMemberFavouriteUsingPUTRaw(requestParameters: AddMemberFavouriteUsingPUTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters['productId'] == null) {
            throw new runtime.RequiredError(
                'productId',
                'Required parameter "productId" was null or undefined when calling addMemberFavouriteUsingPUT().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        if (requestParameters['productId'] != null) {
            queryParameters['productId'] = requestParameters['productId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/member/favourite`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * addMemberFavourite
     */
    async addMemberFavouriteUsingPUT(requestParameters: AddMemberFavouriteUsingPUTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string | null | undefined > {
        const response = await this.addMemberFavouriteUsingPUTRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 201:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * addMember
     */
    async addMemberUsingPOSTRaw(requestParameters: AddMemberUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SignUpMessageDto>> {
        if (requestParameters['phone'] == null) {
            throw new runtime.RequiredError(
                'phone',
                'Required parameter "phone" was null or undefined when calling addMemberUsingPOST().'
            );
        }

        if (requestParameters['firstname'] == null) {
            throw new runtime.RequiredError(
                'firstname',
                'Required parameter "firstname" was null or undefined when calling addMemberUsingPOST().'
            );
        }

        if (requestParameters['email'] == null) {
            throw new runtime.RequiredError(
                'email',
                'Required parameter "email" was null or undefined when calling addMemberUsingPOST().'
            );
        }

        if (requestParameters['gender'] == null) {
            throw new runtime.RequiredError(
                'gender',
                'Required parameter "gender" was null or undefined when calling addMemberUsingPOST().'
            );
        }

        if (requestParameters['birthday'] == null) {
            throw new runtime.RequiredError(
                'birthday',
                'Required parameter "birthday" was null or undefined when calling addMemberUsingPOST().'
            );
        }

        if (requestParameters['password'] == null) {
            throw new runtime.RequiredError(
                'password',
                'Required parameter "password" was null or undefined when calling addMemberUsingPOST().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['locationId'] != null) {
            queryParameters['locationId'] = requestParameters['locationId'];
        }

        if (requestParameters['phone'] != null) {
            queryParameters['phone'] = requestParameters['phone'];
        }

        if (requestParameters['verificationCode'] != null) {
            queryParameters['verificationCode'] = requestParameters['verificationCode'];
        }

        if (requestParameters['district'] != null) {
            queryParameters['district'] = requestParameters['district'];
        }

        if (requestParameters['firstname'] != null) {
            queryParameters['firstname'] = requestParameters['firstname'];
        }

        if (requestParameters['lastname'] != null) {
            queryParameters['lastname'] = requestParameters['lastname'];
        }

        if (requestParameters['email'] != null) {
            queryParameters['email'] = requestParameters['email'];
        }

        if (requestParameters['gender'] != null) {
            queryParameters['gender'] = requestParameters['gender'];
        }

        if (requestParameters['birthday'] != null) {
            queryParameters['birthday'] = requestParameters['birthday'];
        }

        if (requestParameters['ageGroup'] != null) {
            queryParameters['ageGroup'] = requestParameters['ageGroup'];
        }

        if (requestParameters['password'] != null) {
            queryParameters['password'] = requestParameters['password'];
        }

        if (requestParameters['agreement2'] != null) {
            queryParameters['agreement2'] = requestParameters['agreement2'];
        }

        if (requestParameters['acceptPromoption'] != null) {
            queryParameters['acceptPromoption'] = requestParameters['acceptPromoption'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/member/add`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SignUpMessageDtoFromJSON(jsonValue));
    }

    /**
     * addMember
     */
    async addMemberUsingPOST(requestParameters: AddMemberUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SignUpMessageDto | null | undefined > {
        const response = await this.addMemberUsingPOSTRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 201:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * addOauthMember2
     */
    async addOauthMember2UsingPOSTRaw(requestParameters: AddOauthMember2UsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GoogleVerifyResponseModel>> {
        if (requestParameters['credential'] == null) {
            throw new runtime.RequiredError(
                'credential',
                'Required parameter "credential" was null or undefined when calling addOauthMember2UsingPOST().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['credential'] != null) {
            queryParameters['credential'] = requestParameters['credential'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/member/addOauth2`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GoogleVerifyResponseModelFromJSON(jsonValue));
    }

    /**
     * addOauthMember2
     */
    async addOauthMember2UsingPOST(requestParameters: AddOauthMember2UsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GoogleVerifyResponseModel | null | undefined > {
        const response = await this.addOauthMember2UsingPOSTRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 201:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * addOauthMember
     */
    async addOauthMemberUsingPOSTRaw(requestParameters: AddOauthMemberUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SignUpMessageDto>> {
        if (requestParameters['locationId'] == null) {
            throw new runtime.RequiredError(
                'locationId',
                'Required parameter "locationId" was null or undefined when calling addOauthMemberUsingPOST().'
            );
        }

        if (requestParameters['phone'] == null) {
            throw new runtime.RequiredError(
                'phone',
                'Required parameter "phone" was null or undefined when calling addOauthMemberUsingPOST().'
            );
        }

        if (requestParameters['firstname'] == null) {
            throw new runtime.RequiredError(
                'firstname',
                'Required parameter "firstname" was null or undefined when calling addOauthMemberUsingPOST().'
            );
        }

        if (requestParameters['email'] == null) {
            throw new runtime.RequiredError(
                'email',
                'Required parameter "email" was null or undefined when calling addOauthMemberUsingPOST().'
            );
        }

        if (requestParameters['gender'] == null) {
            throw new runtime.RequiredError(
                'gender',
                'Required parameter "gender" was null or undefined when calling addOauthMemberUsingPOST().'
            );
        }

        if (requestParameters['birthday'] == null) {
            throw new runtime.RequiredError(
                'birthday',
                'Required parameter "birthday" was null or undefined when calling addOauthMemberUsingPOST().'
            );
        }

        if (requestParameters['googleId'] == null) {
            throw new runtime.RequiredError(
                'googleId',
                'Required parameter "googleId" was null or undefined when calling addOauthMemberUsingPOST().'
            );
        }

        if (requestParameters['facebookId'] == null) {
            throw new runtime.RequiredError(
                'facebookId',
                'Required parameter "facebookId" was null or undefined when calling addOauthMemberUsingPOST().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['locationId'] != null) {
            queryParameters['locationId'] = requestParameters['locationId'];
        }

        if (requestParameters['phone'] != null) {
            queryParameters['phone'] = requestParameters['phone'];
        }

        if (requestParameters['verificationCode'] != null) {
            queryParameters['verificationCode'] = requestParameters['verificationCode'];
        }

        if (requestParameters['firstname'] != null) {
            queryParameters['firstname'] = requestParameters['firstname'];
        }

        if (requestParameters['lastname'] != null) {
            queryParameters['lastname'] = requestParameters['lastname'];
        }

        if (requestParameters['email'] != null) {
            queryParameters['email'] = requestParameters['email'];
        }

        if (requestParameters['gender'] != null) {
            queryParameters['gender'] = requestParameters['gender'];
        }

        if (requestParameters['birthday'] != null) {
            queryParameters['birthday'] = requestParameters['birthday'];
        }

        if (requestParameters['ageGroup'] != null) {
            queryParameters['ageGroup'] = requestParameters['ageGroup'];
        }

        if (requestParameters['googleId'] != null) {
            queryParameters['googleId'] = requestParameters['googleId'];
        }

        if (requestParameters['facebookId'] != null) {
            queryParameters['facebookId'] = requestParameters['facebookId'];
        }

        if (requestParameters['acceptPromoption'] != null) {
            queryParameters['acceptPromoption'] = requestParameters['acceptPromoption'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/member/addOauth`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SignUpMessageDtoFromJSON(jsonValue));
    }

    /**
     * addOauthMember
     */
    async addOauthMemberUsingPOST(requestParameters: AddOauthMemberUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SignUpMessageDto | null | undefined > {
        const response = await this.addOauthMemberUsingPOSTRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 201:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * changeDefaultMemberAddress
     */
    async changeDefaultMemberAddressUsingPOSTRaw(requestParameters: ChangeDefaultMemberAddressUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        const queryParameters: any = {};

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        if (requestParameters['addressId'] != null) {
            queryParameters['addressId'] = requestParameters['addressId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/member/address/default`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * changeDefaultMemberAddress
     */
    async changeDefaultMemberAddressUsingPOST(requestParameters: ChangeDefaultMemberAddressUsingPOSTRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string | null | undefined > {
        const response = await this.changeDefaultMemberAddressUsingPOSTRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 201:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * checkEmail
     */
    async checkEmailUsingDELETERaw(requestParameters: CheckEmailUsingDELETERequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<boolean>> {
        if (requestParameters['email'] == null) {
            throw new runtime.RequiredError(
                'email',
                'Required parameter "email" was null or undefined when calling checkEmailUsingDELETE().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['email'] != null) {
            queryParameters['email'] = requestParameters['email'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/member/checkEmail`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<boolean>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * checkEmail
     */
    async checkEmailUsingDELETE(requestParameters: CheckEmailUsingDELETERequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<boolean | null | undefined > {
        const response = await this.checkEmailUsingDELETERaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 204:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * checkEmail
     */
    async checkEmailUsingGETRaw(requestParameters: CheckEmailUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<boolean>> {
        if (requestParameters['email'] == null) {
            throw new runtime.RequiredError(
                'email',
                'Required parameter "email" was null or undefined when calling checkEmailUsingGET().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['email'] != null) {
            queryParameters['email'] = requestParameters['email'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/member/checkEmail`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<boolean>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * checkEmail
     */
    async checkEmailUsingGET(requestParameters: CheckEmailUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<boolean> {
        const response = await this.checkEmailUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * checkEmail
     */
    async checkEmailUsingHEADRaw(requestParameters: CheckEmailUsingHEADRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<boolean>> {
        if (requestParameters['email'] == null) {
            throw new runtime.RequiredError(
                'email',
                'Required parameter "email" was null or undefined when calling checkEmailUsingHEAD().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['email'] != null) {
            queryParameters['email'] = requestParameters['email'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/member/checkEmail`,
            method: 'HEAD',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<boolean>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * checkEmail
     */
    async checkEmailUsingHEAD(requestParameters: CheckEmailUsingHEADRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<boolean | null | undefined > {
        const response = await this.checkEmailUsingHEADRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 204:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * checkEmail
     */
    async checkEmailUsingOPTIONSRaw(requestParameters: CheckEmailUsingOPTIONSRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<boolean>> {
        if (requestParameters['email'] == null) {
            throw new runtime.RequiredError(
                'email',
                'Required parameter "email" was null or undefined when calling checkEmailUsingOPTIONS().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['email'] != null) {
            queryParameters['email'] = requestParameters['email'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/member/checkEmail`,
            method: 'OPTIONS',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<boolean>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * checkEmail
     */
    async checkEmailUsingOPTIONS(requestParameters: CheckEmailUsingOPTIONSRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<boolean | null | undefined > {
        const response = await this.checkEmailUsingOPTIONSRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 204:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * checkEmail
     */
    async checkEmailUsingPATCHRaw(requestParameters: CheckEmailUsingPATCHRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<boolean>> {
        if (requestParameters['email'] == null) {
            throw new runtime.RequiredError(
                'email',
                'Required parameter "email" was null or undefined when calling checkEmailUsingPATCH().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['email'] != null) {
            queryParameters['email'] = requestParameters['email'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/member/checkEmail`,
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<boolean>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * checkEmail
     */
    async checkEmailUsingPATCH(requestParameters: CheckEmailUsingPATCHRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<boolean | null | undefined > {
        const response = await this.checkEmailUsingPATCHRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 204:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * checkEmail
     */
    async checkEmailUsingPOSTRaw(requestParameters: CheckEmailUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<boolean>> {
        if (requestParameters['email'] == null) {
            throw new runtime.RequiredError(
                'email',
                'Required parameter "email" was null or undefined when calling checkEmailUsingPOST().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['email'] != null) {
            queryParameters['email'] = requestParameters['email'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/member/checkEmail`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<boolean>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * checkEmail
     */
    async checkEmailUsingPOST(requestParameters: CheckEmailUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<boolean | null | undefined > {
        const response = await this.checkEmailUsingPOSTRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 201:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * checkEmail
     */
    async checkEmailUsingPUTRaw(requestParameters: CheckEmailUsingPUTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<boolean>> {
        if (requestParameters['email'] == null) {
            throw new runtime.RequiredError(
                'email',
                'Required parameter "email" was null or undefined when calling checkEmailUsingPUT().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['email'] != null) {
            queryParameters['email'] = requestParameters['email'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/member/checkEmail`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<boolean>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * checkEmail
     */
    async checkEmailUsingPUT(requestParameters: CheckEmailUsingPUTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<boolean | null | undefined > {
        const response = await this.checkEmailUsingPUTRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 201:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * checkEmail
     */
    async checkEmailUsingTRACERaw(requestParameters: CheckEmailUsingTRACERequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<boolean>> {
        if (requestParameters['email'] == null) {
            throw new runtime.RequiredError(
                'email',
                'Required parameter "email" was null or undefined when calling checkEmailUsingTRACE().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['email'] != null) {
            queryParameters['email'] = requestParameters['email'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/member/checkEmail`,
            method: 'TRACE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<boolean>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * checkEmail
     */
    async checkEmailUsingTRACE(requestParameters: CheckEmailUsingTRACERequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<boolean | null | undefined > {
        const response = await this.checkEmailUsingTRACERaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 204:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * checkFacebookId
     */
    async checkFacebookIdUsingDELETERaw(requestParameters: CheckFacebookIdUsingDELETERequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<boolean>> {
        if (requestParameters['facebookId'] == null) {
            throw new runtime.RequiredError(
                'facebookId',
                'Required parameter "facebookId" was null or undefined when calling checkFacebookIdUsingDELETE().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['facebookId'] != null) {
            queryParameters['facebookId'] = requestParameters['facebookId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/member/checkFacebookId`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<boolean>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * checkFacebookId
     */
    async checkFacebookIdUsingDELETE(requestParameters: CheckFacebookIdUsingDELETERequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<boolean | null | undefined > {
        const response = await this.checkFacebookIdUsingDELETERaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 204:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * checkFacebookId
     */
    async checkFacebookIdUsingGETRaw(requestParameters: CheckFacebookIdUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<boolean>> {
        if (requestParameters['facebookId'] == null) {
            throw new runtime.RequiredError(
                'facebookId',
                'Required parameter "facebookId" was null or undefined when calling checkFacebookIdUsingGET().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['facebookId'] != null) {
            queryParameters['facebookId'] = requestParameters['facebookId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/member/checkFacebookId`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<boolean>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * checkFacebookId
     */
    async checkFacebookIdUsingGET(requestParameters: CheckFacebookIdUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<boolean> {
        const response = await this.checkFacebookIdUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * checkFacebookId
     */
    async checkFacebookIdUsingHEADRaw(requestParameters: CheckFacebookIdUsingHEADRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<boolean>> {
        if (requestParameters['facebookId'] == null) {
            throw new runtime.RequiredError(
                'facebookId',
                'Required parameter "facebookId" was null or undefined when calling checkFacebookIdUsingHEAD().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['facebookId'] != null) {
            queryParameters['facebookId'] = requestParameters['facebookId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/member/checkFacebookId`,
            method: 'HEAD',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<boolean>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * checkFacebookId
     */
    async checkFacebookIdUsingHEAD(requestParameters: CheckFacebookIdUsingHEADRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<boolean | null | undefined > {
        const response = await this.checkFacebookIdUsingHEADRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 204:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * checkFacebookId
     */
    async checkFacebookIdUsingOPTIONSRaw(requestParameters: CheckFacebookIdUsingOPTIONSRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<boolean>> {
        if (requestParameters['facebookId'] == null) {
            throw new runtime.RequiredError(
                'facebookId',
                'Required parameter "facebookId" was null or undefined when calling checkFacebookIdUsingOPTIONS().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['facebookId'] != null) {
            queryParameters['facebookId'] = requestParameters['facebookId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/member/checkFacebookId`,
            method: 'OPTIONS',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<boolean>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * checkFacebookId
     */
    async checkFacebookIdUsingOPTIONS(requestParameters: CheckFacebookIdUsingOPTIONSRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<boolean | null | undefined > {
        const response = await this.checkFacebookIdUsingOPTIONSRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 204:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * checkFacebookId
     */
    async checkFacebookIdUsingPATCHRaw(requestParameters: CheckFacebookIdUsingPATCHRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<boolean>> {
        if (requestParameters['facebookId'] == null) {
            throw new runtime.RequiredError(
                'facebookId',
                'Required parameter "facebookId" was null or undefined when calling checkFacebookIdUsingPATCH().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['facebookId'] != null) {
            queryParameters['facebookId'] = requestParameters['facebookId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/member/checkFacebookId`,
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<boolean>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * checkFacebookId
     */
    async checkFacebookIdUsingPATCH(requestParameters: CheckFacebookIdUsingPATCHRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<boolean | null | undefined > {
        const response = await this.checkFacebookIdUsingPATCHRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 204:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * checkFacebookId
     */
    async checkFacebookIdUsingPOSTRaw(requestParameters: CheckFacebookIdUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<boolean>> {
        if (requestParameters['facebookId'] == null) {
            throw new runtime.RequiredError(
                'facebookId',
                'Required parameter "facebookId" was null or undefined when calling checkFacebookIdUsingPOST().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['facebookId'] != null) {
            queryParameters['facebookId'] = requestParameters['facebookId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/member/checkFacebookId`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<boolean>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * checkFacebookId
     */
    async checkFacebookIdUsingPOST(requestParameters: CheckFacebookIdUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<boolean | null | undefined > {
        const response = await this.checkFacebookIdUsingPOSTRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 201:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * checkFacebookId
     */
    async checkFacebookIdUsingPUTRaw(requestParameters: CheckFacebookIdUsingPUTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<boolean>> {
        if (requestParameters['facebookId'] == null) {
            throw new runtime.RequiredError(
                'facebookId',
                'Required parameter "facebookId" was null or undefined when calling checkFacebookIdUsingPUT().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['facebookId'] != null) {
            queryParameters['facebookId'] = requestParameters['facebookId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/member/checkFacebookId`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<boolean>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * checkFacebookId
     */
    async checkFacebookIdUsingPUT(requestParameters: CheckFacebookIdUsingPUTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<boolean | null | undefined > {
        const response = await this.checkFacebookIdUsingPUTRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 201:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * checkFacebookId
     */
    async checkFacebookIdUsingTRACERaw(requestParameters: CheckFacebookIdUsingTRACERequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<boolean>> {
        if (requestParameters['facebookId'] == null) {
            throw new runtime.RequiredError(
                'facebookId',
                'Required parameter "facebookId" was null or undefined when calling checkFacebookIdUsingTRACE().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['facebookId'] != null) {
            queryParameters['facebookId'] = requestParameters['facebookId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/member/checkFacebookId`,
            method: 'TRACE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<boolean>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * checkFacebookId
     */
    async checkFacebookIdUsingTRACE(requestParameters: CheckFacebookIdUsingTRACERequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<boolean | null | undefined > {
        const response = await this.checkFacebookIdUsingTRACERaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 204:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * checkGoogleId
     */
    async checkGoogleIdUsingDELETERaw(requestParameters: CheckGoogleIdUsingDELETERequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<boolean>> {
        if (requestParameters['googleId'] == null) {
            throw new runtime.RequiredError(
                'googleId',
                'Required parameter "googleId" was null or undefined when calling checkGoogleIdUsingDELETE().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['googleId'] != null) {
            queryParameters['googleId'] = requestParameters['googleId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/member/checkGoogleId`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<boolean>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * checkGoogleId
     */
    async checkGoogleIdUsingDELETE(requestParameters: CheckGoogleIdUsingDELETERequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<boolean | null | undefined > {
        const response = await this.checkGoogleIdUsingDELETERaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 204:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * checkGoogleId
     */
    async checkGoogleIdUsingGETRaw(requestParameters: CheckGoogleIdUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<boolean>> {
        if (requestParameters['googleId'] == null) {
            throw new runtime.RequiredError(
                'googleId',
                'Required parameter "googleId" was null or undefined when calling checkGoogleIdUsingGET().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['googleId'] != null) {
            queryParameters['googleId'] = requestParameters['googleId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/member/checkGoogleId`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<boolean>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * checkGoogleId
     */
    async checkGoogleIdUsingGET(requestParameters: CheckGoogleIdUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<boolean> {
        const response = await this.checkGoogleIdUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * checkGoogleId
     */
    async checkGoogleIdUsingHEADRaw(requestParameters: CheckGoogleIdUsingHEADRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<boolean>> {
        if (requestParameters['googleId'] == null) {
            throw new runtime.RequiredError(
                'googleId',
                'Required parameter "googleId" was null or undefined when calling checkGoogleIdUsingHEAD().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['googleId'] != null) {
            queryParameters['googleId'] = requestParameters['googleId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/member/checkGoogleId`,
            method: 'HEAD',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<boolean>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * checkGoogleId
     */
    async checkGoogleIdUsingHEAD(requestParameters: CheckGoogleIdUsingHEADRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<boolean | null | undefined > {
        const response = await this.checkGoogleIdUsingHEADRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 204:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * checkGoogleId
     */
    async checkGoogleIdUsingOPTIONSRaw(requestParameters: CheckGoogleIdUsingOPTIONSRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<boolean>> {
        if (requestParameters['googleId'] == null) {
            throw new runtime.RequiredError(
                'googleId',
                'Required parameter "googleId" was null or undefined when calling checkGoogleIdUsingOPTIONS().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['googleId'] != null) {
            queryParameters['googleId'] = requestParameters['googleId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/member/checkGoogleId`,
            method: 'OPTIONS',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<boolean>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * checkGoogleId
     */
    async checkGoogleIdUsingOPTIONS(requestParameters: CheckGoogleIdUsingOPTIONSRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<boolean | null | undefined > {
        const response = await this.checkGoogleIdUsingOPTIONSRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 204:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * checkGoogleId
     */
    async checkGoogleIdUsingPATCHRaw(requestParameters: CheckGoogleIdUsingPATCHRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<boolean>> {
        if (requestParameters['googleId'] == null) {
            throw new runtime.RequiredError(
                'googleId',
                'Required parameter "googleId" was null or undefined when calling checkGoogleIdUsingPATCH().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['googleId'] != null) {
            queryParameters['googleId'] = requestParameters['googleId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/member/checkGoogleId`,
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<boolean>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * checkGoogleId
     */
    async checkGoogleIdUsingPATCH(requestParameters: CheckGoogleIdUsingPATCHRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<boolean | null | undefined > {
        const response = await this.checkGoogleIdUsingPATCHRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 204:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * checkGoogleId
     */
    async checkGoogleIdUsingPOSTRaw(requestParameters: CheckGoogleIdUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<boolean>> {
        if (requestParameters['googleId'] == null) {
            throw new runtime.RequiredError(
                'googleId',
                'Required parameter "googleId" was null or undefined when calling checkGoogleIdUsingPOST().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['googleId'] != null) {
            queryParameters['googleId'] = requestParameters['googleId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/member/checkGoogleId`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<boolean>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * checkGoogleId
     */
    async checkGoogleIdUsingPOST(requestParameters: CheckGoogleIdUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<boolean | null | undefined > {
        const response = await this.checkGoogleIdUsingPOSTRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 201:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * checkGoogleId
     */
    async checkGoogleIdUsingPUTRaw(requestParameters: CheckGoogleIdUsingPUTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<boolean>> {
        if (requestParameters['googleId'] == null) {
            throw new runtime.RequiredError(
                'googleId',
                'Required parameter "googleId" was null or undefined when calling checkGoogleIdUsingPUT().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['googleId'] != null) {
            queryParameters['googleId'] = requestParameters['googleId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/member/checkGoogleId`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<boolean>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * checkGoogleId
     */
    async checkGoogleIdUsingPUT(requestParameters: CheckGoogleIdUsingPUTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<boolean | null | undefined > {
        const response = await this.checkGoogleIdUsingPUTRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 201:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * checkGoogleId
     */
    async checkGoogleIdUsingTRACERaw(requestParameters: CheckGoogleIdUsingTRACERequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<boolean>> {
        if (requestParameters['googleId'] == null) {
            throw new runtime.RequiredError(
                'googleId',
                'Required parameter "googleId" was null or undefined when calling checkGoogleIdUsingTRACE().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['googleId'] != null) {
            queryParameters['googleId'] = requestParameters['googleId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/member/checkGoogleId`,
            method: 'TRACE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<boolean>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * checkGoogleId
     */
    async checkGoogleIdUsingTRACE(requestParameters: CheckGoogleIdUsingTRACERequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<boolean | null | undefined > {
        const response = await this.checkGoogleIdUsingTRACERaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 204:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * deleteMemberAddress
     */
    async deleteMemberAddressUsingDELETERaw(requestParameters: DeleteMemberAddressUsingDELETERequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        const queryParameters: any = {};

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        if (requestParameters['addressId'] != null) {
            queryParameters['addressId'] = requestParameters['addressId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/member/address`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * deleteMemberAddress
     */
    async deleteMemberAddressUsingDELETE(requestParameters: DeleteMemberAddressUsingDELETERequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string | null | undefined > {
        const response = await this.deleteMemberAddressUsingDELETERaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 204:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * deleteMemberFavourite
     */
    async deleteMemberFavouriteUsingDELETERaw(requestParameters: DeleteMemberFavouriteUsingDELETERequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        const queryParameters: any = {};

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        if (requestParameters['productId'] != null) {
            queryParameters['productId'] = requestParameters['productId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/member/favourite`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * deleteMemberFavourite
     */
    async deleteMemberFavouriteUsingDELETE(requestParameters: DeleteMemberFavouriteUsingDELETERequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string | null | undefined > {
        const response = await this.deleteMemberFavouriteUsingDELETERaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 204:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * getAllMember
     */
    async getAllMemberUsingGET1Raw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<MemberDto>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/member/all`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(MemberDtoFromJSON));
    }

    /**
     * getAllMember
     */
    async getAllMemberUsingGET1(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<MemberDto>> {
        const response = await this.getAllMemberUsingGET1Raw(initOverrides);
        return await response.value();
    }

    /**
     * getDefaultMemberAddress
     */
    async getDefaultMemberAddressUsingGETRaw(requestParameters: GetDefaultMemberAddressUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AddressDto>> {
        const queryParameters: any = {};

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/member/address/default`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AddressDtoFromJSON(jsonValue));
    }

    /**
     * getDefaultMemberAddress
     */
    async getDefaultMemberAddressUsingGET(requestParameters: GetDefaultMemberAddressUsingGETRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AddressDto> {
        const response = await this.getDefaultMemberAddressUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getMemberAddress
     */
    async getMemberAddressUsingGETRaw(requestParameters: GetMemberAddressUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<AddressDto>>> {
        const queryParameters: any = {};

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/member/address`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(AddressDtoFromJSON));
    }

    /**
     * getMemberAddress
     */
    async getMemberAddressUsingGET(requestParameters: GetMemberAddressUsingGETRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<AddressDto>> {
        const response = await this.getMemberAddressUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getMemberFavourites
     */
    async getMemberFavouritesUsingGETRaw(requestParameters: GetMemberFavouritesUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<DisplayProductDto>>> {
        const queryParameters: any = {};

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/member/favourite`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(DisplayProductDtoFromJSON));
    }

    /**
     * getMemberFavourites
     */
    async getMemberFavouritesUsingGET(requestParameters: GetMemberFavouritesUsingGETRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<DisplayProductDto>> {
        const response = await this.getMemberFavouritesUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getMember
     */
    async getMemberUsingGETRaw(requestParameters: GetMemberUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MemberDto>> {
        if (requestParameters['memberId'] == null) {
            throw new runtime.RequiredError(
                'memberId',
                'Required parameter "memberId" was null or undefined when calling getMemberUsingGET().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/member/{memberId}`.replace(`{${"memberId"}}`, encodeURIComponent(String(requestParameters['memberId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MemberDtoFromJSON(jsonValue));
    }

    /**
     * getMember
     */
    async getMemberUsingGET(requestParameters: GetMemberUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MemberDto> {
        const response = await this.getMemberUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getMember
     */
    async getMemberUsingGET1Raw(requestParameters: GetMemberUsingGET1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MemberDto>> {
        const queryParameters: any = {};

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/member/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MemberDtoFromJSON(jsonValue));
    }

    /**
     * getMember
     */
    async getMemberUsingGET1(requestParameters: GetMemberUsingGET1Request = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MemberDto> {
        const response = await this.getMemberUsingGET1Raw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getTokenByFacebookId
     */
    async getTokenByFacebookIdUsingDELETERaw(requestParameters: GetTokenByFacebookIdUsingDELETERequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters['facebookId'] == null) {
            throw new runtime.RequiredError(
                'facebookId',
                'Required parameter "facebookId" was null or undefined when calling getTokenByFacebookIdUsingDELETE().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['facebookId'] != null) {
            queryParameters['facebookId'] = requestParameters['facebookId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/member/getTokenByFacebookId`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * getTokenByFacebookId
     */
    async getTokenByFacebookIdUsingDELETE(requestParameters: GetTokenByFacebookIdUsingDELETERequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string | null | undefined > {
        const response = await this.getTokenByFacebookIdUsingDELETERaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 204:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * getTokenByFacebookId
     */
    async getTokenByFacebookIdUsingGETRaw(requestParameters: GetTokenByFacebookIdUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters['facebookId'] == null) {
            throw new runtime.RequiredError(
                'facebookId',
                'Required parameter "facebookId" was null or undefined when calling getTokenByFacebookIdUsingGET().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['facebookId'] != null) {
            queryParameters['facebookId'] = requestParameters['facebookId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/member/getTokenByFacebookId`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * getTokenByFacebookId
     */
    async getTokenByFacebookIdUsingGET(requestParameters: GetTokenByFacebookIdUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.getTokenByFacebookIdUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getTokenByFacebookId
     */
    async getTokenByFacebookIdUsingHEADRaw(requestParameters: GetTokenByFacebookIdUsingHEADRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters['facebookId'] == null) {
            throw new runtime.RequiredError(
                'facebookId',
                'Required parameter "facebookId" was null or undefined when calling getTokenByFacebookIdUsingHEAD().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['facebookId'] != null) {
            queryParameters['facebookId'] = requestParameters['facebookId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/member/getTokenByFacebookId`,
            method: 'HEAD',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * getTokenByFacebookId
     */
    async getTokenByFacebookIdUsingHEAD(requestParameters: GetTokenByFacebookIdUsingHEADRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string | null | undefined > {
        const response = await this.getTokenByFacebookIdUsingHEADRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 204:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * getTokenByFacebookId
     */
    async getTokenByFacebookIdUsingOPTIONSRaw(requestParameters: GetTokenByFacebookIdUsingOPTIONSRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters['facebookId'] == null) {
            throw new runtime.RequiredError(
                'facebookId',
                'Required parameter "facebookId" was null or undefined when calling getTokenByFacebookIdUsingOPTIONS().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['facebookId'] != null) {
            queryParameters['facebookId'] = requestParameters['facebookId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/member/getTokenByFacebookId`,
            method: 'OPTIONS',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * getTokenByFacebookId
     */
    async getTokenByFacebookIdUsingOPTIONS(requestParameters: GetTokenByFacebookIdUsingOPTIONSRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string | null | undefined > {
        const response = await this.getTokenByFacebookIdUsingOPTIONSRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 204:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * getTokenByFacebookId
     */
    async getTokenByFacebookIdUsingPATCHRaw(requestParameters: GetTokenByFacebookIdUsingPATCHRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters['facebookId'] == null) {
            throw new runtime.RequiredError(
                'facebookId',
                'Required parameter "facebookId" was null or undefined when calling getTokenByFacebookIdUsingPATCH().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['facebookId'] != null) {
            queryParameters['facebookId'] = requestParameters['facebookId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/member/getTokenByFacebookId`,
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * getTokenByFacebookId
     */
    async getTokenByFacebookIdUsingPATCH(requestParameters: GetTokenByFacebookIdUsingPATCHRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string | null | undefined > {
        const response = await this.getTokenByFacebookIdUsingPATCHRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 204:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * getTokenByFacebookId
     */
    async getTokenByFacebookIdUsingPOSTRaw(requestParameters: GetTokenByFacebookIdUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters['facebookId'] == null) {
            throw new runtime.RequiredError(
                'facebookId',
                'Required parameter "facebookId" was null or undefined when calling getTokenByFacebookIdUsingPOST().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['facebookId'] != null) {
            queryParameters['facebookId'] = requestParameters['facebookId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/member/getTokenByFacebookId`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * getTokenByFacebookId
     */
    async getTokenByFacebookIdUsingPOST(requestParameters: GetTokenByFacebookIdUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string | null | undefined > {
        const response = await this.getTokenByFacebookIdUsingPOSTRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 201:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * getTokenByFacebookId
     */
    async getTokenByFacebookIdUsingPUTRaw(requestParameters: GetTokenByFacebookIdUsingPUTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters['facebookId'] == null) {
            throw new runtime.RequiredError(
                'facebookId',
                'Required parameter "facebookId" was null or undefined when calling getTokenByFacebookIdUsingPUT().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['facebookId'] != null) {
            queryParameters['facebookId'] = requestParameters['facebookId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/member/getTokenByFacebookId`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * getTokenByFacebookId
     */
    async getTokenByFacebookIdUsingPUT(requestParameters: GetTokenByFacebookIdUsingPUTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string | null | undefined > {
        const response = await this.getTokenByFacebookIdUsingPUTRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 201:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * getTokenByFacebookId
     */
    async getTokenByFacebookIdUsingTRACERaw(requestParameters: GetTokenByFacebookIdUsingTRACERequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters['facebookId'] == null) {
            throw new runtime.RequiredError(
                'facebookId',
                'Required parameter "facebookId" was null or undefined when calling getTokenByFacebookIdUsingTRACE().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['facebookId'] != null) {
            queryParameters['facebookId'] = requestParameters['facebookId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/member/getTokenByFacebookId`,
            method: 'TRACE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * getTokenByFacebookId
     */
    async getTokenByFacebookIdUsingTRACE(requestParameters: GetTokenByFacebookIdUsingTRACERequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string | null | undefined > {
        const response = await this.getTokenByFacebookIdUsingTRACERaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 204:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * getTokenByGoogleId
     */
    async getTokenByGoogleIdUsingDELETERaw(requestParameters: GetTokenByGoogleIdUsingDELETERequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters['googleId'] == null) {
            throw new runtime.RequiredError(
                'googleId',
                'Required parameter "googleId" was null or undefined when calling getTokenByGoogleIdUsingDELETE().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['googleId'] != null) {
            queryParameters['googleId'] = requestParameters['googleId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/member/getTokenByGoogleId`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * getTokenByGoogleId
     */
    async getTokenByGoogleIdUsingDELETE(requestParameters: GetTokenByGoogleIdUsingDELETERequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string | null | undefined > {
        const response = await this.getTokenByGoogleIdUsingDELETERaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 204:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * getTokenByGoogleId
     */
    async getTokenByGoogleIdUsingGETRaw(requestParameters: GetTokenByGoogleIdUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters['googleId'] == null) {
            throw new runtime.RequiredError(
                'googleId',
                'Required parameter "googleId" was null or undefined when calling getTokenByGoogleIdUsingGET().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['googleId'] != null) {
            queryParameters['googleId'] = requestParameters['googleId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/member/getTokenByGoogleId`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * getTokenByGoogleId
     */
    async getTokenByGoogleIdUsingGET(requestParameters: GetTokenByGoogleIdUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.getTokenByGoogleIdUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getTokenByGoogleId
     */
    async getTokenByGoogleIdUsingHEADRaw(requestParameters: GetTokenByGoogleIdUsingHEADRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters['googleId'] == null) {
            throw new runtime.RequiredError(
                'googleId',
                'Required parameter "googleId" was null or undefined when calling getTokenByGoogleIdUsingHEAD().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['googleId'] != null) {
            queryParameters['googleId'] = requestParameters['googleId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/member/getTokenByGoogleId`,
            method: 'HEAD',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * getTokenByGoogleId
     */
    async getTokenByGoogleIdUsingHEAD(requestParameters: GetTokenByGoogleIdUsingHEADRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string | null | undefined > {
        const response = await this.getTokenByGoogleIdUsingHEADRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 204:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * getTokenByGoogleId
     */
    async getTokenByGoogleIdUsingOPTIONSRaw(requestParameters: GetTokenByGoogleIdUsingOPTIONSRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters['googleId'] == null) {
            throw new runtime.RequiredError(
                'googleId',
                'Required parameter "googleId" was null or undefined when calling getTokenByGoogleIdUsingOPTIONS().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['googleId'] != null) {
            queryParameters['googleId'] = requestParameters['googleId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/member/getTokenByGoogleId`,
            method: 'OPTIONS',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * getTokenByGoogleId
     */
    async getTokenByGoogleIdUsingOPTIONS(requestParameters: GetTokenByGoogleIdUsingOPTIONSRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string | null | undefined > {
        const response = await this.getTokenByGoogleIdUsingOPTIONSRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 204:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * getTokenByGoogleId
     */
    async getTokenByGoogleIdUsingPATCHRaw(requestParameters: GetTokenByGoogleIdUsingPATCHRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters['googleId'] == null) {
            throw new runtime.RequiredError(
                'googleId',
                'Required parameter "googleId" was null or undefined when calling getTokenByGoogleIdUsingPATCH().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['googleId'] != null) {
            queryParameters['googleId'] = requestParameters['googleId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/member/getTokenByGoogleId`,
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * getTokenByGoogleId
     */
    async getTokenByGoogleIdUsingPATCH(requestParameters: GetTokenByGoogleIdUsingPATCHRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string | null | undefined > {
        const response = await this.getTokenByGoogleIdUsingPATCHRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 204:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * getTokenByGoogleId
     */
    async getTokenByGoogleIdUsingPOSTRaw(requestParameters: GetTokenByGoogleIdUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters['googleId'] == null) {
            throw new runtime.RequiredError(
                'googleId',
                'Required parameter "googleId" was null or undefined when calling getTokenByGoogleIdUsingPOST().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['googleId'] != null) {
            queryParameters['googleId'] = requestParameters['googleId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/member/getTokenByGoogleId`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * getTokenByGoogleId
     */
    async getTokenByGoogleIdUsingPOST(requestParameters: GetTokenByGoogleIdUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string | null | undefined > {
        const response = await this.getTokenByGoogleIdUsingPOSTRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 201:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * getTokenByGoogleId
     */
    async getTokenByGoogleIdUsingPUTRaw(requestParameters: GetTokenByGoogleIdUsingPUTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters['googleId'] == null) {
            throw new runtime.RequiredError(
                'googleId',
                'Required parameter "googleId" was null or undefined when calling getTokenByGoogleIdUsingPUT().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['googleId'] != null) {
            queryParameters['googleId'] = requestParameters['googleId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/member/getTokenByGoogleId`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * getTokenByGoogleId
     */
    async getTokenByGoogleIdUsingPUT(requestParameters: GetTokenByGoogleIdUsingPUTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string | null | undefined > {
        const response = await this.getTokenByGoogleIdUsingPUTRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 201:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * getTokenByGoogleId
     */
    async getTokenByGoogleIdUsingTRACERaw(requestParameters: GetTokenByGoogleIdUsingTRACERequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters['googleId'] == null) {
            throw new runtime.RequiredError(
                'googleId',
                'Required parameter "googleId" was null or undefined when calling getTokenByGoogleIdUsingTRACE().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['googleId'] != null) {
            queryParameters['googleId'] = requestParameters['googleId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/member/getTokenByGoogleId`,
            method: 'TRACE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * getTokenByGoogleId
     */
    async getTokenByGoogleIdUsingTRACE(requestParameters: GetTokenByGoogleIdUsingTRACERequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string | null | undefined > {
        const response = await this.getTokenByGoogleIdUsingTRACERaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 204:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * isValidEmail
     */
    async isValidEmailUsingDELETERaw(requestParameters: IsValidEmailUsingDELETERequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<boolean>> {
        if (requestParameters['email'] == null) {
            throw new runtime.RequiredError(
                'email',
                'Required parameter "email" was null or undefined when calling isValidEmailUsingDELETE().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['email'] != null) {
            queryParameters['email'] = requestParameters['email'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/member/isValid`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<boolean>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * isValidEmail
     */
    async isValidEmailUsingDELETE(requestParameters: IsValidEmailUsingDELETERequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<boolean | null | undefined > {
        const response = await this.isValidEmailUsingDELETERaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 204:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * isValidEmail
     */
    async isValidEmailUsingGETRaw(requestParameters: IsValidEmailUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<boolean>> {
        if (requestParameters['email'] == null) {
            throw new runtime.RequiredError(
                'email',
                'Required parameter "email" was null or undefined when calling isValidEmailUsingGET().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['email'] != null) {
            queryParameters['email'] = requestParameters['email'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/member/isValid`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<boolean>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * isValidEmail
     */
    async isValidEmailUsingGET(requestParameters: IsValidEmailUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<boolean> {
        const response = await this.isValidEmailUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * isValidEmail
     */
    async isValidEmailUsingHEADRaw(requestParameters: IsValidEmailUsingHEADRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<boolean>> {
        if (requestParameters['email'] == null) {
            throw new runtime.RequiredError(
                'email',
                'Required parameter "email" was null or undefined when calling isValidEmailUsingHEAD().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['email'] != null) {
            queryParameters['email'] = requestParameters['email'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/member/isValid`,
            method: 'HEAD',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<boolean>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * isValidEmail
     */
    async isValidEmailUsingHEAD(requestParameters: IsValidEmailUsingHEADRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<boolean | null | undefined > {
        const response = await this.isValidEmailUsingHEADRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 204:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * isValidEmail
     */
    async isValidEmailUsingOPTIONSRaw(requestParameters: IsValidEmailUsingOPTIONSRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<boolean>> {
        if (requestParameters['email'] == null) {
            throw new runtime.RequiredError(
                'email',
                'Required parameter "email" was null or undefined when calling isValidEmailUsingOPTIONS().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['email'] != null) {
            queryParameters['email'] = requestParameters['email'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/member/isValid`,
            method: 'OPTIONS',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<boolean>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * isValidEmail
     */
    async isValidEmailUsingOPTIONS(requestParameters: IsValidEmailUsingOPTIONSRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<boolean | null | undefined > {
        const response = await this.isValidEmailUsingOPTIONSRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 204:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * isValidEmail
     */
    async isValidEmailUsingPATCHRaw(requestParameters: IsValidEmailUsingPATCHRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<boolean>> {
        if (requestParameters['email'] == null) {
            throw new runtime.RequiredError(
                'email',
                'Required parameter "email" was null or undefined when calling isValidEmailUsingPATCH().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['email'] != null) {
            queryParameters['email'] = requestParameters['email'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/member/isValid`,
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<boolean>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * isValidEmail
     */
    async isValidEmailUsingPATCH(requestParameters: IsValidEmailUsingPATCHRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<boolean | null | undefined > {
        const response = await this.isValidEmailUsingPATCHRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 204:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * isValidEmail
     */
    async isValidEmailUsingPOSTRaw(requestParameters: IsValidEmailUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<boolean>> {
        if (requestParameters['email'] == null) {
            throw new runtime.RequiredError(
                'email',
                'Required parameter "email" was null or undefined when calling isValidEmailUsingPOST().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['email'] != null) {
            queryParameters['email'] = requestParameters['email'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/member/isValid`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<boolean>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * isValidEmail
     */
    async isValidEmailUsingPOST(requestParameters: IsValidEmailUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<boolean | null | undefined > {
        const response = await this.isValidEmailUsingPOSTRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 201:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * isValidEmail
     */
    async isValidEmailUsingPUTRaw(requestParameters: IsValidEmailUsingPUTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<boolean>> {
        if (requestParameters['email'] == null) {
            throw new runtime.RequiredError(
                'email',
                'Required parameter "email" was null or undefined when calling isValidEmailUsingPUT().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['email'] != null) {
            queryParameters['email'] = requestParameters['email'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/member/isValid`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<boolean>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * isValidEmail
     */
    async isValidEmailUsingPUT(requestParameters: IsValidEmailUsingPUTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<boolean | null | undefined > {
        const response = await this.isValidEmailUsingPUTRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 201:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * isValidEmail
     */
    async isValidEmailUsingTRACERaw(requestParameters: IsValidEmailUsingTRACERequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<boolean>> {
        if (requestParameters['email'] == null) {
            throw new runtime.RequiredError(
                'email',
                'Required parameter "email" was null or undefined when calling isValidEmailUsingTRACE().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['email'] != null) {
            queryParameters['email'] = requestParameters['email'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/member/isValid`,
            method: 'TRACE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<boolean>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * isValidEmail
     */
    async isValidEmailUsingTRACE(requestParameters: IsValidEmailUsingTRACERequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<boolean | null | undefined > {
        const response = await this.isValidEmailUsingTRACERaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 204:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * refreshToken
     */
    async refreshTokenUsingDELETERaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/member/refreshToken`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * refreshToken
     */
    async refreshTokenUsingDELETE(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string | null | undefined > {
        const response = await this.refreshTokenUsingDELETERaw(initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 204:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * refreshToken
     */
    async refreshTokenUsingGETRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/member/refreshToken`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * refreshToken
     */
    async refreshTokenUsingGET(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.refreshTokenUsingGETRaw(initOverrides);
        return await response.value();
    }

    /**
     * refreshToken
     */
    async refreshTokenUsingHEADRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/member/refreshToken`,
            method: 'HEAD',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * refreshToken
     */
    async refreshTokenUsingHEAD(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string | null | undefined > {
        const response = await this.refreshTokenUsingHEADRaw(initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 204:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * refreshToken
     */
    async refreshTokenUsingOPTIONSRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/member/refreshToken`,
            method: 'OPTIONS',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * refreshToken
     */
    async refreshTokenUsingOPTIONS(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string | null | undefined > {
        const response = await this.refreshTokenUsingOPTIONSRaw(initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 204:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * refreshToken
     */
    async refreshTokenUsingPATCHRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/member/refreshToken`,
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * refreshToken
     */
    async refreshTokenUsingPATCH(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string | null | undefined > {
        const response = await this.refreshTokenUsingPATCHRaw(initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 204:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * refreshToken
     */
    async refreshTokenUsingPOSTRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/member/refreshToken`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * refreshToken
     */
    async refreshTokenUsingPOST(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string | null | undefined > {
        const response = await this.refreshTokenUsingPOSTRaw(initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 201:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * refreshToken
     */
    async refreshTokenUsingPUTRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/member/refreshToken`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * refreshToken
     */
    async refreshTokenUsingPUT(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string | null | undefined > {
        const response = await this.refreshTokenUsingPUTRaw(initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 201:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * refreshToken
     */
    async refreshTokenUsingTRACERaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/member/refreshToken`,
            method: 'TRACE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * refreshToken
     */
    async refreshTokenUsingTRACE(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string | null | undefined > {
        const response = await this.refreshTokenUsingTRACERaw(initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 204:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * resetPasswordEmail
     */
    async resetPasswordEmailUsingPOSTRaw(requestParameters: ResetPasswordEmailUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SuccessResponseModel>> {
        if (requestParameters['email'] == null) {
            throw new runtime.RequiredError(
                'email',
                'Required parameter "email" was null or undefined when calling resetPasswordEmailUsingPOST().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['email'] != null) {
            queryParameters['email'] = requestParameters['email'];
        }

        if (requestParameters['reset'] != null) {
            queryParameters['reset'] = requestParameters['reset'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/member/reset-password/email`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SuccessResponseModelFromJSON(jsonValue));
    }

    /**
     * resetPasswordEmail
     */
    async resetPasswordEmailUsingPOST(requestParameters: ResetPasswordEmailUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SuccessResponseModel | null | undefined > {
        const response = await this.resetPasswordEmailUsingPOSTRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 201:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * resetPasswordSetMemberPassword
     */
    async resetPasswordSetMemberPasswordUsingPOSTRaw(requestParameters: ResetPasswordSetMemberPasswordUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SuccessResponseModel>> {
        if (requestParameters['email'] == null) {
            throw new runtime.RequiredError(
                'email',
                'Required parameter "email" was null or undefined when calling resetPasswordSetMemberPasswordUsingPOST().'
            );
        }

        if (requestParameters['password'] == null) {
            throw new runtime.RequiredError(
                'password',
                'Required parameter "password" was null or undefined when calling resetPasswordSetMemberPasswordUsingPOST().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['email'] != null) {
            queryParameters['email'] = requestParameters['email'];
        }

        if (requestParameters['password'] != null) {
            queryParameters['password'] = requestParameters['password'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/member/reset-password/change-password`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SuccessResponseModelFromJSON(jsonValue));
    }

    /**
     * resetPasswordSetMemberPassword
     */
    async resetPasswordSetMemberPasswordUsingPOST(requestParameters: ResetPasswordSetMemberPasswordUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SuccessResponseModel | null | undefined > {
        const response = await this.resetPasswordSetMemberPasswordUsingPOSTRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 201:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * resetPassword
     */
    async resetPasswordUsingPOSTRaw(requestParameters: ResetPasswordUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters['locationId'] == null) {
            throw new runtime.RequiredError(
                'locationId',
                'Required parameter "locationId" was null or undefined when calling resetPasswordUsingPOST().'
            );
        }

        if (requestParameters['phone'] == null) {
            throw new runtime.RequiredError(
                'phone',
                'Required parameter "phone" was null or undefined when calling resetPasswordUsingPOST().'
            );
        }

        if (requestParameters['verificationCode'] == null) {
            throw new runtime.RequiredError(
                'verificationCode',
                'Required parameter "verificationCode" was null or undefined when calling resetPasswordUsingPOST().'
            );
        }

        if (requestParameters['password'] == null) {
            throw new runtime.RequiredError(
                'password',
                'Required parameter "password" was null or undefined when calling resetPasswordUsingPOST().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['locationId'] != null) {
            queryParameters['locationId'] = requestParameters['locationId'];
        }

        if (requestParameters['phone'] != null) {
            queryParameters['phone'] = requestParameters['phone'];
        }

        if (requestParameters['verificationCode'] != null) {
            queryParameters['verificationCode'] = requestParameters['verificationCode'];
        }

        if (requestParameters['password'] != null) {
            queryParameters['password'] = requestParameters['password'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/member/resetPassword`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * resetPassword
     */
    async resetPasswordUsingPOST(requestParameters: ResetPasswordUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string | null | undefined > {
        const response = await this.resetPasswordUsingPOSTRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 201:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * resetPasswordVerify
     */
    async resetPasswordVerifyUsingPOSTRaw(requestParameters: ResetPasswordVerifyUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SuccessResponseModel>> {
        if (requestParameters['email'] == null) {
            throw new runtime.RequiredError(
                'email',
                'Required parameter "email" was null or undefined when calling resetPasswordVerifyUsingPOST().'
            );
        }

        if (requestParameters['verifyCode'] == null) {
            throw new runtime.RequiredError(
                'verifyCode',
                'Required parameter "verifyCode" was null or undefined when calling resetPasswordVerifyUsingPOST().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['email'] != null) {
            queryParameters['email'] = requestParameters['email'];
        }

        if (requestParameters['verifyCode'] != null) {
            queryParameters['verifyCode'] = requestParameters['verifyCode'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/member/reset-password/verify`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SuccessResponseModelFromJSON(jsonValue));
    }

    /**
     * resetPasswordVerify
     */
    async resetPasswordVerifyUsingPOST(requestParameters: ResetPasswordVerifyUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SuccessResponseModel | null | undefined > {
        const response = await this.resetPasswordVerifyUsingPOSTRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 201:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * updateMemberAddress
     */
    async updateMemberAddressUsingPUTRaw(requestParameters: UpdateMemberAddressUsingPUTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<string>>> {
        if (requestParameters['addressId'] == null) {
            throw new runtime.RequiredError(
                'addressId',
                'Required parameter "addressId" was null or undefined when calling updateMemberAddressUsingPUT().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        if (requestParameters['addressId'] != null) {
            queryParameters['addressId'] = requestParameters['addressId'];
        }

        if (requestParameters['recipient'] != null) {
            queryParameters['recipient'] = requestParameters['recipient'];
        }

        if (requestParameters['contactNumber'] != null) {
            queryParameters['contactNumber'] = requestParameters['contactNumber'];
        }

        if (requestParameters['addressRow1'] != null) {
            queryParameters['addressRow1'] = requestParameters['addressRow1'];
        }

        if (requestParameters['addressRow2'] != null) {
            queryParameters['addressRow2'] = requestParameters['addressRow2'];
        }

        if (requestParameters['addressRow3'] != null) {
            queryParameters['addressRow3'] = requestParameters['addressRow3'];
        }

        if (requestParameters['addressRow4'] != null) {
            queryParameters['addressRow4'] = requestParameters['addressRow4'];
        }

        if (requestParameters['countryCode'] != null) {
            queryParameters['countryCode'] = requestParameters['countryCode'];
        }

        if (requestParameters['phoneCountryId'] != null) {
            queryParameters['phoneCountryId'] = requestParameters['phoneCountryId'];
        }

        if (requestParameters['addressProvince'] != null) {
            queryParameters['addressProvince'] = requestParameters['addressProvince'];
        }

        if (requestParameters['addressCity'] != null) {
            queryParameters['addressCity'] = requestParameters['addressCity'];
        }

        if (requestParameters['addressZone'] != null) {
            queryParameters['addressZone'] = requestParameters['addressZone'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/member/address`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * updateMemberAddress
     */
    async updateMemberAddressUsingPUT(requestParameters: UpdateMemberAddressUsingPUTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<string> | null | undefined > {
        const response = await this.updateMemberAddressUsingPUTRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 201:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * updateMember
     */
    async updateMemberUsingPOSTRaw(requestParameters: UpdateMemberUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MemberDto>> {
        if (requestParameters['memberId'] == null) {
            throw new runtime.RequiredError(
                'memberId',
                'Required parameter "memberId" was null or undefined when calling updateMemberUsingPOST().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['memberId'] != null) {
            queryParameters['memberId'] = requestParameters['memberId'];
        }

        if (requestParameters['username'] != null) {
            queryParameters['username'] = requestParameters['username'];
        }

        if (requestParameters['firstName'] != null) {
            queryParameters['firstName'] = requestParameters['firstName'];
        }

        if (requestParameters['lastName'] != null) {
            queryParameters['lastName'] = requestParameters['lastName'];
        }

        if (requestParameters['password'] != null) {
            queryParameters['password'] = requestParameters['password'];
        }

        if (requestParameters['email'] != null) {
            queryParameters['email'] = requestParameters['email'];
        }

        if (requestParameters['locationId'] != null) {
            queryParameters['locationId'] = requestParameters['locationId'];
        }

        if (requestParameters['phone'] != null) {
            queryParameters['phone'] = requestParameters['phone'];
        }

        if (requestParameters['address1'] != null) {
            queryParameters['address1'] = requestParameters['address1'];
        }

        if (requestParameters['address2'] != null) {
            queryParameters['address2'] = requestParameters['address2'];
        }

        if (requestParameters['address3'] != null) {
            queryParameters['address3'] = requestParameters['address3'];
        }

        if (requestParameters['birthday'] != null) {
            queryParameters['birthday'] = requestParameters['birthday'];
        }

        if (requestParameters['ageGroup'] != null) {
            queryParameters['ageGroup'] = requestParameters['ageGroup'];
        }

        if (requestParameters['memberLevelId'] != null) {
            queryParameters['memberLevelId'] = requestParameters['memberLevelId'];
        }

        if (requestParameters['district'] != null) {
            queryParameters['district'] = requestParameters['district'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/member/update`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters['body'] as any,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MemberDtoFromJSON(jsonValue));
    }

    /**
     * updateMember
     */
    async updateMemberUsingPOST(requestParameters: UpdateMemberUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MemberDto | null | undefined > {
        const response = await this.updateMemberUsingPOSTRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 201:
                return null;
            default:
                return await response.value();
        }
    }

}
