/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';

export interface GetDisplayProductProductUsingGETRequest {
    dpId: string;
}

export interface GetDisplayProductUsingGETRequest {
    dpId: string;
}

export interface GetProductUsingGETRequest {
    pId: string;
}

/**
 * 
 */
export class TestControllerApi extends runtime.BaseAPI {

    /**
     * getDisplayProductProduct
     */
    async getDisplayProductProductUsingGETRaw(requestParameters: GetDisplayProductProductUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        if (requestParameters['dpId'] == null) {
            throw new runtime.RequiredError(
                'dpId',
                'Required parameter "dpId" was null or undefined when calling getDisplayProductProductUsingGET().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/tttt/dp-p/{dpId}`.replace(`{${"dpId"}}`, encodeURIComponent(String(requestParameters['dpId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * getDisplayProductProduct
     */
    async getDisplayProductProductUsingGET(requestParameters: GetDisplayProductProductUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object> {
        const response = await this.getDisplayProductProductUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getDisplayProduct
     */
    async getDisplayProductUsingGETRaw(requestParameters: GetDisplayProductUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        if (requestParameters['dpId'] == null) {
            throw new runtime.RequiredError(
                'dpId',
                'Required parameter "dpId" was null or undefined when calling getDisplayProductUsingGET().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/tttt/dp/{dpId}`.replace(`{${"dpId"}}`, encodeURIComponent(String(requestParameters['dpId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * getDisplayProduct
     */
    async getDisplayProductUsingGET(requestParameters: GetDisplayProductUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object> {
        const response = await this.getDisplayProductUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getProduct
     */
    async getProductUsingGETRaw(requestParameters: GetProductUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        if (requestParameters['pId'] == null) {
            throw new runtime.RequiredError(
                'pId',
                'Required parameter "pId" was null or undefined when calling getProductUsingGET().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/tttt/p/{pId}`.replace(`{${"pId"}}`, encodeURIComponent(String(requestParameters['pId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * getProduct
     */
    async getProductUsingGET(requestParameters: GetProductUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object> {
        const response = await this.getProductUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * sendEmail
     */
    async sendEmailUsingDELETERaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/tttt/email`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * sendEmail
     */
    async sendEmailUsingDELETE(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object | null | undefined > {
        const response = await this.sendEmailUsingDELETERaw(initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 204:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * sendEmail
     */
    async sendEmailUsingGETRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/tttt/email`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * sendEmail
     */
    async sendEmailUsingGET(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object> {
        const response = await this.sendEmailUsingGETRaw(initOverrides);
        return await response.value();
    }

    /**
     * sendEmail
     */
    async sendEmailUsingHEADRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/tttt/email`,
            method: 'HEAD',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * sendEmail
     */
    async sendEmailUsingHEAD(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object | null | undefined > {
        const response = await this.sendEmailUsingHEADRaw(initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 204:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * sendEmail
     */
    async sendEmailUsingOPTIONSRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/tttt/email`,
            method: 'OPTIONS',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * sendEmail
     */
    async sendEmailUsingOPTIONS(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object | null | undefined > {
        const response = await this.sendEmailUsingOPTIONSRaw(initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 204:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * sendEmail
     */
    async sendEmailUsingPATCHRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/tttt/email`,
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * sendEmail
     */
    async sendEmailUsingPATCH(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object | null | undefined > {
        const response = await this.sendEmailUsingPATCHRaw(initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 204:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * sendEmail
     */
    async sendEmailUsingPOSTRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/tttt/email`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * sendEmail
     */
    async sendEmailUsingPOST(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object | null | undefined > {
        const response = await this.sendEmailUsingPOSTRaw(initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 201:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * sendEmail
     */
    async sendEmailUsingPUTRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/tttt/email`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * sendEmail
     */
    async sendEmailUsingPUT(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object | null | undefined > {
        const response = await this.sendEmailUsingPUTRaw(initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 201:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * sendEmail
     */
    async sendEmailUsingTRACERaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/tttt/email`,
            method: 'TRACE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * sendEmail
     */
    async sendEmailUsingTRACE(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object | null | undefined > {
        const response = await this.sendEmailUsingTRACERaw(initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 204:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * sms
     */
    async smsUsingDELETERaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/tttt/sms`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * sms
     */
    async smsUsingDELETE(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object | null | undefined > {
        const response = await this.smsUsingDELETERaw(initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 204:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * sms
     */
    async smsUsingGETRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/tttt/sms`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * sms
     */
    async smsUsingGET(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object> {
        const response = await this.smsUsingGETRaw(initOverrides);
        return await response.value();
    }

    /**
     * sms
     */
    async smsUsingHEADRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/tttt/sms`,
            method: 'HEAD',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * sms
     */
    async smsUsingHEAD(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object | null | undefined > {
        const response = await this.smsUsingHEADRaw(initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 204:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * sms
     */
    async smsUsingOPTIONSRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/tttt/sms`,
            method: 'OPTIONS',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * sms
     */
    async smsUsingOPTIONS(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object | null | undefined > {
        const response = await this.smsUsingOPTIONSRaw(initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 204:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * sms
     */
    async smsUsingPATCHRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/tttt/sms`,
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * sms
     */
    async smsUsingPATCH(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object | null | undefined > {
        const response = await this.smsUsingPATCHRaw(initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 204:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * sms
     */
    async smsUsingPOSTRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/tttt/sms`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * sms
     */
    async smsUsingPOST(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object | null | undefined > {
        const response = await this.smsUsingPOSTRaw(initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 201:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * sms
     */
    async smsUsingPUTRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/tttt/sms`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * sms
     */
    async smsUsingPUT(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object | null | undefined > {
        const response = await this.smsUsingPUTRaw(initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 201:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * sms
     */
    async smsUsingTRACERaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/tttt/sms`,
            method: 'TRACE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * sms
     */
    async smsUsingTRACE(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object | null | undefined > {
        const response = await this.smsUsingTRACERaw(initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 204:
                return null;
            default:
                return await response.value();
        }
    }

}
