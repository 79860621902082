/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AgeGroupDto
 */
export interface AgeGroupDto {
    /**
     * 
     * @type {number}
     * @memberof AgeGroupDto
     */
    ageGroupId?: number;
    /**
     * 
     * @type {string}
     * @memberof AgeGroupDto
     */
    ageGroupName?: string;
    /**
     * 
     * @type {string}
     * @memberof AgeGroupDto
     */
    status?: string;
}

/**
 * Check if a given object implements the AgeGroupDto interface.
 */
export function instanceOfAgeGroupDto(value: object): boolean {
    return true;
}

export function AgeGroupDtoFromJSON(json: any): AgeGroupDto {
    return AgeGroupDtoFromJSONTyped(json, false);
}

export function AgeGroupDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): AgeGroupDto {
    if (json == null) {
        return json;
    }
    return {
        
        'ageGroupId': json['ageGroupId'] == null ? undefined : json['ageGroupId'],
        'ageGroupName': json['ageGroupName'] == null ? undefined : json['ageGroupName'],
        'status': json['status'] == null ? undefined : json['status'],
    };
}

export function AgeGroupDtoToJSON(value?: AgeGroupDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'ageGroupId': value['ageGroupId'],
        'ageGroupName': value['ageGroupName'],
        'status': value['status'],
    };
}

