/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ProductDto } from './ProductDto';
import {
    ProductDtoFromJSON,
    ProductDtoFromJSONTyped,
    ProductDtoToJSON,
} from './ProductDto';

/**
 * 
 * @export
 * @interface ShippingProductDto
 */
export interface ShippingProductDto {
    /**
     * 
     * @type {ProductDto}
     * @memberof ShippingProductDto
     */
    product?: ProductDto;
    /**
     * 
     * @type {number}
     * @memberof ShippingProductDto
     */
    quantity?: number;
    /**
     * 
     * @type {number}
     * @memberof ShippingProductDto
     */
    shippingId?: number;
}

/**
 * Check if a given object implements the ShippingProductDto interface.
 */
export function instanceOfShippingProductDto(value: object): boolean {
    return true;
}

export function ShippingProductDtoFromJSON(json: any): ShippingProductDto {
    return ShippingProductDtoFromJSONTyped(json, false);
}

export function ShippingProductDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ShippingProductDto {
    if (json == null) {
        return json;
    }
    return {
        
        'product': json['product'] == null ? undefined : ProductDtoFromJSON(json['product']),
        'quantity': json['quantity'] == null ? undefined : json['quantity'],
        'shippingId': json['shippingId'] == null ? undefined : json['shippingId'],
    };
}

export function ShippingProductDtoToJSON(value?: ShippingProductDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'product': ProductDtoToJSON(value['product']),
        'quantity': value['quantity'],
        'shippingId': value['shippingId'],
    };
}

