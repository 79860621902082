/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Member } from './Member';
import {
    MemberFromJSON,
    MemberFromJSONTyped,
    MemberToJSON,
} from './Member';
import type { Order } from './Order';
import {
    OrderFromJSON,
    OrderFromJSONTyped,
    OrderToJSON,
} from './Order';

/**
 * 
 * @export
 * @interface UserPointRecord
 */
export interface UserPointRecord {
    /**
     * 
     * @type {Date}
     * @memberof UserPointRecord
     */
    expiryDate?: Date;
    /**
     * 
     * @type {Member}
     * @memberof UserPointRecord
     */
    memberByMemberId?: Member;
    /**
     * 
     * @type {number}
     * @memberof UserPointRecord
     */
    memberId?: number;
    /**
     * 
     * @type {Order}
     * @memberof UserPointRecord
     */
    orderByOrderId?: Order;
    /**
     * 
     * @type {number}
     * @memberof UserPointRecord
     */
    orderId?: number;
    /**
     * 
     * @type {number}
     * @memberof UserPointRecord
     */
    status?: number;
    /**
     * 
     * @type {number}
     * @memberof UserPointRecord
     */
    usedNum?: number;
    /**
     * 
     * @type {Date}
     * @memberof UserPointRecord
     */
    userPointRecordDate?: Date;
    /**
     * 
     * @type {number}
     * @memberof UserPointRecord
     */
    userPointRecordId?: number;
    /**
     * 
     * @type {number}
     * @memberof UserPointRecord
     */
    userPointRecordNum?: number;
}

/**
 * Check if a given object implements the UserPointRecord interface.
 */
export function instanceOfUserPointRecord(value: object): boolean {
    return true;
}

export function UserPointRecordFromJSON(json: any): UserPointRecord {
    return UserPointRecordFromJSONTyped(json, false);
}

export function UserPointRecordFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserPointRecord {
    if (json == null) {
        return json;
    }
    return {
        
        'expiryDate': json['expiryDate'] == null ? undefined : (new Date(json['expiryDate'])),
        'memberByMemberId': json['memberByMemberId'] == null ? undefined : MemberFromJSON(json['memberByMemberId']),
        'memberId': json['memberId'] == null ? undefined : json['memberId'],
        'orderByOrderId': json['orderByOrderId'] == null ? undefined : OrderFromJSON(json['orderByOrderId']),
        'orderId': json['orderId'] == null ? undefined : json['orderId'],
        'status': json['status'] == null ? undefined : json['status'],
        'usedNum': json['usedNum'] == null ? undefined : json['usedNum'],
        'userPointRecordDate': json['userPointRecordDate'] == null ? undefined : (new Date(json['userPointRecordDate'])),
        'userPointRecordId': json['userPointRecordId'] == null ? undefined : json['userPointRecordId'],
        'userPointRecordNum': json['userPointRecordNum'] == null ? undefined : json['userPointRecordNum'],
    };
}

export function UserPointRecordToJSON(value?: UserPointRecord | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'expiryDate': value['expiryDate'] == null ? undefined : ((value['expiryDate']).toISOString()),
        'memberByMemberId': MemberToJSON(value['memberByMemberId']),
        'memberId': value['memberId'],
        'orderByOrderId': OrderToJSON(value['orderByOrderId']),
        'orderId': value['orderId'],
        'status': value['status'],
        'usedNum': value['usedNum'],
        'userPointRecordDate': value['userPointRecordDate'] == null ? undefined : ((value['userPointRecordDate']).toISOString()),
        'userPointRecordId': value['userPointRecordId'],
        'userPointRecordNum': value['userPointRecordNum'],
    };
}

