/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { CartProduct } from './CartProduct';
import {
    CartProductFromJSON,
    CartProductFromJSONTyped,
    CartProductToJSON,
} from './CartProduct';
import type { Product } from './Product';
import {
    ProductFromJSON,
    ProductFromJSONTyped,
    ProductToJSON,
} from './Product';

/**
 * 
 * @export
 * @interface ProductPoint
 */
export interface ProductPoint {
    /**
     * 
     * @type {Array<CartProduct>}
     * @memberof ProductPoint
     */
    cartProductsByCartProductId?: Array<CartProduct>;
    /**
     * 
     * @type {Date}
     * @memberof ProductPoint
     */
    endDate?: Date;
    /**
     * 
     * @type {number}
     * @memberof ProductPoint
     */
    money?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductPoint
     */
    point?: number;
    /**
     * 
     * @type {Product}
     * @memberof ProductPoint
     */
    productByProductId?: Product;
    /**
     * 
     * @type {number}
     * @memberof ProductPoint
     */
    productId?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductPoint
     */
    productPointId?: number;
    /**
     * 
     * @type {Date}
     * @memberof ProductPoint
     */
    startDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof ProductPoint
     */
    status?: string;
}

/**
 * Check if a given object implements the ProductPoint interface.
 */
export function instanceOfProductPoint(value: object): boolean {
    return true;
}

export function ProductPointFromJSON(json: any): ProductPoint {
    return ProductPointFromJSONTyped(json, false);
}

export function ProductPointFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProductPoint {
    if (json == null) {
        return json;
    }
    return {
        
        'cartProductsByCartProductId': json['cartProductsByCartProductId'] == null ? undefined : ((json['cartProductsByCartProductId'] as Array<any>).map(CartProductFromJSON)),
        'endDate': json['endDate'] == null ? undefined : (new Date(json['endDate'])),
        'money': json['money'] == null ? undefined : json['money'],
        'point': json['point'] == null ? undefined : json['point'],
        'productByProductId': json['productByProductId'] == null ? undefined : ProductFromJSON(json['productByProductId']),
        'productId': json['productId'] == null ? undefined : json['productId'],
        'productPointId': json['productPointId'] == null ? undefined : json['productPointId'],
        'startDate': json['startDate'] == null ? undefined : (new Date(json['startDate'])),
        'status': json['status'] == null ? undefined : json['status'],
    };
}

export function ProductPointToJSON(value?: ProductPoint | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'cartProductsByCartProductId': value['cartProductsByCartProductId'] == null ? undefined : ((value['cartProductsByCartProductId'] as Array<any>).map(CartProductToJSON)),
        'endDate': value['endDate'] == null ? undefined : ((value['endDate']).toISOString()),
        'money': value['money'],
        'point': value['point'],
        'productByProductId': ProductToJSON(value['productByProductId']),
        'productId': value['productId'],
        'productPointId': value['productPointId'],
        'startDate': value['startDate'] == null ? undefined : ((value['startDate']).toISOString()),
        'status': value['status'],
    };
}

