/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  OrganizationDto,
} from '../models/index';
import {
    OrganizationDtoFromJSON,
    OrganizationDtoToJSON,
} from '../models/index';

export interface GetOrganizationByOrganizationIdUsingGETRequest {
    organizationId: number;
}

/**
 * 
 */
export class OrganizationControllerApi extends runtime.BaseAPI {

    /**
     * getAllOrganization
     */
    async getAllOrganizationUsingGETRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<OrganizationDto>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/organization/all`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(OrganizationDtoFromJSON));
    }

    /**
     * getAllOrganization
     */
    async getAllOrganizationUsingGET(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<OrganizationDto>> {
        const response = await this.getAllOrganizationUsingGETRaw(initOverrides);
        return await response.value();
    }

    /**
     * getOrganizationByOrganizationId
     */
    async getOrganizationByOrganizationIdUsingGETRaw(requestParameters: GetOrganizationByOrganizationIdUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OrganizationDto>> {
        if (requestParameters['organizationId'] == null) {
            throw new runtime.RequiredError(
                'organizationId',
                'Required parameter "organizationId" was null or undefined when calling getOrganizationByOrganizationIdUsingGET().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/organization/{organizationId}`.replace(`{${"organizationId"}}`, encodeURIComponent(String(requestParameters['organizationId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OrganizationDtoFromJSON(jsonValue));
    }

    /**
     * getOrganizationByOrganizationId
     */
    async getOrganizationByOrganizationIdUsingGET(requestParameters: GetOrganizationByOrganizationIdUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OrganizationDto> {
        const response = await this.getOrganizationByOrganizationIdUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
