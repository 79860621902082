/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface HashtagDto
 */
export interface HashtagDto {
    /**
     * 
     * @type {number}
     * @memberof HashtagDto
     */
    hashtagId?: number;
    /**
     * 
     * @type {string}
     * @memberof HashtagDto
     */
    hashtagName?: string;
}

/**
 * Check if a given object implements the HashtagDto interface.
 */
export function instanceOfHashtagDto(value: object): boolean {
    return true;
}

export function HashtagDtoFromJSON(json: any): HashtagDto {
    return HashtagDtoFromJSONTyped(json, false);
}

export function HashtagDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): HashtagDto {
    if (json == null) {
        return json;
    }
    return {
        
        'hashtagId': json['hashtagId'] == null ? undefined : json['hashtagId'],
        'hashtagName': json['hashtagName'] == null ? undefined : json['hashtagName'],
    };
}

export function HashtagDtoToJSON(value?: HashtagDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'hashtagId': value['hashtagId'],
        'hashtagName': value['hashtagName'],
    };
}

