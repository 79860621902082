/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  MemberDto,
  OrderDtoForReport,
  OrderProductDto2,
  ProductDto,
} from '../models/index';
import {
    MemberDtoFromJSON,
    MemberDtoToJSON,
    OrderDtoForReportFromJSON,
    OrderDtoForReportToJSON,
    OrderProductDto2FromJSON,
    OrderProductDto2ToJSON,
    ProductDtoFromJSON,
    ProductDtoToJSON,
} from '../models/index';

export interface BirthdayDiscountUsingDELETE1Request {
    name?: string;
}

export interface BirthdayDiscountUsingGET1Request {
    name?: string;
}

export interface BirthdayDiscountUsingHEAD1Request {
    name?: string;
}

export interface BirthdayDiscountUsingOPTIONS1Request {
    name?: string;
}

export interface BirthdayDiscountUsingPATCH1Request {
    name?: string;
}

export interface BirthdayDiscountUsingPOST1Request {
    name?: string;
}

export interface BirthdayDiscountUsingPUT1Request {
    name?: string;
}

export interface BirthdayDiscountUsingTRACE1Request {
    name?: string;
}

export interface BusinessReportBetweenDateUsingDELETERequest {
    endDate: string;
    startDate: string;
    name?: string;
}

export interface BusinessReportBetweenDateUsingGETRequest {
    endDate: string;
    startDate: string;
    name?: string;
}

export interface BusinessReportBetweenDateUsingHEADRequest {
    endDate: string;
    startDate: string;
    name?: string;
}

export interface BusinessReportBetweenDateUsingOPTIONSRequest {
    endDate: string;
    startDate: string;
    name?: string;
}

export interface BusinessReportBetweenDateUsingPATCHRequest {
    endDate: string;
    startDate: string;
    name?: string;
}

export interface BusinessReportBetweenDateUsingPOSTRequest {
    endDate: string;
    startDate: string;
    name?: string;
}

export interface BusinessReportBetweenDateUsingPUTRequest {
    endDate: string;
    startDate: string;
    name?: string;
}

export interface BusinessReportBetweenDateUsingTRACERequest {
    endDate: string;
    startDate: string;
    name?: string;
}

export interface CouponCashDiscountUsingDELETE1Request {
    name?: string;
}

export interface CouponCashDiscountUsingGET1Request {
    name?: string;
}

export interface CouponCashDiscountUsingHEAD1Request {
    name?: string;
}

export interface CouponCashDiscountUsingOPTIONS1Request {
    name?: string;
}

export interface CouponCashDiscountUsingPATCH1Request {
    name?: string;
}

export interface CouponCashDiscountUsingPOST1Request {
    name?: string;
}

export interface CouponCashDiscountUsingPUT1Request {
    name?: string;
}

export interface CouponCashDiscountUsingTRACE1Request {
    name?: string;
}

export interface CouponCodeDiscountUsingDELETERequest {
    endDate: string;
    startDate: string;
    name?: string;
}

export interface CouponCodeDiscountUsingGETRequest {
    endDate: string;
    startDate: string;
    name?: string;
}

export interface CouponCodeDiscountUsingHEADRequest {
    endDate: string;
    startDate: string;
    name?: string;
}

export interface CouponCodeDiscountUsingOPTIONSRequest {
    endDate: string;
    startDate: string;
    name?: string;
}

export interface CouponCodeDiscountUsingPATCHRequest {
    endDate: string;
    startDate: string;
    name?: string;
}

export interface CouponCodeDiscountUsingPOSTRequest {
    endDate: string;
    startDate: string;
    name?: string;
}

export interface CouponCodeDiscountUsingPUTRequest {
    endDate: string;
    startDate: string;
    name?: string;
}

export interface CouponCodeDiscountUsingTRACERequest {
    endDate: string;
    startDate: string;
    name?: string;
}

export interface InventoryStockingUsingDELETE1Request {
    name?: string;
}

export interface InventoryStockingUsingGET1Request {
    name?: string;
}

export interface InventoryStockingUsingHEAD1Request {
    name?: string;
}

export interface InventoryStockingUsingOPTIONS1Request {
    name?: string;
}

export interface InventoryStockingUsingPATCH1Request {
    name?: string;
}

export interface InventoryStockingUsingPOST1Request {
    name?: string;
}

export interface InventoryStockingUsingPUT1Request {
    name?: string;
}

export interface InventoryStockingUsingTRACE1Request {
    name?: string;
}

export interface MemberPointUsingDELETERequest {
    endDate: string;
    startDate: string;
    name?: string;
}

export interface MemberPointUsingGETRequest {
    endDate: string;
    startDate: string;
    name?: string;
}

export interface MemberPointUsingHEADRequest {
    endDate: string;
    startDate: string;
    name?: string;
}

export interface MemberPointUsingOPTIONSRequest {
    endDate: string;
    startDate: string;
    name?: string;
}

export interface MemberPointUsingPATCHRequest {
    endDate: string;
    startDate: string;
    name?: string;
}

export interface MemberPointUsingPOSTRequest {
    endDate: string;
    startDate: string;
    name?: string;
}

export interface MemberPointUsingPUTRequest {
    endDate: string;
    startDate: string;
    name?: string;
}

export interface MemberPointUsingTRACERequest {
    endDate: string;
    startDate: string;
    name?: string;
}

export interface PointProductUsingDELETE1Request {
    name?: string;
}

export interface PointProductUsingGET1Request {
    name?: string;
}

export interface PointProductUsingHEAD1Request {
    name?: string;
}

export interface PointProductUsingOPTIONS1Request {
    name?: string;
}

export interface PointProductUsingPATCH1Request {
    name?: string;
}

export interface PointProductUsingPOST1Request {
    name?: string;
}

export interface PointProductUsingPUT1Request {
    name?: string;
}

export interface PointProductUsingTRACE1Request {
    name?: string;
}

export interface PointToCashUsingDELETE1Request {
    endDate: string;
    startDate: string;
    name?: string;
}

export interface PointToCashUsingGET1Request {
    endDate: string;
    startDate: string;
    name?: string;
}

export interface PointToCashUsingHEAD1Request {
    endDate: string;
    startDate: string;
    name?: string;
}

export interface PointToCashUsingOPTIONS1Request {
    endDate: string;
    startDate: string;
    name?: string;
}

export interface PointToCashUsingPATCH1Request {
    endDate: string;
    startDate: string;
    name?: string;
}

export interface PointToCashUsingPOST1Request {
    endDate: string;
    startDate: string;
    name?: string;
}

export interface PointToCashUsingPUT1Request {
    endDate: string;
    startDate: string;
    name?: string;
}

export interface PointToCashUsingTRACE1Request {
    endDate: string;
    startDate: string;
    name?: string;
}

export interface PointToGiftUsingDELETERequest {
    endDate: string;
    startDate: string;
    name?: string;
}

export interface PointToGiftUsingGETRequest {
    endDate: string;
    startDate: string;
    name?: string;
}

export interface PointToGiftUsingHEADRequest {
    endDate: string;
    startDate: string;
    name?: string;
}

export interface PointToGiftUsingOPTIONSRequest {
    endDate: string;
    startDate: string;
    name?: string;
}

export interface PointToGiftUsingPATCHRequest {
    endDate: string;
    startDate: string;
    name?: string;
}

export interface PointToGiftUsingPOSTRequest {
    endDate: string;
    startDate: string;
    name?: string;
}

export interface PointToGiftUsingPUTRequest {
    endDate: string;
    startDate: string;
    name?: string;
}

export interface PointToGiftUsingTRACERequest {
    endDate: string;
    startDate: string;
    name?: string;
}

export interface ProductSalesUsingDELETERequest {
    endDate: string;
    startDate: string;
    name?: string;
}

export interface ProductSalesUsingGETRequest {
    endDate: string;
    startDate: string;
    name?: string;
}

export interface ProductSalesUsingHEADRequest {
    endDate: string;
    startDate: string;
    name?: string;
}

export interface ProductSalesUsingOPTIONSRequest {
    endDate: string;
    startDate: string;
    name?: string;
}

export interface ProductSalesUsingPATCHRequest {
    endDate: string;
    startDate: string;
    name?: string;
}

export interface ProductSalesUsingPOSTRequest {
    endDate: string;
    startDate: string;
    name?: string;
}

export interface ProductSalesUsingPUTRequest {
    endDate: string;
    startDate: string;
    name?: string;
}

export interface ProductSalesUsingTRACERequest {
    endDate: string;
    startDate: string;
    name?: string;
}

/**
 * 
 */
export class ReportControllerApi extends runtime.BaseAPI {

    /**
     * birthdayDiscount
     * @deprecated
     */
    async birthdayDiscountUsingDELETE1Raw(requestParameters: BirthdayDiscountUsingDELETE1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<OrderDtoForReport>>> {
        const queryParameters: any = {};

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/report/birthdayDiscount`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(OrderDtoForReportFromJSON));
    }

    /**
     * birthdayDiscount
     * @deprecated
     */
    async birthdayDiscountUsingDELETE1(requestParameters: BirthdayDiscountUsingDELETE1Request = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<OrderDtoForReport> | null | undefined > {
        const response = await this.birthdayDiscountUsingDELETE1Raw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 204:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * birthdayDiscount
     * @deprecated
     */
    async birthdayDiscountUsingGET1Raw(requestParameters: BirthdayDiscountUsingGET1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<OrderDtoForReport>>> {
        const queryParameters: any = {};

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/report/birthdayDiscount`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(OrderDtoForReportFromJSON));
    }

    /**
     * birthdayDiscount
     * @deprecated
     */
    async birthdayDiscountUsingGET1(requestParameters: BirthdayDiscountUsingGET1Request = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<OrderDtoForReport>> {
        const response = await this.birthdayDiscountUsingGET1Raw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * birthdayDiscount
     * @deprecated
     */
    async birthdayDiscountUsingHEAD1Raw(requestParameters: BirthdayDiscountUsingHEAD1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<OrderDtoForReport>>> {
        const queryParameters: any = {};

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/report/birthdayDiscount`,
            method: 'HEAD',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(OrderDtoForReportFromJSON));
    }

    /**
     * birthdayDiscount
     * @deprecated
     */
    async birthdayDiscountUsingHEAD1(requestParameters: BirthdayDiscountUsingHEAD1Request = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<OrderDtoForReport> | null | undefined > {
        const response = await this.birthdayDiscountUsingHEAD1Raw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 204:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * birthdayDiscount
     * @deprecated
     */
    async birthdayDiscountUsingOPTIONS1Raw(requestParameters: BirthdayDiscountUsingOPTIONS1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<OrderDtoForReport>>> {
        const queryParameters: any = {};

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/report/birthdayDiscount`,
            method: 'OPTIONS',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(OrderDtoForReportFromJSON));
    }

    /**
     * birthdayDiscount
     * @deprecated
     */
    async birthdayDiscountUsingOPTIONS1(requestParameters: BirthdayDiscountUsingOPTIONS1Request = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<OrderDtoForReport> | null | undefined > {
        const response = await this.birthdayDiscountUsingOPTIONS1Raw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 204:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * birthdayDiscount
     * @deprecated
     */
    async birthdayDiscountUsingPATCH1Raw(requestParameters: BirthdayDiscountUsingPATCH1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<OrderDtoForReport>>> {
        const queryParameters: any = {};

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/report/birthdayDiscount`,
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(OrderDtoForReportFromJSON));
    }

    /**
     * birthdayDiscount
     * @deprecated
     */
    async birthdayDiscountUsingPATCH1(requestParameters: BirthdayDiscountUsingPATCH1Request = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<OrderDtoForReport> | null | undefined > {
        const response = await this.birthdayDiscountUsingPATCH1Raw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 204:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * birthdayDiscount
     * @deprecated
     */
    async birthdayDiscountUsingPOST1Raw(requestParameters: BirthdayDiscountUsingPOST1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<OrderDtoForReport>>> {
        const queryParameters: any = {};

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/report/birthdayDiscount`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(OrderDtoForReportFromJSON));
    }

    /**
     * birthdayDiscount
     * @deprecated
     */
    async birthdayDiscountUsingPOST1(requestParameters: BirthdayDiscountUsingPOST1Request = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<OrderDtoForReport> | null | undefined > {
        const response = await this.birthdayDiscountUsingPOST1Raw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 201:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * birthdayDiscount
     * @deprecated
     */
    async birthdayDiscountUsingPUT1Raw(requestParameters: BirthdayDiscountUsingPUT1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<OrderDtoForReport>>> {
        const queryParameters: any = {};

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/report/birthdayDiscount`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(OrderDtoForReportFromJSON));
    }

    /**
     * birthdayDiscount
     * @deprecated
     */
    async birthdayDiscountUsingPUT1(requestParameters: BirthdayDiscountUsingPUT1Request = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<OrderDtoForReport> | null | undefined > {
        const response = await this.birthdayDiscountUsingPUT1Raw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 201:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * birthdayDiscount
     * @deprecated
     */
    async birthdayDiscountUsingTRACE1Raw(requestParameters: BirthdayDiscountUsingTRACE1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<OrderDtoForReport>>> {
        const queryParameters: any = {};

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/report/birthdayDiscount`,
            method: 'TRACE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(OrderDtoForReportFromJSON));
    }

    /**
     * birthdayDiscount
     * @deprecated
     */
    async birthdayDiscountUsingTRACE1(requestParameters: BirthdayDiscountUsingTRACE1Request = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<OrderDtoForReport> | null | undefined > {
        const response = await this.birthdayDiscountUsingTRACE1Raw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 204:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * businessReportBetweenDate
     * @deprecated
     */
    async businessReportBetweenDateUsingDELETERaw(requestParameters: BusinessReportBetweenDateUsingDELETERequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<OrderDtoForReport>>> {
        if (requestParameters['endDate'] == null) {
            throw new runtime.RequiredError(
                'endDate',
                'Required parameter "endDate" was null or undefined when calling businessReportBetweenDateUsingDELETE().'
            );
        }

        if (requestParameters['startDate'] == null) {
            throw new runtime.RequiredError(
                'startDate',
                'Required parameter "startDate" was null or undefined when calling businessReportBetweenDateUsingDELETE().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['endDate'] != null) {
            queryParameters['endDate'] = requestParameters['endDate'];
        }

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        if (requestParameters['startDate'] != null) {
            queryParameters['startDate'] = requestParameters['startDate'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/report/businessReport`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(OrderDtoForReportFromJSON));
    }

    /**
     * businessReportBetweenDate
     * @deprecated
     */
    async businessReportBetweenDateUsingDELETE(requestParameters: BusinessReportBetweenDateUsingDELETERequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<OrderDtoForReport> | null | undefined > {
        const response = await this.businessReportBetweenDateUsingDELETERaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 204:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * businessReportBetweenDate
     * @deprecated
     */
    async businessReportBetweenDateUsingGETRaw(requestParameters: BusinessReportBetweenDateUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<OrderDtoForReport>>> {
        if (requestParameters['endDate'] == null) {
            throw new runtime.RequiredError(
                'endDate',
                'Required parameter "endDate" was null or undefined when calling businessReportBetweenDateUsingGET().'
            );
        }

        if (requestParameters['startDate'] == null) {
            throw new runtime.RequiredError(
                'startDate',
                'Required parameter "startDate" was null or undefined when calling businessReportBetweenDateUsingGET().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['endDate'] != null) {
            queryParameters['endDate'] = requestParameters['endDate'];
        }

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        if (requestParameters['startDate'] != null) {
            queryParameters['startDate'] = requestParameters['startDate'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/report/businessReport`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(OrderDtoForReportFromJSON));
    }

    /**
     * businessReportBetweenDate
     * @deprecated
     */
    async businessReportBetweenDateUsingGET(requestParameters: BusinessReportBetweenDateUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<OrderDtoForReport>> {
        const response = await this.businessReportBetweenDateUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * businessReportBetweenDate
     * @deprecated
     */
    async businessReportBetweenDateUsingHEADRaw(requestParameters: BusinessReportBetweenDateUsingHEADRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<OrderDtoForReport>>> {
        if (requestParameters['endDate'] == null) {
            throw new runtime.RequiredError(
                'endDate',
                'Required parameter "endDate" was null or undefined when calling businessReportBetweenDateUsingHEAD().'
            );
        }

        if (requestParameters['startDate'] == null) {
            throw new runtime.RequiredError(
                'startDate',
                'Required parameter "startDate" was null or undefined when calling businessReportBetweenDateUsingHEAD().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['endDate'] != null) {
            queryParameters['endDate'] = requestParameters['endDate'];
        }

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        if (requestParameters['startDate'] != null) {
            queryParameters['startDate'] = requestParameters['startDate'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/report/businessReport`,
            method: 'HEAD',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(OrderDtoForReportFromJSON));
    }

    /**
     * businessReportBetweenDate
     * @deprecated
     */
    async businessReportBetweenDateUsingHEAD(requestParameters: BusinessReportBetweenDateUsingHEADRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<OrderDtoForReport> | null | undefined > {
        const response = await this.businessReportBetweenDateUsingHEADRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 204:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * businessReportBetweenDate
     * @deprecated
     */
    async businessReportBetweenDateUsingOPTIONSRaw(requestParameters: BusinessReportBetweenDateUsingOPTIONSRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<OrderDtoForReport>>> {
        if (requestParameters['endDate'] == null) {
            throw new runtime.RequiredError(
                'endDate',
                'Required parameter "endDate" was null or undefined when calling businessReportBetweenDateUsingOPTIONS().'
            );
        }

        if (requestParameters['startDate'] == null) {
            throw new runtime.RequiredError(
                'startDate',
                'Required parameter "startDate" was null or undefined when calling businessReportBetweenDateUsingOPTIONS().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['endDate'] != null) {
            queryParameters['endDate'] = requestParameters['endDate'];
        }

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        if (requestParameters['startDate'] != null) {
            queryParameters['startDate'] = requestParameters['startDate'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/report/businessReport`,
            method: 'OPTIONS',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(OrderDtoForReportFromJSON));
    }

    /**
     * businessReportBetweenDate
     * @deprecated
     */
    async businessReportBetweenDateUsingOPTIONS(requestParameters: BusinessReportBetweenDateUsingOPTIONSRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<OrderDtoForReport> | null | undefined > {
        const response = await this.businessReportBetweenDateUsingOPTIONSRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 204:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * businessReportBetweenDate
     * @deprecated
     */
    async businessReportBetweenDateUsingPATCHRaw(requestParameters: BusinessReportBetweenDateUsingPATCHRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<OrderDtoForReport>>> {
        if (requestParameters['endDate'] == null) {
            throw new runtime.RequiredError(
                'endDate',
                'Required parameter "endDate" was null or undefined when calling businessReportBetweenDateUsingPATCH().'
            );
        }

        if (requestParameters['startDate'] == null) {
            throw new runtime.RequiredError(
                'startDate',
                'Required parameter "startDate" was null or undefined when calling businessReportBetweenDateUsingPATCH().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['endDate'] != null) {
            queryParameters['endDate'] = requestParameters['endDate'];
        }

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        if (requestParameters['startDate'] != null) {
            queryParameters['startDate'] = requestParameters['startDate'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/report/businessReport`,
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(OrderDtoForReportFromJSON));
    }

    /**
     * businessReportBetweenDate
     * @deprecated
     */
    async businessReportBetweenDateUsingPATCH(requestParameters: BusinessReportBetweenDateUsingPATCHRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<OrderDtoForReport> | null | undefined > {
        const response = await this.businessReportBetweenDateUsingPATCHRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 204:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * businessReportBetweenDate
     * @deprecated
     */
    async businessReportBetweenDateUsingPOSTRaw(requestParameters: BusinessReportBetweenDateUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<OrderDtoForReport>>> {
        if (requestParameters['endDate'] == null) {
            throw new runtime.RequiredError(
                'endDate',
                'Required parameter "endDate" was null or undefined when calling businessReportBetweenDateUsingPOST().'
            );
        }

        if (requestParameters['startDate'] == null) {
            throw new runtime.RequiredError(
                'startDate',
                'Required parameter "startDate" was null or undefined when calling businessReportBetweenDateUsingPOST().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['endDate'] != null) {
            queryParameters['endDate'] = requestParameters['endDate'];
        }

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        if (requestParameters['startDate'] != null) {
            queryParameters['startDate'] = requestParameters['startDate'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/report/businessReport`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(OrderDtoForReportFromJSON));
    }

    /**
     * businessReportBetweenDate
     * @deprecated
     */
    async businessReportBetweenDateUsingPOST(requestParameters: BusinessReportBetweenDateUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<OrderDtoForReport> | null | undefined > {
        const response = await this.businessReportBetweenDateUsingPOSTRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 201:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * businessReportBetweenDate
     * @deprecated
     */
    async businessReportBetweenDateUsingPUTRaw(requestParameters: BusinessReportBetweenDateUsingPUTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<OrderDtoForReport>>> {
        if (requestParameters['endDate'] == null) {
            throw new runtime.RequiredError(
                'endDate',
                'Required parameter "endDate" was null or undefined when calling businessReportBetweenDateUsingPUT().'
            );
        }

        if (requestParameters['startDate'] == null) {
            throw new runtime.RequiredError(
                'startDate',
                'Required parameter "startDate" was null or undefined when calling businessReportBetweenDateUsingPUT().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['endDate'] != null) {
            queryParameters['endDate'] = requestParameters['endDate'];
        }

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        if (requestParameters['startDate'] != null) {
            queryParameters['startDate'] = requestParameters['startDate'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/report/businessReport`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(OrderDtoForReportFromJSON));
    }

    /**
     * businessReportBetweenDate
     * @deprecated
     */
    async businessReportBetweenDateUsingPUT(requestParameters: BusinessReportBetweenDateUsingPUTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<OrderDtoForReport> | null | undefined > {
        const response = await this.businessReportBetweenDateUsingPUTRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 201:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * businessReportBetweenDate
     * @deprecated
     */
    async businessReportBetweenDateUsingTRACERaw(requestParameters: BusinessReportBetweenDateUsingTRACERequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<OrderDtoForReport>>> {
        if (requestParameters['endDate'] == null) {
            throw new runtime.RequiredError(
                'endDate',
                'Required parameter "endDate" was null or undefined when calling businessReportBetweenDateUsingTRACE().'
            );
        }

        if (requestParameters['startDate'] == null) {
            throw new runtime.RequiredError(
                'startDate',
                'Required parameter "startDate" was null or undefined when calling businessReportBetweenDateUsingTRACE().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['endDate'] != null) {
            queryParameters['endDate'] = requestParameters['endDate'];
        }

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        if (requestParameters['startDate'] != null) {
            queryParameters['startDate'] = requestParameters['startDate'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/report/businessReport`,
            method: 'TRACE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(OrderDtoForReportFromJSON));
    }

    /**
     * businessReportBetweenDate
     * @deprecated
     */
    async businessReportBetweenDateUsingTRACE(requestParameters: BusinessReportBetweenDateUsingTRACERequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<OrderDtoForReport> | null | undefined > {
        const response = await this.businessReportBetweenDateUsingTRACERaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 204:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * couponCashDiscount
     * @deprecated
     */
    async couponCashDiscountUsingDELETE1Raw(requestParameters: CouponCashDiscountUsingDELETE1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<OrderDtoForReport>>> {
        const queryParameters: any = {};

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/report/couponCashDiscount`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(OrderDtoForReportFromJSON));
    }

    /**
     * couponCashDiscount
     * @deprecated
     */
    async couponCashDiscountUsingDELETE1(requestParameters: CouponCashDiscountUsingDELETE1Request = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<OrderDtoForReport> | null | undefined > {
        const response = await this.couponCashDiscountUsingDELETE1Raw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 204:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * couponCashDiscount
     * @deprecated
     */
    async couponCashDiscountUsingGET1Raw(requestParameters: CouponCashDiscountUsingGET1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<OrderDtoForReport>>> {
        const queryParameters: any = {};

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/report/couponCashDiscount`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(OrderDtoForReportFromJSON));
    }

    /**
     * couponCashDiscount
     * @deprecated
     */
    async couponCashDiscountUsingGET1(requestParameters: CouponCashDiscountUsingGET1Request = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<OrderDtoForReport>> {
        const response = await this.couponCashDiscountUsingGET1Raw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * couponCashDiscount
     * @deprecated
     */
    async couponCashDiscountUsingHEAD1Raw(requestParameters: CouponCashDiscountUsingHEAD1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<OrderDtoForReport>>> {
        const queryParameters: any = {};

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/report/couponCashDiscount`,
            method: 'HEAD',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(OrderDtoForReportFromJSON));
    }

    /**
     * couponCashDiscount
     * @deprecated
     */
    async couponCashDiscountUsingHEAD1(requestParameters: CouponCashDiscountUsingHEAD1Request = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<OrderDtoForReport> | null | undefined > {
        const response = await this.couponCashDiscountUsingHEAD1Raw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 204:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * couponCashDiscount
     * @deprecated
     */
    async couponCashDiscountUsingOPTIONS1Raw(requestParameters: CouponCashDiscountUsingOPTIONS1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<OrderDtoForReport>>> {
        const queryParameters: any = {};

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/report/couponCashDiscount`,
            method: 'OPTIONS',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(OrderDtoForReportFromJSON));
    }

    /**
     * couponCashDiscount
     * @deprecated
     */
    async couponCashDiscountUsingOPTIONS1(requestParameters: CouponCashDiscountUsingOPTIONS1Request = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<OrderDtoForReport> | null | undefined > {
        const response = await this.couponCashDiscountUsingOPTIONS1Raw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 204:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * couponCashDiscount
     * @deprecated
     */
    async couponCashDiscountUsingPATCH1Raw(requestParameters: CouponCashDiscountUsingPATCH1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<OrderDtoForReport>>> {
        const queryParameters: any = {};

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/report/couponCashDiscount`,
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(OrderDtoForReportFromJSON));
    }

    /**
     * couponCashDiscount
     * @deprecated
     */
    async couponCashDiscountUsingPATCH1(requestParameters: CouponCashDiscountUsingPATCH1Request = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<OrderDtoForReport> | null | undefined > {
        const response = await this.couponCashDiscountUsingPATCH1Raw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 204:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * couponCashDiscount
     * @deprecated
     */
    async couponCashDiscountUsingPOST1Raw(requestParameters: CouponCashDiscountUsingPOST1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<OrderDtoForReport>>> {
        const queryParameters: any = {};

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/report/couponCashDiscount`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(OrderDtoForReportFromJSON));
    }

    /**
     * couponCashDiscount
     * @deprecated
     */
    async couponCashDiscountUsingPOST1(requestParameters: CouponCashDiscountUsingPOST1Request = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<OrderDtoForReport> | null | undefined > {
        const response = await this.couponCashDiscountUsingPOST1Raw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 201:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * couponCashDiscount
     * @deprecated
     */
    async couponCashDiscountUsingPUT1Raw(requestParameters: CouponCashDiscountUsingPUT1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<OrderDtoForReport>>> {
        const queryParameters: any = {};

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/report/couponCashDiscount`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(OrderDtoForReportFromJSON));
    }

    /**
     * couponCashDiscount
     * @deprecated
     */
    async couponCashDiscountUsingPUT1(requestParameters: CouponCashDiscountUsingPUT1Request = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<OrderDtoForReport> | null | undefined > {
        const response = await this.couponCashDiscountUsingPUT1Raw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 201:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * couponCashDiscount
     * @deprecated
     */
    async couponCashDiscountUsingTRACE1Raw(requestParameters: CouponCashDiscountUsingTRACE1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<OrderDtoForReport>>> {
        const queryParameters: any = {};

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/report/couponCashDiscount`,
            method: 'TRACE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(OrderDtoForReportFromJSON));
    }

    /**
     * couponCashDiscount
     * @deprecated
     */
    async couponCashDiscountUsingTRACE1(requestParameters: CouponCashDiscountUsingTRACE1Request = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<OrderDtoForReport> | null | undefined > {
        const response = await this.couponCashDiscountUsingTRACE1Raw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 204:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * couponCodeDiscount
     * @deprecated
     */
    async couponCodeDiscountUsingDELETERaw(requestParameters: CouponCodeDiscountUsingDELETERequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<OrderDtoForReport>>> {
        if (requestParameters['endDate'] == null) {
            throw new runtime.RequiredError(
                'endDate',
                'Required parameter "endDate" was null or undefined when calling couponCodeDiscountUsingDELETE().'
            );
        }

        if (requestParameters['startDate'] == null) {
            throw new runtime.RequiredError(
                'startDate',
                'Required parameter "startDate" was null or undefined when calling couponCodeDiscountUsingDELETE().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['endDate'] != null) {
            queryParameters['endDate'] = requestParameters['endDate'];
        }

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        if (requestParameters['startDate'] != null) {
            queryParameters['startDate'] = requestParameters['startDate'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/report/couponCodeDiscount`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(OrderDtoForReportFromJSON));
    }

    /**
     * couponCodeDiscount
     * @deprecated
     */
    async couponCodeDiscountUsingDELETE(requestParameters: CouponCodeDiscountUsingDELETERequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<OrderDtoForReport> | null | undefined > {
        const response = await this.couponCodeDiscountUsingDELETERaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 204:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * couponCodeDiscount
     * @deprecated
     */
    async couponCodeDiscountUsingGETRaw(requestParameters: CouponCodeDiscountUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<OrderDtoForReport>>> {
        if (requestParameters['endDate'] == null) {
            throw new runtime.RequiredError(
                'endDate',
                'Required parameter "endDate" was null or undefined when calling couponCodeDiscountUsingGET().'
            );
        }

        if (requestParameters['startDate'] == null) {
            throw new runtime.RequiredError(
                'startDate',
                'Required parameter "startDate" was null or undefined when calling couponCodeDiscountUsingGET().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['endDate'] != null) {
            queryParameters['endDate'] = requestParameters['endDate'];
        }

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        if (requestParameters['startDate'] != null) {
            queryParameters['startDate'] = requestParameters['startDate'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/report/couponCodeDiscount`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(OrderDtoForReportFromJSON));
    }

    /**
     * couponCodeDiscount
     * @deprecated
     */
    async couponCodeDiscountUsingGET(requestParameters: CouponCodeDiscountUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<OrderDtoForReport>> {
        const response = await this.couponCodeDiscountUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * couponCodeDiscount
     * @deprecated
     */
    async couponCodeDiscountUsingHEADRaw(requestParameters: CouponCodeDiscountUsingHEADRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<OrderDtoForReport>>> {
        if (requestParameters['endDate'] == null) {
            throw new runtime.RequiredError(
                'endDate',
                'Required parameter "endDate" was null or undefined when calling couponCodeDiscountUsingHEAD().'
            );
        }

        if (requestParameters['startDate'] == null) {
            throw new runtime.RequiredError(
                'startDate',
                'Required parameter "startDate" was null or undefined when calling couponCodeDiscountUsingHEAD().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['endDate'] != null) {
            queryParameters['endDate'] = requestParameters['endDate'];
        }

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        if (requestParameters['startDate'] != null) {
            queryParameters['startDate'] = requestParameters['startDate'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/report/couponCodeDiscount`,
            method: 'HEAD',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(OrderDtoForReportFromJSON));
    }

    /**
     * couponCodeDiscount
     * @deprecated
     */
    async couponCodeDiscountUsingHEAD(requestParameters: CouponCodeDiscountUsingHEADRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<OrderDtoForReport> | null | undefined > {
        const response = await this.couponCodeDiscountUsingHEADRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 204:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * couponCodeDiscount
     * @deprecated
     */
    async couponCodeDiscountUsingOPTIONSRaw(requestParameters: CouponCodeDiscountUsingOPTIONSRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<OrderDtoForReport>>> {
        if (requestParameters['endDate'] == null) {
            throw new runtime.RequiredError(
                'endDate',
                'Required parameter "endDate" was null or undefined when calling couponCodeDiscountUsingOPTIONS().'
            );
        }

        if (requestParameters['startDate'] == null) {
            throw new runtime.RequiredError(
                'startDate',
                'Required parameter "startDate" was null or undefined when calling couponCodeDiscountUsingOPTIONS().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['endDate'] != null) {
            queryParameters['endDate'] = requestParameters['endDate'];
        }

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        if (requestParameters['startDate'] != null) {
            queryParameters['startDate'] = requestParameters['startDate'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/report/couponCodeDiscount`,
            method: 'OPTIONS',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(OrderDtoForReportFromJSON));
    }

    /**
     * couponCodeDiscount
     * @deprecated
     */
    async couponCodeDiscountUsingOPTIONS(requestParameters: CouponCodeDiscountUsingOPTIONSRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<OrderDtoForReport> | null | undefined > {
        const response = await this.couponCodeDiscountUsingOPTIONSRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 204:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * couponCodeDiscount
     * @deprecated
     */
    async couponCodeDiscountUsingPATCHRaw(requestParameters: CouponCodeDiscountUsingPATCHRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<OrderDtoForReport>>> {
        if (requestParameters['endDate'] == null) {
            throw new runtime.RequiredError(
                'endDate',
                'Required parameter "endDate" was null or undefined when calling couponCodeDiscountUsingPATCH().'
            );
        }

        if (requestParameters['startDate'] == null) {
            throw new runtime.RequiredError(
                'startDate',
                'Required parameter "startDate" was null or undefined when calling couponCodeDiscountUsingPATCH().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['endDate'] != null) {
            queryParameters['endDate'] = requestParameters['endDate'];
        }

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        if (requestParameters['startDate'] != null) {
            queryParameters['startDate'] = requestParameters['startDate'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/report/couponCodeDiscount`,
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(OrderDtoForReportFromJSON));
    }

    /**
     * couponCodeDiscount
     * @deprecated
     */
    async couponCodeDiscountUsingPATCH(requestParameters: CouponCodeDiscountUsingPATCHRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<OrderDtoForReport> | null | undefined > {
        const response = await this.couponCodeDiscountUsingPATCHRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 204:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * couponCodeDiscount
     * @deprecated
     */
    async couponCodeDiscountUsingPOSTRaw(requestParameters: CouponCodeDiscountUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<OrderDtoForReport>>> {
        if (requestParameters['endDate'] == null) {
            throw new runtime.RequiredError(
                'endDate',
                'Required parameter "endDate" was null or undefined when calling couponCodeDiscountUsingPOST().'
            );
        }

        if (requestParameters['startDate'] == null) {
            throw new runtime.RequiredError(
                'startDate',
                'Required parameter "startDate" was null or undefined when calling couponCodeDiscountUsingPOST().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['endDate'] != null) {
            queryParameters['endDate'] = requestParameters['endDate'];
        }

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        if (requestParameters['startDate'] != null) {
            queryParameters['startDate'] = requestParameters['startDate'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/report/couponCodeDiscount`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(OrderDtoForReportFromJSON));
    }

    /**
     * couponCodeDiscount
     * @deprecated
     */
    async couponCodeDiscountUsingPOST(requestParameters: CouponCodeDiscountUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<OrderDtoForReport> | null | undefined > {
        const response = await this.couponCodeDiscountUsingPOSTRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 201:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * couponCodeDiscount
     * @deprecated
     */
    async couponCodeDiscountUsingPUTRaw(requestParameters: CouponCodeDiscountUsingPUTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<OrderDtoForReport>>> {
        if (requestParameters['endDate'] == null) {
            throw new runtime.RequiredError(
                'endDate',
                'Required parameter "endDate" was null or undefined when calling couponCodeDiscountUsingPUT().'
            );
        }

        if (requestParameters['startDate'] == null) {
            throw new runtime.RequiredError(
                'startDate',
                'Required parameter "startDate" was null or undefined when calling couponCodeDiscountUsingPUT().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['endDate'] != null) {
            queryParameters['endDate'] = requestParameters['endDate'];
        }

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        if (requestParameters['startDate'] != null) {
            queryParameters['startDate'] = requestParameters['startDate'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/report/couponCodeDiscount`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(OrderDtoForReportFromJSON));
    }

    /**
     * couponCodeDiscount
     * @deprecated
     */
    async couponCodeDiscountUsingPUT(requestParameters: CouponCodeDiscountUsingPUTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<OrderDtoForReport> | null | undefined > {
        const response = await this.couponCodeDiscountUsingPUTRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 201:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * couponCodeDiscount
     * @deprecated
     */
    async couponCodeDiscountUsingTRACERaw(requestParameters: CouponCodeDiscountUsingTRACERequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<OrderDtoForReport>>> {
        if (requestParameters['endDate'] == null) {
            throw new runtime.RequiredError(
                'endDate',
                'Required parameter "endDate" was null or undefined when calling couponCodeDiscountUsingTRACE().'
            );
        }

        if (requestParameters['startDate'] == null) {
            throw new runtime.RequiredError(
                'startDate',
                'Required parameter "startDate" was null or undefined when calling couponCodeDiscountUsingTRACE().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['endDate'] != null) {
            queryParameters['endDate'] = requestParameters['endDate'];
        }

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        if (requestParameters['startDate'] != null) {
            queryParameters['startDate'] = requestParameters['startDate'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/report/couponCodeDiscount`,
            method: 'TRACE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(OrderDtoForReportFromJSON));
    }

    /**
     * couponCodeDiscount
     * @deprecated
     */
    async couponCodeDiscountUsingTRACE(requestParameters: CouponCodeDiscountUsingTRACERequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<OrderDtoForReport> | null | undefined > {
        const response = await this.couponCodeDiscountUsingTRACERaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 204:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * inventoryStocking
     * @deprecated
     */
    async inventoryStockingUsingDELETE1Raw(requestParameters: InventoryStockingUsingDELETE1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ProductDto>>> {
        const queryParameters: any = {};

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/report/inventoryStocking`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ProductDtoFromJSON));
    }

    /**
     * inventoryStocking
     * @deprecated
     */
    async inventoryStockingUsingDELETE1(requestParameters: InventoryStockingUsingDELETE1Request = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ProductDto> | null | undefined > {
        const response = await this.inventoryStockingUsingDELETE1Raw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 204:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * inventoryStocking
     * @deprecated
     */
    async inventoryStockingUsingGET1Raw(requestParameters: InventoryStockingUsingGET1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ProductDto>>> {
        const queryParameters: any = {};

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/report/inventoryStocking`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ProductDtoFromJSON));
    }

    /**
     * inventoryStocking
     * @deprecated
     */
    async inventoryStockingUsingGET1(requestParameters: InventoryStockingUsingGET1Request = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ProductDto>> {
        const response = await this.inventoryStockingUsingGET1Raw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * inventoryStocking
     * @deprecated
     */
    async inventoryStockingUsingHEAD1Raw(requestParameters: InventoryStockingUsingHEAD1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ProductDto>>> {
        const queryParameters: any = {};

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/report/inventoryStocking`,
            method: 'HEAD',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ProductDtoFromJSON));
    }

    /**
     * inventoryStocking
     * @deprecated
     */
    async inventoryStockingUsingHEAD1(requestParameters: InventoryStockingUsingHEAD1Request = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ProductDto> | null | undefined > {
        const response = await this.inventoryStockingUsingHEAD1Raw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 204:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * inventoryStocking
     * @deprecated
     */
    async inventoryStockingUsingOPTIONS1Raw(requestParameters: InventoryStockingUsingOPTIONS1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ProductDto>>> {
        const queryParameters: any = {};

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/report/inventoryStocking`,
            method: 'OPTIONS',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ProductDtoFromJSON));
    }

    /**
     * inventoryStocking
     * @deprecated
     */
    async inventoryStockingUsingOPTIONS1(requestParameters: InventoryStockingUsingOPTIONS1Request = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ProductDto> | null | undefined > {
        const response = await this.inventoryStockingUsingOPTIONS1Raw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 204:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * inventoryStocking
     * @deprecated
     */
    async inventoryStockingUsingPATCH1Raw(requestParameters: InventoryStockingUsingPATCH1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ProductDto>>> {
        const queryParameters: any = {};

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/report/inventoryStocking`,
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ProductDtoFromJSON));
    }

    /**
     * inventoryStocking
     * @deprecated
     */
    async inventoryStockingUsingPATCH1(requestParameters: InventoryStockingUsingPATCH1Request = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ProductDto> | null | undefined > {
        const response = await this.inventoryStockingUsingPATCH1Raw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 204:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * inventoryStocking
     * @deprecated
     */
    async inventoryStockingUsingPOST1Raw(requestParameters: InventoryStockingUsingPOST1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ProductDto>>> {
        const queryParameters: any = {};

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/report/inventoryStocking`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ProductDtoFromJSON));
    }

    /**
     * inventoryStocking
     * @deprecated
     */
    async inventoryStockingUsingPOST1(requestParameters: InventoryStockingUsingPOST1Request = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ProductDto> | null | undefined > {
        const response = await this.inventoryStockingUsingPOST1Raw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 201:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * inventoryStocking
     * @deprecated
     */
    async inventoryStockingUsingPUT1Raw(requestParameters: InventoryStockingUsingPUT1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ProductDto>>> {
        const queryParameters: any = {};

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/report/inventoryStocking`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ProductDtoFromJSON));
    }

    /**
     * inventoryStocking
     * @deprecated
     */
    async inventoryStockingUsingPUT1(requestParameters: InventoryStockingUsingPUT1Request = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ProductDto> | null | undefined > {
        const response = await this.inventoryStockingUsingPUT1Raw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 201:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * inventoryStocking
     * @deprecated
     */
    async inventoryStockingUsingTRACE1Raw(requestParameters: InventoryStockingUsingTRACE1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ProductDto>>> {
        const queryParameters: any = {};

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/report/inventoryStocking`,
            method: 'TRACE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ProductDtoFromJSON));
    }

    /**
     * inventoryStocking
     * @deprecated
     */
    async inventoryStockingUsingTRACE1(requestParameters: InventoryStockingUsingTRACE1Request = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ProductDto> | null | undefined > {
        const response = await this.inventoryStockingUsingTRACE1Raw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 204:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * memberPoint
     * @deprecated
     */
    async memberPointUsingDELETERaw(requestParameters: MemberPointUsingDELETERequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<MemberDto>>> {
        if (requestParameters['endDate'] == null) {
            throw new runtime.RequiredError(
                'endDate',
                'Required parameter "endDate" was null or undefined when calling memberPointUsingDELETE().'
            );
        }

        if (requestParameters['startDate'] == null) {
            throw new runtime.RequiredError(
                'startDate',
                'Required parameter "startDate" was null or undefined when calling memberPointUsingDELETE().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['endDate'] != null) {
            queryParameters['endDate'] = requestParameters['endDate'];
        }

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        if (requestParameters['startDate'] != null) {
            queryParameters['startDate'] = requestParameters['startDate'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/report/memberPoint`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(MemberDtoFromJSON));
    }

    /**
     * memberPoint
     * @deprecated
     */
    async memberPointUsingDELETE(requestParameters: MemberPointUsingDELETERequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<MemberDto> | null | undefined > {
        const response = await this.memberPointUsingDELETERaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 204:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * memberPoint
     * @deprecated
     */
    async memberPointUsingGETRaw(requestParameters: MemberPointUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<MemberDto>>> {
        if (requestParameters['endDate'] == null) {
            throw new runtime.RequiredError(
                'endDate',
                'Required parameter "endDate" was null or undefined when calling memberPointUsingGET().'
            );
        }

        if (requestParameters['startDate'] == null) {
            throw new runtime.RequiredError(
                'startDate',
                'Required parameter "startDate" was null or undefined when calling memberPointUsingGET().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['endDate'] != null) {
            queryParameters['endDate'] = requestParameters['endDate'];
        }

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        if (requestParameters['startDate'] != null) {
            queryParameters['startDate'] = requestParameters['startDate'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/report/memberPoint`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(MemberDtoFromJSON));
    }

    /**
     * memberPoint
     * @deprecated
     */
    async memberPointUsingGET(requestParameters: MemberPointUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<MemberDto>> {
        const response = await this.memberPointUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * memberPoint
     * @deprecated
     */
    async memberPointUsingHEADRaw(requestParameters: MemberPointUsingHEADRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<MemberDto>>> {
        if (requestParameters['endDate'] == null) {
            throw new runtime.RequiredError(
                'endDate',
                'Required parameter "endDate" was null or undefined when calling memberPointUsingHEAD().'
            );
        }

        if (requestParameters['startDate'] == null) {
            throw new runtime.RequiredError(
                'startDate',
                'Required parameter "startDate" was null or undefined when calling memberPointUsingHEAD().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['endDate'] != null) {
            queryParameters['endDate'] = requestParameters['endDate'];
        }

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        if (requestParameters['startDate'] != null) {
            queryParameters['startDate'] = requestParameters['startDate'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/report/memberPoint`,
            method: 'HEAD',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(MemberDtoFromJSON));
    }

    /**
     * memberPoint
     * @deprecated
     */
    async memberPointUsingHEAD(requestParameters: MemberPointUsingHEADRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<MemberDto> | null | undefined > {
        const response = await this.memberPointUsingHEADRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 204:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * memberPoint
     * @deprecated
     */
    async memberPointUsingOPTIONSRaw(requestParameters: MemberPointUsingOPTIONSRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<MemberDto>>> {
        if (requestParameters['endDate'] == null) {
            throw new runtime.RequiredError(
                'endDate',
                'Required parameter "endDate" was null or undefined when calling memberPointUsingOPTIONS().'
            );
        }

        if (requestParameters['startDate'] == null) {
            throw new runtime.RequiredError(
                'startDate',
                'Required parameter "startDate" was null or undefined when calling memberPointUsingOPTIONS().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['endDate'] != null) {
            queryParameters['endDate'] = requestParameters['endDate'];
        }

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        if (requestParameters['startDate'] != null) {
            queryParameters['startDate'] = requestParameters['startDate'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/report/memberPoint`,
            method: 'OPTIONS',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(MemberDtoFromJSON));
    }

    /**
     * memberPoint
     * @deprecated
     */
    async memberPointUsingOPTIONS(requestParameters: MemberPointUsingOPTIONSRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<MemberDto> | null | undefined > {
        const response = await this.memberPointUsingOPTIONSRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 204:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * memberPoint
     * @deprecated
     */
    async memberPointUsingPATCHRaw(requestParameters: MemberPointUsingPATCHRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<MemberDto>>> {
        if (requestParameters['endDate'] == null) {
            throw new runtime.RequiredError(
                'endDate',
                'Required parameter "endDate" was null or undefined when calling memberPointUsingPATCH().'
            );
        }

        if (requestParameters['startDate'] == null) {
            throw new runtime.RequiredError(
                'startDate',
                'Required parameter "startDate" was null or undefined when calling memberPointUsingPATCH().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['endDate'] != null) {
            queryParameters['endDate'] = requestParameters['endDate'];
        }

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        if (requestParameters['startDate'] != null) {
            queryParameters['startDate'] = requestParameters['startDate'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/report/memberPoint`,
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(MemberDtoFromJSON));
    }

    /**
     * memberPoint
     * @deprecated
     */
    async memberPointUsingPATCH(requestParameters: MemberPointUsingPATCHRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<MemberDto> | null | undefined > {
        const response = await this.memberPointUsingPATCHRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 204:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * memberPoint
     * @deprecated
     */
    async memberPointUsingPOSTRaw(requestParameters: MemberPointUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<MemberDto>>> {
        if (requestParameters['endDate'] == null) {
            throw new runtime.RequiredError(
                'endDate',
                'Required parameter "endDate" was null or undefined when calling memberPointUsingPOST().'
            );
        }

        if (requestParameters['startDate'] == null) {
            throw new runtime.RequiredError(
                'startDate',
                'Required parameter "startDate" was null or undefined when calling memberPointUsingPOST().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['endDate'] != null) {
            queryParameters['endDate'] = requestParameters['endDate'];
        }

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        if (requestParameters['startDate'] != null) {
            queryParameters['startDate'] = requestParameters['startDate'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/report/memberPoint`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(MemberDtoFromJSON));
    }

    /**
     * memberPoint
     * @deprecated
     */
    async memberPointUsingPOST(requestParameters: MemberPointUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<MemberDto> | null | undefined > {
        const response = await this.memberPointUsingPOSTRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 201:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * memberPoint
     * @deprecated
     */
    async memberPointUsingPUTRaw(requestParameters: MemberPointUsingPUTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<MemberDto>>> {
        if (requestParameters['endDate'] == null) {
            throw new runtime.RequiredError(
                'endDate',
                'Required parameter "endDate" was null or undefined when calling memberPointUsingPUT().'
            );
        }

        if (requestParameters['startDate'] == null) {
            throw new runtime.RequiredError(
                'startDate',
                'Required parameter "startDate" was null or undefined when calling memberPointUsingPUT().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['endDate'] != null) {
            queryParameters['endDate'] = requestParameters['endDate'];
        }

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        if (requestParameters['startDate'] != null) {
            queryParameters['startDate'] = requestParameters['startDate'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/report/memberPoint`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(MemberDtoFromJSON));
    }

    /**
     * memberPoint
     * @deprecated
     */
    async memberPointUsingPUT(requestParameters: MemberPointUsingPUTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<MemberDto> | null | undefined > {
        const response = await this.memberPointUsingPUTRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 201:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * memberPoint
     * @deprecated
     */
    async memberPointUsingTRACERaw(requestParameters: MemberPointUsingTRACERequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<MemberDto>>> {
        if (requestParameters['endDate'] == null) {
            throw new runtime.RequiredError(
                'endDate',
                'Required parameter "endDate" was null or undefined when calling memberPointUsingTRACE().'
            );
        }

        if (requestParameters['startDate'] == null) {
            throw new runtime.RequiredError(
                'startDate',
                'Required parameter "startDate" was null or undefined when calling memberPointUsingTRACE().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['endDate'] != null) {
            queryParameters['endDate'] = requestParameters['endDate'];
        }

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        if (requestParameters['startDate'] != null) {
            queryParameters['startDate'] = requestParameters['startDate'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/report/memberPoint`,
            method: 'TRACE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(MemberDtoFromJSON));
    }

    /**
     * memberPoint
     * @deprecated
     */
    async memberPointUsingTRACE(requestParameters: MemberPointUsingTRACERequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<MemberDto> | null | undefined > {
        const response = await this.memberPointUsingTRACERaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 204:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * pointProduct
     * @deprecated
     */
    async pointProductUsingDELETE1Raw(requestParameters: PointProductUsingDELETE1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<OrderProductDto2>>> {
        const queryParameters: any = {};

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/report/pointProduct`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(OrderProductDto2FromJSON));
    }

    /**
     * pointProduct
     * @deprecated
     */
    async pointProductUsingDELETE1(requestParameters: PointProductUsingDELETE1Request = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<OrderProductDto2> | null | undefined > {
        const response = await this.pointProductUsingDELETE1Raw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 204:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * pointProduct
     * @deprecated
     */
    async pointProductUsingGET1Raw(requestParameters: PointProductUsingGET1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<OrderProductDto2>>> {
        const queryParameters: any = {};

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/report/pointProduct`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(OrderProductDto2FromJSON));
    }

    /**
     * pointProduct
     * @deprecated
     */
    async pointProductUsingGET1(requestParameters: PointProductUsingGET1Request = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<OrderProductDto2>> {
        const response = await this.pointProductUsingGET1Raw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * pointProduct
     * @deprecated
     */
    async pointProductUsingHEAD1Raw(requestParameters: PointProductUsingHEAD1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<OrderProductDto2>>> {
        const queryParameters: any = {};

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/report/pointProduct`,
            method: 'HEAD',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(OrderProductDto2FromJSON));
    }

    /**
     * pointProduct
     * @deprecated
     */
    async pointProductUsingHEAD1(requestParameters: PointProductUsingHEAD1Request = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<OrderProductDto2> | null | undefined > {
        const response = await this.pointProductUsingHEAD1Raw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 204:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * pointProduct
     * @deprecated
     */
    async pointProductUsingOPTIONS1Raw(requestParameters: PointProductUsingOPTIONS1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<OrderProductDto2>>> {
        const queryParameters: any = {};

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/report/pointProduct`,
            method: 'OPTIONS',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(OrderProductDto2FromJSON));
    }

    /**
     * pointProduct
     * @deprecated
     */
    async pointProductUsingOPTIONS1(requestParameters: PointProductUsingOPTIONS1Request = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<OrderProductDto2> | null | undefined > {
        const response = await this.pointProductUsingOPTIONS1Raw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 204:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * pointProduct
     * @deprecated
     */
    async pointProductUsingPATCH1Raw(requestParameters: PointProductUsingPATCH1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<OrderProductDto2>>> {
        const queryParameters: any = {};

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/report/pointProduct`,
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(OrderProductDto2FromJSON));
    }

    /**
     * pointProduct
     * @deprecated
     */
    async pointProductUsingPATCH1(requestParameters: PointProductUsingPATCH1Request = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<OrderProductDto2> | null | undefined > {
        const response = await this.pointProductUsingPATCH1Raw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 204:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * pointProduct
     * @deprecated
     */
    async pointProductUsingPOST1Raw(requestParameters: PointProductUsingPOST1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<OrderProductDto2>>> {
        const queryParameters: any = {};

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/report/pointProduct`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(OrderProductDto2FromJSON));
    }

    /**
     * pointProduct
     * @deprecated
     */
    async pointProductUsingPOST1(requestParameters: PointProductUsingPOST1Request = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<OrderProductDto2> | null | undefined > {
        const response = await this.pointProductUsingPOST1Raw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 201:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * pointProduct
     * @deprecated
     */
    async pointProductUsingPUT1Raw(requestParameters: PointProductUsingPUT1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<OrderProductDto2>>> {
        const queryParameters: any = {};

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/report/pointProduct`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(OrderProductDto2FromJSON));
    }

    /**
     * pointProduct
     * @deprecated
     */
    async pointProductUsingPUT1(requestParameters: PointProductUsingPUT1Request = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<OrderProductDto2> | null | undefined > {
        const response = await this.pointProductUsingPUT1Raw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 201:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * pointProduct
     * @deprecated
     */
    async pointProductUsingTRACE1Raw(requestParameters: PointProductUsingTRACE1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<OrderProductDto2>>> {
        const queryParameters: any = {};

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/report/pointProduct`,
            method: 'TRACE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(OrderProductDto2FromJSON));
    }

    /**
     * pointProduct
     * @deprecated
     */
    async pointProductUsingTRACE1(requestParameters: PointProductUsingTRACE1Request = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<OrderProductDto2> | null | undefined > {
        const response = await this.pointProductUsingTRACE1Raw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 204:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * pointToCash
     * @deprecated
     */
    async pointToCashUsingDELETE1Raw(requestParameters: PointToCashUsingDELETE1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<OrderDtoForReport>>> {
        if (requestParameters['endDate'] == null) {
            throw new runtime.RequiredError(
                'endDate',
                'Required parameter "endDate" was null or undefined when calling pointToCashUsingDELETE1().'
            );
        }

        if (requestParameters['startDate'] == null) {
            throw new runtime.RequiredError(
                'startDate',
                'Required parameter "startDate" was null or undefined when calling pointToCashUsingDELETE1().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['endDate'] != null) {
            queryParameters['endDate'] = requestParameters['endDate'];
        }

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        if (requestParameters['startDate'] != null) {
            queryParameters['startDate'] = requestParameters['startDate'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/report/pointToCash`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(OrderDtoForReportFromJSON));
    }

    /**
     * pointToCash
     * @deprecated
     */
    async pointToCashUsingDELETE1(requestParameters: PointToCashUsingDELETE1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<OrderDtoForReport> | null | undefined > {
        const response = await this.pointToCashUsingDELETE1Raw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 204:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * pointToCash
     * @deprecated
     */
    async pointToCashUsingGET1Raw(requestParameters: PointToCashUsingGET1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<OrderDtoForReport>>> {
        if (requestParameters['endDate'] == null) {
            throw new runtime.RequiredError(
                'endDate',
                'Required parameter "endDate" was null or undefined when calling pointToCashUsingGET1().'
            );
        }

        if (requestParameters['startDate'] == null) {
            throw new runtime.RequiredError(
                'startDate',
                'Required parameter "startDate" was null or undefined when calling pointToCashUsingGET1().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['endDate'] != null) {
            queryParameters['endDate'] = requestParameters['endDate'];
        }

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        if (requestParameters['startDate'] != null) {
            queryParameters['startDate'] = requestParameters['startDate'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/report/pointToCash`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(OrderDtoForReportFromJSON));
    }

    /**
     * pointToCash
     * @deprecated
     */
    async pointToCashUsingGET1(requestParameters: PointToCashUsingGET1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<OrderDtoForReport>> {
        const response = await this.pointToCashUsingGET1Raw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * pointToCash
     * @deprecated
     */
    async pointToCashUsingHEAD1Raw(requestParameters: PointToCashUsingHEAD1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<OrderDtoForReport>>> {
        if (requestParameters['endDate'] == null) {
            throw new runtime.RequiredError(
                'endDate',
                'Required parameter "endDate" was null or undefined when calling pointToCashUsingHEAD1().'
            );
        }

        if (requestParameters['startDate'] == null) {
            throw new runtime.RequiredError(
                'startDate',
                'Required parameter "startDate" was null or undefined when calling pointToCashUsingHEAD1().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['endDate'] != null) {
            queryParameters['endDate'] = requestParameters['endDate'];
        }

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        if (requestParameters['startDate'] != null) {
            queryParameters['startDate'] = requestParameters['startDate'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/report/pointToCash`,
            method: 'HEAD',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(OrderDtoForReportFromJSON));
    }

    /**
     * pointToCash
     * @deprecated
     */
    async pointToCashUsingHEAD1(requestParameters: PointToCashUsingHEAD1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<OrderDtoForReport> | null | undefined > {
        const response = await this.pointToCashUsingHEAD1Raw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 204:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * pointToCash
     * @deprecated
     */
    async pointToCashUsingOPTIONS1Raw(requestParameters: PointToCashUsingOPTIONS1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<OrderDtoForReport>>> {
        if (requestParameters['endDate'] == null) {
            throw new runtime.RequiredError(
                'endDate',
                'Required parameter "endDate" was null or undefined when calling pointToCashUsingOPTIONS1().'
            );
        }

        if (requestParameters['startDate'] == null) {
            throw new runtime.RequiredError(
                'startDate',
                'Required parameter "startDate" was null or undefined when calling pointToCashUsingOPTIONS1().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['endDate'] != null) {
            queryParameters['endDate'] = requestParameters['endDate'];
        }

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        if (requestParameters['startDate'] != null) {
            queryParameters['startDate'] = requestParameters['startDate'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/report/pointToCash`,
            method: 'OPTIONS',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(OrderDtoForReportFromJSON));
    }

    /**
     * pointToCash
     * @deprecated
     */
    async pointToCashUsingOPTIONS1(requestParameters: PointToCashUsingOPTIONS1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<OrderDtoForReport> | null | undefined > {
        const response = await this.pointToCashUsingOPTIONS1Raw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 204:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * pointToCash
     * @deprecated
     */
    async pointToCashUsingPATCH1Raw(requestParameters: PointToCashUsingPATCH1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<OrderDtoForReport>>> {
        if (requestParameters['endDate'] == null) {
            throw new runtime.RequiredError(
                'endDate',
                'Required parameter "endDate" was null or undefined when calling pointToCashUsingPATCH1().'
            );
        }

        if (requestParameters['startDate'] == null) {
            throw new runtime.RequiredError(
                'startDate',
                'Required parameter "startDate" was null or undefined when calling pointToCashUsingPATCH1().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['endDate'] != null) {
            queryParameters['endDate'] = requestParameters['endDate'];
        }

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        if (requestParameters['startDate'] != null) {
            queryParameters['startDate'] = requestParameters['startDate'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/report/pointToCash`,
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(OrderDtoForReportFromJSON));
    }

    /**
     * pointToCash
     * @deprecated
     */
    async pointToCashUsingPATCH1(requestParameters: PointToCashUsingPATCH1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<OrderDtoForReport> | null | undefined > {
        const response = await this.pointToCashUsingPATCH1Raw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 204:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * pointToCash
     * @deprecated
     */
    async pointToCashUsingPOST1Raw(requestParameters: PointToCashUsingPOST1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<OrderDtoForReport>>> {
        if (requestParameters['endDate'] == null) {
            throw new runtime.RequiredError(
                'endDate',
                'Required parameter "endDate" was null or undefined when calling pointToCashUsingPOST1().'
            );
        }

        if (requestParameters['startDate'] == null) {
            throw new runtime.RequiredError(
                'startDate',
                'Required parameter "startDate" was null or undefined when calling pointToCashUsingPOST1().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['endDate'] != null) {
            queryParameters['endDate'] = requestParameters['endDate'];
        }

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        if (requestParameters['startDate'] != null) {
            queryParameters['startDate'] = requestParameters['startDate'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/report/pointToCash`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(OrderDtoForReportFromJSON));
    }

    /**
     * pointToCash
     * @deprecated
     */
    async pointToCashUsingPOST1(requestParameters: PointToCashUsingPOST1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<OrderDtoForReport> | null | undefined > {
        const response = await this.pointToCashUsingPOST1Raw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 201:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * pointToCash
     * @deprecated
     */
    async pointToCashUsingPUT1Raw(requestParameters: PointToCashUsingPUT1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<OrderDtoForReport>>> {
        if (requestParameters['endDate'] == null) {
            throw new runtime.RequiredError(
                'endDate',
                'Required parameter "endDate" was null or undefined when calling pointToCashUsingPUT1().'
            );
        }

        if (requestParameters['startDate'] == null) {
            throw new runtime.RequiredError(
                'startDate',
                'Required parameter "startDate" was null or undefined when calling pointToCashUsingPUT1().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['endDate'] != null) {
            queryParameters['endDate'] = requestParameters['endDate'];
        }

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        if (requestParameters['startDate'] != null) {
            queryParameters['startDate'] = requestParameters['startDate'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/report/pointToCash`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(OrderDtoForReportFromJSON));
    }

    /**
     * pointToCash
     * @deprecated
     */
    async pointToCashUsingPUT1(requestParameters: PointToCashUsingPUT1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<OrderDtoForReport> | null | undefined > {
        const response = await this.pointToCashUsingPUT1Raw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 201:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * pointToCash
     * @deprecated
     */
    async pointToCashUsingTRACE1Raw(requestParameters: PointToCashUsingTRACE1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<OrderDtoForReport>>> {
        if (requestParameters['endDate'] == null) {
            throw new runtime.RequiredError(
                'endDate',
                'Required parameter "endDate" was null or undefined when calling pointToCashUsingTRACE1().'
            );
        }

        if (requestParameters['startDate'] == null) {
            throw new runtime.RequiredError(
                'startDate',
                'Required parameter "startDate" was null or undefined when calling pointToCashUsingTRACE1().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['endDate'] != null) {
            queryParameters['endDate'] = requestParameters['endDate'];
        }

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        if (requestParameters['startDate'] != null) {
            queryParameters['startDate'] = requestParameters['startDate'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/report/pointToCash`,
            method: 'TRACE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(OrderDtoForReportFromJSON));
    }

    /**
     * pointToCash
     * @deprecated
     */
    async pointToCashUsingTRACE1(requestParameters: PointToCashUsingTRACE1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<OrderDtoForReport> | null | undefined > {
        const response = await this.pointToCashUsingTRACE1Raw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 204:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * pointToGift
     * @deprecated
     */
    async pointToGiftUsingDELETERaw(requestParameters: PointToGiftUsingDELETERequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<OrderProductDto2>>> {
        if (requestParameters['endDate'] == null) {
            throw new runtime.RequiredError(
                'endDate',
                'Required parameter "endDate" was null or undefined when calling pointToGiftUsingDELETE().'
            );
        }

        if (requestParameters['startDate'] == null) {
            throw new runtime.RequiredError(
                'startDate',
                'Required parameter "startDate" was null or undefined when calling pointToGiftUsingDELETE().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['endDate'] != null) {
            queryParameters['endDate'] = requestParameters['endDate'];
        }

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        if (requestParameters['startDate'] != null) {
            queryParameters['startDate'] = requestParameters['startDate'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/report/pointToGift`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(OrderProductDto2FromJSON));
    }

    /**
     * pointToGift
     * @deprecated
     */
    async pointToGiftUsingDELETE(requestParameters: PointToGiftUsingDELETERequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<OrderProductDto2> | null | undefined > {
        const response = await this.pointToGiftUsingDELETERaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 204:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * pointToGift
     * @deprecated
     */
    async pointToGiftUsingGETRaw(requestParameters: PointToGiftUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<OrderProductDto2>>> {
        if (requestParameters['endDate'] == null) {
            throw new runtime.RequiredError(
                'endDate',
                'Required parameter "endDate" was null or undefined when calling pointToGiftUsingGET().'
            );
        }

        if (requestParameters['startDate'] == null) {
            throw new runtime.RequiredError(
                'startDate',
                'Required parameter "startDate" was null or undefined when calling pointToGiftUsingGET().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['endDate'] != null) {
            queryParameters['endDate'] = requestParameters['endDate'];
        }

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        if (requestParameters['startDate'] != null) {
            queryParameters['startDate'] = requestParameters['startDate'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/report/pointToGift`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(OrderProductDto2FromJSON));
    }

    /**
     * pointToGift
     * @deprecated
     */
    async pointToGiftUsingGET(requestParameters: PointToGiftUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<OrderProductDto2>> {
        const response = await this.pointToGiftUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * pointToGift
     * @deprecated
     */
    async pointToGiftUsingHEADRaw(requestParameters: PointToGiftUsingHEADRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<OrderProductDto2>>> {
        if (requestParameters['endDate'] == null) {
            throw new runtime.RequiredError(
                'endDate',
                'Required parameter "endDate" was null or undefined when calling pointToGiftUsingHEAD().'
            );
        }

        if (requestParameters['startDate'] == null) {
            throw new runtime.RequiredError(
                'startDate',
                'Required parameter "startDate" was null or undefined when calling pointToGiftUsingHEAD().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['endDate'] != null) {
            queryParameters['endDate'] = requestParameters['endDate'];
        }

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        if (requestParameters['startDate'] != null) {
            queryParameters['startDate'] = requestParameters['startDate'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/report/pointToGift`,
            method: 'HEAD',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(OrderProductDto2FromJSON));
    }

    /**
     * pointToGift
     * @deprecated
     */
    async pointToGiftUsingHEAD(requestParameters: PointToGiftUsingHEADRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<OrderProductDto2> | null | undefined > {
        const response = await this.pointToGiftUsingHEADRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 204:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * pointToGift
     * @deprecated
     */
    async pointToGiftUsingOPTIONSRaw(requestParameters: PointToGiftUsingOPTIONSRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<OrderProductDto2>>> {
        if (requestParameters['endDate'] == null) {
            throw new runtime.RequiredError(
                'endDate',
                'Required parameter "endDate" was null or undefined when calling pointToGiftUsingOPTIONS().'
            );
        }

        if (requestParameters['startDate'] == null) {
            throw new runtime.RequiredError(
                'startDate',
                'Required parameter "startDate" was null or undefined when calling pointToGiftUsingOPTIONS().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['endDate'] != null) {
            queryParameters['endDate'] = requestParameters['endDate'];
        }

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        if (requestParameters['startDate'] != null) {
            queryParameters['startDate'] = requestParameters['startDate'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/report/pointToGift`,
            method: 'OPTIONS',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(OrderProductDto2FromJSON));
    }

    /**
     * pointToGift
     * @deprecated
     */
    async pointToGiftUsingOPTIONS(requestParameters: PointToGiftUsingOPTIONSRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<OrderProductDto2> | null | undefined > {
        const response = await this.pointToGiftUsingOPTIONSRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 204:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * pointToGift
     * @deprecated
     */
    async pointToGiftUsingPATCHRaw(requestParameters: PointToGiftUsingPATCHRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<OrderProductDto2>>> {
        if (requestParameters['endDate'] == null) {
            throw new runtime.RequiredError(
                'endDate',
                'Required parameter "endDate" was null or undefined when calling pointToGiftUsingPATCH().'
            );
        }

        if (requestParameters['startDate'] == null) {
            throw new runtime.RequiredError(
                'startDate',
                'Required parameter "startDate" was null or undefined when calling pointToGiftUsingPATCH().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['endDate'] != null) {
            queryParameters['endDate'] = requestParameters['endDate'];
        }

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        if (requestParameters['startDate'] != null) {
            queryParameters['startDate'] = requestParameters['startDate'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/report/pointToGift`,
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(OrderProductDto2FromJSON));
    }

    /**
     * pointToGift
     * @deprecated
     */
    async pointToGiftUsingPATCH(requestParameters: PointToGiftUsingPATCHRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<OrderProductDto2> | null | undefined > {
        const response = await this.pointToGiftUsingPATCHRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 204:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * pointToGift
     * @deprecated
     */
    async pointToGiftUsingPOSTRaw(requestParameters: PointToGiftUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<OrderProductDto2>>> {
        if (requestParameters['endDate'] == null) {
            throw new runtime.RequiredError(
                'endDate',
                'Required parameter "endDate" was null or undefined when calling pointToGiftUsingPOST().'
            );
        }

        if (requestParameters['startDate'] == null) {
            throw new runtime.RequiredError(
                'startDate',
                'Required parameter "startDate" was null or undefined when calling pointToGiftUsingPOST().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['endDate'] != null) {
            queryParameters['endDate'] = requestParameters['endDate'];
        }

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        if (requestParameters['startDate'] != null) {
            queryParameters['startDate'] = requestParameters['startDate'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/report/pointToGift`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(OrderProductDto2FromJSON));
    }

    /**
     * pointToGift
     * @deprecated
     */
    async pointToGiftUsingPOST(requestParameters: PointToGiftUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<OrderProductDto2> | null | undefined > {
        const response = await this.pointToGiftUsingPOSTRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 201:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * pointToGift
     * @deprecated
     */
    async pointToGiftUsingPUTRaw(requestParameters: PointToGiftUsingPUTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<OrderProductDto2>>> {
        if (requestParameters['endDate'] == null) {
            throw new runtime.RequiredError(
                'endDate',
                'Required parameter "endDate" was null or undefined when calling pointToGiftUsingPUT().'
            );
        }

        if (requestParameters['startDate'] == null) {
            throw new runtime.RequiredError(
                'startDate',
                'Required parameter "startDate" was null or undefined when calling pointToGiftUsingPUT().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['endDate'] != null) {
            queryParameters['endDate'] = requestParameters['endDate'];
        }

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        if (requestParameters['startDate'] != null) {
            queryParameters['startDate'] = requestParameters['startDate'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/report/pointToGift`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(OrderProductDto2FromJSON));
    }

    /**
     * pointToGift
     * @deprecated
     */
    async pointToGiftUsingPUT(requestParameters: PointToGiftUsingPUTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<OrderProductDto2> | null | undefined > {
        const response = await this.pointToGiftUsingPUTRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 201:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * pointToGift
     * @deprecated
     */
    async pointToGiftUsingTRACERaw(requestParameters: PointToGiftUsingTRACERequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<OrderProductDto2>>> {
        if (requestParameters['endDate'] == null) {
            throw new runtime.RequiredError(
                'endDate',
                'Required parameter "endDate" was null or undefined when calling pointToGiftUsingTRACE().'
            );
        }

        if (requestParameters['startDate'] == null) {
            throw new runtime.RequiredError(
                'startDate',
                'Required parameter "startDate" was null or undefined when calling pointToGiftUsingTRACE().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['endDate'] != null) {
            queryParameters['endDate'] = requestParameters['endDate'];
        }

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        if (requestParameters['startDate'] != null) {
            queryParameters['startDate'] = requestParameters['startDate'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/report/pointToGift`,
            method: 'TRACE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(OrderProductDto2FromJSON));
    }

    /**
     * pointToGift
     * @deprecated
     */
    async pointToGiftUsingTRACE(requestParameters: PointToGiftUsingTRACERequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<OrderProductDto2> | null | undefined > {
        const response = await this.pointToGiftUsingTRACERaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 204:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * productSales
     * @deprecated
     */
    async productSalesUsingDELETERaw(requestParameters: ProductSalesUsingDELETERequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<OrderProductDto2>>> {
        if (requestParameters['endDate'] == null) {
            throw new runtime.RequiredError(
                'endDate',
                'Required parameter "endDate" was null or undefined when calling productSalesUsingDELETE().'
            );
        }

        if (requestParameters['startDate'] == null) {
            throw new runtime.RequiredError(
                'startDate',
                'Required parameter "startDate" was null or undefined when calling productSalesUsingDELETE().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['endDate'] != null) {
            queryParameters['endDate'] = requestParameters['endDate'];
        }

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        if (requestParameters['startDate'] != null) {
            queryParameters['startDate'] = requestParameters['startDate'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/report/prodcutSales`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(OrderProductDto2FromJSON));
    }

    /**
     * productSales
     * @deprecated
     */
    async productSalesUsingDELETE(requestParameters: ProductSalesUsingDELETERequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<OrderProductDto2> | null | undefined > {
        const response = await this.productSalesUsingDELETERaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 204:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * productSales
     * @deprecated
     */
    async productSalesUsingGETRaw(requestParameters: ProductSalesUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<OrderProductDto2>>> {
        if (requestParameters['endDate'] == null) {
            throw new runtime.RequiredError(
                'endDate',
                'Required parameter "endDate" was null or undefined when calling productSalesUsingGET().'
            );
        }

        if (requestParameters['startDate'] == null) {
            throw new runtime.RequiredError(
                'startDate',
                'Required parameter "startDate" was null or undefined when calling productSalesUsingGET().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['endDate'] != null) {
            queryParameters['endDate'] = requestParameters['endDate'];
        }

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        if (requestParameters['startDate'] != null) {
            queryParameters['startDate'] = requestParameters['startDate'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/report/prodcutSales`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(OrderProductDto2FromJSON));
    }

    /**
     * productSales
     * @deprecated
     */
    async productSalesUsingGET(requestParameters: ProductSalesUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<OrderProductDto2>> {
        const response = await this.productSalesUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * productSales
     * @deprecated
     */
    async productSalesUsingHEADRaw(requestParameters: ProductSalesUsingHEADRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<OrderProductDto2>>> {
        if (requestParameters['endDate'] == null) {
            throw new runtime.RequiredError(
                'endDate',
                'Required parameter "endDate" was null or undefined when calling productSalesUsingHEAD().'
            );
        }

        if (requestParameters['startDate'] == null) {
            throw new runtime.RequiredError(
                'startDate',
                'Required parameter "startDate" was null or undefined when calling productSalesUsingHEAD().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['endDate'] != null) {
            queryParameters['endDate'] = requestParameters['endDate'];
        }

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        if (requestParameters['startDate'] != null) {
            queryParameters['startDate'] = requestParameters['startDate'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/report/prodcutSales`,
            method: 'HEAD',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(OrderProductDto2FromJSON));
    }

    /**
     * productSales
     * @deprecated
     */
    async productSalesUsingHEAD(requestParameters: ProductSalesUsingHEADRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<OrderProductDto2> | null | undefined > {
        const response = await this.productSalesUsingHEADRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 204:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * productSales
     * @deprecated
     */
    async productSalesUsingOPTIONSRaw(requestParameters: ProductSalesUsingOPTIONSRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<OrderProductDto2>>> {
        if (requestParameters['endDate'] == null) {
            throw new runtime.RequiredError(
                'endDate',
                'Required parameter "endDate" was null or undefined when calling productSalesUsingOPTIONS().'
            );
        }

        if (requestParameters['startDate'] == null) {
            throw new runtime.RequiredError(
                'startDate',
                'Required parameter "startDate" was null or undefined when calling productSalesUsingOPTIONS().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['endDate'] != null) {
            queryParameters['endDate'] = requestParameters['endDate'];
        }

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        if (requestParameters['startDate'] != null) {
            queryParameters['startDate'] = requestParameters['startDate'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/report/prodcutSales`,
            method: 'OPTIONS',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(OrderProductDto2FromJSON));
    }

    /**
     * productSales
     * @deprecated
     */
    async productSalesUsingOPTIONS(requestParameters: ProductSalesUsingOPTIONSRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<OrderProductDto2> | null | undefined > {
        const response = await this.productSalesUsingOPTIONSRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 204:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * productSales
     * @deprecated
     */
    async productSalesUsingPATCHRaw(requestParameters: ProductSalesUsingPATCHRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<OrderProductDto2>>> {
        if (requestParameters['endDate'] == null) {
            throw new runtime.RequiredError(
                'endDate',
                'Required parameter "endDate" was null or undefined when calling productSalesUsingPATCH().'
            );
        }

        if (requestParameters['startDate'] == null) {
            throw new runtime.RequiredError(
                'startDate',
                'Required parameter "startDate" was null or undefined when calling productSalesUsingPATCH().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['endDate'] != null) {
            queryParameters['endDate'] = requestParameters['endDate'];
        }

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        if (requestParameters['startDate'] != null) {
            queryParameters['startDate'] = requestParameters['startDate'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/report/prodcutSales`,
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(OrderProductDto2FromJSON));
    }

    /**
     * productSales
     * @deprecated
     */
    async productSalesUsingPATCH(requestParameters: ProductSalesUsingPATCHRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<OrderProductDto2> | null | undefined > {
        const response = await this.productSalesUsingPATCHRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 204:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * productSales
     * @deprecated
     */
    async productSalesUsingPOSTRaw(requestParameters: ProductSalesUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<OrderProductDto2>>> {
        if (requestParameters['endDate'] == null) {
            throw new runtime.RequiredError(
                'endDate',
                'Required parameter "endDate" was null or undefined when calling productSalesUsingPOST().'
            );
        }

        if (requestParameters['startDate'] == null) {
            throw new runtime.RequiredError(
                'startDate',
                'Required parameter "startDate" was null or undefined when calling productSalesUsingPOST().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['endDate'] != null) {
            queryParameters['endDate'] = requestParameters['endDate'];
        }

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        if (requestParameters['startDate'] != null) {
            queryParameters['startDate'] = requestParameters['startDate'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/report/prodcutSales`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(OrderProductDto2FromJSON));
    }

    /**
     * productSales
     * @deprecated
     */
    async productSalesUsingPOST(requestParameters: ProductSalesUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<OrderProductDto2> | null | undefined > {
        const response = await this.productSalesUsingPOSTRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 201:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * productSales
     * @deprecated
     */
    async productSalesUsingPUTRaw(requestParameters: ProductSalesUsingPUTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<OrderProductDto2>>> {
        if (requestParameters['endDate'] == null) {
            throw new runtime.RequiredError(
                'endDate',
                'Required parameter "endDate" was null or undefined when calling productSalesUsingPUT().'
            );
        }

        if (requestParameters['startDate'] == null) {
            throw new runtime.RequiredError(
                'startDate',
                'Required parameter "startDate" was null or undefined when calling productSalesUsingPUT().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['endDate'] != null) {
            queryParameters['endDate'] = requestParameters['endDate'];
        }

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        if (requestParameters['startDate'] != null) {
            queryParameters['startDate'] = requestParameters['startDate'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/report/prodcutSales`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(OrderProductDto2FromJSON));
    }

    /**
     * productSales
     * @deprecated
     */
    async productSalesUsingPUT(requestParameters: ProductSalesUsingPUTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<OrderProductDto2> | null | undefined > {
        const response = await this.productSalesUsingPUTRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 201:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * productSales
     * @deprecated
     */
    async productSalesUsingTRACERaw(requestParameters: ProductSalesUsingTRACERequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<OrderProductDto2>>> {
        if (requestParameters['endDate'] == null) {
            throw new runtime.RequiredError(
                'endDate',
                'Required parameter "endDate" was null or undefined when calling productSalesUsingTRACE().'
            );
        }

        if (requestParameters['startDate'] == null) {
            throw new runtime.RequiredError(
                'startDate',
                'Required parameter "startDate" was null or undefined when calling productSalesUsingTRACE().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['endDate'] != null) {
            queryParameters['endDate'] = requestParameters['endDate'];
        }

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        if (requestParameters['startDate'] != null) {
            queryParameters['startDate'] = requestParameters['startDate'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/report/prodcutSales`,
            method: 'TRACE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(OrderProductDto2FromJSON));
    }

    /**
     * productSales
     * @deprecated
     */
    async productSalesUsingTRACE(requestParameters: ProductSalesUsingTRACERequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<OrderProductDto2> | null | undefined > {
        const response = await this.productSalesUsingTRACERaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 204:
                return null;
            default:
                return await response.value();
        }
    }

}
