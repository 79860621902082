/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ArticleHashtag } from './ArticleHashtag';
import {
    ArticleHashtagFromJSON,
    ArticleHashtagFromJSONTyped,
    ArticleHashtagToJSON,
} from './ArticleHashtag';
import type { Category } from './Category';
import {
    CategoryFromJSON,
    CategoryFromJSONTyped,
    CategoryToJSON,
} from './Category';
import type { DisplayProductArticle } from './DisplayProductArticle';
import {
    DisplayProductArticleFromJSON,
    DisplayProductArticleFromJSONTyped,
    DisplayProductArticleToJSON,
} from './DisplayProductArticle';
import type { MarketingEventArticle } from './MarketingEventArticle';
import {
    MarketingEventArticleFromJSON,
    MarketingEventArticleFromJSONTyped,
    MarketingEventArticleToJSON,
} from './MarketingEventArticle';

/**
 * 
 * @export
 * @interface Article
 */
export interface Article {
    /**
     * 
     * @type {number}
     * @memberof Article
     */
    articleCategory?: number;
    /**
     * 
     * @type {string}
     * @memberof Article
     */
    articleContent?: string;
    /**
     * 
     * @type {string}
     * @memberof Article
     */
    articleCoverImage?: string;
    /**
     * 
     * @type {Array<ArticleHashtag>}
     * @memberof Article
     */
    articleHashtagsByArticleId?: Array<ArticleHashtag>;
    /**
     * 
     * @type {number}
     * @memberof Article
     */
    articleId?: number;
    /**
     * 
     * @type {string}
     * @memberof Article
     */
    articleTitle?: string;
    /**
     * 
     * @type {Category}
     * @memberof Article
     */
    categoryByArticleCategory?: Category;
    /**
     * 
     * @type {Array<DisplayProductArticle>}
     * @memberof Article
     */
    displayProductArticlesByArticleId?: Array<DisplayProductArticle>;
    /**
     * 
     * @type {Array<MarketingEventArticle>}
     * @memberof Article
     */
    marketingEventArticlesByArticleId?: Array<MarketingEventArticle>;
    /**
     * 
     * @type {Date}
     * @memberof Article
     */
    offShelfDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof Article
     */
    onShelfDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof Article
     */
    status?: string;
}

/**
 * Check if a given object implements the Article interface.
 */
export function instanceOfArticle(value: object): boolean {
    return true;
}

export function ArticleFromJSON(json: any): Article {
    return ArticleFromJSONTyped(json, false);
}

export function ArticleFromJSONTyped(json: any, ignoreDiscriminator: boolean): Article {
    if (json == null) {
        return json;
    }
    return {
        
        'articleCategory': json['articleCategory'] == null ? undefined : json['articleCategory'],
        'articleContent': json['articleContent'] == null ? undefined : json['articleContent'],
        'articleCoverImage': json['articleCoverImage'] == null ? undefined : json['articleCoverImage'],
        'articleHashtagsByArticleId': json['articleHashtagsByArticleId'] == null ? undefined : ((json['articleHashtagsByArticleId'] as Array<any>).map(ArticleHashtagFromJSON)),
        'articleId': json['articleId'] == null ? undefined : json['articleId'],
        'articleTitle': json['articleTitle'] == null ? undefined : json['articleTitle'],
        'categoryByArticleCategory': json['categoryByArticleCategory'] == null ? undefined : CategoryFromJSON(json['categoryByArticleCategory']),
        'displayProductArticlesByArticleId': json['displayProductArticlesByArticleId'] == null ? undefined : ((json['displayProductArticlesByArticleId'] as Array<any>).map(DisplayProductArticleFromJSON)),
        'marketingEventArticlesByArticleId': json['marketingEventArticlesByArticleId'] == null ? undefined : ((json['marketingEventArticlesByArticleId'] as Array<any>).map(MarketingEventArticleFromJSON)),
        'offShelfDate': json['offShelfDate'] == null ? undefined : (new Date(json['offShelfDate'])),
        'onShelfDate': json['onShelfDate'] == null ? undefined : (new Date(json['onShelfDate'])),
        'status': json['status'] == null ? undefined : json['status'],
    };
}

export function ArticleToJSON(value?: Article | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'articleCategory': value['articleCategory'],
        'articleContent': value['articleContent'],
        'articleCoverImage': value['articleCoverImage'],
        'articleHashtagsByArticleId': value['articleHashtagsByArticleId'] == null ? undefined : ((value['articleHashtagsByArticleId'] as Array<any>).map(ArticleHashtagToJSON)),
        'articleId': value['articleId'],
        'articleTitle': value['articleTitle'],
        'categoryByArticleCategory': CategoryToJSON(value['categoryByArticleCategory']),
        'displayProductArticlesByArticleId': value['displayProductArticlesByArticleId'] == null ? undefined : ((value['displayProductArticlesByArticleId'] as Array<any>).map(DisplayProductArticleToJSON)),
        'marketingEventArticlesByArticleId': value['marketingEventArticlesByArticleId'] == null ? undefined : ((value['marketingEventArticlesByArticleId'] as Array<any>).map(MarketingEventArticleToJSON)),
        'offShelfDate': value['offShelfDate'] == null ? undefined : ((value['offShelfDate']).toISOString()),
        'onShelfDate': value['onShelfDate'] == null ? undefined : ((value['onShelfDate']).toISOString()),
        'status': value['status'],
    };
}

