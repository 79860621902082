/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Member } from './Member';
import {
    MemberFromJSON,
    MemberFromJSONTyped,
    MemberToJSON,
} from './Member';

/**
 * 
 * @export
 * @interface UserPointHistory
 */
export interface UserPointHistory {
    /**
     * 
     * @type {Member}
     * @memberof UserPointHistory
     */
    memberByMemberId?: Member;
    /**
     * 
     * @type {number}
     * @memberof UserPointHistory
     */
    memberId?: number;
    /**
     * 
     * @type {number}
     * @memberof UserPointHistory
     */
    pointHistoryId?: number;
    /**
     * 
     * @type {string}
     * @memberof UserPointHistory
     */
    pointType?: string;
    /**
     * 
     * @type {number}
     * @memberof UserPointHistory
     */
    pointValue?: number;
}

/**
 * Check if a given object implements the UserPointHistory interface.
 */
export function instanceOfUserPointHistory(value: object): boolean {
    return true;
}

export function UserPointHistoryFromJSON(json: any): UserPointHistory {
    return UserPointHistoryFromJSONTyped(json, false);
}

export function UserPointHistoryFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserPointHistory {
    if (json == null) {
        return json;
    }
    return {
        
        'memberByMemberId': json['memberByMemberId'] == null ? undefined : MemberFromJSON(json['memberByMemberId']),
        'memberId': json['memberId'] == null ? undefined : json['memberId'],
        'pointHistoryId': json['pointHistoryId'] == null ? undefined : json['pointHistoryId'],
        'pointType': json['pointType'] == null ? undefined : json['pointType'],
        'pointValue': json['pointValue'] == null ? undefined : json['pointValue'],
    };
}

export function UserPointHistoryToJSON(value?: UserPointHistory | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'memberByMemberId': MemberToJSON(value['memberByMemberId']),
        'memberId': value['memberId'],
        'pointHistoryId': value['pointHistoryId'],
        'pointType': value['pointType'],
        'pointValue': value['pointValue'],
    };
}

