/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ModuleDescriptor
 */
export interface ModuleDescriptor {
    /**
     * 
     * @type {boolean}
     * @memberof ModuleDescriptor
     */
    automatic?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ModuleDescriptor
     */
    open?: boolean;
}

/**
 * Check if a given object implements the ModuleDescriptor interface.
 */
export function instanceOfModuleDescriptor(value: object): boolean {
    return true;
}

export function ModuleDescriptorFromJSON(json: any): ModuleDescriptor {
    return ModuleDescriptorFromJSONTyped(json, false);
}

export function ModuleDescriptorFromJSONTyped(json: any, ignoreDiscriminator: boolean): ModuleDescriptor {
    if (json == null) {
        return json;
    }
    return {
        
        'automatic': json['automatic'] == null ? undefined : json['automatic'],
        'open': json['open'] == null ? undefined : json['open'],
    };
}

export function ModuleDescriptorToJSON(value?: ModuleDescriptor | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'automatic': value['automatic'],
        'open': value['open'],
    };
}

