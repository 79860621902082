/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { CartProductDiscount } from './CartProductDiscount';
import {
    CartProductDiscountFromJSON,
    CartProductDiscountFromJSONTyped,
    CartProductDiscountToJSON,
} from './CartProductDiscount';
import type { MemberShoppingCart } from './MemberShoppingCart';
import {
    MemberShoppingCartFromJSON,
    MemberShoppingCartFromJSONTyped,
    MemberShoppingCartToJSON,
} from './MemberShoppingCart';
import type { Product } from './Product';
import {
    ProductFromJSON,
    ProductFromJSONTyped,
    ProductToJSON,
} from './Product';
import type { ProductPoint } from './ProductPoint';
import {
    ProductPointFromJSON,
    ProductPointFromJSONTyped,
    ProductPointToJSON,
} from './ProductPoint';

/**
 * 
 * @export
 * @interface CartProduct
 */
export interface CartProduct {
    /**
     * 
     * @type {Array<CartProductDiscount>}
     * @memberof CartProduct
     */
    cartProductDiscountsByCartProductId?: Array<CartProductDiscount>;
    /**
     * 
     * @type {number}
     * @memberof CartProduct
     */
    cartProductId?: number;
    /**
     * 
     * @type {number}
     * @memberof CartProduct
     */
    deliveryTimes?: number;
    /**
     * 
     * @type {MemberShoppingCart}
     * @memberof CartProduct
     */
    memberShoppingCartByShoppingCartId?: MemberShoppingCart;
    /**
     * 
     * @type {Product}
     * @memberof CartProduct
     */
    productByProductId?: Product;
    /**
     * 
     * @type {number}
     * @memberof CartProduct
     */
    productId?: number;
    /**
     * 
     * @type {ProductPoint}
     * @memberof CartProduct
     */
    productPointByProductPointId?: ProductPoint;
    /**
     * 
     * @type {number}
     * @memberof CartProduct
     */
    productPointId?: number;
    /**
     * 
     * @type {number}
     * @memberof CartProduct
     */
    quantity?: number;
    /**
     * 
     * @type {number}
     * @memberof CartProduct
     */
    shoppingCartId?: number;
}

/**
 * Check if a given object implements the CartProduct interface.
 */
export function instanceOfCartProduct(value: object): boolean {
    return true;
}

export function CartProductFromJSON(json: any): CartProduct {
    return CartProductFromJSONTyped(json, false);
}

export function CartProductFromJSONTyped(json: any, ignoreDiscriminator: boolean): CartProduct {
    if (json == null) {
        return json;
    }
    return {
        
        'cartProductDiscountsByCartProductId': json['cartProductDiscountsByCartProductId'] == null ? undefined : ((json['cartProductDiscountsByCartProductId'] as Array<any>).map(CartProductDiscountFromJSON)),
        'cartProductId': json['cartProductId'] == null ? undefined : json['cartProductId'],
        'deliveryTimes': json['deliveryTimes'] == null ? undefined : json['deliveryTimes'],
        'memberShoppingCartByShoppingCartId': json['memberShoppingCartByShoppingCartId'] == null ? undefined : MemberShoppingCartFromJSON(json['memberShoppingCartByShoppingCartId']),
        'productByProductId': json['productByProductId'] == null ? undefined : ProductFromJSON(json['productByProductId']),
        'productId': json['productId'] == null ? undefined : json['productId'],
        'productPointByProductPointId': json['productPointByProductPointId'] == null ? undefined : ProductPointFromJSON(json['productPointByProductPointId']),
        'productPointId': json['productPointId'] == null ? undefined : json['productPointId'],
        'quantity': json['quantity'] == null ? undefined : json['quantity'],
        'shoppingCartId': json['shoppingCartId'] == null ? undefined : json['shoppingCartId'],
    };
}

export function CartProductToJSON(value?: CartProduct | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'cartProductDiscountsByCartProductId': value['cartProductDiscountsByCartProductId'] == null ? undefined : ((value['cartProductDiscountsByCartProductId'] as Array<any>).map(CartProductDiscountToJSON)),
        'cartProductId': value['cartProductId'],
        'deliveryTimes': value['deliveryTimes'],
        'memberShoppingCartByShoppingCartId': MemberShoppingCartToJSON(value['memberShoppingCartByShoppingCartId']),
        'productByProductId': ProductToJSON(value['productByProductId']),
        'productId': value['productId'],
        'productPointByProductPointId': ProductPointToJSON(value['productPointByProductPointId']),
        'productPointId': value['productPointId'],
        'quantity': value['quantity'],
        'shoppingCartId': value['shoppingCartId'],
    };
}

