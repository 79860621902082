/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { FileUploadItemResponseModel } from './FileUploadItemResponseModel';
import {
    FileUploadItemResponseModelFromJSON,
    FileUploadItemResponseModelFromJSONTyped,
    FileUploadItemResponseModelToJSON,
} from './FileUploadItemResponseModel';

/**
 * 
 * @export
 * @interface FileUploadResponseModel
 */
export interface FileUploadResponseModel {
    /**
     * 
     * @type {number}
     * @memberof FileUploadResponseModel
     */
    fileLength?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof FileUploadResponseModel
     */
    fileNames?: Array<string>;
    /**
     * 
     * @type {Array<FileUploadItemResponseModel>}
     * @memberof FileUploadResponseModel
     */
    files?: Array<FileUploadItemResponseModel>;
}

/**
 * Check if a given object implements the FileUploadResponseModel interface.
 */
export function instanceOfFileUploadResponseModel(value: object): boolean {
    return true;
}

export function FileUploadResponseModelFromJSON(json: any): FileUploadResponseModel {
    return FileUploadResponseModelFromJSONTyped(json, false);
}

export function FileUploadResponseModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): FileUploadResponseModel {
    if (json == null) {
        return json;
    }
    return {
        
        'fileLength': json['fileLength'] == null ? undefined : json['fileLength'],
        'fileNames': json['fileNames'] == null ? undefined : json['fileNames'],
        'files': json['files'] == null ? undefined : ((json['files'] as Array<any>).map(FileUploadItemResponseModelFromJSON)),
    };
}

export function FileUploadResponseModelToJSON(value?: FileUploadResponseModel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'fileLength': value['fileLength'],
        'fileNames': value['fileNames'],
        'files': value['files'] == null ? undefined : ((value['files'] as Array<any>).map(FileUploadItemResponseModelToJSON)),
    };
}

