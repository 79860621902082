/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Coupon } from './Coupon';
import {
    CouponFromJSON,
    CouponFromJSONTyped,
    CouponToJSON,
} from './Coupon';
import type { Order } from './Order';
import {
    OrderFromJSON,
    OrderFromJSONTyped,
    OrderToJSON,
} from './Order';

/**
 * 
 * @export
 * @interface OrderCoupon
 */
export interface OrderCoupon {
    /**
     * 
     * @type {Coupon}
     * @memberof OrderCoupon
     */
    couponByCouponId?: Coupon;
    /**
     * 
     * @type {number}
     * @memberof OrderCoupon
     */
    couponId?: number;
    /**
     * 
     * @type {Order}
     * @memberof OrderCoupon
     */
    orderByOrderId?: Order;
    /**
     * 
     * @type {number}
     * @memberof OrderCoupon
     */
    orderId?: number;
}

/**
 * Check if a given object implements the OrderCoupon interface.
 */
export function instanceOfOrderCoupon(value: object): boolean {
    return true;
}

export function OrderCouponFromJSON(json: any): OrderCoupon {
    return OrderCouponFromJSONTyped(json, false);
}

export function OrderCouponFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrderCoupon {
    if (json == null) {
        return json;
    }
    return {
        
        'couponByCouponId': json['couponByCouponId'] == null ? undefined : CouponFromJSON(json['couponByCouponId']),
        'couponId': json['couponId'] == null ? undefined : json['couponId'],
        'orderByOrderId': json['orderByOrderId'] == null ? undefined : OrderFromJSON(json['orderByOrderId']),
        'orderId': json['orderId'] == null ? undefined : json['orderId'],
    };
}

export function OrderCouponToJSON(value?: OrderCoupon | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'couponByCouponId': CouponToJSON(value['couponByCouponId']),
        'couponId': value['couponId'],
        'orderByOrderId': OrderToJSON(value['orderByOrderId']),
        'orderId': value['orderId'],
    };
}

