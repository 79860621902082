/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Orderedmember
 */
export interface Orderedmember {
    /**
     * 
     * @type {string}
     * @memberof Orderedmember
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof Orderedmember
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof Orderedmember
     */
    lastName?: string;
    /**
     * 
     * @type {number}
     * @memberof Orderedmember
     */
    memberId?: number;
    /**
     * 
     * @type {number}
     * @memberof Orderedmember
     */
    orderId?: number;
    /**
     * 
     * @type {number}
     * @memberof Orderedmember
     */
    productId?: number;
}

/**
 * Check if a given object implements the Orderedmember interface.
 */
export function instanceOfOrderedmember(value: object): boolean {
    return true;
}

export function OrderedmemberFromJSON(json: any): Orderedmember {
    return OrderedmemberFromJSONTyped(json, false);
}

export function OrderedmemberFromJSONTyped(json: any, ignoreDiscriminator: boolean): Orderedmember {
    if (json == null) {
        return json;
    }
    return {
        
        'email': json['email'] == null ? undefined : json['email'],
        'firstName': json['firstName'] == null ? undefined : json['firstName'],
        'lastName': json['lastName'] == null ? undefined : json['lastName'],
        'memberId': json['memberId'] == null ? undefined : json['memberId'],
        'orderId': json['orderId'] == null ? undefined : json['orderId'],
        'productId': json['productId'] == null ? undefined : json['productId'],
    };
}

export function OrderedmemberToJSON(value?: Orderedmember | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'email': value['email'],
        'firstName': value['firstName'],
        'lastName': value['lastName'],
        'memberId': value['memberId'],
        'orderId': value['orderId'],
        'productId': value['productId'],
    };
}

