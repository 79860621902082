/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Discount } from './Discount';
import {
    DiscountFromJSON,
    DiscountFromJSONTyped,
    DiscountToJSON,
} from './Discount';
import type { MemberLevel } from './MemberLevel';
import {
    MemberLevelFromJSON,
    MemberLevelFromJSONTyped,
    MemberLevelToJSON,
} from './MemberLevel';

/**
 * 
 * @export
 * @interface MemberLevelDiscount
 */
export interface MemberLevelDiscount {
    /**
     * 
     * @type {Discount}
     * @memberof MemberLevelDiscount
     */
    discountByDiscountId?: Discount;
    /**
     * 
     * @type {number}
     * @memberof MemberLevelDiscount
     */
    discountId?: number;
    /**
     * 
     * @type {MemberLevel}
     * @memberof MemberLevelDiscount
     */
    memberLevelByMemberLevelId?: MemberLevel;
    /**
     * 
     * @type {number}
     * @memberof MemberLevelDiscount
     */
    memberLevelId?: number;
}

/**
 * Check if a given object implements the MemberLevelDiscount interface.
 */
export function instanceOfMemberLevelDiscount(value: object): boolean {
    return true;
}

export function MemberLevelDiscountFromJSON(json: any): MemberLevelDiscount {
    return MemberLevelDiscountFromJSONTyped(json, false);
}

export function MemberLevelDiscountFromJSONTyped(json: any, ignoreDiscriminator: boolean): MemberLevelDiscount {
    if (json == null) {
        return json;
    }
    return {
        
        'discountByDiscountId': json['discountByDiscountId'] == null ? undefined : DiscountFromJSON(json['discountByDiscountId']),
        'discountId': json['discountId'] == null ? undefined : json['discountId'],
        'memberLevelByMemberLevelId': json['memberLevelByMemberLevelId'] == null ? undefined : MemberLevelFromJSON(json['memberLevelByMemberLevelId']),
        'memberLevelId': json['memberLevelId'] == null ? undefined : json['memberLevelId'],
    };
}

export function MemberLevelDiscountToJSON(value?: MemberLevelDiscount | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'discountByDiscountId': DiscountToJSON(value['discountByDiscountId']),
        'discountId': value['discountId'],
        'memberLevelByMemberLevelId': MemberLevelToJSON(value['memberLevelByMemberLevelId']),
        'memberLevelId': value['memberLevelId'],
    };
}

