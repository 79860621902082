/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { LibFileObj } from './LibFileObj';
import {
    LibFileObjFromJSON,
    LibFileObjFromJSONTyped,
    LibFileObjToJSON,
} from './LibFileObj';

/**
 * 
 * @export
 * @interface ProductRequestModel
 */
export interface ProductRequestModel {
    /**
     * 
     * @type {string}
     * @memberof ProductRequestModel
     */
    author?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductRequestModel
     */
    bookComment?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductRequestModel
     */
    bookIsbn?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ProductRequestModel
     */
    categoryIds?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ProductRequestModel
     */
    coverFileName?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductRequestModel
     */
    coverFilePath?: string;
    /**
     * 
     * @type {number}
     * @memberof ProductRequestModel
     */
    coverFileSize?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductRequestModel
     */
    ctsId?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductRequestModel
     */
    epubPreviewFileMd5?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductRequestModel
     */
    epubPreviewFileName?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductRequestModel
     */
    epubPreviewFilePath?: string;
    /**
     * 
     * @type {number}
     * @memberof ProductRequestModel
     */
    epubPreviewFileSize?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductRequestModel
     */
    epubProdFileMd5?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductRequestModel
     */
    epubProdFileName?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductRequestModel
     */
    epubProdFilePath?: string;
    /**
     * 
     * @type {number}
     * @memberof ProductRequestModel
     */
    epubProdFileSize?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof ProductRequestModel
     */
    languageIds?: Array<string>;
    /**
     * 
     * @type {Array<LibFileObj>}
     * @memberof ProductRequestModel
     */
    multimediaFileNames?: Array<LibFileObj>;
    /**
     * 
     * @type {Date}
     * @memberof ProductRequestModel
     */
    onShelfDate?: Date;
    /**
     * 
     * @type {number}
     * @memberof ProductRequestModel
     */
    organizationId?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductRequestModel
     */
    productDescription?: string;
    /**
     * 
     * @type {number}
     * @memberof ProductRequestModel
     */
    productInventory?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductRequestModel
     */
    productNameChi?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductRequestModel
     */
    productNameEng?: string;
    /**
     * 
     * @type {number}
     * @memberof ProductRequestModel
     */
    productOriginalPrice?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductRequestModel
     */
    productPrice?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductRequestModel
     */
    productType?: string;
    /**
     * 
     * @type {Date}
     * @memberof ProductRequestModel
     */
    publishDate?: Date;
    /**
     * 
     * @type {Array<LibFileObj>}
     * @memberof ProductRequestModel
     */
    thumbFileNames?: Array<LibFileObj>;
    /**
     * 
     * @type {string}
     * @memberof ProductRequestModel
     */
    translator?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductRequestModel
     */
    version?: string;
}

/**
 * Check if a given object implements the ProductRequestModel interface.
 */
export function instanceOfProductRequestModel(value: object): boolean {
    return true;
}

export function ProductRequestModelFromJSON(json: any): ProductRequestModel {
    return ProductRequestModelFromJSONTyped(json, false);
}

export function ProductRequestModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProductRequestModel {
    if (json == null) {
        return json;
    }
    return {
        
        'author': json['author'] == null ? undefined : json['author'],
        'bookComment': json['bookComment'] == null ? undefined : json['bookComment'],
        'bookIsbn': json['bookIsbn'] == null ? undefined : json['bookIsbn'],
        'categoryIds': json['categoryIds'] == null ? undefined : json['categoryIds'],
        'coverFileName': json['coverFileName'] == null ? undefined : json['coverFileName'],
        'coverFilePath': json['coverFilePath'] == null ? undefined : json['coverFilePath'],
        'coverFileSize': json['coverFileSize'] == null ? undefined : json['coverFileSize'],
        'ctsId': json['ctsId'] == null ? undefined : json['ctsId'],
        'epubPreviewFileMd5': json['epubPreviewFileMd5'] == null ? undefined : json['epubPreviewFileMd5'],
        'epubPreviewFileName': json['epubPreviewFileName'] == null ? undefined : json['epubPreviewFileName'],
        'epubPreviewFilePath': json['epubPreviewFilePath'] == null ? undefined : json['epubPreviewFilePath'],
        'epubPreviewFileSize': json['epubPreviewFileSize'] == null ? undefined : json['epubPreviewFileSize'],
        'epubProdFileMd5': json['epubProdFileMd5'] == null ? undefined : json['epubProdFileMd5'],
        'epubProdFileName': json['epubProdFileName'] == null ? undefined : json['epubProdFileName'],
        'epubProdFilePath': json['epubProdFilePath'] == null ? undefined : json['epubProdFilePath'],
        'epubProdFileSize': json['epubProdFileSize'] == null ? undefined : json['epubProdFileSize'],
        'languageIds': json['languageIds'] == null ? undefined : json['languageIds'],
        'multimediaFileNames': json['multimediaFileNames'] == null ? undefined : ((json['multimediaFileNames'] as Array<any>).map(LibFileObjFromJSON)),
        'onShelfDate': json['onShelfDate'] == null ? undefined : (new Date(json['onShelfDate'])),
        'organizationId': json['organizationId'] == null ? undefined : json['organizationId'],
        'productDescription': json['productDescription'] == null ? undefined : json['productDescription'],
        'productInventory': json['productInventory'] == null ? undefined : json['productInventory'],
        'productNameChi': json['productNameChi'] == null ? undefined : json['productNameChi'],
        'productNameEng': json['productNameEng'] == null ? undefined : json['productNameEng'],
        'productOriginalPrice': json['productOriginalPrice'] == null ? undefined : json['productOriginalPrice'],
        'productPrice': json['productPrice'] == null ? undefined : json['productPrice'],
        'productType': json['productType'] == null ? undefined : json['productType'],
        'publishDate': json['publishDate'] == null ? undefined : (new Date(json['publishDate'])),
        'thumbFileNames': json['thumbFileNames'] == null ? undefined : ((json['thumbFileNames'] as Array<any>).map(LibFileObjFromJSON)),
        'translator': json['translator'] == null ? undefined : json['translator'],
        'version': json['version'] == null ? undefined : json['version'],
    };
}

export function ProductRequestModelToJSON(value?: ProductRequestModel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'author': value['author'],
        'bookComment': value['bookComment'],
        'bookIsbn': value['bookIsbn'],
        'categoryIds': value['categoryIds'],
        'coverFileName': value['coverFileName'],
        'coverFilePath': value['coverFilePath'],
        'coverFileSize': value['coverFileSize'],
        'ctsId': value['ctsId'],
        'epubPreviewFileMd5': value['epubPreviewFileMd5'],
        'epubPreviewFileName': value['epubPreviewFileName'],
        'epubPreviewFilePath': value['epubPreviewFilePath'],
        'epubPreviewFileSize': value['epubPreviewFileSize'],
        'epubProdFileMd5': value['epubProdFileMd5'],
        'epubProdFileName': value['epubProdFileName'],
        'epubProdFilePath': value['epubProdFilePath'],
        'epubProdFileSize': value['epubProdFileSize'],
        'languageIds': value['languageIds'],
        'multimediaFileNames': value['multimediaFileNames'] == null ? undefined : ((value['multimediaFileNames'] as Array<any>).map(LibFileObjToJSON)),
        'onShelfDate': value['onShelfDate'] == null ? undefined : ((value['onShelfDate']).toISOString()),
        'organizationId': value['organizationId'],
        'productDescription': value['productDescription'],
        'productInventory': value['productInventory'],
        'productNameChi': value['productNameChi'],
        'productNameEng': value['productNameEng'],
        'productOriginalPrice': value['productOriginalPrice'],
        'productPrice': value['productPrice'],
        'productType': value['productType'],
        'publishDate': value['publishDate'] == null ? undefined : ((value['publishDate']).toISOString()),
        'thumbFileNames': value['thumbFileNames'] == null ? undefined : ((value['thumbFileNames'] as Array<any>).map(LibFileObjToJSON)),
        'translator': value['translator'],
        'version': value['version'],
    };
}

