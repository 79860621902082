/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface IntegerListRequestModel
 */
export interface IntegerListRequestModel {
    /**
     * 
     * @type {Array<number>}
     * @memberof IntegerListRequestModel
     */
    integers?: Array<number>;
}

/**
 * Check if a given object implements the IntegerListRequestModel interface.
 */
export function instanceOfIntegerListRequestModel(value: object): boolean {
    return true;
}

export function IntegerListRequestModelFromJSON(json: any): IntegerListRequestModel {
    return IntegerListRequestModelFromJSONTyped(json, false);
}

export function IntegerListRequestModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): IntegerListRequestModel {
    if (json == null) {
        return json;
    }
    return {
        
        'integers': json['integers'] == null ? undefined : json['integers'],
    };
}

export function IntegerListRequestModelToJSON(value?: IntegerListRequestModel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'integers': value['integers'],
    };
}

