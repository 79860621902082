import React, { createContext, useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import Button from "../../components/Button/Button";
import Input from "../../components/Input/Input";
import style from "./LoginPage.module.css";
import FacebookIcon from "@mui/icons-material/Facebook";
import { loginPending, loginSuccess, loginFail } from "./loginSlice";
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import * as AuthenticationAPI from "../../api/AuthenticationAPI";
import { useTranslation } from "react-i18next";
import ForgetPassword from "./ForgetPassword";
import DialogBox from "../DialogBox/DialogBox";
import { STYLETYPE_MAIN, STYLETYPE_SECOND } from "../../utils/Constant";
import jwtDecode from "jwt-decode";

var sha512 = require("js-sha512");
// import {useNavigate,useLocation} from 'react-router-dom'
export const AuthContext = createContext(null);

export default function LoginPage() {
  // const [token, setToken] = useState<any>(null);

  const [active, setActive] = useState<boolean>(true);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isSubmit, setIsSubmit] = useState(false);
  const [remember, setRemember] = useState(false);
  const [toggleEmail, setToggleEmail] = useState(true);
  const [togglePhone, setTogglePhone] = useState(false);
  const { t } = useTranslation();
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);

  const activeLogin = () => {
    setActive(true);
  };
  const activeRegister = () => {
    setActive(false);
  };

  const handletoggle = (value: any) => {
    switch (value) {
      case "email":
        console.log(value);
        setToggleEmail(true);
        setTogglePhone(false);
        setActive(true);
        break;
      case "phone":
        setToggleEmail(false);
        setTogglePhone(true);
        setActive(false);
        break;
    }
  };

  const fetchLogin = () => {
    AuthenticationAPI.login(email, password);
  };

  const navigate = useNavigate();
  const location = useLocation();

  const handleKeyDown = (e: any) => {
    if (e.keyCode === 13) {
      fetchLogin();
    }
  };

  const dialogCallback = (action: string) => {
    switch (action) {
      case "close":
        setShowConfirmDialog(false);
    }
  };
  // console.log(navigate)
  // const history = useHistory()
  // const tokenApi  = useAuth()
  // console.log(tokenApi)

  // useEffect(() => {
  //   // const tokonApi = fakeAuth();
  //   const result = fectchApi();
  //   console.log(result);
  //   if (result !== null) {
  //     // setToken(tokonApi)
  //     setToken(result);
  //   }
  // }, []);

  // const fectchApi = async () => {
  //   const tokonApi = await fakeAuth();
  //   return tokonApi;
  // };

  // function onSignIn(googleUser: any) {
  //   var profile = googleUser.getBasicProfile();
  //   console.log("ID: " + profile.getId()); // Do not send to your backend! Use an ID token instead.
  //   console.log("Name: " + profile.getName());
  //   console.log("Image URL: " + profile.getImageUrl());
  //   console.log("Email: " + profile.getEmail()); // This is null if the 'email' scope is not present.
  // }

  function handleCallbackResponse(response: any) {
    console.log("response.credential", jwtDecode(response.credential));
    console.log("response", response);
    let form = new FormData();
    form.append("credential", response.credential);
    fetch(process.env.REACT_APP_BACKEND + "member/addOauth2", {
      body: form,
      method: "post",
    })
      .then((res) => {
        return res.json();
        //console.log("res", res.json());
      })
      .then((res) => {
        navigate("/register", { replace: true });
      });
  }

  useEffect(() => {
    /*global google */
    //window.location.href = `${process.env.REACT_APP_BACKEND}oauth2/authorization/google`;

    (window as any).google.accounts.id.initialize({
      client_id:
        "969536386921-bnaloa7citn1jirbd5v8nqp6orrcvujd.apps.googleusercontent.com",
      callback: handleCallbackResponse,
    });
    (window as any).google.accounts.id.renderButton(
      document.getElementById("signInDiv"),
      {
        theme: "outline",
        size: "large",
      }
    );
  }, []);
  return (
    <>
      <div className={style.tabButtonContainer}>
        <Button
          buttonType={"button"}
          styleType={"tabButtonCurrent"}
          callBack={(value) => navigate("/login")}
        >
          <p className={style.tabButtonText}>{t("signIn.memberSignIn")}</p>
        </Button>
        <Button
          buttonType={"button"}
          styleType={"tabButton"}
          callBack={(value) => navigate("/register")}
        >
          <p className={style.tabButtonText}>{t("signUp.signUp")}</p>
        </Button>
      </div>
      <div className={style.abc}>
        <div className={style.toggleGroupContainer}>
          <div className={style.toggleGroup}>
            {/* <div className={style.toggleSilder}>123</div> */}
            <Button
              buttonType={"button"}
              styleType={toggleEmail ? "toggleButtonCurrent" : "toggleButton"}
              callBack={(value) => handletoggle("email")}
              // onClick={activeLogin()}
            >
              {t("signIn.signInWithEmail")}
            </Button>
            {/* <Button
              buttonType={"button"}
              styleType={togglePhone ? "toggleButtonCurrent" : "toggleButton"}
              callBack={(value) => handletoggle("phone")}
            >
              以電話號碼登入
            </Button> */}
          </div>
        </div>
        <div
          id={active === true ? style.loginActive : style.loginNonActive}
          className={style.emailLogin}
        >
          <div
            className={style.loginInputContainer}
            onKeyDown={(e) => handleKeyDown(e)}
          >
            <div className={style.loginInnerInputContainer}>
              <Input
                type="text"
                placeholder={"signIn.emailAddress"}
                styleType={"loginInput"}
                callBack={(value) => setEmail(value)}
              ></Input>
              <Input
                type="password"
                placeholder={"signIn.password"}
                styleType={"loginInput"}
                callBack={(value) => setPassword(value)}
              ></Input>
              <div>
                <Button
                  buttonType={"button"}
                  styleType={"loginButton"}
                  callBack={(value) => fetchLogin()}
                >
                  {t("signIn.signIn")}
                </Button>
              </div>
              <div className={style.loginDetails}>

                <Input
                  id={"keepLogin"}
                  type="checkbox"
                  placeholder={""}
                  styleType={"checkbox"}
                  checked={remember}
                  callBack={(value) => setRemember(value)}
                  name={t("signIn.staySignedIn")}
                ></Input>

                <Button
                  buttonType={"button"}
                  styleType={"forgetButton"}
                  callBack={(value) => setShowConfirmDialog(true)}
                >
                  {t("signIn.forgotPassword")}
                </Button>
              </div>
            </div>
          </div>

          <div className={style.horizontalLineContainer}>
            <hr className={style.horizontalLine}></hr>
          </div>
          <div className={style.loginInputContainer}>
            <div className={style.loginInnerInputContainer2}>
              {/* <p className={style.otherLoginText}>
                {t("signIn.signInWithOtherMethods")}
              </p> */}
              {/* <Button
                buttonType={"button"}
                styleType={"facebookButton"}
                callBack={(value) => {
                  console.log("facebook login");
                  window.location.href = `${process.env.REACT_APP_BACKEND}oauth2/authorization/facebook`;
                }}
              >
                <div className={style.InnerButtonAlig}>
                  <FacebookIcon />
                  <p> {t("signIn.signInWithFacebook")}</p>
                </div>
              </Button> */}
              {/*
              <Button
                buttonType={"button"}
                styleType={"googleButton"}
                callBack={(value) =>
                  (window.location.href = `${process.env.REACT_APP_BACKEND}oauth2/authorization/google`)
                }
              >
                <div className={style.InnerButtonAlig}>
                  <span id={style.logo}>
                    <span className={style.red}></span>
                    <span className={style.yellow}></span>
                    <span className={style.green}></span>
                    <span className={style.gline}></span>
                    <span className={style.blue}></span>
                  </span>
                  <p>{t("signIn.signInWithGoogle")}</p>
                </div>
              </Button>
              */}
            </div>
          </div>
        </div>
      </div>

      <DialogBox
        styleType={STYLETYPE_SECOND}
        showDialog={showConfirmDialog}
        // p1={confirmDialogMessage}
        title={t("signIn.forgotPassword")}
        scroll={false}
      >
        <ForgetPassword callback={dialogCallback} />
      </DialogBox>
    </>
  );
}
