/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { MemberDto } from './MemberDto';
import {
    MemberDtoFromJSON,
    MemberDtoFromJSONTyped,
    MemberDtoToJSON,
} from './MemberDto';

/**
 * 
 * @export
 * @interface MemberLevelDto
 */
export interface MemberLevelDto {
    /**
     * 
     * @type {number}
     * @memberof MemberLevelDto
     */
    memberLevelId?: number;
    /**
     * 
     * @type {Array<MemberDto>}
     * @memberof MemberLevelDto
     */
    members?: Array<MemberDto>;
    /**
     * 
     * @type {string}
     * @memberof MemberLevelDto
     */
    membershipName?: string;
    /**
     * 
     * @type {string}
     * @memberof MemberLevelDto
     */
    membershipPeriod?: string;
    /**
     * 
     * @type {string}
     * @memberof MemberLevelDto
     */
    order?: string;
}

/**
 * Check if a given object implements the MemberLevelDto interface.
 */
export function instanceOfMemberLevelDto(value: object): boolean {
    return true;
}

export function MemberLevelDtoFromJSON(json: any): MemberLevelDto {
    return MemberLevelDtoFromJSONTyped(json, false);
}

export function MemberLevelDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): MemberLevelDto {
    if (json == null) {
        return json;
    }
    return {
        
        'memberLevelId': json['memberLevelId'] == null ? undefined : json['memberLevelId'],
        'members': json['members'] == null ? undefined : ((json['members'] as Array<any>).map(MemberDtoFromJSON)),
        'membershipName': json['membershipName'] == null ? undefined : json['membershipName'],
        'membershipPeriod': json['membershipPeriod'] == null ? undefined : json['membershipPeriod'],
        'order': json['order'] == null ? undefined : json['order'],
    };
}

export function MemberLevelDtoToJSON(value?: MemberLevelDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'memberLevelId': value['memberLevelId'],
        'members': value['members'] == null ? undefined : ((value['members'] as Array<any>).map(MemberDtoToJSON)),
        'membershipName': value['membershipName'],
        'membershipPeriod': value['membershipPeriod'],
        'order': value['order'],
    };
}

