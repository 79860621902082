/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MessageDto
 */
export interface MessageDto {
    /**
     * 
     * @type {string}
     * @memberof MessageDto
     */
    content?: string;
    /**
     * 
     * @type {number}
     * @memberof MessageDto
     */
    messageId?: number;
    /**
     * 
     * @type {Date}
     * @memberof MessageDto
     */
    scheduledSendTime?: Date;
    /**
     * 
     * @type {number}
     * @memberof MessageDto
     */
    status?: number;
    /**
     * 
     * @type {number}
     * @memberof MessageDto
     */
    targetAsBirthdayMember?: number;
    /**
     * 
     * @type {number}
     * @memberof MessageDto
     */
    targetAsBirthdayVipMember?: number;
    /**
     * 
     * @type {number}
     * @memberof MessageDto
     */
    targetAsMember?: number;
    /**
     * 
     * @type {number}
     * @memberof MessageDto
     */
    targetAsVipMember?: number;
    /**
     * 
     * @type {string}
     * @memberof MessageDto
     */
    title?: string;
}

/**
 * Check if a given object implements the MessageDto interface.
 */
export function instanceOfMessageDto(value: object): boolean {
    return true;
}

export function MessageDtoFromJSON(json: any): MessageDto {
    return MessageDtoFromJSONTyped(json, false);
}

export function MessageDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): MessageDto {
    if (json == null) {
        return json;
    }
    return {
        
        'content': json['content'] == null ? undefined : json['content'],
        'messageId': json['messageId'] == null ? undefined : json['messageId'],
        'scheduledSendTime': json['scheduledSendTime'] == null ? undefined : (new Date(json['scheduledSendTime'])),
        'status': json['status'] == null ? undefined : json['status'],
        'targetAsBirthdayMember': json['targetAsBirthdayMember'] == null ? undefined : json['targetAsBirthdayMember'],
        'targetAsBirthdayVipMember': json['targetAsBirthdayVipMember'] == null ? undefined : json['targetAsBirthdayVipMember'],
        'targetAsMember': json['targetAsMember'] == null ? undefined : json['targetAsMember'],
        'targetAsVipMember': json['targetAsVipMember'] == null ? undefined : json['targetAsVipMember'],
        'title': json['title'] == null ? undefined : json['title'],
    };
}

export function MessageDtoToJSON(value?: MessageDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'content': value['content'],
        'messageId': value['messageId'],
        'scheduledSendTime': value['scheduledSendTime'] == null ? undefined : ((value['scheduledSendTime']).toISOString()),
        'status': value['status'],
        'targetAsBirthdayMember': value['targetAsBirthdayMember'],
        'targetAsBirthdayVipMember': value['targetAsBirthdayVipMember'],
        'targetAsMember': value['targetAsMember'],
        'targetAsVipMember': value['targetAsVipMember'],
        'title': value['title'],
    };
}

