/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Environment
 */
export interface Environment {
    /**
     * 
     * @type {Array<string>}
     * @memberof Environment
     */
    activeProfiles?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof Environment
     */
    defaultProfiles?: Array<string>;
}

/**
 * Check if a given object implements the Environment interface.
 */
export function instanceOfEnvironment(value: object): boolean {
    return true;
}

export function EnvironmentFromJSON(json: any): Environment {
    return EnvironmentFromJSONTyped(json, false);
}

export function EnvironmentFromJSONTyped(json: any, ignoreDiscriminator: boolean): Environment {
    if (json == null) {
        return json;
    }
    return {
        
        'activeProfiles': json['activeProfiles'] == null ? undefined : json['activeProfiles'],
        'defaultProfiles': json['defaultProfiles'] == null ? undefined : json['defaultProfiles'],
    };
}

export function EnvironmentToJSON(value?: Environment | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'activeProfiles': value['activeProfiles'],
        'defaultProfiles': value['defaultProfiles'],
    };
}

