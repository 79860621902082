/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  Gospel,
  Resource,
} from '../models/index';
import {
    GospelFromJSON,
    GospelToJSON,
    ResourceFromJSON,
    ResourceToJSON,
} from '../models/index';

export interface GetGospelByDateUsingGETRequest {
    date: string;
}

export interface ImportGospelUsingPOSTRequest {
    file: Blob;
}

export interface SetGospelUsingPOSTRequest {
    date: string;
    enable?: boolean;
    chnContent?: string;
    engContent?: string;
    chnDateString?: string;
    engDateString?: string;
}

/**
 * 
 */
export class GospelControllerApi extends runtime.BaseAPI {

    /**
     * exportGospel
     */
    async exportGospelUsingGETRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Resource>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/gospel/export`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResourceFromJSON(jsonValue));
    }

    /**
     * exportGospel
     */
    async exportGospelUsingGET(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Resource> {
        const response = await this.exportGospelUsingGETRaw(initOverrides);
        return await response.value();
    }

    /**
     * getGospelByDate
     */
    async getGospelByDateUsingGETRaw(requestParameters: GetGospelByDateUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Gospel>> {
        if (requestParameters['date'] == null) {
            throw new runtime.RequiredError(
                'date',
                'Required parameter "date" was null or undefined when calling getGospelByDateUsingGET().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['date'] != null) {
            queryParameters['date'] = requestParameters['date'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/gospel/current`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GospelFromJSON(jsonValue));
    }

    /**
     * getGospelByDate
     */
    async getGospelByDateUsingGET(requestParameters: GetGospelByDateUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Gospel> {
        const response = await this.getGospelByDateUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getGospel
     */
    async getGospelUsingGETRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Gospel>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/gospel/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GospelFromJSON));
    }

    /**
     * getGospel
     */
    async getGospelUsingGET(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Gospel>> {
        const response = await this.getGospelUsingGETRaw(initOverrides);
        return await response.value();
    }

    /**
     * importGospel
     */
    async importGospelUsingPOSTRaw(requestParameters: ImportGospelUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<boolean>> {
        if (requestParameters['file'] == null) {
            throw new runtime.RequiredError(
                'file',
                'Required parameter "file" was null or undefined when calling importGospelUsingPOST().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters['file'] != null) {
            formParams.append('file', requestParameters['file'] as any);
        }

        const response = await this.request({
            path: `/gospel/import`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<boolean>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * importGospel
     */
    async importGospelUsingPOST(requestParameters: ImportGospelUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<boolean | null | undefined > {
        const response = await this.importGospelUsingPOSTRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 201:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * setGospel
     */
    async setGospelUsingPOSTRaw(requestParameters: SetGospelUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Gospel>> {
        if (requestParameters['date'] == null) {
            throw new runtime.RequiredError(
                'date',
                'Required parameter "date" was null or undefined when calling setGospelUsingPOST().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['date'] != null) {
            queryParameters['date'] = requestParameters['date'];
        }

        if (requestParameters['enable'] != null) {
            queryParameters['enable'] = requestParameters['enable'];
        }

        if (requestParameters['chnContent'] != null) {
            queryParameters['chnContent'] = requestParameters['chnContent'];
        }

        if (requestParameters['engContent'] != null) {
            queryParameters['engContent'] = requestParameters['engContent'];
        }

        if (requestParameters['chnDateString'] != null) {
            queryParameters['chnDateString'] = requestParameters['chnDateString'];
        }

        if (requestParameters['engDateString'] != null) {
            queryParameters['engDateString'] = requestParameters['engDateString'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/gospel/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GospelFromJSON(jsonValue));
    }

    /**
     * setGospel
     */
    async setGospelUsingPOST(requestParameters: SetGospelUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Gospel | null | undefined > {
        const response = await this.setGospelUsingPOSTRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 201:
                return null;
            default:
                return await response.value();
        }
    }

}
