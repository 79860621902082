/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Article } from './Article';
import {
    ArticleFromJSON,
    ArticleFromJSONTyped,
    ArticleToJSON,
} from './Article';
import type { DisplayProduct } from './DisplayProduct';
import {
    DisplayProductFromJSON,
    DisplayProductFromJSONTyped,
    DisplayProductToJSON,
} from './DisplayProduct';

/**
 * 
 * @export
 * @interface DisplayProductArticle
 */
export interface DisplayProductArticle {
    /**
     * 
     * @type {Article}
     * @memberof DisplayProductArticle
     */
    articleByArticleId?: Article;
    /**
     * 
     * @type {number}
     * @memberof DisplayProductArticle
     */
    articleId?: number;
    /**
     * 
     * @type {DisplayProduct}
     * @memberof DisplayProductArticle
     */
    displayProductByDisplayProductId?: DisplayProduct;
    /**
     * 
     * @type {number}
     * @memberof DisplayProductArticle
     */
    displayProductId?: number;
}

/**
 * Check if a given object implements the DisplayProductArticle interface.
 */
export function instanceOfDisplayProductArticle(value: object): boolean {
    return true;
}

export function DisplayProductArticleFromJSON(json: any): DisplayProductArticle {
    return DisplayProductArticleFromJSONTyped(json, false);
}

export function DisplayProductArticleFromJSONTyped(json: any, ignoreDiscriminator: boolean): DisplayProductArticle {
    if (json == null) {
        return json;
    }
    return {
        
        'articleByArticleId': json['articleByArticleId'] == null ? undefined : ArticleFromJSON(json['articleByArticleId']),
        'articleId': json['articleId'] == null ? undefined : json['articleId'],
        'displayProductByDisplayProductId': json['displayProductByDisplayProductId'] == null ? undefined : DisplayProductFromJSON(json['displayProductByDisplayProductId']),
        'displayProductId': json['displayProductId'] == null ? undefined : json['displayProductId'],
    };
}

export function DisplayProductArticleToJSON(value?: DisplayProductArticle | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'articleByArticleId': ArticleToJSON(value['articleByArticleId']),
        'articleId': value['articleId'],
        'displayProductByDisplayProductId': DisplayProductToJSON(value['displayProductByDisplayProductId']),
        'displayProductId': value['displayProductId'],
    };
}

