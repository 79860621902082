/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { CartProductDto } from './CartProductDto';
import {
    CartProductDtoFromJSON,
    CartProductDtoFromJSONTyped,
    CartProductDtoToJSON,
} from './CartProductDto';
import type { CouponDto } from './CouponDto';
import {
    CouponDtoFromJSON,
    CouponDtoFromJSONTyped,
    CouponDtoToJSON,
} from './CouponDto';
import type { MemberDto } from './MemberDto';
import {
    MemberDtoFromJSON,
    MemberDtoFromJSONTyped,
    MemberDtoToJSON,
} from './MemberDto';
import type { ProductPriceTotal } from './ProductPriceTotal';
import {
    ProductPriceTotalFromJSON,
    ProductPriceTotalFromJSONTyped,
    ProductPriceTotalToJSON,
} from './ProductPriceTotal';

/**
 * 
 * @export
 * @interface MemberShoppingCartDto
 */
export interface MemberShoppingCartDto {
    /**
     * 
     * @type {Array<CartProductDto>}
     * @memberof MemberShoppingCartDto
     */
    cartProducts?: Array<CartProductDto>;
    /**
     * 
     * @type {CouponDto}
     * @memberof MemberShoppingCartDto
     */
    coupon?: CouponDto;
    /**
     * 
     * @type {MemberDto}
     * @memberof MemberShoppingCartDto
     */
    member?: MemberDto;
    /**
     * 
     * @type {{ [key: string]: ProductPriceTotal; }}
     * @memberof MemberShoppingCartDto
     */
    productPriceTotalMap?: { [key: string]: ProductPriceTotal; };
    /**
     * 
     * @type {string}
     * @memberof MemberShoppingCartDto
     */
    promotionCode?: string;
    /**
     * 
     * @type {number}
     * @memberof MemberShoppingCartDto
     */
    shoppingCartId?: number;
    /**
     * 
     * @type {number}
     * @memberof MemberShoppingCartDto
     */
    total?: number;
    /**
     * 
     * @type {number}
     * @memberof MemberShoppingCartDto
     */
    totalQuantity?: number;
    /**
     * 
     * @type {number}
     * @memberof MemberShoppingCartDto
     */
    totalUsedPointForProduct?: number;
    /**
     * 
     * @type {number}
     * @memberof MemberShoppingCartDto
     */
    totalWithoutDiscount?: number;
}

/**
 * Check if a given object implements the MemberShoppingCartDto interface.
 */
export function instanceOfMemberShoppingCartDto(value: object): boolean {
    return true;
}

export function MemberShoppingCartDtoFromJSON(json: any): MemberShoppingCartDto {
    return MemberShoppingCartDtoFromJSONTyped(json, false);
}

export function MemberShoppingCartDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): MemberShoppingCartDto {
    if (json == null) {
        return json;
    }
    return {
        
        'cartProducts': json['cartProducts'] == null ? undefined : ((json['cartProducts'] as Array<any>).map(CartProductDtoFromJSON)),
        'coupon': json['coupon'] == null ? undefined : CouponDtoFromJSON(json['coupon']),
        'member': json['member'] == null ? undefined : MemberDtoFromJSON(json['member']),
        'productPriceTotalMap': json['productPriceTotalMap'] == null ? undefined : (mapValues(json['productPriceTotalMap'], ProductPriceTotalFromJSON)),
        'promotionCode': json['promotionCode'] == null ? undefined : json['promotionCode'],
        'shoppingCartId': json['shoppingCartId'] == null ? undefined : json['shoppingCartId'],
        'total': json['total'] == null ? undefined : json['total'],
        'totalQuantity': json['totalQuantity'] == null ? undefined : json['totalQuantity'],
        'totalUsedPointForProduct': json['totalUsedPointForProduct'] == null ? undefined : json['totalUsedPointForProduct'],
        'totalWithoutDiscount': json['totalWithoutDiscount'] == null ? undefined : json['totalWithoutDiscount'],
    };
}

export function MemberShoppingCartDtoToJSON(value?: MemberShoppingCartDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'cartProducts': value['cartProducts'] == null ? undefined : ((value['cartProducts'] as Array<any>).map(CartProductDtoToJSON)),
        'coupon': CouponDtoToJSON(value['coupon']),
        'member': MemberDtoToJSON(value['member']),
        'productPriceTotalMap': value['productPriceTotalMap'] == null ? undefined : (mapValues(value['productPriceTotalMap'], ProductPriceTotalToJSON)),
        'promotionCode': value['promotionCode'],
        'shoppingCartId': value['shoppingCartId'],
        'total': value['total'],
        'totalQuantity': value['totalQuantity'],
        'totalUsedPointForProduct': value['totalUsedPointForProduct'],
        'totalWithoutDiscount': value['totalWithoutDiscount'],
    };
}

