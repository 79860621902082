/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Package
 */
export interface Package {
    /**
     * 
     * @type {Array<object>}
     * @memberof Package
     */
    annotations?: Array<object>;
    /**
     * 
     * @type {Array<object>}
     * @memberof Package
     */
    declaredAnnotations?: Array<object>;
    /**
     * 
     * @type {string}
     * @memberof Package
     */
    implementationTitle?: string;
    /**
     * 
     * @type {string}
     * @memberof Package
     */
    implementationVendor?: string;
    /**
     * 
     * @type {string}
     * @memberof Package
     */
    implementationVersion?: string;
    /**
     * 
     * @type {string}
     * @memberof Package
     */
    name?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Package
     */
    sealed?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Package
     */
    specificationTitle?: string;
    /**
     * 
     * @type {string}
     * @memberof Package
     */
    specificationVendor?: string;
    /**
     * 
     * @type {string}
     * @memberof Package
     */
    specificationVersion?: string;
}

/**
 * Check if a given object implements the Package interface.
 */
export function instanceOfPackage(value: object): boolean {
    return true;
}

export function PackageFromJSON(json: any): Package {
    return PackageFromJSONTyped(json, false);
}

export function PackageFromJSONTyped(json: any, ignoreDiscriminator: boolean): Package {
    if (json == null) {
        return json;
    }
    return {
        
        'annotations': json['annotations'] == null ? undefined : json['annotations'],
        'declaredAnnotations': json['declaredAnnotations'] == null ? undefined : json['declaredAnnotations'],
        'implementationTitle': json['implementationTitle'] == null ? undefined : json['implementationTitle'],
        'implementationVendor': json['implementationVendor'] == null ? undefined : json['implementationVendor'],
        'implementationVersion': json['implementationVersion'] == null ? undefined : json['implementationVersion'],
        'name': json['name'] == null ? undefined : json['name'],
        'sealed': json['sealed'] == null ? undefined : json['sealed'],
        'specificationTitle': json['specificationTitle'] == null ? undefined : json['specificationTitle'],
        'specificationVendor': json['specificationVendor'] == null ? undefined : json['specificationVendor'],
        'specificationVersion': json['specificationVersion'] == null ? undefined : json['specificationVersion'],
    };
}

export function PackageToJSON(value?: Package | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'annotations': value['annotations'],
        'declaredAnnotations': value['declaredAnnotations'],
        'implementationTitle': value['implementationTitle'],
        'implementationVendor': value['implementationVendor'],
        'implementationVersion': value['implementationVersion'],
        'name': value['name'],
        'sealed': value['sealed'],
        'specificationTitle': value['specificationTitle'],
        'specificationVendor': value['specificationVendor'],
        'specificationVersion': value['specificationVersion'],
    };
}

