/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Language
 */
export interface Language {
    /**
     * 
     * @type {number}
     * @memberof Language
     */
    languageId?: number;
    /**
     * 
     * @type {string}
     * @memberof Language
     */
    mongoId?: string;
    /**
     * 
     * @type {string}
     * @memberof Language
     */
    name?: string;
    /**
     * 
     * @type {number}
     * @memberof Language
     */
    seq?: number;
    /**
     * 
     * @type {string}
     * @memberof Language
     */
    status?: string;
}

/**
 * Check if a given object implements the Language interface.
 */
export function instanceOfLanguage(value: object): boolean {
    return true;
}

export function LanguageFromJSON(json: any): Language {
    return LanguageFromJSONTyped(json, false);
}

export function LanguageFromJSONTyped(json: any, ignoreDiscriminator: boolean): Language {
    if (json == null) {
        return json;
    }
    return {
        
        'languageId': json['languageId'] == null ? undefined : json['languageId'],
        'mongoId': json['mongoId'] == null ? undefined : json['mongoId'],
        'name': json['name'] == null ? undefined : json['name'],
        'seq': json['seq'] == null ? undefined : json['seq'],
        'status': json['status'] == null ? undefined : json['status'],
    };
}

export function LanguageToJSON(value?: Language | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'languageId': value['languageId'],
        'mongoId': value['mongoId'],
        'name': value['name'],
        'seq': value['seq'],
        'status': value['status'],
    };
}

