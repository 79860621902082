/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AddGuestGWPResponseDto,
  MemberShoppingCartDto,
} from '../models/index';
import {
    AddGuestGWPResponseDtoFromJSON,
    AddGuestGWPResponseDtoToJSON,
    MemberShoppingCartDtoFromJSON,
    MemberShoppingCartDtoToJSON,
} from '../models/index';

export interface AddCartProductGwpForGuestUsingPOSTRequest {
    discountId: number;
    productListJson: string;
    name?: string;
    couponCode?: string;
}

export interface AddCartProductGwpUsingPOSTRequest {
    discountId: number;
    name?: string;
}

export interface AddCartProductPwpUsingPOSTRequest {
    name?: string;
    discountId?: number;
}

export interface AddCartProductUsingPOSTRequest {
    productId: number;
    name?: string;
    quantity?: number;
}

export interface AddCartProductUsingPointUsingPOSTRequest {
    productId: number;
    productPointId: number;
    name?: string;
    quantity?: number;
}

export interface ClearShoppingCartCouponUsingPOSTRequest {
    name?: string;
}

export interface ClearShoppingCartProductUsingPOSTRequest {
    name?: string;
}

export interface ClearShoppingCartPromotionCodeUsingPOSTRequest {
    name?: string;
}

export interface DeleteCartProductUsingPOSTRequest {
    productId: number;
    name?: string;
}

export interface DeleteCartProductUsingPointByMemberIdUsingPOSTRequest {
    productId: number;
    productPointId: number;
    name?: string;
}

export interface EmptyShoppingCartUsingPOSTRequest {
    name?: string;
}

export interface GetShoppingCartUsingGETRequest {
    name?: string;
}

export interface GetShoppingCartUsingGET1Request {
    name?: string;
}

export interface MinusCartProductGwpUsingPOSTRequest {
    discountId: number;
    name?: string;
}

export interface MinusCartProductPwpUsingPOSTRequest {
    name?: string;
    discountId?: number;
}

export interface MinusCartProductUsingPOSTRequest {
    productId: number;
    name?: string;
    quantity?: number;
}

export interface MinusCartProductUsingPointUsingPOSTRequest {
    productId: number;
    productPointId: number;
    name?: string;
    quantity?: number;
}

export interface SetCartProductDeliveryTimesUsingPOSTRequest {
    name?: string;
    productId?: number;
    deliveryTimes?: number;
}

export interface SetCartProductUsingPOSTRequest {
    productId: number;
    quantity: number;
    name?: string;
}

export interface SetCartProductUsingPointUsingPOSTRequest {
    productId: number;
    productPointId: number;
    quantity: number;
    name?: string;
}

export interface SetShoppingCartContentUsingPOSTRequest {
    productListJson: string;
    name?: string;
    promotionCode?: string;
}

export interface SetShoppingCartCouponUsingPOSTRequest {
    couponId: string;
    name?: string;
}

export interface SetShoppingCartPromotionCodeUsingPOSTRequest {
    promotionCode: string;
    name?: string;
}

export interface UpdateCartProductUsingPOSTRequest {
    productId: number;
    quantity: number;
    name?: string;
}

export interface UpdateCartProductUsingPointByMemberIdUsingPOSTRequest {
    productId: number;
    productPointId: number;
    quantity: number;
    name?: string;
}

/**
 * 
 */
export class ShoppingCartControllerApi extends runtime.BaseAPI {

    /**
     * addCartProductGwpForGuest
     */
    async addCartProductGwpForGuestUsingPOSTRaw(requestParameters: AddCartProductGwpForGuestUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<AddGuestGWPResponseDto>>> {
        if (requestParameters['discountId'] == null) {
            throw new runtime.RequiredError(
                'discountId',
                'Required parameter "discountId" was null or undefined when calling addCartProductGwpForGuestUsingPOST().'
            );
        }

        if (requestParameters['productListJson'] == null) {
            throw new runtime.RequiredError(
                'productListJson',
                'Required parameter "productListJson" was null or undefined when calling addCartProductGwpForGuestUsingPOST().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        if (requestParameters['discountId'] != null) {
            queryParameters['discountId'] = requestParameters['discountId'];
        }

        if (requestParameters['productListJson'] != null) {
            queryParameters['productListJson'] = requestParameters['productListJson'];
        }

        if (requestParameters['couponCode'] != null) {
            queryParameters['couponCode'] = requestParameters['couponCode'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/shoppingCart/cartProduct/gwp/guestadd`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(AddGuestGWPResponseDtoFromJSON));
    }

    /**
     * addCartProductGwpForGuest
     */
    async addCartProductGwpForGuestUsingPOST(requestParameters: AddCartProductGwpForGuestUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<AddGuestGWPResponseDto> | null | undefined > {
        const response = await this.addCartProductGwpForGuestUsingPOSTRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 201:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * addCartProductGwp
     */
    async addCartProductGwpUsingPOSTRaw(requestParameters: AddCartProductGwpUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MemberShoppingCartDto>> {
        if (requestParameters['discountId'] == null) {
            throw new runtime.RequiredError(
                'discountId',
                'Required parameter "discountId" was null or undefined when calling addCartProductGwpUsingPOST().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        if (requestParameters['discountId'] != null) {
            queryParameters['discountId'] = requestParameters['discountId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/shoppingCart/cartProduct/gwp/add`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MemberShoppingCartDtoFromJSON(jsonValue));
    }

    /**
     * addCartProductGwp
     */
    async addCartProductGwpUsingPOST(requestParameters: AddCartProductGwpUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MemberShoppingCartDto | null | undefined > {
        const response = await this.addCartProductGwpUsingPOSTRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 201:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * addCartProductPwp
     */
    async addCartProductPwpUsingPOSTRaw(requestParameters: AddCartProductPwpUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MemberShoppingCartDto>> {
        const queryParameters: any = {};

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        if (requestParameters['discountId'] != null) {
            queryParameters['discountId'] = requestParameters['discountId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/shoppingCart/cartProduct/pwp/add`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MemberShoppingCartDtoFromJSON(jsonValue));
    }

    /**
     * addCartProductPwp
     */
    async addCartProductPwpUsingPOST(requestParameters: AddCartProductPwpUsingPOSTRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MemberShoppingCartDto | null | undefined > {
        const response = await this.addCartProductPwpUsingPOSTRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 201:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * addCartProduct
     */
    async addCartProductUsingPOSTRaw(requestParameters: AddCartProductUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MemberShoppingCartDto>> {
        if (requestParameters['productId'] == null) {
            throw new runtime.RequiredError(
                'productId',
                'Required parameter "productId" was null or undefined when calling addCartProductUsingPOST().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        if (requestParameters['productId'] != null) {
            queryParameters['productId'] = requestParameters['productId'];
        }

        if (requestParameters['quantity'] != null) {
            queryParameters['quantity'] = requestParameters['quantity'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/shoppingCart/cartProduct/add`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MemberShoppingCartDtoFromJSON(jsonValue));
    }

    /**
     * addCartProduct
     */
    async addCartProductUsingPOST(requestParameters: AddCartProductUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MemberShoppingCartDto | null | undefined > {
        const response = await this.addCartProductUsingPOSTRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 201:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * addCartProductUsingPoint
     */
    async addCartProductUsingPointUsingPOSTRaw(requestParameters: AddCartProductUsingPointUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MemberShoppingCartDto>> {
        if (requestParameters['productId'] == null) {
            throw new runtime.RequiredError(
                'productId',
                'Required parameter "productId" was null or undefined when calling addCartProductUsingPointUsingPOST().'
            );
        }

        if (requestParameters['productPointId'] == null) {
            throw new runtime.RequiredError(
                'productPointId',
                'Required parameter "productPointId" was null or undefined when calling addCartProductUsingPointUsingPOST().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        if (requestParameters['productId'] != null) {
            queryParameters['productId'] = requestParameters['productId'];
        }

        if (requestParameters['productPointId'] != null) {
            queryParameters['productPointId'] = requestParameters['productPointId'];
        }

        if (requestParameters['quantity'] != null) {
            queryParameters['quantity'] = requestParameters['quantity'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/shoppingCart/cartProduct/usingPoint/add`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MemberShoppingCartDtoFromJSON(jsonValue));
    }

    /**
     * addCartProductUsingPoint
     */
    async addCartProductUsingPointUsingPOST(requestParameters: AddCartProductUsingPointUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MemberShoppingCartDto | null | undefined > {
        const response = await this.addCartProductUsingPointUsingPOSTRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 201:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * clearShoppingCartCoupon
     */
    async clearShoppingCartCouponUsingPOSTRaw(requestParameters: ClearShoppingCartCouponUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MemberShoppingCartDto>> {
        const queryParameters: any = {};

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/shoppingCart/coupon/delete`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MemberShoppingCartDtoFromJSON(jsonValue));
    }

    /**
     * clearShoppingCartCoupon
     */
    async clearShoppingCartCouponUsingPOST(requestParameters: ClearShoppingCartCouponUsingPOSTRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MemberShoppingCartDto | null | undefined > {
        const response = await this.clearShoppingCartCouponUsingPOSTRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 201:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * clearShoppingCartProduct
     */
    async clearShoppingCartProductUsingPOSTRaw(requestParameters: ClearShoppingCartProductUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MemberShoppingCartDto>> {
        const queryParameters: any = {};

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/shoppingCart/cartProduct/delete/all`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MemberShoppingCartDtoFromJSON(jsonValue));
    }

    /**
     * clearShoppingCartProduct
     */
    async clearShoppingCartProductUsingPOST(requestParameters: ClearShoppingCartProductUsingPOSTRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MemberShoppingCartDto | null | undefined > {
        const response = await this.clearShoppingCartProductUsingPOSTRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 201:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * clearShoppingCartPromotionCode
     */
    async clearShoppingCartPromotionCodeUsingPOSTRaw(requestParameters: ClearShoppingCartPromotionCodeUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MemberShoppingCartDto>> {
        const queryParameters: any = {};

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/shoppingCart/promotionCode/delete`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MemberShoppingCartDtoFromJSON(jsonValue));
    }

    /**
     * clearShoppingCartPromotionCode
     */
    async clearShoppingCartPromotionCodeUsingPOST(requestParameters: ClearShoppingCartPromotionCodeUsingPOSTRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MemberShoppingCartDto | null | undefined > {
        const response = await this.clearShoppingCartPromotionCodeUsingPOSTRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 201:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * deleteCartProduct
     */
    async deleteCartProductUsingPOSTRaw(requestParameters: DeleteCartProductUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MemberShoppingCartDto>> {
        if (requestParameters['productId'] == null) {
            throw new runtime.RequiredError(
                'productId',
                'Required parameter "productId" was null or undefined when calling deleteCartProductUsingPOST().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        if (requestParameters['productId'] != null) {
            queryParameters['productId'] = requestParameters['productId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/shoppingCart/cartProduct/delete`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MemberShoppingCartDtoFromJSON(jsonValue));
    }

    /**
     * deleteCartProduct
     */
    async deleteCartProductUsingPOST(requestParameters: DeleteCartProductUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MemberShoppingCartDto | null | undefined > {
        const response = await this.deleteCartProductUsingPOSTRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 201:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * deleteCartProductUsingPointByMemberId
     */
    async deleteCartProductUsingPointByMemberIdUsingPOSTRaw(requestParameters: DeleteCartProductUsingPointByMemberIdUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MemberShoppingCartDto>> {
        if (requestParameters['productId'] == null) {
            throw new runtime.RequiredError(
                'productId',
                'Required parameter "productId" was null or undefined when calling deleteCartProductUsingPointByMemberIdUsingPOST().'
            );
        }

        if (requestParameters['productPointId'] == null) {
            throw new runtime.RequiredError(
                'productPointId',
                'Required parameter "productPointId" was null or undefined when calling deleteCartProductUsingPointByMemberIdUsingPOST().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        if (requestParameters['productId'] != null) {
            queryParameters['productId'] = requestParameters['productId'];
        }

        if (requestParameters['productPointId'] != null) {
            queryParameters['productPointId'] = requestParameters['productPointId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/shoppingCart/cartProduct/usingPoint/delete`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MemberShoppingCartDtoFromJSON(jsonValue));
    }

    /**
     * deleteCartProductUsingPointByMemberId
     */
    async deleteCartProductUsingPointByMemberIdUsingPOST(requestParameters: DeleteCartProductUsingPointByMemberIdUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MemberShoppingCartDto | null | undefined > {
        const response = await this.deleteCartProductUsingPointByMemberIdUsingPOSTRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 201:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * emptyShoppingCart
     */
    async emptyShoppingCartUsingPOSTRaw(requestParameters: EmptyShoppingCartUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MemberShoppingCartDto>> {
        const queryParameters: any = {};

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/shoppingCart/clean`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MemberShoppingCartDtoFromJSON(jsonValue));
    }

    /**
     * emptyShoppingCart
     */
    async emptyShoppingCartUsingPOST(requestParameters: EmptyShoppingCartUsingPOSTRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MemberShoppingCartDto | null | undefined > {
        const response = await this.emptyShoppingCartUsingPOSTRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 201:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * getShoppingCart
     */
    async getShoppingCartUsingGETRaw(requestParameters: GetShoppingCartUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MemberShoppingCartDto>> {
        const queryParameters: any = {};

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/shoppingCart`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MemberShoppingCartDtoFromJSON(jsonValue));
    }

    /**
     * getShoppingCart
     */
    async getShoppingCartUsingGET(requestParameters: GetShoppingCartUsingGETRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MemberShoppingCartDto> {
        const response = await this.getShoppingCartUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getShoppingCart
     */
    async getShoppingCartUsingGET1Raw(requestParameters: GetShoppingCartUsingGET1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MemberShoppingCartDto>> {
        const queryParameters: any = {};

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/shoppingCart/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MemberShoppingCartDtoFromJSON(jsonValue));
    }

    /**
     * getShoppingCart
     */
    async getShoppingCartUsingGET1(requestParameters: GetShoppingCartUsingGET1Request = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MemberShoppingCartDto> {
        const response = await this.getShoppingCartUsingGET1Raw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * minusCartProductGwp
     */
    async minusCartProductGwpUsingPOSTRaw(requestParameters: MinusCartProductGwpUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MemberShoppingCartDto>> {
        if (requestParameters['discountId'] == null) {
            throw new runtime.RequiredError(
                'discountId',
                'Required parameter "discountId" was null or undefined when calling minusCartProductGwpUsingPOST().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        if (requestParameters['discountId'] != null) {
            queryParameters['discountId'] = requestParameters['discountId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/shoppingCart/cartProduct/gwp/delete`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MemberShoppingCartDtoFromJSON(jsonValue));
    }

    /**
     * minusCartProductGwp
     */
    async minusCartProductGwpUsingPOST(requestParameters: MinusCartProductGwpUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MemberShoppingCartDto | null | undefined > {
        const response = await this.minusCartProductGwpUsingPOSTRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 201:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * minusCartProductPwp
     */
    async minusCartProductPwpUsingPOSTRaw(requestParameters: MinusCartProductPwpUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MemberShoppingCartDto>> {
        const queryParameters: any = {};

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        if (requestParameters['discountId'] != null) {
            queryParameters['discountId'] = requestParameters['discountId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/shoppingCart/cartProduct/pwp/delete`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MemberShoppingCartDtoFromJSON(jsonValue));
    }

    /**
     * minusCartProductPwp
     */
    async minusCartProductPwpUsingPOST(requestParameters: MinusCartProductPwpUsingPOSTRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MemberShoppingCartDto | null | undefined > {
        const response = await this.minusCartProductPwpUsingPOSTRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 201:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * minusCartProduct
     */
    async minusCartProductUsingPOSTRaw(requestParameters: MinusCartProductUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MemberShoppingCartDto>> {
        if (requestParameters['productId'] == null) {
            throw new runtime.RequiredError(
                'productId',
                'Required parameter "productId" was null or undefined when calling minusCartProductUsingPOST().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        if (requestParameters['productId'] != null) {
            queryParameters['productId'] = requestParameters['productId'];
        }

        if (requestParameters['quantity'] != null) {
            queryParameters['quantity'] = requestParameters['quantity'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/shoppingCart/cartProduct/minus`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MemberShoppingCartDtoFromJSON(jsonValue));
    }

    /**
     * minusCartProduct
     */
    async minusCartProductUsingPOST(requestParameters: MinusCartProductUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MemberShoppingCartDto | null | undefined > {
        const response = await this.minusCartProductUsingPOSTRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 201:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * minusCartProductUsingPoint
     */
    async minusCartProductUsingPointUsingPOSTRaw(requestParameters: MinusCartProductUsingPointUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MemberShoppingCartDto>> {
        if (requestParameters['productId'] == null) {
            throw new runtime.RequiredError(
                'productId',
                'Required parameter "productId" was null or undefined when calling minusCartProductUsingPointUsingPOST().'
            );
        }

        if (requestParameters['productPointId'] == null) {
            throw new runtime.RequiredError(
                'productPointId',
                'Required parameter "productPointId" was null or undefined when calling minusCartProductUsingPointUsingPOST().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        if (requestParameters['productId'] != null) {
            queryParameters['productId'] = requestParameters['productId'];
        }

        if (requestParameters['productPointId'] != null) {
            queryParameters['productPointId'] = requestParameters['productPointId'];
        }

        if (requestParameters['quantity'] != null) {
            queryParameters['quantity'] = requestParameters['quantity'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/shoppingCart/cartProduct/usingPoint/minus`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MemberShoppingCartDtoFromJSON(jsonValue));
    }

    /**
     * minusCartProductUsingPoint
     */
    async minusCartProductUsingPointUsingPOST(requestParameters: MinusCartProductUsingPointUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MemberShoppingCartDto | null | undefined > {
        const response = await this.minusCartProductUsingPointUsingPOSTRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 201:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * setCartProductDeliveryTimes
     */
    async setCartProductDeliveryTimesUsingPOSTRaw(requestParameters: SetCartProductDeliveryTimesUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MemberShoppingCartDto>> {
        const queryParameters: any = {};

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        if (requestParameters['productId'] != null) {
            queryParameters['productId'] = requestParameters['productId'];
        }

        if (requestParameters['deliveryTimes'] != null) {
            queryParameters['deliveryTimes'] = requestParameters['deliveryTimes'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/shoppingCart/cartProduct/deliveryTimes`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MemberShoppingCartDtoFromJSON(jsonValue));
    }

    /**
     * setCartProductDeliveryTimes
     */
    async setCartProductDeliveryTimesUsingPOST(requestParameters: SetCartProductDeliveryTimesUsingPOSTRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MemberShoppingCartDto | null | undefined > {
        const response = await this.setCartProductDeliveryTimesUsingPOSTRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 201:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * setCartProduct
     */
    async setCartProductUsingPOSTRaw(requestParameters: SetCartProductUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MemberShoppingCartDto>> {
        if (requestParameters['productId'] == null) {
            throw new runtime.RequiredError(
                'productId',
                'Required parameter "productId" was null or undefined when calling setCartProductUsingPOST().'
            );
        }

        if (requestParameters['quantity'] == null) {
            throw new runtime.RequiredError(
                'quantity',
                'Required parameter "quantity" was null or undefined when calling setCartProductUsingPOST().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        if (requestParameters['productId'] != null) {
            queryParameters['productId'] = requestParameters['productId'];
        }

        if (requestParameters['quantity'] != null) {
            queryParameters['quantity'] = requestParameters['quantity'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/shoppingCart/cartProduct/set`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MemberShoppingCartDtoFromJSON(jsonValue));
    }

    /**
     * setCartProduct
     */
    async setCartProductUsingPOST(requestParameters: SetCartProductUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MemberShoppingCartDto | null | undefined > {
        const response = await this.setCartProductUsingPOSTRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 201:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * setCartProductUsingPoint
     */
    async setCartProductUsingPointUsingPOSTRaw(requestParameters: SetCartProductUsingPointUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MemberShoppingCartDto>> {
        if (requestParameters['productId'] == null) {
            throw new runtime.RequiredError(
                'productId',
                'Required parameter "productId" was null or undefined when calling setCartProductUsingPointUsingPOST().'
            );
        }

        if (requestParameters['productPointId'] == null) {
            throw new runtime.RequiredError(
                'productPointId',
                'Required parameter "productPointId" was null or undefined when calling setCartProductUsingPointUsingPOST().'
            );
        }

        if (requestParameters['quantity'] == null) {
            throw new runtime.RequiredError(
                'quantity',
                'Required parameter "quantity" was null or undefined when calling setCartProductUsingPointUsingPOST().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        if (requestParameters['productId'] != null) {
            queryParameters['productId'] = requestParameters['productId'];
        }

        if (requestParameters['productPointId'] != null) {
            queryParameters['productPointId'] = requestParameters['productPointId'];
        }

        if (requestParameters['quantity'] != null) {
            queryParameters['quantity'] = requestParameters['quantity'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/shoppingCart/cartProduct/usingPoint/set`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MemberShoppingCartDtoFromJSON(jsonValue));
    }

    /**
     * setCartProductUsingPoint
     */
    async setCartProductUsingPointUsingPOST(requestParameters: SetCartProductUsingPointUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MemberShoppingCartDto | null | undefined > {
        const response = await this.setCartProductUsingPointUsingPOSTRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 201:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * setShoppingCartContent
     */
    async setShoppingCartContentUsingPOSTRaw(requestParameters: SetShoppingCartContentUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MemberShoppingCartDto>> {
        if (requestParameters['productListJson'] == null) {
            throw new runtime.RequiredError(
                'productListJson',
                'Required parameter "productListJson" was null or undefined when calling setShoppingCartContentUsingPOST().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        if (requestParameters['productListJson'] != null) {
            queryParameters['productListJson'] = requestParameters['productListJson'];
        }

        if (requestParameters['promotionCode'] != null) {
            queryParameters['promotionCode'] = requestParameters['promotionCode'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/shoppingCart/setContent`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MemberShoppingCartDtoFromJSON(jsonValue));
    }

    /**
     * setShoppingCartContent
     */
    async setShoppingCartContentUsingPOST(requestParameters: SetShoppingCartContentUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MemberShoppingCartDto | null | undefined > {
        const response = await this.setShoppingCartContentUsingPOSTRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 201:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * setShoppingCartCoupon
     */
    async setShoppingCartCouponUsingPOSTRaw(requestParameters: SetShoppingCartCouponUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MemberShoppingCartDto>> {
        if (requestParameters['couponId'] == null) {
            throw new runtime.RequiredError(
                'couponId',
                'Required parameter "couponId" was null or undefined when calling setShoppingCartCouponUsingPOST().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        if (requestParameters['couponId'] != null) {
            queryParameters['couponId'] = requestParameters['couponId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/shoppingCart/coupon/set`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MemberShoppingCartDtoFromJSON(jsonValue));
    }

    /**
     * setShoppingCartCoupon
     */
    async setShoppingCartCouponUsingPOST(requestParameters: SetShoppingCartCouponUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MemberShoppingCartDto | null | undefined > {
        const response = await this.setShoppingCartCouponUsingPOSTRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 201:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * setShoppingCartPromotionCode
     */
    async setShoppingCartPromotionCodeUsingPOSTRaw(requestParameters: SetShoppingCartPromotionCodeUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MemberShoppingCartDto>> {
        if (requestParameters['promotionCode'] == null) {
            throw new runtime.RequiredError(
                'promotionCode',
                'Required parameter "promotionCode" was null or undefined when calling setShoppingCartPromotionCodeUsingPOST().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        if (requestParameters['promotionCode'] != null) {
            queryParameters['promotionCode'] = requestParameters['promotionCode'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/shoppingCart/promotionCode/set`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MemberShoppingCartDtoFromJSON(jsonValue));
    }

    /**
     * setShoppingCartPromotionCode
     */
    async setShoppingCartPromotionCodeUsingPOST(requestParameters: SetShoppingCartPromotionCodeUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MemberShoppingCartDto | null | undefined > {
        const response = await this.setShoppingCartPromotionCodeUsingPOSTRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 201:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * updateCartProduct
     */
    async updateCartProductUsingPOSTRaw(requestParameters: UpdateCartProductUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MemberShoppingCartDto>> {
        if (requestParameters['productId'] == null) {
            throw new runtime.RequiredError(
                'productId',
                'Required parameter "productId" was null or undefined when calling updateCartProductUsingPOST().'
            );
        }

        if (requestParameters['quantity'] == null) {
            throw new runtime.RequiredError(
                'quantity',
                'Required parameter "quantity" was null or undefined when calling updateCartProductUsingPOST().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        if (requestParameters['productId'] != null) {
            queryParameters['productId'] = requestParameters['productId'];
        }

        if (requestParameters['quantity'] != null) {
            queryParameters['quantity'] = requestParameters['quantity'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/shoppingCart/cartProduct/update`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MemberShoppingCartDtoFromJSON(jsonValue));
    }

    /**
     * updateCartProduct
     */
    async updateCartProductUsingPOST(requestParameters: UpdateCartProductUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MemberShoppingCartDto | null | undefined > {
        const response = await this.updateCartProductUsingPOSTRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 201:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * updateCartProductUsingPointByMemberId
     */
    async updateCartProductUsingPointByMemberIdUsingPOSTRaw(requestParameters: UpdateCartProductUsingPointByMemberIdUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MemberShoppingCartDto>> {
        if (requestParameters['productId'] == null) {
            throw new runtime.RequiredError(
                'productId',
                'Required parameter "productId" was null or undefined when calling updateCartProductUsingPointByMemberIdUsingPOST().'
            );
        }

        if (requestParameters['productPointId'] == null) {
            throw new runtime.RequiredError(
                'productPointId',
                'Required parameter "productPointId" was null or undefined when calling updateCartProductUsingPointByMemberIdUsingPOST().'
            );
        }

        if (requestParameters['quantity'] == null) {
            throw new runtime.RequiredError(
                'quantity',
                'Required parameter "quantity" was null or undefined when calling updateCartProductUsingPointByMemberIdUsingPOST().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        if (requestParameters['productId'] != null) {
            queryParameters['productId'] = requestParameters['productId'];
        }

        if (requestParameters['productPointId'] != null) {
            queryParameters['productPointId'] = requestParameters['productPointId'];
        }

        if (requestParameters['quantity'] != null) {
            queryParameters['quantity'] = requestParameters['quantity'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/shoppingCart/cartProduct/usingPoint/update`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MemberShoppingCartDtoFromJSON(jsonValue));
    }

    /**
     * updateCartProductUsingPointByMemberId
     */
    async updateCartProductUsingPointByMemberIdUsingPOST(requestParameters: UpdateCartProductUsingPointByMemberIdUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MemberShoppingCartDto | null | undefined > {
        const response = await this.updateCartProductUsingPointByMemberIdUsingPOSTRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 201:
                return null;
            default:
                return await response.value();
        }
    }

}
