import { getFileExtension } from "./GlobalFunction";
import { getMd5WithBrowser } from "./reader/fileUtils/md5Util";
import api from "../api/api";
import BookUtil from "./reader/fileUtils/bookUtil";
import RecentBooks from "./reader/readUtils/recordRecent";
import localforage from "localforage";
import { addEpub } from "./reader/fileUtils/epubUtil";
import StorageUtil from "./reader/serviceUtils/storageUtil";

export const openBook_old = (props: any) => {
  console.log("propspreview231", props);

  if (props.ePubProdFileName !== undefined) {
    var fileName = props["ePubProdFileName"];
    console.log("fileName", fileName);
    api.getFileByFileName(fileName).then((fileResult: any) => {
      fileResult.blob().then(async (blob: any) => {
        console.log("blob", blob);
        var file = new File([blob], fileName, {
          lastModified: new Date().getTime(),
        });

        var fileExtension = getFileExtension(fileName);

        await getMd5WithBrowser(file).then(async (md5) => {
          console.log("md5", md5);
          if (fileExtension === "pdf") {
            let reader = new FileReader();
            reader.readAsArrayBuffer(blob);
            reader.onload = async (event) => {
              const file_content = event?.target?.result;
              BookUtil.generateBook(
                fileName,
                fileExtension,
                md5,
                blob.size,
                fileName,
                file_content
              ).then(async (bookObj) => {
                await BookUtil.addBook(bookObj.key, file_content);
                RecentBooks.setRecent(bookObj.key);
                localforage.setItem("books", bookObj);
                openBookClick(bookObj, props.displayProductNameChi);
              });
            };
          }
          console.log("fileExtension", fileExtension === "epub");
          if (fileExtension === "epub") {
            addEpub(file, md5, fileName).then(async (bookObj) => {
              console.log("bookObj", bookObj);
              await BookUtil.addBook(bookObj.key, file);
              RecentBooks.setRecent(bookObj.key);
              localforage.setItem("books", bookObj);
              openBookClick(bookObj, props);
            });
          }
        });
      });
    });
  }
};

export const openBook = (props: any, setLoader: any) => {
  if (props["epubProdFileName"] !== undefined) {
    const fileName = props["epubProdFileName"];
    console.log("fileName", fileName);
    api
      .getFileByString(fileName)
      .then(async (blob: any) => {
        const file = new File([blob], fileName, {
          lastModified: new Date().getTime(),
        });
        console.log("file", file);

        const fileExtension = getFileExtension(fileName);

        await getMd5WithBrowser(file).then(async (md5) => {
          if (fileExtension === "pdf") {
            let reader = new FileReader();
            reader.readAsArrayBuffer(blob);
            reader.onload = async (event) => {
              const file_content = event?.target?.result;
              BookUtil.generateBook(
                fileName,
                fileExtension,
                md5,
                blob.size,
                fileName,
                file_content
              ).then(async (bookObj) => {
                //console.log("ffff", bookObj);
                await BookUtil.addBook(bookObj.key, file_content);
                RecentBooks.setRecent(bookObj.key);
                localforage.setItem("books", bookObj);
                openBookClick(bookObj, props);
              });
            };
          }

          if (fileExtension === "epub") {
            addEpub(file, md5, fileName).then(async (bookObj) => {
              await BookUtil.addBook(bookObj.key, file);
              RecentBooks.setRecent(bookObj.key);
              localforage.setItem("books", bookObj);
              openBookClick(bookObj, props);
            });
          }
        });
      })
      .finally(() => {
        setLoader(false);
      });
  }
};
export const openCdnBook = (props: any, setLoader: any, encryptedPath: string, type: 'preview' | 'prod') => {
  if (
    props["epubProdFileName"] !== undefined
    || props["epubPreviewFileName"] !== undefined
  ) {
    const fileName = type === 'preview' && props["epubPreviewFileName"] 
      ? props["epubPreviewFileName"] 
      : props["epubProdFileName"];
    console.log("fileName", fileName);

    // 先顯示確認框
    /*
    if (!window.confirm("是否開啟書本?")) {
      console.log("User cancelled book opening");
      setLoader(false);
      return;
    }
    */

    console.log("Starting file download...");
    setLoader(true); // 顯示 loading

    api
    .getEncryptedFile(encryptedPath) // 使用新的加密文件讀取方法
      .then(async (blob: any) => {
        console.log("File downloaded, size:", blob.size);
        const file = new File([blob], fileName, {
          lastModified: new Date().getTime(),
        });
        console.log("File object created");

        const fileExtension = getFileExtension(fileName);
        console.log("File extension:", fileExtension);

        await getMd5WithBrowser(file).then(async (md5) => {
          console.log("MD5 generated:", md5);

          if (fileExtension === "pdf") {
            console.log("Processing PDF file");
            let reader = new FileReader();
            reader.readAsArrayBuffer(blob);
            reader.onload = async (event) => {
              const file_content = event?.target?.result;
              BookUtil.generateBook(
                fileName,
                fileExtension,
                md5,
                blob.size,
                fileName,
                file_content
              ).then(async (bookObj) => {
                await BookUtil.addBook(bookObj.key, file_content);
                RecentBooks.setRecent(bookObj.key);
                localforage.setItem("books", bookObj);
                openBookClick(bookObj, props);
              });
            };
          }

          if (fileExtension === "epub") {
            console.log("Processing EPUB file");
            addEpub(file, md5, fileName).then(async (bookObj) => {
              await BookUtil.addBook(bookObj.key, file);
              RecentBooks.setRecent(bookObj.key);
              localforage.setItem("books", bookObj);
              openBookClick(bookObj, props);
            });
          }
        });
      })
      .catch(error => {
        console.error("Error during file processing:", error);
        // 檢查是否為 403 錯誤
        if (error.response && error.response.status === 403) {
          alert("無權限存取此檔案");
        } else {
          alert("檔案處理時發生錯誤");
        }
        setLoader(false);
      })
      .finally(() => {
        setLoader(false);
      });
  } else {
    console.log("No epubProdFileName provided");
    setLoader(false);
  }
};

const openBookClick = (bookObj: any, props: any) => {
  console.log("openBookClick", bookObj);
  console.log("props", props);
  if (StorageUtil.getReaderConfig("isOpenInMain") === "yes") {
    //props.history.push(BookUtil.getBookUrl(book));
    //props.handleReadingBook(book);
  } else {
    //localStorage.setItem("tempBook", JSON.stringify(book));

    //BookUtil.RedirectBook(bookObj, bookObj.name, props.productId);
    BookUtil.RedirectBook(bookObj, props?.productNameChi ?? "", props.productId);
    // .then(() => {
    //   if (localStorage.getItem("openbook") === "true") {
    //     localStorage.setItem("openbook", "false")
    //     if (window.innerWidth >= 1024) {
    //       setOpenBookAlertDialog(true)
    //     }
    //   }
    // })
  }
};
