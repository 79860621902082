/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ArticleDto } from './ArticleDto';
import {
    ArticleDtoFromJSON,
    ArticleDtoFromJSONTyped,
    ArticleDtoToJSON,
} from './ArticleDto';
import type { DiscountDtoLite } from './DiscountDtoLite';
import {
    DiscountDtoLiteFromJSON,
    DiscountDtoLiteFromJSONTyped,
    DiscountDtoLiteToJSON,
} from './DiscountDtoLite';
import type { MemberDto } from './MemberDto';
import {
    MemberDtoFromJSON,
    MemberDtoFromJSONTyped,
    MemberDtoToJSON,
} from './MemberDto';
import type { MemberLevelDto } from './MemberLevelDto';
import {
    MemberLevelDtoFromJSON,
    MemberLevelDtoFromJSONTyped,
    MemberLevelDtoToJSON,
} from './MemberLevelDto';

/**
 * 
 * @export
 * @interface MarketingEventDto
 */
export interface MarketingEventDto {
    /**
     * 
     * @type {Array<ArticleDto>}
     * @memberof MarketingEventDto
     */
    articles?: Array<ArticleDto>;
    /**
     * 
     * @type {string}
     * @memberof MarketingEventDto
     */
    bannerContent?: string;
    /**
     * 
     * @type {string}
     * @memberof MarketingEventDto
     */
    bannerLinkTo?: string;
    /**
     * 
     * @type {number}
     * @memberof MarketingEventDto
     */
    bannerPosition?: number;
    /**
     * 
     * @type {DiscountDtoLite}
     * @memberof MarketingEventDto
     */
    discount?: DiscountDtoLite;
    /**
     * 
     * @type {Date}
     * @memberof MarketingEventDto
     */
    endDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof MarketingEventDto
     */
    expireBannerContent?: string;
    /**
     * 
     * @type {Date}
     * @memberof MarketingEventDto
     */
    expireBannerEndDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof MarketingEventDto
     */
    isMegaSale?: string;
    /**
     * 
     * @type {number}
     * @memberof MarketingEventDto
     */
    marketingEventId?: number;
    /**
     * 
     * @type {string}
     * @memberof MarketingEventDto
     */
    marketingEventName?: string;
    /**
     * 
     * @type {number}
     * @memberof MarketingEventDto
     */
    marketingEventPriority?: number;
    /**
     * 
     * @type {Array<MemberLevelDto>}
     * @memberof MarketingEventDto
     */
    memberLevels?: Array<MemberLevelDto>;
    /**
     * 
     * @type {Array<MemberDto>}
     * @memberof MarketingEventDto
     */
    members?: Array<MemberDto>;
    /**
     * 
     * @type {string}
     * @memberof MarketingEventDto
     */
    mobileBannerContent?: string;
    /**
     * 
     * @type {string}
     * @memberof MarketingEventDto
     */
    mobileExpireBannerContent?: string;
    /**
     * 
     * @type {string}
     * @memberof MarketingEventDto
     */
    promotionCode?: string;
    /**
     * 
     * @type {string}
     * @memberof MarketingEventDto
     */
    remark?: string;
    /**
     * 
     * @type {Date}
     * @memberof MarketingEventDto
     */
    startDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof MarketingEventDto
     */
    status?: string;
}

/**
 * Check if a given object implements the MarketingEventDto interface.
 */
export function instanceOfMarketingEventDto(value: object): boolean {
    return true;
}

export function MarketingEventDtoFromJSON(json: any): MarketingEventDto {
    return MarketingEventDtoFromJSONTyped(json, false);
}

export function MarketingEventDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): MarketingEventDto {
    if (json == null) {
        return json;
    }
    return {
        
        'articles': json['articles'] == null ? undefined : ((json['articles'] as Array<any>).map(ArticleDtoFromJSON)),
        'bannerContent': json['bannerContent'] == null ? undefined : json['bannerContent'],
        'bannerLinkTo': json['bannerLinkTo'] == null ? undefined : json['bannerLinkTo'],
        'bannerPosition': json['bannerPosition'] == null ? undefined : json['bannerPosition'],
        'discount': json['discount'] == null ? undefined : DiscountDtoLiteFromJSON(json['discount']),
        'endDate': json['endDate'] == null ? undefined : (new Date(json['endDate'])),
        'expireBannerContent': json['expireBannerContent'] == null ? undefined : json['expireBannerContent'],
        'expireBannerEndDate': json['expireBannerEndDate'] == null ? undefined : (new Date(json['expireBannerEndDate'])),
        'isMegaSale': json['isMegaSale'] == null ? undefined : json['isMegaSale'],
        'marketingEventId': json['marketingEventId'] == null ? undefined : json['marketingEventId'],
        'marketingEventName': json['marketingEventName'] == null ? undefined : json['marketingEventName'],
        'marketingEventPriority': json['marketingEventPriority'] == null ? undefined : json['marketingEventPriority'],
        'memberLevels': json['memberLevels'] == null ? undefined : ((json['memberLevels'] as Array<any>).map(MemberLevelDtoFromJSON)),
        'members': json['members'] == null ? undefined : ((json['members'] as Array<any>).map(MemberDtoFromJSON)),
        'mobileBannerContent': json['mobileBannerContent'] == null ? undefined : json['mobileBannerContent'],
        'mobileExpireBannerContent': json['mobileExpireBannerContent'] == null ? undefined : json['mobileExpireBannerContent'],
        'promotionCode': json['promotionCode'] == null ? undefined : json['promotionCode'],
        'remark': json['remark'] == null ? undefined : json['remark'],
        'startDate': json['startDate'] == null ? undefined : (new Date(json['startDate'])),
        'status': json['status'] == null ? undefined : json['status'],
    };
}

export function MarketingEventDtoToJSON(value?: MarketingEventDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'articles': value['articles'] == null ? undefined : ((value['articles'] as Array<any>).map(ArticleDtoToJSON)),
        'bannerContent': value['bannerContent'],
        'bannerLinkTo': value['bannerLinkTo'],
        'bannerPosition': value['bannerPosition'],
        'discount': DiscountDtoLiteToJSON(value['discount']),
        'endDate': value['endDate'] == null ? undefined : ((value['endDate']).toISOString()),
        'expireBannerContent': value['expireBannerContent'],
        'expireBannerEndDate': value['expireBannerEndDate'] == null ? undefined : ((value['expireBannerEndDate']).toISOString()),
        'isMegaSale': value['isMegaSale'],
        'marketingEventId': value['marketingEventId'],
        'marketingEventName': value['marketingEventName'],
        'marketingEventPriority': value['marketingEventPriority'],
        'memberLevels': value['memberLevels'] == null ? undefined : ((value['memberLevels'] as Array<any>).map(MemberLevelDtoToJSON)),
        'members': value['members'] == null ? undefined : ((value['members'] as Array<any>).map(MemberDtoToJSON)),
        'mobileBannerContent': value['mobileBannerContent'],
        'mobileExpireBannerContent': value['mobileExpireBannerContent'],
        'promotionCode': value['promotionCode'],
        'remark': value['remark'],
        'startDate': value['startDate'] == null ? undefined : ((value['startDate']).toISOString()),
        'status': value['status'],
    };
}

