/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { AwardRequestModel } from './AwardRequestModel';
import {
    AwardRequestModelFromJSON,
    AwardRequestModelFromJSONTyped,
    AwardRequestModelToJSON,
} from './AwardRequestModel';
import type { CategoryRequestModel } from './CategoryRequestModel';
import {
    CategoryRequestModelFromJSON,
    CategoryRequestModelFromJSONTyped,
    CategoryRequestModelToJSON,
} from './CategoryRequestModel';
import type { LanguageRequestModel } from './LanguageRequestModel';
import {
    LanguageRequestModelFromJSON,
    LanguageRequestModelFromJSONTyped,
    LanguageRequestModelToJSON,
} from './LanguageRequestModel';
import type { OrganizationRequestModel } from './OrganizationRequestModel';
import {
    OrganizationRequestModelFromJSON,
    OrganizationRequestModelFromJSONTyped,
    OrganizationRequestModelToJSON,
} from './OrganizationRequestModel';
import type { ProductRequestModel } from './ProductRequestModel';
import {
    ProductRequestModelFromJSON,
    ProductRequestModelFromJSONTyped,
    ProductRequestModelToJSON,
} from './ProductRequestModel';

/**
 * 
 * @export
 * @interface Sync
 */
export interface Sync {
    /**
     * 
     * @type {Array<AwardRequestModel>}
     * @memberof Sync
     */
    awardRequestModels?: Array<AwardRequestModel>;
    /**
     * 
     * @type {Array<OrganizationRequestModel>}
     * @memberof Sync
     */
    bookOrganizationRequestModels?: Array<OrganizationRequestModel>;
    /**
     * 
     * @type {Array<CategoryRequestModel>}
     * @memberof Sync
     */
    categoryModels?: Array<CategoryRequestModel>;
    /**
     * 
     * @type {Array<LanguageRequestModel>}
     * @memberof Sync
     */
    languageRequestModels?: Array<LanguageRequestModel>;
    /**
     * 
     * @type {number}
     * @memberof Sync
     */
    missionId?: number;
    /**
     * 
     * @type {Array<ProductRequestModel>}
     * @memberof Sync
     */
    products?: Array<ProductRequestModel>;
}

/**
 * Check if a given object implements the Sync interface.
 */
export function instanceOfSync(value: object): boolean {
    return true;
}

export function SyncFromJSON(json: any): Sync {
    return SyncFromJSONTyped(json, false);
}

export function SyncFromJSONTyped(json: any, ignoreDiscriminator: boolean): Sync {
    if (json == null) {
        return json;
    }
    return {
        
        'awardRequestModels': json['awardRequestModels'] == null ? undefined : ((json['awardRequestModels'] as Array<any>).map(AwardRequestModelFromJSON)),
        'bookOrganizationRequestModels': json['bookOrganizationRequestModels'] == null ? undefined : ((json['bookOrganizationRequestModels'] as Array<any>).map(OrganizationRequestModelFromJSON)),
        'categoryModels': json['categoryModels'] == null ? undefined : ((json['categoryModels'] as Array<any>).map(CategoryRequestModelFromJSON)),
        'languageRequestModels': json['languageRequestModels'] == null ? undefined : ((json['languageRequestModels'] as Array<any>).map(LanguageRequestModelFromJSON)),
        'missionId': json['missionId'] == null ? undefined : json['missionId'],
        'products': json['products'] == null ? undefined : ((json['products'] as Array<any>).map(ProductRequestModelFromJSON)),
    };
}

export function SyncToJSON(value?: Sync | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'awardRequestModels': value['awardRequestModels'] == null ? undefined : ((value['awardRequestModels'] as Array<any>).map(AwardRequestModelToJSON)),
        'bookOrganizationRequestModels': value['bookOrganizationRequestModels'] == null ? undefined : ((value['bookOrganizationRequestModels'] as Array<any>).map(OrganizationRequestModelToJSON)),
        'categoryModels': value['categoryModels'] == null ? undefined : ((value['categoryModels'] as Array<any>).map(CategoryRequestModelToJSON)),
        'languageRequestModels': value['languageRequestModels'] == null ? undefined : ((value['languageRequestModels'] as Array<any>).map(LanguageRequestModelToJSON)),
        'missionId': value['missionId'],
        'products': value['products'] == null ? undefined : ((value['products'] as Array<any>).map(ProductRequestModelToJSON)),
    };
}

