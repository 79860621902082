/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';

export interface NotifyUsingPOSTRequest {
    body?: string;
}

export interface PrePayUsingPOSTRequest {
    body?: string;
}

export interface ReserveBook2UsingPOSTRequest {
    amount: string;
    currency: string;
    orderId: string;
    orderReference: string;
    operation: string;
    description: string;
    transactionSrc: string;
}

export interface ReserveBookUsingPOSTRequest {
    amount: string;
    currency: string;
    orderId: string;
    orderReference: string;
    operation: string;
    description: string;
    transactionSrc: string;
}

export interface WebHookUsingPOSTRequest {
    xNotificationSecret: string;
    body?: string;
}

/**
 * 
 */
export class BocPayControllerApi extends runtime.BaseAPI {

    /**
     * notify
     */
    async notifyUsingPOSTRaw(requestParameters: NotifyUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/boc_checkout/notify`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters['body'] as any,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * notify
     */
    async notifyUsingPOST(requestParameters: NotifyUsingPOSTRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.notifyUsingPOSTRaw(requestParameters, initOverrides);
    }

    /**
     * prePay
     */
    async prePayUsingPOSTRaw(requestParameters: PrePayUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/boc_checkout/prepay`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters['body'] as any,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * prePay
     */
    async prePayUsingPOST(requestParameters: PrePayUsingPOSTRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string | null | undefined > {
        const response = await this.prePayUsingPOSTRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 201:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * reserveBook2
     */
    async reserveBook2UsingPOSTRaw(requestParameters: ReserveBook2UsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters['amount'] == null) {
            throw new runtime.RequiredError(
                'amount',
                'Required parameter "amount" was null or undefined when calling reserveBook2UsingPOST().'
            );
        }

        if (requestParameters['currency'] == null) {
            throw new runtime.RequiredError(
                'currency',
                'Required parameter "currency" was null or undefined when calling reserveBook2UsingPOST().'
            );
        }

        if (requestParameters['orderId'] == null) {
            throw new runtime.RequiredError(
                'orderId',
                'Required parameter "orderId" was null or undefined when calling reserveBook2UsingPOST().'
            );
        }

        if (requestParameters['orderReference'] == null) {
            throw new runtime.RequiredError(
                'orderReference',
                'Required parameter "orderReference" was null or undefined when calling reserveBook2UsingPOST().'
            );
        }

        if (requestParameters['operation'] == null) {
            throw new runtime.RequiredError(
                'operation',
                'Required parameter "operation" was null or undefined when calling reserveBook2UsingPOST().'
            );
        }

        if (requestParameters['description'] == null) {
            throw new runtime.RequiredError(
                'description',
                'Required parameter "description" was null or undefined when calling reserveBook2UsingPOST().'
            );
        }

        if (requestParameters['transactionSrc'] == null) {
            throw new runtime.RequiredError(
                'transactionSrc',
                'Required parameter "transactionSrc" was null or undefined when calling reserveBook2UsingPOST().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['amount'] != null) {
            queryParameters['amount'] = requestParameters['amount'];
        }

        if (requestParameters['currency'] != null) {
            queryParameters['currency'] = requestParameters['currency'];
        }

        if (requestParameters['orderId'] != null) {
            queryParameters['orderId'] = requestParameters['orderId'];
        }

        if (requestParameters['orderReference'] != null) {
            queryParameters['orderReference'] = requestParameters['orderReference'];
        }

        if (requestParameters['operation'] != null) {
            queryParameters['operation'] = requestParameters['operation'];
        }

        if (requestParameters['description'] != null) {
            queryParameters['description'] = requestParameters['description'];
        }

        if (requestParameters['transactionSrc'] != null) {
            queryParameters['transactionSrc'] = requestParameters['transactionSrc'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/boc_checkout/unionPay/getSession`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * reserveBook2
     */
    async reserveBook2UsingPOST(requestParameters: ReserveBook2UsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string | null | undefined > {
        const response = await this.reserveBook2UsingPOSTRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 201:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * reserveBook
     */
    async reserveBookUsingPOSTRaw(requestParameters: ReserveBookUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters['amount'] == null) {
            throw new runtime.RequiredError(
                'amount',
                'Required parameter "amount" was null or undefined when calling reserveBookUsingPOST().'
            );
        }

        if (requestParameters['currency'] == null) {
            throw new runtime.RequiredError(
                'currency',
                'Required parameter "currency" was null or undefined when calling reserveBookUsingPOST().'
            );
        }

        if (requestParameters['orderId'] == null) {
            throw new runtime.RequiredError(
                'orderId',
                'Required parameter "orderId" was null or undefined when calling reserveBookUsingPOST().'
            );
        }

        if (requestParameters['orderReference'] == null) {
            throw new runtime.RequiredError(
                'orderReference',
                'Required parameter "orderReference" was null or undefined when calling reserveBookUsingPOST().'
            );
        }

        if (requestParameters['operation'] == null) {
            throw new runtime.RequiredError(
                'operation',
                'Required parameter "operation" was null or undefined when calling reserveBookUsingPOST().'
            );
        }

        if (requestParameters['description'] == null) {
            throw new runtime.RequiredError(
                'description',
                'Required parameter "description" was null or undefined when calling reserveBookUsingPOST().'
            );
        }

        if (requestParameters['transactionSrc'] == null) {
            throw new runtime.RequiredError(
                'transactionSrc',
                'Required parameter "transactionSrc" was null or undefined when calling reserveBookUsingPOST().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['amount'] != null) {
            queryParameters['amount'] = requestParameters['amount'];
        }

        if (requestParameters['currency'] != null) {
            queryParameters['currency'] = requestParameters['currency'];
        }

        if (requestParameters['orderId'] != null) {
            queryParameters['orderId'] = requestParameters['orderId'];
        }

        if (requestParameters['orderReference'] != null) {
            queryParameters['orderReference'] = requestParameters['orderReference'];
        }

        if (requestParameters['operation'] != null) {
            queryParameters['operation'] = requestParameters['operation'];
        }

        if (requestParameters['description'] != null) {
            queryParameters['description'] = requestParameters['description'];
        }

        if (requestParameters['transactionSrc'] != null) {
            queryParameters['transactionSrc'] = requestParameters['transactionSrc'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/boc_checkout/getSession`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * reserveBook
     */
    async reserveBookUsingPOST(requestParameters: ReserveBookUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string | null | undefined > {
        const response = await this.reserveBookUsingPOSTRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 201:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * webHook
     */
    async webHookUsingPOSTRaw(requestParameters: WebHookUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['xNotificationSecret'] == null) {
            throw new runtime.RequiredError(
                'xNotificationSecret',
                'Required parameter "xNotificationSecret" was null or undefined when calling webHookUsingPOST().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters['xNotificationSecret'] != null) {
            headerParameters['X-Notification-Secret'] = String(requestParameters['xNotificationSecret']);
        }

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/boc_checkout/webHook`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters['body'] as any,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * webHook
     */
    async webHookUsingPOST(requestParameters: WebHookUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.webHookUsingPOSTRaw(requestParameters, initOverrides);
    }

}
