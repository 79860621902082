/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Member } from './Member';
import {
    MemberFromJSON,
    MemberFromJSONTyped,
    MemberToJSON,
} from './Member';
import type { Product } from './Product';
import {
    ProductFromJSON,
    ProductFromJSONTyped,
    ProductToJSON,
} from './Product';

/**
 * 
 * @export
 * @interface BookShelf
 */
export interface BookShelf {
    /**
     * 
     * @type {number}
     * @memberof BookShelf
     */
    bookShelfId?: number;
    /**
     * 
     * @type {Member}
     * @memberof BookShelf
     */
    memberByMemberId?: Member;
    /**
     * 
     * @type {number}
     * @memberof BookShelf
     */
    memberId?: number;
    /**
     * 
     * @type {Product}
     * @memberof BookShelf
     */
    productByProductId?: Product;
    /**
     * 
     * @type {number}
     * @memberof BookShelf
     */
    productId?: number;
}

/**
 * Check if a given object implements the BookShelf interface.
 */
export function instanceOfBookShelf(value: object): boolean {
    return true;
}

export function BookShelfFromJSON(json: any): BookShelf {
    return BookShelfFromJSONTyped(json, false);
}

export function BookShelfFromJSONTyped(json: any, ignoreDiscriminator: boolean): BookShelf {
    if (json == null) {
        return json;
    }
    return {
        
        'bookShelfId': json['bookShelfId'] == null ? undefined : json['bookShelfId'],
        'memberByMemberId': json['memberByMemberId'] == null ? undefined : MemberFromJSON(json['memberByMemberId']),
        'memberId': json['memberId'] == null ? undefined : json['memberId'],
        'productByProductId': json['productByProductId'] == null ? undefined : ProductFromJSON(json['productByProductId']),
        'productId': json['productId'] == null ? undefined : json['productId'],
    };
}

export function BookShelfToJSON(value?: BookShelf | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'bookShelfId': value['bookShelfId'],
        'memberByMemberId': MemberToJSON(value['memberByMemberId']),
        'memberId': value['memberId'],
        'productByProductId': ProductToJSON(value['productByProductId']),
        'productId': value['productId'],
    };
}

