/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface LibFileObj
 */
export interface LibFileObj {
    /**
     * 
     * @type {string}
     * @memberof LibFileObj
     */
    fileName?: string;
    /**
     * 
     * @type {string}
     * @memberof LibFileObj
     */
    filePath?: string;
    /**
     * 
     * @type {number}
     * @memberof LibFileObj
     */
    fileSize?: number;
}

/**
 * Check if a given object implements the LibFileObj interface.
 */
export function instanceOfLibFileObj(value: object): boolean {
    return true;
}

export function LibFileObjFromJSON(json: any): LibFileObj {
    return LibFileObjFromJSONTyped(json, false);
}

export function LibFileObjFromJSONTyped(json: any, ignoreDiscriminator: boolean): LibFileObj {
    if (json == null) {
        return json;
    }
    return {
        
        'fileName': json['fileName'] == null ? undefined : json['fileName'],
        'filePath': json['filePath'] == null ? undefined : json['filePath'],
        'fileSize': json['fileSize'] == null ? undefined : json['fileSize'],
    };
}

export function LibFileObjToJSON(value?: LibFileObj | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'fileName': value['fileName'],
        'filePath': value['filePath'],
        'fileSize': value['fileSize'],
    };
}

