/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MarketingEventBannerDto
 */
export interface MarketingEventBannerDto {
    /**
     * 
     * @type {number}
     * @memberof MarketingEventBannerDto
     */
    bannerId?: number;
    /**
     * 
     * @type {string}
     * @memberof MarketingEventBannerDto
     */
    content?: string;
    /**
     * 
     * @type {number}
     * @memberof MarketingEventBannerDto
     */
    marketingEventId?: number;
    /**
     * 
     * @type {string}
     * @memberof MarketingEventBannerDto
     */
    marketingEventName?: string;
    /**
     * 
     * @type {string}
     * @memberof MarketingEventBannerDto
     */
    mobileContent?: string;
    /**
     * 
     * @type {number}
     * @memberof MarketingEventBannerDto
     */
    position?: number;
}

/**
 * Check if a given object implements the MarketingEventBannerDto interface.
 */
export function instanceOfMarketingEventBannerDto(value: object): boolean {
    return true;
}

export function MarketingEventBannerDtoFromJSON(json: any): MarketingEventBannerDto {
    return MarketingEventBannerDtoFromJSONTyped(json, false);
}

export function MarketingEventBannerDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): MarketingEventBannerDto {
    if (json == null) {
        return json;
    }
    return {
        
        'bannerId': json['bannerId'] == null ? undefined : json['bannerId'],
        'content': json['content'] == null ? undefined : json['content'],
        'marketingEventId': json['marketingEventId'] == null ? undefined : json['marketingEventId'],
        'marketingEventName': json['marketingEventName'] == null ? undefined : json['marketingEventName'],
        'mobileContent': json['mobileContent'] == null ? undefined : json['mobileContent'],
        'position': json['position'] == null ? undefined : json['position'],
    };
}

export function MarketingEventBannerDtoToJSON(value?: MarketingEventBannerDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'bannerId': value['bannerId'],
        'content': value['content'],
        'marketingEventId': value['marketingEventId'],
        'marketingEventName': value['marketingEventName'],
        'mobileContent': value['mobileContent'],
        'position': value['position'],
    };
}

