/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CKEditorResponseDTO,
  FileEncryptionCheckResponseModel,
  FileUploadResponseModel,
  Resource,
} from '../models/index';
import {
    CKEditorResponseDTOFromJSON,
    CKEditorResponseDTOToJSON,
    FileEncryptionCheckResponseModelFromJSON,
    FileEncryptionCheckResponseModelToJSON,
    FileUploadResponseModelFromJSON,
    FileUploadResponseModelToJSON,
    ResourceFromJSON,
    ResourceToJSON,
} from '../models/index';

export interface CheckEncryptedFileUsingGETRequest {
    filename: string;
}

export interface CkeditorUploadUsingPOSTRequest {
    ckCsrfToken: string;
    type: string;
    body?: string;
}

export interface FileEncryptionTxtForAndroidAppUsingGETRequest {
    filename: string;
}

export interface FileEncryptionTxtForAppUsingGETRequest {
    filename: string;
}

export interface FileEncryptionTxtUsingGETRequest {
    filename: string;
}

export interface FileEncryptionUsingGETRequest {
    filename: string;
}

export interface FileuploadfileUsingPOSTRequest {
    body?: Blob;
}

export interface FileuploadfileUsingPOST1Request {
    body?: Blob;
}

export interface GetFileUsingGETRequest {
    filename: string;
}

/**
 * 
 */
export class FileHandlingControllerApi extends runtime.BaseAPI {

    /**
     * checkEncryptedFile
     */
    async checkEncryptedFileUsingGETRaw(requestParameters: CheckEncryptedFileUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FileEncryptionCheckResponseModel>> {
        if (requestParameters['filename'] == null) {
            throw new runtime.RequiredError(
                'filename',
                'Required parameter "filename" was null or undefined when calling checkEncryptedFileUsingGET().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/file/fileEncrypt/check/{filename}`.replace(`{${"filename"}}`, encodeURIComponent(String(requestParameters['filename']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FileEncryptionCheckResponseModelFromJSON(jsonValue));
    }

    /**
     * checkEncryptedFile
     */
    async checkEncryptedFileUsingGET(requestParameters: CheckEncryptedFileUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FileEncryptionCheckResponseModel> {
        const response = await this.checkEncryptedFileUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * ckeditorUpload
     */
    async ckeditorUploadUsingPOSTRaw(requestParameters: CkeditorUploadUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CKEditorResponseDTO>> {
        if (requestParameters['ckCsrfToken'] == null) {
            throw new runtime.RequiredError(
                'ckCsrfToken',
                'Required parameter "ckCsrfToken" was null or undefined when calling ckeditorUploadUsingPOST().'
            );
        }

        if (requestParameters['type'] == null) {
            throw new runtime.RequiredError(
                'type',
                'Required parameter "type" was null or undefined when calling ckeditorUploadUsingPOST().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['ckCsrfToken'] != null) {
            queryParameters['ckCsrfToken'] = requestParameters['ckCsrfToken'];
        }

        if (requestParameters['type'] != null) {
            queryParameters['type'] = requestParameters['type'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/file/fileupload/ckeditor`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters['body'] as any,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CKEditorResponseDTOFromJSON(jsonValue));
    }

    /**
     * ckeditorUpload
     */
    async ckeditorUploadUsingPOST(requestParameters: CkeditorUploadUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CKEditorResponseDTO | null | undefined > {
        const response = await this.ckeditorUploadUsingPOSTRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 201:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * fileEncryptionTxtForAndroidApp
     */
    async fileEncryptionTxtForAndroidAppUsingGETRaw(requestParameters: FileEncryptionTxtForAndroidAppUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Resource>> {
        if (requestParameters['filename'] == null) {
            throw new runtime.RequiredError(
                'filename',
                'Required parameter "filename" was null or undefined when calling fileEncryptionTxtForAndroidAppUsingGET().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/file/fileEncrypt/txt/app/android/{filename}`.replace(`{${"filename"}}`, encodeURIComponent(String(requestParameters['filename']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResourceFromJSON(jsonValue));
    }

    /**
     * fileEncryptionTxtForAndroidApp
     */
    async fileEncryptionTxtForAndroidAppUsingGET(requestParameters: FileEncryptionTxtForAndroidAppUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Resource> {
        const response = await this.fileEncryptionTxtForAndroidAppUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * FileEncryptionTxtForApp
     */
    async fileEncryptionTxtForAppUsingGETRaw(requestParameters: FileEncryptionTxtForAppUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Resource>> {
        if (requestParameters['filename'] == null) {
            throw new runtime.RequiredError(
                'filename',
                'Required parameter "filename" was null or undefined when calling fileEncryptionTxtForAppUsingGET().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/file/fileEncrypt/txt/app/{filename}`.replace(`{${"filename"}}`, encodeURIComponent(String(requestParameters['filename']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResourceFromJSON(jsonValue));
    }

    /**
     * FileEncryptionTxtForApp
     */
    async fileEncryptionTxtForAppUsingGET(requestParameters: FileEncryptionTxtForAppUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Resource> {
        const response = await this.fileEncryptionTxtForAppUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * FileEncryptionTxt
     */
    async fileEncryptionTxtUsingGETRaw(requestParameters: FileEncryptionTxtUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Resource>> {
        if (requestParameters['filename'] == null) {
            throw new runtime.RequiredError(
                'filename',
                'Required parameter "filename" was null or undefined when calling fileEncryptionTxtUsingGET().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/file/fileEncrypt/txt/{filename}`.replace(`{${"filename"}}`, encodeURIComponent(String(requestParameters['filename']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResourceFromJSON(jsonValue));
    }

    /**
     * FileEncryptionTxt
     */
    async fileEncryptionTxtUsingGET(requestParameters: FileEncryptionTxtUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Resource> {
        const response = await this.fileEncryptionTxtUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * FileEncryption
     */
    async fileEncryptionUsingGETRaw(requestParameters: FileEncryptionUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters['filename'] == null) {
            throw new runtime.RequiredError(
                'filename',
                'Required parameter "filename" was null or undefined when calling fileEncryptionUsingGET().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/file/fileEncrypt/{filename}`.replace(`{${"filename"}}`, encodeURIComponent(String(requestParameters['filename']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * FileEncryption
     */
    async fileEncryptionUsingGET(requestParameters: FileEncryptionUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.fileEncryptionUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * fileuploadfile
     */
    async fileuploadfileUsingPOSTRaw(requestParameters: FileuploadfileUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FileUploadResponseModel>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/file/fileupload`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters['body'] as any,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FileUploadResponseModelFromJSON(jsonValue));
    }

    /**
     * fileuploadfile
     */
    async fileuploadfileUsingPOST(requestParameters: FileuploadfileUsingPOSTRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FileUploadResponseModel | null | undefined > {
        const response = await this.fileuploadfileUsingPOSTRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 201:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * fileuploadfile
     */
    async fileuploadfileUsingPOST1Raw(requestParameters: FileuploadfileUsingPOST1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/file/fileupload/image`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters['body'] as any,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * fileuploadfile
     */
    async fileuploadfileUsingPOST1(requestParameters: FileuploadfileUsingPOST1Request = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string | null | undefined > {
        const response = await this.fileuploadfileUsingPOST1Raw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 201:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * getFile
     */
    async getFileUsingGETRaw(requestParameters: GetFileUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Resource>> {
        if (requestParameters['filename'] == null) {
            throw new runtime.RequiredError(
                'filename',
                'Required parameter "filename" was null or undefined when calling getFileUsingGET().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/file/{filename}`.replace(`{${"filename"}}`, encodeURIComponent(String(requestParameters['filename']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResourceFromJSON(jsonValue));
    }

    /**
     * getFile
     */
    async getFileUsingGET(requestParameters: GetFileUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Resource> {
        const response = await this.getFileUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
