/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface BookCountModel
 */
export interface BookCountModel {
    /**
     * 
     * @type {number}
     * @memberof BookCountModel
     */
    bookCount?: number;
    /**
     * 
     * @type {number}
     * @memberof BookCountModel
     */
    categoryId?: number;
}

/**
 * Check if a given object implements the BookCountModel interface.
 */
export function instanceOfBookCountModel(value: object): boolean {
    return true;
}

export function BookCountModelFromJSON(json: any): BookCountModel {
    return BookCountModelFromJSONTyped(json, false);
}

export function BookCountModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): BookCountModel {
    if (json == null) {
        return json;
    }
    return {
        
        'bookCount': json['bookCount'] == null ? undefined : json['bookCount'],
        'categoryId': json['categoryId'] == null ? undefined : json['categoryId'],
    };
}

export function BookCountModelToJSON(value?: BookCountModel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'bookCount': value['bookCount'],
        'categoryId': value['categoryId'],
    };
}

