/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AwardRequestModel
 */
export interface AwardRequestModel {
    /**
     * 
     * @type {string}
     * @memberof AwardRequestModel
     */
    awardName?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AwardRequestModel
     */
    _delete?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AwardRequestModel
     */
    mongoId?: string;
    /**
     * 
     * @type {number}
     * @memberof AwardRequestModel
     */
    seq?: number;
    /**
     * 
     * @type {string}
     * @memberof AwardRequestModel
     */
    yearOf?: string;
}

/**
 * Check if a given object implements the AwardRequestModel interface.
 */
export function instanceOfAwardRequestModel(value: object): boolean {
    return true;
}

export function AwardRequestModelFromJSON(json: any): AwardRequestModel {
    return AwardRequestModelFromJSONTyped(json, false);
}

export function AwardRequestModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): AwardRequestModel {
    if (json == null) {
        return json;
    }
    return {
        
        'awardName': json['awardName'] == null ? undefined : json['awardName'],
        '_delete': json['delete'] == null ? undefined : json['delete'],
        'mongoId': json['mongoId'] == null ? undefined : json['mongoId'],
        'seq': json['seq'] == null ? undefined : json['seq'],
        'yearOf': json['yearOf'] == null ? undefined : json['yearOf'],
    };
}

export function AwardRequestModelToJSON(value?: AwardRequestModel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'awardName': value['awardName'],
        'delete': value['_delete'],
        'mongoId': value['mongoId'],
        'seq': value['seq'],
        'yearOf': value['yearOf'],
    };
}

