/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { AwardDto } from './AwardDto';
import {
    AwardDtoFromJSON,
    AwardDtoFromJSONTyped,
    AwardDtoToJSON,
} from './AwardDto';
import type { CategoryDto } from './CategoryDto';
import {
    CategoryDtoFromJSON,
    CategoryDtoFromJSONTyped,
    CategoryDtoToJSON,
} from './CategoryDto';
import type { DisplayProductDto } from './DisplayProductDto';
import {
    DisplayProductDtoFromJSON,
    DisplayProductDtoFromJSONTyped,
    DisplayProductDtoToJSON,
} from './DisplayProductDto';
import type { LanguageDto } from './LanguageDto';
import {
    LanguageDtoFromJSON,
    LanguageDtoFromJSONTyped,
    LanguageDtoToJSON,
} from './LanguageDto';
import type { ProductPointDto } from './ProductPointDto';
import {
    ProductPointDtoFromJSON,
    ProductPointDtoFromJSONTyped,
    ProductPointDtoToJSON,
} from './ProductPointDto';

/**
 * 
 * @export
 * @interface ProductDto
 */
export interface ProductDto {
    /**
     * 
     * @type {string}
     * @memberof ProductDto
     */
    author?: string;
    /**
     * 
     * @type {Array<AwardDto>}
     * @memberof ProductDto
     */
    awards?: Array<AwardDto>;
    /**
     * 
     * @type {string}
     * @memberof ProductDto
     */
    bookComment?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductDto
     */
    bookIsbn?: string;
    /**
     * 
     * @type {Array<CategoryDto>}
     * @memberof ProductDto
     */
    categories?: Array<CategoryDto>;
    /**
     * 
     * @type {string}
     * @memberof ProductDto
     */
    coverFileName?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductDto
     */
    coverFileNameCdn?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductDto
     */
    ctsId?: string;
    /**
     * 
     * @type {number}
     * @memberof ProductDto
     */
    deposit?: number;
    /**
     * 
     * @type {DisplayProductDto}
     * @memberof ProductDto
     */
    displayProduct?: DisplayProductDto;
    /**
     * 
     * @type {number}
     * @memberof ProductDto
     */
    displayProductId?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductDto
     */
    epubPreviewFileName?: string;
    /**
     * 
     * @type {number}
     * @memberof ProductDto
     */
    epubPreviewFileSize?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductDto
     */
    epubProdFileName?: string;
    /**
     * 
     * @type {number}
     * @memberof ProductDto
     */
    epubProdFileSize?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductDto
     */
    isGroupProduct?: string;
    /**
     * 
     * @type {Array<LanguageDto>}
     * @memberof ProductDto
     */
    languages?: Array<LanguageDto>;
    /**
     * 
     * @type {string}
     * @memberof ProductDto
     */
    multimediaFileNames?: string;
    /**
     * 
     * @type {Date}
     * @memberof ProductDto
     */
    offShelfDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof ProductDto
     */
    onShelfDate?: Date;
    /**
     * 
     * @type {number}
     * @memberof ProductDto
     */
    organizationId?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductDto
     */
    organizationName?: string;
    /**
     * 
     * @type {Array<ProductPointDto>}
     * @memberof ProductDto
     */
    points?: Array<ProductPointDto>;
    /**
     * 
     * @type {string}
     * @memberof ProductDto
     */
    productDescription?: string;
    /**
     * 
     * @type {number}
     * @memberof ProductDto
     */
    productDiscountPrice?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductDto
     */
    productHashtag?: string;
    /**
     * 
     * @type {number}
     * @memberof ProductDto
     */
    productId?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof ProductDto
     */
    productImage?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof ProductDto
     */
    productInventory?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductDto
     */
    productNameChi?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductDto
     */
    productNameEng?: string;
    /**
     * 
     * @type {number}
     * @memberof ProductDto
     */
    productOriginalPrice?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductDto
     */
    productPrice?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductDto
     */
    productStep?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductDto
     */
    productType?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductDto
     */
    productVolume?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductDto
     */
    productVolumeUnit?: string;
    /**
     * 
     * @type {Array<ProductDto>}
     * @memberof ProductDto
     */
    products?: Array<ProductDto>;
    /**
     * 
     * @type {Date}
     * @memberof ProductDto
     */
    publishDate?: Date;
    /**
     * 
     * @type {number}
     * @memberof ProductDto
     */
    sales?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductDto
     */
    sku?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductDto
     */
    status?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductDto
     */
    subscriptionPeriod?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductDto
     */
    thumbFileNames?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductDto
     */
    translator?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductDto
     */
    version?: string;
}

/**
 * Check if a given object implements the ProductDto interface.
 */
export function instanceOfProductDto(value: object): boolean {
    return true;
}

export function ProductDtoFromJSON(json: any): ProductDto {
    return ProductDtoFromJSONTyped(json, false);
}

export function ProductDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProductDto {
    if (json == null) {
        return json;
    }
    return {
        
        'author': json['author'] == null ? undefined : json['author'],
        'awards': json['awards'] == null ? undefined : ((json['awards'] as Array<any>).map(AwardDtoFromJSON)),
        'bookComment': json['bookComment'] == null ? undefined : json['bookComment'],
        'bookIsbn': json['bookIsbn'] == null ? undefined : json['bookIsbn'],
        'categories': json['categories'] == null ? undefined : ((json['categories'] as Array<any>).map(CategoryDtoFromJSON)),
        'coverFileName': json['coverFileName'] == null ? undefined : json['coverFileName'],
        'coverFileNameCdn': json['coverFileNameCdn'] == null ? undefined : json['coverFileNameCdn'],
        'ctsId': json['ctsId'] == null ? undefined : json['ctsId'],
        'deposit': json['deposit'] == null ? undefined : json['deposit'],
        'displayProduct': json['displayProduct'] == null ? undefined : DisplayProductDtoFromJSON(json['displayProduct']),
        'displayProductId': json['displayProductId'] == null ? undefined : json['displayProductId'],
        'epubPreviewFileName': json['epubPreviewFileName'] == null ? undefined : json['epubPreviewFileName'],
        'epubPreviewFileSize': json['epubPreviewFileSize'] == null ? undefined : json['epubPreviewFileSize'],
        'epubProdFileName': json['epubProdFileName'] == null ? undefined : json['epubProdFileName'],
        'epubProdFileSize': json['epubProdFileSize'] == null ? undefined : json['epubProdFileSize'],
        'isGroupProduct': json['isGroupProduct'] == null ? undefined : json['isGroupProduct'],
        'languages': json['languages'] == null ? undefined : ((json['languages'] as Array<any>).map(LanguageDtoFromJSON)),
        'multimediaFileNames': json['multimediaFileNames'] == null ? undefined : json['multimediaFileNames'],
        'offShelfDate': json['offShelfDate'] == null ? undefined : (new Date(json['offShelfDate'])),
        'onShelfDate': json['onShelfDate'] == null ? undefined : (new Date(json['onShelfDate'])),
        'organizationId': json['organizationId'] == null ? undefined : json['organizationId'],
        'organizationName': json['organizationName'] == null ? undefined : json['organizationName'],
        'points': json['points'] == null ? undefined : ((json['points'] as Array<any>).map(ProductPointDtoFromJSON)),
        'productDescription': json['productDescription'] == null ? undefined : json['productDescription'],
        'productDiscountPrice': json['productDiscountPrice'] == null ? undefined : json['productDiscountPrice'],
        'productHashtag': json['productHashtag'] == null ? undefined : json['productHashtag'],
        'productId': json['productId'] == null ? undefined : json['productId'],
        'productImage': json['productImage'] == null ? undefined : json['productImage'],
        'productInventory': json['productInventory'] == null ? undefined : json['productInventory'],
        'productNameChi': json['productNameChi'] == null ? undefined : json['productNameChi'],
        'productNameEng': json['productNameEng'] == null ? undefined : json['productNameEng'],
        'productOriginalPrice': json['productOriginalPrice'] == null ? undefined : json['productOriginalPrice'],
        'productPrice': json['productPrice'] == null ? undefined : json['productPrice'],
        'productStep': json['productStep'] == null ? undefined : json['productStep'],
        'productType': json['productType'] == null ? undefined : json['productType'],
        'productVolume': json['productVolume'] == null ? undefined : json['productVolume'],
        'productVolumeUnit': json['productVolumeUnit'] == null ? undefined : json['productVolumeUnit'],
        'products': json['products'] == null ? undefined : ((json['products'] as Array<any>).map(ProductDtoFromJSON)),
        'publishDate': json['publishDate'] == null ? undefined : (new Date(json['publishDate'])),
        'sales': json['sales'] == null ? undefined : json['sales'],
        'sku': json['sku'] == null ? undefined : json['sku'],
        'status': json['status'] == null ? undefined : json['status'],
        'subscriptionPeriod': json['subscriptionPeriod'] == null ? undefined : json['subscriptionPeriod'],
        'thumbFileNames': json['thumbFileNames'] == null ? undefined : json['thumbFileNames'],
        'translator': json['translator'] == null ? undefined : json['translator'],
        'version': json['version'] == null ? undefined : json['version'],
    };
}

export function ProductDtoToJSON(value?: ProductDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'author': value['author'],
        'awards': value['awards'] == null ? undefined : ((value['awards'] as Array<any>).map(AwardDtoToJSON)),
        'bookComment': value['bookComment'],
        'bookIsbn': value['bookIsbn'],
        'categories': value['categories'] == null ? undefined : ((value['categories'] as Array<any>).map(CategoryDtoToJSON)),
        'coverFileName': value['coverFileName'],
        'coverFileNameCdn': value['coverFileNameCdn'],
        'ctsId': value['ctsId'],
        'deposit': value['deposit'],
        'displayProduct': DisplayProductDtoToJSON(value['displayProduct']),
        'displayProductId': value['displayProductId'],
        'epubPreviewFileName': value['epubPreviewFileName'],
        'epubPreviewFileSize': value['epubPreviewFileSize'],
        'epubProdFileName': value['epubProdFileName'],
        'epubProdFileSize': value['epubProdFileSize'],
        'isGroupProduct': value['isGroupProduct'],
        'languages': value['languages'] == null ? undefined : ((value['languages'] as Array<any>).map(LanguageDtoToJSON)),
        'multimediaFileNames': value['multimediaFileNames'],
        'offShelfDate': value['offShelfDate'] == null ? undefined : ((value['offShelfDate']).toISOString()),
        'onShelfDate': value['onShelfDate'] == null ? undefined : ((value['onShelfDate']).toISOString()),
        'organizationId': value['organizationId'],
        'organizationName': value['organizationName'],
        'points': value['points'] == null ? undefined : ((value['points'] as Array<any>).map(ProductPointDtoToJSON)),
        'productDescription': value['productDescription'],
        'productDiscountPrice': value['productDiscountPrice'],
        'productHashtag': value['productHashtag'],
        'productId': value['productId'],
        'productImage': value['productImage'],
        'productInventory': value['productInventory'],
        'productNameChi': value['productNameChi'],
        'productNameEng': value['productNameEng'],
        'productOriginalPrice': value['productOriginalPrice'],
        'productPrice': value['productPrice'],
        'productStep': value['productStep'],
        'productType': value['productType'],
        'productVolume': value['productVolume'],
        'productVolumeUnit': value['productVolumeUnit'],
        'products': value['products'] == null ? undefined : ((value['products'] as Array<any>).map(ProductDtoToJSON)),
        'publishDate': value['publishDate'] == null ? undefined : ((value['publishDate']).toISOString()),
        'sales': value['sales'],
        'sku': value['sku'],
        'status': value['status'],
        'subscriptionPeriod': value['subscriptionPeriod'],
        'thumbFileNames': value['thumbFileNames'],
        'translator': value['translator'],
        'version': value['version'],
    };
}

