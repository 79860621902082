/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CalendarEvent
 */
export interface CalendarEvent {
    /**
     * 
     * @type {number}
     * @memberof CalendarEvent
     */
    calendarEventId?: number;
    /**
     * 
     * @type {string}
     * @memberof CalendarEvent
     */
    description?: string;
    /**
     * 
     * @type {Date}
     * @memberof CalendarEvent
     */
    endDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof CalendarEvent
     */
    startDate?: Date;
    /**
     * 
     * @type {number}
     * @memberof CalendarEvent
     */
    status?: number;
    /**
     * 
     * @type {string}
     * @memberof CalendarEvent
     */
    title?: string;
}

/**
 * Check if a given object implements the CalendarEvent interface.
 */
export function instanceOfCalendarEvent(value: object): boolean {
    return true;
}

export function CalendarEventFromJSON(json: any): CalendarEvent {
    return CalendarEventFromJSONTyped(json, false);
}

export function CalendarEventFromJSONTyped(json: any, ignoreDiscriminator: boolean): CalendarEvent {
    if (json == null) {
        return json;
    }
    return {
        
        'calendarEventId': json['calendarEventId'] == null ? undefined : json['calendarEventId'],
        'description': json['description'] == null ? undefined : json['description'],
        'endDate': json['endDate'] == null ? undefined : (new Date(json['endDate'])),
        'startDate': json['startDate'] == null ? undefined : (new Date(json['startDate'])),
        'status': json['status'] == null ? undefined : json['status'],
        'title': json['title'] == null ? undefined : json['title'],
    };
}

export function CalendarEventToJSON(value?: CalendarEvent | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'calendarEventId': value['calendarEventId'],
        'description': value['description'],
        'endDate': value['endDate'] == null ? undefined : ((value['endDate']).toISOString()),
        'startDate': value['startDate'] == null ? undefined : ((value['startDate']).toISOString()),
        'status': value['status'],
        'title': value['title'],
    };
}

