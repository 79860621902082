/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ProductDtoLite } from './ProductDtoLite';
import {
    ProductDtoLiteFromJSON,
    ProductDtoLiteFromJSONTyped,
    ProductDtoLiteToJSON,
} from './ProductDtoLite';

/**
 * 
 * @export
 * @interface ProductPointDtoAdmin
 */
export interface ProductPointDtoAdmin {
    /**
     * 
     * @type {number}
     * @memberof ProductPointDtoAdmin
     */
    money?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductPointDtoAdmin
     */
    point?: number;
    /**
     * 
     * @type {ProductDtoLite}
     * @memberof ProductPointDtoAdmin
     */
    product?: ProductDtoLite;
    /**
     * 
     * @type {number}
     * @memberof ProductPointDtoAdmin
     */
    productId?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductPointDtoAdmin
     */
    productPointId?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductPointDtoAdmin
     */
    status?: string;
}

/**
 * Check if a given object implements the ProductPointDtoAdmin interface.
 */
export function instanceOfProductPointDtoAdmin(value: object): boolean {
    return true;
}

export function ProductPointDtoAdminFromJSON(json: any): ProductPointDtoAdmin {
    return ProductPointDtoAdminFromJSONTyped(json, false);
}

export function ProductPointDtoAdminFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProductPointDtoAdmin {
    if (json == null) {
        return json;
    }
    return {
        
        'money': json['money'] == null ? undefined : json['money'],
        'point': json['point'] == null ? undefined : json['point'],
        'product': json['product'] == null ? undefined : ProductDtoLiteFromJSON(json['product']),
        'productId': json['productId'] == null ? undefined : json['productId'],
        'productPointId': json['productPointId'] == null ? undefined : json['productPointId'],
        'status': json['status'] == null ? undefined : json['status'],
    };
}

export function ProductPointDtoAdminToJSON(value?: ProductPointDtoAdmin | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'money': value['money'],
        'point': value['point'],
        'product': ProductDtoLiteToJSON(value['product']),
        'productId': value['productId'],
        'productPointId': value['productPointId'],
        'status': value['status'],
    };
}

