/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Organization } from './Organization';
import {
    OrganizationFromJSON,
    OrganizationFromJSONTyped,
    OrganizationToJSON,
} from './Organization';

/**
 * 
 * @export
 * @interface OrganizationMapping
 */
export interface OrganizationMapping {
    /**
     * 
     * @type {Organization}
     * @memberof OrganizationMapping
     */
    mainOrganizationByMainOrganizationId?: Organization;
    /**
     * 
     * @type {number}
     * @memberof OrganizationMapping
     */
    mainOrganizationId?: number;
    /**
     * 
     * @type {Organization}
     * @memberof OrganizationMapping
     */
    subOrganizationBySubOrganizationId?: Organization;
    /**
     * 
     * @type {number}
     * @memberof OrganizationMapping
     */
    subOrganizationId?: number;
}

/**
 * Check if a given object implements the OrganizationMapping interface.
 */
export function instanceOfOrganizationMapping(value: object): boolean {
    return true;
}

export function OrganizationMappingFromJSON(json: any): OrganizationMapping {
    return OrganizationMappingFromJSONTyped(json, false);
}

export function OrganizationMappingFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrganizationMapping {
    if (json == null) {
        return json;
    }
    return {
        
        'mainOrganizationByMainOrganizationId': json['mainOrganizationByMainOrganizationId'] == null ? undefined : OrganizationFromJSON(json['mainOrganizationByMainOrganizationId']),
        'mainOrganizationId': json['mainOrganizationId'] == null ? undefined : json['mainOrganizationId'],
        'subOrganizationBySubOrganizationId': json['subOrganizationBySubOrganizationId'] == null ? undefined : OrganizationFromJSON(json['subOrganizationBySubOrganizationId']),
        'subOrganizationId': json['subOrganizationId'] == null ? undefined : json['subOrganizationId'],
    };
}

export function OrganizationMappingToJSON(value?: OrganizationMapping | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'mainOrganizationByMainOrganizationId': OrganizationToJSON(value['mainOrganizationByMainOrganizationId']),
        'mainOrganizationId': value['mainOrganizationId'],
        'subOrganizationBySubOrganizationId': OrganizationToJSON(value['subOrganizationBySubOrganizationId']),
        'subOrganizationId': value['subOrganizationId'],
    };
}

