/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  MessageDto,
} from '../models/index';
import {
    MessageDtoFromJSON,
    MessageDtoToJSON,
} from '../models/index';

export interface AddMessageUsingPOSTRequest {
    title: string;
    scheduledSendTime: Date;
    targetAsMember: number;
    targetAsVipMember: number;
    targetAsBirthdayMember: number;
    targetAsBirthdayVipMember: number;
    content: string;
    name?: string;
}

export interface DeleteMessageUsingPOSTRequest {
    messageId: number;
    name?: string;
}

export interface GetMessageByMessageIdUsingGETRequest {
    messageId: number;
}

export interface UpdateMessageUsingPOSTRequest {
    messageId: number;
    title: string;
    scheduledSendTime: Date;
    targetAsMember: number;
    targetAsVipMember: number;
    targetAsBirthdayMember: number;
    targetAsBirthdayVipMember: number;
    content: string;
    name?: string;
}

/**
 * 
 */
export class MessageControllerApi extends runtime.BaseAPI {

    /**
     * addMessage
     */
    async addMessageUsingPOSTRaw(requestParameters: AddMessageUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MessageDto>> {
        if (requestParameters['title'] == null) {
            throw new runtime.RequiredError(
                'title',
                'Required parameter "title" was null or undefined when calling addMessageUsingPOST().'
            );
        }

        if (requestParameters['scheduledSendTime'] == null) {
            throw new runtime.RequiredError(
                'scheduledSendTime',
                'Required parameter "scheduledSendTime" was null or undefined when calling addMessageUsingPOST().'
            );
        }

        if (requestParameters['targetAsMember'] == null) {
            throw new runtime.RequiredError(
                'targetAsMember',
                'Required parameter "targetAsMember" was null or undefined when calling addMessageUsingPOST().'
            );
        }

        if (requestParameters['targetAsVipMember'] == null) {
            throw new runtime.RequiredError(
                'targetAsVipMember',
                'Required parameter "targetAsVipMember" was null or undefined when calling addMessageUsingPOST().'
            );
        }

        if (requestParameters['targetAsBirthdayMember'] == null) {
            throw new runtime.RequiredError(
                'targetAsBirthdayMember',
                'Required parameter "targetAsBirthdayMember" was null or undefined when calling addMessageUsingPOST().'
            );
        }

        if (requestParameters['targetAsBirthdayVipMember'] == null) {
            throw new runtime.RequiredError(
                'targetAsBirthdayVipMember',
                'Required parameter "targetAsBirthdayVipMember" was null or undefined when calling addMessageUsingPOST().'
            );
        }

        if (requestParameters['content'] == null) {
            throw new runtime.RequiredError(
                'content',
                'Required parameter "content" was null or undefined when calling addMessageUsingPOST().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        if (requestParameters['title'] != null) {
            queryParameters['title'] = requestParameters['title'];
        }

        if (requestParameters['scheduledSendTime'] != null) {
            queryParameters['scheduledSendTime'] = (requestParameters['scheduledSendTime'] as any).toISOString();
        }

        if (requestParameters['targetAsMember'] != null) {
            queryParameters['targetAsMember'] = requestParameters['targetAsMember'];
        }

        if (requestParameters['targetAsVipMember'] != null) {
            queryParameters['targetAsVipMember'] = requestParameters['targetAsVipMember'];
        }

        if (requestParameters['targetAsBirthdayMember'] != null) {
            queryParameters['targetAsBirthdayMember'] = requestParameters['targetAsBirthdayMember'];
        }

        if (requestParameters['targetAsBirthdayVipMember'] != null) {
            queryParameters['targetAsBirthdayVipMember'] = requestParameters['targetAsBirthdayVipMember'];
        }

        if (requestParameters['content'] != null) {
            queryParameters['content'] = requestParameters['content'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/message/add`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MessageDtoFromJSON(jsonValue));
    }

    /**
     * addMessage
     */
    async addMessageUsingPOST(requestParameters: AddMessageUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MessageDto | null | undefined > {
        const response = await this.addMessageUsingPOSTRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 201:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * deleteMessage
     */
    async deleteMessageUsingPOSTRaw(requestParameters: DeleteMessageUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MessageDto>> {
        if (requestParameters['messageId'] == null) {
            throw new runtime.RequiredError(
                'messageId',
                'Required parameter "messageId" was null or undefined when calling deleteMessageUsingPOST().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        if (requestParameters['messageId'] != null) {
            queryParameters['messageId'] = requestParameters['messageId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/message/delete`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MessageDtoFromJSON(jsonValue));
    }

    /**
     * deleteMessage
     */
    async deleteMessageUsingPOST(requestParameters: DeleteMessageUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MessageDto | null | undefined > {
        const response = await this.deleteMessageUsingPOSTRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 201:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * getAllMessage
     */
    async getAllMessageUsingGETRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<MessageDto>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/message`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(MessageDtoFromJSON));
    }

    /**
     * getAllMessage
     */
    async getAllMessageUsingGET(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<MessageDto>> {
        const response = await this.getAllMessageUsingGETRaw(initOverrides);
        return await response.value();
    }

    /**
     * getAllMessage
     */
    async getAllMessageUsingGET1Raw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<MessageDto>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/message/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(MessageDtoFromJSON));
    }

    /**
     * getAllMessage
     */
    async getAllMessageUsingGET1(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<MessageDto>> {
        const response = await this.getAllMessageUsingGET1Raw(initOverrides);
        return await response.value();
    }

    /**
     * getAllMessage
     */
    async getAllMessageUsingGET2Raw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<MessageDto>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/message/all`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(MessageDtoFromJSON));
    }

    /**
     * getAllMessage
     */
    async getAllMessageUsingGET2(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<MessageDto>> {
        const response = await this.getAllMessageUsingGET2Raw(initOverrides);
        return await response.value();
    }

    /**
     * getMessageByMessageId
     */
    async getMessageByMessageIdUsingGETRaw(requestParameters: GetMessageByMessageIdUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MessageDto>> {
        if (requestParameters['messageId'] == null) {
            throw new runtime.RequiredError(
                'messageId',
                'Required parameter "messageId" was null or undefined when calling getMessageByMessageIdUsingGET().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/message/{messageId}`.replace(`{${"messageId"}}`, encodeURIComponent(String(requestParameters['messageId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MessageDtoFromJSON(jsonValue));
    }

    /**
     * getMessageByMessageId
     */
    async getMessageByMessageIdUsingGET(requestParameters: GetMessageByMessageIdUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MessageDto> {
        const response = await this.getMessageByMessageIdUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * updateMessage
     */
    async updateMessageUsingPOSTRaw(requestParameters: UpdateMessageUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MessageDto>> {
        if (requestParameters['messageId'] == null) {
            throw new runtime.RequiredError(
                'messageId',
                'Required parameter "messageId" was null or undefined when calling updateMessageUsingPOST().'
            );
        }

        if (requestParameters['title'] == null) {
            throw new runtime.RequiredError(
                'title',
                'Required parameter "title" was null or undefined when calling updateMessageUsingPOST().'
            );
        }

        if (requestParameters['scheduledSendTime'] == null) {
            throw new runtime.RequiredError(
                'scheduledSendTime',
                'Required parameter "scheduledSendTime" was null or undefined when calling updateMessageUsingPOST().'
            );
        }

        if (requestParameters['targetAsMember'] == null) {
            throw new runtime.RequiredError(
                'targetAsMember',
                'Required parameter "targetAsMember" was null or undefined when calling updateMessageUsingPOST().'
            );
        }

        if (requestParameters['targetAsVipMember'] == null) {
            throw new runtime.RequiredError(
                'targetAsVipMember',
                'Required parameter "targetAsVipMember" was null or undefined when calling updateMessageUsingPOST().'
            );
        }

        if (requestParameters['targetAsBirthdayMember'] == null) {
            throw new runtime.RequiredError(
                'targetAsBirthdayMember',
                'Required parameter "targetAsBirthdayMember" was null or undefined when calling updateMessageUsingPOST().'
            );
        }

        if (requestParameters['targetAsBirthdayVipMember'] == null) {
            throw new runtime.RequiredError(
                'targetAsBirthdayVipMember',
                'Required parameter "targetAsBirthdayVipMember" was null or undefined when calling updateMessageUsingPOST().'
            );
        }

        if (requestParameters['content'] == null) {
            throw new runtime.RequiredError(
                'content',
                'Required parameter "content" was null or undefined when calling updateMessageUsingPOST().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        if (requestParameters['messageId'] != null) {
            queryParameters['messageId'] = requestParameters['messageId'];
        }

        if (requestParameters['title'] != null) {
            queryParameters['title'] = requestParameters['title'];
        }

        if (requestParameters['scheduledSendTime'] != null) {
            queryParameters['scheduledSendTime'] = (requestParameters['scheduledSendTime'] as any).toISOString();
        }

        if (requestParameters['targetAsMember'] != null) {
            queryParameters['targetAsMember'] = requestParameters['targetAsMember'];
        }

        if (requestParameters['targetAsVipMember'] != null) {
            queryParameters['targetAsVipMember'] = requestParameters['targetAsVipMember'];
        }

        if (requestParameters['targetAsBirthdayMember'] != null) {
            queryParameters['targetAsBirthdayMember'] = requestParameters['targetAsBirthdayMember'];
        }

        if (requestParameters['targetAsBirthdayVipMember'] != null) {
            queryParameters['targetAsBirthdayVipMember'] = requestParameters['targetAsBirthdayVipMember'];
        }

        if (requestParameters['content'] != null) {
            queryParameters['content'] = requestParameters['content'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/message/update`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MessageDtoFromJSON(jsonValue));
    }

    /**
     * updateMessage
     */
    async updateMessageUsingPOST(requestParameters: UpdateMessageUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MessageDto | null | undefined > {
        const response = await this.updateMessageUsingPOSTRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 201:
                return null;
            default:
                return await response.value();
        }
    }

}
