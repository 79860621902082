/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Category } from './Category';
import {
    CategoryFromJSON,
    CategoryFromJSONTyped,
    CategoryToJSON,
} from './Category';
import type { DisplayProduct } from './DisplayProduct';
import {
    DisplayProductFromJSON,
    DisplayProductFromJSONTyped,
    DisplayProductToJSON,
} from './DisplayProduct';

/**
 * 
 * @export
 * @interface DisplayProductCategory
 */
export interface DisplayProductCategory {
    /**
     * 
     * @type {Category}
     * @memberof DisplayProductCategory
     */
    categoryByCategoryId?: Category;
    /**
     * 
     * @type {number}
     * @memberof DisplayProductCategory
     */
    categoryId?: number;
    /**
     * 
     * @type {DisplayProduct}
     * @memberof DisplayProductCategory
     */
    displayProductByDisplayProductId?: DisplayProduct;
    /**
     * 
     * @type {number}
     * @memberof DisplayProductCategory
     */
    displayProductId?: number;
    /**
     * 
     * @type {number}
     * @memberof DisplayProductCategory
     */
    order?: number;
}

/**
 * Check if a given object implements the DisplayProductCategory interface.
 */
export function instanceOfDisplayProductCategory(value: object): boolean {
    return true;
}

export function DisplayProductCategoryFromJSON(json: any): DisplayProductCategory {
    return DisplayProductCategoryFromJSONTyped(json, false);
}

export function DisplayProductCategoryFromJSONTyped(json: any, ignoreDiscriminator: boolean): DisplayProductCategory {
    if (json == null) {
        return json;
    }
    return {
        
        'categoryByCategoryId': json['categoryByCategoryId'] == null ? undefined : CategoryFromJSON(json['categoryByCategoryId']),
        'categoryId': json['categoryId'] == null ? undefined : json['categoryId'],
        'displayProductByDisplayProductId': json['displayProductByDisplayProductId'] == null ? undefined : DisplayProductFromJSON(json['displayProductByDisplayProductId']),
        'displayProductId': json['displayProductId'] == null ? undefined : json['displayProductId'],
        'order': json['order'] == null ? undefined : json['order'],
    };
}

export function DisplayProductCategoryToJSON(value?: DisplayProductCategory | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'categoryByCategoryId': CategoryToJSON(value['categoryByCategoryId']),
        'categoryId': value['categoryId'],
        'displayProductByDisplayProductId': DisplayProductToJSON(value['displayProductByDisplayProductId']),
        'displayProductId': value['displayProductId'],
        'order': value['order'],
    };
}

