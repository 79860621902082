/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Country } from './Country';
import {
    CountryFromJSON,
    CountryFromJSONTyped,
    CountryToJSON,
} from './Country';
import type { CouponDto } from './CouponDto';
import {
    CouponDtoFromJSON,
    CouponDtoFromJSONTyped,
    CouponDtoToJSON,
} from './CouponDto';
import type { MarketingEventDto } from './MarketingEventDto';
import {
    MarketingEventDtoFromJSON,
    MarketingEventDtoFromJSONTyped,
    MarketingEventDtoToJSON,
} from './MarketingEventDto';
import type { MemberDto } from './MemberDto';
import {
    MemberDtoFromJSON,
    MemberDtoFromJSONTyped,
    MemberDtoToJSON,
} from './MemberDto';
import type { OrderProductDto } from './OrderProductDto';
import {
    OrderProductDtoFromJSON,
    OrderProductDtoFromJSONTyped,
    OrderProductDtoToJSON,
} from './OrderProductDto';
import type { PointRuleDto } from './PointRuleDto';
import {
    PointRuleDtoFromJSON,
    PointRuleDtoFromJSONTyped,
    PointRuleDtoToJSON,
} from './PointRuleDto';
import type { ShippingDto } from './ShippingDto';
import {
    ShippingDtoFromJSON,
    ShippingDtoFromJSONTyped,
    ShippingDtoToJSON,
} from './ShippingDto';

/**
 * 
 * @export
 * @interface OrderDto
 */
export interface OrderDto {
    /**
     * 
     * @type {number}
     * @memberof OrderDto
     */
    convertedCash?: number;
    /**
     * 
     * @type {Country}
     * @memberof OrderDto
     */
    country?: Country;
    /**
     * 
     * @type {number}
     * @memberof OrderDto
     */
    countryId?: number;
    /**
     * 
     * @type {Array<CouponDto>}
     * @memberof OrderDto
     */
    coupons?: Array<CouponDto>;
    /**
     * 
     * @type {Date}
     * @memberof OrderDto
     */
    deliveryDate2nd?: Date;
    /**
     * 
     * @type {number}
     * @memberof OrderDto
     */
    earnedPoint?: number;
    /**
     * 
     * @type {boolean}
     * @memberof OrderDto
     */
    hasGroupProduct?: boolean;
    /**
     * 
     * @type {Array<MarketingEventDto>}
     * @memberof OrderDto
     */
    marketingEvents?: Array<MarketingEventDto>;
    /**
     * 
     * @type {MemberDto}
     * @memberof OrderDto
     */
    member?: MemberDto;
    /**
     * 
     * @type {string}
     * @memberof OrderDto
     */
    orderAddress?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderDto
     */
    orderAddress1?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderDto
     */
    orderAddress2?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderDto
     */
    orderAddress3?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderDto
     */
    orderAddress4?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderDto
     */
    orderAddressCity?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderDto
     */
    orderAddressProvince?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderDto
     */
    orderAddressRecipient?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderDto
     */
    orderAddressZone?: string;
    /**
     * 
     * @type {Date}
     * @memberof OrderDto
     */
    orderDate?: Date;
    /**
     * 
     * @type {number}
     * @memberof OrderDto
     */
    orderId?: number;
    /**
     * 
     * @type {string}
     * @memberof OrderDto
     */
    orderNo?: string;
    /**
     * 
     * @type {Array<OrderProductDto>}
     * @memberof OrderDto
     */
    orderProducts?: Array<OrderProductDto>;
    /**
     * 
     * @type {string}
     * @memberof OrderDto
     */
    orderStatus?: string;
    /**
     * 
     * @type {number}
     * @memberof OrderDto
     */
    orderTotal?: number;
    /**
     * 
     * @type {string}
     * @memberof OrderDto
     */
    paymentMethod?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderDto
     */
    phone?: string;
    /**
     * 
     * @type {PointRuleDto}
     * @memberof OrderDto
     */
    pointRule?: PointRuleDto;
    /**
     * 
     * @type {number}
     * @memberof OrderDto
     */
    productTotal?: number;
    /**
     * 
     * @type {number}
     * @memberof OrderDto
     */
    productTotalBeforeDiscount?: number;
    /**
     * 
     * @type {number}
     * @memberof OrderDto
     */
    shippingFee?: number;
    /**
     * 
     * @type {string}
     * @memberof OrderDto
     */
    shippingMedhod?: string;
    /**
     * 
     * @type {Array<ShippingDto>}
     * @memberof OrderDto
     */
    shippings?: Array<ShippingDto>;
    /**
     * 
     * @type {number}
     * @memberof OrderDto
     */
    totalUsedPointForProduct?: number;
    /**
     * 
     * @type {string}
     * @memberof OrderDto
     */
    trackingNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderDto
     */
    trackingNumber2nd?: string;
    /**
     * 
     * @type {number}
     * @memberof OrderDto
     */
    usedPoint?: number;
}

/**
 * Check if a given object implements the OrderDto interface.
 */
export function instanceOfOrderDto(value: object): boolean {
    return true;
}

export function OrderDtoFromJSON(json: any): OrderDto {
    return OrderDtoFromJSONTyped(json, false);
}

export function OrderDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrderDto {
    if (json == null) {
        return json;
    }
    return {
        
        'convertedCash': json['convertedCash'] == null ? undefined : json['convertedCash'],
        'country': json['country'] == null ? undefined : CountryFromJSON(json['country']),
        'countryId': json['countryId'] == null ? undefined : json['countryId'],
        'coupons': json['coupons'] == null ? undefined : ((json['coupons'] as Array<any>).map(CouponDtoFromJSON)),
        'deliveryDate2nd': json['deliveryDate2nd'] == null ? undefined : (new Date(json['deliveryDate2nd'])),
        'earnedPoint': json['earnedPoint'] == null ? undefined : json['earnedPoint'],
        'hasGroupProduct': json['hasGroupProduct'] == null ? undefined : json['hasGroupProduct'],
        'marketingEvents': json['marketingEvents'] == null ? undefined : ((json['marketingEvents'] as Array<any>).map(MarketingEventDtoFromJSON)),
        'member': json['member'] == null ? undefined : MemberDtoFromJSON(json['member']),
        'orderAddress': json['orderAddress'] == null ? undefined : json['orderAddress'],
        'orderAddress1': json['orderAddress1'] == null ? undefined : json['orderAddress1'],
        'orderAddress2': json['orderAddress2'] == null ? undefined : json['orderAddress2'],
        'orderAddress3': json['orderAddress3'] == null ? undefined : json['orderAddress3'],
        'orderAddress4': json['orderAddress4'] == null ? undefined : json['orderAddress4'],
        'orderAddressCity': json['orderAddressCity'] == null ? undefined : json['orderAddressCity'],
        'orderAddressProvince': json['orderAddressProvince'] == null ? undefined : json['orderAddressProvince'],
        'orderAddressRecipient': json['orderAddressRecipient'] == null ? undefined : json['orderAddressRecipient'],
        'orderAddressZone': json['orderAddressZone'] == null ? undefined : json['orderAddressZone'],
        'orderDate': json['orderDate'] == null ? undefined : (new Date(json['orderDate'])),
        'orderId': json['orderId'] == null ? undefined : json['orderId'],
        'orderNo': json['orderNo'] == null ? undefined : json['orderNo'],
        'orderProducts': json['orderProducts'] == null ? undefined : ((json['orderProducts'] as Array<any>).map(OrderProductDtoFromJSON)),
        'orderStatus': json['orderStatus'] == null ? undefined : json['orderStatus'],
        'orderTotal': json['orderTotal'] == null ? undefined : json['orderTotal'],
        'paymentMethod': json['paymentMethod'] == null ? undefined : json['paymentMethod'],
        'phone': json['phone'] == null ? undefined : json['phone'],
        'pointRule': json['pointRule'] == null ? undefined : PointRuleDtoFromJSON(json['pointRule']),
        'productTotal': json['productTotal'] == null ? undefined : json['productTotal'],
        'productTotalBeforeDiscount': json['productTotalBeforeDiscount'] == null ? undefined : json['productTotalBeforeDiscount'],
        'shippingFee': json['shippingFee'] == null ? undefined : json['shippingFee'],
        'shippingMedhod': json['shippingMedhod'] == null ? undefined : json['shippingMedhod'],
        'shippings': json['shippings'] == null ? undefined : ((json['shippings'] as Array<any>).map(ShippingDtoFromJSON)),
        'totalUsedPointForProduct': json['totalUsedPointForProduct'] == null ? undefined : json['totalUsedPointForProduct'],
        'trackingNumber': json['trackingNumber'] == null ? undefined : json['trackingNumber'],
        'trackingNumber2nd': json['trackingNumber2nd'] == null ? undefined : json['trackingNumber2nd'],
        'usedPoint': json['usedPoint'] == null ? undefined : json['usedPoint'],
    };
}

export function OrderDtoToJSON(value?: OrderDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'convertedCash': value['convertedCash'],
        'country': CountryToJSON(value['country']),
        'countryId': value['countryId'],
        'coupons': value['coupons'] == null ? undefined : ((value['coupons'] as Array<any>).map(CouponDtoToJSON)),
        'deliveryDate2nd': value['deliveryDate2nd'] == null ? undefined : ((value['deliveryDate2nd']).toISOString()),
        'earnedPoint': value['earnedPoint'],
        'hasGroupProduct': value['hasGroupProduct'],
        'marketingEvents': value['marketingEvents'] == null ? undefined : ((value['marketingEvents'] as Array<any>).map(MarketingEventDtoToJSON)),
        'member': MemberDtoToJSON(value['member']),
        'orderAddress': value['orderAddress'],
        'orderAddress1': value['orderAddress1'],
        'orderAddress2': value['orderAddress2'],
        'orderAddress3': value['orderAddress3'],
        'orderAddress4': value['orderAddress4'],
        'orderAddressCity': value['orderAddressCity'],
        'orderAddressProvince': value['orderAddressProvince'],
        'orderAddressRecipient': value['orderAddressRecipient'],
        'orderAddressZone': value['orderAddressZone'],
        'orderDate': value['orderDate'] == null ? undefined : ((value['orderDate']).toISOString()),
        'orderId': value['orderId'],
        'orderNo': value['orderNo'],
        'orderProducts': value['orderProducts'] == null ? undefined : ((value['orderProducts'] as Array<any>).map(OrderProductDtoToJSON)),
        'orderStatus': value['orderStatus'],
        'orderTotal': value['orderTotal'],
        'paymentMethod': value['paymentMethod'],
        'phone': value['phone'],
        'pointRule': PointRuleDtoToJSON(value['pointRule']),
        'productTotal': value['productTotal'],
        'productTotalBeforeDiscount': value['productTotalBeforeDiscount'],
        'shippingFee': value['shippingFee'],
        'shippingMedhod': value['shippingMedhod'],
        'shippings': value['shippings'] == null ? undefined : ((value['shippings'] as Array<any>).map(ShippingDtoToJSON)),
        'totalUsedPointForProduct': value['totalUsedPointForProduct'],
        'trackingNumber': value['trackingNumber'],
        'trackingNumber2nd': value['trackingNumber2nd'],
        'usedPoint': value['usedPoint'],
    };
}

