/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  SfLocker,
} from '../models/index';
import {
    SfLockerFromJSON,
    SfLockerToJSON,
} from '../models/index';

export interface FindLockerByAreaUsingGETRequest {
    area?: string;
}

export interface FindLockerByLockerIdUsingPOSTRequest {
    lockerId: string;
}

/**
 * 
 */
export class SfLockerControllerApi extends runtime.BaseAPI {

    /**
     * findAllArea
     */
    async findAllAreaUsingGETRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<string>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/sf-locker/area`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * findAllArea
     */
    async findAllAreaUsingGET(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<string>> {
        const response = await this.findAllAreaUsingGETRaw(initOverrides);
        return await response.value();
    }

    /**
     * findLockerByArea
     */
    async findLockerByAreaUsingGETRaw(requestParameters: FindLockerByAreaUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<SfLocker>>> {
        const queryParameters: any = {};

        if (requestParameters['area'] != null) {
            queryParameters['area'] = requestParameters['area'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/sf-locker/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SfLockerFromJSON));
    }

    /**
     * findLockerByArea
     */
    async findLockerByAreaUsingGET(requestParameters: FindLockerByAreaUsingGETRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<SfLocker>> {
        const response = await this.findLockerByAreaUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * findLockerByLockerId
     */
    async findLockerByLockerIdUsingPOSTRaw(requestParameters: FindLockerByLockerIdUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SfLocker>> {
        if (requestParameters['lockerId'] == null) {
            throw new runtime.RequiredError(
                'lockerId',
                'Required parameter "lockerId" was null or undefined when calling findLockerByLockerIdUsingPOST().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['lockerId'] != null) {
            queryParameters['lockerId'] = requestParameters['lockerId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/sf-locker/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SfLockerFromJSON(jsonValue));
    }

    /**
     * findLockerByLockerId
     */
    async findLockerByLockerIdUsingPOST(requestParameters: FindLockerByLockerIdUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SfLocker | null | undefined > {
        const response = await this.findLockerByLockerIdUsingPOSTRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 201:
                return null;
            default:
                return await response.value();
        }
    }

}
