/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { OffShelfProduct } from './OffShelfProduct';
import {
    OffShelfProductFromJSON,
    OffShelfProductFromJSONTyped,
    OffShelfProductToJSON,
} from './OffShelfProduct';

/**
 * 
 * @export
 * @interface OffShelfModel
 */
export interface OffShelfModel {
    /**
     * 
     * @type {Array<OffShelfProduct>}
     * @memberof OffShelfModel
     */
    offShelfProducts?: Array<OffShelfProduct>;
}

/**
 * Check if a given object implements the OffShelfModel interface.
 */
export function instanceOfOffShelfModel(value: object): boolean {
    return true;
}

export function OffShelfModelFromJSON(json: any): OffShelfModel {
    return OffShelfModelFromJSONTyped(json, false);
}

export function OffShelfModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): OffShelfModel {
    if (json == null) {
        return json;
    }
    return {
        
        'offShelfProducts': json['offShelfProducts'] == null ? undefined : ((json['offShelfProducts'] as Array<any>).map(OffShelfProductFromJSON)),
    };
}

export function OffShelfModelToJSON(value?: OffShelfModel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'offShelfProducts': value['offShelfProducts'] == null ? undefined : ((value['offShelfProducts'] as Array<any>).map(OffShelfProductToJSON)),
    };
}

