/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  DiscountDto,
  MemberLevel,
  ProductDto,
} from '../models/index';
import {
    DiscountDtoFromJSON,
    DiscountDtoToJSON,
    MemberLevelFromJSON,
    MemberLevelToJSON,
    ProductDtoFromJSON,
    ProductDtoToJSON,
} from '../models/index';

export interface AddDiscountUsingPOSTRequest {
    discountTitle: string;
    discountType: string;
    name?: string;
    condition?: string;
    actionType?: string;
    actionValue?: number;
    target?: string;
    limitToUse?: number;
    couponCode?: string;
    discountProductId?: number;
    body?: Date;
}

export interface ClearDiscountProductUsingGETRequest {
    discountId: number;
    name?: string;
}

export interface ClearMemberLevelDiscountUsingGETRequest {
    memberLevelId: number;
    name?: string;
}

export interface ClearProductDiscountUsingGETRequest {
    productId: number;
    name?: string;
}

export interface DeleteDiscountProductUsingGETRequest {
    discountId: number;
    productIds: number;
    name?: string;
}

export interface DeleteDiscountUsingPOSTRequest {
    discountId: number;
    name?: string;
}

export interface GetAllDiscountAdminUsingGETRequest {
    name?: string;
}

export interface GetAllDiscountUsingGETRequest {
    name?: string;
}

export interface GetAllPwpDiscountUsingGETRequest {
    name?: string;
}

export interface GetAllSpecificDiscountUsingGETRequest {
    name?: string;
}

export interface GetAvailableGwpUsingGETRequest {
    name?: string;
}

export interface GetAvailablePwpForGuestUsingDELETERequest {
    productListJson: string;
}

export interface GetAvailablePwpForGuestUsingGETRequest {
    productListJson: string;
}

export interface GetAvailablePwpForGuestUsingHEADRequest {
    productListJson: string;
}

export interface GetAvailablePwpForGuestUsingOPTIONSRequest {
    productListJson: string;
}

export interface GetAvailablePwpForGuestUsingPATCHRequest {
    productListJson: string;
}

export interface GetAvailablePwpForGuestUsingPOSTRequest {
    productListJson: string;
}

export interface GetAvailablePwpForGuestUsingPUTRequest {
    productListJson: string;
}

export interface GetAvailablePwpForGuestUsingTRACERequest {
    productListJson: string;
}

export interface GetAvailablePwpTargetByMemberIdUsingGETRequest {
    name?: string;
}

export interface GetAvailablePwpTargetForGuestUsingDELETERequest {
    productListJson: string;
}

export interface GetAvailablePwpTargetForGuestUsingHEADRequest {
    productListJson: string;
}

export interface GetAvailablePwpTargetForGuestUsingOPTIONSRequest {
    productListJson: string;
}

export interface GetAvailablePwpTargetForGuestUsingPATCHRequest {
    productListJson: string;
}

export interface GetAvailablePwpTargetForGuestUsingPOSTRequest {
    productListJson: string;
}

export interface GetAvailablePwpTargetForGuestUsingPUTRequest {
    productListJson: string;
}

export interface GetAvailablePwpTargetForGuestUsingTRACERequest {
    productListJson: string;
}

export interface GetDiscountByDiscountIdAdminUsingGETRequest {
    discountId: number;
    name?: string;
}

export interface GetDiscountByDiscountIdUsingGETRequest {
    discountId: number;
}

export interface GetDiscountProductByDiscountIdUsingGETRequest {
    discountId: number;
}

export interface SetDiscountProductUsingGETRequest {
    discountId: number;
    productIds: number;
    name?: string;
}

export interface SetMemberLevelDiscountUsingGETRequest {
    memberLevelId: number;
    discountIds: number;
    name?: string;
}

export interface UpdateDiscountLimitToUseUsingGETRequest {
    discountId: number;
    limitToUse: number;
    name?: string;
}

export interface UpdateDiscountUsingPOSTRequest {
    discountId: number;
    name?: string;
    discountTitle?: string;
    discountType?: string;
    condition?: string;
    actionType?: string;
    actionValue?: number;
    target?: string;
    limitToUse?: number;
    couponCode?: string;
    discountProductId?: number;
    body?: Date;
}

/**
 * 
 */
export class DiscontControllerApi extends runtime.BaseAPI {

    /**
     * addDiscount
     */
    async addDiscountUsingPOSTRaw(requestParameters: AddDiscountUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DiscountDto>> {
        if (requestParameters['discountTitle'] == null) {
            throw new runtime.RequiredError(
                'discountTitle',
                'Required parameter "discountTitle" was null or undefined when calling addDiscountUsingPOST().'
            );
        }

        if (requestParameters['discountType'] == null) {
            throw new runtime.RequiredError(
                'discountType',
                'Required parameter "discountType" was null or undefined when calling addDiscountUsingPOST().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        if (requestParameters['discountTitle'] != null) {
            queryParameters['discountTitle'] = requestParameters['discountTitle'];
        }

        if (requestParameters['discountType'] != null) {
            queryParameters['discountType'] = requestParameters['discountType'];
        }

        if (requestParameters['condition'] != null) {
            queryParameters['condition'] = requestParameters['condition'];
        }

        if (requestParameters['actionType'] != null) {
            queryParameters['actionType'] = requestParameters['actionType'];
        }

        if (requestParameters['actionValue'] != null) {
            queryParameters['actionValue'] = requestParameters['actionValue'];
        }

        if (requestParameters['target'] != null) {
            queryParameters['target'] = requestParameters['target'];
        }

        if (requestParameters['limitToUse'] != null) {
            queryParameters['limitToUse'] = requestParameters['limitToUse'];
        }

        if (requestParameters['couponCode'] != null) {
            queryParameters['couponCode'] = requestParameters['couponCode'];
        }

        if (requestParameters['discountProductId'] != null) {
            queryParameters['discountProductId'] = requestParameters['discountProductId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/discount/add`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters['body'] as any,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DiscountDtoFromJSON(jsonValue));
    }

    /**
     * addDiscount
     */
    async addDiscountUsingPOST(requestParameters: AddDiscountUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DiscountDto | null | undefined > {
        const response = await this.addDiscountUsingPOSTRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 201:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * clearDiscountProduct
     */
    async clearDiscountProductUsingGETRaw(requestParameters: ClearDiscountProductUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DiscountDto>> {
        if (requestParameters['discountId'] == null) {
            throw new runtime.RequiredError(
                'discountId',
                'Required parameter "discountId" was null or undefined when calling clearDiscountProductUsingGET().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/discount/{discountId}/products/clear`.replace(`{${"discountId"}}`, encodeURIComponent(String(requestParameters['discountId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DiscountDtoFromJSON(jsonValue));
    }

    /**
     * clearDiscountProduct
     */
    async clearDiscountProductUsingGET(requestParameters: ClearDiscountProductUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DiscountDto> {
        const response = await this.clearDiscountProductUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * clearMemberLevelDiscount
     */
    async clearMemberLevelDiscountUsingGETRaw(requestParameters: ClearMemberLevelDiscountUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MemberLevel>> {
        if (requestParameters['memberLevelId'] == null) {
            throw new runtime.RequiredError(
                'memberLevelId',
                'Required parameter "memberLevelId" was null or undefined when calling clearMemberLevelDiscountUsingGET().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/discount/clearMemberLevelDiscount/{memberLevelId}`.replace(`{${"memberLevelId"}}`, encodeURIComponent(String(requestParameters['memberLevelId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MemberLevelFromJSON(jsonValue));
    }

    /**
     * clearMemberLevelDiscount
     */
    async clearMemberLevelDiscountUsingGET(requestParameters: ClearMemberLevelDiscountUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MemberLevel> {
        const response = await this.clearMemberLevelDiscountUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * clearProductDiscount
     */
    async clearProductDiscountUsingGETRaw(requestParameters: ClearProductDiscountUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProductDto>> {
        if (requestParameters['productId'] == null) {
            throw new runtime.RequiredError(
                'productId',
                'Required parameter "productId" was null or undefined when calling clearProductDiscountUsingGET().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/discount/product/{productId}/clear`.replace(`{${"productId"}}`, encodeURIComponent(String(requestParameters['productId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProductDtoFromJSON(jsonValue));
    }

    /**
     * clearProductDiscount
     */
    async clearProductDiscountUsingGET(requestParameters: ClearProductDiscountUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProductDto> {
        const response = await this.clearProductDiscountUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * deleteDiscountProduct
     */
    async deleteDiscountProductUsingGETRaw(requestParameters: DeleteDiscountProductUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DiscountDto>> {
        if (requestParameters['discountId'] == null) {
            throw new runtime.RequiredError(
                'discountId',
                'Required parameter "discountId" was null or undefined when calling deleteDiscountProductUsingGET().'
            );
        }

        if (requestParameters['productIds'] == null) {
            throw new runtime.RequiredError(
                'productIds',
                'Required parameter "productIds" was null or undefined when calling deleteDiscountProductUsingGET().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        if (requestParameters['productIds'] != null) {
            queryParameters['productIds'] = requestParameters['productIds'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/discount/{discountId}/products/delete`.replace(`{${"discountId"}}`, encodeURIComponent(String(requestParameters['discountId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DiscountDtoFromJSON(jsonValue));
    }

    /**
     * deleteDiscountProduct
     */
    async deleteDiscountProductUsingGET(requestParameters: DeleteDiscountProductUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DiscountDto> {
        const response = await this.deleteDiscountProductUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * deleteDiscount
     */
    async deleteDiscountUsingPOSTRaw(requestParameters: DeleteDiscountUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DiscountDto>> {
        if (requestParameters['discountId'] == null) {
            throw new runtime.RequiredError(
                'discountId',
                'Required parameter "discountId" was null or undefined when calling deleteDiscountUsingPOST().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/discount/delete/{discountId}`.replace(`{${"discountId"}}`, encodeURIComponent(String(requestParameters['discountId']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DiscountDtoFromJSON(jsonValue));
    }

    /**
     * deleteDiscount
     */
    async deleteDiscountUsingPOST(requestParameters: DeleteDiscountUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DiscountDto | null | undefined > {
        const response = await this.deleteDiscountUsingPOSTRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 201:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * getAllDiscountAdmin
     */
    async getAllDiscountAdminUsingGETRaw(requestParameters: GetAllDiscountAdminUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<DiscountDto>>> {
        const queryParameters: any = {};

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/discount/admin/all`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(DiscountDtoFromJSON));
    }

    /**
     * getAllDiscountAdmin
     */
    async getAllDiscountAdminUsingGET(requestParameters: GetAllDiscountAdminUsingGETRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<DiscountDto>> {
        const response = await this.getAllDiscountAdminUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getAllDiscount
     */
    async getAllDiscountUsingGETRaw(requestParameters: GetAllDiscountUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<DiscountDto>>> {
        const queryParameters: any = {};

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/discount/all`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(DiscountDtoFromJSON));
    }

    /**
     * getAllDiscount
     */
    async getAllDiscountUsingGET(requestParameters: GetAllDiscountUsingGETRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<DiscountDto>> {
        const response = await this.getAllDiscountUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getAllPwpDiscount
     */
    async getAllPwpDiscountUsingGETRaw(requestParameters: GetAllPwpDiscountUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<DiscountDto>>> {
        const queryParameters: any = {};

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/discount/pwp`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(DiscountDtoFromJSON));
    }

    /**
     * getAllPwpDiscount
     */
    async getAllPwpDiscountUsingGET(requestParameters: GetAllPwpDiscountUsingGETRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<DiscountDto>> {
        const response = await this.getAllPwpDiscountUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getAllSpecificDiscount
     */
    async getAllSpecificDiscountUsingGETRaw(requestParameters: GetAllSpecificDiscountUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<DiscountDto>>> {
        const queryParameters: any = {};

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/discount/allSpecific`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(DiscountDtoFromJSON));
    }

    /**
     * getAllSpecificDiscount
     */
    async getAllSpecificDiscountUsingGET(requestParameters: GetAllSpecificDiscountUsingGETRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<DiscountDto>> {
        const response = await this.getAllSpecificDiscountUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getAvailableGwp
     */
    async getAvailableGwpUsingGETRaw(requestParameters: GetAvailableGwpUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<DiscountDto>>> {
        const queryParameters: any = {};

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/discount/available_gwp`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(DiscountDtoFromJSON));
    }

    /**
     * getAvailableGwp
     */
    async getAvailableGwpUsingGET(requestParameters: GetAvailableGwpUsingGETRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<DiscountDto>> {
        const response = await this.getAvailableGwpUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getAvailablePwpForGuest
     */
    async getAvailablePwpForGuestUsingDELETERaw(requestParameters: GetAvailablePwpForGuestUsingDELETERequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<{ [key: string]: Array<DiscountDto>; }>> {
        if (requestParameters['productListJson'] == null) {
            throw new runtime.RequiredError(
                'productListJson',
                'Required parameter "productListJson" was null or undefined when calling getAvailablePwpForGuestUsingDELETE().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['productListJson'] != null) {
            queryParameters['productListJson'] = requestParameters['productListJson'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/discount/available_pwp`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * getAvailablePwpForGuest
     */
    async getAvailablePwpForGuestUsingDELETE(requestParameters: GetAvailablePwpForGuestUsingDELETERequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<{ [key: string]: Array<DiscountDto>; } | null | undefined > {
        const response = await this.getAvailablePwpForGuestUsingDELETERaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 204:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * getAvailablePwpForGuest
     */
    async getAvailablePwpForGuestUsingGETRaw(requestParameters: GetAvailablePwpForGuestUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<{ [key: string]: Array<DiscountDto>; }>> {
        if (requestParameters['productListJson'] == null) {
            throw new runtime.RequiredError(
                'productListJson',
                'Required parameter "productListJson" was null or undefined when calling getAvailablePwpForGuestUsingGET().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['productListJson'] != null) {
            queryParameters['productListJson'] = requestParameters['productListJson'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/discount/available_pwp`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * getAvailablePwpForGuest
     */
    async getAvailablePwpForGuestUsingGET(requestParameters: GetAvailablePwpForGuestUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<{ [key: string]: Array<DiscountDto>; }> {
        const response = await this.getAvailablePwpForGuestUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getAvailablePwpForGuest
     */
    async getAvailablePwpForGuestUsingHEADRaw(requestParameters: GetAvailablePwpForGuestUsingHEADRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<{ [key: string]: Array<DiscountDto>; }>> {
        if (requestParameters['productListJson'] == null) {
            throw new runtime.RequiredError(
                'productListJson',
                'Required parameter "productListJson" was null or undefined when calling getAvailablePwpForGuestUsingHEAD().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['productListJson'] != null) {
            queryParameters['productListJson'] = requestParameters['productListJson'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/discount/available_pwp`,
            method: 'HEAD',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * getAvailablePwpForGuest
     */
    async getAvailablePwpForGuestUsingHEAD(requestParameters: GetAvailablePwpForGuestUsingHEADRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<{ [key: string]: Array<DiscountDto>; } | null | undefined > {
        const response = await this.getAvailablePwpForGuestUsingHEADRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 204:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * getAvailablePwpForGuest
     */
    async getAvailablePwpForGuestUsingOPTIONSRaw(requestParameters: GetAvailablePwpForGuestUsingOPTIONSRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<{ [key: string]: Array<DiscountDto>; }>> {
        if (requestParameters['productListJson'] == null) {
            throw new runtime.RequiredError(
                'productListJson',
                'Required parameter "productListJson" was null or undefined when calling getAvailablePwpForGuestUsingOPTIONS().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['productListJson'] != null) {
            queryParameters['productListJson'] = requestParameters['productListJson'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/discount/available_pwp`,
            method: 'OPTIONS',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * getAvailablePwpForGuest
     */
    async getAvailablePwpForGuestUsingOPTIONS(requestParameters: GetAvailablePwpForGuestUsingOPTIONSRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<{ [key: string]: Array<DiscountDto>; } | null | undefined > {
        const response = await this.getAvailablePwpForGuestUsingOPTIONSRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 204:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * getAvailablePwpForGuest
     */
    async getAvailablePwpForGuestUsingPATCHRaw(requestParameters: GetAvailablePwpForGuestUsingPATCHRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<{ [key: string]: Array<DiscountDto>; }>> {
        if (requestParameters['productListJson'] == null) {
            throw new runtime.RequiredError(
                'productListJson',
                'Required parameter "productListJson" was null or undefined when calling getAvailablePwpForGuestUsingPATCH().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['productListJson'] != null) {
            queryParameters['productListJson'] = requestParameters['productListJson'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/discount/available_pwp`,
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * getAvailablePwpForGuest
     */
    async getAvailablePwpForGuestUsingPATCH(requestParameters: GetAvailablePwpForGuestUsingPATCHRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<{ [key: string]: Array<DiscountDto>; } | null | undefined > {
        const response = await this.getAvailablePwpForGuestUsingPATCHRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 204:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * getAvailablePwpForGuest
     */
    async getAvailablePwpForGuestUsingPOSTRaw(requestParameters: GetAvailablePwpForGuestUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<{ [key: string]: Array<DiscountDto>; }>> {
        if (requestParameters['productListJson'] == null) {
            throw new runtime.RequiredError(
                'productListJson',
                'Required parameter "productListJson" was null or undefined when calling getAvailablePwpForGuestUsingPOST().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['productListJson'] != null) {
            queryParameters['productListJson'] = requestParameters['productListJson'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/discount/available_pwp`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * getAvailablePwpForGuest
     */
    async getAvailablePwpForGuestUsingPOST(requestParameters: GetAvailablePwpForGuestUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<{ [key: string]: Array<DiscountDto>; } | null | undefined > {
        const response = await this.getAvailablePwpForGuestUsingPOSTRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 201:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * getAvailablePwpForGuest
     */
    async getAvailablePwpForGuestUsingPUTRaw(requestParameters: GetAvailablePwpForGuestUsingPUTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<{ [key: string]: Array<DiscountDto>; }>> {
        if (requestParameters['productListJson'] == null) {
            throw new runtime.RequiredError(
                'productListJson',
                'Required parameter "productListJson" was null or undefined when calling getAvailablePwpForGuestUsingPUT().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['productListJson'] != null) {
            queryParameters['productListJson'] = requestParameters['productListJson'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/discount/available_pwp`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * getAvailablePwpForGuest
     */
    async getAvailablePwpForGuestUsingPUT(requestParameters: GetAvailablePwpForGuestUsingPUTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<{ [key: string]: Array<DiscountDto>; } | null | undefined > {
        const response = await this.getAvailablePwpForGuestUsingPUTRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 201:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * getAvailablePwpForGuest
     */
    async getAvailablePwpForGuestUsingTRACERaw(requestParameters: GetAvailablePwpForGuestUsingTRACERequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<{ [key: string]: Array<DiscountDto>; }>> {
        if (requestParameters['productListJson'] == null) {
            throw new runtime.RequiredError(
                'productListJson',
                'Required parameter "productListJson" was null or undefined when calling getAvailablePwpForGuestUsingTRACE().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['productListJson'] != null) {
            queryParameters['productListJson'] = requestParameters['productListJson'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/discount/available_pwp`,
            method: 'TRACE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * getAvailablePwpForGuest
     */
    async getAvailablePwpForGuestUsingTRACE(requestParameters: GetAvailablePwpForGuestUsingTRACERequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<{ [key: string]: Array<DiscountDto>; } | null | undefined > {
        const response = await this.getAvailablePwpForGuestUsingTRACERaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 204:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * getAvailablePwpTargetByMemberId
     */
    async getAvailablePwpTargetByMemberIdUsingGETRaw(requestParameters: GetAvailablePwpTargetByMemberIdUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<{ [key: string]: Array<DiscountDto>; }>> {
        const queryParameters: any = {};

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/discount/available_pwp_target`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * getAvailablePwpTargetByMemberId
     */
    async getAvailablePwpTargetByMemberIdUsingGET(requestParameters: GetAvailablePwpTargetByMemberIdUsingGETRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<{ [key: string]: Array<DiscountDto>; }> {
        const response = await this.getAvailablePwpTargetByMemberIdUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getAvailablePwpTargetForGuest
     */
    async getAvailablePwpTargetForGuestUsingDELETERaw(requestParameters: GetAvailablePwpTargetForGuestUsingDELETERequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<{ [key: string]: Array<DiscountDto>; }>> {
        if (requestParameters['productListJson'] == null) {
            throw new runtime.RequiredError(
                'productListJson',
                'Required parameter "productListJson" was null or undefined when calling getAvailablePwpTargetForGuestUsingDELETE().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['productListJson'] != null) {
            queryParameters['productListJson'] = requestParameters['productListJson'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/discount/available_pwp_target`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * getAvailablePwpTargetForGuest
     */
    async getAvailablePwpTargetForGuestUsingDELETE(requestParameters: GetAvailablePwpTargetForGuestUsingDELETERequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<{ [key: string]: Array<DiscountDto>; } | null | undefined > {
        const response = await this.getAvailablePwpTargetForGuestUsingDELETERaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 204:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * getAvailablePwpTargetForGuest
     */
    async getAvailablePwpTargetForGuestUsingHEADRaw(requestParameters: GetAvailablePwpTargetForGuestUsingHEADRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<{ [key: string]: Array<DiscountDto>; }>> {
        if (requestParameters['productListJson'] == null) {
            throw new runtime.RequiredError(
                'productListJson',
                'Required parameter "productListJson" was null or undefined when calling getAvailablePwpTargetForGuestUsingHEAD().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['productListJson'] != null) {
            queryParameters['productListJson'] = requestParameters['productListJson'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/discount/available_pwp_target`,
            method: 'HEAD',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * getAvailablePwpTargetForGuest
     */
    async getAvailablePwpTargetForGuestUsingHEAD(requestParameters: GetAvailablePwpTargetForGuestUsingHEADRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<{ [key: string]: Array<DiscountDto>; } | null | undefined > {
        const response = await this.getAvailablePwpTargetForGuestUsingHEADRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 204:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * getAvailablePwpTargetForGuest
     */
    async getAvailablePwpTargetForGuestUsingOPTIONSRaw(requestParameters: GetAvailablePwpTargetForGuestUsingOPTIONSRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<{ [key: string]: Array<DiscountDto>; }>> {
        if (requestParameters['productListJson'] == null) {
            throw new runtime.RequiredError(
                'productListJson',
                'Required parameter "productListJson" was null or undefined when calling getAvailablePwpTargetForGuestUsingOPTIONS().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['productListJson'] != null) {
            queryParameters['productListJson'] = requestParameters['productListJson'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/discount/available_pwp_target`,
            method: 'OPTIONS',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * getAvailablePwpTargetForGuest
     */
    async getAvailablePwpTargetForGuestUsingOPTIONS(requestParameters: GetAvailablePwpTargetForGuestUsingOPTIONSRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<{ [key: string]: Array<DiscountDto>; } | null | undefined > {
        const response = await this.getAvailablePwpTargetForGuestUsingOPTIONSRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 204:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * getAvailablePwpTargetForGuest
     */
    async getAvailablePwpTargetForGuestUsingPATCHRaw(requestParameters: GetAvailablePwpTargetForGuestUsingPATCHRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<{ [key: string]: Array<DiscountDto>; }>> {
        if (requestParameters['productListJson'] == null) {
            throw new runtime.RequiredError(
                'productListJson',
                'Required parameter "productListJson" was null or undefined when calling getAvailablePwpTargetForGuestUsingPATCH().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['productListJson'] != null) {
            queryParameters['productListJson'] = requestParameters['productListJson'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/discount/available_pwp_target`,
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * getAvailablePwpTargetForGuest
     */
    async getAvailablePwpTargetForGuestUsingPATCH(requestParameters: GetAvailablePwpTargetForGuestUsingPATCHRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<{ [key: string]: Array<DiscountDto>; } | null | undefined > {
        const response = await this.getAvailablePwpTargetForGuestUsingPATCHRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 204:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * getAvailablePwpTargetForGuest
     */
    async getAvailablePwpTargetForGuestUsingPOSTRaw(requestParameters: GetAvailablePwpTargetForGuestUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<{ [key: string]: Array<DiscountDto>; }>> {
        if (requestParameters['productListJson'] == null) {
            throw new runtime.RequiredError(
                'productListJson',
                'Required parameter "productListJson" was null or undefined when calling getAvailablePwpTargetForGuestUsingPOST().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['productListJson'] != null) {
            queryParameters['productListJson'] = requestParameters['productListJson'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/discount/available_pwp_target`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * getAvailablePwpTargetForGuest
     */
    async getAvailablePwpTargetForGuestUsingPOST(requestParameters: GetAvailablePwpTargetForGuestUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<{ [key: string]: Array<DiscountDto>; } | null | undefined > {
        const response = await this.getAvailablePwpTargetForGuestUsingPOSTRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 201:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * getAvailablePwpTargetForGuest
     */
    async getAvailablePwpTargetForGuestUsingPUTRaw(requestParameters: GetAvailablePwpTargetForGuestUsingPUTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<{ [key: string]: Array<DiscountDto>; }>> {
        if (requestParameters['productListJson'] == null) {
            throw new runtime.RequiredError(
                'productListJson',
                'Required parameter "productListJson" was null or undefined when calling getAvailablePwpTargetForGuestUsingPUT().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['productListJson'] != null) {
            queryParameters['productListJson'] = requestParameters['productListJson'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/discount/available_pwp_target`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * getAvailablePwpTargetForGuest
     */
    async getAvailablePwpTargetForGuestUsingPUT(requestParameters: GetAvailablePwpTargetForGuestUsingPUTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<{ [key: string]: Array<DiscountDto>; } | null | undefined > {
        const response = await this.getAvailablePwpTargetForGuestUsingPUTRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 201:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * getAvailablePwpTargetForGuest
     */
    async getAvailablePwpTargetForGuestUsingTRACERaw(requestParameters: GetAvailablePwpTargetForGuestUsingTRACERequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<{ [key: string]: Array<DiscountDto>; }>> {
        if (requestParameters['productListJson'] == null) {
            throw new runtime.RequiredError(
                'productListJson',
                'Required parameter "productListJson" was null or undefined when calling getAvailablePwpTargetForGuestUsingTRACE().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['productListJson'] != null) {
            queryParameters['productListJson'] = requestParameters['productListJson'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/discount/available_pwp_target`,
            method: 'TRACE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * getAvailablePwpTargetForGuest
     */
    async getAvailablePwpTargetForGuestUsingTRACE(requestParameters: GetAvailablePwpTargetForGuestUsingTRACERequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<{ [key: string]: Array<DiscountDto>; } | null | undefined > {
        const response = await this.getAvailablePwpTargetForGuestUsingTRACERaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 204:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * getDiscountByDiscountIdAdmin
     */
    async getDiscountByDiscountIdAdminUsingGETRaw(requestParameters: GetDiscountByDiscountIdAdminUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DiscountDto>> {
        if (requestParameters['discountId'] == null) {
            throw new runtime.RequiredError(
                'discountId',
                'Required parameter "discountId" was null or undefined when calling getDiscountByDiscountIdAdminUsingGET().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/discount/admin/{discountId}`.replace(`{${"discountId"}}`, encodeURIComponent(String(requestParameters['discountId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DiscountDtoFromJSON(jsonValue));
    }

    /**
     * getDiscountByDiscountIdAdmin
     */
    async getDiscountByDiscountIdAdminUsingGET(requestParameters: GetDiscountByDiscountIdAdminUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DiscountDto> {
        const response = await this.getDiscountByDiscountIdAdminUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getDiscountByDiscountId
     */
    async getDiscountByDiscountIdUsingGETRaw(requestParameters: GetDiscountByDiscountIdUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DiscountDto>> {
        if (requestParameters['discountId'] == null) {
            throw new runtime.RequiredError(
                'discountId',
                'Required parameter "discountId" was null or undefined when calling getDiscountByDiscountIdUsingGET().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/discount/{discountId}`.replace(`{${"discountId"}}`, encodeURIComponent(String(requestParameters['discountId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DiscountDtoFromJSON(jsonValue));
    }

    /**
     * getDiscountByDiscountId
     */
    async getDiscountByDiscountIdUsingGET(requestParameters: GetDiscountByDiscountIdUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DiscountDto> {
        const response = await this.getDiscountByDiscountIdUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getDiscountProductByDiscountId
     */
    async getDiscountProductByDiscountIdUsingGETRaw(requestParameters: GetDiscountProductByDiscountIdUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ProductDto>>> {
        if (requestParameters['discountId'] == null) {
            throw new runtime.RequiredError(
                'discountId',
                'Required parameter "discountId" was null or undefined when calling getDiscountProductByDiscountIdUsingGET().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/discount/{discountId}/products`.replace(`{${"discountId"}}`, encodeURIComponent(String(requestParameters['discountId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ProductDtoFromJSON));
    }

    /**
     * getDiscountProductByDiscountId
     */
    async getDiscountProductByDiscountIdUsingGET(requestParameters: GetDiscountProductByDiscountIdUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ProductDto>> {
        const response = await this.getDiscountProductByDiscountIdUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * setDiscountProduct
     */
    async setDiscountProductUsingGETRaw(requestParameters: SetDiscountProductUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DiscountDto>> {
        if (requestParameters['discountId'] == null) {
            throw new runtime.RequiredError(
                'discountId',
                'Required parameter "discountId" was null or undefined when calling setDiscountProductUsingGET().'
            );
        }

        if (requestParameters['productIds'] == null) {
            throw new runtime.RequiredError(
                'productIds',
                'Required parameter "productIds" was null or undefined when calling setDiscountProductUsingGET().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        if (requestParameters['productIds'] != null) {
            queryParameters['productIds'] = requestParameters['productIds'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/discount/{discountId}/products/set`.replace(`{${"discountId"}}`, encodeURIComponent(String(requestParameters['discountId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DiscountDtoFromJSON(jsonValue));
    }

    /**
     * setDiscountProduct
     */
    async setDiscountProductUsingGET(requestParameters: SetDiscountProductUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DiscountDto> {
        const response = await this.setDiscountProductUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * setMemberLevelDiscount
     */
    async setMemberLevelDiscountUsingGETRaw(requestParameters: SetMemberLevelDiscountUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MemberLevel>> {
        if (requestParameters['memberLevelId'] == null) {
            throw new runtime.RequiredError(
                'memberLevelId',
                'Required parameter "memberLevelId" was null or undefined when calling setMemberLevelDiscountUsingGET().'
            );
        }

        if (requestParameters['discountIds'] == null) {
            throw new runtime.RequiredError(
                'discountIds',
                'Required parameter "discountIds" was null or undefined when calling setMemberLevelDiscountUsingGET().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        if (requestParameters['memberLevelId'] != null) {
            queryParameters['memberLevelId'] = requestParameters['memberLevelId'];
        }

        if (requestParameters['discountIds'] != null) {
            queryParameters['discountIds'] = requestParameters['discountIds'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/discount/setMemberLevelDiscount`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MemberLevelFromJSON(jsonValue));
    }

    /**
     * setMemberLevelDiscount
     */
    async setMemberLevelDiscountUsingGET(requestParameters: SetMemberLevelDiscountUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MemberLevel> {
        const response = await this.setMemberLevelDiscountUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * updateDiscountLimitToUse
     */
    async updateDiscountLimitToUseUsingGETRaw(requestParameters: UpdateDiscountLimitToUseUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DiscountDto>> {
        if (requestParameters['discountId'] == null) {
            throw new runtime.RequiredError(
                'discountId',
                'Required parameter "discountId" was null or undefined when calling updateDiscountLimitToUseUsingGET().'
            );
        }

        if (requestParameters['limitToUse'] == null) {
            throw new runtime.RequiredError(
                'limitToUse',
                'Required parameter "limitToUse" was null or undefined when calling updateDiscountLimitToUseUsingGET().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        if (requestParameters['discountId'] != null) {
            queryParameters['discountId'] = requestParameters['discountId'];
        }

        if (requestParameters['limitToUse'] != null) {
            queryParameters['limitToUse'] = requestParameters['limitToUse'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/discount/update/limitToUse`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DiscountDtoFromJSON(jsonValue));
    }

    /**
     * updateDiscountLimitToUse
     */
    async updateDiscountLimitToUseUsingGET(requestParameters: UpdateDiscountLimitToUseUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DiscountDto> {
        const response = await this.updateDiscountLimitToUseUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * updateDiscount
     */
    async updateDiscountUsingPOSTRaw(requestParameters: UpdateDiscountUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DiscountDto>> {
        if (requestParameters['discountId'] == null) {
            throw new runtime.RequiredError(
                'discountId',
                'Required parameter "discountId" was null or undefined when calling updateDiscountUsingPOST().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        if (requestParameters['discountId'] != null) {
            queryParameters['discountId'] = requestParameters['discountId'];
        }

        if (requestParameters['discountTitle'] != null) {
            queryParameters['discountTitle'] = requestParameters['discountTitle'];
        }

        if (requestParameters['discountType'] != null) {
            queryParameters['discountType'] = requestParameters['discountType'];
        }

        if (requestParameters['condition'] != null) {
            queryParameters['condition'] = requestParameters['condition'];
        }

        if (requestParameters['actionType'] != null) {
            queryParameters['actionType'] = requestParameters['actionType'];
        }

        if (requestParameters['actionValue'] != null) {
            queryParameters['actionValue'] = requestParameters['actionValue'];
        }

        if (requestParameters['target'] != null) {
            queryParameters['target'] = requestParameters['target'];
        }

        if (requestParameters['limitToUse'] != null) {
            queryParameters['limitToUse'] = requestParameters['limitToUse'];
        }

        if (requestParameters['couponCode'] != null) {
            queryParameters['couponCode'] = requestParameters['couponCode'];
        }

        if (requestParameters['discountProductId'] != null) {
            queryParameters['discountProductId'] = requestParameters['discountProductId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/discount/update`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters['body'] as any,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DiscountDtoFromJSON(jsonValue));
    }

    /**
     * updateDiscount
     */
    async updateDiscountUsingPOST(requestParameters: UpdateDiscountUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DiscountDto | null | undefined > {
        const response = await this.updateDiscountUsingPOSTRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 201:
                return null;
            default:
                return await response.value();
        }
    }

}
