/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AdminMemberRole
 */
export interface AdminMemberRole {
    /**
     * 
     * @type {number}
     * @memberof AdminMemberRole
     */
    admin?: number;
    /**
     * 
     * @type {number}
     * @memberof AdminMemberRole
     */
    adminMemberRoleId?: number;
    /**
     * 
     * @type {string}
     * @memberof AdminMemberRole
     */
    adminMemberRoleName?: string;
    /**
     * 
     * @type {number}
     * @memberof AdminMemberRole
     */
    articleManagement?: number;
    /**
     * 
     * @type {number}
     * @memberof AdminMemberRole
     */
    discountManagement?: number;
    /**
     * 
     * @type {number}
     * @memberof AdminMemberRole
     */
    inventoryManagement?: number;
    /**
     * 
     * @type {number}
     * @memberof AdminMemberRole
     */
    marketingEventManagement?: number;
    /**
     * 
     * @type {number}
     * @memberof AdminMemberRole
     */
    membershipManagement?: number;
    /**
     * 
     * @type {number}
     * @memberof AdminMemberRole
     */
    membershipManagementRead?: number;
    /**
     * 
     * @type {number}
     * @memberof AdminMemberRole
     */
    orderManagement?: number;
    /**
     * 
     * @type {number}
     * @memberof AdminMemberRole
     */
    orderManagementRead?: number;
    /**
     * 
     * @type {number}
     * @memberof AdminMemberRole
     */
    pointManagement?: number;
    /**
     * 
     * @type {number}
     * @memberof AdminMemberRole
     */
    productManagement?: number;
    /**
     * 
     * @type {number}
     * @memberof AdminMemberRole
     */
    productPriceManagement?: number;
    /**
     * 
     * @type {number}
     * @memberof AdminMemberRole
     */
    reportManagement?: number;
    /**
     * 
     * @type {number}
     * @memberof AdminMemberRole
     */
    stockLevelManagement?: number;
}

/**
 * Check if a given object implements the AdminMemberRole interface.
 */
export function instanceOfAdminMemberRole(value: object): boolean {
    return true;
}

export function AdminMemberRoleFromJSON(json: any): AdminMemberRole {
    return AdminMemberRoleFromJSONTyped(json, false);
}

export function AdminMemberRoleFromJSONTyped(json: any, ignoreDiscriminator: boolean): AdminMemberRole {
    if (json == null) {
        return json;
    }
    return {
        
        'admin': json['admin'] == null ? undefined : json['admin'],
        'adminMemberRoleId': json['adminMemberRoleId'] == null ? undefined : json['adminMemberRoleId'],
        'adminMemberRoleName': json['adminMemberRoleName'] == null ? undefined : json['adminMemberRoleName'],
        'articleManagement': json['articleManagement'] == null ? undefined : json['articleManagement'],
        'discountManagement': json['discountManagement'] == null ? undefined : json['discountManagement'],
        'inventoryManagement': json['inventoryManagement'] == null ? undefined : json['inventoryManagement'],
        'marketingEventManagement': json['marketingEventManagement'] == null ? undefined : json['marketingEventManagement'],
        'membershipManagement': json['membershipManagement'] == null ? undefined : json['membershipManagement'],
        'membershipManagementRead': json['membershipManagementRead'] == null ? undefined : json['membershipManagementRead'],
        'orderManagement': json['orderManagement'] == null ? undefined : json['orderManagement'],
        'orderManagementRead': json['orderManagementRead'] == null ? undefined : json['orderManagementRead'],
        'pointManagement': json['pointManagement'] == null ? undefined : json['pointManagement'],
        'productManagement': json['productManagement'] == null ? undefined : json['productManagement'],
        'productPriceManagement': json['productPriceManagement'] == null ? undefined : json['productPriceManagement'],
        'reportManagement': json['reportManagement'] == null ? undefined : json['reportManagement'],
        'stockLevelManagement': json['stockLevelManagement'] == null ? undefined : json['stockLevelManagement'],
    };
}

export function AdminMemberRoleToJSON(value?: AdminMemberRole | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'admin': value['admin'],
        'adminMemberRoleId': value['adminMemberRoleId'],
        'adminMemberRoleName': value['adminMemberRoleName'],
        'articleManagement': value['articleManagement'],
        'discountManagement': value['discountManagement'],
        'inventoryManagement': value['inventoryManagement'],
        'marketingEventManagement': value['marketingEventManagement'],
        'membershipManagement': value['membershipManagement'],
        'membershipManagementRead': value['membershipManagementRead'],
        'orderManagement': value['orderManagement'],
        'orderManagementRead': value['orderManagementRead'],
        'pointManagement': value['pointManagement'],
        'productManagement': value['productManagement'],
        'productPriceManagement': value['productPriceManagement'],
        'reportManagement': value['reportManagement'],
        'stockLevelManagement': value['stockLevelManagement'],
    };
}

