/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SfStation
 */
export interface SfStation {
    /**
     * 
     * @type {string}
     * @memberof SfStation
     */
    addressCity?: string;
    /**
     * 
     * @type {string}
     * @memberof SfStation
     */
    addressProvince?: string;
    /**
     * 
     * @type {string}
     * @memberof SfStation
     */
    addressZone?: string;
    /**
     * 
     * @type {string}
     * @memberof SfStation
     */
    area?: string;
    /**
     * 
     * @type {string}
     * @memberof SfStation
     */
    sfStationCode?: string;
    /**
     * 
     * @type {string}
     * @memberof SfStation
     */
    sfStationName?: string;
}

/**
 * Check if a given object implements the SfStation interface.
 */
export function instanceOfSfStation(value: object): boolean {
    return true;
}

export function SfStationFromJSON(json: any): SfStation {
    return SfStationFromJSONTyped(json, false);
}

export function SfStationFromJSONTyped(json: any, ignoreDiscriminator: boolean): SfStation {
    if (json == null) {
        return json;
    }
    return {
        
        'addressCity': json['addressCity'] == null ? undefined : json['addressCity'],
        'addressProvince': json['addressProvince'] == null ? undefined : json['addressProvince'],
        'addressZone': json['addressZone'] == null ? undefined : json['addressZone'],
        'area': json['area'] == null ? undefined : json['area'],
        'sfStationCode': json['sfStationCode'] == null ? undefined : json['sfStationCode'],
        'sfStationName': json['sfStationName'] == null ? undefined : json['sfStationName'],
    };
}

export function SfStationToJSON(value?: SfStation | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'addressCity': value['addressCity'],
        'addressProvince': value['addressProvince'],
        'addressZone': value['addressZone'],
        'area': value['area'],
        'sfStationCode': value['sfStationCode'],
        'sfStationName': value['sfStationName'],
    };
}

