/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { DisplayProduct } from './DisplayProduct';
import {
    DisplayProductFromJSON,
    DisplayProductFromJSONTyped,
    DisplayProductToJSON,
} from './DisplayProduct';
import type { Language } from './Language';
import {
    LanguageFromJSON,
    LanguageFromJSONTyped,
    LanguageToJSON,
} from './Language';

/**
 * 
 * @export
 * @interface DisplayProductLanguage
 */
export interface DisplayProductLanguage {
    /**
     * 
     * @type {DisplayProduct}
     * @memberof DisplayProductLanguage
     */
    displayProductByDisplayProductId?: DisplayProduct;
    /**
     * 
     * @type {number}
     * @memberof DisplayProductLanguage
     */
    displayProductId?: number;
    /**
     * 
     * @type {Language}
     * @memberof DisplayProductLanguage
     */
    languageByLanguageId?: Language;
    /**
     * 
     * @type {number}
     * @memberof DisplayProductLanguage
     */
    languageId?: number;
}

/**
 * Check if a given object implements the DisplayProductLanguage interface.
 */
export function instanceOfDisplayProductLanguage(value: object): boolean {
    return true;
}

export function DisplayProductLanguageFromJSON(json: any): DisplayProductLanguage {
    return DisplayProductLanguageFromJSONTyped(json, false);
}

export function DisplayProductLanguageFromJSONTyped(json: any, ignoreDiscriminator: boolean): DisplayProductLanguage {
    if (json == null) {
        return json;
    }
    return {
        
        'displayProductByDisplayProductId': json['displayProductByDisplayProductId'] == null ? undefined : DisplayProductFromJSON(json['displayProductByDisplayProductId']),
        'displayProductId': json['displayProductId'] == null ? undefined : json['displayProductId'],
        'languageByLanguageId': json['languageByLanguageId'] == null ? undefined : LanguageFromJSON(json['languageByLanguageId']),
        'languageId': json['languageId'] == null ? undefined : json['languageId'],
    };
}

export function DisplayProductLanguageToJSON(value?: DisplayProductLanguage | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'displayProductByDisplayProductId': DisplayProductToJSON(value['displayProductByDisplayProductId']),
        'displayProductId': value['displayProductId'],
        'languageByLanguageId': LanguageToJSON(value['languageByLanguageId']),
        'languageId': value['languageId'],
    };
}

