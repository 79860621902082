/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { OrderDtoLite } from './OrderDtoLite';
import {
    OrderDtoLiteFromJSON,
    OrderDtoLiteFromJSONTyped,
    OrderDtoLiteToJSON,
} from './OrderDtoLite';
import type { ProductDto } from './ProductDto';
import {
    ProductDtoFromJSON,
    ProductDtoFromJSONTyped,
    ProductDtoToJSON,
} from './ProductDto';
import type { ProductPointDto } from './ProductPointDto';
import {
    ProductPointDtoFromJSON,
    ProductPointDtoFromJSONTyped,
    ProductPointDtoToJSON,
} from './ProductPointDto';

/**
 * 
 * @export
 * @interface OrderProductDto2
 */
export interface OrderProductDto2 {
    /**
     * 
     * @type {number}
     * @memberof OrderProductDto2
     */
    deliveryTimes?: number;
    /**
     * 
     * @type {number}
     * @memberof OrderProductDto2
     */
    finalPrice?: number;
    /**
     * 
     * @type {OrderDtoLite}
     * @memberof OrderProductDto2
     */
    order?: OrderDtoLite;
    /**
     * 
     * @type {number}
     * @memberof OrderProductDto2
     */
    orderProductId?: number;
    /**
     * 
     * @type {ProductDto}
     * @memberof OrderProductDto2
     */
    product?: ProductDto;
    /**
     * 
     * @type {number}
     * @memberof OrderProductDto2
     */
    productId?: number;
    /**
     * 
     * @type {ProductPointDto}
     * @memberof OrderProductDto2
     */
    productPoint?: ProductPointDto;
    /**
     * 
     * @type {number}
     * @memberof OrderProductDto2
     */
    productPrice?: number;
    /**
     * 
     * @type {number}
     * @memberof OrderProductDto2
     */
    quantity?: number;
    /**
     * 
     * @type {number}
     * @memberof OrderProductDto2
     */
    usedPoint?: number;
}

/**
 * Check if a given object implements the OrderProductDto2 interface.
 */
export function instanceOfOrderProductDto2(value: object): boolean {
    return true;
}

export function OrderProductDto2FromJSON(json: any): OrderProductDto2 {
    return OrderProductDto2FromJSONTyped(json, false);
}

export function OrderProductDto2FromJSONTyped(json: any, ignoreDiscriminator: boolean): OrderProductDto2 {
    if (json == null) {
        return json;
    }
    return {
        
        'deliveryTimes': json['deliveryTimes'] == null ? undefined : json['deliveryTimes'],
        'finalPrice': json['finalPrice'] == null ? undefined : json['finalPrice'],
        'order': json['order'] == null ? undefined : OrderDtoLiteFromJSON(json['order']),
        'orderProductId': json['orderProductId'] == null ? undefined : json['orderProductId'],
        'product': json['product'] == null ? undefined : ProductDtoFromJSON(json['product']),
        'productId': json['productId'] == null ? undefined : json['productId'],
        'productPoint': json['productPoint'] == null ? undefined : ProductPointDtoFromJSON(json['productPoint']),
        'productPrice': json['productPrice'] == null ? undefined : json['productPrice'],
        'quantity': json['quantity'] == null ? undefined : json['quantity'],
        'usedPoint': json['usedPoint'] == null ? undefined : json['usedPoint'],
    };
}

export function OrderProductDto2ToJSON(value?: OrderProductDto2 | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'deliveryTimes': value['deliveryTimes'],
        'finalPrice': value['finalPrice'],
        'order': OrderDtoLiteToJSON(value['order']),
        'orderProductId': value['orderProductId'],
        'product': ProductDtoToJSON(value['product']),
        'productId': value['productId'],
        'productPoint': ProductPointDtoToJSON(value['productPoint']),
        'productPrice': value['productPrice'],
        'quantity': value['quantity'],
        'usedPoint': value['usedPoint'],
    };
}

