/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ClassLoader } from './ClassLoader';
import {
    ClassLoaderFromJSON,
    ClassLoaderFromJSONTyped,
    ClassLoaderToJSON,
} from './ClassLoader';
import type { Environment } from './Environment';
import {
    EnvironmentFromJSON,
    EnvironmentFromJSONTyped,
    EnvironmentToJSON,
} from './Environment';

/**
 * 
 * @export
 * @interface ApplicationContext
 */
export interface ApplicationContext {
    /**
     * 
     * @type {string}
     * @memberof ApplicationContext
     */
    applicationName?: string;
    /**
     * 
     * @type {object}
     * @memberof ApplicationContext
     */
    autowireCapableBeanFactory?: object;
    /**
     * 
     * @type {number}
     * @memberof ApplicationContext
     */
    beanDefinitionCount?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof ApplicationContext
     */
    beanDefinitionNames?: Array<string>;
    /**
     * 
     * @type {ClassLoader}
     * @memberof ApplicationContext
     */
    classLoader?: ClassLoader;
    /**
     * 
     * @type {string}
     * @memberof ApplicationContext
     */
    displayName?: string;
    /**
     * 
     * @type {Environment}
     * @memberof ApplicationContext
     */
    environment?: Environment;
    /**
     * 
     * @type {string}
     * @memberof ApplicationContext
     */
    id?: string;
    /**
     * 
     * @type {ApplicationContext}
     * @memberof ApplicationContext
     */
    parent?: ApplicationContext;
    /**
     * 
     * @type {object}
     * @memberof ApplicationContext
     */
    parentBeanFactory?: object;
    /**
     * 
     * @type {number}
     * @memberof ApplicationContext
     */
    startupDate?: number;
}

/**
 * Check if a given object implements the ApplicationContext interface.
 */
export function instanceOfApplicationContext(value: object): boolean {
    return true;
}

export function ApplicationContextFromJSON(json: any): ApplicationContext {
    return ApplicationContextFromJSONTyped(json, false);
}

export function ApplicationContextFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApplicationContext {
    if (json == null) {
        return json;
    }
    return {
        
        'applicationName': json['applicationName'] == null ? undefined : json['applicationName'],
        'autowireCapableBeanFactory': json['autowireCapableBeanFactory'] == null ? undefined : json['autowireCapableBeanFactory'],
        'beanDefinitionCount': json['beanDefinitionCount'] == null ? undefined : json['beanDefinitionCount'],
        'beanDefinitionNames': json['beanDefinitionNames'] == null ? undefined : json['beanDefinitionNames'],
        'classLoader': json['classLoader'] == null ? undefined : ClassLoaderFromJSON(json['classLoader']),
        'displayName': json['displayName'] == null ? undefined : json['displayName'],
        'environment': json['environment'] == null ? undefined : EnvironmentFromJSON(json['environment']),
        'id': json['id'] == null ? undefined : json['id'],
        'parent': json['parent'] == null ? undefined : ApplicationContextFromJSON(json['parent']),
        'parentBeanFactory': json['parentBeanFactory'] == null ? undefined : json['parentBeanFactory'],
        'startupDate': json['startupDate'] == null ? undefined : json['startupDate'],
    };
}

export function ApplicationContextToJSON(value?: ApplicationContext | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'applicationName': value['applicationName'],
        'autowireCapableBeanFactory': value['autowireCapableBeanFactory'],
        'beanDefinitionCount': value['beanDefinitionCount'],
        'beanDefinitionNames': value['beanDefinitionNames'],
        'classLoader': ClassLoaderToJSON(value['classLoader']),
        'displayName': value['displayName'],
        'environment': EnvironmentToJSON(value['environment']),
        'id': value['id'],
        'parent': ApplicationContextToJSON(value['parent']),
        'parentBeanFactory': value['parentBeanFactory'],
        'startupDate': value['startupDate'],
    };
}

