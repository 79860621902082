/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ApplicationContext } from './ApplicationContext';
import {
    ApplicationContextFromJSON,
    ApplicationContextFromJSONTyped,
    ApplicationContextToJSON,
} from './ApplicationContext';

/**
 * 
 * @export
 * @interface RedirectView
 */
export interface RedirectView {
    /**
     * 
     * @type {ApplicationContext}
     * @memberof RedirectView
     */
    applicationContext?: ApplicationContext;
    /**
     * 
     * @type {object}
     * @memberof RedirectView
     */
    attributesMap?: object;
    /**
     * 
     * @type {string}
     * @memberof RedirectView
     */
    beanName?: string;
    /**
     * 
     * @type {string}
     * @memberof RedirectView
     */
    contentType?: string;
    /**
     * 
     * @type {boolean}
     * @memberof RedirectView
     */
    exposePathVariables?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof RedirectView
     */
    hosts?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof RedirectView
     */
    propagateQueryProperties?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RedirectView
     */
    redirectView?: boolean;
    /**
     * 
     * @type {string}
     * @memberof RedirectView
     */
    requestContextAttribute?: string;
    /**
     * 
     * @type {object}
     * @memberof RedirectView
     */
    staticAttributes?: object;
    /**
     * 
     * @type {string}
     * @memberof RedirectView
     */
    url?: string;
}

/**
 * Check if a given object implements the RedirectView interface.
 */
export function instanceOfRedirectView(value: object): boolean {
    return true;
}

export function RedirectViewFromJSON(json: any): RedirectView {
    return RedirectViewFromJSONTyped(json, false);
}

export function RedirectViewFromJSONTyped(json: any, ignoreDiscriminator: boolean): RedirectView {
    if (json == null) {
        return json;
    }
    return {
        
        'applicationContext': json['applicationContext'] == null ? undefined : ApplicationContextFromJSON(json['applicationContext']),
        'attributesMap': json['attributesMap'] == null ? undefined : json['attributesMap'],
        'beanName': json['beanName'] == null ? undefined : json['beanName'],
        'contentType': json['contentType'] == null ? undefined : json['contentType'],
        'exposePathVariables': json['exposePathVariables'] == null ? undefined : json['exposePathVariables'],
        'hosts': json['hosts'] == null ? undefined : json['hosts'],
        'propagateQueryProperties': json['propagateQueryProperties'] == null ? undefined : json['propagateQueryProperties'],
        'redirectView': json['redirectView'] == null ? undefined : json['redirectView'],
        'requestContextAttribute': json['requestContextAttribute'] == null ? undefined : json['requestContextAttribute'],
        'staticAttributes': json['staticAttributes'] == null ? undefined : json['staticAttributes'],
        'url': json['url'] == null ? undefined : json['url'],
    };
}

export function RedirectViewToJSON(value?: RedirectView | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'applicationContext': ApplicationContextToJSON(value['applicationContext']),
        'attributesMap': value['attributesMap'],
        'beanName': value['beanName'],
        'contentType': value['contentType'],
        'exposePathVariables': value['exposePathVariables'],
        'hosts': value['hosts'],
        'propagateQueryProperties': value['propagateQueryProperties'],
        'redirectView': value['redirectView'],
        'requestContextAttribute': value['requestContextAttribute'],
        'staticAttributes': value['staticAttributes'],
        'url': value['url'],
    };
}

