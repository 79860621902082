/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { DiscountDto } from './DiscountDto';
import {
    DiscountDtoFromJSON,
    DiscountDtoFromJSONTyped,
    DiscountDtoToJSON,
} from './DiscountDto';
import type { ProductDto } from './ProductDto';
import {
    ProductDtoFromJSON,
    ProductDtoFromJSONTyped,
    ProductDtoToJSON,
} from './ProductDto';
import type { ProductPointDto } from './ProductPointDto';
import {
    ProductPointDtoFromJSON,
    ProductPointDtoFromJSONTyped,
    ProductPointDtoToJSON,
} from './ProductPointDto';

/**
 * 
 * @export
 * @interface OrderProductDto
 */
export interface OrderProductDto {
    /**
     * 
     * @type {number}
     * @memberof OrderProductDto
     */
    deliveryTimes?: number;
    /**
     * 
     * @type {Array<DiscountDto>}
     * @memberof OrderProductDto
     */
    discountList?: Array<DiscountDto>;
    /**
     * 
     * @type {number}
     * @memberof OrderProductDto
     */
    finalPrice?: number;
    /**
     * 
     * @type {number}
     * @memberof OrderProductDto
     */
    orderProductId?: number;
    /**
     * 
     * @type {ProductDto}
     * @memberof OrderProductDto
     */
    product?: ProductDto;
    /**
     * 
     * @type {number}
     * @memberof OrderProductDto
     */
    productId?: number;
    /**
     * 
     * @type {ProductPointDto}
     * @memberof OrderProductDto
     */
    productPoint?: ProductPointDto;
    /**
     * 
     * @type {number}
     * @memberof OrderProductDto
     */
    productPrice?: number;
    /**
     * 
     * @type {number}
     * @memberof OrderProductDto
     */
    quantity?: number;
    /**
     * 
     * @type {number}
     * @memberof OrderProductDto
     */
    usedPoint?: number;
}

/**
 * Check if a given object implements the OrderProductDto interface.
 */
export function instanceOfOrderProductDto(value: object): boolean {
    return true;
}

export function OrderProductDtoFromJSON(json: any): OrderProductDto {
    return OrderProductDtoFromJSONTyped(json, false);
}

export function OrderProductDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrderProductDto {
    if (json == null) {
        return json;
    }
    return {
        
        'deliveryTimes': json['deliveryTimes'] == null ? undefined : json['deliveryTimes'],
        'discountList': json['discountList'] == null ? undefined : ((json['discountList'] as Array<any>).map(DiscountDtoFromJSON)),
        'finalPrice': json['finalPrice'] == null ? undefined : json['finalPrice'],
        'orderProductId': json['orderProductId'] == null ? undefined : json['orderProductId'],
        'product': json['product'] == null ? undefined : ProductDtoFromJSON(json['product']),
        'productId': json['productId'] == null ? undefined : json['productId'],
        'productPoint': json['productPoint'] == null ? undefined : ProductPointDtoFromJSON(json['productPoint']),
        'productPrice': json['productPrice'] == null ? undefined : json['productPrice'],
        'quantity': json['quantity'] == null ? undefined : json['quantity'],
        'usedPoint': json['usedPoint'] == null ? undefined : json['usedPoint'],
    };
}

export function OrderProductDtoToJSON(value?: OrderProductDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'deliveryTimes': value['deliveryTimes'],
        'discountList': value['discountList'] == null ? undefined : ((value['discountList'] as Array<any>).map(DiscountDtoToJSON)),
        'finalPrice': value['finalPrice'],
        'orderProductId': value['orderProductId'],
        'product': ProductDtoToJSON(value['product']),
        'productId': value['productId'],
        'productPoint': ProductPointDtoToJSON(value['productPoint']),
        'productPrice': value['productPrice'],
        'quantity': value['quantity'],
        'usedPoint': value['usedPoint'],
    };
}

