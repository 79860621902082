/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { MarketingEvent } from './MarketingEvent';
import {
    MarketingEventFromJSON,
    MarketingEventFromJSONTyped,
    MarketingEventToJSON,
} from './MarketingEvent';
import type { Member } from './Member';
import {
    MemberFromJSON,
    MemberFromJSONTyped,
    MemberToJSON,
} from './Member';

/**
 * 
 * @export
 * @interface MarketingEventMember
 */
export interface MarketingEventMember {
    /**
     * 
     * @type {MarketingEvent}
     * @memberof MarketingEventMember
     */
    marketingEventByMarketingEventId?: MarketingEvent;
    /**
     * 
     * @type {number}
     * @memberof MarketingEventMember
     */
    marketingEventId?: number;
    /**
     * 
     * @type {Member}
     * @memberof MarketingEventMember
     */
    memberByMemberId?: Member;
    /**
     * 
     * @type {number}
     * @memberof MarketingEventMember
     */
    memberId?: number;
}

/**
 * Check if a given object implements the MarketingEventMember interface.
 */
export function instanceOfMarketingEventMember(value: object): boolean {
    return true;
}

export function MarketingEventMemberFromJSON(json: any): MarketingEventMember {
    return MarketingEventMemberFromJSONTyped(json, false);
}

export function MarketingEventMemberFromJSONTyped(json: any, ignoreDiscriminator: boolean): MarketingEventMember {
    if (json == null) {
        return json;
    }
    return {
        
        'marketingEventByMarketingEventId': json['marketingEventByMarketingEventId'] == null ? undefined : MarketingEventFromJSON(json['marketingEventByMarketingEventId']),
        'marketingEventId': json['marketingEventId'] == null ? undefined : json['marketingEventId'],
        'memberByMemberId': json['memberByMemberId'] == null ? undefined : MemberFromJSON(json['memberByMemberId']),
        'memberId': json['memberId'] == null ? undefined : json['memberId'],
    };
}

export function MarketingEventMemberToJSON(value?: MarketingEventMember | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'marketingEventByMarketingEventId': MarketingEventToJSON(value['marketingEventByMarketingEventId']),
        'marketingEventId': value['marketingEventId'],
        'memberByMemberId': MemberToJSON(value['memberByMemberId']),
        'memberId': value['memberId'],
    };
}

