/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ArticleDto,
} from '../models/index';
import {
    ArticleDtoFromJSON,
    ArticleDtoToJSON,
} from '../models/index';

export interface AddArticleUsingPOSTRequest {
    articleTitle: string;
    articleContent: string;
    name?: string;
    categoryId?: number;
    articleCoverImage?: string;
    relatedDisplayProductIds?: number;
    hashtag?: string;
    marketingEventId?: number;
    status?: string;
    body?: Date;
}

export interface DeleteArticleUsingPOSTRequest {
    articleId: number;
    name?: string;
}

export interface GetArticleByArticleIdAdminUsingGETRequest {
    articleId: number;
    name?: string;
}

export interface GetArticleByArticleIdUsingGETRequest {
    articleId: number;
}

export interface GetArticleByCategoryIdUsingGETRequest {
    categoryId: number;
}

export interface GetArticleByHashtagNameUsingGETRequest {
    hashtagName: string;
}

export interface GetPagedArticleAdminUsingGETRequest {
    page: string;
    size: string;
    name?: string;
}

export interface GetPagedArticleByCategoryIdAdminUsingGETRequest {
    page: string;
    size: string;
    categoryId: number;
    name?: string;
}

export interface GetPagedArticleByHashtagNameAdminUsingGETRequest {
    page: string;
    size: string;
    hashtagName: string;
    name?: string;
}

export interface UpdateArticleUsingPOSTRequest {
    name?: string;
    articleId?: number;
    articleTitle?: string;
    categoryId?: number;
    articleContent?: string;
    articleCoverImage?: string;
    relatedDisplayProductIds?: number;
    hashtag?: string;
    marketingEventId?: number;
    status?: string;
    body?: Date;
}

/**
 * 
 */
export class ArticleControllerApi extends runtime.BaseAPI {

    /**
     * addArticle
     */
    async addArticleUsingPOSTRaw(requestParameters: AddArticleUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ArticleDto>> {
        if (requestParameters['articleTitle'] == null) {
            throw new runtime.RequiredError(
                'articleTitle',
                'Required parameter "articleTitle" was null or undefined when calling addArticleUsingPOST().'
            );
        }

        if (requestParameters['articleContent'] == null) {
            throw new runtime.RequiredError(
                'articleContent',
                'Required parameter "articleContent" was null or undefined when calling addArticleUsingPOST().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        if (requestParameters['articleTitle'] != null) {
            queryParameters['articleTitle'] = requestParameters['articleTitle'];
        }

        if (requestParameters['categoryId'] != null) {
            queryParameters['categoryId'] = requestParameters['categoryId'];
        }

        if (requestParameters['articleContent'] != null) {
            queryParameters['articleContent'] = requestParameters['articleContent'];
        }

        if (requestParameters['articleCoverImage'] != null) {
            queryParameters['articleCoverImage'] = requestParameters['articleCoverImage'];
        }

        if (requestParameters['relatedDisplayProductIds'] != null) {
            queryParameters['relatedDisplayProductIds'] = requestParameters['relatedDisplayProductIds'];
        }

        if (requestParameters['hashtag'] != null) {
            queryParameters['hashtag'] = requestParameters['hashtag'];
        }

        if (requestParameters['marketingEventId'] != null) {
            queryParameters['marketingEventId'] = requestParameters['marketingEventId'];
        }

        if (requestParameters['status'] != null) {
            queryParameters['status'] = requestParameters['status'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/article/add`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters['body'] as any,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ArticleDtoFromJSON(jsonValue));
    }

    /**
     * addArticle
     */
    async addArticleUsingPOST(requestParameters: AddArticleUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ArticleDto | null | undefined > {
        const response = await this.addArticleUsingPOSTRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 201:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * deleteArticle
     */
    async deleteArticleUsingPOSTRaw(requestParameters: DeleteArticleUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ArticleDto>> {
        if (requestParameters['articleId'] == null) {
            throw new runtime.RequiredError(
                'articleId',
                'Required parameter "articleId" was null or undefined when calling deleteArticleUsingPOST().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/article/delete/{articleId}`.replace(`{${"articleId"}}`, encodeURIComponent(String(requestParameters['articleId']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ArticleDtoFromJSON(jsonValue));
    }

    /**
     * deleteArticle
     */
    async deleteArticleUsingPOST(requestParameters: DeleteArticleUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ArticleDto | null | undefined > {
        const response = await this.deleteArticleUsingPOSTRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 201:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * getAllArticle
     */
    async getAllArticleUsingGETRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ArticleDto>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/article/all`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ArticleDtoFromJSON));
    }

    /**
     * getAllArticle
     */
    async getAllArticleUsingGET(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ArticleDto>> {
        const response = await this.getAllArticleUsingGETRaw(initOverrides);
        return await response.value();
    }

    /**
     * getArticleByArticleIdAdmin
     */
    async getArticleByArticleIdAdminUsingGETRaw(requestParameters: GetArticleByArticleIdAdminUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ArticleDto>> {
        if (requestParameters['articleId'] == null) {
            throw new runtime.RequiredError(
                'articleId',
                'Required parameter "articleId" was null or undefined when calling getArticleByArticleIdAdminUsingGET().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/article/admin/{articleId}`.replace(`{${"articleId"}}`, encodeURIComponent(String(requestParameters['articleId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ArticleDtoFromJSON(jsonValue));
    }

    /**
     * getArticleByArticleIdAdmin
     */
    async getArticleByArticleIdAdminUsingGET(requestParameters: GetArticleByArticleIdAdminUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ArticleDto> {
        const response = await this.getArticleByArticleIdAdminUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getArticleByArticleId
     */
    async getArticleByArticleIdUsingGETRaw(requestParameters: GetArticleByArticleIdUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ArticleDto>> {
        if (requestParameters['articleId'] == null) {
            throw new runtime.RequiredError(
                'articleId',
                'Required parameter "articleId" was null or undefined when calling getArticleByArticleIdUsingGET().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/article/{articleId}`.replace(`{${"articleId"}}`, encodeURIComponent(String(requestParameters['articleId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ArticleDtoFromJSON(jsonValue));
    }

    /**
     * getArticleByArticleId
     */
    async getArticleByArticleIdUsingGET(requestParameters: GetArticleByArticleIdUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ArticleDto> {
        const response = await this.getArticleByArticleIdUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getArticleByCategoryId
     */
    async getArticleByCategoryIdUsingGETRaw(requestParameters: GetArticleByCategoryIdUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ArticleDto>>> {
        if (requestParameters['categoryId'] == null) {
            throw new runtime.RequiredError(
                'categoryId',
                'Required parameter "categoryId" was null or undefined when calling getArticleByCategoryIdUsingGET().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/article/category/{categoryId}`.replace(`{${"categoryId"}}`, encodeURIComponent(String(requestParameters['categoryId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ArticleDtoFromJSON));
    }

    /**
     * getArticleByCategoryId
     */
    async getArticleByCategoryIdUsingGET(requestParameters: GetArticleByCategoryIdUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ArticleDto>> {
        const response = await this.getArticleByCategoryIdUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getArticleByHashtagName
     */
    async getArticleByHashtagNameUsingGETRaw(requestParameters: GetArticleByHashtagNameUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ArticleDto>>> {
        if (requestParameters['hashtagName'] == null) {
            throw new runtime.RequiredError(
                'hashtagName',
                'Required parameter "hashtagName" was null or undefined when calling getArticleByHashtagNameUsingGET().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/article/hashtag/{hashtagName}`.replace(`{${"hashtagName"}}`, encodeURIComponent(String(requestParameters['hashtagName']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ArticleDtoFromJSON));
    }

    /**
     * getArticleByHashtagName
     */
    async getArticleByHashtagNameUsingGET(requestParameters: GetArticleByHashtagNameUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ArticleDto>> {
        const response = await this.getArticleByHashtagNameUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getPagedArticleAdmin
     */
    async getPagedArticleAdminUsingGETRaw(requestParameters: GetPagedArticleAdminUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        if (requestParameters['page'] == null) {
            throw new runtime.RequiredError(
                'page',
                'Required parameter "page" was null or undefined when calling getPagedArticleAdminUsingGET().'
            );
        }

        if (requestParameters['size'] == null) {
            throw new runtime.RequiredError(
                'size',
                'Required parameter "size" was null or undefined when calling getPagedArticleAdminUsingGET().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        if (requestParameters['size'] != null) {
            queryParameters['size'] = requestParameters['size'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/article/admin/all`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * getPagedArticleAdmin
     */
    async getPagedArticleAdminUsingGET(requestParameters: GetPagedArticleAdminUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object> {
        const response = await this.getPagedArticleAdminUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getPagedArticleByCategoryIdAdmin
     */
    async getPagedArticleByCategoryIdAdminUsingGETRaw(requestParameters: GetPagedArticleByCategoryIdAdminUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        if (requestParameters['page'] == null) {
            throw new runtime.RequiredError(
                'page',
                'Required parameter "page" was null or undefined when calling getPagedArticleByCategoryIdAdminUsingGET().'
            );
        }

        if (requestParameters['size'] == null) {
            throw new runtime.RequiredError(
                'size',
                'Required parameter "size" was null or undefined when calling getPagedArticleByCategoryIdAdminUsingGET().'
            );
        }

        if (requestParameters['categoryId'] == null) {
            throw new runtime.RequiredError(
                'categoryId',
                'Required parameter "categoryId" was null or undefined when calling getPagedArticleByCategoryIdAdminUsingGET().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        if (requestParameters['size'] != null) {
            queryParameters['size'] = requestParameters['size'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/article/admin/category/{categoryId}`.replace(`{${"categoryId"}}`, encodeURIComponent(String(requestParameters['categoryId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * getPagedArticleByCategoryIdAdmin
     */
    async getPagedArticleByCategoryIdAdminUsingGET(requestParameters: GetPagedArticleByCategoryIdAdminUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object> {
        const response = await this.getPagedArticleByCategoryIdAdminUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getPagedArticleByHashtagNameAdmin
     */
    async getPagedArticleByHashtagNameAdminUsingGETRaw(requestParameters: GetPagedArticleByHashtagNameAdminUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        if (requestParameters['page'] == null) {
            throw new runtime.RequiredError(
                'page',
                'Required parameter "page" was null or undefined when calling getPagedArticleByHashtagNameAdminUsingGET().'
            );
        }

        if (requestParameters['size'] == null) {
            throw new runtime.RequiredError(
                'size',
                'Required parameter "size" was null or undefined when calling getPagedArticleByHashtagNameAdminUsingGET().'
            );
        }

        if (requestParameters['hashtagName'] == null) {
            throw new runtime.RequiredError(
                'hashtagName',
                'Required parameter "hashtagName" was null or undefined when calling getPagedArticleByHashtagNameAdminUsingGET().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        if (requestParameters['size'] != null) {
            queryParameters['size'] = requestParameters['size'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/article/admin/hashtag/{hashtagName}`.replace(`{${"hashtagName"}}`, encodeURIComponent(String(requestParameters['hashtagName']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * getPagedArticleByHashtagNameAdmin
     */
    async getPagedArticleByHashtagNameAdminUsingGET(requestParameters: GetPagedArticleByHashtagNameAdminUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object> {
        const response = await this.getPagedArticleByHashtagNameAdminUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * updateArticle
     */
    async updateArticleUsingPOSTRaw(requestParameters: UpdateArticleUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ArticleDto>> {
        const queryParameters: any = {};

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        if (requestParameters['articleId'] != null) {
            queryParameters['articleId'] = requestParameters['articleId'];
        }

        if (requestParameters['articleTitle'] != null) {
            queryParameters['articleTitle'] = requestParameters['articleTitle'];
        }

        if (requestParameters['categoryId'] != null) {
            queryParameters['categoryId'] = requestParameters['categoryId'];
        }

        if (requestParameters['articleContent'] != null) {
            queryParameters['articleContent'] = requestParameters['articleContent'];
        }

        if (requestParameters['articleCoverImage'] != null) {
            queryParameters['articleCoverImage'] = requestParameters['articleCoverImage'];
        }

        if (requestParameters['relatedDisplayProductIds'] != null) {
            queryParameters['relatedDisplayProductIds'] = requestParameters['relatedDisplayProductIds'];
        }

        if (requestParameters['hashtag'] != null) {
            queryParameters['hashtag'] = requestParameters['hashtag'];
        }

        if (requestParameters['marketingEventId'] != null) {
            queryParameters['marketingEventId'] = requestParameters['marketingEventId'];
        }

        if (requestParameters['status'] != null) {
            queryParameters['status'] = requestParameters['status'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/article/update`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters['body'] as any,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ArticleDtoFromJSON(jsonValue));
    }

    /**
     * updateArticle
     */
    async updateArticleUsingPOST(requestParameters: UpdateArticleUsingPOSTRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ArticleDto | null | undefined > {
        const response = await this.updateArticleUsingPOSTRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 201:
                return null;
            default:
                return await response.value();
        }
    }

}
