/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CalendarEvent,
} from '../models/index';
import {
    CalendarEventFromJSON,
    CalendarEventToJSON,
} from '../models/index';

export interface AddCalendarEventUsingPOSTRequest {
    calendarEventName: string;
    description: string;
    name?: string;
    body?: Date;
}

export interface DeleteCalendarEventUsingPOSTRequest {
    calendarEventId: number;
    name?: string;
}

export interface GetCalendarEventByCalendarEventIdUsingGETRequest {
    calendarEventId: number;
    name?: string;
}

export interface UpdateCalendarEventUsingPOSTRequest {
    calendarEventId: number;
    calendarEventName: string;
    description: string;
    name?: string;
    body?: Date;
}

/**
 * 
 */
export class CalendarEventControllerApi extends runtime.BaseAPI {

    /**
     * addCalendarEvent
     */
    async addCalendarEventUsingPOSTRaw(requestParameters: AddCalendarEventUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<CalendarEvent>>> {
        if (requestParameters['calendarEventName'] == null) {
            throw new runtime.RequiredError(
                'calendarEventName',
                'Required parameter "calendarEventName" was null or undefined when calling addCalendarEventUsingPOST().'
            );
        }

        if (requestParameters['description'] == null) {
            throw new runtime.RequiredError(
                'description',
                'Required parameter "description" was null or undefined when calling addCalendarEventUsingPOST().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        if (requestParameters['calendarEventName'] != null) {
            queryParameters['calendarEventName'] = requestParameters['calendarEventName'];
        }

        if (requestParameters['description'] != null) {
            queryParameters['description'] = requestParameters['description'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/calendarEvent/add`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters['body'] as any,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CalendarEventFromJSON));
    }

    /**
     * addCalendarEvent
     */
    async addCalendarEventUsingPOST(requestParameters: AddCalendarEventUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<CalendarEvent> | null | undefined > {
        const response = await this.addCalendarEventUsingPOSTRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 201:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * deleteCalendarEvent
     */
    async deleteCalendarEventUsingPOSTRaw(requestParameters: DeleteCalendarEventUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<CalendarEvent>>> {
        if (requestParameters['calendarEventId'] == null) {
            throw new runtime.RequiredError(
                'calendarEventId',
                'Required parameter "calendarEventId" was null or undefined when calling deleteCalendarEventUsingPOST().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        if (requestParameters['calendarEventId'] != null) {
            queryParameters['calendarEventId'] = requestParameters['calendarEventId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/calendarEvent/delete`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CalendarEventFromJSON));
    }

    /**
     * deleteCalendarEvent
     */
    async deleteCalendarEventUsingPOST(requestParameters: DeleteCalendarEventUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<CalendarEvent> | null | undefined > {
        const response = await this.deleteCalendarEventUsingPOSTRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 201:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * getAllCalendarEvent
     */
    async getAllCalendarEventUsingGETRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<CalendarEvent>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/calendarEvent/all`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CalendarEventFromJSON));
    }

    /**
     * getAllCalendarEvent
     */
    async getAllCalendarEventUsingGET(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<CalendarEvent>> {
        const response = await this.getAllCalendarEventUsingGETRaw(initOverrides);
        return await response.value();
    }

    /**
     * getCalendarEventByCalendarEventId
     */
    async getCalendarEventByCalendarEventIdUsingGETRaw(requestParameters: GetCalendarEventByCalendarEventIdUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CalendarEvent>> {
        if (requestParameters['calendarEventId'] == null) {
            throw new runtime.RequiredError(
                'calendarEventId',
                'Required parameter "calendarEventId" was null or undefined when calling getCalendarEventByCalendarEventIdUsingGET().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/calendarEvent/{calendarEventId}`.replace(`{${"calendarEventId"}}`, encodeURIComponent(String(requestParameters['calendarEventId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CalendarEventFromJSON(jsonValue));
    }

    /**
     * getCalendarEventByCalendarEventId
     */
    async getCalendarEventByCalendarEventIdUsingGET(requestParameters: GetCalendarEventByCalendarEventIdUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CalendarEvent> {
        const response = await this.getCalendarEventByCalendarEventIdUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getCurrentCalendarEvent
     */
    async getCurrentCalendarEventUsingGETRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<CalendarEvent>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/calendarEvent/current`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CalendarEventFromJSON));
    }

    /**
     * getCurrentCalendarEvent
     */
    async getCurrentCalendarEventUsingGET(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<CalendarEvent>> {
        const response = await this.getCurrentCalendarEventUsingGETRaw(initOverrides);
        return await response.value();
    }

    /**
     * updateCalendarEvent
     */
    async updateCalendarEventUsingPOSTRaw(requestParameters: UpdateCalendarEventUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CalendarEvent>> {
        if (requestParameters['calendarEventId'] == null) {
            throw new runtime.RequiredError(
                'calendarEventId',
                'Required parameter "calendarEventId" was null or undefined when calling updateCalendarEventUsingPOST().'
            );
        }

        if (requestParameters['calendarEventName'] == null) {
            throw new runtime.RequiredError(
                'calendarEventName',
                'Required parameter "calendarEventName" was null or undefined when calling updateCalendarEventUsingPOST().'
            );
        }

        if (requestParameters['description'] == null) {
            throw new runtime.RequiredError(
                'description',
                'Required parameter "description" was null or undefined when calling updateCalendarEventUsingPOST().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        if (requestParameters['calendarEventName'] != null) {
            queryParameters['calendarEventName'] = requestParameters['calendarEventName'];
        }

        if (requestParameters['description'] != null) {
            queryParameters['description'] = requestParameters['description'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/calendarEvent/update/{calendarEventId}`.replace(`{${"calendarEventId"}}`, encodeURIComponent(String(requestParameters['calendarEventId']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters['body'] as any,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CalendarEventFromJSON(jsonValue));
    }

    /**
     * updateCalendarEvent
     */
    async updateCalendarEventUsingPOST(requestParameters: UpdateCalendarEventUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CalendarEvent | null | undefined > {
        const response = await this.updateCalendarEventUsingPOSTRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 201:
                return null;
            default:
                return await response.value();
        }
    }

}
