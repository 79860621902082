/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DiscountDtoLite
 */
export interface DiscountDtoLite {
    /**
     * 
     * @type {string}
     * @memberof DiscountDtoLite
     */
    actionType?: string;
    /**
     * 
     * @type {number}
     * @memberof DiscountDtoLite
     */
    actionValue?: number;
    /**
     * 
     * @type {string}
     * @memberof DiscountDtoLite
     */
    condition?: string;
    /**
     * 
     * @type {number}
     * @memberof DiscountDtoLite
     */
    discountId?: number;
    /**
     * 
     * @type {string}
     * @memberof DiscountDtoLite
     */
    discountTitle?: string;
    /**
     * 
     * @type {string}
     * @memberof DiscountDtoLite
     */
    discountType?: string;
    /**
     * 
     * @type {number}
     * @memberof DiscountDtoLite
     */
    limitToUse?: number;
    /**
     * 
     * @type {number}
     * @memberof DiscountDtoLite
     */
    limitToUseRest?: number;
    /**
     * 
     * @type {string}
     * @memberof DiscountDtoLite
     */
    status?: string;
    /**
     * 
     * @type {string}
     * @memberof DiscountDtoLite
     */
    target?: string;
}

/**
 * Check if a given object implements the DiscountDtoLite interface.
 */
export function instanceOfDiscountDtoLite(value: object): boolean {
    return true;
}

export function DiscountDtoLiteFromJSON(json: any): DiscountDtoLite {
    return DiscountDtoLiteFromJSONTyped(json, false);
}

export function DiscountDtoLiteFromJSONTyped(json: any, ignoreDiscriminator: boolean): DiscountDtoLite {
    if (json == null) {
        return json;
    }
    return {
        
        'actionType': json['actionType'] == null ? undefined : json['actionType'],
        'actionValue': json['actionValue'] == null ? undefined : json['actionValue'],
        'condition': json['condition'] == null ? undefined : json['condition'],
        'discountId': json['discountId'] == null ? undefined : json['discountId'],
        'discountTitle': json['discountTitle'] == null ? undefined : json['discountTitle'],
        'discountType': json['discountType'] == null ? undefined : json['discountType'],
        'limitToUse': json['limitToUse'] == null ? undefined : json['limitToUse'],
        'limitToUseRest': json['limitToUseRest'] == null ? undefined : json['limitToUseRest'],
        'status': json['status'] == null ? undefined : json['status'],
        'target': json['target'] == null ? undefined : json['target'],
    };
}

export function DiscountDtoLiteToJSON(value?: DiscountDtoLite | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'actionType': value['actionType'],
        'actionValue': value['actionValue'],
        'condition': value['condition'],
        'discountId': value['discountId'],
        'discountTitle': value['discountTitle'],
        'discountType': value['discountType'],
        'limitToUse': value['limitToUse'],
        'limitToUseRest': value['limitToUseRest'],
        'status': value['status'],
        'target': value['target'],
    };
}

