/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface LanguageDto
 */
export interface LanguageDto {
    /**
     * 
     * @type {number}
     * @memberof LanguageDto
     */
    languageId?: number;
    /**
     * 
     * @type {string}
     * @memberof LanguageDto
     */
    name?: string;
    /**
     * 
     * @type {number}
     * @memberof LanguageDto
     */
    seq?: number;
}

/**
 * Check if a given object implements the LanguageDto interface.
 */
export function instanceOfLanguageDto(value: object): boolean {
    return true;
}

export function LanguageDtoFromJSON(json: any): LanguageDto {
    return LanguageDtoFromJSONTyped(json, false);
}

export function LanguageDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): LanguageDto {
    if (json == null) {
        return json;
    }
    return {
        
        'languageId': json['languageId'] == null ? undefined : json['languageId'],
        'name': json['name'] == null ? undefined : json['name'],
        'seq': json['seq'] == null ? undefined : json['seq'],
    };
}

export function LanguageDtoToJSON(value?: LanguageDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'languageId': value['languageId'],
        'name': value['name'],
        'seq': value['seq'],
    };
}

