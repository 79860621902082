/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Article } from './Article';
import {
    ArticleFromJSON,
    ArticleFromJSONTyped,
    ArticleToJSON,
} from './Article';
import type { DisplayProductCategory } from './DisplayProductCategory';
import {
    DisplayProductCategoryFromJSON,
    DisplayProductCategoryFromJSONTyped,
    DisplayProductCategoryToJSON,
} from './DisplayProductCategory';
import type { ProductCategory } from './ProductCategory';
import {
    ProductCategoryFromJSON,
    ProductCategoryFromJSONTyped,
    ProductCategoryToJSON,
} from './ProductCategory';

/**
 * 
 * @export
 * @interface Category
 */
export interface Category {
    /**
     * 
     * @type {Array<Article>}
     * @memberof Category
     */
    articlesByCategoryId?: Array<Article>;
    /**
     * 
     * @type {string}
     * @memberof Category
     */
    bannerImage?: string;
    /**
     * 
     * @type {number}
     * @memberof Category
     */
    categoryId?: number;
    /**
     * 
     * @type {string}
     * @memberof Category
     */
    categoryName?: string;
    /**
     * 
     * @type {string}
     * @memberof Category
     */
    categoryUrlNaming?: string;
    /**
     * 
     * @type {Array<DisplayProductCategory>}
     * @memberof Category
     */
    displayProductCategoriesByCategoryId?: Array<DisplayProductCategory>;
    /**
     * 
     * @type {boolean}
     * @memberof Category
     */
    isCollection?: boolean;
    /**
     * 
     * @type {number}
     * @memberof Category
     */
    isHeader?: number;
    /**
     * 
     * @type {boolean}
     * @memberof Category
     */
    isRecommend?: boolean;
    /**
     * 
     * @type {number}
     * @memberof Category
     */
    isStep?: number;
    /**
     * 
     * @type {string}
     * @memberof Category
     */
    mobileBannerImage?: string;
    /**
     * 
     * @type {string}
     * @memberof Category
     */
    mongoId?: string;
    /**
     * 
     * @type {number}
     * @memberof Category
     */
    parentCategoryId?: number;
    /**
     * 
     * @type {Array<ProductCategory>}
     * @memberof Category
     */
    productCategoriesByCategoryId?: Array<ProductCategory>;
    /**
     * 
     * @type {number}
     * @memberof Category
     */
    seq?: number;
    /**
     * 
     * @type {Array<Category>}
     * @memberof Category
     */
    subCategoriesByParentCategoryId?: Array<Category>;
}

/**
 * Check if a given object implements the Category interface.
 */
export function instanceOfCategory(value: object): boolean {
    return true;
}

export function CategoryFromJSON(json: any): Category {
    return CategoryFromJSONTyped(json, false);
}

export function CategoryFromJSONTyped(json: any, ignoreDiscriminator: boolean): Category {
    if (json == null) {
        return json;
    }
    return {
        
        'articlesByCategoryId': json['articlesByCategoryId'] == null ? undefined : ((json['articlesByCategoryId'] as Array<any>).map(ArticleFromJSON)),
        'bannerImage': json['bannerImage'] == null ? undefined : json['bannerImage'],
        'categoryId': json['categoryId'] == null ? undefined : json['categoryId'],
        'categoryName': json['categoryName'] == null ? undefined : json['categoryName'],
        'categoryUrlNaming': json['categoryUrlNaming'] == null ? undefined : json['categoryUrlNaming'],
        'displayProductCategoriesByCategoryId': json['displayProductCategoriesByCategoryId'] == null ? undefined : ((json['displayProductCategoriesByCategoryId'] as Array<any>).map(DisplayProductCategoryFromJSON)),
        'isCollection': json['isCollection'] == null ? undefined : json['isCollection'],
        'isHeader': json['isHeader'] == null ? undefined : json['isHeader'],
        'isRecommend': json['isRecommend'] == null ? undefined : json['isRecommend'],
        'isStep': json['isStep'] == null ? undefined : json['isStep'],
        'mobileBannerImage': json['mobileBannerImage'] == null ? undefined : json['mobileBannerImage'],
        'mongoId': json['mongoId'] == null ? undefined : json['mongoId'],
        'parentCategoryId': json['parentCategoryId'] == null ? undefined : json['parentCategoryId'],
        'productCategoriesByCategoryId': json['productCategoriesByCategoryId'] == null ? undefined : ((json['productCategoriesByCategoryId'] as Array<any>).map(ProductCategoryFromJSON)),
        'seq': json['seq'] == null ? undefined : json['seq'],
        'subCategoriesByParentCategoryId': json['subCategoriesByParentCategoryId'] == null ? undefined : ((json['subCategoriesByParentCategoryId'] as Array<any>).map(CategoryFromJSON)),
    };
}

export function CategoryToJSON(value?: Category | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'articlesByCategoryId': value['articlesByCategoryId'] == null ? undefined : ((value['articlesByCategoryId'] as Array<any>).map(ArticleToJSON)),
        'bannerImage': value['bannerImage'],
        'categoryId': value['categoryId'],
        'categoryName': value['categoryName'],
        'categoryUrlNaming': value['categoryUrlNaming'],
        'displayProductCategoriesByCategoryId': value['displayProductCategoriesByCategoryId'] == null ? undefined : ((value['displayProductCategoriesByCategoryId'] as Array<any>).map(DisplayProductCategoryToJSON)),
        'isCollection': value['isCollection'],
        'isHeader': value['isHeader'],
        'isRecommend': value['isRecommend'],
        'isStep': value['isStep'],
        'mobileBannerImage': value['mobileBannerImage'],
        'mongoId': value['mongoId'],
        'parentCategoryId': value['parentCategoryId'],
        'productCategoriesByCategoryId': value['productCategoriesByCategoryId'] == null ? undefined : ((value['productCategoriesByCategoryId'] as Array<any>).map(ProductCategoryToJSON)),
        'seq': value['seq'],
        'subCategoriesByParentCategoryId': value['subCategoriesByParentCategoryId'] == null ? undefined : ((value['subCategoriesByParentCategoryId'] as Array<any>).map(CategoryToJSON)),
    };
}

