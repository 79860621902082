/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  BookCountJPAModel,
  BookCountModel,
  CategoryDto,
  IntegerListRequestModel,
} from '../models/index';
import {
    BookCountJPAModelFromJSON,
    BookCountJPAModelToJSON,
    BookCountModelFromJSON,
    BookCountModelToJSON,
    CategoryDtoFromJSON,
    CategoryDtoToJSON,
    IntegerListRequestModelFromJSON,
    IntegerListRequestModelToJSON,
} from '../models/index';

export interface AddCategoriesUsingPOSTRequest {
    name?: string;
    requestModels?: string;
}

export interface AddCategoryUsingPOSTRequest {
    categoryName: string;
    name?: string;
    bannerImage?: string;
    mobileBannerImage?: string;
    seq?: number;
    isCollection?: number;
}

export interface AddCollectionUsingPOSTRequest {
    collectionName: string;
    name?: string;
    bannerImage?: string;
    mobileBannerImage?: string;
    seq?: number;
}

export interface AddSubCategoryUsingPOSTRequest {
    categoryName: string;
    parentCategoryId: number;
    seq: number;
    name?: string;
    bannerImage?: string;
    mobileBannerImage?: string;
}

export interface DeleteCategoryUsingPOSTRequest {
    categoryId: number;
    name?: string;
}

export interface GetAllSubCategoryByParentCategoryIdUsingGETRequest {
    parentCategoryId: number;
}

export interface GetBookCountByCategoryIdUsingGETRequest {
    categoryId: number;
    name?: string;
}

export interface GetBookCountUsingGETRequest {
    name?: string;
}

export interface GetCategoryByCategoryIdUsingGETRequest {
    categoryId: number;
}

export interface RearrangeSeqUsingPOSTRequest {
    name?: string;
}

export interface UpdateAllStatusCategoryUsingPOSTRequest {
    isAll: boolean;
    name?: string;
    integerListRequestModel?: IntegerListRequestModel;
}

export interface UpdateCategoryUsingPOSTRequest {
    id: number;
    categoryName: string;
    name?: string;
    bannerImage?: string;
    mobileBannerImage?: string;
    seq?: number;
}

export interface UpdateParentCategorySeqUsingPOSTRequest {
    name?: string;
    integerListRequestModel?: IntegerListRequestModel;
}

export interface UpdateStatusCategoryUsingPOSTRequest {
    id: number;
    name?: string;
}

export interface UpdateSubCategorySeqUsingPOSTRequest {
    parentCategoryId: number;
    name?: string;
    integerListRequestModel?: IntegerListRequestModel;
}

/**
 * 
 */
export class CategoryControllerApi extends runtime.BaseAPI {

    /**
     * addCategories
     */
    async addCategoriesUsingPOSTRaw(requestParameters: AddCategoriesUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<CategoryDto>>> {
        const queryParameters: any = {};

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        if (requestParameters['requestModels'] != null) {
            queryParameters['requestModels'] = requestParameters['requestModels'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/category/sync/adds`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CategoryDtoFromJSON));
    }

    /**
     * addCategories
     */
    async addCategoriesUsingPOST(requestParameters: AddCategoriesUsingPOSTRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<CategoryDto> | null | undefined > {
        const response = await this.addCategoriesUsingPOSTRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 201:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * addCategory
     */
    async addCategoryUsingPOSTRaw(requestParameters: AddCategoryUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CategoryDto>> {
        if (requestParameters['categoryName'] == null) {
            throw new runtime.RequiredError(
                'categoryName',
                'Required parameter "categoryName" was null or undefined when calling addCategoryUsingPOST().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        if (requestParameters['bannerImage'] != null) {
            queryParameters['bannerImage'] = requestParameters['bannerImage'];
        }

        if (requestParameters['mobileBannerImage'] != null) {
            queryParameters['mobileBannerImage'] = requestParameters['mobileBannerImage'];
        }

        if (requestParameters['seq'] != null) {
            queryParameters['seq'] = requestParameters['seq'];
        }

        if (requestParameters['isCollection'] != null) {
            queryParameters['IsCollection'] = requestParameters['isCollection'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/category/add/{categoryName}`.replace(`{${"categoryName"}}`, encodeURIComponent(String(requestParameters['categoryName']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CategoryDtoFromJSON(jsonValue));
    }

    /**
     * addCategory
     */
    async addCategoryUsingPOST(requestParameters: AddCategoryUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CategoryDto | null | undefined > {
        const response = await this.addCategoryUsingPOSTRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 201:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * Creates a new collection with the specified name and properties
     * Add a new collection
     */
    async addCollectionUsingPOSTRaw(requestParameters: AddCollectionUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CategoryDto>> {
        if (requestParameters['collectionName'] == null) {
            throw new runtime.RequiredError(
                'collectionName',
                'Required parameter "collectionName" was null or undefined when calling addCollectionUsingPOST().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        if (requestParameters['bannerImage'] != null) {
            queryParameters['bannerImage'] = requestParameters['bannerImage'];
        }

        if (requestParameters['mobileBannerImage'] != null) {
            queryParameters['mobileBannerImage'] = requestParameters['mobileBannerImage'];
        }

        if (requestParameters['seq'] != null) {
            queryParameters['seq'] = requestParameters['seq'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/category/addCollection/{categoryName}`.replace(`{${"collectionName"}}`, encodeURIComponent(String(requestParameters['collectionName']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CategoryDtoFromJSON(jsonValue));
    }

    /**
     * Creates a new collection with the specified name and properties
     * Add a new collection
     */
    async addCollectionUsingPOST(requestParameters: AddCollectionUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CategoryDto | null | undefined > {
        const response = await this.addCollectionUsingPOSTRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 201:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * addSubCategory
     */
    async addSubCategoryUsingPOSTRaw(requestParameters: AddSubCategoryUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CategoryDto>> {
        if (requestParameters['categoryName'] == null) {
            throw new runtime.RequiredError(
                'categoryName',
                'Required parameter "categoryName" was null or undefined when calling addSubCategoryUsingPOST().'
            );
        }

        if (requestParameters['parentCategoryId'] == null) {
            throw new runtime.RequiredError(
                'parentCategoryId',
                'Required parameter "parentCategoryId" was null or undefined when calling addSubCategoryUsingPOST().'
            );
        }

        if (requestParameters['seq'] == null) {
            throw new runtime.RequiredError(
                'seq',
                'Required parameter "seq" was null or undefined when calling addSubCategoryUsingPOST().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        if (requestParameters['parentCategoryId'] != null) {
            queryParameters['parentCategoryId'] = requestParameters['parentCategoryId'];
        }

        if (requestParameters['seq'] != null) {
            queryParameters['seq'] = requestParameters['seq'];
        }

        if (requestParameters['bannerImage'] != null) {
            queryParameters['bannerImage'] = requestParameters['bannerImage'];
        }

        if (requestParameters['mobileBannerImage'] != null) {
            queryParameters['mobileBannerImage'] = requestParameters['mobileBannerImage'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/category/add-subCategory/{categoryName}`.replace(`{${"categoryName"}}`, encodeURIComponent(String(requestParameters['categoryName']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CategoryDtoFromJSON(jsonValue));
    }

    /**
     * addSubCategory
     */
    async addSubCategoryUsingPOST(requestParameters: AddSubCategoryUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CategoryDto | null | undefined > {
        const response = await this.addSubCategoryUsingPOSTRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 201:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * deleteCategory
     */
    async deleteCategoryUsingPOSTRaw(requestParameters: DeleteCategoryUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CategoryDto>> {
        if (requestParameters['categoryId'] == null) {
            throw new runtime.RequiredError(
                'categoryId',
                'Required parameter "categoryId" was null or undefined when calling deleteCategoryUsingPOST().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/category/delete/{categoryId}`.replace(`{${"categoryId"}}`, encodeURIComponent(String(requestParameters['categoryId']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CategoryDtoFromJSON(jsonValue));
    }

    /**
     * deleteCategory
     */
    async deleteCategoryUsingPOST(requestParameters: DeleteCategoryUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CategoryDto | null | undefined > {
        const response = await this.deleteCategoryUsingPOSTRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 201:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * getAllCategoryByOrder
     */
    async getAllCategoryByOrderUsingGETRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<CategoryDto>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/category/allByOrder`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CategoryDtoFromJSON));
    }

    /**
     * getAllCategoryByOrder
     */
    async getAllCategoryByOrderUsingGET(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<CategoryDto>> {
        const response = await this.getAllCategoryByOrderUsingGETRaw(initOverrides);
        return await response.value();
    }

    /**
     * getAllCategory
     */
    async getAllCategoryUsingGETRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<CategoryDto>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/category/all`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CategoryDtoFromJSON));
    }

    /**
     * getAllCategory
     */
    async getAllCategoryUsingGET(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<CategoryDto>> {
        const response = await this.getAllCategoryUsingGETRaw(initOverrides);
        return await response.value();
    }

    /**
     * getAllCollection
     */
    async getAllCollectionUsingGETRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<CategoryDto>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/category/getCollection`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CategoryDtoFromJSON));
    }

    /**
     * getAllCollection
     */
    async getAllCollectionUsingGET(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<CategoryDto>> {
        const response = await this.getAllCollectionUsingGETRaw(initOverrides);
        return await response.value();
    }

    /**
     * getAllParentCategory
     */
    async getAllParentCategoryUsingGETRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<CategoryDto>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/category/parentCategory`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CategoryDtoFromJSON));
    }

    /**
     * getAllParentCategory
     */
    async getAllParentCategoryUsingGET(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<CategoryDto>> {
        const response = await this.getAllParentCategoryUsingGETRaw(initOverrides);
        return await response.value();
    }

    /**
     * getAllSubCategoryByParentCategoryId
     */
    async getAllSubCategoryByParentCategoryIdUsingGETRaw(requestParameters: GetAllSubCategoryByParentCategoryIdUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<CategoryDto>>> {
        if (requestParameters['parentCategoryId'] == null) {
            throw new runtime.RequiredError(
                'parentCategoryId',
                'Required parameter "parentCategoryId" was null or undefined when calling getAllSubCategoryByParentCategoryIdUsingGET().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/category/subCategory/{parentCategoryId}`.replace(`{${"parentCategoryId"}}`, encodeURIComponent(String(requestParameters['parentCategoryId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CategoryDtoFromJSON));
    }

    /**
     * getAllSubCategoryByParentCategoryId
     */
    async getAllSubCategoryByParentCategoryIdUsingGET(requestParameters: GetAllSubCategoryByParentCategoryIdUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<CategoryDto>> {
        const response = await this.getAllSubCategoryByParentCategoryIdUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getBookCountByCategoryId
     */
    async getBookCountByCategoryIdUsingGETRaw(requestParameters: GetBookCountByCategoryIdUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BookCountJPAModel>> {
        if (requestParameters['categoryId'] == null) {
            throw new runtime.RequiredError(
                'categoryId',
                'Required parameter "categoryId" was null or undefined when calling getBookCountByCategoryIdUsingGET().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/category/getBookCount/{categoryId}`.replace(`{${"categoryId"}}`, encodeURIComponent(String(requestParameters['categoryId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BookCountJPAModelFromJSON(jsonValue));
    }

    /**
     * getBookCountByCategoryId
     */
    async getBookCountByCategoryIdUsingGET(requestParameters: GetBookCountByCategoryIdUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BookCountJPAModel> {
        const response = await this.getBookCountByCategoryIdUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getBookCount
     */
    async getBookCountUsingGETRaw(requestParameters: GetBookCountUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<BookCountModel>>> {
        const queryParameters: any = {};

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/category/getBookCount`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(BookCountModelFromJSON));
    }

    /**
     * getBookCount
     */
    async getBookCountUsingGET(requestParameters: GetBookCountUsingGETRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<BookCountModel>> {
        const response = await this.getBookCountUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getCategoryByCategoryId
     */
    async getCategoryByCategoryIdUsingGETRaw(requestParameters: GetCategoryByCategoryIdUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CategoryDto>> {
        if (requestParameters['categoryId'] == null) {
            throw new runtime.RequiredError(
                'categoryId',
                'Required parameter "categoryId" was null or undefined when calling getCategoryByCategoryIdUsingGET().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/category/{categoryId}`.replace(`{${"categoryId"}}`, encodeURIComponent(String(requestParameters['categoryId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CategoryDtoFromJSON(jsonValue));
    }

    /**
     * getCategoryByCategoryId
     */
    async getCategoryByCategoryIdUsingGET(requestParameters: GetCategoryByCategoryIdUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CategoryDto> {
        const response = await this.getCategoryByCategoryIdUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * rearrangeSeq
     */
    async rearrangeSeqUsingPOSTRaw(requestParameters: RearrangeSeqUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<CategoryDto>>> {
        const queryParameters: any = {};

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/category/rearrangeSeq`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CategoryDtoFromJSON));
    }

    /**
     * rearrangeSeq
     */
    async rearrangeSeqUsingPOST(requestParameters: RearrangeSeqUsingPOSTRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<CategoryDto> | null | undefined > {
        const response = await this.rearrangeSeqUsingPOSTRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 201:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * updateAllStatusCategory
     */
    async updateAllStatusCategoryUsingPOSTRaw(requestParameters: UpdateAllStatusCategoryUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<CategoryDto>>> {
        if (requestParameters['isAll'] == null) {
            throw new runtime.RequiredError(
                'isAll',
                'Required parameter "isAll" was null or undefined when calling updateAllStatusCategoryUsingPOST().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        if (requestParameters['isAll'] != null) {
            queryParameters['isAll'] = requestParameters['isAll'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/category/updateAllStatus`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: IntegerListRequestModelToJSON(requestParameters['integerListRequestModel']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CategoryDtoFromJSON));
    }

    /**
     * updateAllStatusCategory
     */
    async updateAllStatusCategoryUsingPOST(requestParameters: UpdateAllStatusCategoryUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<CategoryDto> | null | undefined > {
        const response = await this.updateAllStatusCategoryUsingPOSTRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 201:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * updateCategory
     */
    async updateCategoryUsingPOSTRaw(requestParameters: UpdateCategoryUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CategoryDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling updateCategoryUsingPOST().'
            );
        }

        if (requestParameters['categoryName'] == null) {
            throw new runtime.RequiredError(
                'categoryName',
                'Required parameter "categoryName" was null or undefined when calling updateCategoryUsingPOST().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        if (requestParameters['id'] != null) {
            queryParameters['id'] = requestParameters['id'];
        }

        if (requestParameters['bannerImage'] != null) {
            queryParameters['bannerImage'] = requestParameters['bannerImage'];
        }

        if (requestParameters['mobileBannerImage'] != null) {
            queryParameters['mobileBannerImage'] = requestParameters['mobileBannerImage'];
        }

        if (requestParameters['seq'] != null) {
            queryParameters['seq'] = requestParameters['seq'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/category/update/{categoryName}`.replace(`{${"categoryName"}}`, encodeURIComponent(String(requestParameters['categoryName']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CategoryDtoFromJSON(jsonValue));
    }

    /**
     * updateCategory
     */
    async updateCategoryUsingPOST(requestParameters: UpdateCategoryUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CategoryDto | null | undefined > {
        const response = await this.updateCategoryUsingPOSTRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 201:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * updateParentCategorySeq
     */
    async updateParentCategorySeqUsingPOSTRaw(requestParameters: UpdateParentCategorySeqUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<CategoryDto>>> {
        const queryParameters: any = {};

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/category/updateParentCategorySeq`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: IntegerListRequestModelToJSON(requestParameters['integerListRequestModel']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CategoryDtoFromJSON));
    }

    /**
     * updateParentCategorySeq
     */
    async updateParentCategorySeqUsingPOST(requestParameters: UpdateParentCategorySeqUsingPOSTRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<CategoryDto> | null | undefined > {
        const response = await this.updateParentCategorySeqUsingPOSTRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 201:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * updateStatusCategory
     */
    async updateStatusCategoryUsingPOSTRaw(requestParameters: UpdateStatusCategoryUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CategoryDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling updateStatusCategoryUsingPOST().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        if (requestParameters['id'] != null) {
            queryParameters['id'] = requestParameters['id'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/category/updateStatus`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CategoryDtoFromJSON(jsonValue));
    }

    /**
     * updateStatusCategory
     */
    async updateStatusCategoryUsingPOST(requestParameters: UpdateStatusCategoryUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CategoryDto | null | undefined > {
        const response = await this.updateStatusCategoryUsingPOSTRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 201:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * updateSubCategorySeq
     */
    async updateSubCategorySeqUsingPOSTRaw(requestParameters: UpdateSubCategorySeqUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<CategoryDto>>> {
        if (requestParameters['parentCategoryId'] == null) {
            throw new runtime.RequiredError(
                'parentCategoryId',
                'Required parameter "parentCategoryId" was null or undefined when calling updateSubCategorySeqUsingPOST().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        if (requestParameters['parentCategoryId'] != null) {
            queryParameters['parentCategoryId'] = requestParameters['parentCategoryId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/category/updateSubCategorySeq`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: IntegerListRequestModelToJSON(requestParameters['integerListRequestModel']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CategoryDtoFromJSON));
    }

    /**
     * updateSubCategorySeq
     */
    async updateSubCategorySeqUsingPOST(requestParameters: UpdateSubCategorySeqUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<CategoryDto> | null | undefined > {
        const response = await this.updateSubCategorySeqUsingPOSTRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 201:
                return null;
            default:
                return await response.value();
        }
    }

}
