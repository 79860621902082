/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { CartProduct } from './CartProduct';
import {
    CartProductFromJSON,
    CartProductFromJSONTyped,
    CartProductToJSON,
} from './CartProduct';
import type { Coupon } from './Coupon';
import {
    CouponFromJSON,
    CouponFromJSONTyped,
    CouponToJSON,
} from './Coupon';
import type { Member } from './Member';
import {
    MemberFromJSON,
    MemberFromJSONTyped,
    MemberToJSON,
} from './Member';
import type { ShoppingCartCoupon } from './ShoppingCartCoupon';
import {
    ShoppingCartCouponFromJSON,
    ShoppingCartCouponFromJSONTyped,
    ShoppingCartCouponToJSON,
} from './ShoppingCartCoupon';

/**
 * 
 * @export
 * @interface MemberShoppingCart
 */
export interface MemberShoppingCart {
    /**
     * 
     * @type {Array<CartProduct>}
     * @memberof MemberShoppingCart
     */
    cartProductByShoppingCartId?: Array<CartProduct>;
    /**
     * 
     * @type {Coupon}
     * @memberof MemberShoppingCart
     */
    couponByCouponId?: Coupon;
    /**
     * 
     * @type {number}
     * @memberof MemberShoppingCart
     */
    couponId?: number;
    /**
     * 
     * @type {Member}
     * @memberof MemberShoppingCart
     */
    memberByMemberId?: Member;
    /**
     * 
     * @type {number}
     * @memberof MemberShoppingCart
     */
    memberId?: number;
    /**
     * 
     * @type {string}
     * @memberof MemberShoppingCart
     */
    promotionCode?: string;
    /**
     * 
     * @type {Array<ShoppingCartCoupon>}
     * @memberof MemberShoppingCart
     */
    shoppingCartCouponsByShoppingCartId?: Array<ShoppingCartCoupon>;
    /**
     * 
     * @type {number}
     * @memberof MemberShoppingCart
     */
    shoppingCartId?: number;
    /**
     * 
     * @type {number}
     * @memberof MemberShoppingCart
     */
    total?: number;
}

/**
 * Check if a given object implements the MemberShoppingCart interface.
 */
export function instanceOfMemberShoppingCart(value: object): boolean {
    return true;
}

export function MemberShoppingCartFromJSON(json: any): MemberShoppingCart {
    return MemberShoppingCartFromJSONTyped(json, false);
}

export function MemberShoppingCartFromJSONTyped(json: any, ignoreDiscriminator: boolean): MemberShoppingCart {
    if (json == null) {
        return json;
    }
    return {
        
        'cartProductByShoppingCartId': json['cartProductByShoppingCartId'] == null ? undefined : ((json['cartProductByShoppingCartId'] as Array<any>).map(CartProductFromJSON)),
        'couponByCouponId': json['couponByCouponId'] == null ? undefined : CouponFromJSON(json['couponByCouponId']),
        'couponId': json['couponId'] == null ? undefined : json['couponId'],
        'memberByMemberId': json['memberByMemberId'] == null ? undefined : MemberFromJSON(json['memberByMemberId']),
        'memberId': json['memberId'] == null ? undefined : json['memberId'],
        'promotionCode': json['promotionCode'] == null ? undefined : json['promotionCode'],
        'shoppingCartCouponsByShoppingCartId': json['shoppingCartCouponsByShoppingCartId'] == null ? undefined : ((json['shoppingCartCouponsByShoppingCartId'] as Array<any>).map(ShoppingCartCouponFromJSON)),
        'shoppingCartId': json['shoppingCartId'] == null ? undefined : json['shoppingCartId'],
        'total': json['total'] == null ? undefined : json['total'],
    };
}

export function MemberShoppingCartToJSON(value?: MemberShoppingCart | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'cartProductByShoppingCartId': value['cartProductByShoppingCartId'] == null ? undefined : ((value['cartProductByShoppingCartId'] as Array<any>).map(CartProductToJSON)),
        'couponByCouponId': CouponToJSON(value['couponByCouponId']),
        'couponId': value['couponId'],
        'memberByMemberId': MemberToJSON(value['memberByMemberId']),
        'memberId': value['memberId'],
        'promotionCode': value['promotionCode'],
        'shoppingCartCouponsByShoppingCartId': value['shoppingCartCouponsByShoppingCartId'] == null ? undefined : ((value['shoppingCartCouponsByShoppingCartId'] as Array<any>).map(ShoppingCartCouponToJSON)),
        'shoppingCartId': value['shoppingCartId'],
        'total': value['total'],
    };
}

