import {g, p} from './BasicAPI';


export const getAll = () => {
    return g(`/displayProduct/admin/all`)
        .then(res => res.json());
}

export const getDisplayProductByCategoryId = (id) =>{
    return g(`/displayProduct/category/${id}?page=1&size=9999`).then(res=>res.json())
}

export const getDisplayProductById = (id) => 
{
    return g(`/displayProduct/admin/${id}`)
        .then(res => res.json());
}

///delete/{displayProductId}
export const deleteDisplayProduct = (id) => 
{
    return p(`/displayProduct/delete/${id}`)
        .then(res => res.json());
}

export function create2(
	displayProductNameChi,
	displayProductNameEng,
	displayProductUrlNaming,
	displayProductPrice,
    displayProductImage,
	displayProductShortDescription,
	displayProductDescriptionDetail,
	displayProductDescriptionFeature,
    displayProductDescriptionIngredient,
	displayProductDescriptionUsage,
	displayProductDescriptionUseCount,
    displayProductDescriptionTarget,
	functionalIcon,
	recommended,
	onShelfDate,
	offShelfDate,
	order,
	categoryIds,
    productIds,
	hashtag
)
{
    return p('/displayProduct/add', {
        displayProductNameChi,
        displayProductNameEng,
		displayProductUrlNaming,
        displayProductPrice,
        displayProductImage,
		displayProductShortDescription,
        displayProductDescriptionDetail,
        displayProductDescriptionFeature,
        displayProductDescriptionIngredient,
        displayProductDescriptionUsage,
        displayProductDescriptionUseCount,
        displayProductDescriptionTarget,
        functionalIcon,
		recommended,
        onShelfDate,
        offShelfDate,
		order,
        categoryIds,
        productIds,
        hashtag,
    }).then(t => t.json());
}

export function update2(
	id,
	displayProductNameChi,
	displayProductNameEng,
	displayProductUrlNaming,
	displayProductPrice,
    displayProductImage,
	displayProductShortDescription,
	displayProductDescriptionDetail,
	displayProductDescriptionFeature,
    displayProductDescriptionIngredient,
	displayProductDescriptionUsage,
	displayProductDescriptionUseCount,
    displayProductDescriptionTarget,
	functionalIcon,
	recommended,
	onShelfDate,
	offShelfDate,
	order,
	categoryIds,
    productIds,
	hashtag
)
{
    return p('/displayProduct/update', {
        displayProductId: id,
        displayProductNameChi,
        displayProductNameEng,
		displayProductUrlNaming,
        displayProductPrice,
        displayProductImage,
		displayProductShortDescription,
        displayProductDescriptionDetail,
        displayProductDescriptionFeature,
        displayProductDescriptionIngredient,
        displayProductDescriptionUsage,
        displayProductDescriptionUseCount,
        displayProductDescriptionTarget,
        functionalIcon,
		recommended,
        onShelfDate,
        offShelfDate,
		order,
        categoryIds,
        productIds,
        hashtag,
    }).then(t => t.json());
}

export function updateOrder2(id, order)
{
    return p('/displayProduct/updateDisplayProductOrder', {
        displayProductId: id,
		order,
    }).then(t => t.json());
}

export function getDisplayProductByUrlName(url){
    return g(`/displayProduct/${url}`).then(res=>res.json())
}