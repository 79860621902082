/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Award } from './Award';
import {
    AwardFromJSON,
    AwardFromJSONTyped,
    AwardToJSON,
} from './Award';
import type { DisplayProduct } from './DisplayProduct';
import {
    DisplayProductFromJSON,
    DisplayProductFromJSONTyped,
    DisplayProductToJSON,
} from './DisplayProduct';

/**
 * 
 * @export
 * @interface DisplayProductAward
 */
export interface DisplayProductAward {
    /**
     * 
     * @type {Award}
     * @memberof DisplayProductAward
     */
    award?: Award;
    /**
     * 
     * @type {number}
     * @memberof DisplayProductAward
     */
    awardId?: number;
    /**
     * 
     * @type {DisplayProduct}
     * @memberof DisplayProductAward
     */
    displayProduct?: DisplayProduct;
    /**
     * 
     * @type {number}
     * @memberof DisplayProductAward
     */
    displayProductId?: number;
}

/**
 * Check if a given object implements the DisplayProductAward interface.
 */
export function instanceOfDisplayProductAward(value: object): boolean {
    return true;
}

export function DisplayProductAwardFromJSON(json: any): DisplayProductAward {
    return DisplayProductAwardFromJSONTyped(json, false);
}

export function DisplayProductAwardFromJSONTyped(json: any, ignoreDiscriminator: boolean): DisplayProductAward {
    if (json == null) {
        return json;
    }
    return {
        
        'award': json['award'] == null ? undefined : AwardFromJSON(json['award']),
        'awardId': json['awardId'] == null ? undefined : json['awardId'],
        'displayProduct': json['displayProduct'] == null ? undefined : DisplayProductFromJSON(json['displayProduct']),
        'displayProductId': json['displayProductId'] == null ? undefined : json['displayProductId'],
    };
}

export function DisplayProductAwardToJSON(value?: DisplayProductAward | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'award': AwardToJSON(value['award']),
        'awardId': value['awardId'],
        'displayProduct': DisplayProductToJSON(value['displayProduct']),
        'displayProductId': value['displayProductId'],
    };
}

