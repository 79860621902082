/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { MarketingEvent } from './MarketingEvent';
import {
    MarketingEventFromJSON,
    MarketingEventFromJSONTyped,
    MarketingEventToJSON,
} from './MarketingEvent';
import type { Order } from './Order';
import {
    OrderFromJSON,
    OrderFromJSONTyped,
    OrderToJSON,
} from './Order';

/**
 * 
 * @export
 * @interface OrderMarketingEvent
 */
export interface OrderMarketingEvent {
    /**
     * 
     * @type {MarketingEvent}
     * @memberof OrderMarketingEvent
     */
    marketingEventByMarketingEventId?: MarketingEvent;
    /**
     * 
     * @type {number}
     * @memberof OrderMarketingEvent
     */
    marketingEventId?: number;
    /**
     * 
     * @type {Order}
     * @memberof OrderMarketingEvent
     */
    orderByOrderId?: Order;
    /**
     * 
     * @type {number}
     * @memberof OrderMarketingEvent
     */
    orderId?: number;
}

/**
 * Check if a given object implements the OrderMarketingEvent interface.
 */
export function instanceOfOrderMarketingEvent(value: object): boolean {
    return true;
}

export function OrderMarketingEventFromJSON(json: any): OrderMarketingEvent {
    return OrderMarketingEventFromJSONTyped(json, false);
}

export function OrderMarketingEventFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrderMarketingEvent {
    if (json == null) {
        return json;
    }
    return {
        
        'marketingEventByMarketingEventId': json['marketingEventByMarketingEventId'] == null ? undefined : MarketingEventFromJSON(json['marketingEventByMarketingEventId']),
        'marketingEventId': json['marketingEventId'] == null ? undefined : json['marketingEventId'],
        'orderByOrderId': json['orderByOrderId'] == null ? undefined : OrderFromJSON(json['orderByOrderId']),
        'orderId': json['orderId'] == null ? undefined : json['orderId'],
    };
}

export function OrderMarketingEventToJSON(value?: OrderMarketingEvent | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'marketingEventByMarketingEventId': MarketingEventToJSON(value['marketingEventByMarketingEventId']),
        'marketingEventId': value['marketingEventId'],
        'orderByOrderId': OrderToJSON(value['orderByOrderId']),
        'orderId': value['orderId'],
    };
}

