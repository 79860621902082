import { FileHandlingControllerApi } from '../fetcher/apis/FileHandlingControllerApi';
import { Configuration } from '../fetcher/runtime';

const config = new Configuration({
    basePath: process.env.REACT_APP_BACKEND_URL?.replace(/\/+$/, ""), // 移除尾部的斜線
    apiKey: async () => {
        return localStorage.getItem('token') || '';
    }
});

const fileHandlingApi = new FileHandlingControllerApi(config);

export { fileHandlingApi };