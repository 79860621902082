/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  PointRuleDto,
} from '../models/index';
import {
    PointRuleDtoFromJSON,
    PointRuleDtoToJSON,
} from '../models/index';

export interface AddPointRuleUsingPOSTRequest {
    pointRuleName: string;
    period: string;
    startDate: Date;
    endDate: Date;
    appiedProduct: number;
    appiedMemberLevel: number;
    name?: string;
    cashForOnePoint?: number;
    pointToOneCash?: number;
    oneCashToPoint?: number;
    forBirthday?: string;
}

export interface DeletePointRuleUsingPOSTRequest {
    pointRuleId: number;
    name?: string;
}

export interface GetAllPointRuleUsingGETRequest {
    name?: string;
}

export interface GetPointRuleUsingGETRequest {
    pointRuleId: number;
    name?: string;
}

export interface PointToCashUsingDELETERequest {
    point: number;
}

export interface PointToCashUsingGETRequest {
    point: number;
}

export interface PointToCashUsingHEADRequest {
    point: number;
}

export interface PointToCashUsingOPTIONSRequest {
    point: number;
}

export interface PointToCashUsingPATCHRequest {
    point: number;
}

export interface PointToCashUsingPOSTRequest {
    point: number;
}

export interface PointToCashUsingPUTRequest {
    point: number;
}

export interface PointToCashUsingTRACERequest {
    point: number;
}

export interface UpdatePointRuleUsingPOSTRequest {
    pointRuleId: number;
    name?: string;
    pointRuleName?: string;
    cashForOnePoint?: number;
    pointToOneCash?: number;
    oneCashToPoint?: number;
    period?: string;
    startDate?: Date;
    endDate?: Date;
    appiedProduct?: number;
    appiedMemberLevel?: number;
    forBirthday?: string;
}

/**
 * 
 */
export class PointRuleControllerApi extends runtime.BaseAPI {

    /**
     * addPointRule
     */
    async addPointRuleUsingPOSTRaw(requestParameters: AddPointRuleUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PointRuleDto>> {
        if (requestParameters['pointRuleName'] == null) {
            throw new runtime.RequiredError(
                'pointRuleName',
                'Required parameter "pointRuleName" was null or undefined when calling addPointRuleUsingPOST().'
            );
        }

        if (requestParameters['period'] == null) {
            throw new runtime.RequiredError(
                'period',
                'Required parameter "period" was null or undefined when calling addPointRuleUsingPOST().'
            );
        }

        if (requestParameters['startDate'] == null) {
            throw new runtime.RequiredError(
                'startDate',
                'Required parameter "startDate" was null or undefined when calling addPointRuleUsingPOST().'
            );
        }

        if (requestParameters['endDate'] == null) {
            throw new runtime.RequiredError(
                'endDate',
                'Required parameter "endDate" was null or undefined when calling addPointRuleUsingPOST().'
            );
        }

        if (requestParameters['appiedProduct'] == null) {
            throw new runtime.RequiredError(
                'appiedProduct',
                'Required parameter "appiedProduct" was null or undefined when calling addPointRuleUsingPOST().'
            );
        }

        if (requestParameters['appiedMemberLevel'] == null) {
            throw new runtime.RequiredError(
                'appiedMemberLevel',
                'Required parameter "appiedMemberLevel" was null or undefined when calling addPointRuleUsingPOST().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        if (requestParameters['pointRuleName'] != null) {
            queryParameters['pointRuleName'] = requestParameters['pointRuleName'];
        }

        if (requestParameters['cashForOnePoint'] != null) {
            queryParameters['cashForOnePoint'] = requestParameters['cashForOnePoint'];
        }

        if (requestParameters['pointToOneCash'] != null) {
            queryParameters['pointToOneCash'] = requestParameters['pointToOneCash'];
        }

        if (requestParameters['oneCashToPoint'] != null) {
            queryParameters['oneCashToPoint'] = requestParameters['oneCashToPoint'];
        }

        if (requestParameters['period'] != null) {
            queryParameters['period'] = requestParameters['period'];
        }

        if (requestParameters['startDate'] != null) {
            queryParameters['startDate'] = (requestParameters['startDate'] as any).toISOString();
        }

        if (requestParameters['endDate'] != null) {
            queryParameters['endDate'] = (requestParameters['endDate'] as any).toISOString();
        }

        if (requestParameters['appiedProduct'] != null) {
            queryParameters['appiedProduct'] = requestParameters['appiedProduct'];
        }

        if (requestParameters['appiedMemberLevel'] != null) {
            queryParameters['appiedMemberLevel'] = requestParameters['appiedMemberLevel'];
        }

        if (requestParameters['forBirthday'] != null) {
            queryParameters['forBirthday'] = requestParameters['forBirthday'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/pointRule/add`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PointRuleDtoFromJSON(jsonValue));
    }

    /**
     * addPointRule
     */
    async addPointRuleUsingPOST(requestParameters: AddPointRuleUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PointRuleDto | null | undefined > {
        const response = await this.addPointRuleUsingPOSTRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 201:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * deletePointRule
     */
    async deletePointRuleUsingPOSTRaw(requestParameters: DeletePointRuleUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PointRuleDto>> {
        if (requestParameters['pointRuleId'] == null) {
            throw new runtime.RequiredError(
                'pointRuleId',
                'Required parameter "pointRuleId" was null or undefined when calling deletePointRuleUsingPOST().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/pointRule/delete/{pointRuleId}`.replace(`{${"pointRuleId"}}`, encodeURIComponent(String(requestParameters['pointRuleId']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PointRuleDtoFromJSON(jsonValue));
    }

    /**
     * deletePointRule
     */
    async deletePointRuleUsingPOST(requestParameters: DeletePointRuleUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PointRuleDto | null | undefined > {
        const response = await this.deletePointRuleUsingPOSTRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 201:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * getAllPointRule
     */
    async getAllPointRuleUsingGETRaw(requestParameters: GetAllPointRuleUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<PointRuleDto>>> {
        const queryParameters: any = {};

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/pointRule/all`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PointRuleDtoFromJSON));
    }

    /**
     * getAllPointRule
     */
    async getAllPointRuleUsingGET(requestParameters: GetAllPointRuleUsingGETRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<PointRuleDto>> {
        const response = await this.getAllPointRuleUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getPointRulePointToOneCash
     */
    async getPointRulePointToOneCashUsingDELETERaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PointRuleDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/pointRule/pointToOneCash`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PointRuleDtoFromJSON(jsonValue));
    }

    /**
     * getPointRulePointToOneCash
     */
    async getPointRulePointToOneCashUsingDELETE(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PointRuleDto | null | undefined > {
        const response = await this.getPointRulePointToOneCashUsingDELETERaw(initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 204:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * getPointRulePointToOneCash
     */
    async getPointRulePointToOneCashUsingGETRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PointRuleDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/pointRule/pointToOneCash`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PointRuleDtoFromJSON(jsonValue));
    }

    /**
     * getPointRulePointToOneCash
     */
    async getPointRulePointToOneCashUsingGET(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PointRuleDto> {
        const response = await this.getPointRulePointToOneCashUsingGETRaw(initOverrides);
        return await response.value();
    }

    /**
     * getPointRulePointToOneCash
     */
    async getPointRulePointToOneCashUsingHEADRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PointRuleDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/pointRule/pointToOneCash`,
            method: 'HEAD',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PointRuleDtoFromJSON(jsonValue));
    }

    /**
     * getPointRulePointToOneCash
     */
    async getPointRulePointToOneCashUsingHEAD(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PointRuleDto | null | undefined > {
        const response = await this.getPointRulePointToOneCashUsingHEADRaw(initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 204:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * getPointRulePointToOneCash
     */
    async getPointRulePointToOneCashUsingOPTIONSRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PointRuleDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/pointRule/pointToOneCash`,
            method: 'OPTIONS',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PointRuleDtoFromJSON(jsonValue));
    }

    /**
     * getPointRulePointToOneCash
     */
    async getPointRulePointToOneCashUsingOPTIONS(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PointRuleDto | null | undefined > {
        const response = await this.getPointRulePointToOneCashUsingOPTIONSRaw(initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 204:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * getPointRulePointToOneCash
     */
    async getPointRulePointToOneCashUsingPATCHRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PointRuleDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/pointRule/pointToOneCash`,
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PointRuleDtoFromJSON(jsonValue));
    }

    /**
     * getPointRulePointToOneCash
     */
    async getPointRulePointToOneCashUsingPATCH(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PointRuleDto | null | undefined > {
        const response = await this.getPointRulePointToOneCashUsingPATCHRaw(initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 204:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * getPointRulePointToOneCash
     */
    async getPointRulePointToOneCashUsingPOSTRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PointRuleDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/pointRule/pointToOneCash`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PointRuleDtoFromJSON(jsonValue));
    }

    /**
     * getPointRulePointToOneCash
     */
    async getPointRulePointToOneCashUsingPOST(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PointRuleDto | null | undefined > {
        const response = await this.getPointRulePointToOneCashUsingPOSTRaw(initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 201:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * getPointRulePointToOneCash
     */
    async getPointRulePointToOneCashUsingPUTRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PointRuleDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/pointRule/pointToOneCash`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PointRuleDtoFromJSON(jsonValue));
    }

    /**
     * getPointRulePointToOneCash
     */
    async getPointRulePointToOneCashUsingPUT(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PointRuleDto | null | undefined > {
        const response = await this.getPointRulePointToOneCashUsingPUTRaw(initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 201:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * getPointRulePointToOneCash
     */
    async getPointRulePointToOneCashUsingTRACERaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PointRuleDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/pointRule/pointToOneCash`,
            method: 'TRACE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PointRuleDtoFromJSON(jsonValue));
    }

    /**
     * getPointRulePointToOneCash
     */
    async getPointRulePointToOneCashUsingTRACE(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PointRuleDto | null | undefined > {
        const response = await this.getPointRulePointToOneCashUsingTRACERaw(initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 204:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * getPointRule
     */
    async getPointRuleUsingGETRaw(requestParameters: GetPointRuleUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PointRuleDto>> {
        if (requestParameters['pointRuleId'] == null) {
            throw new runtime.RequiredError(
                'pointRuleId',
                'Required parameter "pointRuleId" was null or undefined when calling getPointRuleUsingGET().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/pointRule/{pointRuleId}`.replace(`{${"pointRuleId"}}`, encodeURIComponent(String(requestParameters['pointRuleId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PointRuleDtoFromJSON(jsonValue));
    }

    /**
     * getPointRule
     */
    async getPointRuleUsingGET(requestParameters: GetPointRuleUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PointRuleDto> {
        const response = await this.getPointRuleUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * pointToCash
     */
    async pointToCashUsingDELETERaw(requestParameters: PointToCashUsingDELETERequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        if (requestParameters['point'] == null) {
            throw new runtime.RequiredError(
                'point',
                'Required parameter "point" was null or undefined when calling pointToCashUsingDELETE().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/pointRule/pointToCash/{point}`.replace(`{${"point"}}`, encodeURIComponent(String(requestParameters['point']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * pointToCash
     */
    async pointToCashUsingDELETE(requestParameters: PointToCashUsingDELETERequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object | null | undefined > {
        const response = await this.pointToCashUsingDELETERaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 204:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * pointToCash
     */
    async pointToCashUsingGETRaw(requestParameters: PointToCashUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        if (requestParameters['point'] == null) {
            throw new runtime.RequiredError(
                'point',
                'Required parameter "point" was null or undefined when calling pointToCashUsingGET().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/pointRule/pointToCash/{point}`.replace(`{${"point"}}`, encodeURIComponent(String(requestParameters['point']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * pointToCash
     */
    async pointToCashUsingGET(requestParameters: PointToCashUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object> {
        const response = await this.pointToCashUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * pointToCash
     */
    async pointToCashUsingHEADRaw(requestParameters: PointToCashUsingHEADRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        if (requestParameters['point'] == null) {
            throw new runtime.RequiredError(
                'point',
                'Required parameter "point" was null or undefined when calling pointToCashUsingHEAD().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/pointRule/pointToCash/{point}`.replace(`{${"point"}}`, encodeURIComponent(String(requestParameters['point']))),
            method: 'HEAD',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * pointToCash
     */
    async pointToCashUsingHEAD(requestParameters: PointToCashUsingHEADRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object | null | undefined > {
        const response = await this.pointToCashUsingHEADRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 204:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * pointToCash
     */
    async pointToCashUsingOPTIONSRaw(requestParameters: PointToCashUsingOPTIONSRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        if (requestParameters['point'] == null) {
            throw new runtime.RequiredError(
                'point',
                'Required parameter "point" was null or undefined when calling pointToCashUsingOPTIONS().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/pointRule/pointToCash/{point}`.replace(`{${"point"}}`, encodeURIComponent(String(requestParameters['point']))),
            method: 'OPTIONS',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * pointToCash
     */
    async pointToCashUsingOPTIONS(requestParameters: PointToCashUsingOPTIONSRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object | null | undefined > {
        const response = await this.pointToCashUsingOPTIONSRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 204:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * pointToCash
     */
    async pointToCashUsingPATCHRaw(requestParameters: PointToCashUsingPATCHRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        if (requestParameters['point'] == null) {
            throw new runtime.RequiredError(
                'point',
                'Required parameter "point" was null or undefined when calling pointToCashUsingPATCH().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/pointRule/pointToCash/{point}`.replace(`{${"point"}}`, encodeURIComponent(String(requestParameters['point']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * pointToCash
     */
    async pointToCashUsingPATCH(requestParameters: PointToCashUsingPATCHRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object | null | undefined > {
        const response = await this.pointToCashUsingPATCHRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 204:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * pointToCash
     */
    async pointToCashUsingPOSTRaw(requestParameters: PointToCashUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        if (requestParameters['point'] == null) {
            throw new runtime.RequiredError(
                'point',
                'Required parameter "point" was null or undefined when calling pointToCashUsingPOST().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/pointRule/pointToCash/{point}`.replace(`{${"point"}}`, encodeURIComponent(String(requestParameters['point']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * pointToCash
     */
    async pointToCashUsingPOST(requestParameters: PointToCashUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object | null | undefined > {
        const response = await this.pointToCashUsingPOSTRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 201:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * pointToCash
     */
    async pointToCashUsingPUTRaw(requestParameters: PointToCashUsingPUTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        if (requestParameters['point'] == null) {
            throw new runtime.RequiredError(
                'point',
                'Required parameter "point" was null or undefined when calling pointToCashUsingPUT().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/pointRule/pointToCash/{point}`.replace(`{${"point"}}`, encodeURIComponent(String(requestParameters['point']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * pointToCash
     */
    async pointToCashUsingPUT(requestParameters: PointToCashUsingPUTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object | null | undefined > {
        const response = await this.pointToCashUsingPUTRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 201:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * pointToCash
     */
    async pointToCashUsingTRACERaw(requestParameters: PointToCashUsingTRACERequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        if (requestParameters['point'] == null) {
            throw new runtime.RequiredError(
                'point',
                'Required parameter "point" was null or undefined when calling pointToCashUsingTRACE().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/pointRule/pointToCash/{point}`.replace(`{${"point"}}`, encodeURIComponent(String(requestParameters['point']))),
            method: 'TRACE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * pointToCash
     */
    async pointToCashUsingTRACE(requestParameters: PointToCashUsingTRACERequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object | null | undefined > {
        const response = await this.pointToCashUsingTRACERaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 204:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * updatePointRule
     */
    async updatePointRuleUsingPOSTRaw(requestParameters: UpdatePointRuleUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PointRuleDto>> {
        if (requestParameters['pointRuleId'] == null) {
            throw new runtime.RequiredError(
                'pointRuleId',
                'Required parameter "pointRuleId" was null or undefined when calling updatePointRuleUsingPOST().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        if (requestParameters['pointRuleId'] != null) {
            queryParameters['pointRuleId'] = requestParameters['pointRuleId'];
        }

        if (requestParameters['pointRuleName'] != null) {
            queryParameters['pointRuleName'] = requestParameters['pointRuleName'];
        }

        if (requestParameters['cashForOnePoint'] != null) {
            queryParameters['cashForOnePoint'] = requestParameters['cashForOnePoint'];
        }

        if (requestParameters['pointToOneCash'] != null) {
            queryParameters['pointToOneCash'] = requestParameters['pointToOneCash'];
        }

        if (requestParameters['oneCashToPoint'] != null) {
            queryParameters['oneCashToPoint'] = requestParameters['oneCashToPoint'];
        }

        if (requestParameters['period'] != null) {
            queryParameters['period'] = requestParameters['period'];
        }

        if (requestParameters['startDate'] != null) {
            queryParameters['startDate'] = (requestParameters['startDate'] as any).toISOString();
        }

        if (requestParameters['endDate'] != null) {
            queryParameters['endDate'] = (requestParameters['endDate'] as any).toISOString();
        }

        if (requestParameters['appiedProduct'] != null) {
            queryParameters['appiedProduct'] = requestParameters['appiedProduct'];
        }

        if (requestParameters['appiedMemberLevel'] != null) {
            queryParameters['appiedMemberLevel'] = requestParameters['appiedMemberLevel'];
        }

        if (requestParameters['forBirthday'] != null) {
            queryParameters['forBirthday'] = requestParameters['forBirthday'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/pointRule/update`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PointRuleDtoFromJSON(jsonValue));
    }

    /**
     * updatePointRule
     */
    async updatePointRuleUsingPOST(requestParameters: UpdatePointRuleUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PointRuleDto | null | undefined > {
        const response = await this.updatePointRuleUsingPOSTRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 201:
                return null;
            default:
                return await response.value();
        }
    }

}
