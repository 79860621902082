/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Country } from './Country';
import {
    CountryFromJSON,
    CountryFromJSONTyped,
    CountryToJSON,
} from './Country';
import type { Member } from './Member';
import {
    MemberFromJSON,
    MemberFromJSONTyped,
    MemberToJSON,
} from './Member';
import type { OrderCoupon } from './OrderCoupon';
import {
    OrderCouponFromJSON,
    OrderCouponFromJSONTyped,
    OrderCouponToJSON,
} from './OrderCoupon';
import type { OrderMarketingEvent } from './OrderMarketingEvent';
import {
    OrderMarketingEventFromJSON,
    OrderMarketingEventFromJSONTyped,
    OrderMarketingEventToJSON,
} from './OrderMarketingEvent';
import type { OrderProduct } from './OrderProduct';
import {
    OrderProductFromJSON,
    OrderProductFromJSONTyped,
    OrderProductToJSON,
} from './OrderProduct';
import type { PointRule } from './PointRule';
import {
    PointRuleFromJSON,
    PointRuleFromJSONTyped,
    PointRuleToJSON,
} from './PointRule';
import type { Shipping } from './Shipping';
import {
    ShippingFromJSON,
    ShippingFromJSONTyped,
    ShippingToJSON,
} from './Shipping';
import type { UserPointRecord } from './UserPointRecord';
import {
    UserPointRecordFromJSON,
    UserPointRecordFromJSONTyped,
    UserPointRecordToJSON,
} from './UserPointRecord';

/**
 * 
 * @export
 * @interface Order
 */
export interface Order {
    /**
     * 
     * @type {Country}
     * @memberof Order
     */
    countryByCountryId?: Country;
    /**
     * 
     * @type {number}
     * @memberof Order
     */
    countryId?: number;
    /**
     * 
     * @type {Date}
     * @memberof Order
     */
    deliveryDate2nd?: Date;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    ip?: string;
    /**
     * 
     * @type {Member}
     * @memberof Order
     */
    memberByMemberId?: Member;
    /**
     * 
     * @type {number}
     * @memberof Order
     */
    memberId?: number;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    orderAddress?: string;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    orderAddress1?: string;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    orderAddress2?: string;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    orderAddress3?: string;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    orderAddress4?: string;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    orderAddressCity?: string;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    orderAddressProvince?: string;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    orderAddressRecipient?: string;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    orderAddressZone?: string;
    /**
     * 
     * @type {Array<OrderCoupon>}
     * @memberof Order
     */
    orderCouponsByOrderId?: Array<OrderCoupon>;
    /**
     * 
     * @type {Date}
     * @memberof Order
     */
    orderDate?: Date;
    /**
     * 
     * @type {number}
     * @memberof Order
     */
    orderId?: number;
    /**
     * 
     * @type {Array<OrderMarketingEvent>}
     * @memberof Order
     */
    orderMarketingEventsByOrderId?: Array<OrderMarketingEvent>;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    orderNo?: string;
    /**
     * 
     * @type {Array<OrderProduct>}
     * @memberof Order
     */
    orderProductsByOrderId?: Array<OrderProduct>;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    orderStatus?: string;
    /**
     * 
     * @type {number}
     * @memberof Order
     */
    orderTotal?: number;
    /**
     * 
     * @type {Date}
     * @memberof Order
     */
    paidDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    paymentMethod?: string;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    phone?: string;
    /**
     * 
     * @type {PointRule}
     * @memberof Order
     */
    pointRuleByPointRuleId?: PointRule;
    /**
     * 
     * @type {number}
     * @memberof Order
     */
    pointRuleId?: number;
    /**
     * 
     * @type {number}
     * @memberof Order
     */
    productTotal?: number;
    /**
     * 
     * @type {number}
     * @memberof Order
     */
    shippingFee?: number;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    shippingMedhod?: string;
    /**
     * 
     * @type {Array<Shipping>}
     * @memberof Order
     */
    shippingsByOrderId?: Array<Shipping>;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    trackingNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    trackingNumber2nd?: string;
    /**
     * 
     * @type {number}
     * @memberof Order
     */
    usedPoint?: number;
    /**
     * 
     * @type {UserPointRecord}
     * @memberof Order
     */
    userPointRecord?: UserPointRecord;
}

/**
 * Check if a given object implements the Order interface.
 */
export function instanceOfOrder(value: object): boolean {
    return true;
}

export function OrderFromJSON(json: any): Order {
    return OrderFromJSONTyped(json, false);
}

export function OrderFromJSONTyped(json: any, ignoreDiscriminator: boolean): Order {
    if (json == null) {
        return json;
    }
    return {
        
        'countryByCountryId': json['countryByCountryId'] == null ? undefined : CountryFromJSON(json['countryByCountryId']),
        'countryId': json['countryId'] == null ? undefined : json['countryId'],
        'deliveryDate2nd': json['deliveryDate2nd'] == null ? undefined : (new Date(json['deliveryDate2nd'])),
        'ip': json['ip'] == null ? undefined : json['ip'],
        'memberByMemberId': json['memberByMemberId'] == null ? undefined : MemberFromJSON(json['memberByMemberId']),
        'memberId': json['memberId'] == null ? undefined : json['memberId'],
        'orderAddress': json['orderAddress'] == null ? undefined : json['orderAddress'],
        'orderAddress1': json['orderAddress1'] == null ? undefined : json['orderAddress1'],
        'orderAddress2': json['orderAddress2'] == null ? undefined : json['orderAddress2'],
        'orderAddress3': json['orderAddress3'] == null ? undefined : json['orderAddress3'],
        'orderAddress4': json['orderAddress4'] == null ? undefined : json['orderAddress4'],
        'orderAddressCity': json['orderAddressCity'] == null ? undefined : json['orderAddressCity'],
        'orderAddressProvince': json['orderAddressProvince'] == null ? undefined : json['orderAddressProvince'],
        'orderAddressRecipient': json['orderAddressRecipient'] == null ? undefined : json['orderAddressRecipient'],
        'orderAddressZone': json['orderAddressZone'] == null ? undefined : json['orderAddressZone'],
        'orderCouponsByOrderId': json['orderCouponsByOrderId'] == null ? undefined : ((json['orderCouponsByOrderId'] as Array<any>).map(OrderCouponFromJSON)),
        'orderDate': json['orderDate'] == null ? undefined : (new Date(json['orderDate'])),
        'orderId': json['orderId'] == null ? undefined : json['orderId'],
        'orderMarketingEventsByOrderId': json['orderMarketingEventsByOrderId'] == null ? undefined : ((json['orderMarketingEventsByOrderId'] as Array<any>).map(OrderMarketingEventFromJSON)),
        'orderNo': json['orderNo'] == null ? undefined : json['orderNo'],
        'orderProductsByOrderId': json['orderProductsByOrderId'] == null ? undefined : ((json['orderProductsByOrderId'] as Array<any>).map(OrderProductFromJSON)),
        'orderStatus': json['orderStatus'] == null ? undefined : json['orderStatus'],
        'orderTotal': json['orderTotal'] == null ? undefined : json['orderTotal'],
        'paidDate': json['paidDate'] == null ? undefined : (new Date(json['paidDate'])),
        'paymentMethod': json['paymentMethod'] == null ? undefined : json['paymentMethod'],
        'phone': json['phone'] == null ? undefined : json['phone'],
        'pointRuleByPointRuleId': json['pointRuleByPointRuleId'] == null ? undefined : PointRuleFromJSON(json['pointRuleByPointRuleId']),
        'pointRuleId': json['pointRuleId'] == null ? undefined : json['pointRuleId'],
        'productTotal': json['productTotal'] == null ? undefined : json['productTotal'],
        'shippingFee': json['shippingFee'] == null ? undefined : json['shippingFee'],
        'shippingMedhod': json['shippingMedhod'] == null ? undefined : json['shippingMedhod'],
        'shippingsByOrderId': json['shippingsByOrderId'] == null ? undefined : ((json['shippingsByOrderId'] as Array<any>).map(ShippingFromJSON)),
        'trackingNumber': json['trackingNumber'] == null ? undefined : json['trackingNumber'],
        'trackingNumber2nd': json['trackingNumber2nd'] == null ? undefined : json['trackingNumber2nd'],
        'usedPoint': json['usedPoint'] == null ? undefined : json['usedPoint'],
        'userPointRecord': json['userPointRecord'] == null ? undefined : UserPointRecordFromJSON(json['userPointRecord']),
    };
}

export function OrderToJSON(value?: Order | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'countryByCountryId': CountryToJSON(value['countryByCountryId']),
        'countryId': value['countryId'],
        'deliveryDate2nd': value['deliveryDate2nd'] == null ? undefined : ((value['deliveryDate2nd']).toISOString()),
        'ip': value['ip'],
        'memberByMemberId': MemberToJSON(value['memberByMemberId']),
        'memberId': value['memberId'],
        'orderAddress': value['orderAddress'],
        'orderAddress1': value['orderAddress1'],
        'orderAddress2': value['orderAddress2'],
        'orderAddress3': value['orderAddress3'],
        'orderAddress4': value['orderAddress4'],
        'orderAddressCity': value['orderAddressCity'],
        'orderAddressProvince': value['orderAddressProvince'],
        'orderAddressRecipient': value['orderAddressRecipient'],
        'orderAddressZone': value['orderAddressZone'],
        'orderCouponsByOrderId': value['orderCouponsByOrderId'] == null ? undefined : ((value['orderCouponsByOrderId'] as Array<any>).map(OrderCouponToJSON)),
        'orderDate': value['orderDate'] == null ? undefined : ((value['orderDate']).toISOString()),
        'orderId': value['orderId'],
        'orderMarketingEventsByOrderId': value['orderMarketingEventsByOrderId'] == null ? undefined : ((value['orderMarketingEventsByOrderId'] as Array<any>).map(OrderMarketingEventToJSON)),
        'orderNo': value['orderNo'],
        'orderProductsByOrderId': value['orderProductsByOrderId'] == null ? undefined : ((value['orderProductsByOrderId'] as Array<any>).map(OrderProductToJSON)),
        'orderStatus': value['orderStatus'],
        'orderTotal': value['orderTotal'],
        'paidDate': value['paidDate'] == null ? undefined : ((value['paidDate']).toISOString()),
        'paymentMethod': value['paymentMethod'],
        'phone': value['phone'],
        'pointRuleByPointRuleId': PointRuleToJSON(value['pointRuleByPointRuleId']),
        'pointRuleId': value['pointRuleId'],
        'productTotal': value['productTotal'],
        'shippingFee': value['shippingFee'],
        'shippingMedhod': value['shippingMedhod'],
        'shippingsByOrderId': value['shippingsByOrderId'] == null ? undefined : ((value['shippingsByOrderId'] as Array<any>).map(ShippingToJSON)),
        'trackingNumber': value['trackingNumber'],
        'trackingNumber2nd': value['trackingNumber2nd'],
        'usedPoint': value['usedPoint'],
        'userPointRecord': UserPointRecordToJSON(value['userPointRecord']),
    };
}

