/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { CartProduct } from './CartProduct';
import {
    CartProductFromJSON,
    CartProductFromJSONTyped,
    CartProductToJSON,
} from './CartProduct';
import type { DiscountProduct } from './DiscountProduct';
import {
    DiscountProductFromJSON,
    DiscountProductFromJSONTyped,
    DiscountProductToJSON,
} from './DiscountProduct';
import type { DisplayProductProduct } from './DisplayProductProduct';
import {
    DisplayProductProductFromJSON,
    DisplayProductProductFromJSONTyped,
    DisplayProductProductToJSON,
} from './DisplayProductProduct';
import type { GroupProduct } from './GroupProduct';
import {
    GroupProductFromJSON,
    GroupProductFromJSONTyped,
    GroupProductToJSON,
} from './GroupProduct';
import type { OrderProduct } from './OrderProduct';
import {
    OrderProductFromJSON,
    OrderProductFromJSONTyped,
    OrderProductToJSON,
} from './OrderProduct';
import type { Organization } from './Organization';
import {
    OrganizationFromJSON,
    OrganizationFromJSONTyped,
    OrganizationToJSON,
} from './Organization';
import type { PointRule } from './PointRule';
import {
    PointRuleFromJSON,
    PointRuleFromJSONTyped,
    PointRuleToJSON,
} from './PointRule';
import type { ProductAward } from './ProductAward';
import {
    ProductAwardFromJSON,
    ProductAwardFromJSONTyped,
    ProductAwardToJSON,
} from './ProductAward';
import type { ProductCategory } from './ProductCategory';
import {
    ProductCategoryFromJSON,
    ProductCategoryFromJSONTyped,
    ProductCategoryToJSON,
} from './ProductCategory';
import type { ProductFile } from './ProductFile';
import {
    ProductFileFromJSON,
    ProductFileFromJSONTyped,
    ProductFileToJSON,
} from './ProductFile';
import type { ProductLanguage } from './ProductLanguage';
import {
    ProductLanguageFromJSON,
    ProductLanguageFromJSONTyped,
    ProductLanguageToJSON,
} from './ProductLanguage';
import type { ProductPoint } from './ProductPoint';
import {
    ProductPointFromJSON,
    ProductPointFromJSONTyped,
    ProductPointToJSON,
} from './ProductPoint';
import type { ShippingProduct } from './ShippingProduct';
import {
    ShippingProductFromJSON,
    ShippingProductFromJSONTyped,
    ShippingProductToJSON,
} from './ShippingProduct';

/**
 * 
 * @export
 * @interface Product
 */
export interface Product {
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    author?: string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    bookComment?: string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    bookIsbn?: string;
    /**
     * 
     * @type {Array<CartProduct>}
     * @memberof Product
     */
    cartProductsByProductId?: Array<CartProduct>;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    coverFileName?: string;
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    coverFileSize?: number;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    ctsId?: string;
    /**
     * 
     * @type {Array<DiscountProduct>}
     * @memberof Product
     */
    discountProductsByProductId?: Array<DiscountProduct>;
    /**
     * 
     * @type {Array<DisplayProductProduct>}
     * @memberof Product
     */
    displayProductProductsByProductId?: Array<DisplayProductProduct>;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    ePubPreviewFileMd5?: string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    ePubPreviewFileName?: string;
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    ePubPreviewFileSize?: number;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    ePubProdFileMd5?: string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    ePubProdFileName?: string;
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    ePubProdFileSize?: number;
    /**
     * 
     * @type {boolean}
     * @memberof Product
     */
    groupProduct?: boolean;
    /**
     * 
     * @type {Array<GroupProduct>}
     * @memberof Product
     */
    groupProductsByProductId?: Array<GroupProduct>;
    /**
     * 
     * @type {Array<GroupProduct>}
     * @memberof Product
     */
    groupProductsByProductId0?: Array<GroupProduct>;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    isGroupProduct?: string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    multimediaFileNames?: string;
    /**
     * 
     * @type {Date}
     * @memberof Product
     */
    offShelfDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof Product
     */
    onShelfDate?: Date;
    /**
     * 
     * @type {Array<OrderProduct>}
     * @memberof Product
     */
    orderProductsByProductId?: Array<OrderProduct>;
    /**
     * 
     * @type {Organization}
     * @memberof Product
     */
    organizationByOrganizationId?: Organization;
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    organizationId?: number;
    /**
     * 
     * @type {Array<PointRule>}
     * @memberof Product
     */
    pointRulesByProductId?: Array<PointRule>;
    /**
     * 
     * @type {Array<ProductAward>}
     * @memberof Product
     */
    productAwardsByProductId?: Array<ProductAward>;
    /**
     * 
     * @type {Array<ProductCategory>}
     * @memberof Product
     */
    productCategoriesByProductId?: Array<ProductCategory>;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    productDescription?: string;
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    productDiscountPrice?: number;
    /**
     * 
     * @type {Array<ProductFile>}
     * @memberof Product
     */
    productFilesByProductId?: Array<ProductFile>;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    productHashtag?: string;
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    productId?: number;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    productImage?: string;
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    productInventory?: number;
    /**
     * 
     * @type {Array<ProductLanguage>}
     * @memberof Product
     */
    productLanguagesByProductId?: Array<ProductLanguage>;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    productNameChi?: string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    productNameEng?: string;
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    productOriginalPrice?: number;
    /**
     * 
     * @type {Array<ProductPoint>}
     * @memberof Product
     */
    productPointsByProductId?: Array<ProductPoint>;
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    productPrice?: number;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    productType?: string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    productVolume?: string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    productVolumeUnit?: string;
    /**
     * 
     * @type {Date}
     * @memberof Product
     */
    publishDate?: Date;
    /**
     * 
     * @type {Array<ShippingProduct>}
     * @memberof Product
     */
    shippingProductsByProductId?: Array<ShippingProduct>;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    sku?: string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    status?: string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    subscriptionPeriod?: string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    thumbFileNames?: string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    translator?: string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    version?: string;
}

/**
 * Check if a given object implements the Product interface.
 */
export function instanceOfProduct(value: object): boolean {
    return true;
}

export function ProductFromJSON(json: any): Product {
    return ProductFromJSONTyped(json, false);
}

export function ProductFromJSONTyped(json: any, ignoreDiscriminator: boolean): Product {
    if (json == null) {
        return json;
    }
    return {
        
        'author': json['author'] == null ? undefined : json['author'],
        'bookComment': json['bookComment'] == null ? undefined : json['bookComment'],
        'bookIsbn': json['bookIsbn'] == null ? undefined : json['bookIsbn'],
        'cartProductsByProductId': json['cartProductsByProductId'] == null ? undefined : ((json['cartProductsByProductId'] as Array<any>).map(CartProductFromJSON)),
        'coverFileName': json['coverFileName'] == null ? undefined : json['coverFileName'],
        'coverFileSize': json['coverFileSize'] == null ? undefined : json['coverFileSize'],
        'ctsId': json['ctsId'] == null ? undefined : json['ctsId'],
        'discountProductsByProductId': json['discountProductsByProductId'] == null ? undefined : ((json['discountProductsByProductId'] as Array<any>).map(DiscountProductFromJSON)),
        'displayProductProductsByProductId': json['displayProductProductsByProductId'] == null ? undefined : ((json['displayProductProductsByProductId'] as Array<any>).map(DisplayProductProductFromJSON)),
        'ePubPreviewFileMd5': json['ePubPreviewFileMd5'] == null ? undefined : json['ePubPreviewFileMd5'],
        'ePubPreviewFileName': json['ePubPreviewFileName'] == null ? undefined : json['ePubPreviewFileName'],
        'ePubPreviewFileSize': json['ePubPreviewFileSize'] == null ? undefined : json['ePubPreviewFileSize'],
        'ePubProdFileMd5': json['ePubProdFileMd5'] == null ? undefined : json['ePubProdFileMd5'],
        'ePubProdFileName': json['ePubProdFileName'] == null ? undefined : json['ePubProdFileName'],
        'ePubProdFileSize': json['ePubProdFileSize'] == null ? undefined : json['ePubProdFileSize'],
        'groupProduct': json['groupProduct'] == null ? undefined : json['groupProduct'],
        'groupProductsByProductId': json['groupProductsByProductId'] == null ? undefined : ((json['groupProductsByProductId'] as Array<any>).map(GroupProductFromJSON)),
        'groupProductsByProductId0': json['groupProductsByProductId_0'] == null ? undefined : ((json['groupProductsByProductId_0'] as Array<any>).map(GroupProductFromJSON)),
        'isGroupProduct': json['isGroupProduct'] == null ? undefined : json['isGroupProduct'],
        'multimediaFileNames': json['multimediaFileNames'] == null ? undefined : json['multimediaFileNames'],
        'offShelfDate': json['offShelfDate'] == null ? undefined : (new Date(json['offShelfDate'])),
        'onShelfDate': json['onShelfDate'] == null ? undefined : (new Date(json['onShelfDate'])),
        'orderProductsByProductId': json['orderProductsByProductId'] == null ? undefined : ((json['orderProductsByProductId'] as Array<any>).map(OrderProductFromJSON)),
        'organizationByOrganizationId': json['organizationByOrganizationId'] == null ? undefined : OrganizationFromJSON(json['organizationByOrganizationId']),
        'organizationId': json['organizationId'] == null ? undefined : json['organizationId'],
        'pointRulesByProductId': json['pointRulesByProductId'] == null ? undefined : ((json['pointRulesByProductId'] as Array<any>).map(PointRuleFromJSON)),
        'productAwardsByProductId': json['productAwardsByProductId'] == null ? undefined : ((json['productAwardsByProductId'] as Array<any>).map(ProductAwardFromJSON)),
        'productCategoriesByProductId': json['productCategoriesByProductId'] == null ? undefined : ((json['productCategoriesByProductId'] as Array<any>).map(ProductCategoryFromJSON)),
        'productDescription': json['productDescription'] == null ? undefined : json['productDescription'],
        'productDiscountPrice': json['productDiscountPrice'] == null ? undefined : json['productDiscountPrice'],
        'productFilesByProductId': json['productFilesByProductId'] == null ? undefined : ((json['productFilesByProductId'] as Array<any>).map(ProductFileFromJSON)),
        'productHashtag': json['productHashtag'] == null ? undefined : json['productHashtag'],
        'productId': json['productId'] == null ? undefined : json['productId'],
        'productImage': json['productImage'] == null ? undefined : json['productImage'],
        'productInventory': json['productInventory'] == null ? undefined : json['productInventory'],
        'productLanguagesByProductId': json['productLanguagesByProductId'] == null ? undefined : ((json['productLanguagesByProductId'] as Array<any>).map(ProductLanguageFromJSON)),
        'productNameChi': json['productNameChi'] == null ? undefined : json['productNameChi'],
        'productNameEng': json['productNameEng'] == null ? undefined : json['productNameEng'],
        'productOriginalPrice': json['productOriginalPrice'] == null ? undefined : json['productOriginalPrice'],
        'productPointsByProductId': json['productPointsByProductId'] == null ? undefined : ((json['productPointsByProductId'] as Array<any>).map(ProductPointFromJSON)),
        'productPrice': json['productPrice'] == null ? undefined : json['productPrice'],
        'productType': json['productType'] == null ? undefined : json['productType'],
        'productVolume': json['productVolume'] == null ? undefined : json['productVolume'],
        'productVolumeUnit': json['productVolumeUnit'] == null ? undefined : json['productVolumeUnit'],
        'publishDate': json['publishDate'] == null ? undefined : (new Date(json['publishDate'])),
        'shippingProductsByProductId': json['shippingProductsByProductId'] == null ? undefined : ((json['shippingProductsByProductId'] as Array<any>).map(ShippingProductFromJSON)),
        'sku': json['sku'] == null ? undefined : json['sku'],
        'status': json['status'] == null ? undefined : json['status'],
        'subscriptionPeriod': json['subscriptionPeriod'] == null ? undefined : json['subscriptionPeriod'],
        'thumbFileNames': json['thumbFileNames'] == null ? undefined : json['thumbFileNames'],
        'translator': json['translator'] == null ? undefined : json['translator'],
        'version': json['version'] == null ? undefined : json['version'],
    };
}

export function ProductToJSON(value?: Product | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'author': value['author'],
        'bookComment': value['bookComment'],
        'bookIsbn': value['bookIsbn'],
        'cartProductsByProductId': value['cartProductsByProductId'] == null ? undefined : ((value['cartProductsByProductId'] as Array<any>).map(CartProductToJSON)),
        'coverFileName': value['coverFileName'],
        'coverFileSize': value['coverFileSize'],
        'ctsId': value['ctsId'],
        'discountProductsByProductId': value['discountProductsByProductId'] == null ? undefined : ((value['discountProductsByProductId'] as Array<any>).map(DiscountProductToJSON)),
        'displayProductProductsByProductId': value['displayProductProductsByProductId'] == null ? undefined : ((value['displayProductProductsByProductId'] as Array<any>).map(DisplayProductProductToJSON)),
        'ePubPreviewFileMd5': value['ePubPreviewFileMd5'],
        'ePubPreviewFileName': value['ePubPreviewFileName'],
        'ePubPreviewFileSize': value['ePubPreviewFileSize'],
        'ePubProdFileMd5': value['ePubProdFileMd5'],
        'ePubProdFileName': value['ePubProdFileName'],
        'ePubProdFileSize': value['ePubProdFileSize'],
        'groupProduct': value['groupProduct'],
        'groupProductsByProductId': value['groupProductsByProductId'] == null ? undefined : ((value['groupProductsByProductId'] as Array<any>).map(GroupProductToJSON)),
        'groupProductsByProductId_0': value['groupProductsByProductId0'] == null ? undefined : ((value['groupProductsByProductId0'] as Array<any>).map(GroupProductToJSON)),
        'isGroupProduct': value['isGroupProduct'],
        'multimediaFileNames': value['multimediaFileNames'],
        'offShelfDate': value['offShelfDate'] == null ? undefined : ((value['offShelfDate']).toISOString()),
        'onShelfDate': value['onShelfDate'] == null ? undefined : ((value['onShelfDate']).toISOString()),
        'orderProductsByProductId': value['orderProductsByProductId'] == null ? undefined : ((value['orderProductsByProductId'] as Array<any>).map(OrderProductToJSON)),
        'organizationByOrganizationId': OrganizationToJSON(value['organizationByOrganizationId']),
        'organizationId': value['organizationId'],
        'pointRulesByProductId': value['pointRulesByProductId'] == null ? undefined : ((value['pointRulesByProductId'] as Array<any>).map(PointRuleToJSON)),
        'productAwardsByProductId': value['productAwardsByProductId'] == null ? undefined : ((value['productAwardsByProductId'] as Array<any>).map(ProductAwardToJSON)),
        'productCategoriesByProductId': value['productCategoriesByProductId'] == null ? undefined : ((value['productCategoriesByProductId'] as Array<any>).map(ProductCategoryToJSON)),
        'productDescription': value['productDescription'],
        'productDiscountPrice': value['productDiscountPrice'],
        'productFilesByProductId': value['productFilesByProductId'] == null ? undefined : ((value['productFilesByProductId'] as Array<any>).map(ProductFileToJSON)),
        'productHashtag': value['productHashtag'],
        'productId': value['productId'],
        'productImage': value['productImage'],
        'productInventory': value['productInventory'],
        'productLanguagesByProductId': value['productLanguagesByProductId'] == null ? undefined : ((value['productLanguagesByProductId'] as Array<any>).map(ProductLanguageToJSON)),
        'productNameChi': value['productNameChi'],
        'productNameEng': value['productNameEng'],
        'productOriginalPrice': value['productOriginalPrice'],
        'productPointsByProductId': value['productPointsByProductId'] == null ? undefined : ((value['productPointsByProductId'] as Array<any>).map(ProductPointToJSON)),
        'productPrice': value['productPrice'],
        'productType': value['productType'],
        'productVolume': value['productVolume'],
        'productVolumeUnit': value['productVolumeUnit'],
        'publishDate': value['publishDate'] == null ? undefined : ((value['publishDate']).toISOString()),
        'shippingProductsByProductId': value['shippingProductsByProductId'] == null ? undefined : ((value['shippingProductsByProductId'] as Array<any>).map(ShippingProductToJSON)),
        'sku': value['sku'],
        'status': value['status'],
        'subscriptionPeriod': value['subscriptionPeriod'],
        'thumbFileNames': value['thumbFileNames'],
        'translator': value['translator'],
        'version': value['version'],
    };
}

