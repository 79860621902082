/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  MemberShoppingCartDto,
  OrderDto,
  OrderPointRecordDto,
  Orderedmember,
  RedirectView,
  Resource,
} from '../models/index';
import {
    MemberShoppingCartDtoFromJSON,
    MemberShoppingCartDtoToJSON,
    OrderDtoFromJSON,
    OrderDtoToJSON,
    OrderPointRecordDtoFromJSON,
    OrderPointRecordDtoToJSON,
    OrderedmemberFromJSON,
    OrderedmemberToJSON,
    RedirectViewFromJSON,
    RedirectViewToJSON,
    ResourceFromJSON,
    ResourceToJSON,
} from '../models/index';

export interface AddGustOrderUsingPOSTRequest {
    countryId: number;
    productListJson: string;
    shippingMethod: string;
    phone: string;
    orderAddress?: string;
    orderAddressRecipient?: string;
    orderAddressProvince?: string;
    orderAddressCity?: string;
    orderAddressZone?: string;
    orderAddress1?: string;
    orderAddress2?: string;
    orderAddress3?: string;
    orderAddress4?: string;
    couponCode?: string;
    promotionCode?: string;
}

export interface AddMemberOrderUsingPOSTRequest {
    countryId: number;
    productId: number;
    quantity: number;
    shippingMethod: string;
    name?: string;
    orderAddress?: string;
    orderAddressRecipient?: string;
    orderAddressProvince?: string;
    orderAddressCity?: string;
    orderAddressZone?: string;
    orderAddress1?: string;
    orderAddress2?: string;
    orderAddress3?: string;
    orderAddress4?: string;
    promotionCode?: string;
    deliveryTimes?: number;
}

export interface AddOrderUsingPOSTRequest {
    countryId: number;
    shippingMethod: string;
    name?: string;
    orderAddress?: string;
    orderAddressRecipient?: string;
    orderAddressProvince?: string;
    orderAddressCity?: string;
    orderAddressZone?: string;
    orderAddress1?: string;
    orderAddress2?: string;
    orderAddress3?: string;
    orderAddress4?: string;
    pointRuleId?: number;
    pointToCash?: number;
    phone?: string;
}

export interface CheckOrderByOrderNoAndPhoneUsingPOSTRequest {
    orderNo: string;
    phone: string;
}

export interface CitylineDatafeedUsingDELETERequest {
    params: { [key: string]: string; };
}

export interface CitylineDatafeedUsingGETRequest {
    params: { [key: string]: string; };
}

export interface CitylineDatafeedUsingHEADRequest {
    params: { [key: string]: string; };
}

export interface CitylineDatafeedUsingOPTIONSRequest {
    params: { [key: string]: string; };
}

export interface CitylineDatafeedUsingPATCHRequest {
    params: { [key: string]: string; };
}

export interface CitylineDatafeedUsingPOSTRequest {
    params: { [key: string]: string; };
}

export interface CitylineDatafeedUsingPUTRequest {
    params: { [key: string]: string; };
}

export interface CitylineDatafeedUsingTRACERequest {
    params: { [key: string]: string; };
}

export interface DeleteArticleUsingPOST1Request {
    orderId: number;
    name?: string;
}

export interface GetAllOrderAdminUsingGETRequest {
    name?: string;
}

export interface GetAllOrderByMemberUsingGETRequest {
    name?: string;
}

export interface GetAllOrderWithGroupProductAdminUsingGETRequest {
    name?: string;
}

export interface GetGuestOrderByUsingGETRequest {
    orderId: number;
    phone: string;
}

export interface GetMemberOrderByOrderNoUsingGETRequest {
    orderNo: string;
    name?: string;
}

export interface GetMemberOrderUsingGETRequest {
    orderId: number;
    name?: string;
}

export interface GetMemberPointRecordUsingGETRequest {
    name?: string;
}

export interface GetOrderAdminUsingGETRequest {
    orderId: number;
    name?: string;
}

export interface GetOrderXlsAdminUsingGETRequest {
    orderId: number;
    name?: string;
}

export interface GetOrderedmemberUsingGETRequest {
    productId?: number;
}

export interface GetPagedOrderWidthGroupProductByMemberUsingGETRequest {
    page: string;
    size: string;
    name?: string;
}

export interface GlobalPaymentsDatafeedUsingDELETERequest {
    params: { [key: string]: string; };
}

export interface GlobalPaymentsDatafeedUsingGETRequest {
    params: { [key: string]: string; };
}

export interface GlobalPaymentsDatafeedUsingHEADRequest {
    params: { [key: string]: string; };
}

export interface GlobalPaymentsDatafeedUsingOPTIONSRequest {
    params: { [key: string]: string; };
}

export interface GlobalPaymentsDatafeedUsingPATCHRequest {
    params: { [key: string]: string; };
}

export interface GlobalPaymentsDatafeedUsingPOSTRequest {
    params: { [key: string]: string; };
}

export interface GlobalPaymentsDatafeedUsingPUTRequest {
    params: { [key: string]: string; };
}

export interface GlobalPaymentsDatafeedUsingTRACERequest {
    params: { [key: string]: string; };
}

export interface MakeCLDecisionUsingPOSTRequest {
    params: { [key: string]: string; };
}

export interface MakeGPDecisionUsingPOSTRequest {
    params: { [key: string]: string; };
}

export interface MastercardDatafeedUsingDELETERequest {
    params: { [key: string]: string; };
}

export interface MastercardDatafeedUsingGETRequest {
    params: { [key: string]: string; };
}

export interface MastercardDatafeedUsingHEADRequest {
    params: { [key: string]: string; };
}

export interface MastercardDatafeedUsingOPTIONSRequest {
    params: { [key: string]: string; };
}

export interface MastercardDatafeedUsingPATCHRequest {
    params: { [key: string]: string; };
}

export interface MastercardDatafeedUsingPOSTRequest {
    params: { [key: string]: string; };
}

export interface MastercardDatafeedUsingPUTRequest {
    params: { [key: string]: string; };
}

export interface MastercardDatafeedUsingTRACERequest {
    params: { [key: string]: string; };
}

export interface OrderStatusUpdateUsingDELETERequest {
    params: { [key: string]: string; };
    name?: string;
}

export interface OrderStatusUpdateUsingGETRequest {
    params: { [key: string]: string; };
    name?: string;
}

export interface OrderStatusUpdateUsingHEADRequest {
    params: { [key: string]: string; };
    name?: string;
}

export interface OrderStatusUpdateUsingOPTIONSRequest {
    params: { [key: string]: string; };
    name?: string;
}

export interface OrderStatusUpdateUsingPATCHRequest {
    params: { [key: string]: string; };
    name?: string;
}

export interface OrderStatusUpdateUsingPOSTRequest {
    params: { [key: string]: string; };
    name?: string;
}

export interface OrderStatusUpdateUsingPUTRequest {
    params: { [key: string]: string; };
    name?: string;
}

export interface OrderStatusUpdateUsingTRACERequest {
    params: { [key: string]: string; };
    name?: string;
}

export interface PreGustOrder2UsingPOSTRequest {
    productListJson: string;
    couponCode?: string;
    promotionCode?: string;
}

export interface PreGustOrderUsingPOSTRequest {
    productListJson: string;
    couponCode?: string;
    promotionCode?: string;
}

export interface PreMemberOrderUsingPOSTRequest {
    productId: number;
    quantity: number;
    name?: string;
    promotionCode?: string;
    deliveryTimes?: number;
}

export interface RebuyOrderUsingGETRequest {
    orderId: number;
    name?: string;
}

export interface SearchUsingPOSTRequest {
    orderNo?: string;
    memberId?: number;
    orderStatus?: string;
    body?: Date;
}

export interface SuccessOrderHandleUsingGETRequest {
    orderId: number;
    paymentMethod: string;
    orderStatus: string;
}

export interface UpdateOrderStatusUsingPOSTRequest {
    orderId: number;
    orderStatus: string;
}

export interface UpdateTrackingNumber2ndUsingPOSTRequest {
    orderId: number;
    trackingNumber2nd: string;
    deliveryDate2nd: Date;
    name?: string;
}

export interface UpdateTrackingNumberUsingPOSTRequest {
    orderId: number;
    trackingNumber: string;
    name?: string;
}

/**
 * 
 */
export class OrderControllerApi extends runtime.BaseAPI {

    /**
     * addGustOrder
     */
    async addGustOrderUsingPOSTRaw(requestParameters: AddGustOrderUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        if (requestParameters['countryId'] == null) {
            throw new runtime.RequiredError(
                'countryId',
                'Required parameter "countryId" was null or undefined when calling addGustOrderUsingPOST().'
            );
        }

        if (requestParameters['productListJson'] == null) {
            throw new runtime.RequiredError(
                'productListJson',
                'Required parameter "productListJson" was null or undefined when calling addGustOrderUsingPOST().'
            );
        }

        if (requestParameters['shippingMethod'] == null) {
            throw new runtime.RequiredError(
                'shippingMethod',
                'Required parameter "shippingMethod" was null or undefined when calling addGustOrderUsingPOST().'
            );
        }

        if (requestParameters['phone'] == null) {
            throw new runtime.RequiredError(
                'phone',
                'Required parameter "phone" was null or undefined when calling addGustOrderUsingPOST().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['countryId'] != null) {
            queryParameters['countryId'] = requestParameters['countryId'];
        }

        if (requestParameters['orderAddress'] != null) {
            queryParameters['orderAddress'] = requestParameters['orderAddress'];
        }

        if (requestParameters['orderAddressRecipient'] != null) {
            queryParameters['orderAddressRecipient'] = requestParameters['orderAddressRecipient'];
        }

        if (requestParameters['orderAddressProvince'] != null) {
            queryParameters['orderAddressProvince'] = requestParameters['orderAddressProvince'];
        }

        if (requestParameters['orderAddressCity'] != null) {
            queryParameters['orderAddressCity'] = requestParameters['orderAddressCity'];
        }

        if (requestParameters['orderAddressZone'] != null) {
            queryParameters['orderAddressZone'] = requestParameters['orderAddressZone'];
        }

        if (requestParameters['orderAddress1'] != null) {
            queryParameters['orderAddress1'] = requestParameters['orderAddress1'];
        }

        if (requestParameters['orderAddress2'] != null) {
            queryParameters['orderAddress2'] = requestParameters['orderAddress2'];
        }

        if (requestParameters['orderAddress3'] != null) {
            queryParameters['orderAddress3'] = requestParameters['orderAddress3'];
        }

        if (requestParameters['orderAddress4'] != null) {
            queryParameters['orderAddress4'] = requestParameters['orderAddress4'];
        }

        if (requestParameters['productListJson'] != null) {
            queryParameters['productListJson'] = requestParameters['productListJson'];
        }

        if (requestParameters['couponCode'] != null) {
            queryParameters['couponCode'] = requestParameters['couponCode'];
        }

        if (requestParameters['promotionCode'] != null) {
            queryParameters['promotionCode'] = requestParameters['promotionCode'];
        }

        if (requestParameters['shippingMethod'] != null) {
            queryParameters['shippingMethod'] = requestParameters['shippingMethod'];
        }

        if (requestParameters['phone'] != null) {
            queryParameters['phone'] = requestParameters['phone'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/order/guest/add`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * addGustOrder
     */
    async addGustOrderUsingPOST(requestParameters: AddGustOrderUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object | null | undefined > {
        const response = await this.addGustOrderUsingPOSTRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 201:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * addMemberOrder
     */
    async addMemberOrderUsingPOSTRaw(requestParameters: AddMemberOrderUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        if (requestParameters['countryId'] == null) {
            throw new runtime.RequiredError(
                'countryId',
                'Required parameter "countryId" was null or undefined when calling addMemberOrderUsingPOST().'
            );
        }

        if (requestParameters['productId'] == null) {
            throw new runtime.RequiredError(
                'productId',
                'Required parameter "productId" was null or undefined when calling addMemberOrderUsingPOST().'
            );
        }

        if (requestParameters['quantity'] == null) {
            throw new runtime.RequiredError(
                'quantity',
                'Required parameter "quantity" was null or undefined when calling addMemberOrderUsingPOST().'
            );
        }

        if (requestParameters['shippingMethod'] == null) {
            throw new runtime.RequiredError(
                'shippingMethod',
                'Required parameter "shippingMethod" was null or undefined when calling addMemberOrderUsingPOST().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        if (requestParameters['countryId'] != null) {
            queryParameters['countryId'] = requestParameters['countryId'];
        }

        if (requestParameters['orderAddress'] != null) {
            queryParameters['orderAddress'] = requestParameters['orderAddress'];
        }

        if (requestParameters['orderAddressRecipient'] != null) {
            queryParameters['orderAddressRecipient'] = requestParameters['orderAddressRecipient'];
        }

        if (requestParameters['orderAddressProvince'] != null) {
            queryParameters['orderAddressProvince'] = requestParameters['orderAddressProvince'];
        }

        if (requestParameters['orderAddressCity'] != null) {
            queryParameters['orderAddressCity'] = requestParameters['orderAddressCity'];
        }

        if (requestParameters['orderAddressZone'] != null) {
            queryParameters['orderAddressZone'] = requestParameters['orderAddressZone'];
        }

        if (requestParameters['orderAddress1'] != null) {
            queryParameters['orderAddress1'] = requestParameters['orderAddress1'];
        }

        if (requestParameters['orderAddress2'] != null) {
            queryParameters['orderAddress2'] = requestParameters['orderAddress2'];
        }

        if (requestParameters['orderAddress3'] != null) {
            queryParameters['orderAddress3'] = requestParameters['orderAddress3'];
        }

        if (requestParameters['orderAddress4'] != null) {
            queryParameters['orderAddress4'] = requestParameters['orderAddress4'];
        }

        if (requestParameters['productId'] != null) {
            queryParameters['productId'] = requestParameters['productId'];
        }

        if (requestParameters['quantity'] != null) {
            queryParameters['quantity'] = requestParameters['quantity'];
        }

        if (requestParameters['promotionCode'] != null) {
            queryParameters['promotionCode'] = requestParameters['promotionCode'];
        }

        if (requestParameters['shippingMethod'] != null) {
            queryParameters['shippingMethod'] = requestParameters['shippingMethod'];
        }

        if (requestParameters['deliveryTimes'] != null) {
            queryParameters['deliveryTimes'] = requestParameters['deliveryTimes'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/order/member/add`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * addMemberOrder
     */
    async addMemberOrderUsingPOST(requestParameters: AddMemberOrderUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object | null | undefined > {
        const response = await this.addMemberOrderUsingPOSTRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 201:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * addOrder
     */
    async addOrderUsingPOSTRaw(requestParameters: AddOrderUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        if (requestParameters['countryId'] == null) {
            throw new runtime.RequiredError(
                'countryId',
                'Required parameter "countryId" was null or undefined when calling addOrderUsingPOST().'
            );
        }

        if (requestParameters['shippingMethod'] == null) {
            throw new runtime.RequiredError(
                'shippingMethod',
                'Required parameter "shippingMethod" was null or undefined when calling addOrderUsingPOST().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        if (requestParameters['countryId'] != null) {
            queryParameters['countryId'] = requestParameters['countryId'];
        }

        if (requestParameters['orderAddress'] != null) {
            queryParameters['orderAddress'] = requestParameters['orderAddress'];
        }

        if (requestParameters['orderAddressRecipient'] != null) {
            queryParameters['orderAddressRecipient'] = requestParameters['orderAddressRecipient'];
        }

        if (requestParameters['orderAddressProvince'] != null) {
            queryParameters['orderAddressProvince'] = requestParameters['orderAddressProvince'];
        }

        if (requestParameters['orderAddressCity'] != null) {
            queryParameters['orderAddressCity'] = requestParameters['orderAddressCity'];
        }

        if (requestParameters['orderAddressZone'] != null) {
            queryParameters['orderAddressZone'] = requestParameters['orderAddressZone'];
        }

        if (requestParameters['orderAddress1'] != null) {
            queryParameters['orderAddress1'] = requestParameters['orderAddress1'];
        }

        if (requestParameters['orderAddress2'] != null) {
            queryParameters['orderAddress2'] = requestParameters['orderAddress2'];
        }

        if (requestParameters['orderAddress3'] != null) {
            queryParameters['orderAddress3'] = requestParameters['orderAddress3'];
        }

        if (requestParameters['orderAddress4'] != null) {
            queryParameters['orderAddress4'] = requestParameters['orderAddress4'];
        }

        if (requestParameters['pointRuleId'] != null) {
            queryParameters['pointRuleId'] = requestParameters['pointRuleId'];
        }

        if (requestParameters['pointToCash'] != null) {
            queryParameters['pointToCash'] = requestParameters['pointToCash'];
        }

        if (requestParameters['shippingMethod'] != null) {
            queryParameters['shippingMethod'] = requestParameters['shippingMethod'];
        }

        if (requestParameters['phone'] != null) {
            queryParameters['phone'] = requestParameters['phone'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/order/add`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * addOrder
     */
    async addOrderUsingPOST(requestParameters: AddOrderUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object | null | undefined > {
        const response = await this.addOrderUsingPOSTRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 201:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * checkOrderByOrderNoAndPhone
     */
    async checkOrderByOrderNoAndPhoneUsingPOSTRaw(requestParameters: CheckOrderByOrderNoAndPhoneUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<OrderDto>>> {
        if (requestParameters['orderNo'] == null) {
            throw new runtime.RequiredError(
                'orderNo',
                'Required parameter "orderNo" was null or undefined when calling checkOrderByOrderNoAndPhoneUsingPOST().'
            );
        }

        if (requestParameters['phone'] == null) {
            throw new runtime.RequiredError(
                'phone',
                'Required parameter "phone" was null or undefined when calling checkOrderByOrderNoAndPhoneUsingPOST().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['orderNo'] != null) {
            queryParameters['orderNo'] = requestParameters['orderNo'];
        }

        if (requestParameters['phone'] != null) {
            queryParameters['phone'] = requestParameters['phone'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/order/checking`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(OrderDtoFromJSON));
    }

    /**
     * checkOrderByOrderNoAndPhone
     */
    async checkOrderByOrderNoAndPhoneUsingPOST(requestParameters: CheckOrderByOrderNoAndPhoneUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<OrderDto> | null | undefined > {
        const response = await this.checkOrderByOrderNoAndPhoneUsingPOSTRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 201:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * citylineDatafeed
     */
    async citylineDatafeedUsingDELETERaw(requestParameters: CitylineDatafeedUsingDELETERequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        if (requestParameters['params'] == null) {
            throw new runtime.RequiredError(
                'params',
                'Required parameter "params" was null or undefined when calling citylineDatafeedUsingDELETE().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['params'] != null) {
            queryParameters['params'] = requestParameters['params'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/order/cityline/datafeed`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * citylineDatafeed
     */
    async citylineDatafeedUsingDELETE(requestParameters: CitylineDatafeedUsingDELETERequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object | null | undefined > {
        const response = await this.citylineDatafeedUsingDELETERaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 204:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * citylineDatafeed
     */
    async citylineDatafeedUsingGETRaw(requestParameters: CitylineDatafeedUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        if (requestParameters['params'] == null) {
            throw new runtime.RequiredError(
                'params',
                'Required parameter "params" was null or undefined when calling citylineDatafeedUsingGET().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['params'] != null) {
            queryParameters['params'] = requestParameters['params'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/order/cityline/datafeed`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * citylineDatafeed
     */
    async citylineDatafeedUsingGET(requestParameters: CitylineDatafeedUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object> {
        const response = await this.citylineDatafeedUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * citylineDatafeed
     */
    async citylineDatafeedUsingHEADRaw(requestParameters: CitylineDatafeedUsingHEADRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        if (requestParameters['params'] == null) {
            throw new runtime.RequiredError(
                'params',
                'Required parameter "params" was null or undefined when calling citylineDatafeedUsingHEAD().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['params'] != null) {
            queryParameters['params'] = requestParameters['params'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/order/cityline/datafeed`,
            method: 'HEAD',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * citylineDatafeed
     */
    async citylineDatafeedUsingHEAD(requestParameters: CitylineDatafeedUsingHEADRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object | null | undefined > {
        const response = await this.citylineDatafeedUsingHEADRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 204:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * citylineDatafeed
     */
    async citylineDatafeedUsingOPTIONSRaw(requestParameters: CitylineDatafeedUsingOPTIONSRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        if (requestParameters['params'] == null) {
            throw new runtime.RequiredError(
                'params',
                'Required parameter "params" was null or undefined when calling citylineDatafeedUsingOPTIONS().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['params'] != null) {
            queryParameters['params'] = requestParameters['params'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/order/cityline/datafeed`,
            method: 'OPTIONS',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * citylineDatafeed
     */
    async citylineDatafeedUsingOPTIONS(requestParameters: CitylineDatafeedUsingOPTIONSRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object | null | undefined > {
        const response = await this.citylineDatafeedUsingOPTIONSRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 204:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * citylineDatafeed
     */
    async citylineDatafeedUsingPATCHRaw(requestParameters: CitylineDatafeedUsingPATCHRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        if (requestParameters['params'] == null) {
            throw new runtime.RequiredError(
                'params',
                'Required parameter "params" was null or undefined when calling citylineDatafeedUsingPATCH().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['params'] != null) {
            queryParameters['params'] = requestParameters['params'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/order/cityline/datafeed`,
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * citylineDatafeed
     */
    async citylineDatafeedUsingPATCH(requestParameters: CitylineDatafeedUsingPATCHRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object | null | undefined > {
        const response = await this.citylineDatafeedUsingPATCHRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 204:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * citylineDatafeed
     */
    async citylineDatafeedUsingPOSTRaw(requestParameters: CitylineDatafeedUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        if (requestParameters['params'] == null) {
            throw new runtime.RequiredError(
                'params',
                'Required parameter "params" was null or undefined when calling citylineDatafeedUsingPOST().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['params'] != null) {
            queryParameters['params'] = requestParameters['params'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/order/cityline/datafeed`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * citylineDatafeed
     */
    async citylineDatafeedUsingPOST(requestParameters: CitylineDatafeedUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object | null | undefined > {
        const response = await this.citylineDatafeedUsingPOSTRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 201:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * citylineDatafeed
     */
    async citylineDatafeedUsingPUTRaw(requestParameters: CitylineDatafeedUsingPUTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        if (requestParameters['params'] == null) {
            throw new runtime.RequiredError(
                'params',
                'Required parameter "params" was null or undefined when calling citylineDatafeedUsingPUT().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['params'] != null) {
            queryParameters['params'] = requestParameters['params'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/order/cityline/datafeed`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * citylineDatafeed
     */
    async citylineDatafeedUsingPUT(requestParameters: CitylineDatafeedUsingPUTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object | null | undefined > {
        const response = await this.citylineDatafeedUsingPUTRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 201:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * citylineDatafeed
     */
    async citylineDatafeedUsingTRACERaw(requestParameters: CitylineDatafeedUsingTRACERequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        if (requestParameters['params'] == null) {
            throw new runtime.RequiredError(
                'params',
                'Required parameter "params" was null or undefined when calling citylineDatafeedUsingTRACE().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['params'] != null) {
            queryParameters['params'] = requestParameters['params'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/order/cityline/datafeed`,
            method: 'TRACE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * citylineDatafeed
     */
    async citylineDatafeedUsingTRACE(requestParameters: CitylineDatafeedUsingTRACERequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object | null | undefined > {
        const response = await this.citylineDatafeedUsingTRACERaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 204:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * deleteArticle
     */
    async deleteArticleUsingPOST1Raw(requestParameters: DeleteArticleUsingPOST1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OrderDto>> {
        if (requestParameters['orderId'] == null) {
            throw new runtime.RequiredError(
                'orderId',
                'Required parameter "orderId" was null or undefined when calling deleteArticleUsingPOST1().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/order/delete/{orderId}`.replace(`{${"orderId"}}`, encodeURIComponent(String(requestParameters['orderId']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OrderDtoFromJSON(jsonValue));
    }

    /**
     * deleteArticle
     */
    async deleteArticleUsingPOST1(requestParameters: DeleteArticleUsingPOST1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OrderDto | null | undefined > {
        const response = await this.deleteArticleUsingPOST1Raw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 201:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * getAllOrderAdmin
     */
    async getAllOrderAdminUsingGETRaw(requestParameters: GetAllOrderAdminUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<OrderDto>>> {
        const queryParameters: any = {};

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/order/admin/all`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(OrderDtoFromJSON));
    }

    /**
     * getAllOrderAdmin
     */
    async getAllOrderAdminUsingGET(requestParameters: GetAllOrderAdminUsingGETRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<OrderDto>> {
        const response = await this.getAllOrderAdminUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getAllOrderByMember
     */
    async getAllOrderByMemberUsingGETRaw(requestParameters: GetAllOrderByMemberUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<OrderDto>>> {
        const queryParameters: any = {};

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/order/member`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(OrderDtoFromJSON));
    }

    /**
     * getAllOrderByMember
     */
    async getAllOrderByMemberUsingGET(requestParameters: GetAllOrderByMemberUsingGETRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<OrderDto>> {
        const response = await this.getAllOrderByMemberUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getAllOrderWithGroupProductAdmin
     */
    async getAllOrderWithGroupProductAdminUsingGETRaw(requestParameters: GetAllOrderWithGroupProductAdminUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<OrderDto>>> {
        const queryParameters: any = {};

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/order/admin/all/withGroupProduct`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(OrderDtoFromJSON));
    }

    /**
     * getAllOrderWithGroupProductAdmin
     */
    async getAllOrderWithGroupProductAdminUsingGET(requestParameters: GetAllOrderWithGroupProductAdminUsingGETRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<OrderDto>> {
        const response = await this.getAllOrderWithGroupProductAdminUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getGuestOrderBy
     */
    async getGuestOrderByUsingGETRaw(requestParameters: GetGuestOrderByUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OrderDto>> {
        if (requestParameters['orderId'] == null) {
            throw new runtime.RequiredError(
                'orderId',
                'Required parameter "orderId" was null or undefined when calling getGuestOrderByUsingGET().'
            );
        }

        if (requestParameters['phone'] == null) {
            throw new runtime.RequiredError(
                'phone',
                'Required parameter "phone" was null or undefined when calling getGuestOrderByUsingGET().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['phone'] != null) {
            queryParameters['phone'] = requestParameters['phone'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/order/guest/{orderId}`.replace(`{${"orderId"}}`, encodeURIComponent(String(requestParameters['orderId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OrderDtoFromJSON(jsonValue));
    }

    /**
     * getGuestOrderBy
     */
    async getGuestOrderByUsingGET(requestParameters: GetGuestOrderByUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OrderDto> {
        const response = await this.getGuestOrderByUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getMemberOrderByOrderNo
     */
    async getMemberOrderByOrderNoUsingGETRaw(requestParameters: GetMemberOrderByOrderNoUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OrderDto>> {
        if (requestParameters['orderNo'] == null) {
            throw new runtime.RequiredError(
                'orderNo',
                'Required parameter "orderNo" was null or undefined when calling getMemberOrderByOrderNoUsingGET().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/order/member2/{orderNo}`.replace(`{${"orderNo"}}`, encodeURIComponent(String(requestParameters['orderNo']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OrderDtoFromJSON(jsonValue));
    }

    /**
     * getMemberOrderByOrderNo
     */
    async getMemberOrderByOrderNoUsingGET(requestParameters: GetMemberOrderByOrderNoUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OrderDto> {
        const response = await this.getMemberOrderByOrderNoUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getMemberOrder
     */
    async getMemberOrderUsingGETRaw(requestParameters: GetMemberOrderUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OrderDto>> {
        if (requestParameters['orderId'] == null) {
            throw new runtime.RequiredError(
                'orderId',
                'Required parameter "orderId" was null or undefined when calling getMemberOrderUsingGET().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/order/member/{orderId}`.replace(`{${"orderId"}}`, encodeURIComponent(String(requestParameters['orderId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OrderDtoFromJSON(jsonValue));
    }

    /**
     * getMemberOrder
     */
    async getMemberOrderUsingGET(requestParameters: GetMemberOrderUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OrderDto> {
        const response = await this.getMemberOrderUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getMemberPointRecord
     */
    async getMemberPointRecordUsingGETRaw(requestParameters: GetMemberPointRecordUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<OrderPointRecordDto>>> {
        const queryParameters: any = {};

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/order/member/pointRecord`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(OrderPointRecordDtoFromJSON));
    }

    /**
     * getMemberPointRecord
     */
    async getMemberPointRecordUsingGET(requestParameters: GetMemberPointRecordUsingGETRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<OrderPointRecordDto>> {
        const response = await this.getMemberPointRecordUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getOrderAdmin
     */
    async getOrderAdminUsingGETRaw(requestParameters: GetOrderAdminUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OrderDto>> {
        if (requestParameters['orderId'] == null) {
            throw new runtime.RequiredError(
                'orderId',
                'Required parameter "orderId" was null or undefined when calling getOrderAdminUsingGET().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/order/admin/{orderId}`.replace(`{${"orderId"}}`, encodeURIComponent(String(requestParameters['orderId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OrderDtoFromJSON(jsonValue));
    }

    /**
     * getOrderAdmin
     */
    async getOrderAdminUsingGET(requestParameters: GetOrderAdminUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OrderDto> {
        const response = await this.getOrderAdminUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getOrderXlsAdmin
     */
    async getOrderXlsAdminUsingGETRaw(requestParameters: GetOrderXlsAdminUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Resource>> {
        if (requestParameters['orderId'] == null) {
            throw new runtime.RequiredError(
                'orderId',
                'Required parameter "orderId" was null or undefined when calling getOrderXlsAdminUsingGET().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/order/admin/xls/{orderId}`.replace(`{${"orderId"}}`, encodeURIComponent(String(requestParameters['orderId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResourceFromJSON(jsonValue));
    }

    /**
     * getOrderXlsAdmin
     */
    async getOrderXlsAdminUsingGET(requestParameters: GetOrderXlsAdminUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Resource> {
        const response = await this.getOrderXlsAdminUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getOrderedmember
     */
    async getOrderedmemberUsingGETRaw(requestParameters: GetOrderedmemberUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Orderedmember>>> {
        const queryParameters: any = {};

        if (requestParameters['productId'] != null) {
            queryParameters['productId'] = requestParameters['productId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/order/getOrderedmember`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(OrderedmemberFromJSON));
    }

    /**
     * getOrderedmember
     */
    async getOrderedmemberUsingGET(requestParameters: GetOrderedmemberUsingGETRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Orderedmember>> {
        const response = await this.getOrderedmemberUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getPagedOrderWidthGroupProductByMember
     */
    async getPagedOrderWidthGroupProductByMemberUsingGETRaw(requestParameters: GetPagedOrderWidthGroupProductByMemberUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        if (requestParameters['page'] == null) {
            throw new runtime.RequiredError(
                'page',
                'Required parameter "page" was null or undefined when calling getPagedOrderWidthGroupProductByMemberUsingGET().'
            );
        }

        if (requestParameters['size'] == null) {
            throw new runtime.RequiredError(
                'size',
                'Required parameter "size" was null or undefined when calling getPagedOrderWidthGroupProductByMemberUsingGET().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        if (requestParameters['size'] != null) {
            queryParameters['size'] = requestParameters['size'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/order/member/withGroupProduct`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * getPagedOrderWidthGroupProductByMember
     */
    async getPagedOrderWidthGroupProductByMemberUsingGET(requestParameters: GetPagedOrderWidthGroupProductByMemberUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object> {
        const response = await this.getPagedOrderWidthGroupProductByMemberUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getPaymentMethod
     */
    async getPaymentMethodUsingGETRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<string>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/order/allPaymentMethod`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * getPaymentMethod
     */
    async getPaymentMethodUsingGET(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<string>> {
        const response = await this.getPaymentMethodUsingGETRaw(initOverrides);
        return await response.value();
    }

    /**
     * globalPaymentsDatafeed
     */
    async globalPaymentsDatafeedUsingDELETERaw(requestParameters: GlobalPaymentsDatafeedUsingDELETERequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        if (requestParameters['params'] == null) {
            throw new runtime.RequiredError(
                'params',
                'Required parameter "params" was null or undefined when calling globalPaymentsDatafeedUsingDELETE().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['params'] != null) {
            queryParameters['params'] = requestParameters['params'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/order/globpayments/datafeed`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * globalPaymentsDatafeed
     */
    async globalPaymentsDatafeedUsingDELETE(requestParameters: GlobalPaymentsDatafeedUsingDELETERequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object | null | undefined > {
        const response = await this.globalPaymentsDatafeedUsingDELETERaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 204:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * globalPaymentsDatafeed
     */
    async globalPaymentsDatafeedUsingGETRaw(requestParameters: GlobalPaymentsDatafeedUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        if (requestParameters['params'] == null) {
            throw new runtime.RequiredError(
                'params',
                'Required parameter "params" was null or undefined when calling globalPaymentsDatafeedUsingGET().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['params'] != null) {
            queryParameters['params'] = requestParameters['params'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/order/globpayments/datafeed`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * globalPaymentsDatafeed
     */
    async globalPaymentsDatafeedUsingGET(requestParameters: GlobalPaymentsDatafeedUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object> {
        const response = await this.globalPaymentsDatafeedUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * globalPaymentsDatafeed
     */
    async globalPaymentsDatafeedUsingHEADRaw(requestParameters: GlobalPaymentsDatafeedUsingHEADRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        if (requestParameters['params'] == null) {
            throw new runtime.RequiredError(
                'params',
                'Required parameter "params" was null or undefined when calling globalPaymentsDatafeedUsingHEAD().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['params'] != null) {
            queryParameters['params'] = requestParameters['params'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/order/globpayments/datafeed`,
            method: 'HEAD',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * globalPaymentsDatafeed
     */
    async globalPaymentsDatafeedUsingHEAD(requestParameters: GlobalPaymentsDatafeedUsingHEADRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object | null | undefined > {
        const response = await this.globalPaymentsDatafeedUsingHEADRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 204:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * globalPaymentsDatafeed
     */
    async globalPaymentsDatafeedUsingOPTIONSRaw(requestParameters: GlobalPaymentsDatafeedUsingOPTIONSRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        if (requestParameters['params'] == null) {
            throw new runtime.RequiredError(
                'params',
                'Required parameter "params" was null or undefined when calling globalPaymentsDatafeedUsingOPTIONS().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['params'] != null) {
            queryParameters['params'] = requestParameters['params'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/order/globpayments/datafeed`,
            method: 'OPTIONS',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * globalPaymentsDatafeed
     */
    async globalPaymentsDatafeedUsingOPTIONS(requestParameters: GlobalPaymentsDatafeedUsingOPTIONSRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object | null | undefined > {
        const response = await this.globalPaymentsDatafeedUsingOPTIONSRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 204:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * globalPaymentsDatafeed
     */
    async globalPaymentsDatafeedUsingPATCHRaw(requestParameters: GlobalPaymentsDatafeedUsingPATCHRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        if (requestParameters['params'] == null) {
            throw new runtime.RequiredError(
                'params',
                'Required parameter "params" was null or undefined when calling globalPaymentsDatafeedUsingPATCH().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['params'] != null) {
            queryParameters['params'] = requestParameters['params'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/order/globpayments/datafeed`,
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * globalPaymentsDatafeed
     */
    async globalPaymentsDatafeedUsingPATCH(requestParameters: GlobalPaymentsDatafeedUsingPATCHRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object | null | undefined > {
        const response = await this.globalPaymentsDatafeedUsingPATCHRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 204:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * globalPaymentsDatafeed
     */
    async globalPaymentsDatafeedUsingPOSTRaw(requestParameters: GlobalPaymentsDatafeedUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        if (requestParameters['params'] == null) {
            throw new runtime.RequiredError(
                'params',
                'Required parameter "params" was null or undefined when calling globalPaymentsDatafeedUsingPOST().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['params'] != null) {
            queryParameters['params'] = requestParameters['params'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/order/globpayments/datafeed`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * globalPaymentsDatafeed
     */
    async globalPaymentsDatafeedUsingPOST(requestParameters: GlobalPaymentsDatafeedUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object | null | undefined > {
        const response = await this.globalPaymentsDatafeedUsingPOSTRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 201:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * globalPaymentsDatafeed
     */
    async globalPaymentsDatafeedUsingPUTRaw(requestParameters: GlobalPaymentsDatafeedUsingPUTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        if (requestParameters['params'] == null) {
            throw new runtime.RequiredError(
                'params',
                'Required parameter "params" was null or undefined when calling globalPaymentsDatafeedUsingPUT().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['params'] != null) {
            queryParameters['params'] = requestParameters['params'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/order/globpayments/datafeed`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * globalPaymentsDatafeed
     */
    async globalPaymentsDatafeedUsingPUT(requestParameters: GlobalPaymentsDatafeedUsingPUTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object | null | undefined > {
        const response = await this.globalPaymentsDatafeedUsingPUTRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 201:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * globalPaymentsDatafeed
     */
    async globalPaymentsDatafeedUsingTRACERaw(requestParameters: GlobalPaymentsDatafeedUsingTRACERequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        if (requestParameters['params'] == null) {
            throw new runtime.RequiredError(
                'params',
                'Required parameter "params" was null or undefined when calling globalPaymentsDatafeedUsingTRACE().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['params'] != null) {
            queryParameters['params'] = requestParameters['params'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/order/globpayments/datafeed`,
            method: 'TRACE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * globalPaymentsDatafeed
     */
    async globalPaymentsDatafeedUsingTRACE(requestParameters: GlobalPaymentsDatafeedUsingTRACERequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object | null | undefined > {
        const response = await this.globalPaymentsDatafeedUsingTRACERaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 204:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * makeCLDecision
     */
    async makeCLDecisionUsingPOSTRaw(requestParameters: MakeCLDecisionUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RedirectView>> {
        if (requestParameters['params'] == null) {
            throw new runtime.RequiredError(
                'params',
                'Required parameter "params" was null or undefined when calling makeCLDecisionUsingPOST().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['params'] != null) {
            queryParameters['params'] = requestParameters['params'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/order/cl_decision`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RedirectViewFromJSON(jsonValue));
    }

    /**
     * makeCLDecision
     */
    async makeCLDecisionUsingPOST(requestParameters: MakeCLDecisionUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RedirectView | null | undefined > {
        const response = await this.makeCLDecisionUsingPOSTRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 201:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * makeGPDecision
     */
    async makeGPDecisionUsingPOSTRaw(requestParameters: MakeGPDecisionUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RedirectView>> {
        if (requestParameters['params'] == null) {
            throw new runtime.RequiredError(
                'params',
                'Required parameter "params" was null or undefined when calling makeGPDecisionUsingPOST().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['params'] != null) {
            queryParameters['params'] = requestParameters['params'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/order/gp_decision`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RedirectViewFromJSON(jsonValue));
    }

    /**
     * makeGPDecision
     */
    async makeGPDecisionUsingPOST(requestParameters: MakeGPDecisionUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RedirectView | null | undefined > {
        const response = await this.makeGPDecisionUsingPOSTRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 201:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * mastercardDatafeed
     */
    async mastercardDatafeedUsingDELETERaw(requestParameters: MastercardDatafeedUsingDELETERequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        if (requestParameters['params'] == null) {
            throw new runtime.RequiredError(
                'params',
                'Required parameter "params" was null or undefined when calling mastercardDatafeedUsingDELETE().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['params'] != null) {
            queryParameters['params'] = requestParameters['params'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/order/mastercard/datafeed`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * mastercardDatafeed
     */
    async mastercardDatafeedUsingDELETE(requestParameters: MastercardDatafeedUsingDELETERequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object | null | undefined > {
        const response = await this.mastercardDatafeedUsingDELETERaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 204:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * mastercardDatafeed
     */
    async mastercardDatafeedUsingGETRaw(requestParameters: MastercardDatafeedUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        if (requestParameters['params'] == null) {
            throw new runtime.RequiredError(
                'params',
                'Required parameter "params" was null or undefined when calling mastercardDatafeedUsingGET().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['params'] != null) {
            queryParameters['params'] = requestParameters['params'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/order/mastercard/datafeed`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * mastercardDatafeed
     */
    async mastercardDatafeedUsingGET(requestParameters: MastercardDatafeedUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object> {
        const response = await this.mastercardDatafeedUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * mastercardDatafeed
     */
    async mastercardDatafeedUsingHEADRaw(requestParameters: MastercardDatafeedUsingHEADRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        if (requestParameters['params'] == null) {
            throw new runtime.RequiredError(
                'params',
                'Required parameter "params" was null or undefined when calling mastercardDatafeedUsingHEAD().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['params'] != null) {
            queryParameters['params'] = requestParameters['params'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/order/mastercard/datafeed`,
            method: 'HEAD',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * mastercardDatafeed
     */
    async mastercardDatafeedUsingHEAD(requestParameters: MastercardDatafeedUsingHEADRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object | null | undefined > {
        const response = await this.mastercardDatafeedUsingHEADRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 204:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * mastercardDatafeed
     */
    async mastercardDatafeedUsingOPTIONSRaw(requestParameters: MastercardDatafeedUsingOPTIONSRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        if (requestParameters['params'] == null) {
            throw new runtime.RequiredError(
                'params',
                'Required parameter "params" was null or undefined when calling mastercardDatafeedUsingOPTIONS().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['params'] != null) {
            queryParameters['params'] = requestParameters['params'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/order/mastercard/datafeed`,
            method: 'OPTIONS',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * mastercardDatafeed
     */
    async mastercardDatafeedUsingOPTIONS(requestParameters: MastercardDatafeedUsingOPTIONSRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object | null | undefined > {
        const response = await this.mastercardDatafeedUsingOPTIONSRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 204:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * mastercardDatafeed
     */
    async mastercardDatafeedUsingPATCHRaw(requestParameters: MastercardDatafeedUsingPATCHRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        if (requestParameters['params'] == null) {
            throw new runtime.RequiredError(
                'params',
                'Required parameter "params" was null or undefined when calling mastercardDatafeedUsingPATCH().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['params'] != null) {
            queryParameters['params'] = requestParameters['params'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/order/mastercard/datafeed`,
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * mastercardDatafeed
     */
    async mastercardDatafeedUsingPATCH(requestParameters: MastercardDatafeedUsingPATCHRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object | null | undefined > {
        const response = await this.mastercardDatafeedUsingPATCHRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 204:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * mastercardDatafeed
     */
    async mastercardDatafeedUsingPOSTRaw(requestParameters: MastercardDatafeedUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        if (requestParameters['params'] == null) {
            throw new runtime.RequiredError(
                'params',
                'Required parameter "params" was null or undefined when calling mastercardDatafeedUsingPOST().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['params'] != null) {
            queryParameters['params'] = requestParameters['params'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/order/mastercard/datafeed`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * mastercardDatafeed
     */
    async mastercardDatafeedUsingPOST(requestParameters: MastercardDatafeedUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object | null | undefined > {
        const response = await this.mastercardDatafeedUsingPOSTRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 201:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * mastercardDatafeed
     */
    async mastercardDatafeedUsingPUTRaw(requestParameters: MastercardDatafeedUsingPUTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        if (requestParameters['params'] == null) {
            throw new runtime.RequiredError(
                'params',
                'Required parameter "params" was null or undefined when calling mastercardDatafeedUsingPUT().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['params'] != null) {
            queryParameters['params'] = requestParameters['params'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/order/mastercard/datafeed`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * mastercardDatafeed
     */
    async mastercardDatafeedUsingPUT(requestParameters: MastercardDatafeedUsingPUTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object | null | undefined > {
        const response = await this.mastercardDatafeedUsingPUTRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 201:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * mastercardDatafeed
     */
    async mastercardDatafeedUsingTRACERaw(requestParameters: MastercardDatafeedUsingTRACERequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        if (requestParameters['params'] == null) {
            throw new runtime.RequiredError(
                'params',
                'Required parameter "params" was null or undefined when calling mastercardDatafeedUsingTRACE().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['params'] != null) {
            queryParameters['params'] = requestParameters['params'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/order/mastercard/datafeed`,
            method: 'TRACE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * mastercardDatafeed
     */
    async mastercardDatafeedUsingTRACE(requestParameters: MastercardDatafeedUsingTRACERequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object | null | undefined > {
        const response = await this.mastercardDatafeedUsingTRACERaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 204:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * orderStatusUpdate
     */
    async orderStatusUpdateUsingDELETERaw(requestParameters: OrderStatusUpdateUsingDELETERequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OrderDto>> {
        if (requestParameters['params'] == null) {
            throw new runtime.RequiredError(
                'params',
                'Required parameter "params" was null or undefined when calling orderStatusUpdateUsingDELETE().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        if (requestParameters['params'] != null) {
            queryParameters['params'] = requestParameters['params'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/order/status/update`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OrderDtoFromJSON(jsonValue));
    }

    /**
     * orderStatusUpdate
     */
    async orderStatusUpdateUsingDELETE(requestParameters: OrderStatusUpdateUsingDELETERequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OrderDto | null | undefined > {
        const response = await this.orderStatusUpdateUsingDELETERaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 204:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * orderStatusUpdate
     */
    async orderStatusUpdateUsingGETRaw(requestParameters: OrderStatusUpdateUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OrderDto>> {
        if (requestParameters['params'] == null) {
            throw new runtime.RequiredError(
                'params',
                'Required parameter "params" was null or undefined when calling orderStatusUpdateUsingGET().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        if (requestParameters['params'] != null) {
            queryParameters['params'] = requestParameters['params'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/order/status/update`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OrderDtoFromJSON(jsonValue));
    }

    /**
     * orderStatusUpdate
     */
    async orderStatusUpdateUsingGET(requestParameters: OrderStatusUpdateUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OrderDto> {
        const response = await this.orderStatusUpdateUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * orderStatusUpdate
     */
    async orderStatusUpdateUsingHEADRaw(requestParameters: OrderStatusUpdateUsingHEADRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OrderDto>> {
        if (requestParameters['params'] == null) {
            throw new runtime.RequiredError(
                'params',
                'Required parameter "params" was null or undefined when calling orderStatusUpdateUsingHEAD().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        if (requestParameters['params'] != null) {
            queryParameters['params'] = requestParameters['params'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/order/status/update`,
            method: 'HEAD',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OrderDtoFromJSON(jsonValue));
    }

    /**
     * orderStatusUpdate
     */
    async orderStatusUpdateUsingHEAD(requestParameters: OrderStatusUpdateUsingHEADRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OrderDto | null | undefined > {
        const response = await this.orderStatusUpdateUsingHEADRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 204:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * orderStatusUpdate
     */
    async orderStatusUpdateUsingOPTIONSRaw(requestParameters: OrderStatusUpdateUsingOPTIONSRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OrderDto>> {
        if (requestParameters['params'] == null) {
            throw new runtime.RequiredError(
                'params',
                'Required parameter "params" was null or undefined when calling orderStatusUpdateUsingOPTIONS().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        if (requestParameters['params'] != null) {
            queryParameters['params'] = requestParameters['params'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/order/status/update`,
            method: 'OPTIONS',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OrderDtoFromJSON(jsonValue));
    }

    /**
     * orderStatusUpdate
     */
    async orderStatusUpdateUsingOPTIONS(requestParameters: OrderStatusUpdateUsingOPTIONSRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OrderDto | null | undefined > {
        const response = await this.orderStatusUpdateUsingOPTIONSRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 204:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * orderStatusUpdate
     */
    async orderStatusUpdateUsingPATCHRaw(requestParameters: OrderStatusUpdateUsingPATCHRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OrderDto>> {
        if (requestParameters['params'] == null) {
            throw new runtime.RequiredError(
                'params',
                'Required parameter "params" was null or undefined when calling orderStatusUpdateUsingPATCH().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        if (requestParameters['params'] != null) {
            queryParameters['params'] = requestParameters['params'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/order/status/update`,
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OrderDtoFromJSON(jsonValue));
    }

    /**
     * orderStatusUpdate
     */
    async orderStatusUpdateUsingPATCH(requestParameters: OrderStatusUpdateUsingPATCHRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OrderDto | null | undefined > {
        const response = await this.orderStatusUpdateUsingPATCHRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 204:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * orderStatusUpdate
     */
    async orderStatusUpdateUsingPOSTRaw(requestParameters: OrderStatusUpdateUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OrderDto>> {
        if (requestParameters['params'] == null) {
            throw new runtime.RequiredError(
                'params',
                'Required parameter "params" was null or undefined when calling orderStatusUpdateUsingPOST().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        if (requestParameters['params'] != null) {
            queryParameters['params'] = requestParameters['params'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/order/status/update`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OrderDtoFromJSON(jsonValue));
    }

    /**
     * orderStatusUpdate
     */
    async orderStatusUpdateUsingPOST(requestParameters: OrderStatusUpdateUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OrderDto | null | undefined > {
        const response = await this.orderStatusUpdateUsingPOSTRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 201:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * orderStatusUpdate
     */
    async orderStatusUpdateUsingPUTRaw(requestParameters: OrderStatusUpdateUsingPUTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OrderDto>> {
        if (requestParameters['params'] == null) {
            throw new runtime.RequiredError(
                'params',
                'Required parameter "params" was null or undefined when calling orderStatusUpdateUsingPUT().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        if (requestParameters['params'] != null) {
            queryParameters['params'] = requestParameters['params'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/order/status/update`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OrderDtoFromJSON(jsonValue));
    }

    /**
     * orderStatusUpdate
     */
    async orderStatusUpdateUsingPUT(requestParameters: OrderStatusUpdateUsingPUTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OrderDto | null | undefined > {
        const response = await this.orderStatusUpdateUsingPUTRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 201:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * orderStatusUpdate
     */
    async orderStatusUpdateUsingTRACERaw(requestParameters: OrderStatusUpdateUsingTRACERequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OrderDto>> {
        if (requestParameters['params'] == null) {
            throw new runtime.RequiredError(
                'params',
                'Required parameter "params" was null or undefined when calling orderStatusUpdateUsingTRACE().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        if (requestParameters['params'] != null) {
            queryParameters['params'] = requestParameters['params'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/order/status/update`,
            method: 'TRACE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OrderDtoFromJSON(jsonValue));
    }

    /**
     * orderStatusUpdate
     */
    async orderStatusUpdateUsingTRACE(requestParameters: OrderStatusUpdateUsingTRACERequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OrderDto | null | undefined > {
        const response = await this.orderStatusUpdateUsingTRACERaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 204:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * preGustOrder2
     */
    async preGustOrder2UsingPOSTRaw(requestParameters: PreGustOrder2UsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        if (requestParameters['productListJson'] == null) {
            throw new runtime.RequiredError(
                'productListJson',
                'Required parameter "productListJson" was null or undefined when calling preGustOrder2UsingPOST().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['productListJson'] != null) {
            queryParameters['productListJson'] = requestParameters['productListJson'];
        }

        if (requestParameters['couponCode'] != null) {
            queryParameters['couponCode'] = requestParameters['couponCode'];
        }

        if (requestParameters['promotionCode'] != null) {
            queryParameters['promotionCode'] = requestParameters['promotionCode'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/order/guest/pre2`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * preGustOrder2
     */
    async preGustOrder2UsingPOST(requestParameters: PreGustOrder2UsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object | null | undefined > {
        const response = await this.preGustOrder2UsingPOSTRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 201:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * preGustOrder
     */
    async preGustOrderUsingPOSTRaw(requestParameters: PreGustOrderUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        if (requestParameters['productListJson'] == null) {
            throw new runtime.RequiredError(
                'productListJson',
                'Required parameter "productListJson" was null or undefined when calling preGustOrderUsingPOST().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['productListJson'] != null) {
            queryParameters['productListJson'] = requestParameters['productListJson'];
        }

        if (requestParameters['couponCode'] != null) {
            queryParameters['couponCode'] = requestParameters['couponCode'];
        }

        if (requestParameters['promotionCode'] != null) {
            queryParameters['promotionCode'] = requestParameters['promotionCode'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/order/guest/pre`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * preGustOrder
     */
    async preGustOrderUsingPOST(requestParameters: PreGustOrderUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object | null | undefined > {
        const response = await this.preGustOrderUsingPOSTRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 201:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * preMemberOrder
     */
    async preMemberOrderUsingPOSTRaw(requestParameters: PreMemberOrderUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OrderDto>> {
        if (requestParameters['productId'] == null) {
            throw new runtime.RequiredError(
                'productId',
                'Required parameter "productId" was null or undefined when calling preMemberOrderUsingPOST().'
            );
        }

        if (requestParameters['quantity'] == null) {
            throw new runtime.RequiredError(
                'quantity',
                'Required parameter "quantity" was null or undefined when calling preMemberOrderUsingPOST().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        if (requestParameters['productId'] != null) {
            queryParameters['productId'] = requestParameters['productId'];
        }

        if (requestParameters['quantity'] != null) {
            queryParameters['quantity'] = requestParameters['quantity'];
        }

        if (requestParameters['promotionCode'] != null) {
            queryParameters['promotionCode'] = requestParameters['promotionCode'];
        }

        if (requestParameters['deliveryTimes'] != null) {
            queryParameters['deliveryTimes'] = requestParameters['deliveryTimes'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/order/member/pre`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OrderDtoFromJSON(jsonValue));
    }

    /**
     * preMemberOrder
     */
    async preMemberOrderUsingPOST(requestParameters: PreMemberOrderUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OrderDto | null | undefined > {
        const response = await this.preMemberOrderUsingPOSTRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 201:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * rebuyOrder
     */
    async rebuyOrderUsingGETRaw(requestParameters: RebuyOrderUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MemberShoppingCartDto>> {
        if (requestParameters['orderId'] == null) {
            throw new runtime.RequiredError(
                'orderId',
                'Required parameter "orderId" was null or undefined when calling rebuyOrderUsingGET().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/order/rebuy/{orderId}`.replace(`{${"orderId"}}`, encodeURIComponent(String(requestParameters['orderId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MemberShoppingCartDtoFromJSON(jsonValue));
    }

    /**
     * rebuyOrder
     */
    async rebuyOrderUsingGET(requestParameters: RebuyOrderUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MemberShoppingCartDto> {
        const response = await this.rebuyOrderUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * search
     */
    async searchUsingPOSTRaw(requestParameters: SearchUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<OrderDto>>> {
        const queryParameters: any = {};

        if (requestParameters['orderNo'] != null) {
            queryParameters['orderNo'] = requestParameters['orderNo'];
        }

        if (requestParameters['memberId'] != null) {
            queryParameters['memberId'] = requestParameters['memberId'];
        }

        if (requestParameters['orderStatus'] != null) {
            queryParameters['orderStatus'] = requestParameters['orderStatus'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/order/search`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters['body'] as any,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(OrderDtoFromJSON));
    }

    /**
     * search
     */
    async searchUsingPOST(requestParameters: SearchUsingPOSTRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<OrderDto> | null | undefined > {
        const response = await this.searchUsingPOSTRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 201:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * successOrderHandle
     */
    async successOrderHandleUsingGETRaw(requestParameters: SuccessOrderHandleUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OrderDto>> {
        if (requestParameters['orderId'] == null) {
            throw new runtime.RequiredError(
                'orderId',
                'Required parameter "orderId" was null or undefined when calling successOrderHandleUsingGET().'
            );
        }

        if (requestParameters['paymentMethod'] == null) {
            throw new runtime.RequiredError(
                'paymentMethod',
                'Required parameter "paymentMethod" was null or undefined when calling successOrderHandleUsingGET().'
            );
        }

        if (requestParameters['orderStatus'] == null) {
            throw new runtime.RequiredError(
                'orderStatus',
                'Required parameter "orderStatus" was null or undefined when calling successOrderHandleUsingGET().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/order/success/{orderId}/{paymentMethod}/{orderStatus}`.replace(`{${"orderId"}}`, encodeURIComponent(String(requestParameters['orderId']))).replace(`{${"paymentMethod"}}`, encodeURIComponent(String(requestParameters['paymentMethod']))).replace(`{${"orderStatus"}}`, encodeURIComponent(String(requestParameters['orderStatus']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OrderDtoFromJSON(jsonValue));
    }

    /**
     * successOrderHandle
     */
    async successOrderHandleUsingGET(requestParameters: SuccessOrderHandleUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OrderDto> {
        const response = await this.successOrderHandleUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * updateOrderStatus
     */
    async updateOrderStatusUsingPOSTRaw(requestParameters: UpdateOrderStatusUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OrderDto>> {
        if (requestParameters['orderId'] == null) {
            throw new runtime.RequiredError(
                'orderId',
                'Required parameter "orderId" was null or undefined when calling updateOrderStatusUsingPOST().'
            );
        }

        if (requestParameters['orderStatus'] == null) {
            throw new runtime.RequiredError(
                'orderStatus',
                'Required parameter "orderStatus" was null or undefined when calling updateOrderStatusUsingPOST().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['orderId'] != null) {
            queryParameters['orderId'] = requestParameters['orderId'];
        }

        if (requestParameters['orderStatus'] != null) {
            queryParameters['orderStatus'] = requestParameters['orderStatus'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/order/updateStatus`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OrderDtoFromJSON(jsonValue));
    }

    /**
     * updateOrderStatus
     */
    async updateOrderStatusUsingPOST(requestParameters: UpdateOrderStatusUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OrderDto | null | undefined > {
        const response = await this.updateOrderStatusUsingPOSTRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 201:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * updateTrackingNumber2nd
     */
    async updateTrackingNumber2ndUsingPOSTRaw(requestParameters: UpdateTrackingNumber2ndUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OrderDto>> {
        if (requestParameters['orderId'] == null) {
            throw new runtime.RequiredError(
                'orderId',
                'Required parameter "orderId" was null or undefined when calling updateTrackingNumber2ndUsingPOST().'
            );
        }

        if (requestParameters['trackingNumber2nd'] == null) {
            throw new runtime.RequiredError(
                'trackingNumber2nd',
                'Required parameter "trackingNumber2nd" was null or undefined when calling updateTrackingNumber2ndUsingPOST().'
            );
        }

        if (requestParameters['deliveryDate2nd'] == null) {
            throw new runtime.RequiredError(
                'deliveryDate2nd',
                'Required parameter "deliveryDate2nd" was null or undefined when calling updateTrackingNumber2ndUsingPOST().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        if (requestParameters['orderId'] != null) {
            queryParameters['orderId'] = requestParameters['orderId'];
        }

        if (requestParameters['trackingNumber2nd'] != null) {
            queryParameters['trackingNumber2nd'] = requestParameters['trackingNumber2nd'];
        }

        if (requestParameters['deliveryDate2nd'] != null) {
            queryParameters['deliveryDate2nd'] = (requestParameters['deliveryDate2nd'] as any).toISOString();
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/order/updateTrackingNumber2nd`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OrderDtoFromJSON(jsonValue));
    }

    /**
     * updateTrackingNumber2nd
     */
    async updateTrackingNumber2ndUsingPOST(requestParameters: UpdateTrackingNumber2ndUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OrderDto | null | undefined > {
        const response = await this.updateTrackingNumber2ndUsingPOSTRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 201:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * updateTrackingNumber
     */
    async updateTrackingNumberUsingPOSTRaw(requestParameters: UpdateTrackingNumberUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OrderDto>> {
        if (requestParameters['orderId'] == null) {
            throw new runtime.RequiredError(
                'orderId',
                'Required parameter "orderId" was null or undefined when calling updateTrackingNumberUsingPOST().'
            );
        }

        if (requestParameters['trackingNumber'] == null) {
            throw new runtime.RequiredError(
                'trackingNumber',
                'Required parameter "trackingNumber" was null or undefined when calling updateTrackingNumberUsingPOST().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        if (requestParameters['orderId'] != null) {
            queryParameters['orderId'] = requestParameters['orderId'];
        }

        if (requestParameters['trackingNumber'] != null) {
            queryParameters['trackingNumber'] = requestParameters['trackingNumber'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/order/updateTrackingNumber`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OrderDtoFromJSON(jsonValue));
    }

    /**
     * updateTrackingNumber
     */
    async updateTrackingNumberUsingPOST(requestParameters: UpdateTrackingNumberUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OrderDto | null | undefined > {
        const response = await this.updateTrackingNumberUsingPOSTRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 201:
                return null;
            default:
                return await response.value();
        }
    }

}
