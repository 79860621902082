/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  PageContentDto,
} from '../models/index';
import {
    PageContentDtoFromJSON,
    PageContentDtoToJSON,
} from '../models/index';

export interface AddPageContentUsingPOSTRequest {
    content: string;
    pageName: string;
    name?: string;
}

export interface DeleteArticleUsingPOST2Request {
    pageContentId: number;
    name?: string;
}

export interface GetPageContentByIdUsingGETRequest {
    pageContentId: number;
}

export interface GetPageContentByPageNameUsingGETRequest {
    pageName: string;
}

export interface UpdatePageContentUsingPOSTRequest {
    pageContentId: number;
    content: string;
    pageName: string;
    name?: string;
}

/**
 * 
 */
export class PageContentControllerApi extends runtime.BaseAPI {

    /**
     * addPageContent
     */
    async addPageContentUsingPOSTRaw(requestParameters: AddPageContentUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PageContentDto>> {
        if (requestParameters['content'] == null) {
            throw new runtime.RequiredError(
                'content',
                'Required parameter "content" was null or undefined when calling addPageContentUsingPOST().'
            );
        }

        if (requestParameters['pageName'] == null) {
            throw new runtime.RequiredError(
                'pageName',
                'Required parameter "pageName" was null or undefined when calling addPageContentUsingPOST().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        if (requestParameters['content'] != null) {
            queryParameters['content'] = requestParameters['content'];
        }

        if (requestParameters['pageName'] != null) {
            queryParameters['pageName'] = requestParameters['pageName'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/pageContent/add`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PageContentDtoFromJSON(jsonValue));
    }

    /**
     * addPageContent
     */
    async addPageContentUsingPOST(requestParameters: AddPageContentUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PageContentDto | null | undefined > {
        const response = await this.addPageContentUsingPOSTRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 201:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * deleteArticle
     */
    async deleteArticleUsingPOST2Raw(requestParameters: DeleteArticleUsingPOST2Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PageContentDto>> {
        if (requestParameters['pageContentId'] == null) {
            throw new runtime.RequiredError(
                'pageContentId',
                'Required parameter "pageContentId" was null or undefined when calling deleteArticleUsingPOST2().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/pageContent/delete/{pageContentId}`.replace(`{${"pageContentId"}}`, encodeURIComponent(String(requestParameters['pageContentId']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PageContentDtoFromJSON(jsonValue));
    }

    /**
     * deleteArticle
     */
    async deleteArticleUsingPOST2(requestParameters: DeleteArticleUsingPOST2Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PageContentDto | null | undefined > {
        const response = await this.deleteArticleUsingPOST2Raw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 201:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * getAllPageContent
     */
    async getAllPageContentUsingGETRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<PageContentDto>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/pageContent/all`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PageContentDtoFromJSON));
    }

    /**
     * getAllPageContent
     */
    async getAllPageContentUsingGET(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<PageContentDto>> {
        const response = await this.getAllPageContentUsingGETRaw(initOverrides);
        return await response.value();
    }

    /**
     * getPageContentById
     */
    async getPageContentByIdUsingGETRaw(requestParameters: GetPageContentByIdUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PageContentDto>> {
        if (requestParameters['pageContentId'] == null) {
            throw new runtime.RequiredError(
                'pageContentId',
                'Required parameter "pageContentId" was null or undefined when calling getPageContentByIdUsingGET().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/pageContent/{pageContentId}`.replace(`{${"pageContentId"}}`, encodeURIComponent(String(requestParameters['pageContentId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PageContentDtoFromJSON(jsonValue));
    }

    /**
     * getPageContentById
     */
    async getPageContentByIdUsingGET(requestParameters: GetPageContentByIdUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PageContentDto> {
        const response = await this.getPageContentByIdUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getPageContentByPageName
     */
    async getPageContentByPageNameUsingGETRaw(requestParameters: GetPageContentByPageNameUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PageContentDto>> {
        if (requestParameters['pageName'] == null) {
            throw new runtime.RequiredError(
                'pageName',
                'Required parameter "pageName" was null or undefined when calling getPageContentByPageNameUsingGET().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/pageContent/pageName/{pageName}`.replace(`{${"pageName"}}`, encodeURIComponent(String(requestParameters['pageName']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PageContentDtoFromJSON(jsonValue));
    }

    /**
     * getPageContentByPageName
     */
    async getPageContentByPageNameUsingGET(requestParameters: GetPageContentByPageNameUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PageContentDto> {
        const response = await this.getPageContentByPageNameUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * updatePageContent
     */
    async updatePageContentUsingPOSTRaw(requestParameters: UpdatePageContentUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PageContentDto>> {
        if (requestParameters['pageContentId'] == null) {
            throw new runtime.RequiredError(
                'pageContentId',
                'Required parameter "pageContentId" was null or undefined when calling updatePageContentUsingPOST().'
            );
        }

        if (requestParameters['content'] == null) {
            throw new runtime.RequiredError(
                'content',
                'Required parameter "content" was null or undefined when calling updatePageContentUsingPOST().'
            );
        }

        if (requestParameters['pageName'] == null) {
            throw new runtime.RequiredError(
                'pageName',
                'Required parameter "pageName" was null or undefined when calling updatePageContentUsingPOST().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        if (requestParameters['pageContentId'] != null) {
            queryParameters['pageContentId'] = requestParameters['pageContentId'];
        }

        if (requestParameters['content'] != null) {
            queryParameters['content'] = requestParameters['content'];
        }

        if (requestParameters['pageName'] != null) {
            queryParameters['pageName'] = requestParameters['pageName'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/pageContent/update`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PageContentDtoFromJSON(jsonValue));
    }

    /**
     * updatePageContent
     */
    async updatePageContentUsingPOST(requestParameters: UpdatePageContentUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PageContentDto | null | undefined > {
        const response = await this.updatePageContentUsingPOSTRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 201:
                return null;
            default:
                return await response.value();
        }
    }

}
