/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { AdminMemberRole } from './AdminMemberRole';
import {
    AdminMemberRoleFromJSON,
    AdminMemberRoleFromJSONTyped,
    AdminMemberRoleToJSON,
} from './AdminMemberRole';

/**
 * 
 * @export
 * @interface AdminMemberDto
 */
export interface AdminMemberDto {
    /**
     * 
     * @type {number}
     * @memberof AdminMemberDto
     */
    adminMemberId?: number;
    /**
     * 
     * @type {AdminMemberRole}
     * @memberof AdminMemberDto
     */
    adminMemberRole?: AdminMemberRole;
    /**
     * 
     * @type {string}
     * @memberof AdminMemberDto
     */
    firstname?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminMemberDto
     */
    lastname?: string;
    /**
     * 
     * @type {number}
     * @memberof AdminMemberDto
     */
    memberRole?: number;
    /**
     * 
     * @type {string}
     * @memberof AdminMemberDto
     */
    memberType?: string;
    /**
     * 
     * @type {string}
     * @memberof AdminMemberDto
     */
    username?: string;
}

/**
 * Check if a given object implements the AdminMemberDto interface.
 */
export function instanceOfAdminMemberDto(value: object): boolean {
    return true;
}

export function AdminMemberDtoFromJSON(json: any): AdminMemberDto {
    return AdminMemberDtoFromJSONTyped(json, false);
}

export function AdminMemberDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): AdminMemberDto {
    if (json == null) {
        return json;
    }
    return {
        
        'adminMemberId': json['adminMemberId'] == null ? undefined : json['adminMemberId'],
        'adminMemberRole': json['adminMemberRole'] == null ? undefined : AdminMemberRoleFromJSON(json['adminMemberRole']),
        'firstname': json['firstname'] == null ? undefined : json['firstname'],
        'lastname': json['lastname'] == null ? undefined : json['lastname'],
        'memberRole': json['memberRole'] == null ? undefined : json['memberRole'],
        'memberType': json['memberType'] == null ? undefined : json['memberType'],
        'username': json['username'] == null ? undefined : json['username'],
    };
}

export function AdminMemberDtoToJSON(value?: AdminMemberDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'adminMemberId': value['adminMemberId'],
        'adminMemberRole': AdminMemberRoleToJSON(value['adminMemberRole']),
        'firstname': value['firstname'],
        'lastname': value['lastname'],
        'memberRole': value['memberRole'],
        'memberType': value['memberType'],
        'username': value['username'],
    };
}

