/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { OrderDtoLite } from './OrderDtoLite';
import {
    OrderDtoLiteFromJSON,
    OrderDtoLiteFromJSONTyped,
    OrderDtoLiteToJSON,
} from './OrderDtoLite';
import type { ShippingProductDto } from './ShippingProductDto';
import {
    ShippingProductDtoFromJSON,
    ShippingProductDtoFromJSONTyped,
    ShippingProductDtoToJSON,
} from './ShippingProductDto';

/**
 * 
 * @export
 * @interface ShippingDto
 */
export interface ShippingDto {
    /**
     * 
     * @type {OrderDtoLite}
     * @memberof ShippingDto
     */
    order?: OrderDtoLite;
    /**
     * 
     * @type {Date}
     * @memberof ShippingDto
     */
    shippingDate?: Date;
    /**
     * 
     * @type {number}
     * @memberof ShippingDto
     */
    shippingId?: number;
    /**
     * 
     * @type {Array<ShippingProductDto>}
     * @memberof ShippingDto
     */
    shippingProducts?: Array<ShippingProductDto>;
    /**
     * 
     * @type {string}
     * @memberof ShippingDto
     */
    shippingStatus?: string;
    /**
     * 
     * @type {string}
     * @memberof ShippingDto
     */
    trackingNumber?: string;
}

/**
 * Check if a given object implements the ShippingDto interface.
 */
export function instanceOfShippingDto(value: object): boolean {
    return true;
}

export function ShippingDtoFromJSON(json: any): ShippingDto {
    return ShippingDtoFromJSONTyped(json, false);
}

export function ShippingDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ShippingDto {
    if (json == null) {
        return json;
    }
    return {
        
        'order': json['order'] == null ? undefined : OrderDtoLiteFromJSON(json['order']),
        'shippingDate': json['shippingDate'] == null ? undefined : (new Date(json['shippingDate'])),
        'shippingId': json['shippingId'] == null ? undefined : json['shippingId'],
        'shippingProducts': json['shippingProducts'] == null ? undefined : ((json['shippingProducts'] as Array<any>).map(ShippingProductDtoFromJSON)),
        'shippingStatus': json['shippingStatus'] == null ? undefined : json['shippingStatus'],
        'trackingNumber': json['trackingNumber'] == null ? undefined : json['trackingNumber'],
    };
}

export function ShippingDtoToJSON(value?: ShippingDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'order': OrderDtoLiteToJSON(value['order']),
        'shippingDate': value['shippingDate'] == null ? undefined : ((value['shippingDate']).toISOString()),
        'shippingId': value['shippingId'],
        'shippingProducts': value['shippingProducts'] == null ? undefined : ((value['shippingProducts'] as Array<any>).map(ShippingProductDtoToJSON)),
        'shippingStatus': value['shippingStatus'],
        'trackingNumber': value['trackingNumber'],
    };
}

