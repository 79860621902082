/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AddressDto
 */
export interface AddressDto {
    /**
     * 
     * @type {string}
     * @memberof AddressDto
     */
    addressCity?: string;
    /**
     * 
     * @type {number}
     * @memberof AddressDto
     */
    addressId?: number;
    /**
     * 
     * @type {string}
     * @memberof AddressDto
     */
    addressProvince?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressDto
     */
    addressRow1?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressDto
     */
    addressRow2?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressDto
     */
    addressRow3?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressDto
     */
    addressRow4?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressDto
     */
    addressZone?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressDto
     */
    contactNumber?: string;
    /**
     * 
     * @type {number}
     * @memberof AddressDto
     */
    countryId?: number;
    /**
     * 
     * @type {string}
     * @memberof AddressDto
     */
    isDefault?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressDto
     */
    phoneCountryId?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressDto
     */
    recipient?: string;
}

/**
 * Check if a given object implements the AddressDto interface.
 */
export function instanceOfAddressDto(value: object): boolean {
    return true;
}

export function AddressDtoFromJSON(json: any): AddressDto {
    return AddressDtoFromJSONTyped(json, false);
}

export function AddressDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): AddressDto {
    if (json == null) {
        return json;
    }
    return {
        
        'addressCity': json['addressCity'] == null ? undefined : json['addressCity'],
        'addressId': json['addressId'] == null ? undefined : json['addressId'],
        'addressProvince': json['addressProvince'] == null ? undefined : json['addressProvince'],
        'addressRow1': json['addressRow1'] == null ? undefined : json['addressRow1'],
        'addressRow2': json['addressRow2'] == null ? undefined : json['addressRow2'],
        'addressRow3': json['addressRow3'] == null ? undefined : json['addressRow3'],
        'addressRow4': json['addressRow4'] == null ? undefined : json['addressRow4'],
        'addressZone': json['addressZone'] == null ? undefined : json['addressZone'],
        'contactNumber': json['contactNumber'] == null ? undefined : json['contactNumber'],
        'countryId': json['countryId'] == null ? undefined : json['countryId'],
        'isDefault': json['isDefault'] == null ? undefined : json['isDefault'],
        'phoneCountryId': json['phoneCountryId'] == null ? undefined : json['phoneCountryId'],
        'recipient': json['recipient'] == null ? undefined : json['recipient'],
    };
}

export function AddressDtoToJSON(value?: AddressDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'addressCity': value['addressCity'],
        'addressId': value['addressId'],
        'addressProvince': value['addressProvince'],
        'addressRow1': value['addressRow1'],
        'addressRow2': value['addressRow2'],
        'addressRow3': value['addressRow3'],
        'addressRow4': value['addressRow4'],
        'addressZone': value['addressZone'],
        'contactNumber': value['contactNumber'],
        'countryId': value['countryId'],
        'isDefault': value['isDefault'],
        'phoneCountryId': value['phoneCountryId'],
        'recipient': value['recipient'],
    };
}

