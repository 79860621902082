/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Country } from './Country';
import {
    CountryFromJSON,
    CountryFromJSONTyped,
    CountryToJSON,
} from './Country';

/**
 * 
 * @export
 * @interface Address
 */
export interface Address {
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    addressCity?: string;
    /**
     * 
     * @type {number}
     * @memberof Address
     */
    addressId?: number;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    addressProvince?: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    addressRow1?: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    addressRow2?: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    addressRow3?: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    addressRow4?: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    addressZone?: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    contactNumber?: string;
    /**
     * 
     * @type {Country}
     * @memberof Address
     */
    countryByCountryId?: Country;
    /**
     * 
     * @type {number}
     * @memberof Address
     */
    countryId?: number;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    isDefault?: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    phoneCountryId?: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    recipient?: string;
}

/**
 * Check if a given object implements the Address interface.
 */
export function instanceOfAddress(value: object): boolean {
    return true;
}

export function AddressFromJSON(json: any): Address {
    return AddressFromJSONTyped(json, false);
}

export function AddressFromJSONTyped(json: any, ignoreDiscriminator: boolean): Address {
    if (json == null) {
        return json;
    }
    return {
        
        'addressCity': json['addressCity'] == null ? undefined : json['addressCity'],
        'addressId': json['addressId'] == null ? undefined : json['addressId'],
        'addressProvince': json['addressProvince'] == null ? undefined : json['addressProvince'],
        'addressRow1': json['addressRow1'] == null ? undefined : json['addressRow1'],
        'addressRow2': json['addressRow2'] == null ? undefined : json['addressRow2'],
        'addressRow3': json['addressRow3'] == null ? undefined : json['addressRow3'],
        'addressRow4': json['addressRow4'] == null ? undefined : json['addressRow4'],
        'addressZone': json['addressZone'] == null ? undefined : json['addressZone'],
        'contactNumber': json['contactNumber'] == null ? undefined : json['contactNumber'],
        'countryByCountryId': json['countryByCountryId'] == null ? undefined : CountryFromJSON(json['countryByCountryId']),
        'countryId': json['countryId'] == null ? undefined : json['countryId'],
        'isDefault': json['isDefault'] == null ? undefined : json['isDefault'],
        'phoneCountryId': json['phoneCountryId'] == null ? undefined : json['phoneCountryId'],
        'recipient': json['recipient'] == null ? undefined : json['recipient'],
    };
}

export function AddressToJSON(value?: Address | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'addressCity': value['addressCity'],
        'addressId': value['addressId'],
        'addressProvince': value['addressProvince'],
        'addressRow1': value['addressRow1'],
        'addressRow2': value['addressRow2'],
        'addressRow3': value['addressRow3'],
        'addressRow4': value['addressRow4'],
        'addressZone': value['addressZone'],
        'contactNumber': value['contactNumber'],
        'countryByCountryId': CountryToJSON(value['countryByCountryId']),
        'countryId': value['countryId'],
        'isDefault': value['isDefault'],
        'phoneCountryId': value['phoneCountryId'],
        'recipient': value['recipient'],
    };
}

