/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  DisplayProductDto,
  DisplayProductMember2Dto,
  Resource,
} from '../models/index';
import {
    DisplayProductDtoFromJSON,
    DisplayProductDtoToJSON,
    DisplayProductMember2DtoFromJSON,
    DisplayProductMember2DtoToJSON,
    ResourceFromJSON,
    ResourceToJSON,
} from '../models/index';

export interface AddDisplayProductUsingPOSTRequest {
    displayProductNameChi: string;
    displayProductNameEng: string;
    displayProductUrlNaming: string;
    displayProductPrice: number;
    displayProductImage: string;
    displayProductDescriptionDetail: string;
    displayProductDescriptionFeature: string;
    displayProductDescriptionIngredient: string;
    displayProductDescriptionUsage: string;
    displayProductDescriptionUseCount: string;
    displayProductDescriptionTarget: string;
    functionalIcon: string;
    productIds: number;
    name?: string;
    displayProductOriginalPrice?: number;
    displayProductShortDescription?: string;
    recommended?: string;
    onShelfDate?: Date;
    offShelfDate?: Date;
    order?: number;
    categoryIds?: number;
    hashtag?: string;
    displayProductNameHtmlChi?: string;
    displayProductNameHtmlEng?: string;
    publisher?: string;
    productType?: string;
    language?: string;
    sku?: string;
    isbn?: string;
    displayProductAuthor?: string;
    bookComment?: string;
    languages?: number;
    awards?: number;
    organizationId?: number;
    displayProductTranslator?: string;
}

export interface DeleteProductUsingPOSTRequest {
    displayProductId: number;
    name?: string;
}

export interface GetAllDisplayProductAdminUsingGETRequest {
    name?: string;
}

export interface GetAllDisplayProductCSVUsingGETRequest {
    name?: string;
}

export interface GetAllDisplayProductTxtUsingGETRequest {
    name?: string;
}

export interface GetAllDisplayProductUsingGETRequest {
    name?: string;
}

export interface GetDisplayProductByCategoryIdAdminUsingGETRequest {
    categoryId: number;
    name?: string;
}

export interface GetDisplayProductByDisplayProductIdAdminUsingGETRequest {
    displayProductId: number;
    name?: string;
}

export interface GetDisplayProductByDisplayProductUrlNamingUsingGETRequest {
    idOrUrlNaming: string;
    name?: string;
}

export interface GetDisplayProductByHashtagNameAdminUsingGETRequest {
    hashtagName: string;
    name?: string;
}

export interface GetDisplayProductByOrganizationIdUsingGETRequest {
    organizationId: string;
    name?: string;
}

export interface GetPagedDisplayProductByCategoryIdUsingGETRequest {
    page: string;
    size: string;
    categoryIdOrUrlNaming: string;
    name?: string;
}

export interface GetPagedDisplayProductByHashtagNameUsingGETRequest {
    page: string;
    size: string;
    hashtagName: string;
    name?: string;
}

export interface SearchDisplayProductAdminUsingPOSTRequest {
    searchString: string;
    name?: string;
}

export interface SearchDisplayProductUsingPOSTRequest {
    searchString: string;
}

export interface SetDisplayProductOrderUsingPOST2Request {
    displayProductIds: number;
    categoryId: number;
    name?: string;
}

export interface SetDisplayProductOrderUsingPOST3Request {
    displayProductIds: number;
    name?: string;
}

export interface UpdateDisplayProductOrderUsingPOSTRequest {
    displayProductId: number;
    name?: string;
    order?: number;
}

export interface UpdateDisplayProductUsingPOSTRequest {
    displayProductId: number;
    name?: string;
    displayProductNameChi?: string;
    displayProductNameEng?: string;
    displayProductUrlNaming?: string;
    displayProductPrice?: number;
    displayProductOriginalPrice?: number;
    displayProductImage?: string;
    displayProductShortDescription?: string;
    displayProductDescriptionDetail?: string;
    displayProductDescriptionFeature?: string;
    displayProductDescriptionIngredient?: string;
    displayProductDescriptionUsage?: string;
    displayProductDescriptionUseCount?: string;
    displayProductDescriptionTarget?: string;
    functionalIcon?: string;
    recommended?: string;
    order?: number;
    categoryIds?: number;
    productIds?: number;
    hashtag?: string;
    displayProductNameHtmlChi?: string;
    displayProductNameHtmlEng?: string;
    publisher?: string;
    productType?: string;
    language?: string;
    sku?: string;
    isbn?: string;
    displayProductAuthor?: string;
    bookComment?: string;
    languages?: number;
    awards?: number;
    organizationId?: number;
    displayProductTranslator?: string;
    body?: Date;
}

/**
 * 
 */
export class DisplayProductControllerApi extends runtime.BaseAPI {

    /**
     * addDisplayProduct
     */
    async addDisplayProductUsingPOSTRaw(requestParameters: AddDisplayProductUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DisplayProductDto>> {
        if (requestParameters['displayProductNameChi'] == null) {
            throw new runtime.RequiredError(
                'displayProductNameChi',
                'Required parameter "displayProductNameChi" was null or undefined when calling addDisplayProductUsingPOST().'
            );
        }

        if (requestParameters['displayProductNameEng'] == null) {
            throw new runtime.RequiredError(
                'displayProductNameEng',
                'Required parameter "displayProductNameEng" was null or undefined when calling addDisplayProductUsingPOST().'
            );
        }

        if (requestParameters['displayProductUrlNaming'] == null) {
            throw new runtime.RequiredError(
                'displayProductUrlNaming',
                'Required parameter "displayProductUrlNaming" was null or undefined when calling addDisplayProductUsingPOST().'
            );
        }

        if (requestParameters['displayProductPrice'] == null) {
            throw new runtime.RequiredError(
                'displayProductPrice',
                'Required parameter "displayProductPrice" was null or undefined when calling addDisplayProductUsingPOST().'
            );
        }

        if (requestParameters['displayProductImage'] == null) {
            throw new runtime.RequiredError(
                'displayProductImage',
                'Required parameter "displayProductImage" was null or undefined when calling addDisplayProductUsingPOST().'
            );
        }

        if (requestParameters['displayProductDescriptionDetail'] == null) {
            throw new runtime.RequiredError(
                'displayProductDescriptionDetail',
                'Required parameter "displayProductDescriptionDetail" was null or undefined when calling addDisplayProductUsingPOST().'
            );
        }

        if (requestParameters['displayProductDescriptionFeature'] == null) {
            throw new runtime.RequiredError(
                'displayProductDescriptionFeature',
                'Required parameter "displayProductDescriptionFeature" was null or undefined when calling addDisplayProductUsingPOST().'
            );
        }

        if (requestParameters['displayProductDescriptionIngredient'] == null) {
            throw new runtime.RequiredError(
                'displayProductDescriptionIngredient',
                'Required parameter "displayProductDescriptionIngredient" was null or undefined when calling addDisplayProductUsingPOST().'
            );
        }

        if (requestParameters['displayProductDescriptionUsage'] == null) {
            throw new runtime.RequiredError(
                'displayProductDescriptionUsage',
                'Required parameter "displayProductDescriptionUsage" was null or undefined when calling addDisplayProductUsingPOST().'
            );
        }

        if (requestParameters['displayProductDescriptionUseCount'] == null) {
            throw new runtime.RequiredError(
                'displayProductDescriptionUseCount',
                'Required parameter "displayProductDescriptionUseCount" was null or undefined when calling addDisplayProductUsingPOST().'
            );
        }

        if (requestParameters['displayProductDescriptionTarget'] == null) {
            throw new runtime.RequiredError(
                'displayProductDescriptionTarget',
                'Required parameter "displayProductDescriptionTarget" was null or undefined when calling addDisplayProductUsingPOST().'
            );
        }

        if (requestParameters['functionalIcon'] == null) {
            throw new runtime.RequiredError(
                'functionalIcon',
                'Required parameter "functionalIcon" was null or undefined when calling addDisplayProductUsingPOST().'
            );
        }

        if (requestParameters['productIds'] == null) {
            throw new runtime.RequiredError(
                'productIds',
                'Required parameter "productIds" was null or undefined when calling addDisplayProductUsingPOST().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        if (requestParameters['displayProductNameChi'] != null) {
            queryParameters['displayProductNameChi'] = requestParameters['displayProductNameChi'];
        }

        if (requestParameters['displayProductNameEng'] != null) {
            queryParameters['displayProductNameEng'] = requestParameters['displayProductNameEng'];
        }

        if (requestParameters['displayProductUrlNaming'] != null) {
            queryParameters['displayProductUrlNaming'] = requestParameters['displayProductUrlNaming'];
        }

        if (requestParameters['displayProductPrice'] != null) {
            queryParameters['displayProductPrice'] = requestParameters['displayProductPrice'];
        }

        if (requestParameters['displayProductOriginalPrice'] != null) {
            queryParameters['displayProductOriginalPrice'] = requestParameters['displayProductOriginalPrice'];
        }

        if (requestParameters['displayProductImage'] != null) {
            queryParameters['displayProductImage'] = requestParameters['displayProductImage'];
        }

        if (requestParameters['displayProductShortDescription'] != null) {
            queryParameters['displayProductShortDescription'] = requestParameters['displayProductShortDescription'];
        }

        if (requestParameters['displayProductDescriptionDetail'] != null) {
            queryParameters['displayProductDescriptionDetail'] = requestParameters['displayProductDescriptionDetail'];
        }

        if (requestParameters['displayProductDescriptionFeature'] != null) {
            queryParameters['displayProductDescriptionFeature'] = requestParameters['displayProductDescriptionFeature'];
        }

        if (requestParameters['displayProductDescriptionIngredient'] != null) {
            queryParameters['displayProductDescriptionIngredient'] = requestParameters['displayProductDescriptionIngredient'];
        }

        if (requestParameters['displayProductDescriptionUsage'] != null) {
            queryParameters['displayProductDescriptionUsage'] = requestParameters['displayProductDescriptionUsage'];
        }

        if (requestParameters['displayProductDescriptionUseCount'] != null) {
            queryParameters['displayProductDescriptionUseCount'] = requestParameters['displayProductDescriptionUseCount'];
        }

        if (requestParameters['displayProductDescriptionTarget'] != null) {
            queryParameters['displayProductDescriptionTarget'] = requestParameters['displayProductDescriptionTarget'];
        }

        if (requestParameters['functionalIcon'] != null) {
            queryParameters['functionalIcon'] = requestParameters['functionalIcon'];
        }

        if (requestParameters['recommended'] != null) {
            queryParameters['recommended'] = requestParameters['recommended'];
        }

        if (requestParameters['onShelfDate'] != null) {
            queryParameters['onShelfDate'] = (requestParameters['onShelfDate'] as any).toISOString();
        }

        if (requestParameters['offShelfDate'] != null) {
            queryParameters['offShelfDate'] = (requestParameters['offShelfDate'] as any).toISOString();
        }

        if (requestParameters['order'] != null) {
            queryParameters['order'] = requestParameters['order'];
        }

        if (requestParameters['categoryIds'] != null) {
            queryParameters['categoryIds'] = requestParameters['categoryIds'];
        }

        if (requestParameters['productIds'] != null) {
            queryParameters['productIds'] = requestParameters['productIds'];
        }

        if (requestParameters['hashtag'] != null) {
            queryParameters['hashtag'] = requestParameters['hashtag'];
        }

        if (requestParameters['displayProductNameHtmlChi'] != null) {
            queryParameters['displayProductNameHtmlChi'] = requestParameters['displayProductNameHtmlChi'];
        }

        if (requestParameters['displayProductNameHtmlEng'] != null) {
            queryParameters['displayProductNameHtmlEng'] = requestParameters['displayProductNameHtmlEng'];
        }

        if (requestParameters['publisher'] != null) {
            queryParameters['publisher'] = requestParameters['publisher'];
        }

        if (requestParameters['productType'] != null) {
            queryParameters['productType'] = requestParameters['productType'];
        }

        if (requestParameters['language'] != null) {
            queryParameters['language'] = requestParameters['language'];
        }

        if (requestParameters['sku'] != null) {
            queryParameters['sku'] = requestParameters['sku'];
        }

        if (requestParameters['isbn'] != null) {
            queryParameters['isbn'] = requestParameters['isbn'];
        }

        if (requestParameters['displayProductAuthor'] != null) {
            queryParameters['displayProductAuthor'] = requestParameters['displayProductAuthor'];
        }

        if (requestParameters['bookComment'] != null) {
            queryParameters['bookComment'] = requestParameters['bookComment'];
        }

        if (requestParameters['languages'] != null) {
            queryParameters['languages'] = requestParameters['languages'];
        }

        if (requestParameters['awards'] != null) {
            queryParameters['awards'] = requestParameters['awards'];
        }

        if (requestParameters['organizationId'] != null) {
            queryParameters['organizationId'] = requestParameters['organizationId'];
        }

        if (requestParameters['displayProductTranslator'] != null) {
            queryParameters['displayProductTranslator'] = requestParameters['displayProductTranslator'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/displayProduct/add`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DisplayProductDtoFromJSON(jsonValue));
    }

    /**
     * addDisplayProduct
     */
    async addDisplayProductUsingPOST(requestParameters: AddDisplayProductUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DisplayProductDto | null | undefined > {
        const response = await this.addDisplayProductUsingPOSTRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 201:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * deleteProduct
     */
    async deleteProductUsingPOSTRaw(requestParameters: DeleteProductUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DisplayProductDto>> {
        if (requestParameters['displayProductId'] == null) {
            throw new runtime.RequiredError(
                'displayProductId',
                'Required parameter "displayProductId" was null or undefined when calling deleteProductUsingPOST().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/displayProduct/delete/{displayProductId}`.replace(`{${"displayProductId"}}`, encodeURIComponent(String(requestParameters['displayProductId']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DisplayProductDtoFromJSON(jsonValue));
    }

    /**
     * deleteProduct
     */
    async deleteProductUsingPOST(requestParameters: DeleteProductUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DisplayProductDto | null | undefined > {
        const response = await this.deleteProductUsingPOSTRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 201:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * getAllDisplayProductAdmin
     */
    async getAllDisplayProductAdminUsingGETRaw(requestParameters: GetAllDisplayProductAdminUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<DisplayProductDto>>> {
        const queryParameters: any = {};

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/displayProduct/admin/all`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(DisplayProductDtoFromJSON));
    }

    /**
     * getAllDisplayProductAdmin
     */
    async getAllDisplayProductAdminUsingGET(requestParameters: GetAllDisplayProductAdminUsingGETRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<DisplayProductDto>> {
        const response = await this.getAllDisplayProductAdminUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getAllDisplayProductCSV
     */
    async getAllDisplayProductCSVUsingGETRaw(requestParameters: GetAllDisplayProductCSVUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Resource>> {
        const queryParameters: any = {};

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/displayProduct/all_facebook`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResourceFromJSON(jsonValue));
    }

    /**
     * getAllDisplayProductCSV
     */
    async getAllDisplayProductCSVUsingGET(requestParameters: GetAllDisplayProductCSVUsingGETRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Resource> {
        const response = await this.getAllDisplayProductCSVUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getAllDisplayProductTxt
     */
    async getAllDisplayProductTxtUsingGETRaw(requestParameters: GetAllDisplayProductTxtUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Resource>> {
        const queryParameters: any = {};

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/displayProduct/all_google`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResourceFromJSON(jsonValue));
    }

    /**
     * getAllDisplayProductTxt
     */
    async getAllDisplayProductTxtUsingGET(requestParameters: GetAllDisplayProductTxtUsingGETRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Resource> {
        const response = await this.getAllDisplayProductTxtUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getAllDisplayProduct
     */
    async getAllDisplayProductUsingGETRaw(requestParameters: GetAllDisplayProductUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<DisplayProductMember2Dto>>> {
        const queryParameters: any = {};

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/displayProduct/all`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(DisplayProductMember2DtoFromJSON));
    }

    /**
     * getAllDisplayProduct
     */
    async getAllDisplayProductUsingGET(requestParameters: GetAllDisplayProductUsingGETRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<DisplayProductMember2Dto>> {
        const response = await this.getAllDisplayProductUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getDisplayProductByCategoryIdAdmin
     */
    async getDisplayProductByCategoryIdAdminUsingGETRaw(requestParameters: GetDisplayProductByCategoryIdAdminUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<DisplayProductDto>>> {
        if (requestParameters['categoryId'] == null) {
            throw new runtime.RequiredError(
                'categoryId',
                'Required parameter "categoryId" was null or undefined when calling getDisplayProductByCategoryIdAdminUsingGET().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/displayProduct/admin/category/{categoryId}`.replace(`{${"categoryId"}}`, encodeURIComponent(String(requestParameters['categoryId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(DisplayProductDtoFromJSON));
    }

    /**
     * getDisplayProductByCategoryIdAdmin
     */
    async getDisplayProductByCategoryIdAdminUsingGET(requestParameters: GetDisplayProductByCategoryIdAdminUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<DisplayProductDto>> {
        const response = await this.getDisplayProductByCategoryIdAdminUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getDisplayProductByDisplayProductIdAdmin
     */
    async getDisplayProductByDisplayProductIdAdminUsingGETRaw(requestParameters: GetDisplayProductByDisplayProductIdAdminUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DisplayProductMember2Dto>> {
        if (requestParameters['displayProductId'] == null) {
            throw new runtime.RequiredError(
                'displayProductId',
                'Required parameter "displayProductId" was null or undefined when calling getDisplayProductByDisplayProductIdAdminUsingGET().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/displayProduct/admin/{displayProductId}`.replace(`{${"displayProductId"}}`, encodeURIComponent(String(requestParameters['displayProductId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DisplayProductMember2DtoFromJSON(jsonValue));
    }

    /**
     * getDisplayProductByDisplayProductIdAdmin
     */
    async getDisplayProductByDisplayProductIdAdminUsingGET(requestParameters: GetDisplayProductByDisplayProductIdAdminUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DisplayProductMember2Dto> {
        const response = await this.getDisplayProductByDisplayProductIdAdminUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getDisplayProductByDisplayProductUrlNaming
     */
    async getDisplayProductByDisplayProductUrlNamingUsingGETRaw(requestParameters: GetDisplayProductByDisplayProductUrlNamingUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DisplayProductMember2Dto>> {
        if (requestParameters['idOrUrlNaming'] == null) {
            throw new runtime.RequiredError(
                'idOrUrlNaming',
                'Required parameter "idOrUrlNaming" was null or undefined when calling getDisplayProductByDisplayProductUrlNamingUsingGET().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/displayProduct/{idOrUrlNaming}`.replace(`{${"idOrUrlNaming"}}`, encodeURIComponent(String(requestParameters['idOrUrlNaming']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DisplayProductMember2DtoFromJSON(jsonValue));
    }

    /**
     * getDisplayProductByDisplayProductUrlNaming
     */
    async getDisplayProductByDisplayProductUrlNamingUsingGET(requestParameters: GetDisplayProductByDisplayProductUrlNamingUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DisplayProductMember2Dto> {
        const response = await this.getDisplayProductByDisplayProductUrlNamingUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getDisplayProductByHashtagNameAdmin
     */
    async getDisplayProductByHashtagNameAdminUsingGETRaw(requestParameters: GetDisplayProductByHashtagNameAdminUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<DisplayProductDto>>> {
        if (requestParameters['hashtagName'] == null) {
            throw new runtime.RequiredError(
                'hashtagName',
                'Required parameter "hashtagName" was null or undefined when calling getDisplayProductByHashtagNameAdminUsingGET().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/displayProduct/admin/hashtag/{hashtagName}`.replace(`{${"hashtagName"}}`, encodeURIComponent(String(requestParameters['hashtagName']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(DisplayProductDtoFromJSON));
    }

    /**
     * getDisplayProductByHashtagNameAdmin
     */
    async getDisplayProductByHashtagNameAdminUsingGET(requestParameters: GetDisplayProductByHashtagNameAdminUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<DisplayProductDto>> {
        const response = await this.getDisplayProductByHashtagNameAdminUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getDisplayProductByOrganizationId
     */
    async getDisplayProductByOrganizationIdUsingGETRaw(requestParameters: GetDisplayProductByOrganizationIdUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<DisplayProductMember2Dto>>> {
        if (requestParameters['organizationId'] == null) {
            throw new runtime.RequiredError(
                'organizationId',
                'Required parameter "organizationId" was null or undefined when calling getDisplayProductByOrganizationIdUsingGET().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/displayProduct/organization/{organizationId}`.replace(`{${"organizationId"}}`, encodeURIComponent(String(requestParameters['organizationId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(DisplayProductMember2DtoFromJSON));
    }

    /**
     * getDisplayProductByOrganizationId
     */
    async getDisplayProductByOrganizationIdUsingGET(requestParameters: GetDisplayProductByOrganizationIdUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<DisplayProductMember2Dto>> {
        const response = await this.getDisplayProductByOrganizationIdUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getPagedDisplayProductByCategoryId
     */
    async getPagedDisplayProductByCategoryIdUsingGETRaw(requestParameters: GetPagedDisplayProductByCategoryIdUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        if (requestParameters['page'] == null) {
            throw new runtime.RequiredError(
                'page',
                'Required parameter "page" was null or undefined when calling getPagedDisplayProductByCategoryIdUsingGET().'
            );
        }

        if (requestParameters['size'] == null) {
            throw new runtime.RequiredError(
                'size',
                'Required parameter "size" was null or undefined when calling getPagedDisplayProductByCategoryIdUsingGET().'
            );
        }

        if (requestParameters['categoryIdOrUrlNaming'] == null) {
            throw new runtime.RequiredError(
                'categoryIdOrUrlNaming',
                'Required parameter "categoryIdOrUrlNaming" was null or undefined when calling getPagedDisplayProductByCategoryIdUsingGET().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        if (requestParameters['size'] != null) {
            queryParameters['size'] = requestParameters['size'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/displayProduct/category/{categoryIdOrUrlNaming}`.replace(`{${"categoryIdOrUrlNaming"}}`, encodeURIComponent(String(requestParameters['categoryIdOrUrlNaming']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * getPagedDisplayProductByCategoryId
     */
    async getPagedDisplayProductByCategoryIdUsingGET(requestParameters: GetPagedDisplayProductByCategoryIdUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object> {
        const response = await this.getPagedDisplayProductByCategoryIdUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getPagedDisplayProductByHashtagName
     */
    async getPagedDisplayProductByHashtagNameUsingGETRaw(requestParameters: GetPagedDisplayProductByHashtagNameUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        if (requestParameters['page'] == null) {
            throw new runtime.RequiredError(
                'page',
                'Required parameter "page" was null or undefined when calling getPagedDisplayProductByHashtagNameUsingGET().'
            );
        }

        if (requestParameters['size'] == null) {
            throw new runtime.RequiredError(
                'size',
                'Required parameter "size" was null or undefined when calling getPagedDisplayProductByHashtagNameUsingGET().'
            );
        }

        if (requestParameters['hashtagName'] == null) {
            throw new runtime.RequiredError(
                'hashtagName',
                'Required parameter "hashtagName" was null or undefined when calling getPagedDisplayProductByHashtagNameUsingGET().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        if (requestParameters['size'] != null) {
            queryParameters['size'] = requestParameters['size'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/displayProduct/hashtag/{hashtagName}`.replace(`{${"hashtagName"}}`, encodeURIComponent(String(requestParameters['hashtagName']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * getPagedDisplayProductByHashtagName
     */
    async getPagedDisplayProductByHashtagNameUsingGET(requestParameters: GetPagedDisplayProductByHashtagNameUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object> {
        const response = await this.getPagedDisplayProductByHashtagNameUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * searchDisplayProductAdmin
     */
    async searchDisplayProductAdminUsingPOSTRaw(requestParameters: SearchDisplayProductAdminUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<DisplayProductDto>>> {
        if (requestParameters['searchString'] == null) {
            throw new runtime.RequiredError(
                'searchString',
                'Required parameter "searchString" was null or undefined when calling searchDisplayProductAdminUsingPOST().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        if (requestParameters['searchString'] != null) {
            queryParameters['searchString'] = requestParameters['searchString'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/displayProduct/admin/search`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(DisplayProductDtoFromJSON));
    }

    /**
     * searchDisplayProductAdmin
     */
    async searchDisplayProductAdminUsingPOST(requestParameters: SearchDisplayProductAdminUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<DisplayProductDto> | null | undefined > {
        const response = await this.searchDisplayProductAdminUsingPOSTRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 201:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * searchDisplayProduct
     */
    async searchDisplayProductUsingPOSTRaw(requestParameters: SearchDisplayProductUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<DisplayProductDto>>> {
        if (requestParameters['searchString'] == null) {
            throw new runtime.RequiredError(
                'searchString',
                'Required parameter "searchString" was null or undefined when calling searchDisplayProductUsingPOST().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['searchString'] != null) {
            queryParameters['searchString'] = requestParameters['searchString'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/displayProduct/search`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(DisplayProductDtoFromJSON));
    }

    /**
     * searchDisplayProduct
     */
    async searchDisplayProductUsingPOST(requestParameters: SearchDisplayProductUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<DisplayProductDto> | null | undefined > {
        const response = await this.searchDisplayProductUsingPOSTRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 201:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * setDisplayProductOrder
     */
    async setDisplayProductOrderUsingPOST2Raw(requestParameters: SetDisplayProductOrderUsingPOST2Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters['displayProductIds'] == null) {
            throw new runtime.RequiredError(
                'displayProductIds',
                'Required parameter "displayProductIds" was null or undefined when calling setDisplayProductOrderUsingPOST2().'
            );
        }

        if (requestParameters['categoryId'] == null) {
            throw new runtime.RequiredError(
                'categoryId',
                'Required parameter "categoryId" was null or undefined when calling setDisplayProductOrderUsingPOST2().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        if (requestParameters['displayProductIds'] != null) {
            queryParameters['displayProductIds'] = requestParameters['displayProductIds'];
        }

        if (requestParameters['categoryId'] != null) {
            queryParameters['categoryId'] = requestParameters['categoryId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/displayProduct/category/order/set`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * setDisplayProductOrder
     */
    async setDisplayProductOrderUsingPOST2(requestParameters: SetDisplayProductOrderUsingPOST2Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string | null | undefined > {
        const response = await this.setDisplayProductOrderUsingPOST2Raw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 201:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * setDisplayProductOrder
     */
    async setDisplayProductOrderUsingPOST3Raw(requestParameters: SetDisplayProductOrderUsingPOST3Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters['displayProductIds'] == null) {
            throw new runtime.RequiredError(
                'displayProductIds',
                'Required parameter "displayProductIds" was null or undefined when calling setDisplayProductOrderUsingPOST3().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        if (requestParameters['displayProductIds'] != null) {
            queryParameters['displayProductIds'] = requestParameters['displayProductIds'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/displayProduct/order/set`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * setDisplayProductOrder
     */
    async setDisplayProductOrderUsingPOST3(requestParameters: SetDisplayProductOrderUsingPOST3Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string | null | undefined > {
        const response = await this.setDisplayProductOrderUsingPOST3Raw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 201:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * updateDisplayProductOrder
     */
    async updateDisplayProductOrderUsingPOSTRaw(requestParameters: UpdateDisplayProductOrderUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DisplayProductDto>> {
        if (requestParameters['displayProductId'] == null) {
            throw new runtime.RequiredError(
                'displayProductId',
                'Required parameter "displayProductId" was null or undefined when calling updateDisplayProductOrderUsingPOST().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        if (requestParameters['displayProductId'] != null) {
            queryParameters['displayProductId'] = requestParameters['displayProductId'];
        }

        if (requestParameters['order'] != null) {
            queryParameters['order'] = requestParameters['order'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/displayProduct/updateDisplayProductOrder`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DisplayProductDtoFromJSON(jsonValue));
    }

    /**
     * updateDisplayProductOrder
     */
    async updateDisplayProductOrderUsingPOST(requestParameters: UpdateDisplayProductOrderUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DisplayProductDto | null | undefined > {
        const response = await this.updateDisplayProductOrderUsingPOSTRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 201:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * updateDisplayProduct
     */
    async updateDisplayProductUsingPOSTRaw(requestParameters: UpdateDisplayProductUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DisplayProductDto>> {
        if (requestParameters['displayProductId'] == null) {
            throw new runtime.RequiredError(
                'displayProductId',
                'Required parameter "displayProductId" was null or undefined when calling updateDisplayProductUsingPOST().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        if (requestParameters['displayProductId'] != null) {
            queryParameters['displayProductId'] = requestParameters['displayProductId'];
        }

        if (requestParameters['displayProductNameChi'] != null) {
            queryParameters['displayProductNameChi'] = requestParameters['displayProductNameChi'];
        }

        if (requestParameters['displayProductNameEng'] != null) {
            queryParameters['displayProductNameEng'] = requestParameters['displayProductNameEng'];
        }

        if (requestParameters['displayProductUrlNaming'] != null) {
            queryParameters['displayProductUrlNaming'] = requestParameters['displayProductUrlNaming'];
        }

        if (requestParameters['displayProductPrice'] != null) {
            queryParameters['displayProductPrice'] = requestParameters['displayProductPrice'];
        }

        if (requestParameters['displayProductOriginalPrice'] != null) {
            queryParameters['displayProductOriginalPrice'] = requestParameters['displayProductOriginalPrice'];
        }

        if (requestParameters['displayProductImage'] != null) {
            queryParameters['displayProductImage'] = requestParameters['displayProductImage'];
        }

        if (requestParameters['displayProductShortDescription'] != null) {
            queryParameters['displayProductShortDescription'] = requestParameters['displayProductShortDescription'];
        }

        if (requestParameters['displayProductDescriptionDetail'] != null) {
            queryParameters['displayProductDescriptionDetail'] = requestParameters['displayProductDescriptionDetail'];
        }

        if (requestParameters['displayProductDescriptionFeature'] != null) {
            queryParameters['displayProductDescriptionFeature'] = requestParameters['displayProductDescriptionFeature'];
        }

        if (requestParameters['displayProductDescriptionIngredient'] != null) {
            queryParameters['displayProductDescriptionIngredient'] = requestParameters['displayProductDescriptionIngredient'];
        }

        if (requestParameters['displayProductDescriptionUsage'] != null) {
            queryParameters['displayProductDescriptionUsage'] = requestParameters['displayProductDescriptionUsage'];
        }

        if (requestParameters['displayProductDescriptionUseCount'] != null) {
            queryParameters['displayProductDescriptionUseCount'] = requestParameters['displayProductDescriptionUseCount'];
        }

        if (requestParameters['displayProductDescriptionTarget'] != null) {
            queryParameters['displayProductDescriptionTarget'] = requestParameters['displayProductDescriptionTarget'];
        }

        if (requestParameters['functionalIcon'] != null) {
            queryParameters['functionalIcon'] = requestParameters['functionalIcon'];
        }

        if (requestParameters['recommended'] != null) {
            queryParameters['recommended'] = requestParameters['recommended'];
        }

        if (requestParameters['order'] != null) {
            queryParameters['order'] = requestParameters['order'];
        }

        if (requestParameters['categoryIds'] != null) {
            queryParameters['categoryIds'] = requestParameters['categoryIds'];
        }

        if (requestParameters['productIds'] != null) {
            queryParameters['productIds'] = requestParameters['productIds'];
        }

        if (requestParameters['hashtag'] != null) {
            queryParameters['hashtag'] = requestParameters['hashtag'];
        }

        if (requestParameters['displayProductNameHtmlChi'] != null) {
            queryParameters['displayProductNameHtmlChi'] = requestParameters['displayProductNameHtmlChi'];
        }

        if (requestParameters['displayProductNameHtmlEng'] != null) {
            queryParameters['displayProductNameHtmlEng'] = requestParameters['displayProductNameHtmlEng'];
        }

        if (requestParameters['publisher'] != null) {
            queryParameters['publisher'] = requestParameters['publisher'];
        }

        if (requestParameters['productType'] != null) {
            queryParameters['productType'] = requestParameters['productType'];
        }

        if (requestParameters['language'] != null) {
            queryParameters['language'] = requestParameters['language'];
        }

        if (requestParameters['sku'] != null) {
            queryParameters['sku'] = requestParameters['sku'];
        }

        if (requestParameters['isbn'] != null) {
            queryParameters['isbn'] = requestParameters['isbn'];
        }

        if (requestParameters['displayProductAuthor'] != null) {
            queryParameters['displayProductAuthor'] = requestParameters['displayProductAuthor'];
        }

        if (requestParameters['bookComment'] != null) {
            queryParameters['bookComment'] = requestParameters['bookComment'];
        }

        if (requestParameters['languages'] != null) {
            queryParameters['languages'] = requestParameters['languages'];
        }

        if (requestParameters['awards'] != null) {
            queryParameters['awards'] = requestParameters['awards'];
        }

        if (requestParameters['organizationId'] != null) {
            queryParameters['organizationId'] = requestParameters['organizationId'];
        }

        if (requestParameters['displayProductTranslator'] != null) {
            queryParameters['displayProductTranslator'] = requestParameters['displayProductTranslator'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/displayProduct/update`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters['body'] as any,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DisplayProductDtoFromJSON(jsonValue));
    }

    /**
     * updateDisplayProduct
     */
    async updateDisplayProductUsingPOST(requestParameters: UpdateDisplayProductUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DisplayProductDto | null | undefined > {
        const response = await this.updateDisplayProductUsingPOSTRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 201:
                return null;
            default:
                return await response.value();
        }
    }

}
