/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { MemberDto } from './MemberDto';
import {
    MemberDtoFromJSON,
    MemberDtoFromJSONTyped,
    MemberDtoToJSON,
} from './MemberDto';
import type { OrderDto } from './OrderDto';
import {
    OrderDtoFromJSON,
    OrderDtoFromJSONTyped,
    OrderDtoToJSON,
} from './OrderDto';

/**
 * 
 * @export
 * @interface UserPointRecordDto
 */
export interface UserPointRecordDto {
    /**
     * 
     * @type {MemberDto}
     * @memberof UserPointRecordDto
     */
    member?: MemberDto;
    /**
     * 
     * @type {number}
     * @memberof UserPointRecordDto
     */
    memberId?: number;
    /**
     * 
     * @type {OrderDto}
     * @memberof UserPointRecordDto
     */
    order?: OrderDto;
    /**
     * 
     * @type {number}
     * @memberof UserPointRecordDto
     */
    orderId?: number;
    /**
     * 
     * @type {Date}
     * @memberof UserPointRecordDto
     */
    userPointRecordDate?: Date;
    /**
     * 
     * @type {number}
     * @memberof UserPointRecordDto
     */
    userPointRecordId?: number;
    /**
     * 
     * @type {number}
     * @memberof UserPointRecordDto
     */
    userPointRecordNum?: number;
}

/**
 * Check if a given object implements the UserPointRecordDto interface.
 */
export function instanceOfUserPointRecordDto(value: object): boolean {
    return true;
}

export function UserPointRecordDtoFromJSON(json: any): UserPointRecordDto {
    return UserPointRecordDtoFromJSONTyped(json, false);
}

export function UserPointRecordDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserPointRecordDto {
    if (json == null) {
        return json;
    }
    return {
        
        'member': json['member'] == null ? undefined : MemberDtoFromJSON(json['member']),
        'memberId': json['memberId'] == null ? undefined : json['memberId'],
        'order': json['order'] == null ? undefined : OrderDtoFromJSON(json['order']),
        'orderId': json['orderId'] == null ? undefined : json['orderId'],
        'userPointRecordDate': json['userPointRecordDate'] == null ? undefined : (new Date(json['userPointRecordDate'])),
        'userPointRecordId': json['userPointRecordId'] == null ? undefined : json['userPointRecordId'],
        'userPointRecordNum': json['userPointRecordNum'] == null ? undefined : json['userPointRecordNum'],
    };
}

export function UserPointRecordDtoToJSON(value?: UserPointRecordDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'member': MemberDtoToJSON(value['member']),
        'memberId': value['memberId'],
        'order': OrderDtoToJSON(value['order']),
        'orderId': value['orderId'],
        'userPointRecordDate': value['userPointRecordDate'] == null ? undefined : ((value['userPointRecordDate']).toISOString()),
        'userPointRecordId': value['userPointRecordId'],
        'userPointRecordNum': value['userPointRecordNum'],
    };
}

