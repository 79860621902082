/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { OrganizationMapping } from './OrganizationMapping';
import {
    OrganizationMappingFromJSON,
    OrganizationMappingFromJSONTyped,
    OrganizationMappingToJSON,
} from './OrganizationMapping';

/**
 * 
 * @export
 * @interface Organization
 */
export interface Organization {
    /**
     * 
     * @type {string}
     * @memberof Organization
     */
    bannerUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof Organization
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof Organization
     */
    fullName?: string;
    /**
     * 
     * @type {string}
     * @memberof Organization
     */
    logoUrl?: string;
    /**
     * 
     * @type {Array<OrganizationMapping>}
     * @memberof Organization
     */
    mainOrganizationMappingByOrganizationId?: Array<OrganizationMapping>;
    /**
     * 
     * @type {string}
     * @memberof Organization
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof Organization
     */
    nameEng?: string;
    /**
     * 
     * @type {string}
     * @memberof Organization
     */
    nameZhCn?: string;
    /**
     * 
     * @type {string}
     * @memberof Organization
     */
    nameZhHk?: string;
    /**
     * 
     * @type {number}
     * @memberof Organization
     */
    organizationId?: number;
    /**
     * 
     * @type {number}
     * @memberof Organization
     */
    seq?: number;
    /**
     * 
     * @type {number}
     * @memberof Organization
     */
    serverId?: number;
    /**
     * 
     * @type {string}
     * @memberof Organization
     */
    shortName?: string;
    /**
     * 
     * @type {string}
     * @memberof Organization
     */
    status?: string;
    /**
     * 
     * @type {Array<OrganizationMapping>}
     * @memberof Organization
     */
    subOrganizationMappingByOrganizationId?: Array<OrganizationMapping>;
    /**
     * 
     * @type {string}
     * @memberof Organization
     */
    webUrl?: string;
}

/**
 * Check if a given object implements the Organization interface.
 */
export function instanceOfOrganization(value: object): boolean {
    return true;
}

export function OrganizationFromJSON(json: any): Organization {
    return OrganizationFromJSONTyped(json, false);
}

export function OrganizationFromJSONTyped(json: any, ignoreDiscriminator: boolean): Organization {
    if (json == null) {
        return json;
    }
    return {
        
        'bannerUrl': json['bannerUrl'] == null ? undefined : json['bannerUrl'],
        'description': json['description'] == null ? undefined : json['description'],
        'fullName': json['fullName'] == null ? undefined : json['fullName'],
        'logoUrl': json['logoUrl'] == null ? undefined : json['logoUrl'],
        'mainOrganizationMappingByOrganizationId': json['mainOrganizationMappingByOrganizationId'] == null ? undefined : ((json['mainOrganizationMappingByOrganizationId'] as Array<any>).map(OrganizationMappingFromJSON)),
        'name': json['name'] == null ? undefined : json['name'],
        'nameEng': json['nameEng'] == null ? undefined : json['nameEng'],
        'nameZhCn': json['nameZhCn'] == null ? undefined : json['nameZhCn'],
        'nameZhHk': json['nameZhHk'] == null ? undefined : json['nameZhHk'],
        'organizationId': json['organizationId'] == null ? undefined : json['organizationId'],
        'seq': json['seq'] == null ? undefined : json['seq'],
        'serverId': json['serverId'] == null ? undefined : json['serverId'],
        'shortName': json['shortName'] == null ? undefined : json['shortName'],
        'status': json['status'] == null ? undefined : json['status'],
        'subOrganizationMappingByOrganizationId': json['subOrganizationMappingByOrganizationId'] == null ? undefined : ((json['subOrganizationMappingByOrganizationId'] as Array<any>).map(OrganizationMappingFromJSON)),
        'webUrl': json['webUrl'] == null ? undefined : json['webUrl'],
    };
}

export function OrganizationToJSON(value?: Organization | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'bannerUrl': value['bannerUrl'],
        'description': value['description'],
        'fullName': value['fullName'],
        'logoUrl': value['logoUrl'],
        'mainOrganizationMappingByOrganizationId': value['mainOrganizationMappingByOrganizationId'] == null ? undefined : ((value['mainOrganizationMappingByOrganizationId'] as Array<any>).map(OrganizationMappingToJSON)),
        'name': value['name'],
        'nameEng': value['nameEng'],
        'nameZhCn': value['nameZhCn'],
        'nameZhHk': value['nameZhHk'],
        'organizationId': value['organizationId'],
        'seq': value['seq'],
        'serverId': value['serverId'],
        'shortName': value['shortName'],
        'status': value['status'],
        'subOrganizationMappingByOrganizationId': value['subOrganizationMappingByOrganizationId'] == null ? undefined : ((value['subOrganizationMappingByOrganizationId'] as Array<any>).map(OrganizationMappingToJSON)),
        'webUrl': value['webUrl'],
    };
}

