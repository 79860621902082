/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Resource
 */
export interface Resource {
    /**
     * 
     * @type {string}
     * @memberof Resource
     */
    description?: string;
    /**
     * 
     * @type {Blob}
     * @memberof Resource
     */
    file?: Blob;
    /**
     * 
     * @type {string}
     * @memberof Resource
     */
    filename?: string;
    /**
     * 
     * @type {object}
     * @memberof Resource
     */
    inputStream?: object;
    /**
     * 
     * @type {boolean}
     * @memberof Resource
     */
    open?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Resource
     */
    readable?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Resource
     */
    uri?: string;
    /**
     * 
     * @type {string}
     * @memberof Resource
     */
    url?: string;
}

/**
 * Check if a given object implements the Resource interface.
 */
export function instanceOfResource(value: object): boolean {
    return true;
}

export function ResourceFromJSON(json: any): Resource {
    return ResourceFromJSONTyped(json, false);
}

export function ResourceFromJSONTyped(json: any, ignoreDiscriminator: boolean): Resource {
    if (json == null) {
        return json;
    }
    return {
        
        'description': json['description'] == null ? undefined : json['description'],
        'file': json['file'] == null ? undefined : json['file'],
        'filename': json['filename'] == null ? undefined : json['filename'],
        'inputStream': json['inputStream'] == null ? undefined : json['inputStream'],
        'open': json['open'] == null ? undefined : json['open'],
        'readable': json['readable'] == null ? undefined : json['readable'],
        'uri': json['uri'] == null ? undefined : json['uri'],
        'url': json['url'] == null ? undefined : json['url'],
    };
}

export function ResourceToJSON(value?: Resource | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'description': value['description'],
        'file': value['file'],
        'filename': value['filename'],
        'inputStream': value['inputStream'],
        'open': value['open'],
        'readable': value['readable'],
        'uri': value['uri'],
        'url': value['url'],
    };
}

