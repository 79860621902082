/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Discount } from './Discount';
import {
    DiscountFromJSON,
    DiscountFromJSONTyped,
    DiscountToJSON,
} from './Discount';
import type { MemberShoppingCart } from './MemberShoppingCart';
import {
    MemberShoppingCartFromJSON,
    MemberShoppingCartFromJSONTyped,
    MemberShoppingCartToJSON,
} from './MemberShoppingCart';
import type { OrderCoupon } from './OrderCoupon';
import {
    OrderCouponFromJSON,
    OrderCouponFromJSONTyped,
    OrderCouponToJSON,
} from './OrderCoupon';

/**
 * 
 * @export
 * @interface Coupon
 */
export interface Coupon {
    /**
     * 
     * @type {string}
     * @memberof Coupon
     */
    couponCode?: string;
    /**
     * 
     * @type {number}
     * @memberof Coupon
     */
    couponId?: number;
    /**
     * 
     * @type {Discount}
     * @memberof Coupon
     */
    discountByDiscountId?: Discount;
    /**
     * 
     * @type {number}
     * @memberof Coupon
     */
    discountId?: number;
    /**
     * 
     * @type {Date}
     * @memberof Coupon
     */
    endDate?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof Coupon
     */
    ignore?: boolean;
    /**
     * 
     * @type {Array<OrderCoupon>}
     * @memberof Coupon
     */
    orderCouponsByCouponId?: Array<OrderCoupon>;
    /**
     * 
     * @type {Array<MemberShoppingCart>}
     * @memberof Coupon
     */
    shoppingCartsByCouponId?: Array<MemberShoppingCart>;
    /**
     * 
     * @type {Date}
     * @memberof Coupon
     */
    startDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof Coupon
     */
    status?: string;
    /**
     * 
     * @type {string}
     * @memberof Coupon
     */
    title?: string;
}

/**
 * Check if a given object implements the Coupon interface.
 */
export function instanceOfCoupon(value: object): boolean {
    return true;
}

export function CouponFromJSON(json: any): Coupon {
    return CouponFromJSONTyped(json, false);
}

export function CouponFromJSONTyped(json: any, ignoreDiscriminator: boolean): Coupon {
    if (json == null) {
        return json;
    }
    return {
        
        'couponCode': json['couponCode'] == null ? undefined : json['couponCode'],
        'couponId': json['couponId'] == null ? undefined : json['couponId'],
        'discountByDiscountId': json['discountByDiscountId'] == null ? undefined : DiscountFromJSON(json['discountByDiscountId']),
        'discountId': json['discountId'] == null ? undefined : json['discountId'],
        'endDate': json['endDate'] == null ? undefined : (new Date(json['endDate'])),
        'ignore': json['ignore'] == null ? undefined : json['ignore'],
        'orderCouponsByCouponId': json['orderCouponsByCouponId'] == null ? undefined : ((json['orderCouponsByCouponId'] as Array<any>).map(OrderCouponFromJSON)),
        'shoppingCartsByCouponId': json['shoppingCartsByCouponId'] == null ? undefined : ((json['shoppingCartsByCouponId'] as Array<any>).map(MemberShoppingCartFromJSON)),
        'startDate': json['startDate'] == null ? undefined : (new Date(json['startDate'])),
        'status': json['status'] == null ? undefined : json['status'],
        'title': json['title'] == null ? undefined : json['title'],
    };
}

export function CouponToJSON(value?: Coupon | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'couponCode': value['couponCode'],
        'couponId': value['couponId'],
        'discountByDiscountId': DiscountToJSON(value['discountByDiscountId']),
        'discountId': value['discountId'],
        'endDate': value['endDate'] == null ? undefined : ((value['endDate']).toISOString()),
        'ignore': value['ignore'],
        'orderCouponsByCouponId': value['orderCouponsByCouponId'] == null ? undefined : ((value['orderCouponsByCouponId'] as Array<any>).map(OrderCouponToJSON)),
        'shoppingCartsByCouponId': value['shoppingCartsByCouponId'] == null ? undefined : ((value['shoppingCartsByCouponId'] as Array<any>).map(MemberShoppingCartToJSON)),
        'startDate': value['startDate'] == null ? undefined : ((value['startDate']).toISOString()),
        'status': value['status'],
        'title': value['title'],
    };
}

