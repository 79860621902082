/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface OrderPointRecordDto
 */
export interface OrderPointRecordDto {
    /**
     * 
     * @type {Date}
     * @memberof OrderPointRecordDto
     */
    date?: Date;
    /**
     * 
     * @type {number}
     * @memberof OrderPointRecordDto
     */
    earnedPoint?: number;
    /**
     * 
     * @type {number}
     * @memberof OrderPointRecordDto
     */
    orderId?: number;
    /**
     * 
     * @type {string}
     * @memberof OrderPointRecordDto
     */
    orderNo?: string;
    /**
     * 
     * @type {number}
     * @memberof OrderPointRecordDto
     */
    usedPoint?: number;
}

/**
 * Check if a given object implements the OrderPointRecordDto interface.
 */
export function instanceOfOrderPointRecordDto(value: object): boolean {
    return true;
}

export function OrderPointRecordDtoFromJSON(json: any): OrderPointRecordDto {
    return OrderPointRecordDtoFromJSONTyped(json, false);
}

export function OrderPointRecordDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrderPointRecordDto {
    if (json == null) {
        return json;
    }
    return {
        
        'date': json['date'] == null ? undefined : (new Date(json['date'])),
        'earnedPoint': json['earnedPoint'] == null ? undefined : json['earnedPoint'],
        'orderId': json['orderId'] == null ? undefined : json['orderId'],
        'orderNo': json['orderNo'] == null ? undefined : json['orderNo'],
        'usedPoint': json['usedPoint'] == null ? undefined : json['usedPoint'],
    };
}

export function OrderPointRecordDtoToJSON(value?: OrderPointRecordDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'date': value['date'] == null ? undefined : ((value['date']).toISOString()),
        'earnedPoint': value['earnedPoint'],
        'orderId': value['orderId'],
        'orderNo': value['orderNo'],
        'usedPoint': value['usedPoint'],
    };
}

