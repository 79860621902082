/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { CartProductDiscount } from './CartProductDiscount';
import {
    CartProductDiscountFromJSON,
    CartProductDiscountFromJSONTyped,
    CartProductDiscountToJSON,
} from './CartProductDiscount';
import type { Coupon } from './Coupon';
import {
    CouponFromJSON,
    CouponFromJSONTyped,
    CouponToJSON,
} from './Coupon';
import type { DiscountProduct } from './DiscountProduct';
import {
    DiscountProductFromJSON,
    DiscountProductFromJSONTyped,
    DiscountProductToJSON,
} from './DiscountProduct';
import type { MarketingEventDiscount } from './MarketingEventDiscount';
import {
    MarketingEventDiscountFromJSON,
    MarketingEventDiscountFromJSONTyped,
    MarketingEventDiscountToJSON,
} from './MarketingEventDiscount';
import type { MemberLevelDiscount } from './MemberLevelDiscount';
import {
    MemberLevelDiscountFromJSON,
    MemberLevelDiscountFromJSONTyped,
    MemberLevelDiscountToJSON,
} from './MemberLevelDiscount';

/**
 * 
 * @export
 * @interface Discount
 */
export interface Discount {
    /**
     * 
     * @type {string}
     * @memberof Discount
     */
    actionType?: string;
    /**
     * 
     * @type {number}
     * @memberof Discount
     */
    actionValue?: number;
    /**
     * 
     * @type {Array<CartProductDiscount>}
     * @memberof Discount
     */
    cartProductDiscountsByCartProductId?: Array<CartProductDiscount>;
    /**
     * 
     * @type {string}
     * @memberof Discount
     */
    condition?: string;
    /**
     * 
     * @type {Array<Coupon>}
     * @memberof Discount
     */
    couponsByDiscountId?: Array<Coupon>;
    /**
     * 
     * @type {Date}
     * @memberof Discount
     */
    createAt?: Date;
    /**
     * 
     * @type {number}
     * @memberof Discount
     */
    discountId?: number;
    /**
     * 
     * @type {Array<DiscountProduct>}
     * @memberof Discount
     */
    discountProductsByDiscountId?: Array<DiscountProduct>;
    /**
     * 
     * @type {string}
     * @memberof Discount
     */
    discountTitle?: string;
    /**
     * 
     * @type {string}
     * @memberof Discount
     */
    discountType?: string;
    /**
     * 
     * @type {number}
     * @memberof Discount
     */
    limitToUse?: number;
    /**
     * 
     * @type {number}
     * @memberof Discount
     */
    limitToUseRest?: number;
    /**
     * 
     * @type {Array<MarketingEventDiscount>}
     * @memberof Discount
     */
    marketingEventDiscountsByDiscountId?: Array<MarketingEventDiscount>;
    /**
     * 
     * @type {Array<MemberLevelDiscount>}
     * @memberof Discount
     */
    memberLevelDiscountsByDiscountId?: Array<MemberLevelDiscount>;
    /**
     * 
     * @type {string}
     * @memberof Discount
     */
    status?: string;
    /**
     * 
     * @type {string}
     * @memberof Discount
     */
    target?: string;
}

/**
 * Check if a given object implements the Discount interface.
 */
export function instanceOfDiscount(value: object): boolean {
    return true;
}

export function DiscountFromJSON(json: any): Discount {
    return DiscountFromJSONTyped(json, false);
}

export function DiscountFromJSONTyped(json: any, ignoreDiscriminator: boolean): Discount {
    if (json == null) {
        return json;
    }
    return {
        
        'actionType': json['actionType'] == null ? undefined : json['actionType'],
        'actionValue': json['actionValue'] == null ? undefined : json['actionValue'],
        'cartProductDiscountsByCartProductId': json['cartProductDiscountsByCartProductId'] == null ? undefined : ((json['cartProductDiscountsByCartProductId'] as Array<any>).map(CartProductDiscountFromJSON)),
        'condition': json['condition'] == null ? undefined : json['condition'],
        'couponsByDiscountId': json['couponsByDiscountId'] == null ? undefined : ((json['couponsByDiscountId'] as Array<any>).map(CouponFromJSON)),
        'createAt': json['createAt'] == null ? undefined : (new Date(json['createAt'])),
        'discountId': json['discountId'] == null ? undefined : json['discountId'],
        'discountProductsByDiscountId': json['discountProductsByDiscountId'] == null ? undefined : ((json['discountProductsByDiscountId'] as Array<any>).map(DiscountProductFromJSON)),
        'discountTitle': json['discountTitle'] == null ? undefined : json['discountTitle'],
        'discountType': json['discountType'] == null ? undefined : json['discountType'],
        'limitToUse': json['limitToUse'] == null ? undefined : json['limitToUse'],
        'limitToUseRest': json['limitToUseRest'] == null ? undefined : json['limitToUseRest'],
        'marketingEventDiscountsByDiscountId': json['marketingEventDiscountsByDiscountId'] == null ? undefined : ((json['marketingEventDiscountsByDiscountId'] as Array<any>).map(MarketingEventDiscountFromJSON)),
        'memberLevelDiscountsByDiscountId': json['memberLevelDiscountsByDiscountId'] == null ? undefined : ((json['memberLevelDiscountsByDiscountId'] as Array<any>).map(MemberLevelDiscountFromJSON)),
        'status': json['status'] == null ? undefined : json['status'],
        'target': json['target'] == null ? undefined : json['target'],
    };
}

export function DiscountToJSON(value?: Discount | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'actionType': value['actionType'],
        'actionValue': value['actionValue'],
        'cartProductDiscountsByCartProductId': value['cartProductDiscountsByCartProductId'] == null ? undefined : ((value['cartProductDiscountsByCartProductId'] as Array<any>).map(CartProductDiscountToJSON)),
        'condition': value['condition'],
        'couponsByDiscountId': value['couponsByDiscountId'] == null ? undefined : ((value['couponsByDiscountId'] as Array<any>).map(CouponToJSON)),
        'createAt': value['createAt'] == null ? undefined : ((value['createAt']).toISOString()),
        'discountId': value['discountId'],
        'discountProductsByDiscountId': value['discountProductsByDiscountId'] == null ? undefined : ((value['discountProductsByDiscountId'] as Array<any>).map(DiscountProductToJSON)),
        'discountTitle': value['discountTitle'],
        'discountType': value['discountType'],
        'limitToUse': value['limitToUse'],
        'limitToUseRest': value['limitToUseRest'],
        'marketingEventDiscountsByDiscountId': value['marketingEventDiscountsByDiscountId'] == null ? undefined : ((value['marketingEventDiscountsByDiscountId'] as Array<any>).map(MarketingEventDiscountToJSON)),
        'memberLevelDiscountsByDiscountId': value['memberLevelDiscountsByDiscountId'] == null ? undefined : ((value['memberLevelDiscountsByDiscountId'] as Array<any>).map(MemberLevelDiscountToJSON)),
        'status': value['status'],
        'target': value['target'],
    };
}

