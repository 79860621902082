/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Address } from './Address';
import {
    AddressFromJSON,
    AddressFromJSONTyped,
    AddressToJSON,
} from './Address';
import type { AgeGroup } from './AgeGroup';
import {
    AgeGroupFromJSON,
    AgeGroupFromJSONTyped,
    AgeGroupToJSON,
} from './AgeGroup';
import type { Country } from './Country';
import {
    CountryFromJSON,
    CountryFromJSONTyped,
    CountryToJSON,
} from './Country';
import type { MarketingEventMember } from './MarketingEventMember';
import {
    MarketingEventMemberFromJSON,
    MarketingEventMemberFromJSONTyped,
    MarketingEventMemberToJSON,
} from './MarketingEventMember';
import type { MemberLevel } from './MemberLevel';
import {
    MemberLevelFromJSON,
    MemberLevelFromJSONTyped,
    MemberLevelToJSON,
} from './MemberLevel';
import type { MemberShoppingCart } from './MemberShoppingCart';
import {
    MemberShoppingCartFromJSON,
    MemberShoppingCartFromJSONTyped,
    MemberShoppingCartToJSON,
} from './MemberShoppingCart';
import type { Order } from './Order';
import {
    OrderFromJSON,
    OrderFromJSONTyped,
    OrderToJSON,
} from './Order';
import type { Point } from './Point';
import {
    PointFromJSON,
    PointFromJSONTyped,
    PointToJSON,
} from './Point';
import type { UserPointHistory } from './UserPointHistory';
import {
    UserPointHistoryFromJSON,
    UserPointHistoryFromJSONTyped,
    UserPointHistoryToJSON,
} from './UserPointHistory';

/**
 * 
 * @export
 * @interface Member
 */
export interface Member {
    /**
     * 
     * @type {string}
     * @memberof Member
     */
    acceptPromoption?: string;
    /**
     * 
     * @type {string}
     * @memberof Member
     */
    accessToken?: string;
    /**
     * 
     * @type {string}
     * @memberof Member
     */
    accountStatus?: string;
    /**
     * 
     * @type {string}
     * @memberof Member
     */
    activeToken?: string;
    /**
     * 
     * @type {number}
     * @memberof Member
     */
    address1?: number;
    /**
     * 
     * @type {number}
     * @memberof Member
     */
    address2?: number;
    /**
     * 
     * @type {number}
     * @memberof Member
     */
    address3?: number;
    /**
     * 
     * @type {Address}
     * @memberof Member
     */
    addressByAddress1?: Address;
    /**
     * 
     * @type {Address}
     * @memberof Member
     */
    addressByAddress2?: Address;
    /**
     * 
     * @type {Address}
     * @memberof Member
     */
    addressByAddress3?: Address;
    /**
     * 
     * @type {AgeGroup}
     * @memberof Member
     */
    ageGroupByAgeGroupId?: AgeGroup;
    /**
     * 
     * @type {number}
     * @memberof Member
     */
    ageGroupId?: number;
    /**
     * 
     * @type {string}
     * @memberof Member
     */
    authType?: MemberAuthTypeEnum;
    /**
     * 
     * @type {boolean}
     * @memberof Member
     */
    birthday?: boolean;
    /**
     * 
     * @type {number}
     * @memberof Member
     */
    birthdayMonth?: number;
    /**
     * 
     * @type {boolean}
     * @memberof Member
     */
    birthdayThisMonth?: boolean;
    /**
     * 
     * @type {Country}
     * @memberof Member
     */
    countryByLocationId?: Country;
    /**
     * 
     * @type {Date}
     * @memberof Member
     */
    dateOfBirth?: Date;
    /**
     * 
     * @type {string}
     * @memberof Member
     */
    district?: string;
    /**
     * 
     * @type {string}
     * @memberof Member
     */
    email?: string;
    /**
     * 
     * @type {number}
     * @memberof Member
     */
    expirePoint?: number;
    /**
     * 
     * @type {string}
     * @memberof Member
     */
    facebookId?: string;
    /**
     * 
     * @type {string}
     * @memberof Member
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof Member
     */
    gender?: string;
    /**
     * 
     * @type {string}
     * @memberof Member
     */
    googleId?: string;
    /**
     * 
     * @type {Date}
     * @memberof Member
     */
    joinDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof Member
     */
    lastName?: string;
    /**
     * 
     * @type {number}
     * @memberof Member
     */
    locationId?: number;
    /**
     * 
     * @type {Array<MarketingEventMember>}
     * @memberof Member
     */
    marketingEventMembersByMemberId?: Array<MarketingEventMember>;
    /**
     * 
     * @type {Member}
     * @memberof Member
     */
    memberByReferalMemberId?: Member;
    /**
     * 
     * @type {number}
     * @memberof Member
     */
    memberId?: number;
    /**
     * 
     * @type {MemberLevel}
     * @memberof Member
     */
    memberLevelByMemberLevelId?: MemberLevel;
    /**
     * 
     * @type {number}
     * @memberof Member
     */
    memberLevelId?: number;
    /**
     * 
     * @type {Array<MemberShoppingCart>}
     * @memberof Member
     */
    memberShoppingCartsByMemberId?: Array<MemberShoppingCart>;
    /**
     * 
     * @type {Array<Member>}
     * @memberof Member
     */
    membersByMemberId?: Array<Member>;
    /**
     * 
     * @type {Date}
     * @memberof Member
     */
    membershipExpireDate?: Date;
    /**
     * 
     * @type {Array<Order>}
     * @memberof Member
     */
    ordersByMemberId?: Array<Order>;
    /**
     * 
     * @type {string}
     * @memberof Member
     */
    password?: string;
    /**
     * 
     * @type {string}
     * @memberof Member
     */
    phone?: string;
    /**
     * 
     * @type {Array<Point>}
     * @memberof Member
     */
    pointsByMemberId?: Array<Point>;
    /**
     * 
     * @type {number}
     * @memberof Member
     */
    referalMemberId?: number;
    /**
     * 
     * @type {string}
     * @memberof Member
     */
    status?: string;
    /**
     * 
     * @type {Array<UserPointHistory>}
     * @memberof Member
     */
    userPointHistoriesByMemberId?: Array<UserPointHistory>;
    /**
     * 
     * @type {string}
     * @memberof Member
     */
    username?: string;
    /**
     * 
     * @type {number}
     * @memberof Member
     */
    validPoint?: number;
    /**
     * 
     * @type {string}
     * @memberof Member
     */
    verifyStatus?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Member
     */
    vip?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof Member
     */
    vipJoinDate?: Date;
}


/**
 * @export
 */
export const MemberAuthTypeEnum = {
    Database: 'DATABASE',
    Facebook: 'FACEBOOK',
    Google: 'GOOGLE'
} as const;
export type MemberAuthTypeEnum = typeof MemberAuthTypeEnum[keyof typeof MemberAuthTypeEnum];


/**
 * Check if a given object implements the Member interface.
 */
export function instanceOfMember(value: object): boolean {
    return true;
}

export function MemberFromJSON(json: any): Member {
    return MemberFromJSONTyped(json, false);
}

export function MemberFromJSONTyped(json: any, ignoreDiscriminator: boolean): Member {
    if (json == null) {
        return json;
    }
    return {
        
        'acceptPromoption': json['acceptPromoption'] == null ? undefined : json['acceptPromoption'],
        'accessToken': json['accessToken'] == null ? undefined : json['accessToken'],
        'accountStatus': json['accountStatus'] == null ? undefined : json['accountStatus'],
        'activeToken': json['activeToken'] == null ? undefined : json['activeToken'],
        'address1': json['address1'] == null ? undefined : json['address1'],
        'address2': json['address2'] == null ? undefined : json['address2'],
        'address3': json['address3'] == null ? undefined : json['address3'],
        'addressByAddress1': json['addressByAddress1'] == null ? undefined : AddressFromJSON(json['addressByAddress1']),
        'addressByAddress2': json['addressByAddress2'] == null ? undefined : AddressFromJSON(json['addressByAddress2']),
        'addressByAddress3': json['addressByAddress3'] == null ? undefined : AddressFromJSON(json['addressByAddress3']),
        'ageGroupByAgeGroupId': json['ageGroupByAgeGroupId'] == null ? undefined : AgeGroupFromJSON(json['ageGroupByAgeGroupId']),
        'ageGroupId': json['ageGroupId'] == null ? undefined : json['ageGroupId'],
        'authType': json['authType'] == null ? undefined : json['authType'],
        'birthday': json['birthday'] == null ? undefined : json['birthday'],
        'birthdayMonth': json['birthdayMonth'] == null ? undefined : json['birthdayMonth'],
        'birthdayThisMonth': json['birthdayThisMonth'] == null ? undefined : json['birthdayThisMonth'],
        'countryByLocationId': json['countryByLocationId'] == null ? undefined : CountryFromJSON(json['countryByLocationId']),
        'dateOfBirth': json['dateOfBirth'] == null ? undefined : (new Date(json['dateOfBirth'])),
        'district': json['district'] == null ? undefined : json['district'],
        'email': json['email'] == null ? undefined : json['email'],
        'expirePoint': json['expirePoint'] == null ? undefined : json['expirePoint'],
        'facebookId': json['facebookId'] == null ? undefined : json['facebookId'],
        'firstName': json['firstName'] == null ? undefined : json['firstName'],
        'gender': json['gender'] == null ? undefined : json['gender'],
        'googleId': json['googleId'] == null ? undefined : json['googleId'],
        'joinDate': json['joinDate'] == null ? undefined : (new Date(json['joinDate'])),
        'lastName': json['lastName'] == null ? undefined : json['lastName'],
        'locationId': json['locationId'] == null ? undefined : json['locationId'],
        'marketingEventMembersByMemberId': json['marketingEventMembersByMemberId'] == null ? undefined : ((json['marketingEventMembersByMemberId'] as Array<any>).map(MarketingEventMemberFromJSON)),
        'memberByReferalMemberId': json['memberByReferalMemberId'] == null ? undefined : MemberFromJSON(json['memberByReferalMemberId']),
        'memberId': json['memberId'] == null ? undefined : json['memberId'],
        'memberLevelByMemberLevelId': json['memberLevelByMemberLevelId'] == null ? undefined : MemberLevelFromJSON(json['memberLevelByMemberLevelId']),
        'memberLevelId': json['memberLevelId'] == null ? undefined : json['memberLevelId'],
        'memberShoppingCartsByMemberId': json['memberShoppingCartsByMemberId'] == null ? undefined : ((json['memberShoppingCartsByMemberId'] as Array<any>).map(MemberShoppingCartFromJSON)),
        'membersByMemberId': json['membersByMemberId'] == null ? undefined : ((json['membersByMemberId'] as Array<any>).map(MemberFromJSON)),
        'membershipExpireDate': json['membershipExpireDate'] == null ? undefined : (new Date(json['membershipExpireDate'])),
        'ordersByMemberId': json['ordersByMemberId'] == null ? undefined : ((json['ordersByMemberId'] as Array<any>).map(OrderFromJSON)),
        'password': json['password'] == null ? undefined : json['password'],
        'phone': json['phone'] == null ? undefined : json['phone'],
        'pointsByMemberId': json['pointsByMemberId'] == null ? undefined : ((json['pointsByMemberId'] as Array<any>).map(PointFromJSON)),
        'referalMemberId': json['referalMemberId'] == null ? undefined : json['referalMemberId'],
        'status': json['status'] == null ? undefined : json['status'],
        'userPointHistoriesByMemberId': json['userPointHistoriesByMemberId'] == null ? undefined : ((json['userPointHistoriesByMemberId'] as Array<any>).map(UserPointHistoryFromJSON)),
        'username': json['username'] == null ? undefined : json['username'],
        'validPoint': json['validPoint'] == null ? undefined : json['validPoint'],
        'verifyStatus': json['verifyStatus'] == null ? undefined : json['verifyStatus'],
        'vip': json['vip'] == null ? undefined : json['vip'],
        'vipJoinDate': json['vipJoinDate'] == null ? undefined : (new Date(json['vipJoinDate'])),
    };
}

export function MemberToJSON(value?: Member | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'acceptPromoption': value['acceptPromoption'],
        'accessToken': value['accessToken'],
        'accountStatus': value['accountStatus'],
        'activeToken': value['activeToken'],
        'address1': value['address1'],
        'address2': value['address2'],
        'address3': value['address3'],
        'addressByAddress1': AddressToJSON(value['addressByAddress1']),
        'addressByAddress2': AddressToJSON(value['addressByAddress2']),
        'addressByAddress3': AddressToJSON(value['addressByAddress3']),
        'ageGroupByAgeGroupId': AgeGroupToJSON(value['ageGroupByAgeGroupId']),
        'ageGroupId': value['ageGroupId'],
        'authType': value['authType'],
        'birthday': value['birthday'],
        'birthdayMonth': value['birthdayMonth'],
        'birthdayThisMonth': value['birthdayThisMonth'],
        'countryByLocationId': CountryToJSON(value['countryByLocationId']),
        'dateOfBirth': value['dateOfBirth'] == null ? undefined : ((value['dateOfBirth']).toISOString()),
        'district': value['district'],
        'email': value['email'],
        'expirePoint': value['expirePoint'],
        'facebookId': value['facebookId'],
        'firstName': value['firstName'],
        'gender': value['gender'],
        'googleId': value['googleId'],
        'joinDate': value['joinDate'] == null ? undefined : ((value['joinDate']).toISOString()),
        'lastName': value['lastName'],
        'locationId': value['locationId'],
        'marketingEventMembersByMemberId': value['marketingEventMembersByMemberId'] == null ? undefined : ((value['marketingEventMembersByMemberId'] as Array<any>).map(MarketingEventMemberToJSON)),
        'memberByReferalMemberId': MemberToJSON(value['memberByReferalMemberId']),
        'memberId': value['memberId'],
        'memberLevelByMemberLevelId': MemberLevelToJSON(value['memberLevelByMemberLevelId']),
        'memberLevelId': value['memberLevelId'],
        'memberShoppingCartsByMemberId': value['memberShoppingCartsByMemberId'] == null ? undefined : ((value['memberShoppingCartsByMemberId'] as Array<any>).map(MemberShoppingCartToJSON)),
        'membersByMemberId': value['membersByMemberId'] == null ? undefined : ((value['membersByMemberId'] as Array<any>).map(MemberToJSON)),
        'membershipExpireDate': value['membershipExpireDate'] == null ? undefined : ((value['membershipExpireDate']).toISOString()),
        'ordersByMemberId': value['ordersByMemberId'] == null ? undefined : ((value['ordersByMemberId'] as Array<any>).map(OrderToJSON)),
        'password': value['password'],
        'phone': value['phone'],
        'pointsByMemberId': value['pointsByMemberId'] == null ? undefined : ((value['pointsByMemberId'] as Array<any>).map(PointToJSON)),
        'referalMemberId': value['referalMemberId'],
        'status': value['status'],
        'userPointHistoriesByMemberId': value['userPointHistoriesByMemberId'] == null ? undefined : ((value['userPointHistoriesByMemberId'] as Array<any>).map(UserPointHistoryToJSON)),
        'username': value['username'],
        'validPoint': value['validPoint'],
        'verifyStatus': value['verifyStatus'],
        'vip': value['vip'],
        'vipJoinDate': value['vipJoinDate'] == null ? undefined : ((value['vipJoinDate']).toISOString()),
    };
}

