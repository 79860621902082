/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SubCategoryRequestModel
 */
export interface SubCategoryRequestModel {
    /**
     * 
     * @type {string}
     * @memberof SubCategoryRequestModel
     */
    categoryName?: string;
    /**
     * 
     * @type {string}
     * @memberof SubCategoryRequestModel
     */
    mongoId?: string;
    /**
     * 
     * @type {number}
     * @memberof SubCategoryRequestModel
     */
    seq?: number;
}

/**
 * Check if a given object implements the SubCategoryRequestModel interface.
 */
export function instanceOfSubCategoryRequestModel(value: object): boolean {
    return true;
}

export function SubCategoryRequestModelFromJSON(json: any): SubCategoryRequestModel {
    return SubCategoryRequestModelFromJSONTyped(json, false);
}

export function SubCategoryRequestModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): SubCategoryRequestModel {
    if (json == null) {
        return json;
    }
    return {
        
        'categoryName': json['categoryName'] == null ? undefined : json['categoryName'],
        'mongoId': json['mongoId'] == null ? undefined : json['mongoId'],
        'seq': json['seq'] == null ? undefined : json['seq'],
    };
}

export function SubCategoryRequestModelToJSON(value?: SubCategoryRequestModel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'categoryName': value['categoryName'],
        'mongoId': value['mongoId'],
        'seq': value['seq'],
    };
}

