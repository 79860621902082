/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  MemberLevelDtoLite,
  MembershipRuleDto,
} from '../models/index';
import {
    MemberLevelDtoLiteFromJSON,
    MemberLevelDtoLiteToJSON,
    MembershipRuleDtoFromJSON,
    MembershipRuleDtoToJSON,
} from '../models/index';

export interface AddMemberLevelUsingPOSTRequest {
    name?: string;
    isOverride?: string;
    membershipName?: string;
    membershipPeriod?: string;
    order?: string;
    role?: string;
}

export interface AddMembershipRuleUsingPOSTRequest {
    name?: string;
    memberLevelId?: number;
    pointRequire?: number;
    cashRequire?: number;
    period?: string;
}

export interface GetAllMemberLevelUsingGETRequest {
    name?: string;
}

export interface GetMemberLevelUsingGETRequest {
    memberLevelId: number;
    name?: string;
}

export interface GetMembershipRuleUsingGETRequest {
    membershipRuleId: number;
}

export interface UpdateMemberLevelUsingPOSTRequest {
    memberLevelId: number;
    name?: string;
    isOverride?: string;
    membershipName?: string;
    membershipPeriod?: string;
    order?: string;
    role?: string;
}

export interface UpdateMembershipRuleUsingPOSTRequest {
    membershipRuleId: number;
    name?: string;
    memberLevelId?: number;
    pointRequire?: number;
    cashRequire?: number;
    period?: string;
}

/**
 * 
 */
export class MembershipControllerApi extends runtime.BaseAPI {

    /**
     * addMemberLevel
     */
    async addMemberLevelUsingPOSTRaw(requestParameters: AddMemberLevelUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MemberLevelDtoLite>> {
        const queryParameters: any = {};

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        if (requestParameters['isOverride'] != null) {
            queryParameters['isOverride'] = requestParameters['isOverride'];
        }

        if (requestParameters['membershipName'] != null) {
            queryParameters['membershipName'] = requestParameters['membershipName'];
        }

        if (requestParameters['membershipPeriod'] != null) {
            queryParameters['membershipPeriod'] = requestParameters['membershipPeriod'];
        }

        if (requestParameters['order'] != null) {
            queryParameters['order'] = requestParameters['order'];
        }

        if (requestParameters['role'] != null) {
            queryParameters['role'] = requestParameters['role'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/membership/level/add`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MemberLevelDtoLiteFromJSON(jsonValue));
    }

    /**
     * addMemberLevel
     */
    async addMemberLevelUsingPOST(requestParameters: AddMemberLevelUsingPOSTRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MemberLevelDtoLite | null | undefined > {
        const response = await this.addMemberLevelUsingPOSTRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 201:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * addMembershipRule
     */
    async addMembershipRuleUsingPOSTRaw(requestParameters: AddMembershipRuleUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MembershipRuleDto>> {
        const queryParameters: any = {};

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        if (requestParameters['memberLevelId'] != null) {
            queryParameters['memberLevelId'] = requestParameters['memberLevelId'];
        }

        if (requestParameters['pointRequire'] != null) {
            queryParameters['pointRequire'] = requestParameters['pointRequire'];
        }

        if (requestParameters['cashRequire'] != null) {
            queryParameters['cashRequire'] = requestParameters['cashRequire'];
        }

        if (requestParameters['period'] != null) {
            queryParameters['period'] = requestParameters['period'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/membership/rule/add`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MembershipRuleDtoFromJSON(jsonValue));
    }

    /**
     * addMembershipRule
     */
    async addMembershipRuleUsingPOST(requestParameters: AddMembershipRuleUsingPOSTRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MembershipRuleDto | null | undefined > {
        const response = await this.addMembershipRuleUsingPOSTRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 201:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * getAllMemberLevel
     */
    async getAllMemberLevelUsingGETRaw(requestParameters: GetAllMemberLevelUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<MemberLevelDtoLite>>> {
        const queryParameters: any = {};

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/membership/level/all`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(MemberLevelDtoLiteFromJSON));
    }

    /**
     * getAllMemberLevel
     */
    async getAllMemberLevelUsingGET(requestParameters: GetAllMemberLevelUsingGETRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<MemberLevelDtoLite>> {
        const response = await this.getAllMemberLevelUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getAllMembershipRule
     */
    async getAllMembershipRuleUsingGETRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<MembershipRuleDto>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/membership/rule/all`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(MembershipRuleDtoFromJSON));
    }

    /**
     * getAllMembershipRule
     */
    async getAllMembershipRuleUsingGET(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<MembershipRuleDto>> {
        const response = await this.getAllMembershipRuleUsingGETRaw(initOverrides);
        return await response.value();
    }

    /**
     * getMemberLevel
     */
    async getMemberLevelUsingGETRaw(requestParameters: GetMemberLevelUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MemberLevelDtoLite>> {
        if (requestParameters['memberLevelId'] == null) {
            throw new runtime.RequiredError(
                'memberLevelId',
                'Required parameter "memberLevelId" was null or undefined when calling getMemberLevelUsingGET().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/membership/level/{memberLevelId}`.replace(`{${"memberLevelId"}}`, encodeURIComponent(String(requestParameters['memberLevelId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MemberLevelDtoLiteFromJSON(jsonValue));
    }

    /**
     * getMemberLevel
     */
    async getMemberLevelUsingGET(requestParameters: GetMemberLevelUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MemberLevelDtoLite> {
        const response = await this.getMemberLevelUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getMembershipRule
     */
    async getMembershipRuleUsingGETRaw(requestParameters: GetMembershipRuleUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MembershipRuleDto>> {
        if (requestParameters['membershipRuleId'] == null) {
            throw new runtime.RequiredError(
                'membershipRuleId',
                'Required parameter "membershipRuleId" was null or undefined when calling getMembershipRuleUsingGET().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/membership/rule/{membershipRuleId}`.replace(`{${"membershipRuleId"}}`, encodeURIComponent(String(requestParameters['membershipRuleId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MembershipRuleDtoFromJSON(jsonValue));
    }

    /**
     * getMembershipRule
     */
    async getMembershipRuleUsingGET(requestParameters: GetMembershipRuleUsingGETRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MembershipRuleDto> {
        const response = await this.getMembershipRuleUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * updateMemberLevel
     */
    async updateMemberLevelUsingPOSTRaw(requestParameters: UpdateMemberLevelUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MemberLevelDtoLite>> {
        if (requestParameters['memberLevelId'] == null) {
            throw new runtime.RequiredError(
                'memberLevelId',
                'Required parameter "memberLevelId" was null or undefined when calling updateMemberLevelUsingPOST().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        if (requestParameters['memberLevelId'] != null) {
            queryParameters['memberLevelId'] = requestParameters['memberLevelId'];
        }

        if (requestParameters['isOverride'] != null) {
            queryParameters['isOverride'] = requestParameters['isOverride'];
        }

        if (requestParameters['membershipName'] != null) {
            queryParameters['membershipName'] = requestParameters['membershipName'];
        }

        if (requestParameters['membershipPeriod'] != null) {
            queryParameters['membershipPeriod'] = requestParameters['membershipPeriod'];
        }

        if (requestParameters['order'] != null) {
            queryParameters['order'] = requestParameters['order'];
        }

        if (requestParameters['role'] != null) {
            queryParameters['role'] = requestParameters['role'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/membership/level/update`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MemberLevelDtoLiteFromJSON(jsonValue));
    }

    /**
     * updateMemberLevel
     */
    async updateMemberLevelUsingPOST(requestParameters: UpdateMemberLevelUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MemberLevelDtoLite | null | undefined > {
        const response = await this.updateMemberLevelUsingPOSTRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 201:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * updateMembershipRule
     */
    async updateMembershipRuleUsingPOSTRaw(requestParameters: UpdateMembershipRuleUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MembershipRuleDto>> {
        if (requestParameters['membershipRuleId'] == null) {
            throw new runtime.RequiredError(
                'membershipRuleId',
                'Required parameter "membershipRuleId" was null or undefined when calling updateMembershipRuleUsingPOST().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        if (requestParameters['membershipRuleId'] != null) {
            queryParameters['membershipRuleId'] = requestParameters['membershipRuleId'];
        }

        if (requestParameters['memberLevelId'] != null) {
            queryParameters['memberLevelId'] = requestParameters['memberLevelId'];
        }

        if (requestParameters['pointRequire'] != null) {
            queryParameters['pointRequire'] = requestParameters['pointRequire'];
        }

        if (requestParameters['cashRequire'] != null) {
            queryParameters['cashRequire'] = requestParameters['cashRequire'];
        }

        if (requestParameters['period'] != null) {
            queryParameters['period'] = requestParameters['period'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Bearer"] = await this.configuration.apiKey("Bearer"); // Bearer authentication
        }

        const response = await this.request({
            path: `/membership/rule/update`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MembershipRuleDtoFromJSON(jsonValue));
    }

    /**
     * updateMembershipRule
     */
    async updateMembershipRuleUsingPOST(requestParameters: UpdateMembershipRuleUsingPOSTRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MembershipRuleDto | null | undefined > {
        const response = await this.updateMembershipRuleUsingPOSTRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 201:
                return null;
            default:
                return await response.value();
        }
    }

}
