/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ProductDto } from './ProductDto';
import {
    ProductDtoFromJSON,
    ProductDtoFromJSONTyped,
    ProductDtoToJSON,
} from './ProductDto';

/**
 * 
 * @export
 * @interface PointRuleDto
 */
export interface PointRuleDto {
    /**
     * 
     * @type {number}
     * @memberof PointRuleDto
     */
    appliedMemberLevel?: number;
    /**
     * 
     * @type {number}
     * @memberof PointRuleDto
     */
    appliedProduct?: number;
    /**
     * 
     * @type {number}
     * @memberof PointRuleDto
     */
    cashForOnePoint?: number;
    /**
     * 
     * @type {Date}
     * @memberof PointRuleDto
     */
    endDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof PointRuleDto
     */
    isBirthday?: string;
    /**
     * 
     * @type {number}
     * @memberof PointRuleDto
     */
    oneCashToPoint?: number;
    /**
     * 
     * @type {string}
     * @memberof PointRuleDto
     */
    period?: string;
    /**
     * 
     * @type {number}
     * @memberof PointRuleDto
     */
    pointRuleId?: number;
    /**
     * 
     * @type {string}
     * @memberof PointRuleDto
     */
    pointRuleName?: string;
    /**
     * 
     * @type {number}
     * @memberof PointRuleDto
     */
    pointToOneCash?: number;
    /**
     * 
     * @type {ProductDto}
     * @memberof PointRuleDto
     */
    product?: ProductDto;
    /**
     * 
     * @type {Date}
     * @memberof PointRuleDto
     */
    startDate?: Date;
}

/**
 * Check if a given object implements the PointRuleDto interface.
 */
export function instanceOfPointRuleDto(value: object): boolean {
    return true;
}

export function PointRuleDtoFromJSON(json: any): PointRuleDto {
    return PointRuleDtoFromJSONTyped(json, false);
}

export function PointRuleDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PointRuleDto {
    if (json == null) {
        return json;
    }
    return {
        
        'appliedMemberLevel': json['appliedMemberLevel'] == null ? undefined : json['appliedMemberLevel'],
        'appliedProduct': json['appliedProduct'] == null ? undefined : json['appliedProduct'],
        'cashForOnePoint': json['cashForOnePoint'] == null ? undefined : json['cashForOnePoint'],
        'endDate': json['endDate'] == null ? undefined : (new Date(json['endDate'])),
        'isBirthday': json['isBirthday'] == null ? undefined : json['isBirthday'],
        'oneCashToPoint': json['oneCashToPoint'] == null ? undefined : json['oneCashToPoint'],
        'period': json['period'] == null ? undefined : json['period'],
        'pointRuleId': json['pointRuleId'] == null ? undefined : json['pointRuleId'],
        'pointRuleName': json['pointRuleName'] == null ? undefined : json['pointRuleName'],
        'pointToOneCash': json['pointToOneCash'] == null ? undefined : json['pointToOneCash'],
        'product': json['product'] == null ? undefined : ProductDtoFromJSON(json['product']),
        'startDate': json['startDate'] == null ? undefined : (new Date(json['startDate'])),
    };
}

export function PointRuleDtoToJSON(value?: PointRuleDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'appliedMemberLevel': value['appliedMemberLevel'],
        'appliedProduct': value['appliedProduct'],
        'cashForOnePoint': value['cashForOnePoint'],
        'endDate': value['endDate'] == null ? undefined : ((value['endDate']).toISOString()),
        'isBirthday': value['isBirthday'],
        'oneCashToPoint': value['oneCashToPoint'],
        'period': value['period'],
        'pointRuleId': value['pointRuleId'],
        'pointRuleName': value['pointRuleName'],
        'pointToOneCash': value['pointToOneCash'],
        'product': ProductDtoToJSON(value['product']),
        'startDate': value['startDate'] == null ? undefined : ((value['startDate']).toISOString()),
    };
}

