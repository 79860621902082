/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Module } from './Module';
import {
    ModuleFromJSON,
    ModuleFromJSONTyped,
    ModuleToJSON,
} from './Module';
import type { Package } from './Package';
import {
    PackageFromJSON,
    PackageFromJSONTyped,
    PackageToJSON,
} from './Package';

/**
 * 
 * @export
 * @interface ClassLoader
 */
export interface ClassLoader {
    /**
     * 
     * @type {Array<Package>}
     * @memberof ClassLoader
     */
    definedPackages?: Array<Package>;
    /**
     * 
     * @type {string}
     * @memberof ClassLoader
     */
    name?: string;
    /**
     * 
     * @type {ClassLoader}
     * @memberof ClassLoader
     */
    parent?: ClassLoader;
    /**
     * 
     * @type {boolean}
     * @memberof ClassLoader
     */
    registeredAsParallelCapable?: boolean;
    /**
     * 
     * @type {Module}
     * @memberof ClassLoader
     */
    unnamedModule?: Module;
}

/**
 * Check if a given object implements the ClassLoader interface.
 */
export function instanceOfClassLoader(value: object): boolean {
    return true;
}

export function ClassLoaderFromJSON(json: any): ClassLoader {
    return ClassLoaderFromJSONTyped(json, false);
}

export function ClassLoaderFromJSONTyped(json: any, ignoreDiscriminator: boolean): ClassLoader {
    if (json == null) {
        return json;
    }
    return {
        
        'definedPackages': json['definedPackages'] == null ? undefined : ((json['definedPackages'] as Array<any>).map(PackageFromJSON)),
        'name': json['name'] == null ? undefined : json['name'],
        'parent': json['parent'] == null ? undefined : ClassLoaderFromJSON(json['parent']),
        'registeredAsParallelCapable': json['registeredAsParallelCapable'] == null ? undefined : json['registeredAsParallelCapable'],
        'unnamedModule': json['unnamedModule'] == null ? undefined : ModuleFromJSON(json['unnamedModule']),
    };
}

export function ClassLoaderToJSON(value?: ClassLoader | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'definedPackages': value['definedPackages'] == null ? undefined : ((value['definedPackages'] as Array<any>).map(PackageToJSON)),
        'name': value['name'],
        'parent': ClassLoaderToJSON(value['parent']),
        'registeredAsParallelCapable': value['registeredAsParallelCapable'],
        'unnamedModule': ModuleToJSON(value['unnamedModule']),
    };
}

