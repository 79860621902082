/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { MarketingEventMemberLevel } from './MarketingEventMemberLevel';
import {
    MarketingEventMemberLevelFromJSON,
    MarketingEventMemberLevelFromJSONTyped,
    MarketingEventMemberLevelToJSON,
} from './MarketingEventMemberLevel';
import type { Member } from './Member';
import {
    MemberFromJSON,
    MemberFromJSONTyped,
    MemberToJSON,
} from './Member';
import type { MemberLevelDiscount } from './MemberLevelDiscount';
import {
    MemberLevelDiscountFromJSON,
    MemberLevelDiscountFromJSONTyped,
    MemberLevelDiscountToJSON,
} from './MemberLevelDiscount';
import type { MembershipRule } from './MembershipRule';
import {
    MembershipRuleFromJSON,
    MembershipRuleFromJSONTyped,
    MembershipRuleToJSON,
} from './MembershipRule';
import type { PointRule } from './PointRule';
import {
    PointRuleFromJSON,
    PointRuleFromJSONTyped,
    PointRuleToJSON,
} from './PointRule';

/**
 * 
 * @export
 * @interface MemberLevel
 */
export interface MemberLevel {
    /**
     * 
     * @type {string}
     * @memberof MemberLevel
     */
    isOverride?: string;
    /**
     * 
     * @type {Array<MarketingEventMemberLevel>}
     * @memberof MemberLevel
     */
    marketingEventMemberLevelsByMemberLevelId?: Array<MarketingEventMemberLevel>;
    /**
     * 
     * @type {Array<MemberLevelDiscount>}
     * @memberof MemberLevel
     */
    memberLevelDiscountsByMemberLevelId?: Array<MemberLevelDiscount>;
    /**
     * 
     * @type {number}
     * @memberof MemberLevel
     */
    memberLevelId?: number;
    /**
     * 
     * @type {Array<Member>}
     * @memberof MemberLevel
     */
    membersByMemberLevelId?: Array<Member>;
    /**
     * 
     * @type {string}
     * @memberof MemberLevel
     */
    membershipName?: string;
    /**
     * 
     * @type {string}
     * @memberof MemberLevel
     */
    membershipPeriod?: string;
    /**
     * 
     * @type {Array<MembershipRule>}
     * @memberof MemberLevel
     */
    membershipRulesByMemberLevelId?: Array<MembershipRule>;
    /**
     * 
     * @type {string}
     * @memberof MemberLevel
     */
    order?: string;
    /**
     * 
     * @type {Array<PointRule>}
     * @memberof MemberLevel
     */
    pointRulesByMemberLevelId?: Array<PointRule>;
    /**
     * 
     * @type {string}
     * @memberof MemberLevel
     */
    role?: string;
}

/**
 * Check if a given object implements the MemberLevel interface.
 */
export function instanceOfMemberLevel(value: object): boolean {
    return true;
}

export function MemberLevelFromJSON(json: any): MemberLevel {
    return MemberLevelFromJSONTyped(json, false);
}

export function MemberLevelFromJSONTyped(json: any, ignoreDiscriminator: boolean): MemberLevel {
    if (json == null) {
        return json;
    }
    return {
        
        'isOverride': json['isOverride'] == null ? undefined : json['isOverride'],
        'marketingEventMemberLevelsByMemberLevelId': json['marketingEventMemberLevelsByMemberLevelId'] == null ? undefined : ((json['marketingEventMemberLevelsByMemberLevelId'] as Array<any>).map(MarketingEventMemberLevelFromJSON)),
        'memberLevelDiscountsByMemberLevelId': json['memberLevelDiscountsByMemberLevelId'] == null ? undefined : ((json['memberLevelDiscountsByMemberLevelId'] as Array<any>).map(MemberLevelDiscountFromJSON)),
        'memberLevelId': json['memberLevelId'] == null ? undefined : json['memberLevelId'],
        'membersByMemberLevelId': json['membersByMemberLevelId'] == null ? undefined : ((json['membersByMemberLevelId'] as Array<any>).map(MemberFromJSON)),
        'membershipName': json['membershipName'] == null ? undefined : json['membershipName'],
        'membershipPeriod': json['membershipPeriod'] == null ? undefined : json['membershipPeriod'],
        'membershipRulesByMemberLevelId': json['membershipRulesByMemberLevelId'] == null ? undefined : ((json['membershipRulesByMemberLevelId'] as Array<any>).map(MembershipRuleFromJSON)),
        'order': json['order'] == null ? undefined : json['order'],
        'pointRulesByMemberLevelId': json['pointRulesByMemberLevelId'] == null ? undefined : ((json['pointRulesByMemberLevelId'] as Array<any>).map(PointRuleFromJSON)),
        'role': json['role'] == null ? undefined : json['role'],
    };
}

export function MemberLevelToJSON(value?: MemberLevel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'isOverride': value['isOverride'],
        'marketingEventMemberLevelsByMemberLevelId': value['marketingEventMemberLevelsByMemberLevelId'] == null ? undefined : ((value['marketingEventMemberLevelsByMemberLevelId'] as Array<any>).map(MarketingEventMemberLevelToJSON)),
        'memberLevelDiscountsByMemberLevelId': value['memberLevelDiscountsByMemberLevelId'] == null ? undefined : ((value['memberLevelDiscountsByMemberLevelId'] as Array<any>).map(MemberLevelDiscountToJSON)),
        'memberLevelId': value['memberLevelId'],
        'membersByMemberLevelId': value['membersByMemberLevelId'] == null ? undefined : ((value['membersByMemberLevelId'] as Array<any>).map(MemberToJSON)),
        'membershipName': value['membershipName'],
        'membershipPeriod': value['membershipPeriod'],
        'membershipRulesByMemberLevelId': value['membershipRulesByMemberLevelId'] == null ? undefined : ((value['membershipRulesByMemberLevelId'] as Array<any>).map(MembershipRuleToJSON)),
        'order': value['order'],
        'pointRulesByMemberLevelId': value['pointRulesByMemberLevelId'] == null ? undefined : ((value['pointRulesByMemberLevelId'] as Array<any>).map(PointRuleToJSON)),
        'role': value['role'],
    };
}

