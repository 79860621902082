/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Order } from './Order';
import {
    OrderFromJSON,
    OrderFromJSONTyped,
    OrderToJSON,
} from './Order';
import type { OrderProductDiscount } from './OrderProductDiscount';
import {
    OrderProductDiscountFromJSON,
    OrderProductDiscountFromJSONTyped,
    OrderProductDiscountToJSON,
} from './OrderProductDiscount';
import type { Product } from './Product';
import {
    ProductFromJSON,
    ProductFromJSONTyped,
    ProductToJSON,
} from './Product';
import type { ProductPoint } from './ProductPoint';
import {
    ProductPointFromJSON,
    ProductPointFromJSONTyped,
    ProductPointToJSON,
} from './ProductPoint';

/**
 * 
 * @export
 * @interface OrderProduct
 */
export interface OrderProduct {
    /**
     * 
     * @type {number}
     * @memberof OrderProduct
     */
    deliveryTimes?: number;
    /**
     * 
     * @type {number}
     * @memberof OrderProduct
     */
    finalPrice?: number;
    /**
     * 
     * @type {Order}
     * @memberof OrderProduct
     */
    orderByOrderId?: Order;
    /**
     * 
     * @type {number}
     * @memberof OrderProduct
     */
    orderId?: number;
    /**
     * 
     * @type {Array<OrderProductDiscount>}
     * @memberof OrderProduct
     */
    orderProductDiscountsByOrderProductId?: Array<OrderProductDiscount>;
    /**
     * 
     * @type {number}
     * @memberof OrderProduct
     */
    orderProductId?: number;
    /**
     * 
     * @type {Product}
     * @memberof OrderProduct
     */
    productByProductId?: Product;
    /**
     * 
     * @type {number}
     * @memberof OrderProduct
     */
    productId?: number;
    /**
     * 
     * @type {ProductPoint}
     * @memberof OrderProduct
     */
    productPointByProductPointId?: ProductPoint;
    /**
     * 
     * @type {number}
     * @memberof OrderProduct
     */
    productPointId?: number;
    /**
     * 
     * @type {number}
     * @memberof OrderProduct
     */
    productPrice?: number;
    /**
     * 
     * @type {number}
     * @memberof OrderProduct
     */
    quantity?: number;
    /**
     * 
     * @type {number}
     * @memberof OrderProduct
     */
    usedPoint?: number;
}

/**
 * Check if a given object implements the OrderProduct interface.
 */
export function instanceOfOrderProduct(value: object): boolean {
    return true;
}

export function OrderProductFromJSON(json: any): OrderProduct {
    return OrderProductFromJSONTyped(json, false);
}

export function OrderProductFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrderProduct {
    if (json == null) {
        return json;
    }
    return {
        
        'deliveryTimes': json['deliveryTimes'] == null ? undefined : json['deliveryTimes'],
        'finalPrice': json['finalPrice'] == null ? undefined : json['finalPrice'],
        'orderByOrderId': json['orderByOrderId'] == null ? undefined : OrderFromJSON(json['orderByOrderId']),
        'orderId': json['orderId'] == null ? undefined : json['orderId'],
        'orderProductDiscountsByOrderProductId': json['orderProductDiscountsByOrderProductId'] == null ? undefined : ((json['orderProductDiscountsByOrderProductId'] as Array<any>).map(OrderProductDiscountFromJSON)),
        'orderProductId': json['orderProductId'] == null ? undefined : json['orderProductId'],
        'productByProductId': json['productByProductId'] == null ? undefined : ProductFromJSON(json['productByProductId']),
        'productId': json['productId'] == null ? undefined : json['productId'],
        'productPointByProductPointId': json['productPointByProductPointId'] == null ? undefined : ProductPointFromJSON(json['productPointByProductPointId']),
        'productPointId': json['productPointId'] == null ? undefined : json['productPointId'],
        'productPrice': json['productPrice'] == null ? undefined : json['productPrice'],
        'quantity': json['quantity'] == null ? undefined : json['quantity'],
        'usedPoint': json['usedPoint'] == null ? undefined : json['usedPoint'],
    };
}

export function OrderProductToJSON(value?: OrderProduct | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'deliveryTimes': value['deliveryTimes'],
        'finalPrice': value['finalPrice'],
        'orderByOrderId': OrderToJSON(value['orderByOrderId']),
        'orderId': value['orderId'],
        'orderProductDiscountsByOrderProductId': value['orderProductDiscountsByOrderProductId'] == null ? undefined : ((value['orderProductDiscountsByOrderProductId'] as Array<any>).map(OrderProductDiscountToJSON)),
        'orderProductId': value['orderProductId'],
        'productByProductId': ProductToJSON(value['productByProductId']),
        'productId': value['productId'],
        'productPointByProductPointId': ProductPointToJSON(value['productPointByProductPointId']),
        'productPointId': value['productPointId'],
        'productPrice': value['productPrice'],
        'quantity': value['quantity'],
        'usedPoint': value['usedPoint'],
    };
}

